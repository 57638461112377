import React, {Component} from 'react';
import PropTypes from 'prop-types';
import StyledDistribution from './StyledDistribution';
import DistributorsTable from '../../table/DistributorsTable';
import {Row, Col, Button, OverlayTrigger, Popover, ControlLabel} from 'react-bootstrap';
import SelectAdvanced from '../../../../../components/forms/SelectAdvanced';
import {getHiddenWizardFields, buildDefaultAudioFormat, buildDefaultVideoFormat} from '../../../../../utils';
import _ from 'lodash';

class Distribution extends Component {
  handleFieldValueChanged = (name, value) => {
    const {data, onSelect, filters} = this.props;
    value = Number(value);
    onSelect(name, value);
    if (name === 'qc_type_id') {
      const qcType = filters.qc_types.find(t => t.value === value);
      const hiddenFields = getHiddenWizardFields(filters.qc_profiles, data.qc_profile_id);
      onSelect('video_format', qcType.is_video ? [buildDefaultVideoFormat(hiddenFields, qcType)] : null);
      onSelect('audio_format', qcType.is_audio && !qcType.is_video ? buildDefaultAudioFormat(hiddenFields) : null);
      onSelect('caption_id', null);
      onSelect('version_tag', null);
      onSelect('additional_description', null);
      if (qcType.is_video) {
        ['select_files_type_id', 'selectedFiles', 'uploadedFiles'].forEach(k => {
          onSelect(k, undefined);
        });
      }
    }
  };

  renderSelect = () => {
    const {data, filters, onSave, onSelect} = this.props;
    return (
      <StyledDistribution className="step-body-wrapper distribution-step distributor-select">
        <DistributorsTable
          data={filters.distributors}
          qcProfiles={filters.qc_profiles}
          onSave={onSave}
          onSelect={distributorId => {
            onSelect('distributor_id', distributorId);
            const distributor = filters.distributors.find(d => d.value === distributorId);

            const distributor_qc_profile_ids = distributor && distributor.qc_profile_ids && distributor.qc_profile_ids.split(',');

            if (distributor_qc_profile_ids && distributor_qc_profile_ids.length === 1) {
              onSelect('qc_profile_id', +distributor_qc_profile_ids[0]);
            }
          }}
          selected={data.distributor_id}
          />
      </StyledDistribution>
    );
  };

  handleLogoLoad = () => {
    this.refs.distributorLogo.className = this.refs.distributorLogo.className.replace('hidden', '').trim();
  };

  handleLogoError = () => {
    this.refs.distributorName.className = this.refs.distributorName.className.replace('hidden', '').trim();
    const distributorInfo = this.refs.distributorName.parentElement.parentElement;
    distributorInfo.className = distributorInfo.className + ' no-logo';
  };

  renderOptions = () => {
    const {data, filters, onClickChange} = this.props;
    const {qc_profiles, qc_types, distributors} = filters;
    const distributor = distributors.find(d => d.value === data.distributor_id);

    const distributor_qc_profile_ids = distributor && distributor.qc_profile_ids && distributor.qc_profile_ids.split(',');
    let distributor_qc_profiles = [];
    if (distributor_qc_profile_ids) {
      distributor_qc_profile_ids.forEach(profileId => {
        let profile = qc_profiles.find(p => p.value === +profileId);
        if (profile) {
          distributor_qc_profiles.push(profile);
        }
      });
      distributor_qc_profiles = _.orderBy(distributor_qc_profiles, [d => d.name.toLowerCase()]);
    }

    return (
      <StyledDistribution className="step-body-wrapper distribution-step distributor-options">
        <div className="distributor-top">
          <Row className="distributor-select-block">
            <Col md={12} xs={12}>
              <div className="distributor-select-text">Select Distributor</div>
            </Col>
          </Row>
          <Row className={`distributor-selected-info${!distributor.logo_url ? ' no-logo' : ''}`}>
            <Col className="distributor-current" md={4} xs={12}>
              {
                distributor.logo_url ?
                  <img
                    className="distributor-logo hidden"
                    title={distributor.name}
                    src={distributor.logo_url}
                    alt=""
                    ref="distributorLogo"
                    onLoad={this.handleLogoLoad}
                    onError={this.handleLogoError}
                  /> : null
              }
              <div className={`distributor-name${distributor.logo_url ? ' hidden' : ''}`} ref="distributorName">
                {distributor.name}
                <div className="distributor-website">{distributor.website}</div>
              </div>
            </Col>
            <Col className="distributor-change" md={8} xs={12}>
              <Button bsStyle="primary" onClick={onClickChange}>Change</Button>
            </Col>
          </Row>
        </div>
        <div className="qc-profiles-and-types">
          <Row>
            <Col className="qc-profiles" md={3} xs={12}>
              {
                distributor_qc_profiles.length === 1 ? (
                  <div className="qc-profile-default">
                    <Row className="qc-profiles-and-types-label">
                      <Col md={12} xs={12}>
                        <ControlLabel>QC Profile</ControlLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} xs={12}>
                        {distributor_qc_profiles[0].name}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} xs={12}>
                        Using the default QC profile available for this distributor.
                      </Col>
                    </Row>
                  </div>
                ) :
                  <div className="qc-profile-select">
                    <SelectAdvanced
                      label="QC Profile"
                      labelSeparator=""
                      name="qc_profile_id"
                      options={distributor_qc_profiles.length > 0 ? distributor_qc_profiles : qc_profiles}
                      value={data.qc_profile_id}
                      placeholder=""
                      onSelect={this.handleFieldValueChanged}
                      />
                    {
                      data.qc_profile_id ? (
                        <OverlayTrigger
                          placement="right"
                          overlay={<Popover id="popover-what-is-this-qc-profile" title="What is this?">A QC Profile allows you to specify the level of QC you desire for a given project.
                          You can edit and create QC profiles in your preferences section.  For more information, please contact sales@eurofins-dms.com</Popover>}
                          >
                          <span className="overlay-what-is-this">what is this?</span>
                        </OverlayTrigger>
                      ) : null
                    }
                  </div>
              }
            </Col>
            <Col className="qc-types" md={9} xs={12}>
              <Row className="qc-profiles-and-types-label">
                <Col md={12} xs={12}>
                  <ControlLabel>QC Type</ControlLabel>
                </Col>
              </Row>
              <div className="qc-types-items">
                {qc_types.map((t, i) => (i % 2) === 0 ? (
                  <div key={`qc-type-items-${i}`}>
                    <Row>
                      {this.renderQcTypeItem(t)}
                      {(i + 1) < qc_types.length ? this.renderQcTypeItem(qc_types[i + 1]) : null}
                    </Row>
                    <Row>
                      <Col md={6} xs={12}><div className="qc-type-line"/></Col>
                      {(i + 1) < qc_types.length ? <Col md={6} xs={12}><div className="qc-type-line"/></Col> : null}
                    </Row>
                  </div>
                ) : null)}
              </div>
            </Col>
          </Row>
        </div>
      </StyledDistribution>
    );
  };

  renderQcTypeItem = item => {
    const {data} = this.props;
    return (
      <Col md={6} xs={12}>
        <div
          className={`qc-types-item${data.qc_type_id === item.value ? ' qc-type-selected' : ''}`}
          onClick={() => this.handleFieldValueChanged('qc_type_id', item.value)}
          >
          <span className="icon-circle-check"/><span className="qc-type-name">{item.name}</span>
          <div className="qc-type-note">
            {item.description}
          </div>
        </div>
      </Col>
    );
  };

  render() {
    const {showOptions} = this.props;
    return showOptions ? this.renderOptions() : this.renderSelect();
  }
}

Distribution.propTypes = {
  data: PropTypes.object.isRequired,
  showOptions: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClickChange: PropTypes.func.isRequired
};

Distribution.defaultProps = {
  data: {}
};

export default Distribution;
