import {
  MANUAL_WO_DATA_IS_LOADING, SET_MANUAL_WO_DATA
} from './actions';

const initialState = {
  loading: false,
  manualWoData: null,
  memfisCompanyList: []
};

export default function (state = initialState, action) {
  switch (action.type) {

    case MANUAL_WO_DATA_IS_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case SET_MANUAL_WO_DATA: {
      if (action.loadMore) {
        return {
          ...state,
          loading: false,
          manualWoData: [...state.manualWoData, ...action.data.work_order_list]
        };
      }

      return {
        ...state,
        loading: false,
        manualWoData: action.data.work_order_list,
        memfisCompanyList: action.data.memfis_company_list
      };
    }

    default:
      return state;
  }
}
