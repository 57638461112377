import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CommentDisabledIcon from '../../../../../assets/images/issueTable/comment-disabled.svg';
import CommentIcon from '../../../../../assets/images/issueTable/comment.svg';
import {getUserRoles} from '../../../../utils';
import Can from '../../../../components/Can';
import {getIssueCommentsCount} from '../sidebar/Issue';

export const ActionsCell = React.memo(({row, canComment, onClickDiscuss, onClickImage, className}) => {
  return (
    <ActionsCellStyled className={className}>
      <div>
        {!!canComment &&
          <button
            title="Discuss Issue"
            onClick={event => {
              event.stopPropagation();
              onClickDiscuss(row);
            }}
          >
            <img src={!getIssueCommentsCount(row) ? CommentDisabledIcon : CommentIcon} alt="comment"/>
          </button>
        }
        <Can
          roles={getUserRoles()}
          perform="report-details:show-issue-image-btn"
          data={row}
          yes={() => (
            <button
              className="btn-issue-image"
              title="Issue Images"
              onClick={event => {
                event.stopPropagation();
                onClickImage(row);
              }}
            >
              <i className={`fa fa-image${!row.ImageExists ? ' greyed' : ''}`}/>
            </button>
          )}
        />
      </div>
    </ActionsCellStyled>
  );
});

const ActionsCellStyled = styled.td`
  text-align: center;

  > div {
    display: flex;
  }

  button {
    border: none;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    &:hover, &:focus {
      background-color: transparent;
      outline: none;
    }

    &.btn-issue-image {
      margin-left: 6px;
      @media (max-width: 768px) {
        margin-left: 10px;
      }

      i.fa {
        color: #434343;
        font-size: 15px;
        vertical-align: middle;

        &.greyed {
          color: #dadada;
        }
      }
    }
  }
`;

ActionsCell.defaultProps = {
  canComment: true,
};

ActionsCell.propTypes = {
  row: PropTypes.object,
  className: PropTypes.string,
  canComment: PropTypes.bool,
  onClickDiscuss: PropTypes.func,
  onClickImage: PropTypes.func
};
