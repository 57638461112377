import React, {Component} from 'react';
import PropTypes from 'prop-types';
import 'bootstrap';
import {Row, Col, Button, ButtonToolbar} from 'react-bootstrap';
import styled from 'styled-components';
import {StyledModalBackdrop, StyledModalDialog, StyledModal, StyledMobileModal} from '../../../../styled/ModalStyled';
import {StyledModalHeader, StyledCloseModal, StyledModalBody} from '../../../Settings/components/modals/ModalStyled';
import Dropzone from 'react-dropzone';
import isMobile from 'is-mobile';
import Loader from '../../../../components/Loader';

class ImportCsvIssuesModal extends Component {
  state = {
    csvFile: null,
  };

  componentDidMount() {
    const {body} = document;
    body.className += ' modal-open';
  }

  componentWillUnmount() {
    const {body} = document;
    body.className = body.className.replace('modal-open', '').trim();
  }

  handleSubmit = () => {
    const {onSubmit} = this.props;
    const {csvFile} = this.state;

    if (!csvFile) {
      alert('CSV file is required');
      return;
    }

    onSubmit(csvFile);
  };

  handleUpload = files => {
    !!files.length && this.setState({csvFile: files[0]});
  };

  render() {
    const {onClose, uploading} = this.props;
    const {csvFile} = this.state;
    return (
      <StyledMobileModal>
        <StyledModalBackdrop onClick={onClose}/>
        <StyledModalDialog className="modal-dialog-custom">
          {uploading ? <Loader/> : null}
          <StyledModal className="modal-styled-custom">
            <StyledModalHeader className="modal-header-custom">
              Import CSV Issues
              <StyledCloseModal className="icon-close-modal" onClick={onClose}/>
            </StyledModalHeader>
            <StyledModalBodyCustom className="modal-body-custom edit-record-modal-body">
              <StyledModalBodyContent className="wrap-content">
                <Row>
                  <Col sm={12} xs={12}>
                    <div className="form-group">
                      For import, please use the exported issues CSV file with the same column names. Please set a status of "Approved", "Fixed", "Not Fixed", "Pending Approval", "Upgrade" or "Downgrade" for each issue.
                    </div>
                  </Col>
                </Row>
                {csvFile &&
                  <Row>
                    <Col sm={12} xs={12}>
                      <div className="form-group">
                        <label className="control-label">Selected file:</label>
                        <div className="form-control filename">
                          {csvFile.name}
                        </div>
                      </div>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col sm={3} xs={12} className="caption">
                    <div className="form-group">
                      <Dropzone onDrop={this.handleUpload} className="dropzone" multiple={false} accept=".csv">
                        <div>
                          <i className="fas fa-file-upload"/>
                          {isMobile() ? 'Tap' : 'Drop or click'} to select csv file to upload.
                        </div>
                      </Dropzone>
                    </div>
                  </Col>
                </Row>
              </StyledModalBodyContent>
              <ButtonToolbar>
                <Button
                  className="btn-in-modal btn-save"
                  onClick={this.handleSubmit}
                  disabled={!csvFile || uploading}
                >
                  Upload
                </Button>
                <Button
                  className="btn-in-modal btn-cancel"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </ButtonToolbar>
            </StyledModalBodyCustom>
          </StyledModal>
        </StyledModalDialog>
      </StyledMobileModal>
    );
  }
}

const StyledModalBodyCustom = styled(StyledModalBody)`
  overflow-y: hidden;

  @media (max-width: 767px) {
    padding: 5px 0 55px 0 !important;
  }

  .btn[disabled] {
    background: #e0e0e1;
    color: #c8c8c8;
    /*cursor: default;*/
  }
`;

const StyledModalBodyContent = styled.div`
  @media (max-width: 767px) {
    padding: 20px 25px !important;
    max-height: calc(40vh + 40px) !important;
    -webkit-overflow-scrolling: touch;
  }

  .form-group {
    label {
      font-size: 14px;
    }
    label + .form-control {
      font-size: 13px;
      background-color: #F3F3F3;
      border-color: #E9E9E9;
      color: #000;
      font-weight: 500;
      box-shadow: none;
      height: 37px;
      &.filename {
        height: unset;
        background-color: transparent;
        border-color: transparent;
        word-break: break-all;
      }
    }
  }

  .warning-file-uploaded {
    padding-top: 7px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 11px;
    line-height: 13px;
    word-break: break-word;

    i {
      margin-right: 5px;
      color: red;
      font-size: 13px !important;
      line-height: 13px;
    }
  }

  .dropzone {
    margin: 0;
    border-radius: 5px;
    border: black 1px dashed;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      padding: 0 5px;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        margin-right: 5px;
        line-height: unset;
      }
    }

    :not(:hover) {
      div {
        opacity: 0.8;
      }
    }
  }

  @media (min-width: 768px) {
    .col-sm-3.caption {
      min-width: fit-content;
      max-width: 100%;
    }
  }
`;

ImportCsvIssuesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired
};

export default ImportCsvIssuesModal;
