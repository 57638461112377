import React, {Component} from 'react';
import PropTypes from 'prop-types';
import StyledReviewAndConfirm from '../ReviewAndConfirm/StyledReviewAndConfirm';
import SubmitReplacement from './SubmitReplacement';

class SummaryReplacement extends Component {
  handleChange = (name, value) => {
    const {data, onChange} = this.props;
    if (name === 'files') {
      const {select_files_type_id, selectedFiles, uploadedFiles} = value;
      onChange('select_files_type_id', select_files_type_id)
      onChange('selectedFiles', selectedFiles)
      onChange('uploadedFiles', uploadedFiles)
    } else {
      onChange('replacement', {...data.replacement, [name]: value})
    }
  };

  render() {
    const {data, reportdetails, onGetReplacement, allowFileUpload} = this.props;
    const {select_files_type_id, selectedFiles, uploadedFiles} = data;
    return (
      <StyledReviewAndConfirm className="step-body-wrapper review-confirm">
        <SubmitReplacement
          reportdetails={reportdetails}
          startedRequestId={data.origin_request_id}
          replacementCount={data.origin_request.ReplacementCount || 0}
          onGetReplacement={onGetReplacement}
          onChange={this.handleChange}
          files={{select_files_type_id, selectedFiles, uploadedFiles}}
          allowFileUpload={allowFileUpload}
          />
      </StyledReviewAndConfirm>
    );
  }
}

SummaryReplacement.propTypes = {
  allowFileUpload: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  reportdetails: PropTypes.object.isRequired,
  onGetReplacement: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SummaryReplacement;
