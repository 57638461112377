import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import {getMonth, getYear, getISODay} from 'date-fns';
import DatePicker, {registerLocale} from 'react-datepicker';
import en from 'date-fns/locale/en-GB';
import isMobile from 'is-mobile';

registerLocale('en', en);

export const COLORS = ['#0db4d9', '#5d3996', '#ff9a03', '#42bc83', '#c3b6d8'];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const Calendar = props => {
  const [highlightDates, setHighlightDates] = useState([]);
  const {year, month, data} = props;

  useEffect(() => {
    const updatedData = data ? data.map(item => {
      return {
        date: moment(item.date).toDate(),
        colorIndex: item.colorIndex
      }
    }) : [];
    setHighlightDates([
      {'highlighted__day highlighted__day-1': updatedData.filter(item => item.colorIndex % 5 === 1).map(item => item.date)},
      {'highlighted__day highlighted__day-2': updatedData.filter(item => item.colorIndex % 5 === 2).map(item => item.date)},
      {'highlighted__day highlighted__day-3': updatedData.filter(item => item.colorIndex % 5 === 3).map(item => item.date)},
      {'highlighted__day highlighted__day-4': updatedData.filter(item => item.colorIndex % 5 === 4).map(item => item.date)},
      {'highlighted__day highlighted__day-5': updatedData.filter(item => item.colorIndex % 5 === 0).map(item => item.date)}
    ]);
  }, [data]);

  const getHeader = props => {
    const {date} = props;
    return (
      <CalendarHeaderStyled className={isMobile() ? 'is-mobile-calendar' : ''}>
        <span className="month">{months[getMonth(date)]}</span>
        <span className="year">{getYear(date)}</span>
      </CalendarHeaderStyled>
    );
  };

  const getMobileHeader = props => {
    return (
      <CalendarMobileHeaderStyled>
        {getHeader(props)}
        {getHeader(props)}
      </CalendarMobileHeaderStyled>
    );
  };

  const getDayClassName = date => {
    return `iso-day--${getISODay(date)}`;
  };

  const selectedDate = (year && month) ? new Date(year, month - 1, 1) : null;
  return (
    <CalendarStyled className={isMobile() ? 'is-mobile-calendar' : ''}>
      <DatePicker
        inline
        locale="en"
        monthsShown={1}
        useWeekdaysShort={true}
        renderCustomHeader={isMobile() ? getMobileHeader : getHeader}
        highlightDates={highlightDates}
        selected={selectedDate}
        dayClassName={getDayClassName}
      />
    </CalendarStyled>
  )
}

const CalendarHeaderStyled = styled.div`
  align-items: center;
  display: flex;
  font-size: 17px;
  margin-bottom: 16px;
  text-align: left;
  .month {
    font-weight: bold;
    margin-right: 3px;
  }
  .year {
    margin-right: 5px;
  }

  &.is-mobile-calendar {
    @media (max-width: 767px) {
      margin-bottom: 0;
      line-height: 22px;
    }
    @media (min-width: 768px) {
      & + & {
        display: none;
      }
    }
  }
`;

const CalendarMobileHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CalendarStyled = styled.div`
  .react-datepicker {
    border: none;
    background: transparent;
    width: 100%;
    .react-datepicker__month-container {
      width: 100%;
    }
    .react-datepicker__header {
      background: transparent;
      border: none;
    }
    .react-datepicker__day-names,
    .react-datepicker__week {
      display: flex;
      justify-content: space-between;
    }
    .react-datepicker__day-name,
    .react-datepicker__day {
      color: #444444;
      font-size: 15px;
      line-height: 30px;
      margin-bottom: 6px;
      width: 30px;
    }
    .react-datepicker__day-name {
      font-size: 13px;
    }
    .react-datepicker__day {
      cursor: default;
      border-radius: 100%;
      &:hover {
        &:not(.highlighted__day) {
          background-color: transparent;
        }
      }
    }
    .react-datepicker__day--today {
      background: transparent;
      font-weight: normal;
    }
    .react-datepicker__day--outside-month {
      visibility: hidden;
    }
    .react-datepicker__day--selected:not(.highlighted__day) {
      background: transparent !important;
    }
    .highlighted__day {
      color: #ffffff;
    }
    .highlighted__day-1 {
      background: ${COLORS[0]};
    }
    .highlighted__day-2 {
      background: ${COLORS[1]};
    }
    .highlighted__day-3 {
      background: ${COLORS[2]};
    }
    .highlighted__day-4 {
      background: ${COLORS[3]};
    }
    .highlighted__day-5 {
      background: ${COLORS[4]};
    }
  }

  &.is-mobile-calendar {

    @media (max-width: 767px) {
      & + & {
        margin-left: 20px;
      }

      .react-datepicker {

        .react-datepicker__month-container {
          float: none;
        }

        .react-datepicker__header {
          padding: 0 0 0 8px;
        }

        .react-datepicker__day-names {
          display: none;
        }

        .react-datepicker__month {
          display: flex;
          flex-wrap: nowrap;
          overflow-x: auto;
          margin: 0 3px;
        }

        .react-datepicker__day--outside-month {
          display: none;
        }

        .react-datepicker__day {
          position: relative;
          margin: 48px calc((100vw - 23px * 7 - 70px) / (6 * 2)) 16px;
          font-size: 13px;
          line-height: 25px;
          width: 25px;

          &.iso-day--1:before {
            content: 'M';
            position: absolute;
            top: -32px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
            color: #505050;
            opacity: 0.4;
          }
          &.iso-day--2:before {
            content: 'T';
            position: absolute;
            top: -32px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
            color: #505050;
            opacity: 0.4;
          }
          &.iso-day--3:before {
            content: 'W';
            position: absolute;
            top: -32px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
            color: #505050;
            opacity: 0.4;
          }
          &.iso-day--4:before {
            content: 'T';
            position: absolute;
            top: -32px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
            color: #505050;
            opacity: 0.4;
          }
          &.iso-day--5:before {
            content: 'F';
            position: absolute;
            top: -32px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
            color: #505050;
            opacity: 0.4;
          }
          &.iso-day--6:before {
            content: 'S';
            position: absolute;
            top: -32px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
            color: #505050;
            opacity: 0.4;
          }
          &.iso-day--7:before {
            content: 'S';
            position: absolute;
            top: -32px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
            color: #505050;
            opacity: 0.4;
          }
        }
      }
    }
  }
`;

Calendar.propTypes = {
  data: PropTypes.array,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  month: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Calendar;
