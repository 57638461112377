import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../components/Loader';
import Table from '../../../../components/table/Table';
import DateCell from '../../../../components/table/cells/DateCell';
import {TitleCell} from './cells/TitleCell';
import {RequestStatusCell} from '../../../Requests/components/tablecells/RequestStatusCell';
import styled from 'styled-components';
import ViewAllLink from './ViewAllLink';

class RequestsTable extends Component {
  headers = {
    fBegin: {},
    Title: {
      title: 'Request Title',
      component: TitleCell,
      componentArgs: {
        onCloseSearch: this.props.onCloseSearch
      }
    },
    SubmittedOn: {
      title: 'Submitted On',
      component: DateCell,
      componentArgs: {
        toFormat: 'MMMM D, YYYY HH:mm A'
      }
    },
    RequestedBy: {
      title: 'Requested By'
    },
    RequestStatus: {
      title: 'Status',
      component: RequestStatusCell
    },
    fEnd: {}
  };

  render() {
    const {loading, subTable, total, data, onCloseSearch, viewAllLinkTo} = this.props;
    return (
      <RequestsTableStyled className={`${subTable ? ' sub-table' : ''}${total > 3 ? ' more-rows' : ''}`}>
        {loading ? <Loader/> : null}
        {
          subTable ? (
            <div className={`table-info${loading && !total ? ' no-total-data' : ''}`}>
              <div className="total-requests">{total} Request{total === 1 ? '' : 's'}</div>
              <ViewAllLink to={viewAllLinkTo} onClick={onCloseSearch} total={total}/>
            </div>
          ) : null
        }
        <div className="table-block">
          <Table
            headers={this.headers}
            rows={data.slice(0, 3)}
            emptyText={loading ? null : 'No Requests'}
            stripped={false}
            />
        </div>
        <ViewAllLink to={viewAllLinkTo} onClick={onCloseSearch} total={total}/>
      </RequestsTableStyled>
    );
  }
}

const RequestsTableStyled = styled.div`
  @media (min-width: 768px) {
    margin-top: -4px;
  }

  & > .table-info, & > .loader-container + .table-info {
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    margin-top: 4px;
    color: #000000;
    &.no-total-data {
      color: transparent;
      a.view-all {
        display: none;
      }
    }
  }
  & > .table-info {
    margin-bottom: 16px;

    @media (min-width: 768px) {
      a.view-all {
        display: none;
      }
    }

    @media (max-width: 767px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      a.view-all {
        margin: 0 25px 0 0;
      }
    }
  }
  & > .table-block, & > .loader-container + .table-block,
  & > .table-info + .table-block, & > .loader-container + .table-info + .table-block {
    max-width: 100%;
    overflow-x: auto;
    > .table {
      border-collapse: separate;
      color: #000000;
      margin: 0;

      > thead {

        @media (max-width: 767px) {
          display: none;
        }

        > tr {
          > th {
            pointer-events: none;
            font-weight: bold;
            font-size: 12px;
            line-height: 13px;
            text-align: left;
            vertical-align: bottom;
            border: 0;
            border-bottom: 1px solid #e0e0e0;
            padding: 0 10px 15px 1px;
            background: #f9f9f9;
            &:first-child {
              padding-left: 0;
              padding-right: 0;
              width: 31px;
            }
            &:last-child {
              padding-left: 0;
              padding-right: 0;
              width: 37px;
            }
          }
        }
      }

      > tbody {
        > tr {
          cursor: default;
          > td {
            vertical-align: middle;
            padding: 14px 10px 14px 1px;
            background: #fff;
            font-weight: normal;
            font-size: 12px;
            line-height: 13px;
            text-align: left;
            border: 0;
            border-bottom: 0.5px solid #DADADA;
            &:first-child {
              padding-left: 0;
              padding-right: 0;
              width: 31px;
              border-bottom-color: #fff;

              @media (max-width: 767px) {
                width: 25px;
              }
            }
            &:last-child {
              padding-left: 0;
              padding-right: 0;
              width: 37px;
              border-bottom-color: #fff;

              @media (max-width: 767px) {
                width: 0;
              }
            }

            @media (max-width: 767px) {
              &:nth-child(3), &:nth-child(4) {
                display: none;
              }

              border-bottom-color: #efefef;
              padding-right: 25px;
              vertical-align: top;

              &.status {
                text-align: right;
              }
            }
  
            &.data-table-empty {
              text-align: center;
              width: 100% !important;
            }
          }
        }
      }
    }
  }
  &.sub-table {
    margin-top: 0;
    & > .table-block, & > .loader-container + .table-block,
    & > .table-info + .table-block, & > .loader-container + .table-info + .table-block {
      >.table {
        > thead > tr > th {
          border-bottom-color: #aeaeae;
          background: #f9f9f9;
          &:first-child {
            width: 22px;
          }
          &:last-child {
            width: 0;
          }
        }
        > tbody {
          > tr {
            cursor: default;
            > td {
              padding-top: 24px;
              padding-bottom: 24px;
              background: #f9f9f9;

              @media (max-width: 767px) {
                padding-top: 14px;
                padding-bottom: 14px;
              }

              &:first-child {
                width: 22px;
                border-bottom-color: #DADADA;

                @media (max-width: 767px) {
                  width: 0;
                }
              }
              &:last-child {
                width: 0;
                border-bottom-color: #DADADA;
              }
            }
          }
        }
      }
    }
  }
  &:not(.more-rows) {
    .table > tbody > tr:last-child > td {
      border-bottom: 0;
    }
  }
`;

RequestsTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  subTable: PropTypes.bool,
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  viewAllLinkTo: PropTypes.any.isRequired,
  onCloseSearch: PropTypes.func.isRequired
};

export default RequestsTable;
