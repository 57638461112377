import React from 'react';
import PropTypes from 'prop-types';

export const IS_BILLABLE_VALUE_YES = 'true';
export const IS_BILLABLE_VALUE_NO = 'false';
export const IS_BILLABLE_OPTIONS = [{
  value: IS_BILLABLE_VALUE_YES, name: 'Yes'
}, {
  value: IS_BILLABLE_VALUE_NO, name: 'No'
}];

export const getIsBillableOptionByValue = value => {
  return IS_BILLABLE_OPTIONS.find(o => o.value === String(value)) || IS_BILLABLE_OPTIONS[1]/*No*/;
};

export const IsBillableCell = ({value, className}) => (
  <td className={className}>
    {getIsBillableOptionByValue(value).name}
  </td>
);

IsBillableCell.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string
};
