import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import PageComponent from '../../components/PageComponent';
import AppLayout from '../../layouts/AppLayout';
import {Grid} from 'react-bootstrap';
import qs from 'qs';
import {
  clearReportDetails, getReportIssues, discussIssue, fixNotesOfIssue, scrollToIssueComment, getIssueCommentsModal,
  showDiscussModal, showFixNotesModal, showFixListModal, showSubmitReplacementModal, changeIssueTableFilters, addFilter,
  setListViewActiveTab, changeFilterType, updateReportIssuesFilters, setTopActiveTab, changeVideoParams, getReport,
  showEmailReportModal, showAddIssueModal, showIssueImageModal, getReportDetails, getReportIssuesFilters, clearFilters,
  closeGeneralComments, showImportCsvIssuesModal, importCsvIssues, deleteIssue, toggleImportIssuesModal,
  undeleteIssue, exportReport
} from './actions';
import Loader from '../../components/Loader';
import Title from './components/Title';
import ResultMessage from './components/ResultMessage';
import ViewTabs from './components/tabs/ViewTabs';
import Sidebar from './components/sidebar/Sidebar';
import FixListModal from './components/modals/FixListModal';
import IssueDiscussModal from './components/modals/IssueDiscussModal';
import IssueFixNotesModal from './components/modals/IssueFixNotesModal';
import SubmitReplacementModal from './components/modals/SubmitReplacementModal';
import {toggleShowSidebar} from '../../layouts/actions';
import EmailReportModal from './components/modals/EmailReportModal';
import TimelineCollapse from './components/timeline/TimelineCollapse';
import VersionDetails from './components/VersionDetails';
import AddIssueModal from './components/modals/AddIssueModal';
import IssueImageModal from './components/modals/IssueImageModal';
import GeneralCommentsModal from './components/modals/GeneralCommentsModal';
import ImportCsvIssuesModal from './components/modals/ImportCsvIssuesModal';
import ImportIssuesModal from './components/modals/ImportIssuesModal';
import {isOperatorUser, isManagerUser} from '../../utils';
import {confirmAlert} from 'react-confirm-alert';
import PrintReportModal from "./components/modals/PrintReportModal";

class ReportDetails extends PageComponent {
  state = {
    showPrintReportModal: false
  };

  UNSAFE_componentWillMount() {
    const {dispatch, history, match, urlFilters} = this.props;
    const requestId = match.params.requestId;
    dispatch(getReport(history, requestId, urlFilters));
    document.onkeydown = this.handleKeyDown;
  }

  componentWillUnmount() {
    const {dispatch} = this.props;
    dispatch(clearReportDetails());
  };

  isEnabledInteractiveView = () => {
    const {interactive_report} = this.props.reportdetails.details;
    return Boolean(interactive_report);
  };

  handleClickAddIssue = () => {
    const {dispatch, reportdetails} = this.props;
    if (!reportdetails.showAddIssueModal) {
      try {
        const pauseBtn = document.getElementsByClassName('video-container')[0].getElementsByClassName('controls')[0].getElementsByClassName('pause')[0];
        if (pauseBtn) {
          pauseBtn.click();
        }
      } catch {}
      dispatch(showAddIssueModal(true));
      dispatch(changeVideoParams({isChangedSelecting: true}));
    }
  };

  handleClickEditIssue = (issue) => {
    const {dispatch} = this.props;
    dispatch(showAddIssueModal(true, issue));
  };

  handleClickDeleteIssue = (issue) => {
    const {dispatch, history} = this.props;
    confirmAlert({
      title: 'Are You Sure?',
      message: 'Are you sure you want to delete this issue?',
      buttons: [{
        label: 'No'
      }, {
        label: 'Yes',
        onClick: () => dispatch(deleteIssue(history, issue))
      }]
    });
  };

  handleClickUndeleteIssue = (issue) => {
    const {dispatch, history} = this.props;
    confirmAlert({
      title: 'Are You Sure?',
      message: 'Are you sure you want to undelete this issue?',
      buttons: [{
        label: 'No'
      }, {
        label: 'Yes',
        onClick: () => dispatch(undeleteIssue(history, issue))
      }]
    });
  };

  handleKeyDown = event => {
    event = event || window.event;

    const ESC = 27;
    const N = 78;

    if (isOperatorUser()) {
      const isMacPlatform = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
      if (event.keyCode === N && ((!isMacPlatform && event.altKey) || (isMacPlatform && event.ctrlKey))) {
        this.handleClickAddIssue();
      }
    }

    if (event.keyCode === ESC && (event.target.nodeName !== 'INPUT' || event.target.name !== 'comment')) {
      const {dispatch, reportdetails} = this.props;
      if (reportdetails.showFixNotesModal) {
        this.handleCloseFixNotesModal();
      } else if (reportdetails.showDiscussModal) {
        this.handleCloseDiscussModal();
      } else if (reportdetails.showSubmitReplacementModal) {
        dispatch(showSubmitReplacementModal(false));
      } else if (reportdetails.showFixListModal) {
        dispatch(showFixListModal(false));
      } else if (reportdetails.showEmailReportModal) {
        dispatch(showEmailReportModal(false));
      } else if (reportdetails.showAddIssueModal) {
        dispatch(showAddIssueModal(false));
      } else if (reportdetails.showIssueImageModal) {
        dispatch(showIssueImageModal());
      } else if (reportdetails.generalComments.show) {
        dispatch(closeGeneralComments());
      } else if (reportdetails.showImportCsvIssuesModal) {
        dispatch(showImportCsvIssuesModal(false));
      } else if (reportdetails.showImportIssuesModal) {
        dispatch(toggleImportIssuesModal(false));
      }
    }
  };

  handleClickDiscussIssue = (issue, commentId) => {
    const {dispatch} = this.props;
    dispatch(getIssueCommentsModal(issue, commentId));
  };

  handleClickFixNotesIssue = issue => {
    const {dispatch} = this.props;
    dispatch(showFixNotesModal(true, issue));
  };

  handleSendFixNotesIssue = values => {
    const {dispatch, reportdetails, history} = this.props;
    dispatch(fixNotesOfIssue(reportdetails.modalIssue, values, history));
  };

  handleSendDiscussIssue = comment => {
    const {dispatch, reportdetails} = this.props;
    return dispatch(discussIssue(reportdetails.modalIssue, comment));
  };

  handleClickCreateFixList = () => {
    const {dispatch} = this.props;
    dispatch(showFixListModal(true));
  };

  handleAddFilter = filter => {
    const {dispatch} = this.props;
    dispatch(addFilter(filter));
  };

  handleChangeFilters = (filterName, value) => {
    const {dispatch, history, match, reportdetails} = this.props;
    dispatch(changeIssueTableFilters(filterName, value));
    dispatch(getReportIssues(history, reportdetails.activeListViewTab, match.params.requestId));

    this.notToggleSidebar();
  };

  handleChangeFilterType = (filterName, newFilterName, newFilterLabel) => {
    const {dispatch, history, match, reportdetails} = this.props;
    dispatch(changeFilterType(filterName, newFilterName, newFilterLabel));
    dispatch(getReportIssues(history, reportdetails.activeListViewTab, match.params.requestId));

    this.notToggleSidebar();
  };

  handleCloseFixNotesModal = () => {
    const {dispatch} = this.props;
    dispatch(showFixNotesModal(false));
  };

  handleCloseDiscussModal = () => {
    const {dispatch} = this.props;
    dispatch(showDiscussModal(false));
  };

  handleClickSubmitReplacement = () => {
    const {dispatch, reportdetails} = this.props;
    const {
      IsStudioRequest, IsProductionRequest, IsFinishingHouseRequest, StudioID, ProductionID, FinishingHouseID
    } = reportdetails.details;
    if (isManagerUser() && IsStudioRequest === 0 && !IsProductionRequest && !IsFinishingHouseRequest) {
      const isValidProductionID = Array.isArray(ProductionID) && ProductionID.length === 1 && !!ProductionID[0];
      const isValidFinishingHouseID = Array.isArray(FinishingHouseID) && FinishingHouseID.length === 1 && !!FinishingHouseID[0];
      if (isValidProductionID || isValidFinishingHouseID) {
        confirmAlert({customUI: ({onClose}) => (
          <div className="react-confirm-alert-body replacement-company-type-screen">
            <h1>Create replacement</h1>
            <div>
              for
            </div>
            <div className="react-confirm-alert-button-group">
              <button
                onClick={() => {
                  dispatch(showSubmitReplacementModal(true, {cs_studio_id: StudioID}));
                  onClose();
                }}
              >
                Studio
              </button>
              {
                isValidProductionID &&
                  <button
                    onClick={() => {
                      dispatch(showSubmitReplacementModal(true, {cs_production_id: ProductionID[0]}));
                      onClose();
                    }}
                  >
                    Production
                  </button>
              }
              {
                isValidFinishingHouseID &&
                  <button
                    onClick={() => {
                      dispatch(showSubmitReplacementModal(true, {cs_finishing_house_id: FinishingHouseID[0]}));
                      onClose();
                    }}
                  >
                    Post Partner
                  </button>
              }
            </div>
          </div>
        )});
      } else {
        dispatch(showSubmitReplacementModal(true, {cs_studio_id: StudioID}));
      }
    } else {
      dispatch(showSubmitReplacementModal(true));
    }
  };

  handleChangeListViewTab = key => {
    if (key !== 'select') {
      const {dispatch, reportdetails, match, history} = this.props;
      dispatch(setListViewActiveTab(history, reportdetails.requestId, key));
      dispatch(getReportIssues(history, key, match.params.requestId));
      dispatch(updateReportIssuesFilters(key));

      this.notToggleSidebar();
    }
  };

  handleScrollToIssueComment = () => {
    const {dispatch} = this.props;
    dispatch(scrollToIssueComment());
  };

  handleScrollToIssueOnSidebar = issue => {
    const issuesContainerEl = document.getElementsByClassName('sidebar-comments-list')[0];
    const issueEl = document.getElementsByClassName(`issue-${issue.IssueID}`)[0];
    if (issuesContainerEl && issueEl) {
      const expandIssuesForCaptionCueIfNeeded = issue => {
        try {
          const issueParentEl = issueEl.parentElement;
          if (issueParentEl.classList.contains('issues-for-caption-cue') &&
              issueParentEl.classList.contains('collapse') && !issueParentEl.classList.contains('in')) {
            issueParentEl.parentElement.getElementsByClassName('toggle-issues-for-caption-cue')[0].click();
            return true;
          }
        } catch {}
        return false;
      };
      const wasExpanded = expandIssuesForCaptionCueIfNeeded(issue);
      const headerHeight = document.getElementsByClassName('nav navbar-nav')[0].clientHeight;
      const scrollTop = () => issuesContainerEl.scrollTop = issueEl.offsetTop - headerHeight + 5;
      if (wasExpanded) {
        setTimeout(scrollTop, 360);
      } else {
        scrollTop();
      }
    }
  };

  handleClickViewIssue = issue => {
    const {dispatch, history, match} = this.props;
    dispatch(setTopActiveTab(history, match.params.requestId, 2));
    dispatch(toggleShowSidebar(true));
    dispatch(changeVideoParams({selectedIssue: issue, isChangedSelecting: true}));
    this.handleScrollToIssueOnSidebar(issue);
  };

  notToggleSidebar = () => {
    const {dispatch, main} = this.props;
    if (main.sidebarIsOpen) {
      dispatch(toggleShowSidebar(true));
    }
  };

  handleClickCreateEmailReport = () => {
    const {dispatch} = this.props;
    dispatch(showEmailReportModal(true));
  };

  handleClickPrintReport = () => {
    this.setState({
      showPrintReportModal: true
    });
  };

  handleClosePrintReportModal = () => {
    this.setState({
      showPrintReportModal: false
    });
  };

  handleConfirmPrintReport = (includeFixedIssues, includeIssuesComments) => {
    const {dispatch, match} = this.props;
    const requestId = match.params.requestId;
    dispatch(exportReport(requestId, includeFixedIssues, includeIssuesComments));

    this.setState({
      showPrintReportModal: false
    });
  };

  handleVersionChanged = (_, originRequestId) => {
    const {reportdetails} = this.props;
    const version = reportdetails.details.versions && reportdetails.details.versions.find(v => v.VersionID === originRequestId);
    this.handleRequestChanged(version.RequestID);
  };

  handleRequestChanged = requestId => {
    const {dispatch, history} = this.props;
    history.push(`/requests/${requestId}`);
    dispatch(clearFilters());
    dispatch(getReportDetails(history, requestId));
    dispatch(getReportIssues(history, requestId, requestId));
    dispatch(getReportIssuesFilters(requestId));
  };

  handleClickIssueImage = issue => {
    const {dispatch} = this.props;
    dispatch(showIssueImageModal(issue));
  };

  handleOpenImportCsvIssuesModal = () => {
    const {dispatch} = this.props;
    dispatch(showImportCsvIssuesModal(true));
  };

  handleCloseImportCsvIssuesModal = () => {
    const {dispatch} = this.props;
    dispatch(showImportCsvIssuesModal(false));
  };

  handleSubmitImportCsvIssues = (csvFile) => {
    const {dispatch, match, history} = this.props;
    const requestId = match.params.requestId;
    dispatch(importCsvIssues(requestId, csvFile, history));
  };

  renderModals() {
    const {reportdetails, match} = this.props;
    const {showPrintReportModal} = this.state;
    const {saving, imageModalIssue, generalComments} = reportdetails;
    if (reportdetails.showImportIssuesModal) {
      return <ImportIssuesModal/>;
    }
    return (
      <>
        {reportdetails.showFixListModal ?
          <FixListModal
            onSubmit={this.handleClickSubmitReplacement}
            onEmailReport={this.handleClickCreateEmailReport}
            onClickFixNotes={this.handleClickFixNotesIssue}
            onClickDiscuss={this.handleClickDiscussIssue}
            onClickViewIssue={this.handleClickViewIssue}
            onClickIssueImage={this.handleClickIssueImage}
            isEnabledInteractiveView={this.isEnabledInteractiveView()}
          /> : null
        }
        {reportdetails.showAddIssueModal ?
          <AddIssueModal
            currentIssue={reportdetails.modalIssue}
          /> : null
        }
        {reportdetails.showSubmitReplacementModal ?
          <SubmitReplacementModal
            onClickFixNotes={this.handleClickFixNotesIssue}
            onClickDiscuss={this.handleClickDiscussIssue}
            onClickViewIssue={this.handleClickViewIssue}
            onClickIssueImage={this.handleClickIssueImage}
            isEnabledInteractiveView={this.isEnabledInteractiveView()}
          /> : null
        }
        {reportdetails.showEmailReportModal ?
          <EmailReportModal
            onClickFixNotes={this.handleClickFixNotesIssue}
            onClickDiscuss={this.handleClickDiscussIssue}
            onClickViewIssue={this.handleClickViewIssue}
            onClickIssueImage={this.handleClickIssueImage}
            isEnabledInteractiveView={this.isEnabledInteractiveView()}
            /> : null
        }
        {reportdetails.showDiscussModal ?
          <IssueDiscussModal
            visible={reportdetails.showDiscussModal}
            saving={saving}
            loading={reportdetails.loadingIssueComments}
            currentIssue={reportdetails.modalIssue}
            currentIssueCommentId={reportdetails.modalIssueCommentId}
            currentIssueClientStatusId={reportdetails.modalIssueClientStatusId}
            onScrollToComment={this.handleScrollToIssueComment}
            onSend={this.handleSendDiscussIssue}
            onClose={this.handleCloseDiscussModal}
            requestId={+match.params.requestId}
          /> : null
        }
        {reportdetails.showFixNotesModal ?
          <IssueFixNotesModal
            saving={saving}
            reasons={reportdetails.filters.approve_reason || []}
            currentIssue={reportdetails.modalIssue}
            onSend={this.handleSendFixNotesIssue}
            onClose={this.handleCloseFixNotesModal}
          /> : null
        }
        {imageModalIssue.issue ? <IssueImageModal/> : null}
        {generalComments.show ? <GeneralCommentsModal/> : null}
        {reportdetails.showImportCsvIssuesModal &&
          <ImportCsvIssuesModal
            onClose={this.handleCloseImportCsvIssuesModal}
            onSubmit={this.handleSubmitImportCsvIssues}
            uploading={saving}
          />
        }
        {showPrintReportModal &&
          <PrintReportModal
            onClose={this.handleClosePrintReportModal}
            onConfirm={this.handleConfirmPrintReport}
          />
        }
      </>
    );
  }

  render() {
    const {dispatch, reportdetails, match, history, main} = this.props;
    const requestId = match.params.requestId;

    const sidebar = (
      <Sidebar
        reportTitle={reportdetails.details.Title}
        onClickDeleteIssue={this.handleClickDeleteIssue}
        onClickUndeleteIssue={this.handleClickUndeleteIssue}
        showClosedCaptionsBtn={Boolean(reportdetails.details.caption_cues && reportdetails.details.caption_cues.length)}
      />
    );

    return (
      <AppLayout
        className="without-main-header"
        title="Report Details"
        sidebar={sidebar}
        >
        {
          (reportdetails.loading && !reportdetails.details.Title) ? <Loader/> : (
            <StyledReportDetails>
              <Grid id="report-details--container">
                {(reportdetails.completing || reportdetails.isAttachmentDownloading) ? <Loader className="full-screen"/> : null}
                {this.renderModals()}
                <Title
                  data={reportdetails.details}
                  timeline={reportdetails.timeline}
                  isDisabledAddIssue={reportdetails.showAddIssueModal}
                  onClickAddIssue={this.handleClickAddIssue}
                  onClickCreateFixList={this.handleClickCreateFixList}
                  onClickSubmitReplacement={this.handleClickSubmitReplacement}
                  onClickCreateEmailReport={this.handleClickCreateEmailReport}
                  onClickImportCsvIssues={this.handleOpenImportCsvIssuesModal}
                  onClickPrintReport={this.handleClickPrintReport}
                  onSelectVersion={this.handleVersionChanged}
                  loading={reportdetails.loading || reportdetails.completing || reportdetails.issueFiltersLoading || reportdetails.issuesLoading || reportdetails.loaders.generalComments}
                  loadingGeneralComments={reportdetails.loaders.generalComments}
                  isInteractiveView={reportdetails.activeTopTab === 2}
                  issuesLoading={reportdetails.issuesLoading}
                  />
                {reportdetails.details.Title ?
                  <ResultMessage
                    requestStatus={reportdetails.details.RequestStatus}
                    qcStatus={reportdetails.details.QcStatus}
                    qcStatusId={reportdetails.details.QcStatusID}
                    /> : null}
                <TimelineCollapse
                  requestId={requestId}
                  onSelectRequest={this.handleRequestChanged}
                />
                <VersionDetails
                  data={reportdetails.details}
                />
                <ViewTabs
                  dispatch={dispatch}
                  history={history}
                  sidebarIsOpen={main.sidebarIsOpen}
                  requestId={requestId}
                  reportdetails={reportdetails}
                  isEnabledInteractiveView={this.isEnabledInteractiveView()}
                  onClickFixNotes={this.handleClickFixNotesIssue}
                  onClickDiscuss={this.handleClickDiscussIssue}
                  onClickViewIssue={this.handleClickViewIssue}
                  onClickIssueImage={this.handleClickIssueImage}
                  onClickEditIssue={this.handleClickEditIssue}
                  onClickDeleteIssue={this.handleClickDeleteIssue}
                  onClickUndeleteIssue={this.handleClickUndeleteIssue}
                  onChangeFilters={this.handleChangeFilters}
                  onChangeFilterType={this.handleChangeFilterType}
                  onAddFilter={this.handleAddFilter}
                  onChangeListViewTab={this.handleChangeListViewTab}
                  onScrollToIssueOnSidebar={this.handleScrollToIssueOnSidebar}
                />
              </Grid>
            </StyledReportDetails>
          )
        }
      </AppLayout>
    );
  }
}

const StyledReportDetails = styled.div`
  background-color: #fff;
  min-height: calc(100vh - 59px);
  padding-top: 15px;
  padding-bottom: 10px;
  position: relative;

  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 3;

    .react-spinner {
      top: 49%;
    }
  }

  #report-details--container.harding-test--report {
    min-height: calc(100vh - 59px - 25px - 10px);
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      min-height: 100vh;
    }
  }
`;

ReportDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  reportdetails: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    reportdetails: state.reportdetails,
    user: state.user,
    main: state.main,
    urlFilters: qs.parse(ownProps.location.search.substr(1))
  };
}

export default connect(
  mapStateToProps
)(withRouter(ReportDetails));
