import $ from 'jquery';
import config from '../../../../../config';
import {getErrorMessage} from '../../../../utils';
import {showError, showSuccess} from '../../../../layouts/actions';

export const ARTWORK_ADMIN_SPECS_IS_LOADING = 'ARTWORK_ADMIN_SPECS_IS_LOADING';
export const ARTWORK_ADMIN_SPECS_IS_SAVING = 'ARTWORK_ADMIN_SPECS_IS_SAVING';
export const ARTWORK_ADMIN_SPECS_SET_ACTIVE_TAB = 'ARTWORK_ADMIN_SPECS_SET_ACTIVE_TAB';
export const ARTWORK_ADMIN_SPECS_SET_SPEC_MODAL_DATA = 'ARTWORK_ADMIN_SPECS_SET_SPEC_MODAL_DATA';
export const ARTWORK_ADMIN_SPECS_RECEIVE = 'ARTWORK_ADMIN_SPECS_RECEIVE';
export const ARTWORK_ADMIN_SPECS_DELETE = 'ARTWORK_ADMIN_SPECS_DELETE';
export const ARTWORK_ADMIN_SPECS_UPDATE = 'ARTWORK_ADMIN_SPECS_UPDATE';
export const ARTWORK_ADMIN_SPECS_IMPORT_ITEMS_MODAL = 'ARTWORK_ADMIN_SPECS_IMPORT_ITEMS_MODAL';

export const setLoading = loading => ({type: ARTWORK_ADMIN_SPECS_IS_LOADING, loading});

export const setActiveTab = activeTab => ({type: ARTWORK_ADMIN_SPECS_SET_ACTIVE_TAB, activeTab});

const setSpecModalData = data => ({type: ARTWORK_ADMIN_SPECS_SET_SPEC_MODAL_DATA, data});

export const openSpecModal = (specData, selectedVersion) => dispatch => {
  dispatch(setSpecModalData({...specData, selectedVersion}));
};

export const openSpecNameModal = specData => dispatch => {
  dispatch(setSpecModalData({...specData, editField: 'name'}));
};

export const openSpecParamModal = specData => dispatch => {
  dispatch(setSpecModalData({...specData, editField: 'param_option'}));
};

export const openSpecSafeZoneTemplate = specData => dispatch => {
  dispatch(setSpecModalData({...specData, isSafeZoneTemplate: true}));
};

export const openQcInstructions = specData => dispatch => {
  dispatch(setSpecModalData({...specData, isQcInstructions: true}));
};

export const closeSpecModal = () => dispatch => {
  dispatch(setSpecModalData(null));
};

export const getSpecs = () => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_LOADING,
    loading: true
  });
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs`
  })
  .done(res => {
    const {studios, specs, params, metadata_dictionaries} = res;
    const {conditions, fields, statuses} = metadata_dictionaries || {};
    dispatch({
      type: ARTWORK_ADMIN_SPECS_RECEIVE,
      metadataDictionaries: {
        conditions: conditions || [],
        fields: fields || [],
        statuses: statuses || [],
      },
      studios,
      params,
      specs
    });
  })
  .fail(error => {
    dispatch(showError(`Could not get specs.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_LOADING,
      loading: false
    });
  });
};

export const deleteSpec = specData => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/${specData.specID}`
  })
  .done(() => {
    dispatch(showSuccess('Spec has been successfully deleted'));
    dispatch({type: ARTWORK_ADMIN_SPECS_DELETE, specData});
  })
  .fail(error => {
    const message = getErrorMessage(error);
    if (message && message.toLowerCase().indexOf(' is used') !== -1) {
      dispatch(getSpecs());
    }
    dispatch(showError(`Could not delete spec.  ${message}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const deleteSpecVersion = versionId => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/version/${versionId}`
  })
  .done(() => {
    dispatch(showSuccess('Spec version has been successfully deleted'));
  })
  .fail(error => {
    dispatch(showError(`Could not delete spec version.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const getSpecVersion = (versionId, versionName) => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_LOADING,
    loading: true
  });
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/version/${versionId}`
  })
  .fail(error => {
    dispatch(showError(`Could not get "${versionName}" spec.  ${getErrorMessage(error)}`));
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_LOADING,
      loading: false
    });
  });
};

export const saveSpecVersion = (versionId, specItems) => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/version/${versionId}`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(specItems)
  })
  .done(() => {
    dispatch(showSuccess(`Spec version has been successfully updated`));
  })
  .fail(error => {
    dispatch(showError(`Could not save spec version.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const saveSpecVersionAsNew = (specId, specItems) => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/${specId}/new_version`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(specItems)
  })
  .done(() => {
    dispatch(showSuccess(`Spec version has been successfully created`));
  })
  .fail(error => {
    dispatch(showError(`Could not save spec version.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const saveNewSpec = (studioId, specName, specItems) => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/new`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      studio_id: studioId,
      name: specName,
      items: specItems
    })
  })
  .done(() => {
    dispatch(showSuccess(`New spec has been successfully created`));
  })
  .fail(error => {
    dispatch(showError(`Could not create new spec.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const saveSpecField = specData => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  let name, value;
  if (specData.editField === 'name') {
    name = 'name';
    value = specData.specName;
  } else {
    name = 'param_option_id';
    value = specData.paramOptionID;
  }
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/${specData.specID}/${specData.editField}`,
    data: {
      [name]: value
    }
  })
  .done(() => {
    dispatch(showSuccess('Spec has been successfully updated'));
  })
  .fail(error => {
    dispatch(showError(`Could not update spec.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const saveSpecSafeZoneTemplate = (spec, file) => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  const data = new FormData();
  data.append('file', file);
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/${spec.specID}/safe_zone_template`,
    data,
    cache: false,
    processData: false,
    contentType: false
  })
  .done(specSafeZoneTemplateUrl => {
    dispatch(closeSpecModal());
    dispatch(showSuccess('Safe zone template has been successfully saved'));
    dispatch({type: ARTWORK_ADMIN_SPECS_UPDATE, spec, values: {specSafeZoneTemplateUrl}});
  })
  .fail(error => {
    dispatch(showError(`Could not save safe zone template.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const removeSpecSafeZoneTemplate = spec => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/${spec.specID}/safe_zone_template`
  })
  .done(() => {
    dispatch(closeSpecModal());
    dispatch(showSuccess('Safe zone template has been successfully removed'));
    dispatch({type: ARTWORK_ADMIN_SPECS_UPDATE, spec, values: {specSafeZoneTemplateUrl: null}});
  })
  .fail(error => {
    dispatch(showError(`Could not remove safe zone template.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const saveQcInstructions = (spec, text) => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/${spec.specID}/qc_instructions`,
    data: {
      qc_instructions: text
    }
  })
  .done(() => {
    dispatch(closeSpecModal());
    dispatch(showSuccess('QC instructions has been successfully saved'));
    dispatch({type: ARTWORK_ADMIN_SPECS_UPDATE, spec, values: {qcInstructions: text}});
  })
  .fail(error => {
    dispatch(showError(`Could not save QC instructions.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const showImportSpecItemsModal = isShow => ({
  type: ARTWORK_ADMIN_SPECS_IMPORT_ITEMS_MODAL,
  isShow
});

export const importSpecItemsFromCsv = csvFile => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  const data = new FormData();
  data.append('file', csvFile);
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/spec_items`,
    data,
    cache: false,
    processData: false,
    contentType: false
  })
  .done(() => {
    dispatch(showImportSpecItemsModal(false));
    dispatch(getSpecs());
    dispatch(showSuccess('Successfully imported spec items.'));
  })
  .fail(error => {
    dispatch(showError(`Could not import file. ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};
