import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import currentPositionImg from '../../../../../assets/images/video/current-position.svg';
import {Collapse, Button} from 'react-bootstrap';

const CaptionCueActions = React.memo(({handleAddIssueClick}) => {
  return (
    <AdditionalActionsStyled className="additional-actions">
      <Button
        className="caption-add-issue"
        title="Add new issue"
        onClick={handleAddIssueClick}
      >
      + ADD ISSUE
      </Button>
    </AdditionalActionsStyled>
  );
});

class CaptionCue extends React.PureComponent {
  state = {
    isShowAllIssues: true
  };

  handleClickMoreIssues = event => {
    event.stopPropagation();
    this.setState({
      isShowAllIssues: !this.state.isShowAllIssues
    });
  };

  handleClickCue = () => {
    const {cue, onSelectCue} = this.props;
    onSelectCue(cue);
  }

  handleAddIssueClick = (event) => {
    const {cue, onClickAddIssue} = this.props;
    event.stopPropagation();
    onClickAddIssue(cue);
  }

  render() {
    const {isShowAllIssues} = this.state;
    const {cue, isSelected, issues, issuesCount} = this.props;
    const cueClasses = classNames({
      'caption-cue': true,
      [`caption-cue-${cue.CueID}`]: true,
      'selected--caption-cue': isSelected,
      expanded: !!issues && isShowAllIssues
    });

    return (
      <CueStyled
        className={cueClasses}
        onClick={this.handleClickCue}
      >
        <BorderStyled className={`content`}>
          <div className="time-code">
            {`${cue.TimeCodeStart} - ${cue.TimeCodeEnd}`}
          </div>
          <CaptionCueActions
            handleAddIssueClick={this.handleAddIssueClick}
          />
          <div className="description">
            {(cue.Text || '')
              .split(/\n/g)
              .map((t, ti) => <Fragment key={`ct--${cue.CueID}--${ti}`}>{ti > 0 && <br/>}{t}</Fragment>)
            }
          </div>
          {issues ?
            <Button
              className="toggle-issues-for-caption-cue"
              onClick={this.handleClickMoreIssues}
            >
              <i className={`fas fa-chevron-${isShowAllIssues ? 'down' : 'right'} fa-xs fa-fw`} />
              &nbsp;{`${issuesCount} issue${issuesCount === 1 ? '' : 's'}`}
            </Button> : null
          }
        </BorderStyled>
        <div className='currentPosition'>Current Position</div>
        {issues ?
          <Collapse in={isShowAllIssues}>
            <div className="issues-for-caption-cue">
              {issues}
            </div>
          </Collapse> : null
        }
      </CueStyled>
    );
  }
}

const BorderStyled = styled.div`
  --caption-cue-content-bg-color: #e8f4ff;
  background-color: var(--caption-cue-content-bg-color);
  box-shadow: inset 0 0 4px rgba(0,0,0,0.1);
  position: relative;
  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 3px 0;
    border-width: 0 0 12px 12px;
    border-style: solid;
    border-color: #cfe6fb var(--layouts-sidebar-bg-color);
    /*box-shadow: 0 0.3px 0 rgba(0,0,0,0.3);*/
  }
  i {
    &.fa-plus-circle {
      background-color: #fff;
      border-radius: 50%;
      box-shadow: inset 0 0 0 3px/*padding*/ var(--caption-cue-content-bg-color);
    }
  }
`;

const CueStyled = styled.div`
  border-radius: 3px;
  font-size: 12px;
  margin-top: 15px;
  &.expanded {
    padding-bottom: 15px;
    background-color: #cfe6fb;
  }

  > .issue, > div.issues-for-caption-cue > .issue {
    margin-left: 10px;
    margin-right: 10px;
    background: var(--layouts-sidebar-bg-color);
    .currentPosition {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }

  > .currentPosition {
    display: none;
    background: url(${currentPositionImg}) center left 5px no-repeat;
    background-color: #515151;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: default;
    font-size: 9px;
    outline: none;
    padding: 4px 15px;
    margin-top: 10px;
  }

  .btn {
    border: none;
    font-size: 12px;
    font-weight: bold;
    box-shadow: none;
    outline: none !important;
    padding: 0;
    &:not(:focus) {
      background: none;
    }
    &.toggle-issues-for-caption-cue {
      margin-top: 15px;
      margin-left: -4px;
      padding: 0 4px;
      :hover {
        opacity: 0.85;
      }
    }
  }

  > .content {
    padding: 15px;
    .time-code {
      font-weight: bold;
      color: #000000;
      display: inline-block;
    }
    .description {
      margin-top: 15px;
      opacity: 0.5;
    }
    .additional-actions {
      float: right;
      display: flex;
    }
  }
`;

const AdditionalActionsStyled = styled.div`
  > * {
    &:last-child {
      margin-right: 0;
    }
  }

  .caption-add-issue {
    border: none;
    background-color: transparent;
    box-shadow: none;
    color: #279DC2;
    font-size: 10px;
    font-weight: bold;
    outline: none;
    cursor: pointer;

    :hover {
      color: #228daf;
    }
  }
`;

CaptionCue.propTypes = {
  cue: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelectCue: PropTypes.func.isRequired,
  onClickAddIssue: PropTypes.func.isRequired,
  issuesCount: PropTypes.number,
  issues: PropTypes.any,
};

export default CaptionCue;