import {getErrorMessage, isManagerUser, isOperatorUser} from '../../utils';
import $ from 'jquery';
import config from '../../../config';
import qs from "qs";
import {showError, showSuccess} from "../../layouts/actions";
import moment from "moment";

export const REQUESTS_CLEAR = 'REQUESTS_CLEAR';
export const REQUESTS_SET_LOADING = 'REQUESTS_SET_LOADING';
export const REQUESTS_GET_FILTERS = 'REQUESTS_GET_FILTERS';
export const REQUESTS_CHANGE_FILTER = 'REQUESTS_CHANGE_FILTER';
export const REQUESTS_CHANGE_FILTER_TYPE = 'REQUESTS_CHANGE_FILTER_TYPE';
export const REQUESTS_GET_REQUESTS = 'REQUESTS_GET_REQUESTS';
export const REQUESTS_ADD_FILTERS = 'REQUESTS_ADD_FILTERS';
export const REQUESTS_SAVED_FILTER = 'REQUESTS_SAVED_FILTER';
export const REQUESTS_CHANGE_QUICK_UPDATE_VALUES = 'REQUESTS_CHANGE_QUICK_UPDATE_VALUES';
export const REQUESTS_CLOSE_QUICK_UPDATE = 'REQUESTS_CLOSE_QUICK_UPDATE';

export const clearAll = () => ({type: REQUESTS_CLEAR});

export const updateQuickUpdateDate = values => dispatch => {
  dispatch({type: REQUESTS_CHANGE_QUICK_UPDATE_VALUES, values});
};

export const getQuibiProjectForQuickUpdateDate = request => (dispatch, getState) => {
  const {RequestID, QcProjectID, Textless} = request;
  dispatch({type: REQUESTS_SET_LOADING, name: 'quickUpdate', loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/quibi_qc_project`
  })
  .done(res => {
    dispatch(updateQuickUpdateDate({
      show: true,
      QcProjectID,
      Textless,
      ...res
    }));
  })
  .fail(error => {
    dispatch({type: REQUESTS_SET_LOADING, name: 'quickUpdate', loading: false});
    dispatch(showError(`Could not get Quibi project data.  ${getErrorMessage(error)}`));
  });
};

export const closeQuickUpdates = () => ({
  type: REQUESTS_CLOSE_QUICK_UPDATE
});

export const saveQuickUpdates = () => (dispatch, getState) => {
  const {quickUpdateDate} = getState().requests;
  const {QcProjectID, Notes} = quickUpdateDate;
  dispatch({type: REQUESTS_SET_LOADING, name: 'quickUpdate', loading: true});

  const values = {
    qcProjectID: QcProjectID,
    notes: Notes || null
  };
  ['UploadedOn', 'TextlessHorizontalReceived', 'TextlessVerticalReceived', 'V3_HorizontalReceived',
   'V3_VerticalReceived', 'V2_HorizontalReceived', 'V2_VerticalReceived', 'V1_HorizontalReceived',
   'V1_VerticalReceived', 'V1_HorizontalScheduled', 'V1_VerticalScheduled'
  ].forEach(name => {
    const value = quickUpdateDate[name];
    values[name] = value ? moment.utc(value, "YYYY-MM-DDTHH:mm:ss.sssZ").format("YYYY-MM-DD HH:mm:ss") : null;
  });

  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/quibi_qc_project`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(values)
  })
  .done(() => {
    dispatch(closeQuickUpdates());
    dispatch(showSuccess('The request has been updated.'));
  })
  .fail(error => {
    dispatch({type: REQUESTS_SET_LOADING, name: 'quickUpdate', loading: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

const getValidFilter = (allFilters, filter, value) => {
  const allowedFilters = allFilters ? Object.keys(allFilters) : [];
  const isExist = allowedFilters.includes(filter);

  if (isExist) {
    const existFilter = allFilters[filter] || {};

    const existFilterValues = existFilter ? existFilter.map(f => f.value) : [];
    const isStrValue = ['season_num', 'episode_num'].includes(filter);
    return Array.isArray(value) ?
      value.filter(item => existFilterValues.includes(!isStrValue && !isNaN(+item) ? +item : item)).map(item => (!isStrValue && !isNaN(+item) ? +item : item)) :
      null;
  }

  return null;
};

const getValidFilters = (allFilters, urlFilters) => {
  const res = {};

  const filters = {...urlFilters};
  delete filters.tab;
  delete filters.viewTab;

  filters && Object.keys(filters).forEach(key => {

    if (key === 'sort') {
      res[key] = {
        field: filters[key],
        direction: filters.sortDirect === 'desc' ? 'desc' : 'asc'
      }
    } else if (key !== 'search') {
      let validFilter = getValidFilter(allFilters, key, filters[key]);
      if (!validFilter) {
        const othFilters = {};
        allFilters.filters.forEach(item => {
          othFilters[item.name] = item.values;
        });
        validFilter = getValidFilter(othFilters, key, filters[key]);
      }
      if (validFilter) {
        res[key] = validFilter;
      }
    } else {
      res[key] = filters[key];
    }
  });

  return res;
};

export const getValidFiltersForInitSearch = (urlFilters) => {
  const res = {};

  const filters = {...urlFilters};
  delete filters.tab;
  delete filters.viewTab;

  filters && Object.keys(filters).forEach(key => {

    if (key === 'sort') {
      res[key] = {
        field: filters[key],
        direction: (filters.sortDirect || filters.sortdirect) === 'desc' ? 'desc' : 'asc'
      }
    } else if (!['sortDirect', 'sortdirect'].includes(key)) {
      res[key] = filters[key];
    }
  });

  return res;
};

const DASHBOARD_FILTERS_ARR = [
  'from', 'to', 'state', 'period', 'date_type',
  'season_num', 'episode_num', 'req_version', 'req_revision',
];

const prepareSingleFiltersParams = filters => {
  const preparedFilters = {...filters};
  [...DASHBOARD_FILTERS_ARR, 'last_updated'].forEach(key => {
    if (Array.isArray(preparedFilters[key])) {
      preparedFilters[key] = preparedFilters[key].length > 0 ? preparedFilters[key][0] : null;
    }
  });
  return preparedFilters;
};

const loadFilters = (history, requestsFilters, srcFilters, noStatistics) => (dispatch, getState) => {
  let urlSuffix = 'requests/requests/filters';
  if (isManagerUser()) {
    urlSuffix = 'css/requests/filters';
  } else if (isOperatorUser()) {
    urlSuffix = 'operator/requests/filters';
  }

  const ajaxRes = $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/${urlSuffix}`,
    data: {
      filters: JSON.stringify(prepareSingleFiltersParams(requestsFilters)),
      no_statistics: noStatistics
    }
  });

  if (noStatistics === false) {
    return ajaxRes
      .then(res => {
        dispatch({type: REQUESTS_GET_FILTERS, allowedFilters: res.filters, noStatistics});
      });
  }

  return ajaxRes
    .then(res => {
      const validUrlFilters = getValidFilters(res, srcFilters);
      const urlParams = {};
      const someFilters = {};
      Object.keys(validUrlFilters).forEach(filter => {
        if (['search', 'sort'].includes(filter)) {
          urlParams[filter] = validUrlFilters[filter];
        } else {
          someFilters[filter] = validUrlFilters[filter];
        }
      });
      dispatch(savedAllFilters(urlParams));
      return {allowedFilters: res.filters, someFilters};
    })
    .then(({allowedFilters, someFilters}) => {
      dispatch({type: REQUESTS_GET_FILTERS, allowedFilters, noStatistics});

      const someFiltersKeys = Object.keys(someFilters).sort((a, b) => {
        if (DASHBOARD_FILTERS_ARR.includes(a)) {
          if (a === 'to' && b === 'from') {
            return 1;
          }
          return -1;
        }
        if (DASHBOARD_FILTERS_ARR.includes(b)) {
          return 1;
        }
        return a.localeCompare(b);
      });
      someFiltersKeys.forEach(filterName => {
        const filter = allowedFilters.find(item => item.name === filterName);
        if (filter) {
          dispatch(addFilter({
            name: filter.name,
            label: filter.title,
            type: filter.type,
            multi: filter.multi,
            isOrderedValues: filter.isOrderedValues,
            defaultValue: someFilters[filterName],
            disabled: true
          }));
        }
      });

      const requiredFilters = allowedFilters.filter(filter => filter.required);
      requiredFilters.forEach(filter => {
        if (!Object.keys(someFilters).includes(filter.name)) {
          dispatch(addFilter({
            name: filter.name,
            label: filter.title,
            type: filter.type,
            multi: filter.multi,
            isOrderedValues: filter.isOrderedValues,
            defaultValue: filter.defaultValue,
            disabled: true,
            required: true
          }));
        }
      });

      dispatch({type: REQUESTS_SET_LOADING, name: 'filters', loading: false});
    })
    .then(() => getState().requests.selectedFilters)
    .then(selectedFilters => savedState(history, selectedFilters))
    .catch(() => {
      dispatch({type: REQUESTS_SET_LOADING, name: 'filters', loading: false});
    });
};

export const getFilters = (history, requestsFilters, srcFilters) => dispatch => {
  dispatch({type: REQUESTS_SET_LOADING, name: 'filters', loading: true});
  const in2Calls = isManagerUser();
  if (in2Calls) {
    dispatch(loadFilters(history, requestsFilters, srcFilters, true));
  }
  dispatch(loadFilters(history, requestsFilters, srcFilters, in2Calls ? false : undefined));
};

export const savedAllFilters = urlFilters => dispatch => {
  dispatch({type: REQUESTS_SAVED_FILTER, urlFilters});
};

export const changeFilter = (history, name, value) => dispatch => {
  dispatch({type: REQUESTS_CHANGE_FILTER, name, value});
  return dispatch(doSearch(history, null));
};

export const changeFilterType = (history, filterName, newFilterName, newFilterLabel, defaultValue) => dispatch => {
  dispatch({type: REQUESTS_CHANGE_FILTER_TYPE, filterName, newFilterName, newFilterLabel, defaultValue});
  return dispatch(doSearch(history, null));
};

export const addFilter = filter => dispatch => {
  dispatch({type: REQUESTS_ADD_FILTERS, filter});
};

const prepareFiltersParams = filters => {
  if (!filters) {
    return {};
  }
  const filtersObj = {};
  filters.forEach(filter => {
    const name = filter.name.replace(' ', '_').toLowerCase();
    filtersObj[name] = filter.value
  });

  if (filtersObj.sort) {
    const {field, direction} = filtersObj.sort;
    filtersObj.field = field ? field.toLowerCase() : null;
    filtersObj.direction = direction;
    delete filtersObj.sort;
  }
  return prepareSingleFiltersParams(filtersObj);
};

const savedState = (history, selectedFilters) => {
  const filters = {};
  selectedFilters.forEach(filter => {
    if ((Array.isArray(filter.value) && filter.value.length) || filter.value) {
      filters[filter.name] = filter.value;
    }
    if (filter.name === 'sort') {
      filters[filter.name] = filter.value.field;
      filters.sortDirect = filter.value.direction;
    }
  });

  const isRequestsPage = window.location.pathname === '/requests';
  if (isRequestsPage) { //Fixed bug if user clicked Row link before page loaded
    history.replace({
      pathname: `/requests`,
      search: `${qs.stringify(filters)}`
    });
  }

  DASHBOARD_FILTERS_ARR.forEach(key => {
    delete filters[key];
  });
  localStorage.setItem('requestsFilters', JSON.stringify(filters));
};

const getSearchApiUrl = () => {
  if (isManagerUser()) {
    return 'css/requests';
  } else if (isOperatorUser()) {
    return 'operator/requests';
  }
  return 'requests/search';
};

export const doSearch = (history, startedFilters, isLoadMore) => (dispatch, getState) => {
  const state = getState();
  const filtersObj = prepareFiltersParams(state.requests.selectedFilters);
  const start = isLoadMore ? state.requests.data.length : 0;

  !startedFilters && !isLoadMore && savedState(history, state.requests.selectedFilters);

  const loadingName = isLoadMore ? 'more' :'requests' ;
  dispatch({type: REQUESTS_SET_LOADING, name: loadingName, loading: true});

  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/${getSearchApiUrl()}`,
    data: {
      start,
      limit: config.pagingSize,
      filters: JSON.stringify(filtersObj)
    }
  })
    .done(res => {
      let {data, total} = res.requests;
      if (isLoadMore) {
        data = [...state.requests.data, ...data]
      }
      dispatch({type: REQUESTS_GET_REQUESTS, data, total: total});
    })
    .fail(() => {
      dispatch({type: REQUESTS_SET_LOADING, name: loadingName, loading: false});
    });
};

export const getSignedTokenForDownloadFiles = (path, appliedFilters, exportType) => {
  const filters = encodeURIComponent(JSON.stringify(appliedFilters));
  const query = `filters=${filters}&export=${exportType}`;
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/util/download-file`,
    data: {
      path: `/services${path}`,
      query
    }
  })
  .done(res => {
    const token = encodeURIComponent(res.token);
    window.location.href = `${config.apiUrl}${path}?${query}&t=${token}`;
  })
  .fail(() => {});
};

export const upload = (type = 'csv') => (dispatch, getState) => {
  const state = getState();
  const filtersObj = prepareFiltersParams(state.requests.selectedFilters);
  const path = `/v1/qc_on_demand/${getSearchApiUrl()}`;
  getSignedTokenForDownloadFiles(path, filtersObj, type);
};
