/* eslint-disable no-mixed-operators */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {emptyFunction} from '../../../../utils';
import Loader from '../../../../components/Loader';
import BlockHeader from '../../components/BlockHeader';
import PieChart from './PieChart';
import BarChart from '../BarChart';
import ButtonsGroup from '../../components/ButtonsGroup';
import BlockFooter from '../BlockFooter';

class PassPercentage extends React.PureComponent {
  state = {
    period: 3,
    group: 0
  };

  dropdownOptions = [
    {label: "This Year", value: 3},
    {label: "Past 60 days", value: 2},
    {label: "Past 30 days", value: 1}
  ];

  componentDidMount() {
    const {filters} = this.props;
    this.setState({
      period: filters.period,
      group: filters.group
    });
  }

  onPeriodChange = value => {
    const period = value.value;
    this.setState({period});
    this.props.getData(period, this.state.group);
  };

  onGroupChange = group => {
    this.setState({group});
    this.props.getData(this.state.period, group);
  };

  render() {
    const {data, loading, groups} = this.props;
    const {group} = this.state;
    const barChartData =  Array.isArray(data) ? data : [];

    return (
      <StyledPassPercentage className={`box ${group === 0 ? 'nopadding' : ''}`}>
        {loading ? <Loader/> : null}
        <BlockHeader
          title="Pass %"
          dropDown = {{
            value: this.state.period,
            options: this.dropdownOptions,
            onChange: this.onPeriodChange
          }}
          />
        <ButtonsGroup
          btns={[
            {id: 0, title: 'Overall'},
            ...groups
          ]}
          value={group}
          onChange={this.onGroupChange}
        />
        {group === 0 ? <PieChart percentage={data.overall_percentage}/> : null}
        {group === 1 ?
          <Fragment>
            <BarChart data={barChartData} color='#2A7431' showPercent/>
            <BlockFooter onClick={emptyFunction}/>
          </Fragment> : null}
        {(group === 2 || group === 3 || group === 4) ?
          <Fragment>
            <BarChart data={barChartData} color='#84A450' showPercent/>
            <BlockFooter onClick={emptyFunction}/>
          </Fragment> : null}
      </StyledPassPercentage>
    );
  }
}

const StyledPassPercentage = styled.div`
  padding-bottom: 49px;

  @media (min-width: 992px) {
    margin-right: 25px !important;
  }
`;

PassPercentage.defaultProps = {
  data: {}
};

PassPercentage.propTypes = {
  filters: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loading: PropTypes.bool,
  getData: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired
};

export default PassPercentage;
