import React from 'react';
import styled from 'styled-components';
import {Tabs} from 'react-bootstrap';
import Loader from './Loader';

export default styled(Tabs)`
  > .nav-tabs {
    border: 0;
    float: right;
    margin-top: -34px;
    margin-right: 12px;
    > li {
      color: #4d4d4d;
      font-size: 9px;
      padding-top: 1px;
      a {
        background-color: #fbfbfb;
        background-repeat: no-repeat;
        border: 1px solid #e9e9e9;
        border-radius: 0;
        padding: 0;
        overflow: hidden;
        transition: border ease 0.5s;
        white-space: nowrap;
        width: auto;
        &:hover {
          background-color: #ecfaff;
          border-color: #ecfaff;
        }
        &:focus {
          outline: none;
        }
        .tab-title {
          background-repeat: no-repeat;
          padding: 15px 0 16px 30px;
          .text {
            display: none;
          }
          &.with-loader {
            position: relative;
            .loader-container {
              position: absolute;
              top: 4px;
              right: 4px;
              padding: 0;
              .react-spinner {
                width: 20px;
                height: 20px;
                @media (max-width: 767px) {
                  width: 17px;
                  height: 17px;
                }
              }
            }
          }
        }
      }
      &.active {
        padding-top: 0;
        a {
          background-color: #fff;
          border: 1px solid #ffffff;
          padding: 0; 
          &:hover {
            background-color: #fff;
          }
          .tab-title {
            padding: 10px 7px 10px 27px;
            .text {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .tab-content {
    padding-top: 30px;
  }

  @media (max-width: 768px) {
    position: relative;

    >.nav-tabs {
      float: left;
      > li {
        a {
          border: none;
          margin-right: 10px;
          .tab-title {
            background-color: #ECFAFF;
            color: #555555;
            padding: 10px 7px 10px 27px;
            .text {
              display: block;
            }
          }
        }
      }
    }
    .tab-content {
      padding-top: 10px;
    }
  }
`;

export const getTabTitle = (title, className) => {
  return (
    <div title={title} className={`tab-title${className ? ` ${className}` : ''}`}>
      {className === 'with-loader' ? <Loader/> : null}
      <span className="text">{title}</span>
    </div>
  );
};