import $ from 'jquery';
import config from '../../../config';
import {getErrorMessage, buildReportLink} from '../../utils';
import {showError} from '../../layouts/actions';

export const REQUEST_UUID_IS_LOADING = 'REQUEST_UUID_IS_LOADING';

export const getRequestByUUID = (history, requestUUID) => dispatch => {
  dispatch({type: REQUEST_UUID_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/uuid/${requestUUID}`
  })
  .done(res => {
    history.push(buildReportLink(res));
  })
  .fail(error => {
    dispatch({type: REQUEST_UUID_IS_LOADING, loading: false});
    dispatch(showError(getErrorMessage(error), 'Request by UUID'));
    history.push('/requests');
  });
};
