import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { createSelector } from 'reselect'
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import qs from 'qs';
import {
  clearAll, getFilters, changeFilter, changeFilterType, addFilter, doSearch, upload,
  getQuibiProjectForQuickUpdateDate, getValidFiltersForInitSearch, savedAllFilters
} from './actions';
import PageComponent from '../../components/PageComponent';
import AppLayout from '../../layouts/AppLayout';
import {Grid, Row, Col} from 'react-bootstrap';
import Title from './components/Title';
import Filters from '../../components/filters/TableFilters';
import Table from './components/Table';
import logoImg from '../../../assets/images/logo.svg';
import QuickUpdateDate from "./components/QuickUpdateDate";
import Loader from '../../components/Loader';
import {CONTENT_TYPES} from '../NewRequest/constants';

const getSelectedFilters = (props) => props.requests.selectedFilters

const getTableSort = createSelector(
  [getSelectedFilters],
  (selectedFilters) => {
    let sortFilter = selectedFilters.filter(filter => filter.name === 'sort')[0];
    return {field: sortFilter.value.field, direction: sortFilter.value.direction};
  }
)

const getShownFilters = createSelector(
  [getSelectedFilters],
  (selectedFilters) => {
    return selectedFilters.filter(filter => !['search', 'sort'].includes(filter.name));
  }
)

class Requests extends PageComponent {
  UNSAFE_componentWillMount() {
    const {dispatch, history, urlFilters} = this.props;

    const localStorageFilters = JSON.parse(localStorage.getItem('requestsFilters')) || {};
    let requestsFilters = {};
    if (urlFilters) {
      Object.keys(urlFilters).forEach(k => {
        if (urlFilters[k]) {
          requestsFilters[k.toLowerCase()] = urlFilters[k];
        }
      });
    }
    if (!Object.keys(requestsFilters).length) {
      requestsFilters = localStorageFilters;
    }
    const srcFilters = JSON.stringify(requestsFilters) === JSON.stringify(localStorageFilters) ? localStorageFilters : urlFilters;

    if (!Array.isArray(srcFilters.content_type) || !srcFilters.content_type.length) {
      srcFilters.content_type = [CONTENT_TYPES.Feature, CONTENT_TYPES.Episode, CONTENT_TYPES.Trailer];
    }
    if (!Array.isArray(requestsFilters.content_type) || !requestsFilters.content_type.length) {
      requestsFilters.content_type = [CONTENT_TYPES.Feature, CONTENT_TYPES.Episode, CONTENT_TYPES.Trailer];
    }

    const initFilters = getValidFiltersForInitSearch(requestsFilters);
    dispatch(savedAllFilters(initFilters));

    dispatch(doSearch(history, initFilters));
    dispatch(getFilters(history, requestsFilters, srcFilters));
  }

  componentWillUnmount() {
    const {dispatch} = this.props;
    dispatch(clearAll());
  }

  getSearchFilter() {
    const {requests} = this.props;
    return requests.selectedFilters.filter(filter => filter.name === 'search')[0];
  }

  getSortFilter() {
    const {requests} = this.props;
    return requests.selectedFilters.filter(filter => filter.name === 'sort')[0];
  }

  updateFilter = (filterName, filterValue, callback) => {
    const {dispatch, history} = this.props;
    document.getElementsByTagName('tbody')[0].scrollTop = 0;
    if (filterName === 'content_type' && (!Array.isArray(filterValue) || !filterValue.length)) {
      filterValue = [CONTENT_TYPES.Feature, CONTENT_TYPES.Episode, CONTENT_TYPES.Trailer];
    }
    dispatch(changeFilter(history, filterName, filterValue)).then(callback);
  };

  handleChangeSearchFilter = (search, callback) => {
    this.updateFilter('search', search, callback);
  };

  handleClickHeaderCell = (field, direction) => {
    this.updateFilter('sort', {field, direction});
  };

  handleChangeFilters = (filterName, value) => {
    this.updateFilter(filterName, value);
  };

  handleChangeFilterType = (filterName, newFilterName, newFilterLabel, newSelectedFilter) => {
    const {dispatch, history} = this.props;
    dispatch(changeFilterType(history, filterName, newFilterName, newFilterLabel, newSelectedFilter.defaultValue));
  };

  handleAddFilter = filter => {
    const {dispatch} = this.props;
    dispatch(addFilter(filter));
  };

  setLoadMore = isLoadMore => {
    const tableContainer = document.getElementsByClassName('std-requests');
    if (tableContainer && tableContainer.length > 0) {
      if (isLoadMore) {
        tableContainer[0].className += ' load-more';
      } else {
        tableContainer[0].className = tableContainer[0].className.replace(/load-more/g, '').trim();
      }
    }
  };

  handleLoadMore = () => {
    const {dispatch, requests, history} = this.props;
    const {data, total} = requests;
    const loadMoreElm = document.getElementsByClassName('load-more') || [];
    if (data.length < total && !loadMoreElm.length) {
      this.setLoadMore(true);
      setTimeout(() => {
        dispatch(doSearch(history, null, true)).then(
          () => this.setLoadMore(false),
          err => this.setLoadMore(false)
        );
      }, 100);
    } else if (data.length >= total && loadMoreElm.length > 0) {
      this.setLoadMore(false);
    }
  };

  handleUpload = () => {
    const {dispatch} = this.props;
    dispatch(upload());
  };

  openQuickUpdateDatePopup = request => {
    const {dispatch} = this.props;
    dispatch(getQuibiProjectForQuickUpdateDate(request));
  };

  handleRefreshRequestsList = () => {
    const {dispatch, history} = this.props;
    document.getElementsByTagName('tbody')[0].scrollTop = 0;
    dispatch(doSearch(history));
  };

  render() {
    const {requests, dispatch} = this.props;
    const searchFilter = this.getSearchFilter();

    const tableSort = getTableSort(this.props);
    const shownFilters = getShownFilters(this.props);

    const isLoading = Object.keys(requests.loaders).some(k => !['more', 'filters'].includes(k) && requests.loaders[k]);
    return (
      <StyledAppLayout title="Requests">
        {isLoading ? <Loader className="full-screen"/> : null}
        {requests.quickUpdateDate.show &&
          <QuickUpdateDate
            data={requests.quickUpdateDate}
            dispatch={dispatch}
          />
        }
        <Grid>
          <Title
            loadingFilters={requests.loaders.filters}
            total={requests.total}
            searchTitle={searchFilter.value}
            onSearch={this.handleChangeSearchFilter}
            onUpload={this.handleUpload}
            onRefresh={this.handleRefreshRequestsList}
          />
          <Row className="requests-content">
            <Col xs={12} md={2}>
              <StyledWrapFilter>
                <Filters
                  loading={requests.loaders.filters}
                  allowedFilters={requests.allowedFilters}
                  shownFilters={shownFilters}
                  onChangeFilters={this.handleChangeFilters}
                  onChangeFilterType={this.handleChangeFilterType}
                  onAddFilter={this.handleAddFilter}
                />
                <FooterLogoStyled>
                  <Link to="/"><img src={logoImg} alt="logo"/></Link>
                </FooterLogoStyled>
              </StyledWrapFilter>
            </Col>
            <Col xs={12} md={10}>
              <Table
                loading={requests.loaders.requests || requests.loaders.more}
                data={requests.data}
                tableSort={tableSort}
                searchTitle={requests.selectedFilters.search}
                onHeaderCellClick={this.handleClickHeaderCell}
                onSearch={this.handleChangeSearchFilter}
                onLoadMore={this.handleLoadMore}
                openQuickUpdateDatePopup={this.openQuickUpdateDatePopup}
              />
            </Col>
          </Row>
        </Grid>
      </StyledAppLayout>
    );
  }
}

const StyledWrapFilter = styled.div`
  height: calc(100vh - 59px - 54px - 50px);
  > div:first-child {
    height: calc(100vh - 59px - 54px - 120px);
    overflow-y: auto;
    padding-right: 5px;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      -webkit-box-shadow: none;
      background-color: #c4c4c4;
    }
    @media (max-width: 991px) {
      height: auto;
      border-top: 0.5px solid #efefef;
    }
  }

  @media (max-width: 991px) {
    height: auto;
  }
`;

const FooterLogoStyled = styled.div`
  background: #fff;
  bottom: 0;
  padding: 20px 0;
  position: absolute;
  width: 100%;
  @media (max-width: 991px) {
    display: none;
  }
`;

const StyledAppLayout = styled(AppLayout)`
  > div:first-of-type {
    color: #000;
    padding: 0;
    position: relative;

    @media (max-width: 991px) {
      background: linear-gradient(transparent, transparent), linear-gradient(#f1f1f1, #f1f1f1);
      background-position: 0 0, 0 55px;
      background-size: auto 55px, auto auto;
      background-repeat: no-repeat;
      margin-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .requests-content {
    background-color: #fff;
    min-height: calc(100vh - 123px);

    @media (max-width: 991px) {
      background-color: transparent;
      > div:first-child {
        padding: 0 !important;
        background-color: #fff;
      }
      > div:last-child {
        margin-top: 10px;
        background-color: #fff;
      }
    }

    @media (max-width: 767px) {
      background-color: transparent;
      > div:last-child {
        padding: 0 !important;
      }
    }
  }
`;

Requests.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  requests: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    requests: state.requests,
    urlFilters: qs.parse(ownProps.location.search.substr(1)),
    user: state.user
  };
}

export default connect(
  mapStateToProps
)(Requests);
