import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import 'bootstrap';
import Loader from '../../../../components/Loader';
import ReplacementForm from '../ReplacementForm';
import {StyledModalBackdrop, StyledModalDialog, StyledModal, StyledBaseCloseModal, StyledModalSubmitFooter,
  StyledMobileModal
} from '../../../../styled/ModalStyled';
import {getFixListIssues, clearFixList, showSubmitReplacementModal, submitReplacement
} from '../../actions.js';
import {Button} from 'react-bootstrap';
import {IssueStatusesOptions} from '../../utils';
import ReviewAndConfirm from '../../../NewRequest/components/screens/ReviewAndConfirm/ReviewAndConfirm';
import CsWorkOrderSteps from '../../../NewRequest/components/screens/CsWorkOrderSteps';
import {QC_REQUEST_TYPE_REPLACEMENT, SELECT_FILES_OPTIONS} from '../../../NewRequest/constants';
import {isManagerUser} from '../../../../utils';
import {
  hasChangedWoSteps,
  hasInvalidWoSteps,
} from '../../../RequestDetails/components/editWorkOrderStepsTable/utils';

class SubmitReplacementModal extends Component {
  state = {
    step: 1,
    manual_uploads: [],
    replacement: {},
    issues: this.prepareStatuses(this.props.reportdetails.fixlistIssues)
  };

  UNSAFE_componentWillMount() {
    const {body} = document;
    body.className += ' modal-open';

    const {dispatch, history, match, reportdetails} = this.props;
    if (!reportdetails.showFixListModal) {
      dispatch(getFixListIssues(history, match.params.requestId));
    }
  }

  prepareStatuses(issues) {
    return issues.map(i => {
      const status = IssueStatusesOptions.find(s => s.name.toLowerCase() === i.Status.toLowerCase());
      return {...i, StatusID: (status || {}).value};
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {showFixListModal, fixlistIssues} = nextProps.reportdetails;
    if (!showFixListModal && !this.state.issues.length && fixlistIssues.length) {
      this.setState({issues: this.prepareStatuses(fixlistIssues)});
    }
  }

  componentWillUnmount() {
    const {dispatch, reportdetails} = this.props;
    if (!reportdetails.showFixListModal) {
      dispatch(clearFixList());
    }

    const {body} = document;
    body.className = body.className.replace('modal-open', '').trim();
  }

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showSubmitReplacementModal(false));
  };

  handleBack = () => {
    const {step} = this.state;
    this.setState({step: step - 1})
  };

  handleChange = (name, value) => {
    this.setState({[name]: value});
  };

  handleChangeWizard = (name, value) => {
    if (name === 'title' || name === 'replacement') {
      this.setState({[name]: value});
    } else if (name === 'uploadedFiles') {
      this.setState({files: {...this.state.files, [name]: value}});
    }
  };

  handleSubmit = () => {
    const {dispatch, history, match} = this.props;
    const {issues, manual_uploads, package_ids, media_delivery, upload_later, replacement} = this.state;
    const issueStatuses = {};
    issues.forEach(i => {
      issueStatuses[i.IssueID] = i.ClientStatusID;
    });
    const values = {
      ...replacement,
      request_id: match.params.requestId,
      manual_uploads,
      package_ids,
      media_delivery,
      upload_later,
      issue_statuses: issueStatuses
    };
    if (!hasChangedWoSteps(values.wo_steps)) {
      delete values.wo_steps;
    }
    dispatch(submitReplacement(history, values));
  };

  isAllowNextStep = () => {
    const {files, step, replacement} = this.state;
    if (isManagerUser() && step === 2) {
      return !hasInvalidWoSteps(replacement.wo_steps);
    }
    if (step > 1) {
      return true;
    }
    let result = false;
    if (files) {
      const {select_files_type_id, selectedFiles, uploadedFiles} = files;
      result =
        (select_files_type_id > SELECT_FILES_OPTIONS.UploadNow ||
        (select_files_type_id === SELECT_FILES_OPTIONS.UploadNow && uploadedFiles && uploadedFiles.length) ||
        (select_files_type_id === SELECT_FILES_OPTIONS.IBMAspera && selectedFiles && selectedFiles.length));
    }
    return result;
  };

  render() {
    const {
      reportdetails, onClickFixNotes, onClickDiscuss, onClickViewIssue, onClickIssueImage, isEnabledInteractiveView,
      match,
    } = this.props;
    const {details, showFixListModal, loaders} = reportdetails;
    const {allowFileUpload} = details;
    const replacementNumber = details && details.replacement && details.replacement.length > 1 ? details.replacement.length : 1;
    const {step, issues, files, title, replacement} = this.state;
    const dataForWizard = {
      ...files,
      replacement,
      title,
      origin_request_id: match.params.requestId,
      origin_request: {
        ReplacementCount: replacementNumber - 1,
        Title: reportdetails.details.Title,
        QcProfileID: reportdetails.details.QcProfileID,
        hide_pricing_from_partners: reportdetails.details.hide_pricing_from_partners
      },
      request_type_id: QC_REQUEST_TYPE_REPLACEMENT
    };
    return (
      <StyledMobileModal className="submit-replacement-modal mobile-modal-with-issue-table">
        <StyledModalBackdrop opacity={showFixListModal ? 0 : undefined} onClick={() => this.handleClose()}/>
        <StyledModalDialog className="mobile-modal-dialog-custom">
          {reportdetails.saving ? <Loader/> : null}
          <StyledModal>
            <StyledModalHeader className="modal-header-custom">
              <div className="back-btn" onClick={step === 1 ? this.handleClose : this.handleBack}/>
              <div className="modal-header-content">
                <div>
                  <h4>Submit Replacement</h4>
                  <span>Replacement {replacementNumber}</span>
                </div>
                <StyledBaseCloseModal className="icon-close-modal" onClick={() => this.handleClose()}/>
              </div>
            </StyledModalHeader>
            <StyledModalBody className="modal-body-custom">
              {
                step === 1 ?
                  <ReplacementForm
                    loading={reportdetails.loadingFixlistIssues || loaders.issueImageModal}
                    saving={reportdetails.saving}
                    onChange={this.handleChange}
                    issues={issues}
                    onClickFixNotes={onClickFixNotes}
                    onClickDiscuss={onClickDiscuss}
                    onClickViewIssue={onClickViewIssue}
                    onClickIssueImage={onClickIssueImage}
                    isEnabledInteractiveView={isEnabledInteractiveView}
                    files={files}
                    requestId={Number(match.params.requestId)}
                    allowFileUpload={allowFileUpload}
                    notSetDefaultUploadOption
                  /> : (isManagerUser() && step === 2) ?
                  <CsWorkOrderSteps
                    data={dataForWizard}
                    onChange={this.handleChangeWizard}
                  /> :
                  <ReviewAndConfirm
                    data={dataForWizard}
                    filters={{allow_file_upload: allowFileUpload}}
                    onChange={this.handleChangeWizard}
                  />
              }
            </StyledModalBody>
            <StyledSubmitModalFooter disabled={!this.isAllowNextStep()}>
              <div>
                {step === 1 ? null : <Button bsStyle="default" onClick={this.handleBack}>Back</Button>}
                <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
                {
                  step <= (isManagerUser() ? 2 : 1) ?
                    <Button
                      bsStyle="primary"
                      onClick={() => this.setState({step: step + 1})}
                      disabled={!this.isAllowNextStep()}
                      >
                      Next
                    </Button> :
                    <Button bsStyle="primary" onClick={this.handleSubmit}>Submit</Button>
                }
              </div>
            </StyledSubmitModalFooter>
          </StyledModal>
        </StyledModalDialog>
      </StyledMobileModal>
    );
  }
}

const StyledModalHeader = styled.div`
  padding: 33px 44px 0 39px;
  position: fixed;
  top: 0;
  right: 0;
  left: 10%;
  z-index: 1060;
  .modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    > div:first-child {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0;
      }
    }
  }
  h4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    color: #000;
    margin: 0;
    @media (max-width: 767px) {
      margin: 0 !important;
    }
    & + span {
      font-size: 15px;
      line-height: 16px;
      color: #000;
      opacity: 0.4;
      padding-left: 18px
      @media (max-width: 767px) {
        font-size: 13px;
        line-height: 17px;
        color: #999;
        opacity: 1;
        padding-left: 56px;
      }
    }
  }
  .icon-close-modal {
    margin-top: 2px;
    @media (max-width: 767px) {
      margin: 0;
      position: absolute;
    }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }
`;

const StyledModalBody = styled.div`
  padding: 0 38px 0 42px;
  position: fixed;
  top: 73px;
  bottom: 71px;
  right: 0;
  left: 10%;
  z-index: 1061;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 768px) {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      -webkit-box-shadow: none;
      background-color: #c4c4c4;
    }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }

  @media (max-width: 767px) {
    bottom: 83px;
    padding: 0;
    > div.replacement-form {
      height: 100%;
    }
  }
`;

const StyledSubmitModalFooter = styled(StyledModalSubmitFooter)`
  background-color: #F4F4F4;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
`;

SubmitReplacementModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  reportdetails: PropTypes.object.isRequired,
  onClickFixNotes: PropTypes.func.isRequired,
  onClickDiscuss: PropTypes.func.isRequired,
  onClickViewIssue: PropTypes.func.isRequired,
  isEnabledInteractiveView: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    reportdetails: state.reportdetails
  };
}

export default connect(
  mapStateToProps
)(withRouter(SubmitReplacementModal));
