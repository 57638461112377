import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../components/table/Table';
import {SelectedCell} from './cells/SelectedCell';
import {StudioPartnerInfoCell} from './cells/StudioPartnerInfoCell';
import {ImageCell} from './cells/ImageCell';
import {Row, Col} from 'react-bootstrap';
import InputAdvanced from '../../../../components/forms/InputAdvanced';
import searchImg from '../../../../../assets/images/search.svg';
import filterImg from '../../../../../assets/images/filter.svg';
import {StyledStudiosTable, StyledSearchImg} from './styledStudiosTable'

class StudioPartnersTable extends Component {
  state = {
    filtered: null,
    searchTitle: null
  };

  headers = {
    selected: {
      component: SelectedCell
    },
    name: {},
    common: {
      component: StudioPartnerInfoCell
    },
    logo_url: {
      component: ImageCell
    }
  };

  componentDidUpdate(prevProps) {
    const {label} = this.props;
    if (label !== prevProps.label) {
      this.setState({searchTitle: null, filtered: null});
    }
  }

  isSelected = row => {
    const {selected} = this.props;
    if (Array.isArray(selected)) {
      return selected.includes(row.value);
    }
    return row.value === selected;
  };

  handleSearch = (_, value) => {
    let filtered;
    if (!value) {
      filtered = null;
    } else {
      filtered = this.props.data.filter(r => r.name.match(new RegExp(`.*${value}.*`, 'i')));
    }
    this.setState({searchTitle: value, filtered});
  };

  handleResetSearch = () => {
    this.handleSearch('', null);
  };

  render() {
    const {data, onSelect, label, selected} = this.props;
    const {searchTitle, filtered} = this.state;
    return (
      <StyledStudiosTable>
        <Row className="studio-partners-table--search--block">
          <Col md={4} xs={12}>
            <div className="table-info">
              <div className="studio-partner-select-text">Select {label}</div>
              <div>Click to select your {label.toLowerCase()}</div>
            </div>
          </Col>
          <Col md={8} xs={12}>
            <div className="table-buttons-container input-group">
              <StyledSearchImg className="img-search" src={searchImg} alt=""/>
              <InputAdvanced
                inputClassName={`search-title-input${searchTitle ? '' : ' empty'}`}
                value={searchTitle}
                placeholder="Search by title"
                onChange={this.handleSearch}
                onBlur={this.handleSearch}
                onClear={this.handleResetSearch}
                forceReset
                />
              {searchTitle ? <img className="img-filter" src={filterImg} alt=""/> : null}
            </div>
          </Col>
        </Row>
        <div className="table-block">
          <Table
            headers={this.headers}
            rows={filtered || data}
            onRowClick={row => onSelect(this.isSelected(row) ? (Array.isArray(selected) ? -row.value : null) : row.value)}
            getRowClassNames={row => this.isSelected(row) ? 'selected-row' : null}
            stripped={false}
            emptyText={searchTitle ? 'No matching' : `No {label.toLowerCase()}s`}
            />
        </div>
      </StyledStudiosTable>
    );
  }
}

StudioPartnersTable.propTypes = {
  data: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.array, PropTypes.number])
};

StudioPartnersTable.defaultProps = {
  data: []
};

export default StudioPartnersTable;
