const findStatusByName = (statuses, ...statusNames) => {
  const arr = (Array.isArray(statusNames) ? statusNames : [statusNames]).map(s => String(s).toLowerCase());
  return (statuses || []).find(({name}) => arr.includes((name || '').toLowerCase()));
};

export const findCanceledStatus = (statuses) => {
  return findStatusByName(statuses, 'Canceled', 'Cancelled');
};

export const calcOrderNumForNewLastStep = (steps) => {
  return ((steps[steps.length - 1] || {}).StepOrder || steps.length) + 1;
};

export const buildNewStep = (statuses, orderNum) => {
  const notStartedStatus = findStatusByName(statuses, 'Not started') || {};
  return {
    isNew: true,
    StepOrder: orderNum,
    StepStatusID: notStartedStatus.value,
    Status: notStartedStatus.name
  };
};

export const hasChangedWoSteps = (steps) => {
  return (steps || []).some(s => !!(s.changedFields || []).length || s.isNew || s.isDeleted);
};

export const hasInvalidWoSteps = (steps) => {
  return (steps || []).some(s => !s.isDeleted && (!s.Label || !s.Details || (s.isNew && !s.ProductCode)));
};

export const checkDiscountPercentStep = (step, isCreatedWO) => {
  return !isCreatedWO && !!step.IsDiscountPercentStep;
};

export const CommonStepType = {
  HARDING_TEST: 'HARDING_TEST',
  DISCOUNT_PERCENT: 'DISCOUNT_PERCENT'
};
