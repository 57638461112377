import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import {ProgressBar} from 'react-bootstrap';

class ColoredFilter extends Component {
  getPercent(item) {
    const {filter} = this.props;
    const allOption = filter.values.find(item => item.name === 'All');
    return (item.total * 100) / ((allOption && allOption.total) || 1);
  }

  getFilterClasses(item) {
    const {value} = this.props;
    const isAll = item.name === 'All';
    return classNames({
      filter: true,
      all: isAll,
      selected: isAll ? value === null : value ? value.includes(item.value) : ''
    });
  }

  render() {
    const {filter, value, onClick} = this.props;

    return (
      <SeverityFilterStyled>
        {
          filter && filter.values &&
          filter.values.length && filter.values.map((item, i) =>
            <div key={i} className={this.getFilterClasses(item)}>
              <button
                onClick={() => onClick(filter.name, value, item.value)}
                >{item.name} <span className="total">{item.total}</span></button>
              <ProgressBarStyled
                backgroundcolor={item.color}
                now={this.getPercent(item)}
                />
            </div>
          )
        }
      </SeverityFilterStyled>
    );
  }
}

const SeverityFilterStyled = styled.div`
  color: #000000;
  margin: 5px 0 30px;

  .filter {
    border-bottom: 0.5px solid #e5e5e5;
    &.all {
      border-bottom: 0.5px solid #cbcbcb;
    }

    &.selected {
      background: #f9f9f9;
      button {
        background: #f9f9f9;
        font-weight: bold;
        .total {
          font-weight: normal;
          opacity: 1;
        }
      }
    }

    button {
      border: none;
      background-color: transparent;
      box-shadow: none;
      font-size: 10px;
      outline: none;
      padding: 7px;
      text-align: left;
      width: 100%;

      .total {
        float: right;
        margin-left: 10px;
        opacity: 0.5;
      }
    }
  }
`;

const ProgressBarStyled = styled(ProgressBar)`
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  height: 2px;
  margin-bottom: 0;
  .progress-bar {
    background: ${props => props.backgroundcolor ? `#${props.backgroundcolor}` : 'transparent'};
    box-shadow: none;
  }
`;

ColoredFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.any
};

export default ColoredFilter;
