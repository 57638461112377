/* eslint "complexity":"off" */
import {USER_SET_IS_LOGOUT} from '../../../../pages/Login/actions';
import {
  ARTWORK_ADMIN_SPECS_IS_LOADING,
  ARTWORK_ADMIN_SPECS_IS_SAVING,
  ARTWORK_ADMIN_SPECS_SET_ACTIVE_TAB,
  ARTWORK_ADMIN_SPECS_SET_SPEC_MODAL_DATA,
  ARTWORK_ADMIN_SPECS_RECEIVE,
  ARTWORK_ADMIN_SPECS_DELETE,
  ARTWORK_ADMIN_SPECS_UPDATE,
  ARTWORK_ADMIN_SPECS_IMPORT_ITEMS_MODAL,
} from './actions';

const initialState = {
  loading: false,
  saving: false,
  studios: [],
  params: [],
  specs: {},
  metadataDictionaries: {
    conditions: [],
    fields: [],
    statuses: [],
  },
  activeTab: 0,
  specModalData: null,
  isShowImportSpecItemsModal: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT: {
      return initialState;
    }

    case ARTWORK_ADMIN_SPECS_IS_LOADING: {
      return {
        ...state,
        loading: action.loading || initialState.loading
      };
    }

    case ARTWORK_ADMIN_SPECS_IS_SAVING: {
      return {
        ...state,
        saving: action.saving || initialState.saving
      };
    }

    case ARTWORK_ADMIN_SPECS_SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.activeTab
      };
    }

    case ARTWORK_ADMIN_SPECS_SET_SPEC_MODAL_DATA: {
      return {
        ...state,
        specModalData: action.data || initialState.specModalData
      };
    }

    case ARTWORK_ADMIN_SPECS_RECEIVE: {
      return {
        ...state,
        metadataDictionaries: action.metadataDictionaries || state.metadataDictionaries,
        studios: action.studios || state.studios,
        params: action.params || state.params,
        specs: action.specs || state.specs,
      };
    }

    case ARTWORK_ADMIN_SPECS_DELETE: {
      const {specData} = action;
      const specs = {...state.specs};
      const idx = specs[specData.studioID].findIndex(p => p.specID === specData.specID);
      if (idx !== -1) {
        specs[specData.studioID].splice(idx, 1);
      }
      return {
        ...state,
        specs
      };
    }

    case ARTWORK_ADMIN_SPECS_UPDATE: {
      const {values, spec} = action;
      const specs = {...state.specs};
      const idx = specs[spec.studioID].findIndex(p => p.specID === spec.specID);
      if (idx !== -1) {
        specs[spec.studioID][idx] = {...specs[spec.studioID][idx], ...values};
      }
      return {
        ...state,
        specs
      };
    }

    case ARTWORK_ADMIN_SPECS_IMPORT_ITEMS_MODAL: {
      return {
        ...state,
        isShowImportSpecItemsModal: action.isShow
      };
    }

    default:
      return state;
  }
}
