import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {IssueStatusesOptions} from '../../utils';
import Lightbox from 'react-image-lightbox';

function CommentContent(props) {
  const {
    comment: {
      CommentID, Comment, CommentJSON, StatusID, ClientStatus, IssueImageUrl
    },
    prefixKey
  } = props;

  const [fullscreen, setFullscreen] = useState(false);
  const [imgFailed, setImgFailed] = useState(false);

  const getClientStatusAsComment = (statusId, status) => {
    const preparedStatus = status || (IssueStatusesOptions.find(i => i.value === statusId) || {}).name || 'Unapproved';
    return <>Client Status: {preparedStatus || <span style={{opacity: 0.65}}>[none]</span>}</>;
  };

  if (CommentJSON) {
    return CommentJSON.map((cItem, cIndex) =>
      <Fragment key={`${prefixKey || 'ci'}-${CommentID}-${cIndex}`}>
        {cItem.type === 'mention' ?
          <Mention>{`${cItem.text.startsWith('@') ? '' : '@'}${cItem.text}`}</Mention> :
            cItem.text
        }
      </Fragment>
    );
  }

  const prepareImage = () => {
    if (IssueImageUrl) {
      return imgFailed ? <StyledFailedImage>[Image did not load]</StyledFailedImage> : (
        <>
          <StyledImage
            alt=""
            src={IssueImageUrl}
            onClick={() => setFullscreen(true)}
            onError={() => setImgFailed(true)}
          />
          {
            fullscreen &&
              <Lightbox
                mainSrc={IssueImageUrl}
                onCloseRequest={() => setFullscreen(false)}
              />
          }
        </>
      );
    }
    return null;
  };

  return Comment || prepareImage() || getClientStatusAsComment(StatusID, ClientStatus);
}

const Mention = styled.span`
  color: rgb(18, 100, 163);
  background: rgba(29, 155, 209, .1);
  padding: 0 2px 1px 2px;
  border-radius: 3px;
`;

const StyledFailedImage = styled.span`
  padding: 3px 4px;
  border-radius: 3px;
  border: 1px dashed gray;
  color: gray;
  font-weight: 300;
  display: block;
  width: fit-content;
`;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 345px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

CommentContent.propTypes = {
  comment: PropTypes.object.isRequired,
  prefixKey: PropTypes.string
};

export default CommentContent;
