import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../components/table/Table';
import {CheckboxHeaderCell} from './cells/CheckboxHeaderCell';
import {CheckboxCell} from './cells/CheckboxCell';
import {InfoCell} from './cells/InfoCell';
import {AssetNameCell} from './cells/AssetNameCell';
import DateCell from '../../../../components/table/cells/DateCell';
import {getSortedRowsBy} from '../../../../utils';
import styled from 'styled-components';

class AssetsTable extends Component {
  state = {
    tableSort: {}
  };

  constructor(props, context) {
    super(props, context);

    this.headers = {
      select: {
        headCell: {
          component: CheckboxHeaderCell,
          componentArgs: {
            onChange: checked => {
              if (!checked) {
                this.props.onSelect([]);
              } else {
                this.props.onSelect(this.props.data);
              }
            },
            isChecked: () => (this.props.selected.length === this.props.data.length && this.props.data.length > 0)
          }
        },
        component: CheckboxCell,
        componentArgs: {
          onChange: this.handleSelectAsset,
          isChecked: this.isSelected
        }
      },
      asset_name: {
        title: 'FileName',
        className: 'asset-name',
        component: AssetNameCell,
        sortPlaceholder: <span className="icon-sorting"/>
      },
      created_at: {
        title: 'Created Date',
        component: DateCell,
        componentArgs: {
          toFormat: 'MMMM D, YYYY HH:mm A'
        }
      },
      file_size: {
        title: 'Size',
        sortPlaceholder: <span className="icon-sorting"/>
      },
      info: {
        component: InfoCell,
        className: 'asset-info-cell'
      }
    };
  }

  handleHeaderCellClick = (field, direction) => {
    if (field !== 'select' && field !== 'info') {
      this.setState({tableSort: {field, direction}});
    }
  };

  handleSelectAsset = (checked, asset) => {
    this.props.onSelect(asset, checked);
  };

  isSelected = asset => {
    return Boolean(this.props.selected.find(a => a.id === asset.id));
  };

  render() {
    const {data} = this.props;
    const {tableSort} = this.state;
    return (
      <AssetsTableContainer>
        <div className="table-block">
          <Table
            headers={this.headers}
            rows={getSortedRowsBy(data, tableSort.field, tableSort.direction, {file_size: 'file_size_bytes'})}
            sort={tableSort}
            onHeaderCellClick={this.handleHeaderCellClick}
            stripped={false}
            emptyText="No Data"
            />
        </div>
      </AssetsTableContainer>
    );
  }
}

const AssetsTableContainer = styled.div`
  margin: 0 0 17px 33px;

  .table-block {
    @media (max-width: 991px) {
      max-width: 100%;
      overflow-x: auto;
    }

    @media (max-width: 767px) {

      .submit-replacement-modal & {
        margin-right: 10px;
      }
    }

    table.table {
      border-collapse: separate;
      margin-bottom: 0;

      th {
        background: #eee;
        color: #000;
        font-weight: 700;
        font-size: 12px;
        line-height: 13px;
        text-align: left;
        text-transform: unset;
        vertical-align: bottom;
        padding: 19px 18px 9px 19px;
        border-bottom: 0;

        &:first-child {
          padding-top: 17px;
          padding-bottom: 11px;
        }
      }

      td {
        color: #000;
        font-weight: normal;
        font-size: 13px;
        line-height: 14px;
        text-align: left;
        vertical-align: middle;
        border-top: 0.5px solid #DADADA;
        padding: 12px 18px 11px 19px;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          font-size: 17px;
          color: #9c9c9c;
          text-align: right;
          padding-top: 13px;
          padding-bottom: 14px;
          padding-right: 30px;
        }

        &.asset-name {
          position: relative;
          img {
            position: absolute;
            top: calc((100% - 21px) / 2 + 1px);
            height: 21px;
            &+div {
              padding-left: 36px;
            }
          }
        }

        &.data-table-empty {
          width: 100% !important;
          text-align: center;
          font-size: 13px;
          line-height: 14px;
          color: #000;
        }
      }

      th, td {

        &:first-child {
          width: 33px;
        }

        &.asset-name {
          width: 30%;

          @media (max-width: 767px) {

            .submit-replacement-modal & {
              width: unset;
              word-break: break-all;
            }
          }
        }

        &.asset-info-cell {
          @media (max-width: 767px) {

            .submit-replacement-modal & {
              display: none;
            }
          }
        }
      }

      tbody tr {
        background-color: #f9f9f9;
      }
    }
  }
`;

AssetsTable.propTypes = {
  data: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired
};

export default AssetsTable;
