import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import {emptyFunction} from '../../../utils';
import BlockHeader from './BlockHeader';
import Loader from '../../../components/Loader';
import ButtonsGroup from './ButtonsGroup';
import Chart from './BarChart';
// import BlockFooter from './BlockFooter';

class ReplacementCost extends React.PureComponent {
  state = {
    period: 3,
    group: 1
  };

  dropdownOptions = [
    {label: "This Year", value: 3},
    {label: "Past 60 days", value: 2},
    {label: "Past 30 days", value: 1}
  ];

  componentDidMount() {
    const {filters} = this.props;
    this.setState({
      period: filters.period,
      group: filters.group
    });
  }

  onPeriodChange = value => {
    const period = value.value;
    this.setState({period});
    this.props.getData(period, this.state.group);
  };

  groupChange = group => {
    this.setState({
      group
    });
    this.props.getData(this.state.period, group);
  };

  render() {
    const {group} = this.state;
    const {loading, data, groups} = this.props;
    return (
      <StyledReplacementCost className="box">
        {loading ? <Loader/> : null}
        <BlockHeader
          title="QC Costs"
          dropDown = {{
            value: this.state.period,
            options: this.dropdownOptions,
            onChange: this.onPeriodChange
          }}
          />
        <ButtonsGroup
          btns={groups}
          value={group}
          onChange={this.groupChange}
        />
        <Chart key="ReplacementCost" data={data} color="#F3C933" showCurrency />
        {/* <BlockFooter onClick={emptyFunction}/> */}
      </StyledReplacementCost>
    );
  }
}

const StyledReplacementCost = styled.div`
  background: #f9f9f9 !important;
`;

ReplacementCost.propTypes = {
  filters: PropTypes.object,
  data: PropTypes.array,
  loading: PropTypes.bool,
  getData: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired
};

export default ReplacementCost;