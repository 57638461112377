export default {
  apiUrl: window.CONFIG.API_URL,
  mocksAPIUrl: window.CONFIG.MOCKS_API_URL,
  mocksPostAPIUrl: window.CONFIG.MOCKS_POST_API_URL,
  fullStoryOrgId: window.CONFIG.FULL_STORY_ORG_ID,
  gtmId: window.CONFIG.GTM_ID,
  smartlookId: window.CONFIG.SMARTLOOK_ID,

  roles: {
    QC_ON_DEMAND: 'qc_on_demand',
    ADMIN: 'qod_admin',
    MANAGE_USERS: 'manage_users',
    MANAGE_USERS__PERMISSIONS: 'manage_users::permissions',
    MANAGE_USERS__CREATE_CLIENTS_ONLY: 'manage_users::create_clients',
    STUDIO: 'studio',
    CLIENT_SERVICE: 'client_service',
    OPS_MANAGER: 'ops_manager',
    OPERATOR: 'operator',
    PRODUCTION: 'production',
    FINISHING_HOUSE: 'finishing_house',
    DW_MANAGER: 'dw_manager',
    ARTWORK_STUDIO: 'artwork_studio',
    ARTWORK_POST_PARTNER: 'artwork_post_partner',
    ARTWORK_OPERATOR: 'artwork_operator',
    ARTWORK_MANAGER: 'artwork_manager',
    SHOW_DASHBOARD: 'show_dashboard',
  },
  pagingSize: 100,
  passwordMinLength: 8,
  requestStatuses: {
    PENDING: 1,
    WAITING_FOR_ASSETS: 2,
    IN_PROGRESS: 3,
    CLOSED: 4,
    NEEDS_FIXES: 5,
    NONE: 6,
    CANCELLED: 7
  },
  qcStatus: {
    QC_FAILED: 1,
    QC_PASS: 2,
    REVIEW: 3
  },
  requestUserPermission: {
    NO_ACCESS: 1,
    CAN_VIEW: 2,
    CAN_COMMENT: 3,
    CAN_MANAGE: 4
  },
  officeFlag: {
    BURBANK: 512,
    NEW_YORK: 1024
  },
  initAsperaParams: {
    sdkLocation: '//d3gcli72yxqn2z.cloudfront.net/connect/v4',
    minVersion: '3.8.0'
  }
};
