import styled from 'styled-components';
import backImg from '../../../assets/images/back.svg';

const StyledHeader = styled.header`
  .navbar-default.navbar-custom {
    transition: padding 0.3s ease 0.0s;
    background: transparent;
    border: none;
    border-radius: 0;
    text-transform: capitalize;
    margin-bottom: 0;
    height: auto;
  }
  .navbar-header {
    &.brand-center {
      float: none;
      .navbar-brand {
        float: none;
        display: block;
        margin-right: 0 !important;
      }
      .navbar-brand > img {
        float: none;
        margin-left: auto;
        margin-right: auto;
      }
      .navbar-brand > .studio-name {
        display: none;
      }
    }
  }
  .navbar-collapse {
    padding: 0;
  }
  .navbar-nav {
    margin: 0;
    &.navbar-nav-right {
      float: right;
    }
  }
  .navbar-brand {
    display: inline-block;
    height: auto;
    padding: 0;
    margin: 0 !important;
  }
  .navbar-brand > img {
    width: 20px;
    height: 10px;
    margin: 27px 10px 22px;
    float: left;
  }
  .navbar-brand > .studio-name {
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
    color: #fff;
    margin: 24px 10px 17px 8px;
    float: left;
  }
  .navbar-default.navbar-custom .navbar-nav > li > a {
    color: #fff;
    opacity: 0.6;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    height: 59px;
    line-height: 54px;
    margin: 0 0 0 11px;
    padding: 5px 8px 0;
    will-change: background-color, color, opacity, font-weight;
    transition: all 0.3s ease 0s;
    &:hover, &.active, &.active:hover {
      opacity: 1;
      color: #fff;
      background-color: #4a5165;
      font-family: 'Roboto', sans-serif;
    }
    &.active, &.active:hover {
      opacity: 1;
      background-color: unset;
      font-weight: 700;
    }
    &[disabled] {
      pointer-events: none;
    }
    &.create-qc-request {
      margin-top: 14px;
      margin-left: 27px;
      line-height: 32px;
      height: 32px;
      padding-top: 1px;
      padding-left: 15px;
      padding-right: 15px;
      background: #FFFFFF;
      border-radius: 100px;
      font-size: 12px;
      color: #595959;
      opacity: 1;
      &:hover {
        color: #fff;
        background-color: #4a5165;
      }
    }

    @media (max-width: 1600px) {
      margin: 0;
    }
  }

  .navbar-default.navbar-custom .navbar-nav > li.dropdown.open:not(.active) > a:not(.active) {
    background-color: #4a5165;
  }

  .navbar-default.navbar-custom .navbar-nav > .active > a {
    background-color: #272727;
  }
  .navbar-default.navbar-custom .navbar-nav > .open > a {
    color: #fff;
    background-color: #313131;
    font-family: 'Roboto', sans-serif;
  }
  .navbar-default.navbar-custom .navbar-nav > li {
    &.user-dropdown {
      display: flex;

      & > a {
        color: #fff;
        opacity: 1;
        font-size: 13px;
        line-height: 59px;
        margin-left: 0;
        padding-top: 0;
        padding-right: 13px;
        padding-left: 0;
        border-right: 1px solid rgba(255, 255, 255, 0.4);
        border-left: 1px solid rgba(255, 255, 255, 0.4);
        background-color: #02467b;
        display: flex;
        align-items: center;
        .header-username {
          padding-left: 16px;
        }
        .caret {
          opacity: 0.6;
          margin-left: 30px;
        }
        &:hover {
          background-color: #4a5165;
        }
      }
    }
    &.hamburger-right {
      cursor: pointer;
      margin-right: -15px;
      &:hover {
        background-color: #4a5165;
      }
      &.active {
        background-color: #292929;
      }
      img {
        width: 18px;
        height: 17px;
        margin: 21px 19px;
      }
    }
    &.search-header {
      span {
        width: 17px;
        height: 17px;
        margin: 22px 19px 20px 20px;
        display: block;
      }
      &:hover {
        cursor: pointer;
        background-color: #4a5165;
      }
    }
  }

  .dropdown-menu > li {
    &.dropdown-header {
      text-transform: uppercase;
      font-size: 12px;
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      background-color: #ccc;
      color: #393f4f;
    }
    a {
      color: #444;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 36px;
      padding: 0 20px;
      &:hover, &.active, &.active:hover {
        color: #ededed;
        background-color: #4a5165;
        font-family: 'Roboto', sans-serif;
      }
      &.active, &.active:hover {
        background-color: #428bca;
      }
      &.active {
        color: #ededed !important;
      }
      &.active:hover {
        color: #fff !important;
      }
    }
  }

  .dropdown-submenu {
    position: relative;
  }

  .dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    border-radius: 0 6px 6px 6px;
  }

  .dropdown-submenu:hover>.dropdown-menu {
    display: block;
  }

  .dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #aaa;
    margin-top: 12px;
    margin-right: -10px;
  }

  .dropdown-submenu:hover>a:after, .dropdown-submenu>a.active:after {
    border-left-color: #fff;
  }

  .dropdown-submenu.pull-left {
    float: none;
  }

  .dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    border-radius: 6px 0 6px 6px;
  }

  .navbar-right {
    margin-right: 0;
    margin-top: 4px;
  }

  .navbar-custom > .container {
    position: relative;
  }

  @media (max-width: 768px) {
    .container {
      display: flex;
      .navbars-wrap {
        flex-grow: 1;
      }
    }
    .navbar-header {
      display: none;
      float: left;
      .studio-name {
        margin-top: 22px;
      }
    }
    .navbar-nav-left {
      display: none;
    }
    .navbar-nav-right {
      display: flex;
      li, li * {
        &:hover, &.active, &.active:hover {
          background-color: transparent !important;
        }
      }
      .search-header {
        order: 1;
        span {
          margin-left: 15px !important;
          margin-right: 15px !important;
        }
      }
      .notifications-btn {
        order: 2;
        div {
          border-right: 0;
          width: 38px;
          background-position-x: 10px;
          margin-right: 5px;
          margin-left: 5px;
        }
      }
      li.hamburger-right {
        order: 3;
        margin: 0 !important;
        img {
          margin-left: 15px !important;
          margin-right: 10px !important;
        }
      }
      .user-dropdown {
        order: 4;
        display: none !important;

        .dropdown-toggle {
          background-color: transparent !important;
          border: 0 !important;
          padding: 0 !important;
          span {
            border: 2px solid #02477E;
            border-radius: 18px;
            margin: 13px auto 0;
            height: 35px;
            width: 35px;
          }
          .header-username, .caret {
            display: none;
          }
        }

        &.open {
          background: white;
          position: fixed;
          top: 0;
          bottom: 0;
          z-index: 4;
          left: 133px;
          right: 0;
          .dropdown-toggle {
            position: absolute;
            right: 15px;
            span {
              border-color: #c4c4c4;
            }
          }
          .dropdown-menu {
            margin: 65px 0 0 23px;
            a {
              font-size: 15px !important;
              padding: 20px 0 !important;
            }
          }
          .back-btn {
            background: url(${backImg}) no-repeat;
            height: 15px;
            width: 20px;
            position: absolute;
            top: 22px;
            left: 23px;
          }
          .overlay {
            background: black;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            opacity: 0.4;
            width: 133px;
            z-index: 1;
          }
        }
      }
    }

    .without-main-header & {
      display: none;
    }
  }
`;

export default StyledHeader;
