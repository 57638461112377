import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import Select from 'react-select';
import {ControlLabel} from 'react-bootstrap';
import expandImg from '../../../assets/images/expand.svg';

class SelectAdvanced extends Component {
  render() {
    const {creatable, loading, label, labelSeparator, onSelect, name, labelKey, valueKey, options, className,
      labelClassName, selectClassName, onlySelectControl
    } = this.props;
    const loader = loading ? <Loader/> : null;
    const labelHtml = (label === '') ? null :
      <ControlLabel className={labelClassName}>{label}{labelSeparator}</ControlLabel>;
    const selectProps = {...this.props};
    ['creatable', 'loading', 'label', 'labelSeparator', 'onSelect', 'key', 'className', 'onChange', 'selectClassName'
    ].forEach(propName => {
      delete selectProps[propName];
    });
    if (options.length && typeof options[0] === 'string') {
      selectProps.options = options.map(item => ({[valueKey]: item, [labelKey]: item}));
    }
    if (selectProps.value && !selectProps.options.find(o => o[valueKey] === selectProps.value)) {
      selectProps.options = [{[valueKey]: selectProps.value, [labelKey]: selectProps.value}].concat(selectProps.options);
    }
    const finalSelectProps = {
      className: selectClassName,
      promptTextCreator: (creatable ? label => `Create "${label}"` : undefined),
      onSelectResetsInput: !!creatable,
      onBlurResetsInput: false,
      clearable: false,
      simpleValue: true,
      onChange: selValue => onSelect(name, selValue),
      arrowRenderer: () => <img src={expandImg} alt=""/>,
      ...selectProps
    };

    return onlySelectControl ? <Select {...finalSelectProps} /> : (
      <div className={`form-group ${className}`}>
        {labelHtml}
        <div className="field">
          {loader}
          {
            creatable ?
              <Select.Creatable {...finalSelectProps} /> :
              <Select {...finalSelectProps} />
          }
        </div>
      </div>
    );
  }
}

SelectAdvanced.propTypes = {
  creatable: PropTypes.bool,
  loading: PropTypes.bool,
  label: PropTypes.any,
  labelSeparator: PropTypes.string,
  name: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  options: PropTypes.array,
  onSelect: PropTypes.func,
  className: PropTypes.string
};

SelectAdvanced.defaultProps = {
  creatable: false,
  loading: false,
  label: '',
  labelSeparator: ':',
  name: '',
  valueKey: 'value',
  labelKey: 'name',
  options: [],
  onSelect: () => {},
  className: ''
};

export default SelectAdvanced;
