import React from 'react';
import PropTypes from 'prop-types';
import documentImg from '../../../../../../assets/images/document.svg';

export const AssetNameCell = ({value}) => (
  <td className="asset-name"><img src={documentImg} alt=""/><div>{value}</div></td>
);
AssetNameCell.propTypes = {
  value: PropTypes.any
};
