import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormGroup, FormControl, ControlLabel} from 'react-bootstrap';

class InputAdvanced extends Component {
  handleChange = event => {
    this.props.onChange(event.target.name, event.target.value);
  };

  handleBlur = event => {
    this.props.onBlur(event.target.name, event.target.value, event.target.dataset.index, this._input);
  };

  resetFilter(value) {
    this._input.value = value || '';
  }

  componentDidUpdate(prevProps) {
    if (this.props.forceReset || ((JSON.stringify(this.props) !== JSON.stringify(prevProps)) && this.props.reset)) {
      this.resetFilter(this.props.value);
    }
  }

  render() {
    const {
      label, labelSeparator, name, type, value, placeholder, placeholderFocus, maxLength, index, min, disabled, autoComplete, optional,
      onKeyDown, onFocus, onClear, inputClassName, pattern, labelClassName, required
    } = this.props;
    const labelHtml = (label === '') ? null :
      <ControlLabel className={labelClassName}>{label}{labelSeparator}</ControlLabel>;
    return (
      <FormGroup className={optional ? 'optional' : null}>
        {labelHtml}
        <FormControl
          className={inputClassName}
          name={name}
          type={type}
          data-index={index}
          defaultValue={value}
          onKeyDown={event => onKeyDown(event.keyCode, this._input, event.target.value)}
          onFocus={event => onFocus(this._input)}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          pattern={pattern}
          placeholder={placeholder}
          data-placeholder-focus={placeholderFocus}
          autoComplete={autoComplete}
          maxLength={maxLength}
          min={min}
          required={required}
          disabled={disabled ? 'disabled' : null}
          inputRef={ref => {
            this._input = ref;
          }}
        />
        {onClear ? <i className="fa fa-times-circle" onClick={() => onClear(this._input)} title="Clear"/> : null}
      </FormGroup>
    );
  }
}

InputAdvanced.propTypes = {
  label: PropTypes.string.isRequired,
  labelSeparator: PropTypes.any,
  name: PropTypes.string,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClear: PropTypes.func,
  inputClassName: PropTypes.string,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderFocus: PropTypes.string,
  autoComplete: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.number,
  min: PropTypes.number,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  reset: PropTypes.bool,
  forceReset: PropTypes.bool,
  required: PropTypes.bool,
  optional: PropTypes.bool
};

InputAdvanced.defaultProps = {
  label: '',
  labelSeparator: ':',
  name: '',
  type: 'text',
  value: '',
  onKeyDown: () => {},
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  autoComplete: 'nope',
  pattern: null,
  placeholder: null
};

export default InputAdvanced;
