/* eslint "jsx-a11y/anchor-is-valid":"off" */
import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import styled, {css} from 'styled-components';
import searchImg from '../../../../assets/images/search.svg';
import InputAdvanced from '../../../components/forms/InputAdvanced';
import uploadImg from '../../../../assets/images/upload.svg';
import searchImg2 from '../../../../assets/images/search2.svg';
import Loader from '../../../components/Loader';
import RefreshIcon from '../../../components/icons/RefreshIcon';

const TotalColumn = React.memo(({total}) => (
  <Col xs={4} className="total">
    {total}&nbsp;Requests
  </Col>
));

class Title extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.resetValues = false;

    this.state = {
      showSearchInput: false,
      searchTitle: props.searchTitle
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.searchTitle !== this.state.searchTitle) {
      this.setState({
        searchTitle: nextProps.searchTitle
      })
    }
  }

  handleChangeSearch = (_, searchTitle) => {
    this.resetValues = false;
    this.setState({searchTitle});
  };

  handleBlurSearch = () => {
    this.resetValues = true;
    const {searchTitle} = this.props;
    this.setState({searchTitle, showSearchInput: false});
    this.forceUpdate();
  };

  handleKeyDownSearch = (keyCode, input) => {
    if (keyCode === 13) {
      const {searchTitle} = this.state;
      if (searchTitle !== this.props.searchTitle) {
        this.props.onSearch(searchTitle, () => input.blur());
      } else {
        input.blur();
      }
    } else if (keyCode === 27) {
      input.blur();
    }
  };

  handleClickSearchBtn = () => {
    const {searchTitle} = this.state;
    this.setState({showSearchInput: true});
    if (!searchTitle) {
      setTimeout(() => {
        try {
          this._input._input.focus();
        } catch(ignored) {/**/}
      }, 300);
    }
  };

  handleResetSearch = () => {
    this.resetValues = true;
    const searchTitle = '';
    this.setState({searchTitle});
    this.props.onSearch(searchTitle, () => {
      try {
        this._input._input.focus();
      } catch(ignored) {/**/}
    });
  };

  render() {
    const {searchTitle} = this.state;
    const {total, onUpload, onRefresh, loadingFilters} = this.props;
    return (
      <StyledRow disabled={loadingFilters}>
        {loadingFilters && <Loader/>}
        <TotalColumn total={total} />
        <Col xs={8} className={`search${this.state.showSearchInput ? ' active' : ''}`}>
          <ul className="menu">
            <li>
              <a onClick={onRefresh}>
                <RefreshIcon className="refresh-icon" />
                <span>Refresh</span>
              </a>
            </li>
            <li>
              <a onClick={onUpload}>
                <i className="upload-icon icon"/><span>Export</span>
              </a>
            </li>
          </ul>
          <form action="#" onSubmit={event => {event.preventDefault();}}>
            <div className="search-btn" onClick={this.handleClickSearchBtn}/>
            <div className="search-wrap" title="Parts of words or separate words can be replaced with spaces">
              <img className="img-search" src={searchImg} alt=""/>
              <InputAdvanced
                inputClassName={`search-title-input${searchTitle ? '' : ' empty'}`}
                value={searchTitle}
                onChange={this.handleChangeSearch}
                onBlur={this.handleBlurSearch}
                onKeyDown={this.handleKeyDownSearch}
                placeholder="Search by title or WO#"
                reset={this.resetValues}
                ref={r => this._input = r}
                type="search"
                onClear={this.handleResetSearch}
              />
            </div>
          </form>
        </Col>
      </StyledRow>
    );
  }
}

const StyledRow = styled(Row)`
  background: #ededed;
  min-height: 64px;

  ${props => props.disabled && css`
    position: relative;
    .loader-container {
      padding: 0;
      position: absolute;
      top: 17px;
      right: 0;
      width: 305px;
      z-index: 1090;
      @media (max-width: 991px) {
        top: 15px;
        width: 100%;
      }
    }
  `}

  .total {
    color: #454545;
    font-size: 15px;
    line-height: 64px;
    padding-left: 65px;
  }
  
  .search {
    display: flex;

    ${props => props.disabled && css`
      pointer-events: none;
      opacity: 0.55;
    `}

    .menu {
      display: flex;
      padding: 22px 0 0 0;
      justify-content: flex-end;
      width: 100%;
      li {
        list-style: none;
        margin: 0 10px;
        &:hover a span {
          max-width: 1000px;
          transition: all 1.5s ease-in-out;
        }
      }
      a {
        color: #000000;
        display: flex;
        font-size: 10px;
        line-height: 23px;
        white-space: nowrap;
        span {
          max-width: 0;
          overflow: hidden;
          transition: all 0.1s ease-in-out;
        }
      }
      .icon {
        background-repeat: no-repeat;
        display: block;
        margin: 0 3px;
        height: 21px;
        width: 21px;
      }
      .upload-icon {
        background-image: url(${uploadImg});
      }
      .refresh-icon {
        display: inline-block;
        color: #999;
        max-width: 100%;
        margin: 0 3px;

        svg {
          max-height: 100%;
          max-width: 100%;
        }
      }
    }
    .search-wrap {
      margin: 15px 10px 15px auto;
      position: relative;
      width: fit-content;
      .form-group {
        margin-bottom: 0;
      }
      img {
        position: absolute;
        top: 8px;
        left: 5px;
      }
      input {
        border: 0;
        color: #000000;
        font-size: 13px;
        padding-left: 33px;
        padding-right: 25px;
        width: 253px;
        &.empty {
          + i {
            display: none !important;
          }
        }
        &::placeholder {
          color: rgb(117, 117, 117);
          font-weight: 400;
        }
      }
      i {
        position: absolute;
        top: 10px;
        right: 6px;
        font-size: 14px;
        color: #555;
        cursor: pointer;
        &:hover {
          color: #222;
        }
      }
    }
  }

  @media (max-width: 991px) {
    background: #fff;
    min-height: 55px;
    border-radius: 3px 3px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .total {
      color: #000;
      line-height: 17px;
      padding-left: 15px;
      width: auto;
      float: none;
    }

    .search {
      width: auto;
      float: none;
      flex-grow: 1;
      align-items: center;

      .search-btn {
        background: url(${searchImg2}) no-repeat;
        background-position: 0 2px;
        height: 21px;
        width: 21px;
      }

      .menu {
        margin: 0 23px 0 0;
        padding: 4px 0 3px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        li:last-of-type {
          margin-right: 19px;
        }
      }

      .search-wrap {
        margin: -2px 0 0;
        max-width: 0;
        input {
          width: 100%;
          &:focus {
            + i {
              display: none !important;
            }
          }
        }
        * {
          display: none;
        }
      }

      &.active {

        .search-btn {
          display: none;
        }

        .menu {
          width: auto;
          @media (max-width: 375px) {
            display: none;
          }
        }

        form {
          flex-grow: 1;
        }

        .search-wrap {
          max-width: 1000px;
          width: 100%;
          transition: all 1.5s ease-in-out;
          * {
            display: block;
            transition: all 1.5s ease-in-out;
          }
        }
      }
    }
  }
`;

Title.propTypes = {
  loadingFilters: PropTypes.bool.isRequired,
  total: PropTypes.number,
  searchTitle: PropTypes.string,
  onSearch: PropTypes.func,
  onRefresh: PropTypes.func,
  onUpload: PropTypes.func
};

export default Title;