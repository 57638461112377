import React from 'react';
import PropTypes from 'prop-types';

export const WOMemfisCell = React.memo(({value, className, title}) => (
  <td className={`${className || ''}${!value ? ' hidden-xs' : ''}`} title={title}>
    {value}
  </td>
));

WOMemfisCell.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string,
};
