const ISSUE_STATUS__APPROVED = 3;

const ISSUE_STATUS__PENDING_APPROVAL = 20;
const ISSUE_STATUS__PENDING_APPROVAL_LABEL = 'Pending Approval';

export const isApprovedIssueStatus = statusId => statusId === ISSUE_STATUS__APPROVED;

export const isPendingApprovalIssueStatus = (statusId, statusName) => {
  return statusId === ISSUE_STATUS__PENDING_APPROVAL || statusName === ISSUE_STATUS__PENDING_APPROVAL_LABEL;
};

export const IssueStatusesOptions = [
  {value: ISSUE_STATUS__PENDING_APPROVAL, name: ISSUE_STATUS__PENDING_APPROVAL_LABEL},
  {value: ISSUE_STATUS__APPROVED, name: 'Approved'},
  {value: 2, name: 'Not Fixed'},
  {value: 1, name: 'Fixed'},
  {value: 18, name: 'Upgrade'},
  {value: 19, name: 'Downgrade'},
];
