import React, {Fragment} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Loader from '../../../../components/Loader';
import BlockHeader from '../BlockHeader';
import Calendar from './Calendar';
import RequestList from './RequestList';
import BlockFooter from '../BlockFooter';
import {prepareTopFiltersForUrl} from '../../actions';

class RequestQueue extends React.PureComponent {
  state = {
    type: 1
  };

  componentDidMount() {
    const {filters} = this.props;
    this.setState({
      type: filters.type
    });
  }

  onTypeChange = e => {
    this.setState({
      type: e.value
    });
    const {getData, data} = this.props;
    getData({type: e.value, month: data.month, year: data.year});
  };

  onCalendarChange = paramsObj => {
    const {getData} = this.props;
    getData({...paramsObj, type: this.state.type});
  };

  getViewAllLink = () => {
    const {type} = this.state;
    const {data, filters: {topFilters}} = this.props;
    const {month, year} = data;
    const preparedTopFilters = prepareTopFiltersForUrl(topFilters);
    return `requests?` +
      `last_updated[0]=4&` +
      `date_type[0]=${type}&` +
      `from[0]=${`${year}-${month < 10 ? '0' : ''}${month}-01`}&` +
      `to[0]=${`${year}-${month < 10 ? '0' : ''}${month}-${new Date(year, month, 0).getDate()}`}` +
      (preparedTopFilters ? '&' + preparedTopFilters : '');
  };

  render() {
    const {type} = this.state;
    const {data, loading} = this.props;
    const {request_list} = data;
    return (
      <StyledRequestQueue className="box">
        {loading ? <Loader/> : null}
        <Fragment>
          <BlockHeader
            className="block-header-request-queue"
            title="Request Queue"
            dropDown = {{
              label: 'Highlight',
              value: type,
              options: [{label: 'Due Date', value: 1}, {label: 'Request Date', value: 2}],
              onChange: this.onTypeChange
            }}
          />
          <Calendar
            loading={loading}
            year={data.year}
            month={data.month}
            day={data.day}
            data={data.request_queue}
            onChangeDate={this.onCalendarChange}
          />
          <RequestList
            list={request_list}
          />
          {request_list && request_list.length ? <BlockFooter linkTo={this.getViewAllLink()}/> : null}
        </Fragment>
      </StyledRequestQueue>
    );
  }
}

const StyledRequestQueue = styled.div`
  padding-bottom: 50px;

  @media (max-width: 991px) {
    padding-bottom: 45px;
  }
`;

RequestQueue.propTypes = {
  filters: PropTypes.object,
  data: PropTypes.object,
  loading: PropTypes.bool,
  getData: PropTypes.func.isRequired
};

export default RequestQueue;
