export const QC_RESULT__PASS = 1;
export const QC_RESULT__FAIL = 2;
export const QC_RESULT__REVIEW = 3;

export const QC_STATUS__PASSED = 4;
export const QC_STATUS__FAILED = 3;
export const QC_STATUS__IN_PROGRESS = 2;
export const QC_STATUS__NOT_STARTED = 1;


export const isPassedQcResult = (issueObj) => issueObj.qc_result_id === QC_RESULT__PASS;

export const isFailedQcResult = (issueObj) => issueObj.qc_result_id === QC_RESULT__FAIL;

export const isReviewQcResult = (issueObj) => issueObj.qc_result_id === QC_RESULT__REVIEW;

export const hasQcResult = (issueObj) => isPassedQcResult(issueObj) || isFailedQcResult(issueObj) || isReviewQcResult(issueObj);


export const isPassedQcStatus = (assetObj) => assetObj.qc_status_id === QC_STATUS__PASSED;

export const isFailedQcStatus = (assetObj) => assetObj.qc_status_id === QC_STATUS__FAILED;


export const getReportPanelBorderLeftColor = (assetObj) => {
  switch(assetObj.qc_status_id) {
    case QC_STATUS__PASSED:
      return '#42CB83';
    case QC_STATUS__FAILED:
      return '#D61010';
    default:
      return '';
  }
};

export const getQcStatusById = (assetObj) => {
  switch(assetObj.qc_status_id) {
    case QC_STATUS__PASSED:
      return 'Passed';
    case QC_STATUS__FAILED:
      return 'Failed';
    case QC_STATUS__IN_PROGRESS:
      return 'In Progress';
    default:
      return 'Not Started';
  }
};

export const getQcStatusColor = (assetObj) => {
  return getReportPanelBorderLeftColor(assetObj);
};

export const getQcResultColor = (issueObj) => {
  if (issueObj.is_override) {
    return '#ABABAB';
  }
  if (isPassedQcResult(issueObj)) {
    return '#42CB83';
  }
  if (isFailedQcResult(issueObj)) {
    return '#FF3D00';
  }
  if (isReviewQcResult(issueObj)) {
    return '#FFBF03';
  }
  return '';
};

export const hasIssuesWithQcResults = (assetObj) => (assetObj.issues || []).some(hasQcResult);

export const hasAllIssuesWithQcResults = (assetObj) => (assetObj.issues || []).every(hasQcResult);

export const PROJECTS_VIEW__GALLERY = 'GALLERY';
export const PROJECTS_VIEW__LIST = 'LIST';

export const ARTWORK_NAV_HEIGHT = '30px';

const getFloatPrecision = (floatValue, precision) => {
  floatValue = parseFloat(floatValue);
  if (isNaN(floatValue)) {
    return parseFloat('0').toFixed(precision);
  } else {
    const power = Math.pow(10, precision);
    floatValue = (Math.round(floatValue * power) / power).toFixed(precision);
    return floatValue.toString();
  }
};

export const prepareFileSize = size => {
  if (typeof size !== 'number') {
     return '-';
  }
  if (size > 1024) {
    const kbSize = size / 1024;
    if (kbSize > 1024) {
      let mbSize = kbSize / 1024;
      if (mbSize > 1024) {
        let gbSize = mbSize / 1024;
        if (gbSize > 1024) {
          let tbSize = gbSize / 1024;
          return `${getFloatPrecision(tbSize, 2)}TB`;
        }
        return `${getFloatPrecision(gbSize, 2)}GB`;
      }
      return `${getFloatPrecision(mbSize, 2)}MB`;
    }
    return `${Math.round(kbSize)}kB`;
  }
  return `${size}B`;
};

export const NonNumericFilters = [
  'spec',
  'title_concept',
  'revision',
];
