import React, {Component} from 'react';
import PropTypes from 'prop-types';
import 'bootstrap';
import Loader from '../../../../components/Loader';
import {Row, Col} from 'react-bootstrap';
import {formatDate} from '../../../../utils';
import styled from 'styled-components';
import {
  StyledModalBackdrop, StyledModalDialog, StyledModal, StyledIssueModalHeader, StyledCloseModal, StyledIssueModalBody,
  StyledMobileModal
} from '../../../../styled/ModalStyled';
import {IssueSubHeader} from './IssueSubHeader';
import CommentForm, {HeightOfCommentToolbar} from '../../../../components/comments/CommentForm';
import isMobile from 'is-mobile';
import CommentContent from '../sidebar/CommentContent';
import {getMentions} from '../../actions';
import classNames from 'classnames';

const AvatarBgColors = ['#A6A6A6', '#E75592', '#8655e7', '#fb4545', '#55e77b', '#fbcc45', '#fb6745', '#afe755'];

class IssueDiscussModal extends Component {
  timerId = null;

  scrollToComment = () => {
    const {currentIssueCommentId, onScrollToComment, currentIssueClientStatusId} = this.props;
    let commentElm;
    if (currentIssueCommentId) {
      commentElm = document.getElementById(`comment-item-${currentIssueCommentId}`);
    } else {
      if (currentIssueClientStatusId) {
        const statusElms = document.querySelectorAll(`.comment--client-status-${currentIssueClientStatusId}`);
        if (statusElms && statusElms.length > 0) {
          commentElm = statusElms[statusElms.length - 1];
        }
      }
      if (!commentElm) {
        // scroll to latest comment
        const comments = document.querySelectorAll('.comment-item');
        if (comments && comments.length > 0) {
          commentElm = comments[comments.length - 1];
        }
      }
    }
    if (commentElm) {
      commentElm.scrollIntoView();
      this.timerId = setTimeout(onScrollToComment, 500);
    }
  };

  isIOS = () => {
    return !!window.navigator.userAgent.match(/iPhone|iPad|iPod/i);
  };

  checkClassMentionSuggestions = event => {
    let o = event.target;
    while (((o || {}).className || '').indexOf('comments-textarea__suggestions') === -1) {
      if (!o || o === document) {
        return false;
      }
      o = o.parentNode;
    }
    return true;
  };

  preventBehaviorMentionSuggestions = event => {
    try {
      const suggestions = document.getElementsByClassName('comments-textarea__suggestions');
      if (suggestions && suggestions.length && !this.checkClassMentionSuggestions(event)) {
        event.preventDefault();
      }
    } catch {}
  };

  componentDidMount() {
    if (this.isIOS()) {
      document.addEventListener('touchmove', this.preventBehaviorMentionSuggestions, {passive: false});
      document.body.classList.add('fixed');
    }
    document.body.classList.add('modal-open');
    this.scrollToComment();
  }

  componentDidUpdate(prevProps) {
    const {currentIssueCommentId, currentIssueClientStatusId} = this.props;
    if ((currentIssueCommentId && currentIssueCommentId !== prevProps.currentIssueCommentId)
        || (currentIssueClientStatusId && currentIssueClientStatusId !== prevProps.currentIssueClientStatusId)) {
      this.scrollToComment();
    }
  }

  clearTimeout = () => {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  };

  componentWillUnmount() {
    if (this.isIOS()) {
      document.removeEventListener('touchmove', this.preventBehaviorMentionSuggestions, {passive: true});
      document.body.classList.remove('fixed');
    }
    document.body.classList.remove('modal-open');
    this.clearTimeout();
  }

  prepareDate = comment => {
    let date = comment.CommentedOn || comment.ChangedOn || comment.UploadedOn;
    let fromFormat = '';
    if (!date && comment.UnixTimestamp) {
      date = comment.UnixTimestamp * 1000;
      fromFormat = 'x';
    }
    return formatDate(date, fromFormat, 'MMMM D, YYYY HH:mm A');
  };

  getNameFirstLetters(name) {
    return name && name.split(' ').map(s => s[0]).slice(0, 2).join('');
  }

  calcFooterHeight = formHeight => {
    return isMobile() ? formHeight : formHeight + 25;
  };

  render() {
    const {currentIssue, currentIssueCommentId, onClose, saving, loading, onSend, requestId, currentIssueClientStatusId
    } = this.props;
    const userNamesColors = {};
    if (currentIssue.comments) {
      currentIssue.comments.forEach(c => {
        if (!userNamesColors[c.UserName || 'No Name']) {
          const index = Object.keys(userNamesColors).length;
          userNamesColors[c.UserName || 'No Name'] = AvatarBgColors[index < AvatarBgColors.length ? index : (index % 2)];
        }
      });
    }
    const formHeight = (isMobile() ? 81 : 65) + HeightOfCommentToolbar;
    const footerHeight = this.calcFooterHeight(formHeight);
    return (
      <StyledModalWrap>
        <StyledModalBackdrop className="issue-modal-backdrop" onClick={onClose}/>
        <StyledModalDialog className="mobile-modal-dialog-custom">
          {(saving || loading) ? <Loader/> : null}
          <StyledModal className="modal-styled-custom">
            <StyledIssueModalHeader severityColor={currentIssue.SeverityColor} className="modal-header-custom">
              <div className="back-btn" onClick={onClose}/>
              <h4>Discuss Issue</h4>
              <StyledCloseModal className="icon-close-modal" onClick={onClose}/>
            </StyledIssueModalHeader>
            <StyledIssueModalBody bottom={`${footerHeight}px`}>
              <IssueSubHeader issue={currentIssue}/>
              <StyledModalBodyContent>
                {
                  currentIssue.comments && currentIssue.comments.length ? currentIssue.comments.map((c, i) => (
                    <Row key={`comment-${i}`}>
                      <Col md={12} xs={12}>
                        <div
                          className={classNames({
                            'comment-item': true,
                            'content': true,
                            [`comment-${c.StatusID ? `-client-status-${c.StatusID}` : 'c'}`]: true,
                            'highlight': currentIssueCommentId === c.CommentID || (currentIssueClientStatusId && currentIssueClientStatusId === c.StatusID)
                          })}
                          id={c.CommentID ? `comment-item-${c.CommentID}` : undefined}
                          >
                          {
                            c.UserImageUrl ? (
                              <div className="user-column-logo">
                                <UserLogoStyled logoUrl={c.UserImageUrl}></UserLogoStyled>
                              </div>
                            ) : (
                              <div className="user-column">
                                <UserNameShortStyled className="username-short" bgColor={userNamesColors[c.UserName || 'No Name']}>
                                  {this.getNameFirstLetters(c.UserName || 'No Name')}
                                </UserNameShortStyled>
                              </div>
                            )
                          }
                          <div className="comment-column">
                            <div className="username">
                              {c.UserName || <>&nbsp;</>}
                            </div>
                            <div className="comment-text">
                              <CommentContent comment={c} prefixKey="mci"/>
                            </div>
                            <div className="comment-date">
                              {this.prepareDate(c)}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )) : <div className="no-comments">There are no comments yet</div>
                }
              </StyledModalBodyContent>
            </StyledIssueModalBody>
            <StyledModalFooter className="comments-modal-footer">
              <CommentForm
                commentType="issue"
                isLoading={saving || loading}
                requestId={requestId}
                onGetMentions={getMentions}
                onSave={onSend}
                height={formHeight}
                calcModalFooterHeight={this.calcFooterHeight}
                placeholder="Your response..."
                requestImmediateResponseForMentionType="EDMS"
              />
            </StyledModalFooter>
          </StyledModal>
        </StyledModalDialog>
      </StyledModalWrap>
    );
  }
}

export const ContentStyled = styled.div`
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  .content-column {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
`;

export const UserLogoStyled = styled.span`
  background-color: transparent;
  background-image: url(${props => props.logoUrl});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1 1 100%;
  height: 100%;
  width: 100%;
  border-radius: 100px;
`;

export const UserNameShortStyled = styled.div`
  background: ${props => props.bgColor};
  width: 33px;
  height: 33px;
  border-radius: 50%;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #fff;
  margin-right: 11px;
  padding-top: 9px;
  text-align: center;
`;

const StyledModalBodyContent = styled.div`
  padding: 31px 45px 0 50px;

  @media (max-width: 767px) {
    padding: 22px 0 0 23px;
  }

  @media (min-width: 992px) {
    width: 71.75%;
  }
  .username {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 14px;
    color: #000000;
    padding: 10px 0;

    @media (max-width: 767px) {
      font-size: 15px;
      line-height: 17px;
      padding: 5px 0;
    }
  }
  .comment-text {
    font-style: normal;
    font-weight: normal;
    font-size: 13.5px;
    line-height: 1.6em;
    color: rgb(51, 51, 51);
    word-break: break-word;
    white-space: pre-wrap;

    @media (max-width: 767px) {
      line-height: 17px;
    }
  }
  .comment-date {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    color: #000000;
    opacity: 0.5;
    padding: 5px 0 10px;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 14px;
      padding: 10px 0 14px;
    }
  }
  .comment-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 23px;
    display: flex;
    flex-wrap: nowrap;
    &.highlight {
      border-top-left-radius: 14px;
      animation: highlight-animation 1s ease-in;
      @keyframes highlight-animation {
        from {
          background-color: #e5e5e5;
        }
        to {
          background-color: unset;
        }
      }
    }

    &:hover {
      background-color: rgb(249, 249, 249);
    }

    .user-column {
      flex-grow: 1;
      max-width: 44px;
    }

    .user-column-logo {
      flex-grow: 1;
      min-width: 33px;
      max-width: 33px;
      height: 33px;
      margin-right: 11px;
    }

    .comment-column {
      flex-grow: 2;
      word-break: break-all;
    }

    @media (max-width: 767px) {
      margin-bottom: 12px;
      border-bottom: 0;

      .comment-column {
        border-bottom: 1px solid rgba(0,0,0,0.1);
        padding-right: 10px;
      }
    }
  }
`;

const StyledModalFooter = styled.div`
  background-color: #F8F8F8;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
  height: ${90 + HeightOfCommentToolbar}px;
  padding: 12px 14px 12px 13px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 10%;
  z-index: 1060;

  @media (max-width: 991px) {
    left: 2.66%;
  }

  @media (max-width: 767px) {
    padding: 0;
    height: ${81 + HeightOfCommentToolbar}px;
    background-color: #fff;
    border: none;
  }
`;

const StyledModalWrap = styled(StyledMobileModal)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1039;
`;

IssueDiscussModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  requestId: PropTypes.number.isRequired,
  currentIssue: PropTypes.object.isRequired,
  currentIssueCommentId: PropTypes.number,
  currentIssueClientStatusId: PropTypes.number,
  onScrollToComment: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onClose: PropTypes.func
};

IssueDiscussModal.defaultProps = {
  onClose: () => {}
};

export default IssueDiscussModal;
