import React from 'react';

function HeaderRow(props) {
  return (
    <tr>
      <th className="sort">
        <i className="fa fa-exchange-alt"/>
      </th>
      <th className="step-order">
        Step
      </th>
      <th className="step-label">
        Label
      </th>
      <th className="step-description">
        Description
      </th>
      <th className="product-code">
        Product Code
      </th>
      <th className="step-unit">
        Unit
      </th>
      <th className="override-rate">
        Override Rate
      </th>
      <th className="assignee">
        Assigned To
      </th>
      <th className="category-flags">
        Category
      </th>
      <th className="status">
        Status
      </th>
      <th className="time-spent">
        Time Spent
      </th>
      <th className="is-billable">
        Is Billable
      </th>
      <th className="quantity">
        Quantity
      </th>
      <th className="estimated-hours">
        Est. Hours
      </th>
      <th className="actions"/>
    </tr>
  );
}

export default HeaderRow;