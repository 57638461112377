import {createGlobalStyle} from 'styled-components';

import robotoBoldFont from './assets/fonts/Roboto/Roboto-Bold.ttf';
import robotoLightFont from './assets/fonts/Roboto/Roboto-Light.ttf';
import robotoMediumFont from './assets/fonts/Roboto/Roboto-Medium.ttf';
import robotoRegularFont from './assets/fonts/Roboto/Roboto-Regular.ttf';
import robotoThinFont from './assets/fonts/Roboto/Roboto-Thin.ttf';
import robotoBlackFont from './assets/fonts/Roboto/Roboto-Black.ttf';
import icomoonFont from './assets/fonts/Icomoon/icomoon.ttf';

export default createGlobalStyle`
  @font-face {
    font-family: Roboto;
    src: url(${robotoThinFont});
    font-weight: 100;
  }

  @font-face {
    font-family: Roboto;
    src: url(${robotoLightFont});
    font-weight: 300;
  }

  @font-face {
    font-family: Roboto;
    src: url(${robotoRegularFont});
    font-weight: 400;
  }

  @font-face {
    font-family: Roboto;
    src: url(${robotoMediumFont});
    font-weight: 500;
  }

  @font-face {
    font-family: Roboto;
    src: url(${robotoBoldFont});
    font-weight: 700;
  }

  @font-face {
    font-family: Roboto;
    src: url(${robotoBlackFont});
    font-weight: 900;
  }

  @font-face {
    font-family: 'icomoon';
    src:  url(${icomoonFont});
  }

  body {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    color: #282828;
    margin: 0;
    padding: 0;
    background: linear-gradient(122.07deg, #0DB4D9 0%, rgba(2, 80, 173, 0.25) 99.75%), #004A8D no-repeat;
    &.search-open {
      overflow: hidden;
    }
    &.full-screen {
      overflow: hidden;
    }
    &.ReactModal__Body--open .ReactModal__Overlay.ReactModal__Overlay--after-open {
      z-index: 1100 !important;
    }
    &.modal-open--overflow-auto {
      overflow: auto !important;
      padding: 0 !important;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background: rgba(255, 255, 255, 1);
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgba(0, 0, 0, .2);
      -webkit-box-shadow: none;
    }
  }
  
  a {
    color: #428bca;
    cursor: pointer;
    transition: all ease 0.6s;
    outline: 0;
    &.red {
      color: #ff0000;
    }
  }
  
  a img {
    border: 0;
  }
  
  a, ul, li, a:hover, a:focus {
    text-decoration: none;
  }
  
  h1 {
    color: #1b212e;
    font-size: 21px;
    font-weight: 700;
    letter-spacing: 2px;
    margin: 50px 0 25px;
    text-transform: uppercase;
  }
  
  .nomargin, .no-margin {
    margin: 0 !important;
  }
  
  .nopadding, .no-padding {
    padding: 0 !important;
  }
  
  .container {
    padding: 0 15px;
    margin: 0 auto;
  }
  
  th {
    text-align: center;
  }
  
  /* Spinners */
  .loader-container {
    position: relative;
    text-align: center;
    padding: 20px;
    &.full-screen {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.5);
      z-index: 100000;
      display: flex;
      align-items: center;
      justify-content: center;
      .react-spinner {
        top: 0 !important;
      }
    }
  }
  
  .react-spinner {
    position: relative;
    width: 32px;
    height: 32px;
    display: inline-block;
    animation: fade-in .3s ease-in;
  }
  
  .react-spinner_bar {
    animation: react-spinner_spin 1.2s linear infinite;
    border-radius: 5px;
    background-color: #29d;
    position: absolute;
    width: 20%;
    height: 7.8%;
    top: 39.9%;
    left: 40%;
  }
  
  @keyframes react-spinner_spin {
   0% { opacity: 1; }
   100% { opacity: 0.15; }
  }
  
  @-moz-keyframes react-spinner_spin {
   0% { opacity: 1; }
   100% { opacity: 0.15; }
  }
  
  @-webkit-keyframes react-spinner_spin {
   0% { opacity: 1; }
   100% { opacity: 0.15; }
  }
  
  /* Table Container */
  .table-contaner {
    position: relative;
  }
  
  .table-contaner .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 2;
  }
  
  .table-contaner .react-spinner {
    top: 49%;
  }
  
  .react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.7);
    z-index: 10600;
  }
  .react-confirm-alert-body {
    width: 693px;
    padding: 27px 162px 24px 34px;
    background: #FFFFFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    h1 {
      padding: 5px 0;
      font-weight: 200;
      font-size: 36px;
      line-height: 37px;
      color: #000000;
      text-transform: unset;
      letter-spacing: 0;
      margin: 0 0 9px;
    }
    .react-confirm-alert-button-group {
      display: flex;
      justify-content: flex-end;
      margin: 34px -128px 0 0;
      button {
        font-weight: bold;
        font-size: 15px;
        line-height: 21px;
        align-items: center;
        justify-content: center;
        background: transparent;
        margin: 0;
        padding: 0;
        color: #BBBBBB;
        &:last-child {
          color: #5D3996;
        }
        & + button {
          margin-left: 40px;
        }
      }
    }

    &.replacement-company-type-screen {
      @media (min-width: 768px) {
        width: auto;
        h1 {
          font-size: 20px;
          line-height: 30px;
        }
      }
      .react-confirm-alert-button-group {
        button {
          margin: 0;
          color: #5D3996;
          &:hover {
            color: #000;
          }
          & + button {
            margin-left: 15px;
          }
        }
      }
    }

    @media (max-width: 767px) {
      width: 92vw;
      padding: 22px 32px 17px 28px;
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      font-size: 13px;
      line-height: 21px;
      h1 {
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
        padding: 0;
        margin-bottom: 4px;
      }
      .react-confirm-alert-button-group {
        margin: 28px 0 0;
      }
    }
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .container {
      width: 992px;
    }
  }
  
  @media (min-width: 1600px) {
    #mainNav .container {
      width: 1170px;
    }
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .fadeIn {
    animation: fade-in .35s ease-in;
  }
  
  .container {
    width: 100% !important;
  }
  
  ::-webkit-input-placeholder {
    opacity: 1;
    color: rgb(117, 117, 117) !important;
    font-weight: 400 !important;
  }
  :-moz-placeholder { /* Firefox 18- */
    opacity: 1;
    color: rgb(117, 117, 117);
    font-weight: 400;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    opacity: 1;
    color: rgb(117, 117, 117);
    font-weight: 400;
  }
  :-ms-input-placeholder {
    opacity: 1;
    color: rgb(117, 117, 117);
    font-weight: 400;
  }
  
  @media (max-width: 1200px - 1) {
    .container {
      overflow: hidden;
    }
  }
  
  @media (min-width: 992px) {
    main {
      min-height: calc(100vh - 59px);
    }
  }
  
  table-info {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 18px;
  }
  
  .table-buttons-container {
    float: right;
    width: 194px;
    z-index: 1;
  }
  
  .uploads-table {
    margin-bottom: 124px;
  
    table.table {
      border-collapse: separate;
      margin-bottom: 0;
      &.table-striped tbody {
        tr:nth-of-type(odd) {
          background-color: transparent;
          border-top: 0;
        }
        tr:nth-of-type(even) {
          background-color: #ececec;
        }
      }
      &.no-footer {
        border-bottom: 0;
      }
      th {
        background-color: #91949c;
        border-bottom: 0;
        color: #fff;
        font-size: 12px;
        padding: 13px 18px;
        text-transform: uppercase;
        vertical-align: middle;
      }
      td {
        font-size: 13px;
        text-align: center;
        vertical-align: middle;
        &.gray {
          color: gray;
        }
        &.red {
          color: red;
        }
      }
      a {
        font-weight: bold;
      }
    }
  }
  
  @media (max-width: 991px) {
    .table-buttons-container {
      float: none;
      margin-bottom: 10px;
    }
  }
  
  .Select.is-searchable.Select--single:not(.select-in-cell) {
    font-family: 'Roboto', sans-serif;
  
    &.select-with-clear *:not(.Select-clear-zone,.Select-clear), &:not(.select-with-clear) * {
      font-size: 13px !important;
      color: #000 !important;
    }
  
    &.is-open {
  
      .Select-control {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  
    .Select-control {
      border-radius: 5px;
      box-shadow: none;
      outline: none;
      height: 39px;
  
      .Select-multi-value-wrapper {
  
        .Select-placeholder, .Select-value {
          line-height: 17px;
          padding: 12px 11px 8px;
        }
  
        .Select-value .Select-value-label {
          font-weight: 500;
        }
  
        .Select-input {
          height: 37px;
          padding-left: 11px;
          padding-right: 11px;
  
          input {
            line-height: 17px;
            padding-top: 12px;
            padding-bottom: 8px;
          }
        }
      }
  
      .Select-arrow-zone {
        width: 36px;
        padding-top: 1px;
  
        img {
          opacity: 0.3;
          width: 14.57px;
        }
      }
    }
  
    .Select-menu-outer {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      max-width: calc(100% - 1px);
    }
  
    .Select-option:last-child {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  
    &:not(.is-open), &:not(.is-focused) {
  
      .Select-control {
        background-color: #F3F3F3;
        border: 1px solid #E9E9E9;
      }
    }
  
    &:not(.is-disabled) .Select-control:hover {
      background-color: #fff;
    }
  }
  
  .switch-field {
    .react-switch {
      &+span {
        vertical-align: top;
        font-weight: 500;
        font-size: 13px;
        line-height: 28px;
        color: #000000;
        opacity: 0.5;
        padding-left: 9px;
      }
  
      &.checked {
        &+span {
          opacity: 1;
        }
        .react-switch-bg {
          border: 1px solid #EAEAEA;
          box-sizing: border-box;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
          border-radius: 100px;
        }
      }
    }
  }
  
  /* ICONS */
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-avails-episodes:before {
    content: "\\21";
  }
  .icon-avails-titles:before {
    content: "\\23";
  }
  .icon-circle-check:before {
    content: "\\24";
  }
  .icon-create-request:before {
    content: "\\25";
  }
  .icon-close-wizard:before {
    content: "\\26";
  }
  .icon-back-arrow:before {
    content: "\\27";
  }
  .icon-search:before {
    content: "\\28";
  }
  .icon-filter:before {
    content: "\\29";
  }
  .icon-trash:before {
    content: "\\30";
  }
  .icon-cloud-upload:before {
    content: "\\31";
  }
  .icon-info-circle:before {
    content: "\\32";
  }
  .icon-info-circle-white:before {
    content: "\\33";
  }
  .icon-sorting:before {
    content: "\\34";
  }
  .icon-close-modal:before {
    content: "\\35";
  }
  .icon-comment:before {
    content: "\\36";
  }
  
  @media (max-width: 768px) {
    body {
      background: linear-gradient(100.26deg, #0DB4D9 0%, rgba(2, 80, 173, 0.25) 99.75%), #004A8D;
    }
  }

  @media (pointer:coarse) {
    body.full-screen {
      position: fixed;
    }
    body.modal-open {
      overflow: hidden;
    }
    body.mobile-header-menu-open {
      overflow: hidden;
    }
    body.fixed {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1;
    }
  }

  .modal-backdrop.in {
    &.edit-date-modal-backdrop {
      opacity: 0.4;
      z-index: 1070;
      & + .modal.in {
        z-index: 1071;
      }
    }
    &.quick-update-modal-backdrop {
      opacity: 0.1;
    }
  }
`;
