import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid} from 'recharts';
import NoData from './NoData';

const HEIGHT = 20;

class Chart extends Component {
  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  customBarLabel = ({x, y, width, height, value}) => {
    const {showCurrency} = this.props;
    const {showPercent} = this.props;
    return (
      <text x={x + width + 10} y={y + height / 2 + HEIGHT / 4} fill="#666" fontWeight="bold">
        {showCurrency ? '$' : ''}{ showCurrency ? value.toFixed(2) : value }{showPercent ? '%' : ''}
      </text>
    );
  };

  customBar({fill, x, y, width, height}) {
    const myHeight = height > 16 ? `${HEIGHT}px` : height;
    return <rect x={x + 1} y={y + height / 2 - HEIGHT / 2} width={width} height={myHeight} fill={fill}/>;
  }

  render() {
    const {data, color} = this.props;

    if ([undefined, null].includes(data) || !data.length) {
      return <NoData/>;
    }

    return (
      <ChartStyled>
        <BarChart
          width={600}
          height={200}
          data={data}
          layout="vertical"
          margin={{right: 70, left: 10, top: 0}}
        >
          <XAxis type="number" dataKey="value" hide/>
          <YAxis type="category" dataKey="name" width={300} interval={0} tick={{dx: -10}} />
          <CartesianGrid stroke="#D6D6D6" strokeDasharray="1" horizontal={false}/>
          <Bar dataKey="value" fill={color} height={16} label={this.customBarLabel} shape={this.customBar}/>
        </BarChart>
      </ChartStyled>
    );
  }
}

const ChartStyled = styled.div`
  padding:  10px 15px;
  .recharts-wrapper, .recharts-surface {
    width: 100% !important;
  }
  @media (max-width: 991px) {
    text.recharts-text.recharts-cartesian-axis-tick-value * {
      font-size: 16px;
    }
  }
`;

Chart.propTypes = {
  data: PropTypes.array,
  color: PropTypes.string,
  showCurrency: PropTypes.bool
};

export default Chart;
