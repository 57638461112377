import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loader from '../../../components/Loader';
import Table from '../../../components/table/Table';
import TitleCell from './tablecells/TitleCell';
import DateCell from '../../../components/table/cells/DateCell';
import {SubmittedOnCell} from './tablecells/SubmittedOnCell';
import {RequestStatusCell} from './tablecells/RequestStatusCell';
import {QcStatusCell} from './tablecells/QcStatusCell';
import {ActionsCell} from './tablecells/ActionsCell';
import {WOMemfisCell} from './tablecells/WOMemfisCell';
import {isManagerUser, isStudioUser, isStudioPartnerUser, isDwManagerUser, isOperatorUser} from '../../../utils';
import classNames from 'classnames';

const ClientCell = React.memo(({value, title, className}) => (
  <td className={className} title={title}>
    {value === 'Finishing House' ? 'Post Partner' : value}
  </td>
));

const YesNoCell = React.memo(({value, title, className}) => (
  <td className={className} title={title}>
    {value ? 'Yes' : 'No'}
  </td>
));

class RequestsTable extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.headers = this.getHeaders();
  }

  getHeaders() {
    if (isManagerUser()) {
      const isDwManager = isDwManagerUser();
      const dwHeaders = isDwManager ? {
        DwQcStatus: {
          title: 'DW QC Status',
          className: 'dw-qc-status label-from-title',
        },
        DwOperator: {
          title: 'DW Operator',
          className: 'dw-operator label-from-title',
        },
        hasProxy: {
          title: 'Proxy',
          className: 'dw-has-proxy label-from-title',
          component: YesNoCell
        },
        hasCaptions: {
          title: 'Subs',
          className: 'dw-has-captions label-from-title',
          component: YesNoCell
        },
      } : {};
      return {
        ...(isDwManager ? {
        } : {
          CreatedBy: {
            title: 'Created By',
            className: 'created-by label-from-title'
          },
          Client: {
            title: 'Client',
            className: 'client',
            component: ClientCell
          },
        }),
        Studio: {
          title: 'Studio',
          className: 'studio'
        },
        Title: {
          title: 'Title',
          className: 'request-title label-from-title'
        },
        RequestFormat: {
          title: 'Format',
          className: 'request-format label-from-title'
        },
        ...(isDwManager ? {
          DueDate: {
            title: 'Due Date',
            className: 'due-date submitted-on label-from-title pre',
            component: DateCell,
            componentArgs: {
              toFormat: 'll[\r\n]hh:mm A z',
              useTimezone: true
            }
          },
        } : {
          SubmittedOn: {
            title: 'Submitted On',
            className: 'submitted-on label-from-title pre',
            component: DateCell,
            componentArgs: {
              toFormat: 'll[\r\n]hh:mm A z',
              useTimezone: true
            }
          },
          Distributor: {
            title: 'Distributor',
            className: 'distributor label-from-title'
          },
        }),
        RequestStatus: {
          title: 'Request Status',
          className: 'status',
          component: RequestStatusCell
        },
        QcStatus: {
          title: 'QC Status',
          className: 'qc-status',
          component: QcStatusCell
        },
        ...dwHeaders,
        WOMemfis: {
          title: 'WO #',
          className: 'womemfis label-from-title',
          component: WOMemfisCell
        },
        RequestID: {
          title: '-',
          className: 'actions',
          component: ActionsCell,
          componentArgs: {openQuickUpdateDatePopup: this.props.openQuickUpdateDatePopup}
        }
      };
    }

    const isStudio = isStudioUser();
    let headersList = {
      Studio: {
        title: 'Studio',
        className: `studio${isStudio ? ' hidden' : ''}`
      },
      Title: {
        title: 'Request Title',
        className: `request-title label-from-title${isStudio ? ' is-client' : ''}`,
        component: TitleCell
      },
      RequestFormat: {
        title: 'Format',
        className: 'request-format label-from-title'
      },
      SubmittedOn: {
        title: 'Submitted On',
        className: `submitted-on${isStudio ? ' is-client' : ''}`,
        component: SubmittedOnCell
      },
      request_sla: {
        title: 'Due',
        className: 'due'
      },
      RequestedBy: {
        title: 'Requested By',
        className: 'requested-by'
      },
      RequestStatus: {
        title: 'Request Status',
        className: 'status',
        component: RequestStatusCell
      },
      QcStatus: {
        title: 'QC Status',
        className: 'qc-status',
        component: QcStatusCell
      }
    };

    if (isOperatorUser()) {
      headersList = {
        ...headersList,
        DwQcStatus: {
          title: 'DW QC Status',
          className: 'dw-qc-status label-from-title',
        },
        WOMemfis: {
          title: 'WO #',
          className: 'womemfis label-from-title',
          component: WOMemfisCell
        }
      }
    }

    return headersList;
  }

  render() {
    const {loading, data, tableSort, onHeaderCellClick, onLoadMore} = this.props;
    const tableClassNames = classNames({
      'table-contaner': true,
      'std-requests-table': true,
      'client-service': isManagerUser(),
      'dw-manager': isDwManagerUser(),
      'studio-s': isStudioUser(),
      'studio-partner': isStudioPartnerUser(),
      'operator': isOperatorUser()
    });
    return (
      <StyledTable className="std-requests">
        <div className={tableClassNames}>
          {loading ? <Loader/> : null}
          <div className="table-block">
            <Table
              headers={this.headers}
              rows={data}
              stripped={false}
              sort={tableSort}
              onHeaderCellClick={onHeaderCellClick}
              emptyText={loading ? null : 'No Data'}
              onLoadMore={onLoadMore}
              />
          </div>
        </div>
      </StyledTable>
    );
  }
}

const StyledTable = styled.div`
  .loader-container {
    width: 100%;
    z-index: 3;
  }

  &.load-more, .table-body--load-more {
    pointer-events: none;
    opacity: 0.65;
  }

  &.std-requests {
    padding: 24px 0 0 20px;

    .std-requests-table {
      margin-bottom: 0;
  
      .table {
        display: block;
    
        thead {
          position: sticky;
          top: 0;
          display: block;
          overflow-y: scroll;
    
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: none;
            background-color: transparent;
          }
    
          &::-webkit-scrollbar {
            width: 8px;
            background-color: transparent;
          }
    
          &::-webkit-scrollbar-thumb {
            -webkit-box-shadow: none;
            background-color: transparent;
          }

          th {
            white-space: nowrap;
          }
        }
    
        tbody {
          max-height: calc(100vh - 59px - 54px - 111px);
          display: block;
          overflow-y: scroll;
    
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: none;
            background-color: transparent;
          }
    
          &::-webkit-scrollbar {
            width: 8px;
            background-color: transparent;
          }
    
          &::-webkit-scrollbar-thumb {
            border-radius: 100px;
            -webkit-box-shadow: none;
            background-color: #c4c4c4;
          }

          @media (max-width: 767px) {
            .requests-content & {
              &::-webkit-scrollbar {
                width: 0;
              }
              &::-webkit-scrollbar-thumb {
                background-color: transparent;
              }
            }
          }

          tr:hover {
            background-color: #F9F9F9;
            cursor: default;

            &:not(:last-child) {
              box-shadow: 0 20px 25px rgba(0, 0, 0, 0.05);
            }
          }
        }
    
        tr {
          display: block;
          padding-right: 23px;
          
          &:first-of-type {
            border-top: 0;
            td {
              border: 0;
            }
          }
    
          &:before, &:after {
            display: table;
            content: " ";
            clear: both;
          }

          th, td {
            display: block;
            float: left;
            width: 11%;
            color: #000;

            &:first-child {
              padding-left: 23px;
              padding-right: 16px;
            }

            &.request-title {
              width: 20%;
              word-break: break-word;
              &.is-client {
                width: 29%;
              }
            }

            &.submitted-on {
              width: 11%;
              &.is-client {
                width: 16%;
              }
            }
          }
    
          th {
            font-weight: bold;
            font-size: 12px;
            line-height: 13px;
            background: #fff;
            border-radius: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            cursor: pointer;
            padding-top: 15px;
            padding-bottom: 15px;
            text-align: left;
            display: flex;
          }
    
          td {
            padding-top: 23px;
            padding-bottom: 23px;
            font-weight: normal;
            font-size: 13px;
            line-height: 14px;
    
            a {
              font-weight: bold;
              color: #29A4CB;
    
              &:hover {
                color: #228daf;
              }
            }

            &.request-format, &.womemfis {
              word-break: break-all;
            }

            &.data-table-empty {
              text-align: center;
              width: 100% !important;
            }
          }
        }
      }
    
      &.client-service .table tr {
        td {
          &.pre {
            @media (min-width: 768px) {
              white-space: pre;
            }
          }
        }

        th, td {
          &.created-by, &.submitted-on {
            width: 11%;
          }
          &.request-title {
            width: 14%;
          }
          &.request-format {
            width: 9%;
          }
          &.womemfis {
            width: 7%;
          }
          &.studio, &.distributor, &.status, &.qc-status {
            width: 7%;
          }
          &.client {
            width: 7%;
          }
        }
        th.actions {
          color: transparent;
          pointer-events: none;
        }
      }

      &.dw-manager .table tr {
        th, td {
          width: 8%;
          &.request-title {
            width: 15%;
          }
          &.due-date {
            width: 8%;
          }
          &.studio {
            width: 9%;
          }
          &.status {
            width: 9%;
          }
          &.dw-has-captions, &.dw-has-proxy {
            width: 6%;
          }
        }
      }

      &.operator .table tr {
        th, td {
          width: 10%;
          &.request-title {
            width: 20%;
          }
          &.due {
            width: 8%;
          }
          &.womemfis {
            width: 7%;
          }
          &.status {
            width: 8%;
          }
          &.qc-status {
            width: 8%;
          }
          &.dw-qc-status {
            width: 8%;
          }
        }
      }
    }

    @media (max-width: 991px) {
      .requests-content & {
        padding: 2px 0 0;
      }
    }

    @media (max-width: 767px) {
      .requests-content & {
        padding: 7px 0 0;

        .std-requests-table {

          .table {
            thead {
              display: none;
            }
            tbody {
              tr {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border-bottom: 0.5px solid #D3D3D3;
                &:hover {
                  background-color: #fff;
                  box-shadow: none;
                }
                td {
                  border: none;
                  padding: 0 0 0 15px;
                  width: 100% !important;
                  float: none;
                  &.due, &.requested-by, &.assigned-to, &.womemfis, &.dw-qc-status {
                    display: none;
                  }
                }
              }
            }
          }

          &.studio-partner, &.studio-s, &.operator {
            .table tbody tr td {
              &.request-title {
                order: 1;
              }
              &.studio, &.request-format {
                order: 2;
                color: #000 !important;
              }
              &.submitted-on {
                order: 3;
              }
              &.status {
                order: 5;
              }
              &.qc-status {
                order: 4;
              }
              &.qc-status, &.studio, &.request-format {
                font-weight: bold;
                font-size: 11px !important;
                line-height: 12px !important;
                padding-bottom: 5px !important;
                &:before {
                  content: attr(title);
                  color: #000000;
                  font-weight: normal;
                  font-size: 11px;
                  padding-right: 5px;
                }
              }
            }
          }

          &.client-service {
            .table tbody {
              tr {
                td {
                  width: 100% !important;
                  padding-bottom: 5px;
                  font-weight: bold;

                  &:first-child {
                    padding-top: 15px !important;
                  }
                  &:last-child {
                    padding-bottom: 15px !important;
                    a:first-child {
                      margin-bottom: 10px;
                    }
                  }

                  &.client, &.studio, &.status, &.label-from-title, &.qc-status {
                    &:before {
                      content: attr(title);
                      color: #000000;
                      font-weight: normal;
                      font-size: 11px;
                      padding-right: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

RequestsTable.defaultProps = {
  data: []
};

RequestsTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  tableSort: PropTypes.object.isRequired,
  onSearch: PropTypes.func,
  onHeaderCellClick: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired
};

export default RequestsTable;
