import React from 'react';
import PropTypes from 'prop-types';
import {formatDate, formatDateTz} from '../../../utils';

export class DateCell extends React.PureComponent {
  render() {
    const {value, fromFormat, toFormat, className, title, useTimezone} = this.props;
    return (
      <td className={className} title={title}>
        {useTimezone ? formatDateTz(value, fromFormat, toFormat) : formatDate(value, fromFormat, toFormat)}
      </td>
    );
  }
}

DateCell.propTypes = {
  value: PropTypes.any,
  fromFormat: PropTypes.any,
  toFormat: PropTypes.any,
  useTimezone: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string
};

export default DateCell;
