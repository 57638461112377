import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CostCell = ({value, row, className, onChange, isDecimal, isPercent}) => {
  if (value === null && !onChange) {
    return (
      <StyledCell className={className}>
        <span title="Could not calculate rate" className="no-rate">N/A</span>
      </StyledCell>
    );
  }

  const formatValue = () => {
    const cost = parseFloat(value, 10);
    const isCorrectNumber = typeof cost === 'number' && !isNaN(cost);
    const temp = isCorrectNumber ? String(Math.abs(cost).toFixed(2)).split('.') : null;
    if (temp) {
      if (isPercent && isPercent(row)) {
        return <>{cost}%</>;
      }
      return <span className={cost < 0 ? 'negative' : undefined}><strong>{isDecimal ? '' : '$'}{temp[0]}</strong>.{temp[1]}</span>;
    }
    return value || '';
  };

  return (
    <StyledCell className={className}>
      {formatValue()}
    </StyledCell>
  );
};

CostCell.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string
};

const StyledCell = styled.td`
  .no-value {
    font-weight: normal;
    opacity: 0.75;
  }

  .no-rate {
    font-weight: bold;
    color: #E75592;
  }

  span {
    &.negative {
      position: relative;
      &:before {
        content: '-';
        position: absolute;
        left: -5px;
      }
    }
  }
`;

export default CostCell;
