import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {getSeasonDetails, selectSeason, clearSeasonDetails} from './actions';
import PageComponent from '../../components/PageComponent';
import AppLayout from '../../layouts/AppLayout';
import Loader from '../../components/Loader';
import Title from './components/Title';
import Tabs from './components/Tabs';
import ClientServiceCreateRequestModal from './components/modals/ClientServiceCreateRequestModal';

class SeasonDetails extends PageComponent {
  UNSAFE_componentWillMount() {
    const {dispatch, history} = this.props;
    const requestId = this.getRequestId();
    dispatch(getSeasonDetails(history, requestId));
    dispatch(selectSeason(requestId));
  }

  componentWillUnmount() {
    const {dispatch} = this.props;
    dispatch(clearSeasonDetails());
  }

  getRequestId() {
    const {match} = this.props;
    return match.params.requestId;
  }

  changeSeason = requestId => {
    const {dispatch, history} = this.props;
    history.replace({pathname: `/requests/seasons/${requestId}`});
    dispatch(getSeasonDetails(history, requestId));
    dispatch(selectSeason(requestId));
  };

  render() {
    const {loading, details, csCreateRequestModalData} = this.props.seasondetails;

    return (
      <AppLayout
        className="without-main-header"
        title="Season Details"
      >
        <StyledSeasonDetails>
          {loading ? <Loader/> : null}
          {csCreateRequestModalData ? <ClientServiceCreateRequestModal data={csCreateRequestModalData}/> : null}
          <Title title={details.series_name} loading={loading}/>
          <Tabs
            seasonDetails={this.props.seasondetails}
            onChangeSeason={this.changeSeason}
          />
        </StyledSeasonDetails>
      </AppLayout>
    )
  }
}

const StyledSeasonDetails = styled.div`
  background-color: #fff;
  min-height: calc(100vh - 59px);
  padding-top: 25px;
  position: relative;

  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 3;

    .react-spinner {
      top: 49%;
    }
  }
`;

SeasonDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user,
    seasondetails: state.seasondetails
  };
}

export default connect(
  mapStateToProps
)(withRouter(SeasonDetails));
