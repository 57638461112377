import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {Button} from 'react-bootstrap';
import {
  getIssueCommentsModal, getIssueCommentsSidebar, changeVideoParams, showIssueImageModal, showAddIssueModal,
  addGeneralComment
} from '../../actions';
import Loader from '../../../../components/Loader';
import Issue from './Issue';
import CaptionCue from './CaptionCue';
import currentPositionImg from '../../../../../assets/images/video/current-position.svg';
import {getUserRoles, isUserCanComment} from '../../../../utils';
import Can from '../../../../components/Can';
import GeneralComments from './GeneralComments';
import qs from 'qs';
import _ from 'lodash';

class Comments extends Component {
  handleClickAddIssueForCaptionCue = cue => {
    const {dispatch} = this.props;
    const {ProxyTimeCodeStart, ProxyTimeCodeEnd, CueID, TimeCodeStart, TimeCodeEnd} = cue;
    const newIssueData = {
      forCaptionCueID: CueID,
      ProxyTimeCodeStart,
      ProxyTimeCodeEnd,
      TimeCodeStart,
      TimeCodeEnd,
    };
    dispatch(showAddIssueModal(true, newIssueData));
  };

  handleSelectCue = cue => {
    const {dispatch} = this.props;
    dispatch(changeVideoParams({selectedCue: cue, isChangedSelecting: true}));
  };

  handleClickEditIssue = issue => {
    const {dispatch} = this.props;
    dispatch(showAddIssueModal(true, issue));
  };

  handleClickIssueImage = issue => {
    const {dispatch} = this.props;
    dispatch(showIssueImageModal(issue));
  };

  handleDiscussIssue = issue => {
    const {dispatch} = this.props;
    dispatch(getIssueCommentsModal(issue));
  };

  handleSelectIssue = issue => {
    const {dispatch} = this.props;
    dispatch(changeVideoParams({selectedIssue: issue, isChangedSelecting: true}));
  };

  handleClickMore = issue => {
    const {dispatch} = this.props;
    dispatch(getIssueCommentsSidebar(issue));
  };

  isInteractiveView() {
    const {reportdetails} = this.props;
    return reportdetails.activeTopTab === 2;
  }

  getIssuesByTab() {
    const isInteractiveView = this.isInteractiveView();
    return isInteractiveView ? this.getIssuesWithCurrentPosition() : this.filteredIssuesByTab();
  }

  filteredIssuesByTab() {
    const {reportdetails} = this.props;
    const isInteractiveView = this.isInteractiveView();
    return isInteractiveView ? reportdetails.issues : reportdetails.issues.filter(item => item.Comments);
  }

  getIssuesWithCurrentPosition() {
    /*const {reportdetails} = this.props;
    const indexCurrent = reportdetails.videoPlayer.currentIssueID;*/
    const res = [...this.filteredIssuesByTab()];
    /*if (indexCurrent) {
      const index = res.map(item => item.IssueID).indexOf(indexCurrent);
      res.splice(index + 1, 0, {type: 'current'});
    } else if (res.length) {
      res.splice(0, 0, {type: 'current'});
    }*/
    return res;
  }

  getIssuesForCaptionCues = (captionCues, issues) => {
    const issuesForCaptionCues = {};
    if (!issues || !issues.length) {
      return [issuesForCaptionCues, captionCues];
    }

    const cueIDsWithIssues = [];
    const tempCues = captionCues.map(cue => ({...cue, _key: `cue--${cue.CueID}`}));
    const tempArr = issues.map(issue => {
      const tempIssue = {...issue, _key: `issue--${issue.IssueID}`};
      const tempCue = tempCues.find(cue => cue.ProxyTimeCodeStart === tempIssue.ProxyTimeCodeStart);
      if (tempCue) {
        const {CueID} = tempCue;
        cueIDsWithIssues.push(CueID);
        issuesForCaptionCues[CueID] = [...(issuesForCaptionCues[CueID] || []), tempIssue];
        return tempCue;
      }
      return tempIssue;
    });

    const issuesAndCaptionCues = _.sortBy([..._.uniqBy(tempArr, '_key'), ...tempCues.filter(({CueID}) => !cueIDsWithIssues.includes(CueID))], 'ProxyTimeCodeStart');

    return [issuesForCaptionCues, issuesAndCaptionCues];
  };

  renderIssuesComments() {
    const {reportdetails, onDeleteIssue, onUndeleteIssue, displayCaptionsOnSidebar} = this.props;
    const {details: {UserPermissionType, caption_cues, allow_edit_issues}} = reportdetails;
    const isInteractiveView = this.isInteractiveView();
    const issues = this.getIssuesByTab();

    const isShowCaptionCues = displayCaptionsOnSidebar && Boolean(caption_cues && caption_cues.length);
    const [issuesForCaptionCues, issuesAndCaptionCues] = isShowCaptionCues ? this.getIssuesForCaptionCues(caption_cues, issues) : [];

    const isCanComment = isUserCanComment(UserPermissionType);

    const isSelectedIssue = (issue) => {
      return isInteractiveView ? reportdetails.videoPlayer.selectedIssue.IssueID === issue.IssueID : false;
    };

    const renderIssue = (issue) => {
      return (
        <Issue
          key={`issue-${issue.IssueID}`}
          issue={issue}
          selectedIssue={isSelectedIssue(issue)}
          allowEditOnRequest={allow_edit_issues}
          canComment={isCanComment}
          onDiscussIssue={this.handleDiscussIssue}
          onSelectIssue={isInteractiveView ? this.handleSelectIssue : () => {}}
          onClickMore={this.handleClickMore}
          onClickIssueImage={this.handleClickIssueImage}
          onClickEdit={this.handleClickEditIssue}
          onClickDelete={onDeleteIssue}
          onClickUndelete={onUndeleteIssue}
        />
      );
    };

    const renderCurPos = () => (
      <CurrentPositionStyled
        className="default-current-position"
        key="current-position">
        <div className="overlay"/>
        <Button>Current Position</Button>
      </CurrentPositionStyled>
    );

    return (
      <StyledComments className="sidebar-comments-list">
        {reportdetails.loadingIssueCommentsSidebar ? <Loader/> : null}
        {isShowCaptionCues ?
          <>
            {renderCurPos()}
            {issuesAndCaptionCues.map(item => {
              return item.CueID ? (
                <CaptionCue
                  key={`cue-${item.CueID}`}
                  cue={item}
                  isSelected={(reportdetails.videoPlayer.selectedCue || {}).CueID === item.CueID}
                  onSelectCue={this.handleSelectCue}
                  onClickAddIssue={this.handleClickAddIssueForCaptionCue}
                  issuesCount={(issuesForCaptionCues[item.CueID] || []).length}
                  issues={(issuesForCaptionCues[item.CueID] || []).length ? <>{issuesForCaptionCues[item.CueID].map(renderIssue)}</> : null}
                />
              ) : renderIssue(item);
            })}
          </> :
            issues && issues.length ?
              <>
                {renderCurPos()}
                {issues.map(renderIssue)}
              </> :
                <>
                  <Can
                    roles={getUserRoles()}
                    perform="report-details:show-add-issue-btn"
                    yes={() => (
                      <div className="hotkeys-info">
                        Add new issues by pressing (Ctrl + N) on Mac and (Alt + N) on Windows
                      </div> : null
                    )}
                  />
                  <div className="no-data">No Issues</div>
                </>
        }
      </StyledComments>
    );
  }

  render() {
    if (this.isInteractiveView()) {
      return this.renderIssuesComments();
    }

    const {dispatch, reportdetails, match} = this.props;
    const {generalComments, loaders, details: {UserPermissionType}} = reportdetails;

    const urlFilters = qs.parse(window.location.search.substr(1));
    const isInteractiveTab = urlFilters.viewTab && +urlFilters.viewTab === 2;

    return (isInteractiveTab || !isUserCanComment(UserPermissionType)) ? null : (
      <GeneralComments
        isLoading={loaders.generalComments}
        data={generalComments}
        onSave={newComment => {
          return dispatch(addGeneralComment(match.params.requestId, newComment));
        }}
        requestId={+match.params.requestId}
      />
    );
  }
}

const CurrentPositionStyled = styled.div`
  margin: 15px -15px 25px;
  border-bottom: 1px solid #ccc;
  padding: 5px;
  position: relative;
  .btn, .btn:focus, .btn:hover, .btn:active {
    background: url(${currentPositionImg}) center left 5px no-repeat;
    background-color: #515151;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: default;
    font-size: 9px;
    left: 15px;
    outline: none;
    padding: 4px 15px;
    position: absolute;
    top: 0;
  }
  .overlay {
    background-color: #C4C4C4;
    opacity: 0.3;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 9999px;
  }
`;

const StyledComments = styled.div`
  max-height: calc(100vh - 103px);
  overflow: auto;
  padding: 0 15px 15px;
  scroll-behavior: smooth;

  .sidebar-fixed & {
    max-height: calc(100vh - 43px);
  }

  &:hover .overlay {
    display: none;
  }
  
  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 3;

    .react-spinner {
      top: 40%;
    }
  }

  .hotkeys-info {
    color: #909090;
    font-size: 13px;
    padding: 5px 30px;
    text-align: center;
  }
`;

Comments.propTypes = {
  dispatch: PropTypes.func.isRequired,
  reportdetails: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onDeleteIssue: PropTypes.func.isRequired,
  onUndeleteIssue: PropTypes.func.isRequired,
  displayCaptionsOnSidebar: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    reportdetails: state.reportdetails
  };
}

export default  connect(
  mapStateToProps
)(withRouter(Comments));
