import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import InputAdvanced from '../../../../components/forms/InputAdvanced';
import {Row, Col, FormGroup, ControlLabel, FormControl, Checkbox, Clearfix} from 'react-bootstrap';
import SelectAdvanced from "../../../../components/forms/SelectAdvanced";
import RadioButtonsAdvanced from "../../../../components/forms/RadioButtonsAdvanced";
import config from '../../../../../config';
import {getProjectDataForWizard} from '../../actions';
import Loader from '../../../../components/Loader';
import styled from 'styled-components';
import MultiSelect from '../../../RequestDetails/components/forms/MultiSelect';

const OfficeOptions = [
  {name: 'Burbank', value: config.officeFlag.BURBANK},
  {name: 'New York', value: config.officeFlag.NEW_YORK}
];

function Screen(props) {
  const {data, filters, onChange} = props;
  const {memfis_clients} = filters;
  const [isLoading, setLoading] = useState(false);
  const [isRequiredOfficeFlag, setIsRequiredOfficeFlag] = useState(false);
  const [existingQcProjectID, setExistingQcProjectID] = useState(0);
  const [storedDistributionList, setStoredDistributionList] = useState(null);
  const [optionsToUpdateDistributionList, setOptionsToUpdateDistributionList] = useState([]);

  useEffect(() => {
    setLoading(true);
    getProjectDataForWizard(data).then(
      res => {
        setLoading(false);

        const {is_required_office_flag} = res;
        setIsRequiredOfficeFlag(is_required_office_flag);
        const noOfficeFlag = ![config.officeFlag.BURBANK, config.officeFlag.NEW_YORK].includes(res.OfficeFlag);
        if (noOfficeFlag) {
          if (!is_required_office_flag && typeof data.office_flag !== 'number') {
            onChange('office_flag', 0);
          } else if (is_required_office_flag && ![config.officeFlag.BURBANK, config.officeFlag.NEW_YORK].includes(data.office_flag)) {
            onChange('office_flag', null);
          }
        }

        const optionsToUpdateDistrList = res.DistributionListUpdateOptions || [];
        setOptionsToUpdateDistributionList(optionsToUpdateDistrList);
        let distrListUpdateType = data.distribution_list_update_type;
        if (!optionsToUpdateDistrList.length) {
          distrListUpdateType = 0;
        } else if (!optionsToUpdateDistrList.find(o => o.value === distrListUpdateType)) {
          distrListUpdateType = 0;
        }
        if (distrListUpdateType !== data.distribution_list_update_type) {
          onChange('distribution_list_update_type', distrListUpdateType);
        }

        if (res.QcProjectID > 0) {
          setExistingQcProjectID(res.QcProjectID);
          setStoredDistributionList(res.DistributionList);
          if (!distrListUpdateType) {
            onChange('distribution_list', res.DistributionList);
          }
          onChange('client_contact_id', res.ClientContactID);
          onChange('cs_user_id', res.CSUserID);
          !noOfficeFlag && onChange('office_flag', res.OfficeFlag);
        } else if (res.cs_employee_account_no) {
          onChange('cs_user_id', res.cs_employee_account_no);
        }

        if (!res.PendingApprovalStudioPartnerTypeIDs) {
          onChange('pending_approval_studio_partner_type_ids', null);
        } else if (!data.pending_approval_studio_partner_type_ids) {
          onChange('pending_approval_studio_partner_type_ids', res.PendingApprovalStudioPartnerTypeIDs);
        }
      },
      error => {
        console.log(error);
        setLoading(false);
      }
    );
  }, [data, onChange]);

  useEffect(() => {
    if (data.memfis_client_id && !(memfis_clients || []).find(i => i.value === data.memfis_client_id)) {
      onChange('memfis_client_id', null);
    }
  }, [data, onChange, memfis_clients]);

  const handleChangeOffice = (name, value) => {
    if (!value) {
      value = isRequiredOfficeFlag ? null : 0;
    }
    onChange(name, value);
  };

  const handleFieldValueChanged = (name, value) => {
    onChange(name, value);
  };

  const handleTextareaBlur = event => {
    onChange(event.target.name, event.target.value);
  };

  const handleSelectUpdateTypeOfDistributionList = (_, value) => {
    if (!value) {
      onChange('distribution_list', storedDistributionList);
    }
    onChange('distribution_list_update_type', value);
  };

  const seriesShowCode = (filters.series.find(t => t.series_id === data.series_id) || {}).show_code;
  const isDisabledProjectRelatedFields = existingQcProjectID > 0;

  return (
    <div className="step-body-wrapper cs-settings">
      {isLoading ? <Loader className="full-screen"/> : null}
      <Row className="show-code">
        <Col md={3} xs={12}>
          <InputAdvanced
            label="Show Code"
            labelSeparator=""
            name="show_code"
            value={seriesShowCode || data.show_code}
            maxLength={50}
            disabled={Boolean(seriesShowCode)}
            onChange={handleFieldValueChanged}
            onBlur={handleFieldValueChanged}
          />
        </Col>
      </Row>
        <Row>
            <Col md={10} xs={12}>
                <FormGroup>
                    <ControlLabel>Distribution List</ControlLabel>
                    <FormControl
                        name="distribution_list"
                        type="text"
                        componentClass="textarea"
                        placeholder=""
                        value={data.distribution_list || ''}
                        onChange={handleTextareaBlur}
                        onBlur={handleTextareaBlur}
                        disabled={isDisabledProjectRelatedFields && !data.distribution_list_update_type}
                    />
                </FormGroup>
                {isDisabledProjectRelatedFields && !!optionsToUpdateDistributionList.length &&
                  <Clearfix>
                    <StyledRadioButtons
                      label="Select the option to update the Distribution List (if necessary)"
                      labelSeparator=":"
                      name="distribution_list_update_type"
                      options={[...optionsToUpdateDistributionList, ...(data.distribution_list_update_type ? [{value: 0, name: 'Use original value'}] : [])]}
                      value={data.distribution_list_update_type || null}
                      onChange={handleSelectUpdateTypeOfDistributionList}
                    />
                  </Clearfix>
                }
            </Col>
        </Row>
      <Row>
        <Col md={3} xs={12}>
          <StyledSelectAdvanced
            label="Client Contact"
            name="client_contact_id"
            value={data.client_contact_id}
            options={filters.memfis_contacts}
            onSelect={handleFieldValueChanged}
            placeholder=""
            labelSeparator=""
            disabled={isDisabledProjectRelatedFields}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3} xs={12}>
          <StyledSelectAdvanced
            label="Client Service Representative User"
            name="cs_user_id"
            value={data.cs_user_id}
            options={filters.cs_users}
            onSelect={handleFieldValueChanged}
            placeholder=""
            labelSeparator=""
            disabled={isDisabledProjectRelatedFields}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3} xs={12}>
          <StyledSelectAdvanced
            label="Office"
            name="office_flag"
            value={data.office_flag}
            options={OfficeOptions}
            onSelect={handleChangeOffice}
            placeholder=""
            labelSeparator=""
            labelClassName={isRequiredOfficeFlag ? 'required' : undefined}
            disabled={isDisabledProjectRelatedFields && (!isRequiredOfficeFlag || data.office_flag > 0)}
          />
        </Col>
      </Row>
      {!!(memfis_clients || []).length &&
        <Row>
          <Col md={3} xs={12}>
            <StyledSelectAdvanced
              label="MEMFIS Client"
              name="memfis_client_id"
              value={data.memfis_client_id}
              options={memfis_clients}
              onSelect={handleFieldValueChanged}
              placeholder=""
              labelSeparator=""
            />
          </Col>
        </Row>
      }
      {!!filters.pending_approval_studio_partner_types &&
        <Row>
          <Col md={3} xs={12}>
            <MultiSelect
              label="Approval required for"
              value={(data.pending_approval_studio_partner_type_ids || []).map(i => filters.pending_approval_studio_partner_types.find(o => o.value === i)).filter(Boolean)}
              options={filters.pending_approval_studio_partner_types}
              onChange={selected => handleFieldValueChanged('pending_approval_studio_partner_type_ids', (selected || []).map(i => i.value))}
            />
          </Col>
        </Row>
      }
      <Row>
        <Col md={12} xs={12}>
          <StyledCheckbox
            checked={data.send_from_qod}
            onChange={({target: {checked}}) => handleFieldValueChanged('send_from_qod', checked)}
          >
            Send from QOD
          </StyledCheckbox>
        </Col>
      </Row>
    </div>
  );
};

const StyledSelectAdvanced = styled(SelectAdvanced)`
  label {
    &.required {
      position: relative;
      &:after {
        content: '*';
        color: #ff4040;
        position: absolute;
        right: -10px;
        top: -2px;
      }
    }
  }

  .Select.has-value.Select--single,
  .Select.Select--single,
  .Select--multi {
    &.is-disabled > .Select-control {
      background-color: #eee;
      border-color: #ccc;
      cursor: not-allowed;
      .Select-arrow-zone {
        display: none;
      }
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 20px;
  label {
    font-weight: 700;
  }
  input[type="checkbox"] {
    margin-top: 2px;
  }
`;

const StyledRadioButtons = styled(RadioButtonsAdvanced)`
  > label {
    font-size: 13px;
  }
  .field label {
    width: 100%;
    margin: 5px 0px !important;
    &:last-of-type {
      margin-bottom: 20px !important;
    }
  }
`;

Screen.propTypes = {
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Screen;
