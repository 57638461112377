import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IssueTableStyled from '../../ReportDetails/components/IssueTableStyled';
import {getSortedRowsBy} from '../../../utils';
import Table from '../../../components/table/Table';
import Loader from '../../../components/Loader';
import DateCell from '../../../components/table/cells/DateCell';
import CostCell from '../../SeasonDetails/components/episodes/CostCell';
import TitleCell from '../../SeasonDetails/components/episodes/TitleCell';
import ListCell from './ListCell';

class TableWrap extends Component {
  onScroll = () => {
    const {AnalyticsTable} = this.refs;
    const prevScrollTop = Number(AnalyticsTable.getAttribute('data-scrolltop'));
    AnalyticsTable.setAttribute('data-scrolltop', AnalyticsTable.scrollTop);
    const pagingDistanceToBottom = AnalyticsTable.scrollHeight / 3;
    if (AnalyticsTable.scrollTop > prevScrollTop &&
        AnalyticsTable.offsetHeight + AnalyticsTable.scrollTop + pagingDistanceToBottom > AnalyticsTable.scrollHeight) {
      this.props.onLoadMore();
    }
  };

  componentDidMount() {
    if (typeof this.props.onLoadMore === 'function') {
      this.refs.AnalyticsTable.addEventListener('scroll', this.onScroll);
    }
  }

  componentWillUnmount() {
    if (typeof this.props.onLoadMore === 'function') {
      this.refs.AnalyticsTable.removeEventListener('scroll', this.onScroll);
    }
  }

  getHeaders() {
    const res = {};
    const {headers, reportName} = this.props;
    headers && headers.forEach(item => {
      res[item.name] = {
        title: (item.label === 'Finishing House' ? 'Post Partner' : item.label)
      };
      if (['submitted_on', 'DueDate'].includes(item.name) || item.type === 'date') {
        res[item.name].component = DateCell;
        res[item.name].componentArgs = {
          toFormat: item.format || 'MMMM D, YYYY HH:mm A'
        };
      } else if (['TotalCost'].includes(item.name)) {
        res[item.name].component = CostCell;
      } else if (item.name === 'title' && reportName === 'request_by_status') {
        res[item.name].component = TitleCell;
      } else if (item.type === 'list') {
        res[item.name].component = ListCell;
      }
    });
    return res;
  }

  renderHeaderRow() {
    const {headers} = this.props;
    if (headers.some(item => Boolean(item.group))) {
      const groups = {};
      headers.forEach(item => {
        if (item.group) {
          groups[item.group] = (groups[item.group] || 0) + 1;
        } else {
          groups[item.label] = 0;
        }
      });
      return (
        <thead>
          <tr>
            {Object.keys(groups).map((k, i) =>
              <th
                key={`line-1-${i}`}
                className={k.toLowerCase().replace(/\W/g, '-')}
                colSpan={groups[k] || undefined}
              >
                {k}
              </th>
            )}
          </tr>
          <tr>
            {headers.map((item, i) =>
              <th
                key={`line-2-${i}`}
                className={item.label.toLowerCase().replace(/\W/g, '-')}
              >
                {item.group ? item.label : <span>&nbsp;</span>}
              </th>
            )}
          </tr>
        </thead>
      );
    }
    return null;
  }

  render() {
    const {data, loading, tableSort, reportName} = this.props;
    const headers = this.getHeaders();
    return (
      <TableStyled>
        {loading ? <Loader/> : null}
        <div>
          <AnalyticsTableStyled issueBorderWidth={1} ref="AnalyticsTable" id="AnalyticsTable">
            {Object.keys(headers).length ? (
              <Table
                headerRow={this.renderHeaderRow()}
                className={`withoutSort ${reportName || ''}`}
                headers={headers}
                rows={getSortedRowsBy(data, tableSort.field, tableSort.direction)}
                emptyText="No Data"
              />
            ) : null}
          </AnalyticsTableStyled>
        </div>
      </TableStyled>
    );
  }
}

export const HEADER_HEIGHT = '59px';
export const TOP_HEIGHT = '64px';
export const REPORT_HEADER_HEIGHT = '44px';
export const TOP_PADDING_1 = '30px';
export const TOP_PADDING_2 = '10px';

const AnalyticsTableStyled = styled(IssueTableStyled)`
  max-height: calc(100vh - ${HEADER_HEIGHT} - ${TOP_HEIGHT} - ${REPORT_HEADER_HEIGHT} - (2 * ${TOP_PADDING_1}) - (2 * ${TOP_PADDING_2}));
  overflow-y: auto;
  padding-right: 5px;
  @media (min-width: 992px) {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background: transparent/*rgba(255, 255, 255, 1)*/;
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgba(0, 0, 0, .2);
      -webkit-box-shadow: none;
    }
  }
`;

const TableStyled = styled.div`
  padding: 30px 40px;
  > div {
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 10px 15px;
  }

  .table {

    &.quibi_daily_qc_wrap_report {
      tr {
        th, td {
          min-width: 100px;
          &[class^="subtitling-"] {
            min-width: 120px;
          }
          &:last-child {
            min-width: 200px;
          }
          &:first-child {
            border-left-width: 0 !important;
          }
        }
      }
    }

    &.due_today {
      tr {
        th {
          white-space: nowrap;
          min-width: 80px;
          &[title="Title/Version"] {
            min-width: 250px;
          }
        }
      }
    }

    > thead > tr {
      th {
        position: sticky;
        top: 0;
        background-color: #fff;
        font-size: 12px;
        padding: 7px;

        &[colspan] {
          text-align: center;
          padding-right: 52px;
        }
      }

      &:nth-child(2) th {
        top: 31px;
      }

      &:first-child th {
        border-bottom-style: dotted;
      }

      &:last-child th {
        border-top: 0;
        border-bottom-style: solid;
        border-bottom-width: 2px;
      }
    }

    > tbody > tr {
      cursor: default;

      td {
        font-size: 12px;
        padding: 7px;
        color: rgb(29, 28, 29);
      }
    }
  }
`;

TableWrap.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  tableSort: PropTypes.object.isRequired,
  reportName: PropTypes.string,
  onLoadMore: PropTypes.func
};

export default TableWrap;
