import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const NameCell = ({value, row, scope}) => {
  return (
    <StyledCell>
      {value}<span>{scope.replace(/.{1}$/, '')}{row.source ? ` ${row.source}` : ''}</span>
    </StyledCell>
  );
};

const StyledCell = styled.td`
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  color: #3C3C3C;
  span {
    font-weight: bold;
    font-size: 8px;
    opacity: 0.3;
    padding-left: 22px;
    text-transform: uppercase;
  }
`;

NameCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  scope: PropTypes.string
};
