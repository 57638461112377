import rules from "../../rbac-rules";

const checkPermissions = (rules, roles, action, data) => {
  var allowAccess = false;
  if (roles === undefined) {
    return allowAccess;
  }

  for (var i = 0; i < roles.length; i++) {
    allowAccess = allowAccess || check(rules, roles[i], action, data);
  }
  
  return allowAccess;
};

const check = (rules, role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
}

const Can = props =>
  checkPermissions(rules, props.roles, props.perform, props.data)
    ? props.yes()
    : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null
};

export default Can;