import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FormGroup, FormControl, Row, Col} from 'react-bootstrap';
import {FilterSelectStyled} from './FiltersStyled';
import Select from 'react-select';
import DateField from './DateField';

class Filter extends Component {
  getInputField() {
    const {filter, value, onChange} = this.props;
    return (
      <FormControl
        id={filter.name}
        value={value || ''}
        onChange={event => onChange(event.target.id, event.target.value)}
      />
    );
  }

  getSelectOptions() {
    const {filter} = this.props;
    const options = ((filter && filter.values && filter.values.map(item => {
      return {
        label: item.name,
        value: item.value
      };
    })) || []);
    if (filter && !filter.isOrderedValues) {
      options.sort((a, b) => typeof a.label === 'string' && typeof b.label === 'string' ? a.label.localeCompare(b.label) : 0);
    }
    return options;
  }

  getSelectField() {
    const {filter, value, onChange} = this.props;
    const isAllOption = o => o.label === 'All' && ['[[all]]', -1].includes(o.value);
    return (
      <FilterSelectStyled>
        <Select
          multi
          defaultValue={[]}
          value={value}
          options={this.getSelectOptions()}
          onChange={newValue => {
            const isMultiSelected = Array.isArray(newValue) && newValue.length > 1;
            if (isMultiSelected && newValue.some(isAllOption) && !(value || []).some(isAllOption)) {
              onChange(filter.name, newValue.filter(isAllOption));
            } else if (isMultiSelected && newValue.some(isAllOption) && (value || []).some(isAllOption)) {
              onChange(filter.name, newValue.filter(o => !isAllOption(o)));
            } else {
              onChange(filter.name, newValue);
            }
          }}
          placeholder={filter.placeholder || undefined}
        />
      </FilterSelectStyled>
    );
  }

  onDateChange = (id, date) => {
    const {filter, value, onChange} = this.props;
    onChange(filter.name, {
      ...value,
      [id]: date
    });
  };

  getDateField() {
    const {value} = this.props;
    const startDate = value && value.start;
    const endDate = value && value.end;
    return (
      <Row className="date-group">
        <Col md={6}>
          <DateField
            id="start"
            placeholder="From"
            value={startDate}
            onChange={this.onDateChange}
            maxValue={endDate}
          />
        </Col>
        <Col md={6}>
          <DateField
            id="end"
            placeholder="To"
            value={endDate}
            onChange={this.onDateChange}
            minValue={startDate}
          />
        </Col>
      </Row>
    );
  }

  getDatesField() {
    const {filter, value, onChange} = this.props;
    const options = [
      {label: "Past 30 days", value: 1},
      {label: "Past 60 days", value: 2},
      {label: "This Year", value: 3}
    ];
    return (
      <FilterSelectStyled>
        <Select
          className="select-in-cell"
          value={value || filter.defaultValue}
          options={options}
          onChange={event => onChange(filter.name, event ? event.value : filter.defaultValue)}
        />
      </FilterSelectStyled>
    );
  }

  getDatesAltField() {
    const {filter, value, onChange} = this.props;
    return (
      <FilterSelectStyled>
        <Select
          className="select-in-cell"
          value={value || filter.defaultValue}
          options={this.getSelectOptions()}
          onChange={event => onChange(filter.name, event ? event.value : filter.defaultValue)}
        />
      </FilterSelectStyled>
    );
  }

  render() {
    const {filter} = this.props;
    const ValueField = {
      string: this.getInputField(),
      select: this.getSelectField(),
      selectAlt: this.getSelectField(),
      selectStr: this.getSelectField(),
      date: this.getDateField(),
      periodAlt: this.getDatesAltField(),
      period: this.getDatesField()
    }[filter.type];

    return (
      <FilterStyled>
       <Row>
         <Col md={5}>
           <FormControl
             value={filter.title === 'Finishing House' ? 'Post Partner' : filter.title}
             disabled={true}
           />
         </Col>
         <Col md={5}>{ValueField}</Col>
       </Row>
      </FilterStyled>
    );
  }
}

const FilterStyled = styled(FormGroup)`
  margin-bottom: 6px;
  .control-label {
    font-size: 11px;
    margin-right: 10px;
  }
  .form-control {
    border: 1px solid #E9E9E9;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    font-size: 11px;
    height: 27px;
  }
  .date-group {
    display: flex;
    justify-content: space-between;
    [class*="col-"] {
      padding: 0 15px;
    }
  }

  .react-datepicker {
    font-size: 0.9em;
  }
  .react-datepicker__header {
    padding-top: 0.8em;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name, .react-datepicker__day {
    width: 1.7em;
    line-height: 1.7em;
    margin: 0.166em;
  }
  .react-datepicker__current-month {
    font-size: 1em;
  }
  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }
`;

Filter.propTypes = {
  filter: PropTypes.object.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default Filter;