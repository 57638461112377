import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {MentionsInput, Mention} from 'react-mentions';
import isMobile from 'is-mobile';
import classNames from 'classnames';

const AvatarBgColors = ['#A6A6A6', '#E75592', '#8655e7', '#fb4545', '#55e77b', '#fbcc45', '#fb6745', '#afe755'];

export const HeightOfCommentToolbar = 30;

class CommentForm extends Component {
  showButtonToolbar = () => !!this.props.requestImmediateResponseForMentionType;

  state = {
    isLoadingMentions: false,
    mentionsOptions: [],
    isFocused: false,
    comment: this.props.commentText || null,
    commentPlainText: this.props.commentText || null,
    commentMentions: null,
    isCheckedRequestImmediateResponse: false,
    isSaving: false,
  };

  loadMentions = () => {
    if (this.isUnmounted) {
      return;
    }
    const {requestId, commentType, onGetMentions} = this.props;
    const {isLoadingMentions, comment} = this.state;
    if (!isLoadingMentions && ((comment || '').length < 2 || comment.charAt(comment.length - 1) === ' ')) {
      this.setState({isLoadingMentions: true});
      onGetMentions(requestId, commentType).then(
        res => {
          if (this.isUnmounted) {
            return;
          }
          const mentionsOptions = (res || []).map((i, index) => ({
            id: `${i.type}:${i.value}`,
            display: i.name,
            imageUrl: i.image_url,
            mentionType: i.type,
            companyType: i.CompanyType === 'Finishing House' ? 'Post Partner' : i.CompanyType,
            forDirectMentions: !!i.IsOnDirectMentions,
            bgColor: AvatarBgColors[index < AvatarBgColors.length ? index : (index % 2)]
          }));
          this.setState({isLoadingMentions: false, mentionsOptions});
        },
        error => {
          if (this.isUnmounted) {
            return;
          }
          this.setState({isLoadingMentions: false});
          console.log(error);
        }
      );
    }
  };

  componentDidMount() {
    const {withoutStartMentions} = this.props;
    if (!withoutStartMentions) {
      this.loadMentions();
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  getClassNamePrefix = () => this.props.classNamePrefix || 'sidebar-general-comment';

  getDefaultHeight = () => {
    const {height} = this.props;
    return height;
  };

  setHeight = (height) => {
    try {
      const classNamePrefix = this.getClassNamePrefix();
      const maxHeight = window.innerHeight / 2;
      const resHeight = Math.min(height, maxHeight);
      const formElm = document.getElementsByClassName(classNamePrefix + '-form')[0];
      formElm.style.height = `${resHeight}px`;
      if (!this.props.calcModalFooterHeight) {
        const listElm = document.getElementsByClassName(classNamePrefix + 's-list-cont')[0];
        if (listElm) {
          const newHeight = `calc(100% - ${resHeight}px)`;
          listElm.style.height = newHeight;
          listElm.style.maxHeight = newHeight;
        }
      } else {
        const modalFooterHeight = this.props.calcModalFooterHeight(resHeight);
        const newHeight = `${modalFooterHeight}px`;
        const footerElm = document.getElementsByClassName('comments-modal-footer')[0];
        footerElm.style.height = newHeight;
        footerElm.previousElementSibling.style.bottom = newHeight;
      }
    } catch {}
  };

  getBaseHeight = () => {
    const baseHeight = this.getDefaultHeight();
    return baseHeight;
  }

  changeHeight = (commentPlainText) => {
    const baseHeight = this.getBaseHeight();
    try {
      if(baseHeight < this._input.scrollHeight && commentPlainText) {
        this.setHeight(this._input.scrollHeight);
      } else {
        this.setHeight(baseHeight);
      }
    } catch {}
  };

  getRequestImmediateResponseMention = () => {
    const {requestImmediateResponseForMentionType} = this.props;
    const {mentionsOptions} = this.state;
    if (requestImmediateResponseForMentionType) {
      return mentionsOptions.find(o => o.mentionType === requestImmediateResponseForMentionType);
    }
    return null;
  };

  handleChange = (event, newValue, newPlainTextValue, mentions) => {
    let isCheckedRequestImmediateResponse = this.state.isCheckedRequestImmediateResponse;
    const requestImmediateResponseMentionItem = this.getRequestImmediateResponseMention();
    if (!!requestImmediateResponseMentionItem && (mentions || []).some(({id}) => id === requestImmediateResponseMentionItem.id)) {
      isCheckedRequestImmediateResponse = true;
    }
    this.setState({comment: newValue, commentPlainText: newPlainTextValue, commentMentions: mentions, isCheckedRequestImmediateResponse});
    this.changeHeight(newPlainTextValue);
  };

  handleFocus = () => {
    const {comment, isCheckedRequestImmediateResponse} = this.state;
    let commentProps;
    if (!comment && isCheckedRequestImmediateResponse) {
      const mentionItem = this.getRequestImmediateResponseMention();
      if (mentionItem) {
        commentProps = {
          comment: `@[${mentionItem.display}](${mentionItem.id}) `,
          commentPlainText: `@${mentionItem.display} `,
          commentMentions: [{id: mentionItem.id, display: mentionItem.display, childIndex: 0, index: 0, plainTextIndex: 0}]
        };
      }
    }
    this.setState({isFocused: true, ...commentProps});
  };

  handleReset = () => {
    if (this.isUnmounted) {
      return;
    }
    this.setState({
      isFocused: false,
      comment: null,
      commentPlainText: null,
      commentMentions: null,
      isSaving: false,
    });
    this.setHeight(this.getDefaultHeight());
  };

  handleBlur = (event, clickedSuggestion) => {
    if (this.isUnmounted) {
      return;
    }
    if (!clickedSuggestion) {
      const {onBlur, commentType} = this.props;
      if ('report' === commentType && this.state.isSaving === false) {
        return;
      }
      if (onBlur || this.state.isSaving || !this.showButtonToolbar()) {
        this.handleReset();
        onBlur && onBlur();
      } else {
        this.setState({isFocused: false});
        this.changeHeight(this.state.commentPlainText);
      }
    }
  };

  blur = () => {
    try {
      this._input.blur();
    } catch {}
  };

  handleCancel = () => {
    this.handleReset();
    this.blur();
  };

  handleKeyDown = ({keyCode, altKey, ctrlKey, shiftKey}) => {
    if (keyCode === 13) {
      if (!altKey && !ctrlKey && !shiftKey) {
        let hasSuggestions = false;
        try {
          const suggestionsElm = this._input.nextElementSibling;
          if (suggestionsElm && suggestionsElm.className === 'comments-textarea__suggestions') {
            hasSuggestions = true;
          }
        } catch {}
        if (!hasSuggestions) {
          const {commentPlainText, commentMentions} = this.state;
          if (commentPlainText && commentMentions) {
            const {onSave} = this.props;
            const preparedComment = [];
            if (!commentMentions.length) {
              preparedComment.push({type: 'text', text: commentPlainText});
            } else {
              commentMentions.forEach(({id, display, plainTextIndex}, index) => {
                if (plainTextIndex > 0) {
                  if (index === 0) {
                    preparedComment.push({type: 'text', text: commentPlainText.substring(0, plainTextIndex)});
                  } else {
                    const prevItem = commentMentions[index - 1];
                    preparedComment.push({type: 'text', text: commentPlainText.substring(prevItem.plainTextIndex + prevItem.display.length, plainTextIndex)});
                  }
                }
                preparedComment.push({type: 'mention', text: display, attrs: {id: +id.split(':')[1], mentionType: id.split(':')[0]}});
              });
              const lastMention = commentMentions[commentMentions.length - 1];
              if (lastMention.plainTextIndex + lastMention.display.length < commentPlainText.length) {
                preparedComment.push({type: 'text', text: commentPlainText.substring(lastMention.plainTextIndex + lastMention.display.length)});
              }
            }
            this.setState({isSaving: true});
            onSave(preparedComment).then(() => {
              if (!this.isUnmounted) {
                this.blur();
                this.handleBlur();
              }
            });
          } else {
            this.blur();
          }
        }
      }
    } else if (keyCode === 27) {
      this.handleCancel();
    } else if (keyCode === 50 && shiftKey) {
      this.loadMentions();
    }
  };

  getNameFirstLetters = (name) => {
    return name && name.split(' ').map(s => s[0]).slice(0, 2).join('');
  };

  handleToggleRequestImmediateResponse = () => {
    const checked = !this.state.isCheckedRequestImmediateResponse;
    const {comment, commentPlainText, commentMentions} = this.state;
    let commentProps;
    if (comment) {
      const mentionItem = this.getRequestImmediateResponseMention();
      if (mentionItem) {
        const commentMentionIndex = (commentMentions || []).findIndex(({id}) => id === mentionItem.id);
        const hasMention = commentMentionIndex >= 0;
        const newPartOfComment = `@[${mentionItem.display}](${mentionItem.id}) `;
        const newPartOfPlainText = `@${mentionItem.display} `;
        if (checked && !hasMention) {
          commentProps = {
            comment: `${newPartOfComment}${comment}`,
            commentPlainText: `${newPartOfPlainText}${commentPlainText}`,
            commentMentions: [
              {id: mentionItem.id, display: mentionItem.display, childIndex: 0, index: 0, plainTextIndex: 0},
              ...(commentMentions || []).map((i) => ({...i, index: i.index + (newPartOfComment.length - 1), plainTextIndex: i.plainTextIndex + (newPartOfPlainText.length - 1)}))
            ]
          };
        } else if (!checked && hasMention && comment.startsWith(newPartOfComment)) {
          const newCommentMentions = [...commentMentions];
          newCommentMentions.splice(commentMentionIndex, 1);
          commentProps = {
            comment: comment.substr(newPartOfComment.length),
            commentPlainText: commentPlainText.substr(newPartOfPlainText.length),
            commentMentions: newCommentMentions.map((i) => ({...i, index: i.index - (newPartOfComment.length - 1), plainTextIndex: i.plainTextIndex - (newPartOfPlainText.length - 1)}))
          };
        }
      }
    }
    this.setState({isCheckedRequestImmediateResponse: checked, ...commentProps});
  };

  render() {
    const {isLoading, placeholder, commentType, heightDiff, className} = this.props;
    const {comment, isFocused, mentionsOptions, isCheckedRequestImmediateResponse, isSaving} = this.state;
    const minHeight = this.getBaseHeight();
    const classNamePrefix = this.getClassNamePrefix();
    const formClassName = classNamePrefix + '-form';
    const isShowButtonToolbar = this.showButtonToolbar();
    const mentionsInput = (
      <MentionsInput
        className="comments-textarea"
        placeholder={`${placeholder || 'Add a comment...'}${isFocused ? " (use '⏎' to send)" : ''}`}
        value={comment || ''}
        disabled={isLoading || isSaving}
        allowSuggestionsAboveCursor
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        inputRef={ref => {
          this._input = ref;
        }}
        rows={1}
      >
        <Mention
          className="comments-textarea--mention"
          trigger="@"
          data={mentionsOptions}
          appendSpaceOnAdd={true}
          displayTransform={(id, display) => `@${display}`}
          renderSuggestion={(entry, search, highlightedDisplay, index, focused) => {
            const wontBeNotified = !entry.forDirectMentions;
            const isUserMention = entry.mentionType === 'USER';
            const isCompanyMention = entry.mentionType === 'COMPANY';

            let description = null;
            if (entry.mentionType === 'EDMS') {
              description = (
                  <span className='mention-description'>
                      Notifies everyone in Eurofins DMS
                  </span>
              );
            }

            if (entry.mentionType === 'COMPANY') {
              description = (
                  <span className='mention-description'>
                      Notifies everyone in this company
                  </span>
              );
            }

            return (
              <div
                className={`custom-suggestion-item${wontBeNotified ? ' no-ntf' : ''}`}
              >
                {isCompanyMention && <CompanyType>{entry.companyType}</CompanyType>}
                <ShortName imageUrl={entry.imageUrl}>
                  <div
                    style={{backgroundColor: entry.bgColor}}
                    title={`${entry.display}${isCompanyMention ? ` (${entry.companyType})` : ''}`}
                  >
                    {this.getNameFirstLetters(entry.display)}
                  </div>
                </ShortName>
                <SuggestionItemDisplay>
                  {highlightedDisplay}
                  {description}
                </SuggestionItemDisplay>
                {wontBeNotified &&
                  <NoNtfReasonIcon
                    className="fas fa-bell-slash"
                    title={`${isUserMention ? '' : 'Some '}${entry.display}${isUserMention ? '' : ' users'} ${"won't"} be notified as they have turned off the direct mentions and/or the ${commentType} comment`}
                  />
                }
              </div>
            );
          }}
        />
      </MentionsInput>
    );
    return (
      <StyledForm
        className={classNames({
          [className || 'custom--comment-form']: true,
          [formClassName]: true,
          'is-focused': isFocused,
          'is-mobile': isMobile(),
        })}
        formMinHeight={minHeight}
        inputMinHeight={minHeight - (heightDiff || 0)}
        hasBottomToolbar={isShowButtonToolbar}
      >
        <i className="icon-comment"/>
        <form action="#" onSubmit={event => event.preventDefault()}>
          {this.props.height === undefined ? <div>{mentionsInput}</div> : mentionsInput}
        </form>
        {isShowButtonToolbar &&
          <StyledRequestImmediateResponse
            className={classNames({
              'is-enabled': !(isLoading || isSaving),
              'is-active': (isFocused || comment)
            })}
            onClick={this.handleToggleRequestImmediateResponse}
          >
            <div>
              <i className={isCheckedRequestImmediateResponse ? 'fas fa-check-square' : 'far fa-square'}/>
              Request Immediate Response
            </div>
          </StyledRequestImmediateResponse>
        }
      </StyledForm>
    );
  }
}

const StyledRequestImmediateResponse = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 5px 0 15px 23px;
  width: calc(100% - 18px);
  background: #fff;
  > div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    line-height: 14px;
    color: #808080;
    opacity: 0.85;
  }
  i {
    margin-right: 4px;
    font-size: 14px;
    line-height: 1;
    &.fa-check-square {
      color: #555;
    }
  }
  &.is-enabled {
    &.is-active {
      > div {
        color: #696969;
        opacity: 1;
      }
    }
    > div {
      cursor: pointer;
      &:hover {
        color: #434343;
        opacity: 1;
      }
    }
    i {
      &.fa-check-square {
        color: #333;
      }
    }
  }
  &:not(.is-enabled) {
    pointer-events: none;
    > div {
      opacity: 0.75;
    }
  }
`;

const ShortName = styled.span`
  flex: 0 0 40px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #a6a6a6;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 13px;
    line-height: 15px;
    color: #fff;
    ${props => !!props.imageUrl && css`
      background-color: #fff !important;
      background-image: url(${props => props.imageUrl});
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      color: transparent;
    `}
  }

  .no-ntf & {
    opacity: 0.5;
  }
`;

const SuggestionItemDisplay = styled.div`
  flex: 1 1 0%;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  .no-ntf & {
    opacity: 0.5;
  }

  .mention-description {
    display: block;
    color: #8f9499;
  }
`;

const NoNtfReasonIcon = styled.i`
  margin-left: 5px;
  width: 19px;
  height: 19px;
  border-radius: 100%;
  background-color: #42526e;
  color: #fff;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CompanyType = styled.div`
  position: absolute;
  top: 2px;
  right: 16px;
  font-size: 9px;
  color: #757575;

  @media (max-width: 767px) {
    top: 0;
    font-size: 8px;
  }

  .no-ntf & {
    right: 44px;
    opacity: 0.5;
  }
`;

const StyledForm = styled.div`
  position: relative;
  background: #fff;
  height: ${props => props.formMinHeight + 'px'};
  min-height: ${props => props.formMinHeight + 'px'};
  max-height: 50vh;
  box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.08);

  i.icon-comment {
    position: absolute;
    top: 17px;
    left: 23px;
    font-size: 19px;
    color: #adadad;
    opacity: 0.57;
  }

  &.is-focused {
    box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.15);
    i.icon-comment {
      opacity: 1;
    }
  }

  form, .comments-textarea, .comments-textarea__control {
    margin: 0;
    height: 100%;
  }

  .comments-textarea {
    position: relative !important;
    overflow-y: visible !important;
  }

  textarea.comments-textarea__input {
    margin: 0 !important;
    padding: 15px 12px 18px 53px !important;
    ${props => props.hasBottomToolbar && css`
      padding-bottom: ${18 + HeightOfCommentToolbar}px !important;
    `}
    width: 100%;
    height: 100%;
    min-height: ${props => props.inputMinHeight + 'px'} !important;
    max-height: 50vh !important;
    border: none !important;
    border-radius: 0;
    box-sizing: border-box;
    box-shadow: none !important;
    font-family: 'Roboto', sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 13.5px !important;
    line-height: 1.6em !important;
    color: rgb(51,51,51);
    outline: none !important;
    resize: none;
    overflow: auto !important;
    white-space: pre-wrap !important;
    overflow-wrap: break-word !important;

    @media (max-width: 767px) {
      padding-top: 18px !important;
      font-size: 13px !important;
      line-height: 15px !important;
    }

    &::placeholder {
      font-family: 'Roboto', sans-serif !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 13.5px !important;
      line-height: 1.6em !important;

      @media (max-width: 767px) {
        font-size: 13px !important;
        line-height: 15px !important;
      }
    }
  }

  .comments-textarea__highlighter {
    position: relative !important;
    margin: 0 !important;
    padding: 15px 12px 18px 53px !important;
    ${props => props.hasBottomToolbar && css`
      padding-bottom: ${18 + HeightOfCommentToolbar}px !important;
    `}
    width: 100% !important;
    height: 100%;
    min-height: ${props => props.inputMinHeight + 'px'} !important;
    max-height: 50vh !important;
    border: none !important;
    /*color: #000;*/
    font-family: 'Roboto', sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 13.5px !important;
    line-height: 1.6em !important;
    color: rgb(51,51,51);
    overflow: auto !important;
    white-space: pre-wrap !important;
    overflow-wrap: break-word !important;

    @media (max-width: 767px) {
      padding-top: 18px !important;
      font-size: 13px !important;
      line-height: 15px !important;
    }

    .comments-textarea--mention {
      position: relative;
      z-index: 1;
      color: rgb(18, 100, 163);
      text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white;
      pointer-events: none;

      @media (max-width: 767px) {
        background: #fff;
      }
    }
  }

  .comments-textarea__suggestions {
    position: absolute !important;
    border-radius: 3px;
    min-width: 270px !important;
    max-width: 320px;

    @media (max-width: 767px) {
      min-width: 150px !important;
    }

    .comments-textarea__suggestions__list {
      background-color: #fff;
      border: 1px solid rgb(223, 225, 230);
      border-radius: 3px;
      box-shadow: rgb(9 30 66 / 25%) 0px 4px 8px -2px, rgb(9 30 66 / 31%) 0px 0px 1px;
      max-height: 200px;
      overflow: auto;

      @media (max-width: 767px) {
        max-height: 80px;
        -webkit-overflow-scrolling: touch;
      }

      .comments-textarea__suggestions__item {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        font-family: 'Roboto', sans-serif !important;
        font-size: 12px !important;
        line-height: 14px !important;
        &:last-child {
          border-bottom: 0;
        }
        &.comments-textarea__suggestions__item--focused {
          background-color: #ebecf0;
        }

        .custom-suggestion-item {
          align-items: center;
          display: flex;
          flex-flow: row wrap;
          overflow: hidden;
          padding: 6px 14px;
          text-overflow: ellipsis;
          vertical-align: middle;
          position: relative;

          .comments-textarea__suggestions__item__display {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: rgb(29, 28, 29);
            font-size: 13px;
          }
        }
      }
    }
  }
`;

CommentForm.defaultProps = {
  commentType: 'general'
};

CommentForm.propTypes = {
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  commentType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  commentText: PropTypes.string,
  withoutStartMentions: PropTypes.bool,
  onGetMentions: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  requestId: PropTypes.number.isRequired,
  height: PropTypes.number/*.isRequired*/,
  heightDiff: PropTypes.number,
  calcModalFooterHeight: PropTypes.func,
  placeholder: PropTypes.string,
  requestImmediateResponseForMentionType: PropTypes.string,
};

export default CommentForm;
