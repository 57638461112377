import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Prompt} from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  getDictionaries, getReports, changeFormField, saveRequest, setUnsavedState, cleanForm, resetForm,
  changeStep, changeSubStep, autoSave, getAutoSaved, resumeAuto, saveDistributor,
  submitReplacement, saveNewEpisode
} from './actions';
import {getFixListIssues, clearReportDetails} from '../ReportDetails/actions';
import {IssueStatusesOptions} from '../ReportDetails/utils';
import PageComponent from '../../components/PageComponent';
import StyledAppLayout from './styledContainer';
import Loader from '../../components/Loader';
import StepHeader from './components/StepHeader';
import StepBody from './components/StepBody';
import StepFooter from './components/StepFooter';
import {
  NEW_REQUEST_STEP_CHOICES, NEW_REQUEST_STEP_ORIGINAL_REPORT, NEW_REQUEST_STEP_FORMATS, NEW_REQUEST_STEP_MEDIA,
  NEW_REQUEST_STEP_TURNAROUND, NEW_REQUEST_STEP_FILES, NEW_REQUEST_STEP_REVIEW_AND_CONFIRM,
  NEW_REQUEST_STEP_DISTRIBUTION, NEW_REQUEST_STEP_DISTRIBUTION_SELECT, NEW_REQUEST_STEP_DISTRIBUTION_OPTIONS,
  NEW_REQUEST_STEPS, QC_REQUEST_TYPES, CONTENT_TYPES, SELECT_FILES_OPTIONS,
  NEW_REQUEST_STEP_PRODUCTION, NEW_REQUEST_STEP_STUDIO, NEW_REQUEST_STEP_CS_SETTINGS, NEW_REQUEST_STEP_FINISHING_HOUSE,
  NEW_REQUEST_STEP_SECURITY, NEW_REQUEST_STEP_CS_TAGS, NEW_REQUEST_STEP_CS_WO_STEPS
} from './constants';
import {getUserRoles, isManagerUser, isStudioPartnerOrManagerAsSuchUser, getHiddenWizardFields,
  showRuntimeTextlessField, getAsperaConnect, getDefaultRedirectUrl
} from '../../utils';
import _ from 'lodash';
import qs from "qs";
import config from '../../../config';
import isMobile from 'is-mobile';
import {
  hasChangedWoSteps,
  hasInvalidWoSteps,
} from '../RequestDetails/components/editWorkOrderStepsTable/utils';

class NewRequest extends PageComponent {

  UNSAFE_componentWillMount() {
    const {dispatch, history, urlFilters} = this.props;
    if (isMobile()) {
      history.push('/requests');
      return;
    }
    let userParam;
    if (isManagerUser()) {
      const studioId = parseInt(urlFilters.cs_studio, 10);
      const productionId = parseInt(urlFilters.cs_production, 10);
      const finishingHouseId = parseInt(urlFilters.cs_finishing_house, 10);
      // if client service want create request on behalf as studio user
      if (!isNaN(studioId) && studioId > 0) {
        userParam = {cs_studio_id: studioId};
        dispatch(changeFormField('cs_studio_id', studioId));
      } else if (!isNaN(productionId) && productionId > 0) {
        userParam = {cs_production_id: productionId};
        dispatch(changeFormField('cs_production_id', productionId));
      } else if (!isNaN(finishingHouseId) && finishingHouseId > 0) {
        userParam = {cs_finishing_house_id: finishingHouseId};
        dispatch(changeFormField('cs_finishing_house_id', finishingHouseId));
      } else {
        history.push('/requests');
        return;
      }
    }
    dispatch(getDictionaries(userParam));

    dispatch(getAutoSaved())
    .then(() => {
      if (urlFilters.fromDraft) {
        this.handleResumeAutoSavedVersion();
      }
    });
  }

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(setUnsavedState());
  }

  componentWillUnmount() {
    const {dispatch} = this.props;
    dispatch(cleanForm());
    dispatch(clearReportDetails());
  }

  handleClose = () => {
    const {dispatch, history, newrequest} = this.props;
    if (newrequest.values.request_type_id) {
      dispatch(autoSave(history, newrequest, true));
    } else {
      history.push(getDefaultRedirectUrl());
    }
  };

  getStudioIdFromValues = values => {
    const {newrequest} = this.props;
    const {series_id, studio_id, cs_studio_id} = values;
    let studioId;
    if (typeof studio_id === 'number' || typeof cs_studio_id === 'number') {
      studioId = cs_studio_id || studio_id;
    } else if (typeof series_id === 'number') {
      studioId = (newrequest.dictionaries.series.find(s => s.series_id === series_id) || {}).studio_id;
    }
    return studioId || null;
  };

  prepareCompaniesToGetDictionaries = (values, studioId) => {
    return [
      studioId,
      values.cs_production_id,
      values.cs_finishing_house_id,
      ...(Array.isArray(values.production_id) ? values.production_id : []),
      ...(Array.isArray(values.finishing_house_id) ? values.finishing_house_id : [])
    ].filter(i => +i > 0).join(',');
  };

  reloadDictionariesForStudioPartner = values => {
    const {dispatch} = this.props;
    const studioId = this.getStudioIdFromValues(values);
    if (studioId) {
      dispatch(getDictionaries({
        partner_studio_id: studioId,
        cs_production_id: values.cs_production_id,
        cs_finishing_house_id: values.cs_finishing_house_id,
        company_ids: this.prepareCompaniesToGetDictionaries(values, studioId)
      }));
    }
  };

  reloadDictionariesForCompanies = values => {
    const {dispatch} = this.props;
    const studioId = this.getStudioIdFromValues(values);
    if (studioId) {
      dispatch(getDictionaries({
        for_company_ids: true,
        company_ids: this.prepareCompaniesToGetDictionaries(values, studioId)
      }));
    }
  };

  handleResumeAutoSavedVersion = () => {
    const {dispatch, newrequest} = this.props;
    const {autoSavedVersion} = newrequest;
    if (!autoSavedVersion) {
      return;
    }
    dispatch(resumeAuto(autoSavedVersion));
    if (isStudioPartnerOrManagerAsSuchUser(autoSavedVersion.values)) {
      this.reloadDictionariesForStudioPartner(autoSavedVersion.values);
    } else if (isManagerUser()) {
      this.reloadDictionariesForCompanies(autoSavedVersion.values);
    }
  };

  resetRuntimeTextless = () => {
    const {dispatch, newrequest} = this.props;
    const {general, season} = newrequest.values;
    if (general && general.runtime_in_sec__textless) {
      delete general.runtime_in_sec__textless;
      dispatch(changeFormField('general', general));
    }
    if (season && season.episodes && season.episodes.some(ep => Boolean(ep.runtime_in_sec__textless))) {
      season.episodes.forEach(ep => {
        delete ep.runtime_in_sec__textless;
      });
      dispatch(changeFormField('season', season));
    }
  };

  resetFieldsRelatedWithQcProfile = fields => {
    const {dispatch} = this.props;
    ['qc_type_id', 'video_format', 'audio_format', 'version_tag', 'additional_description', ...fields || []].forEach(k => {
      dispatch(changeFormField(k, undefined));
    });
    this.resetRuntimeTextless();
  };

  resetFieldsRelatedWithDistributor = fields => {
    const {dispatch} = this.props;
    dispatch(changeFormField('qc_profile_id', undefined));
    this.resetFieldsRelatedWithQcProfile(fields);
  };

  handleChange = (name, value, parameter) => {
    const {dispatch, newrequest} = this.props;
    const {values} = newrequest;
    if (name === 'request_type_id' && values && values.request_type_id && values.request_type_id !== value) {
      dispatch(resetForm({request_type_id: value}));
      this.forceUpdate();
    } else {
      if (name === 'user_permissions' && values && parameter) {
        const {user_permissions} = values;
        if (user_permissions && Object.keys(user_permissions).length) {
          const preparedValue = {...value};
          Object.keys(preparedValue).forEach(k => {
            if (user_permissions[k]) {
              preparedValue[k].forEach(item => {
                const current = user_permissions[k].find(i => i.UserID === item.UserID);
                if (current && current.defaultPermissionType && item.NeedToConfirm) {
                  item.defaultPermissionType = current.defaultPermissionType;
                  item.PermissionType = current.PermissionType;
                }
              });
            }
          });
          dispatch(changeFormField(name, preparedValue));
          return;
        }
      }
      dispatch(changeFormField(name, value));
      if (isStudioPartnerOrManagerAsSuchUser(values) && name === 'studio_id' && values.studio_id !== value) {
        dispatch(changeFormField('distributor_id', undefined));
        this.resetFieldsRelatedWithDistributor();
        dispatch(changeFormField('selectedFiles',
          values.select_files_type_id === SELECT_FILES_OPTIONS.IBMAspera ? [] : undefined));
        dispatch(changeFormField('uploadedFiles', undefined));
      } else if (name === 'qc_profile_id') {
        this.resetFieldsRelatedWithQcProfile(['select_files_type_id', 'selectedFiles', 'uploadedFiles']);
      } else if (name === 'distributor_id') {
        this.resetFieldsRelatedWithDistributor(['select_files_type_id', 'selectedFiles', 'uploadedFiles']);
      } else if (name === 'video_format' && !showRuntimeTextlessField(value)) {
        this.resetRuntimeTextless();
      }
    }
  };

  handleLoadReports = () => {
    const {dispatch, newrequest} = this.props;
    const {request_type_id, cs_studio_id, cs_production_id, cs_finishing_house_id} = newrequest.values;
    dispatch(getReports(request_type_id === QC_REQUEST_TYPES.Episode.id, {cs_studio_id, cs_production_id, cs_finishing_house_id}));
  };

  handleSaveDistributor = (values, callback) => {
    const {dispatch, newrequest} = this.props;
    const info = {...values};
    const companyId = info.is_system_distributor ? null : this.getStudioIdFromValues(newrequest.values);
    if (companyId) {
      info.company_id = companyId;
    }
    delete info.is_system_distributor;
    dispatch(saveDistributor(info, callback));
  };

  handleClickChangeDistributor = () => {
    const {dispatch} = this.props;
    dispatch(changeSubStep(NEW_REQUEST_STEP_DISTRIBUTION_SELECT));
  };

  handleGetReplacement = requestId => {
    const {dispatch, history} = this.props;
    dispatch(getFixListIssues(history, requestId));
  };

  handleSubmit = () => {
    const {dispatch, history, newrequest, reportdetails} = this.props;
    const {values} = newrequest;
    if (values.request_type_id === QC_REQUEST_TYPES.Replacement.id) {
      const {origin_request_id, cs_studio_id, cs_production_id, cs_finishing_house_id} = values;
      const replacement = {...values.replacement, request_id: origin_request_id, cs_studio_id, cs_production_id, cs_finishing_house_id};
      const {fixlistIssues} = reportdetails;
      if (!replacement.issue_statuses && fixlistIssues && fixlistIssues.length) {
        const issueStatuses = {};
        fixlistIssues.forEach(i => {
          issueStatuses[i.IssueID] = i.StatusID ||
            (IssueStatusesOptions.find(s => s.name.toLowerCase() === i.Status.toLowerCase()) || {}).value;
        });
        replacement.issue_statuses = issueStatuses;
      }
      if (!hasChangedWoSteps(replacement.wo_steps)) {
        delete replacement.wo_steps;
      }
      dispatch(submitReplacement(history, replacement));
    } else if (values.request_type_id === QC_REQUEST_TYPES.Episode.id) {
      dispatch(saveNewEpisode(history, values));
    } else {
      dispatch(saveRequest(history, values));
    }
  };

  handleCancel = () => {
    const {dispatch} = this.props;
    dispatch(changeStep(NEW_REQUEST_STEP_CHOICES));
    dispatch(changeSubStep(null));
    dispatch(resetForm());
  };

  handleFooterCancel = () => {
    const {dispatch, history} = this.props;
    dispatch(changeStep(NEW_REQUEST_STEP_CHOICES));
    dispatch(changeSubStep(null));
    dispatch(resetForm());
    history.push(getDefaultRedirectUrl());
  };

  getStepsForRequestType = type => {
    const userRoles = getUserRoles();
    return NEW_REQUEST_STEPS.filter(s => (
      (!s.requestTypes || s.requestTypes.map(i => i.id).includes(type)) &&
      (!s.excludeRoles || _.isEmpty(_.intersection(userRoles, s.excludeRoles)))
    )).slice();
  };

  getSteps() {
    const {values, dictionaries} = this.props.newrequest;
    const {request_type_id, qc_type_id, content_type_id} = values;
    const steps = this.getStepsForRequestType(request_type_id);
    if (!request_type_id) {
      return steps.slice(0, 1);
    }
    const stepFormatsIndex = steps.findIndex(s => s.id === NEW_REQUEST_STEP_FORMATS);
    if (qc_type_id && stepFormatsIndex >= 0 && dictionaries.qc_types) {
      const qcType = dictionaries.qc_types.find(t => t.value === qc_type_id);
      if (!qcType.is_audio && !qcType.is_video && !qcType.is_captions) {
        steps.splice(stepFormatsIndex, 1);
      }
    }
    const stepMediaIndex = steps.findIndex(s => s.id === NEW_REQUEST_STEP_MEDIA);
    if (content_type_id !== CONTENT_TYPES.Season && stepMediaIndex >= 0) {
      steps.splice(stepMediaIndex, 1);
    }

    const stepFilesIndex = steps.findIndex(s => s.id === NEW_REQUEST_STEP_FILES);
    if (content_type_id === CONTENT_TYPES.Season && stepFilesIndex >= 0) {
      steps.splice(stepFilesIndex, 1);
    }

    const stepStudioIndex = steps.findIndex(s => s.id === NEW_REQUEST_STEP_STUDIO);
    if (stepStudioIndex >= 0 && (typeof values.series_id === 'number' || typeof values.cs_studio_id === 'number')) {
      steps.splice(stepStudioIndex, 1);
    }

    return steps;
  }

  handleBack = () => {
    const {dispatch, newrequest} = this.props;
    if (newrequest.currentStep === NEW_REQUEST_STEP_DISTRIBUTION &&
      newrequest.currentSubStep === NEW_REQUEST_STEP_DISTRIBUTION_OPTIONS) {
      dispatch(changeSubStep(NEW_REQUEST_STEP_DISTRIBUTION_SELECT));
      return;
    }

    const steps = this.getSteps();
    const prevStep = steps.slice().reverse().find(i => i.id < newrequest.currentStep);
    if (prevStep) {
      dispatch(changeStep(prevStep.id));
      const currentSubStep = prevStep.id === NEW_REQUEST_STEP_DISTRIBUTION ? NEW_REQUEST_STEP_DISTRIBUTION_OPTIONS : null;
      dispatch(changeSubStep(currentSubStep));
    }
  };

  handleContinue = () => {
    const {dispatch, history, newrequest} = this.props;
    if (newrequest.currentStep === NEW_REQUEST_STEP_REVIEW_AND_CONFIRM) {
      this.handleSubmit();
      return;
    }

    if (newrequest.currentStep === NEW_REQUEST_STEP_DISTRIBUTION &&
      newrequest.currentSubStep !== NEW_REQUEST_STEP_DISTRIBUTION_OPTIONS) {
      dispatch(changeSubStep(NEW_REQUEST_STEP_DISTRIBUTION_OPTIONS));
      dispatch(autoSave(history, newrequest));
      return;
    }

    if ([NEW_REQUEST_STEP_CHOICES, NEW_REQUEST_STEP_STUDIO].includes(newrequest.currentStep) &&
        isStudioPartnerOrManagerAsSuchUser(newrequest.values)) {
      this.reloadDictionariesForStudioPartner(newrequest.values);
    }

    const steps = this.getSteps();
    const nextStep = steps.find(i => i.id > newrequest.currentStep);
    if (nextStep) {
      dispatch(changeStep(nextStep.id));
      const currentSubStep = nextStep.id === NEW_REQUEST_STEP_DISTRIBUTION ? NEW_REQUEST_STEP_DISTRIBUTION_SELECT : null;
      dispatch(changeSubStep(currentSubStep));
      dispatch(autoSave(history, newrequest));
      if ([NEW_REQUEST_STEP_CS_SETTINGS].includes(nextStep.id)) {
        if (isStudioPartnerOrManagerAsSuchUser(newrequest.values)) {
          this.reloadDictionariesForStudioPartner(newrequest.values);
        } else {
          this.reloadDictionariesForCompanies(newrequest.values);
        }
      }
    }
  };

  isUploadNowFilesUsingUnavailableAspera = () => {
    const {values} = this.props.newrequest;
    const {content_type_id, video_format} = values;
    return content_type_id !== CONTENT_TYPES.Season && (video_format ? video_format.some(i =>
      i.files.select_files_type_id === SELECT_FILES_OPTIONS.UploadNow) :
        values.select_files_type_id === SELECT_FILES_OPTIONS.UploadNow) && !getAsperaConnect(config.initAsperaParams);
  };

  isAllowNextStep = () => {
    const {currentStep, currentSubStep, values, dictionaries} = this.props.newrequest;
    const {season, general, content_type_id} = values;
    const episodes = season ? season.episodes : undefined;
    const qcType = dictionaries.qc_types.find(t => t.value === values.qc_type_id);
    const isNewEpisode = values.request_type_id === QC_REQUEST_TYPES.Episode.id;
    let result;
    switch (currentStep) {
      case NEW_REQUEST_STEP_STUDIO:
        result = Boolean(values.studio_id);
        break;
      case NEW_REQUEST_STEP_ORIGINAL_REPORT:
        result = Boolean(values.origin_request_id);
        break;
      case NEW_REQUEST_STEP_DISTRIBUTION:
        result =
          (currentSubStep === NEW_REQUEST_STEP_DISTRIBUTION_OPTIONS && values.qc_profile_id && values.qc_type_id) ||
          (currentSubStep !== NEW_REQUEST_STEP_DISTRIBUTION_OPTIONS && values.distributor_id);
        break;
      case NEW_REQUEST_STEP_PRODUCTION:
      case NEW_REQUEST_STEP_FINISHING_HOUSE:
        result = true;
        break;
      case NEW_REQUEST_STEP_FORMATS:
        const hiddenFields = getHiddenWizardFields(dictionaries.qc_profiles, values.qc_profile_id);

        const isValidField = (fieldName, format, fieldValue, formatValues) => {
          if (hiddenFields.includes(fieldName)) {
            return true;
          }
          const v = format ? (formatValues || values)[format][fieldValue] : (formatValues || values)[fieldValue];
          return v !== null && v !== undefined;
        };

        if (qcType.is_video) {
          result = (!qcType.is_audio || values.video_format.some(vfItem => Boolean(vfItem.audio_format))) &&
                   values.video_format.every(videoFormatItem => {
            const isAudioValid = !qcType.is_audio || !videoFormatItem.audio_format || (
              isValidField('audio_format', 'audio_format', 'format_id', videoFormatItem) &&
              isValidField('audio_configuration', 'audio_format', 'audio_configuration', videoFormatItem)
            );
            const isVideoValid = (
              isValidField('video_formats', '', 'format_id', videoFormatItem) &&
              isValidField('video_resolutions', '', 'resolution_id', videoFormatItem) &&
              isValidField('video_color_spaces', '', 'color_space_id', videoFormatItem) &&
              isValidField('video_captions', '', 'caption_id', videoFormatItem) &&
              isValidField('video_textless', '', 'textless', videoFormatItem)
            );
            return isAudioValid && isVideoValid;
          });
        } else if (qcType.is_audio) {
          result = (
            isValidField('audio_format', 'audio_format', 'format_id') &&
            isValidField('audio_configuration', 'audio_format', 'audio_configuration')
          );
        } else if (qcType.is_captions) {
          result = values.caption_id !== null && values.caption_id !== undefined;
        } else {
          result = true;
        }
        break;
      case NEW_REQUEST_STEP_MEDIA:
        result = season && (typeof season.season_number === 'number' || season.season_number) &&
          season.total_episodes > 0 && Array.isArray(episodes) && season.total_episodes === episodes.length &&
          episodes.every(ep => Boolean(ep.runtime_in_sec)) &&
          (!showRuntimeTextlessField(values.video_format) || episodes.every(ep => Boolean(ep.runtime_in_sec__textless)));
        break;
      case NEW_REQUEST_STEP_TURNAROUND:
        result = general && general.sla_id && typeof general.allow_interactive_report === 'boolean' &&
          (content_type_id !== CONTENT_TYPES.Trailer || !!(values.trailer_episode_name || '').trim()) &&
          (!isNewEpisode || general.episode_number) &&
          ((![CONTENT_TYPES.Feature, CONTENT_TYPES.Trailer].includes(content_type_id) && !isNewEpisode) ||
          (typeof general.runtime_in_sec === 'number' && general.runtime_in_sec > 0)) &&
          (![CONTENT_TYPES.Feature, CONTENT_TYPES.Trailer].includes(content_type_id) || !showRuntimeTextlessField(values.video_format) ||
          (typeof general.runtime_in_sec__textless === 'number' && general.runtime_in_sec__textless > 0));
        break;
      case NEW_REQUEST_STEP_FILES:
        const isValidFiles = files => {
          const {select_files_type_id, selectedFiles, uploadedFiles} = files;
          return (
            (select_files_type_id > SELECT_FILES_OPTIONS.UploadNow ||
            (select_files_type_id === SELECT_FILES_OPTIONS.UploadNow && uploadedFiles && uploadedFiles.length) ||
            (select_files_type_id === SELECT_FILES_OPTIONS.IBMAspera && selectedFiles && selectedFiles.length))
          );
        };
        if (values.request_type_id === QC_REQUEST_TYPES.NewQcRequest.id && values.video_format) {
          result = values.video_format.every(videoFormatItem => isValidFiles(videoFormatItem.files));
        } else {
          result = isValidFiles(values) &&
            (values.request_type_id !== QC_REQUEST_TYPES.Replacement.id || Boolean(values.replacement));
        }
        break;
      case NEW_REQUEST_STEP_CS_SETTINGS:
        result = typeof values.office_flag === 'number' && values.office_flag >= 0;
        break;
      case NEW_REQUEST_STEP_CS_TAGS:
        result = true;
        break;
      case NEW_REQUEST_STEP_SECURITY:
        result = true;
        break;
      case NEW_REQUEST_STEP_CS_WO_STEPS:
        result = !hasInvalidWoSteps((values.replacement || {}).wo_steps);
        break;
      case NEW_REQUEST_STEP_REVIEW_AND_CONFIRM:
        result = !this.isUploadNowFilesUsingUnavailableAspera();
        break;
      default:
        result = false;
        break;
    }
    return result;
  };

  render() {
    const {newrequest, reportdetails} = this.props;
    const {loading, loadingAutoSaved, loadingReports, saving, dictionaries} = newrequest;
    const showPrompt = Boolean(
      (!this.props.newrequest.isSaved && this.props.newrequest.values.request_type_id) && Cookies.get('__auth')
    );
    const steps = this.getSteps();
    const isFirstStepInHeader = steps.length > 1 && newrequest.currentStep === steps[1].id;
    return (
      <StyledAppLayout title="New Request">
        <div className="new-request">
          <Prompt
            when={showPrompt}
            message="You have unsaved changes, are you sure you want to leave?"
            />
          {
            !loading && !Object.keys(dictionaries).length ? null :
              loading && !Object.keys(dictionaries).length ?
                <div className="new-request-wrapper"><Loader/></div> : (
                  <div className="new-request-wrapper">
                    {(loading || loadingAutoSaved || loadingReports || saving) ? <Loader/> : null}
                    <StepHeader
                      currentStep={newrequest.currentStep}
                      data={newrequest.values}
                      filters={dictionaries}
                      steps={steps}
                      onClose={this.handleClose}
                      onBack={isFirstStepInHeader ? this.handleCancel : this.handleBack}
                      />
                    <StepBody
                      currentStep={newrequest.currentStep}
                      currentSubStep={newrequest.currentSubStep}
                      data={newrequest.values}
                      filters={dictionaries}
                      onChange={this.handleChange}
                      onApplyChoices={this.handleContinue}
                      onLoadReports={this.handleLoadReports}
                      reports={newrequest.reports}
                      onGetReplacement={this.handleGetReplacement}
                      reportdetails={reportdetails}
                      onSaveDistributor={this.handleSaveDistributor}
                      onClickChangeDistributor={this.handleClickChangeDistributor}
                      />
                    <StepFooter
                      resumeData={newrequest.autoSavedVersion}
                      data={newrequest.values}
                      currentStep={newrequest.currentStep}
                      steps={steps}
                      onResume={this.handleResumeAutoSavedVersion}
                      onBack={newrequest.currentStep !== NEW_REQUEST_STEP_ORIGINAL_REPORT ? this.handleBack : null}
                      onCancel={this.handleFooterCancel}
                      onNext={this.isAllowNextStep() ? this.handleContinue : null}
                      />
                  </div>
                )
          }
        </div>
      </StyledAppLayout>
    );
  }
}

NewRequest.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  newrequest: PropTypes.object.isRequired,
  reportdetails: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    newrequest: state.newrequest,
    reportdetails: state.reportdetails,
    user: state.user,
    urlFilters: qs.parse(ownProps.location.search.substr(1)),
  };
}

export default connect(
  mapStateToProps
)(withRouter(NewRequest));
