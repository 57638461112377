import {USER_SET_IS_LOGOUT} from '../../../pages/Login/actions';
import {
  ARTWORK_REPORT_CLEAR,
  ARTWORK_REPORT_TOGGLE_OPERATOR_MODE,
  ARTWORK_REPORT_TOGGLE_FULL_SCREEN_MODE,
  ARTWORK_REPORT_TOGGLE_DARK_MODE,
  ARTWORK_REPORT_SET_PROJECTS_VIEW,
  ARTWORK_REPORT_SAVED_FILTER,
  ARTWORK_REPORT_CHANGE_FILTER,
  ARTWORK_REPORT_CHANGE_FILTER_TYPE,
  ARTWORK_REPORT_ADD_FILTERS,
  ARTWORK_REPORT_REMOVE_FILTERS,
  ARTWORK_REPORT_SET_LOADING,
  ARTWORK_REPORT_SET_FILTERS,
  ARTWORK_REPORT_SET_PROJECTS,
  ARTWORK_REPORT_UPDATE_PROJECT_ASSETS,
  ARTWORK_REPORT_SET_DETAIL,
  ARTWORK_REPORT_UPDATE_DETAIL,
  ARTWORK_REPORT_UPDATE_DETAIL_ISSUE,
  ARTWORK_REPORT_ADD_DETAIL_ISSUE,
  ARTWORK_REPORT_DELETE_DETAIL_ISSUE,
  ARTWORK_REPORT_TOGGLE_UPLOAD_NEW_VERSION_MODAL,
  ARTWORK_REPORT_TOGGLE_ASSET_METADATA_MODAL,
  ARTWORK_REPORT_TOGGLE_QC_INSTRUCTIONS_MODAL,
  ARTWORK_REPORT_CHANGE_VIDEO_PARAMS,
  ARTWORK_REPORT_TOGGLE_COMMENT_TO_VIEW,
} from './actions';
import {
  NonNumericFilters,
  PROJECTS_VIEW__GALLERY
} from './utils';
import {isArtworkOperatorUser, isArtworkManagerUser} from '../../../utils';

const initialState = {
  loaders: {
    filters: false,
    projects: false,
    more: false,
    getReportDetail: false,
    getReportComments: false,
    loadingAssetMetadata: false,
  },

  allowedFilters: [],
  selectedFilters: [{
    name: 'search',
    value: null
  }, {
    name: 'sort',
    value: {}
  }],

  projectsView: PROJECTS_VIEW__GALLERY,
  projectsTotal: 0,
  projectsData: [],

  reportDetail: null,
  reportDetailFilters: null,
  isFullScreenMode: false,
  isDarkMode: false,
  isOperatorMode: isArtworkOperatorUser() || isArtworkManagerUser(),

  isShowUploadNewVersionModal: false,

  assetMetadataModalData: null,
  qcInstructionsModalData: null,

  videoPlayer: {
    selectedIssue: {},
    isChangedSelecting: false,
    isPlaying: false,
    currentIssueID: null
  },

  commentIdToView: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT:
    case ARTWORK_REPORT_CLEAR: {
      return {...initialState};
    }

    case ARTWORK_REPORT_TOGGLE_OPERATOR_MODE: {
      return {
        ...state,
        isOperatorMode: action.modeIsOn || initialState.isOperatorMode
      };
    }

    case ARTWORK_REPORT_TOGGLE_FULL_SCREEN_MODE: {
      return {
        ...state,
        isFullScreenMode: action.modeIsOn || initialState.isFullScreenMode
      };
    }

    case ARTWORK_REPORT_TOGGLE_DARK_MODE: {
      return {
        ...state,
        isDarkMode: action.modeIsOn || initialState.isDarkMode
      };
    }

    case ARTWORK_REPORT_SET_PROJECTS_VIEW: {
      return {
        ...state,
        projectsView: action.view || initialState.projectsView
      };
    }

    case ARTWORK_REPORT_SET_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.name]: action.loading
        }
      };
    }

    case ARTWORK_REPORT_SAVED_FILTER: {
      const urlFilters = {...action.urlFilters};
      if (!urlFilters.search) {
        urlFilters.search = null;
      }
      if (!urlFilters.sort) {
        urlFilters.sort = {};
      }

      const nonNumericFilters = [
        ...NonNumericFilters,
        'from', 'to', 'state', ''
      ];
      const selectedFilters = [];
      Object.keys(urlFilters).forEach(key => {
        const value = Array.isArray(urlFilters[key]) && !nonNumericFilters.includes(key) ?
          urlFilters[key].map(item => !isNaN(+item) ? +item : item) :
          urlFilters[key];

        const res = {name: key, value};
        if (key === 'Last Updated') {
          res.disabled = true;
          selectedFilters.unshift(res);
        } else {
          selectedFilters.push(res);
        }
      });

      return {
        ...state,
        selectedFilters
      }
    }

    case ARTWORK_REPORT_CHANGE_FILTER: {
      const {selectedFilters} = state;
      const isFilterExist = !!selectedFilters.find(filter => filter.name === action.name);
      if (isFilterExist) {
        return {
          ...state,
          selectedFilters: selectedFilters.map(item => {
            return item.name === action.name ? {
              ...item,
              value: action.value || null
            } : item;
          })
        };
      }
      return {
        ...state,
        selectedFilters: [
          ...selectedFilters,
          {
            name: action.name,
            value: action.value
          }
        ]
      }
    }

    case ARTWORK_REPORT_CHANGE_FILTER_TYPE: {
      return {
        ...state,
        selectedFilters: state.selectedFilters.map(item => {
          return item.name === action.filter.name ? {
            ...action.newSelectedFilter,
            title: action.newSelectedFilter.label || action.newSelectedFilter.title,
            value: action.newSelectedFilter.defaultValue ?
              (Array.isArray(action.newSelectedFilter.defaultValue) ? action.newSelectedFilter.defaultValue : [action.newSelectedFilter.defaultValue]) : null
          } : item;
        })
      };
    }

    case ARTWORK_REPORT_ADD_FILTERS: {
      const selectedFilters = [...state.selectedFilters];

      const filterIndex = selectedFilters.findIndex(f => f.name === action.filter.name);
      if (filterIndex !== -1) {
        selectedFilters[filterIndex] = {
          ...selectedFilters[filterIndex],
          title: action.filter.label || action.filter.title,
          type: action.filter.type,
          multi: action.filter.multi,
          forFilterName: action.filter.forFilterName,
          hasAdditionalFilters: action.filter.hasAdditionalFilters,
          isStudioParameter: action.filter.isStudioParameter,
          studioID: action.filter.studioID,
          isOrderedValues: action.filter.isOrderedValues,
          orderNum: action.filter.orderNum,
          disabled: action.filter.disabled
        };
      } else {
        const res = {
          ...action.filter,
          name: action.filter.name,
          title: action.filter.label || action.filter.title,
          value: action.filter.defaultValue ?
           (Array.isArray(action.filter.defaultValue) ? action.filter.defaultValue : [action.filter.defaultValue]) : null,
          orderNum: action.filter.orderNum,
          disabled: action.filter.disabled
        };

        if (action.filter.required) {
          selectedFilters.unshift(res);
        } else {
          selectedFilters.push(res);
        }
      }

      return {
        ...state,
        selectedFilters: selectedFilters.sort((a, b) => (a.orderNum || state.allowedFilters.length) - (b.orderNum || state.allowedFilters.length))
      };
    }

    case ARTWORK_REPORT_REMOVE_FILTERS: {
      const selectedFilters = [...state.selectedFilters];
      return {
        ...state,
        selectedFilters: selectedFilters.filter(f => f.name !== action.filter.name)
      };
    }

    case ARTWORK_REPORT_SET_FILTERS: {
      return {
        ...state,
        allowedFilters: action.allowedFilters
      }
    }

    case ARTWORK_REPORT_SET_PROJECTS: {
      const {selectedFilters} = state;
      return {
        ...state,
        selectedFilters: selectedFilters.map(item => {
          return item.name === 'search' && typeof item.value === 'string' ? {
            ...item,
            value: item.value.trim()
          } : item;
        }),
        loaders: {
          ...state.loaders,
          projects: false,
          more: false
        },
        projectsData: [...action.data || initialState.projectsData],
        projectsTotal: action.total || initialState.projectsTotal
      };
    }

    case ARTWORK_REPORT_UPDATE_PROJECT_ASSETS: {
      const projectsData = [...state.projectsData];
      const {project: {project_id}, assets} = action;
      const projectIndex = projectsData.findIndex(p => p.project_id === project_id);
      if (projectIndex >= 0) {
        projectsData[projectIndex].assets = assets;
      }
      return {
        ...state,
        projectsData
      };
    }

    case ARTWORK_REPORT_SET_DETAIL: {
      let comments;
      if (action.data && action.data.asset_id === (state.reportDetail || {}).asset_id) {
        comments = state.reportDetail.comments;
      }
      return {
        ...state,
        reportDetail: action.data ? {...action.data, comments} : initialState.reportDetail,
        reportDetailFilters: action.data ? (action.filters || state.reportDetailFilters || {}) : initialState.reportDetailFilters
      }
    }

    case ARTWORK_REPORT_UPDATE_DETAIL: {
      const {reportDetail, projectsData} = state;
      let updReportDetail;
      if ((reportDetail || {}).asset_id === action.assetId) {
        updReportDetail = {...reportDetail, ...action.data};
        const projectIndex = projectsData.findIndex(p => p.project_id === reportDetail.project_id);
        if (projectIndex >= 0) {
          const assets = (projectsData[projectIndex].assets || {}).data || [];
          const assetIndex = assets.findIndex(a => a.asset_id === action.assetId);
          if (assetIndex >= 0) {
            assets[assetIndex] = {...assets[assetIndex], ...action.data};
          }
        }
      }
      return {
        ...state,
        projectsData,
        reportDetail: updReportDetail || reportDetail
      }
    }

    case ARTWORK_REPORT_UPDATE_DETAIL_ISSUE: {
      const {reportDetail/*, projectsData*/} = state;
      let updReportDetail;
      if ((reportDetail || {}).asset_id === action.assetId) {
        const issues = [...reportDetail.issues];
        const issueIndex = issues.findIndex(i => i.issue_id === action.issue.issue_id);
        if (issueIndex >= 0) {
          issues[issueIndex] = {...action.issue};
          updReportDetail = {...reportDetail, issues};
          /*const projectIndex = projectsData.findIndex(p => p.project_id === reportDetail.project_id);
          if (projectIndex >= 0) {
            const assets = (projectsData[projectIndex].assets || {}).data || [];
            const assetIndex = assets.findIndex(a => a.asset_id === action.assetId);
            if (assetIndex >= 0) {
              assets[assetIndex] = ;
            }
          }*/
        }
      }
      return {
        ...state,
        /*projectsData,*/
        reportDetail: updReportDetail || reportDetail
      }
    }

    case ARTWORK_REPORT_ADD_DETAIL_ISSUE: {
      const {reportDetail, projectsData} = state;
      let updReportDetail;
      if ((reportDetail || {}).asset_id === action.assetId) {
        const issuesCount = (reportDetail.issues || []).length + 1;
        updReportDetail = {
          ...reportDetail,
          issues_count: issuesCount,
          issues: [...reportDetail.issues || [], action.issue]
        };
        const projectIndex = projectsData.findIndex(p => p.project_id === reportDetail.project_id);
        if (projectIndex >= 0) {
          const assets = (projectsData[projectIndex].assets || {}).data || [];
          const assetIndex = assets.findIndex(a => a.asset_id === action.assetId);
          if (assetIndex >= 0) {
            assets[assetIndex] = {...assets[assetIndex],  issues_count: issuesCount};
          }
        }
      }
      return {
        ...state,
        projectsData,
        reportDetail: updReportDetail || reportDetail
      }
    }

    case ARTWORK_REPORT_DELETE_DETAIL_ISSUE: {
      const {reportDetail, projectsData} = state;
      let updReportDetail;
      if ((reportDetail || {}).asset_id === action.assetId) {
        const issues = [...reportDetail.issues].filter(({issue_id}) => issue_id !== action.issueId);
        updReportDetail = {...reportDetail, issues};
        const projectIndex = projectsData.findIndex(p => p.project_id === reportDetail.project_id);
        if (projectIndex >= 0) {
          const assets = (projectsData[projectIndex].assets || {}).data || [];
          const assetIndex = assets.findIndex(a => a.asset_id === action.assetId);
          if (assetIndex >= 0) {
            assets[assetIndex] = {...assets[assetIndex],  issues_count: issues.length};
          }
        }
      }
      return {
        ...state,
        projectsData,
        reportDetail: updReportDetail || reportDetail
      }
    }

    case ARTWORK_REPORT_TOGGLE_UPLOAD_NEW_VERSION_MODAL: {
      return {
        ...state,
        isShowUploadNewVersionModal: action.isShow || initialState.isShowUploadNewVersionModal
      };
    }

    case ARTWORK_REPORT_TOGGLE_ASSET_METADATA_MODAL: {
      return {
        ...state,
        assetMetadataModalData: action.isShow && action.asset ? action.asset : initialState.assetMetadataModalData
      };
    }

    case ARTWORK_REPORT_TOGGLE_QC_INSTRUCTIONS_MODAL: {
      return {
        ...state,
        qcInstructionsModalData: action.isShow && action.asset ? action.asset : initialState.qcInstructionsModalData
      };
    }

    case ARTWORK_REPORT_CHANGE_VIDEO_PARAMS: {
      return {
        ...state,
        videoPlayer: {
          ...state.videoPlayer,
          ...action.data
        }
      };
    }

    case ARTWORK_REPORT_TOGGLE_COMMENT_TO_VIEW: {
      return {
        ...state,
        commentIdToView: action.commentId || initialState.commentIdToView
      };
    }

    default: {
      return state;
    }
  }
}
