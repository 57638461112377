import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Modal, Button, Clearfix} from 'react-bootstrap';
import styled from 'styled-components';
import RadioButtonsAdvanced from '../../../../components/forms/RadioButtonsAdvanced';
import config from '../../../../../config';
import {hideCsCreateRequestModal, createNewEpisodeRequest} from '../../actions';

class ClientServiceCreateRequestModal extends Component {
  state = {
    userType: null
  };

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(hideCsCreateRequestModal());
  };

  handleSubmit = () => {
    const {dispatch, history, data} = this.props;
    const {StudioID, ProductionID, FinishingHouseID} = data.season;
    const {userType} = this.state;
    const csParam = {};
    let urlParam;
    if (userType === config.roles.STUDIO) {
      csParam.cs_studio_id = StudioID;
      urlParam = `cs_studio=${StudioID}`;
    } else if (userType === config.roles.PRODUCTION) {
      csParam.cs_production_id = +ProductionID;
      urlParam = `cs_production=${ProductionID}`;
    } else {
      csParam.cs_finishing_house_id = +FinishingHouseID;
      urlParam = `cs_finishing_house=${FinishingHouseID}`;
    }
    dispatch(createNewEpisodeRequest(history, data.row, data.currentSeason, csParam, urlParam));
  };

  handleSelectUserType = (_, value) => {
    this.setState({userType: value});
  };

  prepareUserTypeOptions = () => {
    const {data} = this.props;
    const {StudioName, ProductionName, FinishingHouseName} = data.season;
    const options = [{value: config.roles.STUDIO, name: `Studio: ${StudioName}`}];
    if (ProductionName) {
      options.push({value: config.roles.PRODUCTION, name: `Production: ${ProductionName}`});
    }
    if (FinishingHouseName) {
      options.push({value: config.roles.FINISHING_HOUSE, name: `Post Partner: ${FinishingHouseName}`});
    }
    return options;
  };

  render() {
    const {userType} = this.state;
    return (
      <Modal
        className="cs-create-request-modal"
        show={true}
        onHide={this.handleClose}
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>Select Studio/Partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <Clearfix>
              <StyledRadioButtonsAdvanced
                label="User Type"
                labelSeparator=""
                name="user_type"
                options={this.prepareUserTypeOptions()}
                value={userType}
                onChange={this.handleSelectUserType}
              />
            </Clearfix>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonStyled
            bsStyle="primary"
            onClick={this.handleSubmit}
            disabled={!userType}
          >
            Submit
          </ButtonStyled>
          <ButtonStyled
            bsStyle="default"
            onClick={this.handleClose}
          >
            Cancel
          </ButtonStyled>
        </Modal.Footer>
      </Modal>
    );
  }
}

const StyledRadioButtonsAdvanced = styled(RadioButtonsAdvanced)`
  .field {
    display: flex;
    flex-direction: column;
    label {
      margin-top: 10px;
      margin-bottom: 0;
      word-break: break-all;
      .circle {
        min-height: 20px;
        min-width: 20px;
      }
    }
`;

const ButtonStyled = styled(Button)`
    border: none !important;
    outline: none !important;
    box-shadow: none;
    border-radius: 100px;

    &.btn-primary {
      padding-left: 20px;
      padding-right: 20px;
      background: #33333F;
      opacity: 0.8;

      &:disabled, &.disabled {
        background: #e0e0e1;
        color: #c8c8c8;
        cursor: default;
      }

      &:not([disabled]):hover {
        opacity: 1;
      }
    }
`;

ClientServiceCreateRequestModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default connect()(withRouter(ClientServiceCreateRequestModal));
