/* eslint "complexity":"off" */
import {USER_SET_IS_LOGOUT} from '../../pages/Login/actions';
import {
  ADMIN_USER_ALL, ADMIN_USER_CREATE_NEW, ADMIN_USER_EDIT, ADMIN_USER_IS_SAVING,
  ADMIN_COMPANY_IS_SAVING, ADMIN_COMPANY_IS_LOADING, ADMIN_COMPANY_ALL, ADMIN_COMPANY_UPDATE, ADMIN_COMPANY_TO_EDIT,
  ADMIN_COMPANY_DELETE, ADMIN_COMPANY_CREATE_IS_LOADING, ADMIN_COMPANY_INFO_TO_CREATE,
  ADMIN_COMPANY_FILTERS_IS_LOADING, ADMIN_COMPANY_FILTERS_LOAD,
  ADMIN_USER_MFA_MODAL_SHOW, ADMIN_USER_MFA_MODAL_CLOSE, ADMIN_USER_IS_LOADING,
  ADMIN_DISTRIBUTOR_IS_LOADING, ADMIN_DISTRIBUTOR_IS_SAVING, ADMIN_DISTRIBUTOR_ALL, ADMIN_DISTRIBUTOR_MODAL_DATA,
  ADMIN_MERGE_INTO_SYSTEM_DISTR_MODAL_DATA,
  ADMIN_DISTRIBUTOR_DELETE, ADMIN_DISTRIBUTOR_UPDATE,
  ADMIN_USER_MANAGE_PERMISSIONS_MODAL_SHOW,
  ADMIN_USER_MANAGE_PERMISSIONS_MODAL_CLOSE,
  ADMIN_USER_MANAGE_PERMISSIONS_CHANGE,
  ADMIN_SERIES_IS_LOADING,
  ADMIN_SERIES_ALL, ADMIN_USER_COMPANY_LIST_EDIT,
} from './actions';

const initialState = {
  allUsers: {
    isLoading: false,
    users: [],
    filters: {}
  },
  newUser: {
    isShow: false,
    isSaving: false,
    fromUser: null
  },
  editUser: {
    isShow: false,
    isShowEditCompanyModal: false,
    isSaving: false,
    user: null
  },
  isSavingUser: false,

  isSavingCompany: false,
  isLoadingCompanies: false,
  companies: [],
  companyToEdit: null,

  isLoadingCreateCompany: false,
  createCompanyModalData: null,

  isLoadingCompanyFilters: false,
  companyFilters: {},

  isLoadingUsers: false,

  userToManageMFA: null,

  isSavingDistributor: false,
  isLoadingDistributors: false,
  distributors: [],
  distributorFilters: {
    qc_profiles: [],
    companies: []
  },
  distributorModalData: null,
  mergeIntoSystemDistrModalData: null,

  dataToManageUserPermissions: null,

  isLoadingSeries: false,
  series: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT: {
      return initialState;
    }

    case ADMIN_USER_ALL: {
      return {
        ...state,
        allUsers: action.data ? {...state.allUsers, ...action.data} : initialState.allUsers
      };
    }

    case ADMIN_USER_CREATE_NEW: {
      return {
        ...state,
        newUser: action.data ? {...state.newUser, ...action.data} : initialState.newUser
      };
    }

    case ADMIN_USER_EDIT: {
      return {
        ...state,
        editUser: action.data ? {...state.editUser, ...action.data} : initialState.editUser
      };
    }

    case ADMIN_USER_COMPANY_LIST_EDIT: {
      return {
        ...state,
        editUser: action.data ? {...state.editUser, ...action.data} : initialState.editUser
      };
    }

    case ADMIN_USER_IS_SAVING: {
      return {
        ...state,
        isSavingUser: action.saving
      };
    }

    case ADMIN_COMPANY_IS_LOADING: {
      return {
        ...state,
        isLoadingCompanies: action.loading
      };
    }

    case ADMIN_COMPANY_ALL: {
      return {
        ...state,
        isLoadingCompanies: false,
        companies: action.data || initialState.companies
      };
    }

    case ADMIN_COMPANY_UPDATE: {
      const {values, companyID} = action;
      const {companies} = state;
      let companyIndex = companies.findIndex(c => c.CompanyID === companyID);
      if (companyIndex !== -1) {
        if (!values.LogoUrl) {
          delete values.LogoUrl;
        }
        companies[companyIndex] = {...companies[companyIndex], ...values};
      }
      return {
        ...state,
        isSavingCompany: false,
        companyToEdit: initialState.companyToEdit,
        companies
      };
    }

    case ADMIN_COMPANY_IS_SAVING: {
      return {
        ...state,
        isSavingCompany: action.saving
      };
    }

    case ADMIN_COMPANY_TO_EDIT: {
      return {
        ...state,
        companyToEdit: action.company || initialState.companyToEdit,
        companyFilters: action.company ? state.companyFilters : initialState.companyFilters
      };
    }

    case ADMIN_COMPANY_DELETE: {
      const {companyID} = action;
      const {companies} = state;
      let companyIndex = companies.findIndex(c => c.CompanyID === companyID);
      if (companyIndex !== -1) {
        companies.splice(companyIndex, 1);
      }
      return {
        ...state,
        isSavingCompany: false,
        companies
      };
    }

    case ADMIN_COMPANY_CREATE_IS_LOADING: {
      return {
        ...state,
        isLoadingCreateCompany: action.loading
      };
    }

    case ADMIN_COMPANY_INFO_TO_CREATE: {
      return {
        ...state,
        isLoadingCreateCompany: false,
        createCompanyModalData: action.data || initialState.createCompanyModalData
      };
    }

    case ADMIN_COMPANY_FILTERS_IS_LOADING: {
      return {
        ...state,
        isLoadingCompanyFilters: action.loading,
        companyFilters: initialState.companyFilters
      };
    }

    case ADMIN_COMPANY_FILTERS_LOAD: {
      return {
        ...state,
        isLoadingCompanyFilters: false,
        companyFilters: action.data || initialState.companyFilters
      };
    }

    case ADMIN_USER_MFA_MODAL_SHOW: {
      return {
        ...state,
        isLoadingUsers: false,
        userToManageMFA: {...action.data}
      };
    }

    case ADMIN_USER_MFA_MODAL_CLOSE: {
      return {
        ...state,
        isLoadingUsers: false,
        userToManageMFA: initialState.userToManageMFA
      };
    }

    case ADMIN_USER_IS_LOADING: {
      return {
        ...state,
        isLoadingUsers: action.loading
      };
    }

    case ADMIN_DISTRIBUTOR_IS_LOADING: {
      return {
        ...state,
        isLoadingDistributors: action.loading
      };
    }

    case ADMIN_DISTRIBUTOR_IS_SAVING: {
      return {
        ...state,
        isSavingDistributor: action.loading || action.saving || initialState.isSavingDistributor
      };
    }

    case ADMIN_DISTRIBUTOR_ALL: {
      return {
        ...state,
        isLoadingDistributors: false,
        distributors: action.data || initialState.distributors,
        distributorFilters: action.filters || state.distributorFilters
      };
    }

    case ADMIN_DISTRIBUTOR_MODAL_DATA: {
      return {
        ...state,
        distributorModalData: action.data || initialState.distributorModalData
      };
    }

    case ADMIN_MERGE_INTO_SYSTEM_DISTR_MODAL_DATA: {
      return {
        ...state,
        mergeIntoSystemDistrModalData: action.data || initialState.mergeIntoSystemDistrModalData
      };
    }

    case ADMIN_DISTRIBUTOR_DELETE: {
      const {distributorID} = action;
      const {distributors} = state;
      let distributorIndex = distributors.findIndex(c => c.DistributorID === distributorID);
      if (distributorIndex !== -1) {
        distributors.splice(distributorIndex, 1);
      }
      return {
        ...state,
        isSavingDistributor: false,
        distributors
      };
    }

    case ADMIN_DISTRIBUTOR_UPDATE: {
      const {values, distributorID} = action;
      const {distributors} = state;
      let distributorIndex = distributors.findIndex(c => c.DistributorID === distributorID);
      if (distributorIndex !== -1) {
        if (!values.LogoUrl) {
          delete values.LogoUrl;
        }
        distributors[distributorIndex] = {...distributors[distributorIndex], ...values};
      }
      return {
        ...state,
        isSavingDistributor: false,
        distributorModalData: initialState.distributorModalData,
        distributors
      };
    }

    case ADMIN_USER_MANAGE_PERMISSIONS_MODAL_SHOW: {
      return {
        ...state,
        isLoadingUsers: false,
        dataToManageUserPermissions: {...action.data}
      };
    }

    case ADMIN_USER_MANAGE_PERMISSIONS_MODAL_CLOSE: {
      return {
        ...state,
        isLoadingUsers: false,
        dataToManageUserPermissions: initialState.dataToManageUserPermissions
      };
    }

    case ADMIN_USER_MANAGE_PERMISSIONS_CHANGE: {
      const {qcProjectId, permissionTypeId, companyId} = action;
      const data = [...state.dataToManageUserPermissions.projects.data];
      if (qcProjectId) {
        (data.find(r => r.QcProjectID === qcProjectId && r.CompanyID === companyId) || {}).PermissionTypeID = permissionTypeId;
      } else {
        data.forEach(item => {
          item.PermissionTypeID = permissionTypeId;
        });
      }
      return {
        ...state,
        dataToManageUserPermissions: {...state.dataToManageUserPermissions, projects: {...state.dataToManageUserPermissions.projects, data}}
      };
    }

    case ADMIN_SERIES_IS_LOADING: {
      return {
        ...state,
        isLoadingSeries: action.loading
      };
    }

    case ADMIN_SERIES_ALL: {
      return {
        ...state,
        isLoadingSeries: false,
        series: action.data || initialState.series,
      };
    }

    default:
      return state;
  }
}
