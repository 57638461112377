import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Button} from 'react-bootstrap';
import CommentIcon from '../../../../../assets/images/issueTable/comment-white.svg';
import CheckIcon from '../../../../../assets/images/issueTable/check.svg';
import VideoIcon from '../../../../../assets/images/issueTable/video.svg';
import {getUserRoles} from '../../../../utils';
import Can from '../../../../components/Can';
import {ConflictArea} from '../../../WOConflicts/constants';
import {useHistory} from 'react-router-dom';
import {isOperatorUser} from '../../../../utils';

const CollapsedRow = React.memo((props) => {
  const {
    row, onClickDiscuss, onClickFixNotes, onClickView, onClickEdit, onClickDelete, isEnabledInteractiveView,
    canManage, canComment, onClickUndelete, allowEditOnRequest
  } = props;
  let history = useHistory();
  return (
    <CollapsedRowStyled>
      {!!canComment &&
        <Button
          onClick={() => onClickDiscuss(row)}
          >
          <img src={CommentIcon} alt="discuss"/> <span>Discuss Issue</span>
        </Button>
      }
      {
        !isOperatorUser() && !!canManage && !row.IsDeleted &&
          <Button
            onClick={() => onClickFixNotes(row)}
            >
            <img src={CheckIcon} alt="accept"/> <span>Fix Notes</span>
          </Button>
      }
      {
        isEnabledInteractiveView ? (
          <Button
            title="Go to issue in Live View"
            onClick={() => onClickView(row)}
            >
            <img src={VideoIcon} alt="view"/> <span>Live View</span>
          </Button>
        ) : null
      }
      <Can
        roles={getUserRoles()}
        perform="report-details:show-conflicts-issue-btn"
        data={row}
        yes={() => (
          <Button
            title="Click to view and resolve conflicts"
            onClick={() => {
              try {
                history.push(`/conflicts/${ConflictArea.ISSUE}/${row.IssueID}`);
              } catch {}
            }}
            >
            <span><i className="fas fa-clone"/>Conflicts View</span>
          </Button>
        )}
      />
      {!!onClickEdit &&
        <Can
          roles={getUserRoles()}
          perform="report-details:show-edit-issue-btn"
          data={{...row, allowEditOnRequest}}
          yes={() => (
            <Button
              title="Click to edit Issue"
              onClick={() => onClickEdit(row)}
              >
              <span><i className="fas fa-edit"/>Edit Issue</span>
            </Button>
          )}
        />
      }
      {row.IsDeleted ?
        !!onClickUndelete &&
          <Can
            roles={getUserRoles()}
            perform="report-details:show-delete-issue-btn"
            data={row.IsQodIssue}
            yes={() => (
              <Button
                title="Click to undelete Issue"
                onClick={() => onClickUndelete(row)}
                >
                <span><i className="fas fa-trash-restore"/>Undelete Issue</span>
              </Button>
            )}
          /> :
            !!onClickDelete &&
              <Can
                roles={getUserRoles()}
                perform="report-details:show-delete-issue-btn"
                data={row.IsQodIssue}
                yes={() => (
                  <Button
                    title="Click to delete Issue"
                    onClick={() => onClickDelete(row)}
                    >
                    <span><i className="fas fa-trash"/>Delete Issue</span>
                  </Button>
                )}
              />
      }
    </CollapsedRowStyled>
  );
});

const CollapsedRowStyled = styled.div`
  background-color: #575757;
  padding: 4px;

  .btn {
    border: none;
    background-color: transparent;
    box-shadow: none;
    color: #fff;
    font-size: 10px;
    &:hover, &:focus {
      background-color: transparent;
    }
    img {
      margin-right: 5px;
    }
    i {
      font-size: 13px;
      &.fa-edit, &.fa-clone {
        margin-right: 7px;
        padding-left: 3px;
      }
      &.fa-trash, &.fa-trash-restore {
        margin-right: 6px;
        @media (max-width: 767px) {
          padding-left: 3px;
          padding-right: 3px;
        }
      }
    }

    .submit-replacement-modal & {
      &:nth-child(2) {
        display: none;
      }
    }
  }
`;

CollapsedRow.defaultProps = {
  canComment: true,
  canManage: true,
};

CollapsedRow.propTypes = {
  row: PropTypes.object,
  canComment: PropTypes.bool,
  canManage: PropTypes.bool,
  onClickDiscuss: PropTypes.func,
  onClickFixNotes: PropTypes.func,
  onClickView: PropTypes.func,
  isEnabledInteractiveView: PropTypes.bool
};

export default CollapsedRow;
