import moment from 'moment';
import 'moment-timezone';
import Cookies from 'js-cookie';
import config from '../config';
import {CONTENT_TYPES} from './pages/NewRequest/constants';

export const emptyFunction = () => {};

export function ensureArray(value) {
  if (!value) {
    return [];
  }

  if (Array.isArray(value)) {
    return value;
  }

  return [value];
}

export function buildDateFeatured(data, formatDateFunc) {
  const start = formatDateFunc(data.start, 'DD/MM/YYYY');
  return start;
}

export const getErrorMessage = (error, errorCodeToSearch) => {
  if (!error) {
    return null;
  } else if (typeof error === 'string') {
    return error;
  } else if (error.responseJSON) {
    const hasErrorsArr = Array.isArray(error.responseJSON.errors) && error.responseJSON.errors.length;
    if (errorCodeToSearch && hasErrorsArr) {
      const errorByCode = error.responseJSON.errors.find(item => item.code === errorCodeToSearch);
      if (errorByCode) {
        return [errorByCode.message, error.responseJSON.message];
      }
    }
    const errorText = hasErrorsArr ? ` (${error.responseJSON.errors[0].message})` : undefined;
    return `${error.responseJSON.message}${errorText !== undefined ? errorText : ''}`;
  }
  return error.statusText;
};

export const getErrorMessageByCode = (error, code) => {
  if (error && typeof error === 'object' && error.responseJSON && Array.isArray(error.responseJSON.errors)) {
    const {message, errors} = error.responseJSON;
    const errorByCode = errors.find(i => i.code === code);
    if (errorByCode) {
      return errors.length === 1 && (message || '').startsWith(errorByCode.message) ? message : errorByCode.message;
    }
  }
  return null;
};

export const getErrorStatus = error => {
  if (error && typeof error === 'object') {
    return error.responseJSON ? error.responseJSON.status : error.status;
  }
  return null;
};

export const formatDurationAsMinutes = runtimeInSec => {
  if (runtimeInSec) {
    if (runtimeInSec < 3600) {
      return moment.utc(runtimeInSec * 1000).format('mm[m] ss[s]');
    }
    return moment.utc(runtimeInSec * 1000).format('HH[h] mm[m] ss[s]');
  }
  return '-';
};

export const parseRuntimeInSec = runtimeInSec => {
  if (typeof runtimeInSec !== 'number') {
    return null;
  }
  const sec = runtimeInSec % 60;
  const totalMinute = Math.floor(runtimeInSec / 60);
  const min = totalMinute % 60;
  const hour = Math.floor(totalMinute / 60);
  const parts = [`${hour < 10 ? 0 : ''}${hour}:${min < 10 ? 0 : ''}${min}`, `${sec < 10 ? 0 : ''}${sec}:00`];
  const hourWithColon = hour > 0 ? `${hour < 10 ? 0 : ''}${hour}:` : '';
  const runtimeWithColon = `${hourWithColon}${min < 10 ? 0 : ''}${min}:${sec < 10 ? 0 : ''}${sec}`;
  const secondWithColon = sec > 0 ? `:${sec < 10 ? 0 : ''}${sec}` : '';
  const runtimeWithColonForMins = `${hourWithColon}${min < 10 && hourWithColon ? 0 : ''}${min}${secondWithColon}`;
  const runtime = `${hour}h ${min}m ${sec}s`.replace(/\b0{1}(h|m|s)\b/g, '').replace(/\s{2,}/g, ' ').trim();
  return {hour, min, sec, parts, full: `${parts[0]}:${parts[1]}`, runtimeWithColon, runtimeWithColonForMins, runtime};
};

export const getTimeCode = valueInSec => {
  if (typeof valueInSec !== 'number' && (typeof valueInSec !== 'string' || !valueInSec.match(/^[0-9]+$/))) {
    return '';
  }
  const timecode = parseRuntimeInSec(valueInSec);
  return timecode ? timecode.full : null;
};

export const getTimeCodeWithMillis = (value, unit) => {
  if (typeof value !== 'number' && (typeof value !== 'string' || !value.match(/^[0-9]+$/))) {
    return '';
  }
  const d = Number(value) * (unit === 'ms' ? 1 : 1000);
  const millis = Math.floor(d % 1000);
  const formattedMillis = `${millis < 10 ? '00' : millis < 100 ? '0' : ''}${millis}`;
  const seconds = Math.floor(d / 1000);
  const min = Math.floor(seconds % 3600 / 60);
  const sec = Math.floor(seconds % 3600 % 60);
  const hour = Math.floor(seconds / 3600);
  return `${hour < 10 ? '0' : ''}${hour}:${min < 10 ? '0' : ''}${min}:${sec < 10 ? '0' : ''}${sec}.${formattedMillis}`;
};

const getFrameRate = frameRate => frameRate || 23.98;

export const getSecondsFromTimeCode = (timecode, frameRate, defaultValue) => {
  let result = 0;
  if (timecode) {
    const [hours, minutes, seconds, frames] = timecode.split(':');
    if (!hours && !minutes && !seconds && !frames && defaultValue) {
      return defaultValue;
    }
    if (hours) {
      result += Number(hours) * 3600;
    }
    if (minutes) {
      result += Number(minutes) * 60;
    }
    if (seconds) {
      result += Number(seconds);
    }
    if (frames) {
      result += Number(frames / getFrameRate(frameRate));
    }
  } else if (defaultValue) {
    return defaultValue;
  }
  return result;
};

export const getTimeCodeForVideoPlayer = (secs, frameRate, startFileOffsetSeconds) => {
  const secondsNumber = (secs || 0) + (startFileOffsetSeconds || 0);
  let hours = Math.floor(secondsNumber / 3600);
  let minutes = Math.floor((secondsNumber - (hours * 3600)) / 60);
  let seconds = Math.floor(secondsNumber - (hours * 3600) - (minutes * 60));
  let millis = ((secondsNumber % 1) * 1000).toFixed(0);
  let frames = (millis * getFrameRate(frameRate) / 1000).toFixed(0);

  if (hours < 10) {hours = '0' + hours;}
  if (minutes < 10) {minutes = '0' + minutes;}
  if (seconds < 10) {seconds = '0' + seconds;}
  if (frames < 10) {frames = '0' + frames;}

  return `${hours}:${minutes}:${seconds}:${frames}`;
};

export const getSortedRowsBy = (rows, field, direction, altFields, altFieldsGet) => {
  if (!field) {
    return rows;
  }

  const newRows = rows.slice();
  newRows.sort((a, b) => {
    if (altFieldsGet && typeof altFieldsGet[field] === 'function') {
      a = altFieldsGet[field](a[field], a);
      b = altFieldsGet[field](b[field], b);
    } else {
      a = a[(altFields || {})[field] || field];
      b = b[(altFields || {})[field] || field];
    }

    if (direction === 'desc') {
      const tmp = a;
      a = b;
      b = tmp;
    }

    if (typeof a === 'number') {
      return a - b;
    }

    if (typeof a === 'boolean') {
      return (a === b) ? 0 : a ? -1 : 1;
    }

    return (a || '').localeCompare(b || '');
  });

  return newRows;
};

export const getAsperaConnect = (params) => {
  try {
    return new window.AW4.Connect(params);
  } catch(err) {
    console.error('Could not connect to Aspera. window.AW4 = ', window.AW4, JSON.stringify(err, null, 4));
    return null;
  }
};

export const asperaUploadFile = (dataTransferObj, asperaWeb, asperaTransferSpec, successCallback) => {
  const files = dataTransferObj.dataTransfer.files;
  if (files.length === 0) {
    return;
  }
  console.log(files.map(file => ({source: file.name})));
  const transferSpec = {
    ...asperaTransferSpec,
    paths: files.map(file => ({source: file.name})),
    authentication: 'token'
  // cookie: `u-${new Date().getTime()}`
  };
  asperaWeb.startTransfer(transferSpec, {allow_dialogs: false}, {
    error: err => {
      console.log(`Failed to start aspera transfer: ${JSON.stringify(err, null, 4)}`);
    },
    success: res => {
      console.log('Started aspera transfer: ', res);
      const resultTransferSpec = res.transfer_specs[0];
      const app_id = resultTransferSpec.aspera_connect_settings.app_id;
      successCallback(app_id, resultTransferSpec.uuid, resultTransferSpec.transfer_spec.paths);
    }
  });
};

export const asperaUploadStatus = (asperaWeb, appId, transferId, successCallback) => {
  asperaWeb.initSession(appId);
  asperaWeb.getAllTransfers({
    error: err => {
      console.log(`Failed Get All Transfers: ${JSON.stringify(err, null, 4)}`);
    },
    success: res => {
      console.log('Started Get All Transfers: ', res);
      const transferInfo = res.transfers.find(t => t.uuid === transferId);
      if (transferInfo) {
        successCallback(transferInfo.status);
      }
    }
  });
};

const formatDateFromTo = (date, fromFormat, toFormat, useTZ) => {
  if (date && date !== '' && date !== 'N/A') {
    const momentDate = fromFormat === 'x' ? moment(date, fromFormat) : moment(date, [(fromFormat || ''), moment.ISO_8601], false);
    let timezone = useTZ ? moment.tz.guess() : null;
    let result;
    if (timezone) {
      result = momentDate.tz(timezone).format(toFormat || 'YYYY-MM-DD');
    } else {
      result = momentDate.format(toFormat || 'YYYY-MM-DD');
    }
    return toFormat === 'x' ? +result : result;
  }
  return date || '';
};

export const formatDate = (date, fromFormat, toFormat) => {
  return formatDateFromTo(date, fromFormat, toFormat, false);
};

export const formatDateTz = (date, fromFormat, toFormat) => {
  return formatDateFromTo(date, fromFormat, toFormat, true);
};

export const getCurrentDomainObj = () => {
  if (process.env.NODE_ENV === 'production') {
    const split = window.location.host.split('.');
    return { domain: `${split[split.length - 2]}.${split[split.length - 1]}`, expires: 30 };
  }
  return {};
};

export const getCurrentPageToSave = () => {
  const {pathname, search, hash} = window.location;
  return `${pathname}${search}${hash}`;
};

export const buildRedirectUrlToLoginPage = () => {
  const curPage = getCurrentPageToSave();
  if (window.location.pathname === '/login') {
    return curPage;
  }
  const redirectParam = curPage.length > 1 ? `?r=${encodeURIComponent(curPage)}` : '';
  return `/login${redirectParam}`;
};

export const getUserRoles = () => {
  return Cookies.get('__auth') ? JSON.parse(Cookies.get('__role', getCurrentDomainObj()) || '[]') : [];
};

export const isProductionUser = () => {
  return getUserRoles().includes(config.roles.PRODUCTION);
};

export const isProductionUserWithoutDashboard = () => {
  return isProductionUser() && !getUserRoles().includes(config.roles.SHOW_DASHBOARD);
};

export const isFinishingHouseUser = () => {
  return getUserRoles().includes(config.roles.FINISHING_HOUSE);
};

export const isStudioPartnerUser = () => {
  return isProductionUser() || isFinishingHouseUser();
};

export const isOperatorUser = () => {
  return getUserRoles().includes(config.roles.OPERATOR);
};

export const isClientServiceUser = () => {
  return getUserRoles().includes(config.roles.CLIENT_SERVICE);
};

export const isOpsManagerUser = () => {
  return getUserRoles().includes(config.roles.OPS_MANAGER);
};

export const isDwManagerUser = () => {
  return getUserRoles().includes(config.roles.DW_MANAGER);
};

export const isManagerUser = () => {
  return isClientServiceUser() || isOpsManagerUser() || isDwManagerUser();
};

export const isManagerUserCanEditQcProfiles = () => {
  return isClientServiceUser() || isOpsManagerUser();
};

export const isInternalUser = () => {
  return isManagerUser() || isOperatorUser();
};

export const isStudioUser = () => {
  return getUserRoles().includes(config.roles.STUDIO);
};

export const isAdminUser = () => {
  return getUserRoles().includes(config.roles.ADMIN);
};

export const isAllowManageUsers = () => {
  return getUserRoles().includes(config.roles.MANAGE_USERS);
};

export const isAllowManageUsersPermissions = () => {
  return getUserRoles().includes(config.roles.MANAGE_USERS__PERMISSIONS);
};

export const isAllowCreateClientUsersOnly = () => {
  return getUserRoles().includes(config.roles.MANAGE_USERS__CREATE_CLIENTS_ONLY);
};

export const allowViewUserAdminPage = () => {
  return isAllowManageUsers() || isAllowManageUsersPermissions();
};

export const isArtworkStudioUser = () => {
  return getUserRoles().includes(config.roles.ARTWORK_STUDIO);
};

export const isArtworkPostPartnerUser = () => {
  return getUserRoles().includes(config.roles.ARTWORK_POST_PARTNER);
};

export const isArtworkClientUser = () => {
  return isArtworkStudioUser() || isArtworkPostPartnerUser();
};

export const isArtworkOperatorUser = () => {
  return getUserRoles().includes(config.roles.ARTWORK_OPERATOR);
};

export const isArtworkManagerUser = () => {
  return getUserRoles().includes(config.roles.ARTWORK_MANAGER);
};

const isManagerAsStudioPartnerUser = newRequestValues => {
  if (isManagerUser() && newRequestValues && (typeof newRequestValues.cs_production_id === 'number' ||
      (typeof newRequestValues.cs_finishing_house_id === 'number' && newRequestValues.cs_finishing_house_id > 0))) {
    return true;
  }
  return false;
};

export const isStudioPartnerOrManagerAsSuchUser = newRequestValues => {
  return isStudioPartnerUser() || isManagerAsStudioPartnerUser(newRequestValues);
};

export const getHiddenWizardFields = (qcProfiles, qcProfileId) => {
  const hiddenFieldsStr = (qcProfiles.find(item => item.value === qcProfileId) || {}).hidden_wizard_fields;
  return hiddenFieldsStr ? hiddenFieldsStr.split(',').map(s => s.trim()) : [];
};

export const buildDefaultAudioFormat = hiddenFields => {
  return hiddenFields.includes('audio_configuration') ? {} : {audio_configuration: {not_sure: false, data: []}};
};

export const buildDefaultVideoFormat = (/*hiddenFields, qcType*/) => {
  const value = /*qcType.is_audio ? {audio_format: buildDefaultAudioFormat(hiddenFields)} : */{};
  return {...value, files: {}};
};

export const getTextlessOptionName = value => {
  const options = {
    0: 'Texted',
    1: 'Textless',
    2: 'Textless & Texted'
  };
  return options[value] || null;
};

export const getOptionName = (options, value, defaultText) => {
  return ((options || []).find(o => o.value === value) || {}).name || (typeof defaultText === 'string' ? defaultText : '-');
};

export const isPresentTextlessVersion = value => {
  return [1/*Textless*/, 2/*Textless & Texted*/].includes(value);
};

export const showRuntimeTextlessField = videoFormats => {
  return Array.isArray(videoFormats) && videoFormats.length > 1 &&
         videoFormats.some(f => isPresentTextlessVersion(f.textless)) &&
         videoFormats.some(f => !isPresentTextlessVersion(f.textless));
};

const QC_TYPE_COLOR = 'e75593';
const TEXTED_COLOR = '8FBC8F';
const TEXTLESS_COLOR = 'FFD700';
const TEXTLESS_AND_TEXTED_COLOR = '32CD32';

const getMixedColor = (color, colorAdmixture) => {
  if (!color) {
    return colorAdmixture;
  } else if (!colorAdmixture) {
    return color;
  }

  let mixedColorArray = '';
  const hexColorArray = color.match(/.{2}/g);
  const hexColorAdmixtureArray = colorAdmixture.match(/.{2}/g);

  for (let i = 0; i < hexColorArray.length; i++) {
    const avg = Math
      .floor(
        (parseInt(hexColorArray[i], 16) + parseInt(hexColorAdmixtureArray[i], 16)) / 2
      )
      .toString(16);
    mixedColorArray += (avg === 0 ? '00' : avg.toString());
  }
  return mixedColorArray;
};

export const getColorByOption = (combination, optionName)=> {
  if ('video' === optionName) {
    return getMixedColor(combination.ColorSpaceColor,  combination.ResolutionColor);
  } else if ('audio' === optionName) {
    return getMixedColor(combination.AudioSoundColor1,  combination.AudioSoundColor2);
  } else if ('texted' === optionName) {
    if (combination.texted === 'Textless') {
      return TEXTLESS_COLOR;
    } else if (combination.texted === 'Texted') {
      return TEXTED_COLOR;
    } else if (combination.texted === 'Textless & Texted') {
      return TEXTLESS_AND_TEXTED_COLOR;
    }
  } else if ('caption' === optionName) {
    return combination.CaptionColor;
  }
  return QC_TYPE_COLOR;
};

export const isCanceledSeason = title => {
  return (title || '').startsWith('CANCELED - ');
};

const isDisabledEditingRequestFieldByRequestStatus = (statusID, title) => {
  return [
    config.requestStatuses.CANCELLED
  ].includes(statusID) || (statusID === config.requestStatuses.NONE && isCanceledSeason(title));
};

const isDisabledEditingRequestStepByRequestStatus = (statusID, title) => {
  return isDisabledEditingRequestFieldByRequestStatus(statusID, title) || [
    config.requestStatuses.CLOSED,
    config.requestStatuses.NEEDS_FIXES
  ].includes(statusID);
};

const MemfisStatus = {
  CANCELLED: 'Cancelled',
  COMPLETE: 'Complete'
};

const isDisabledEditingRequestFieldByWoMemfisStatus = status => {
  return [
    MemfisStatus.CANCELLED.toLowerCase()
  ].includes((status || '').toLowerCase());
};

const isDisabledEditingRequestStepByWoMemfisStatus = status => {
  return isDisabledEditingRequestFieldByWoMemfisStatus(status) || [
    MemfisStatus.COMPLETE.toLowerCase()
  ].includes((status || '').toLowerCase());
};

export const isDisabledEditingRequestField = ({RequestStatusID, MemfisStatus, Title}) => {
  return isDisabledEditingRequestFieldByRequestStatus(RequestStatusID, Title) ||
         isDisabledEditingRequestFieldByWoMemfisStatus(MemfisStatus);
};

export const isDisabledEditingRequestStep = ({RequestStatusID, MemfisStatus, Title}) => {
  return isDisabledEditingRequestStepByRequestStatus(RequestStatusID, Title) ||
         isDisabledEditingRequestStepByWoMemfisStatus(MemfisStatus);
};

const isAllowedUserPermission = (userPermissionType, expectedPermissionType) => {
  return !!userPermissionType && userPermissionType >= expectedPermissionType;
};

export const isUserCanComment = userPermissionType => {
  return isAllowedUserPermission(userPermissionType, config.requestUserPermission.CAN_COMMENT);
};

export const isUserCanManage = userPermissionType => {
  return isAllowedUserPermission(userPermissionType, config.requestUserPermission.CAN_MANAGE);
};

export const formatSeasonNameForTitle = (seasonNum) => {
  if (typeof seasonNum === 'number' || (typeof seasonNum === 'string' && seasonNum.match(/^\d+$/))) {
    return ` S${String(seasonNum).padStart(2, '0')}`;
  }
  return ` S_${seasonNum}`;
};

export const formatEpisodeNumber = (episodeNum) => {
  if (typeof episodeNum === 'number' || (typeof episodeNum === 'string' && episodeNum.match(/^\d+$/))) {
    return `Ep${String(episodeNum).padStart(2, '0')}`;
  }
  return `Ep_${episodeNum}`;
};

export const getDefaultRedirectUrl = () => {
  if (isArtworkClientUser()) {
    return '/dashboard';
  }
  return (isInternalUser() || isProductionUserWithoutDashboard()) ? '/requests' : '/';
};

export const buildReportLink = ({ContentTypeID, RequestID, QcProjectID}) => {
  if (ContentTypeID === 5/*artwork content type id*/) {
    return `/report?asset_id=${RequestID}`;
  }
  if (ContentTypeID === CONTENT_TYPES.Season) {
    return `/requests/seasons/${QcProjectID}`;
  }
  return `/requests/${RequestID}`;
};

export const isInvalidPasswordWithMessage = password => {
  if (!password) {
    return "Password cannot be blank";
  }

  if (password.length < config.passwordMinLength) {
    return "Password length cannot be less than " + config.passwordMinLength;
  }

  if (!password.match(/^[A-Za-z0-9!@#$%&*]+$/) || ["admin", "client", "qwerty"].includes(password.toLowerCase())) {
    return "Password cannot be too simple";
  }

  let fulfilledRequirements = 0;
  if (password.match(/^.*[a-z]+.*$/)) {
    fulfilledRequirements++;
  }
  if (password.match(/^.*[A-Z]+.*$/)) {
    fulfilledRequirements++;
  }
  if (password.match(/^.*[0-9]+.*$/)) {
    fulfilledRequirements++;
  }
  if (password.match(/^.*[!@#$%&*]+.*$/)) {
    fulfilledRequirements++;
  }
  if (fulfilledRequirements < 3) {
    return "Your password must include 3 of the following 4: lowercase, uppercase, numerals, and special characters";
  }

  return null;
};

export const isInvalidPassword = password => {
  return !!isInvalidPasswordWithMessage(password);
};
