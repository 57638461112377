import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styles from 'styled-components';
import Alerts from './components/Alerts';
import Header from './components/Header';
import Loader from '../components/Loader';
import 'bootstrap';
import Search from '../pages/Search/container';
import {getUnseenNotifications, showConfirmAlertToAutoCreateReplacementWO} from './actions';
import NotificationBar from './components/Notifications/NotificationBar';
import NotificationsModal from './components/Notifications/NotificationsModal';
import MobileHeaderMenu from "./components/MobileHeaderMenu";
import SideBar from './components/Sidebar';
import ClientServiceCreateRequestModal from './components/modals/ClientServiceCreateRequestModal';
import {getUserRoles} from '../utils';
import Can from '../components/Can';
import {confirmAlert} from 'react-confirm-alert';
import {createWorkOrder} from '../pages/RequestDetails/actions';

const renderSearch = () => {
  return <Search/>;
};

const AppLayout = props => {
  const {dispatch, className, user, sidebar, mainState, withMainHeader, title, mobileTitle} = props;
  const {unseenNotifications, notificationBarIsOpen, notificationsModalIsOpen,
    csStudiosIsLoading, csCreateRequestModalData,
    requestIdToAutoCreateReplacementWO, showCsCreateRequestModal, notificationsIsLoading,
    isLoading,
  } = mainState;
  const isNewRequestLayout = window.location.pathname === '/requests/new';
  const showNotificationBar = notificationBarIsOpen && !isNewRequestLayout && window.location.pathname.length <= 1;
  const showNotificationsModal = notificationsModalIsOpen && !isNewRequestLayout;

  const userRoles = getUserRoles();

  const closeSidebarIfOpen = () => {
    document.body.classList.add('sidebar-collapsed');
  };

  const onFocus = React.useCallback(() => {
    setTimeout(function() {
      document.title = 'QC On Demand: ' + title;
    }, 200);
  }, [title]);

  useEffect(() => {
    window.addEventListener("focus", onFocus);

    return function cleanup() {
      window.removeEventListener("focus", onFocus);
    };
  }, [onFocus]);

  useEffect(() => {
    if (window.location.pathname !== '/requests/new') {
      dispatch(getUnseenNotifications());
    }
    if (window.location.pathname === '/requests/new') {
      closeSidebarIfOpen();
    }

    document.title = `QC On Demand: ${title}`;
  }, [dispatch, title]);

  useEffect(() => {
    if (requestIdToAutoCreateReplacementWO) {
      confirmAlert({
        title: 'Replacement WO',
        message: 'Would you like to create a work order at this time?',
        buttons: [{
          label: 'No',
          onClick: () => {
            dispatch(showConfirmAlertToAutoCreateReplacementWO());
          }
        }, {
          label: 'Yes',
          onClick: () => {
            dispatch(createWorkOrder(requestIdToAutoCreateReplacementWO, true));
          }
        }]
      });
    }
  }, [dispatch, requestIdToAutoCreateReplacementWO]);

  return !user.isLogin ? <Loader/> : (
    <div className={className}>
      {isNewRequestLayout ?
        null :
        withMainHeader ?
          <>
            <Header
              notifications={unseenNotifications}
              showNotificationsLoader={!notificationsModalIsOpen && notificationsIsLoading}
              sidebarIsOpen={mainState.sidebarIsOpen}
              email={user.email}
              username={user.name}
              avatar={user.avatar}
              studioName={user.studio}
              mobileTitle={mobileTitle || title}
            />
            <MobileHeaderMenu user={user}/>
          </> : null
      }
      <LayoutStyled>
        {showNotificationBar ? <NotificationBar/> : null}
        {showNotificationsModal ? <NotificationsModal/> : null}
        {showCsCreateRequestModal && csCreateRequestModalData ? <ClientServiceCreateRequestModal data={csCreateRequestModalData}/> : null}
        {(csStudiosIsLoading || isLoading) ? <Loader className="full-screen"/> : null}
        <main>
          {props.children}
        </main>
        {sidebar}
        <Can
          roles={userRoles}
          perform="header:show-search-btn"
          yes={renderSearch}
        />
      </LayoutStyled>
      <Alerts/>
    </div>
  );
};

const LayoutStyled = styles.div`
  overflow: hidden;
  position: relative;

  main {
    padding-right: 397px;
    transition: padding-right 0.3s ease-in-out,width 0.3s ease-in-out;
    width: 100%;

    body.sidebar-collapsed & {
      padding-right: 0;
    }
  }

  @media (max-width: 768px) {
    .without-main-header & {
      main > div:first-of-type {
        margin: 0;
        min-height: 100vh;
        padding: 0;
      }
    }
  }

  &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background: rgba(0, 0, 0, .1);
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgba(0, 0, 0, .2);
      -webkit-box-shadow: none;
    }
`;

AppLayout.defaultProps = {
  sidebar: <SideBar/>,
  withMainHeader: true
};

AppLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  title: PropTypes.string,
  mobileTitle: PropTypes.string,
  className: PropTypes.string,
  sidebar: PropTypes.element,
  user: PropTypes.object.isRequired,
  withMainHeader: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    user: state.user,
    mainState: state.main
  };
}

export default connect(
  mapStateToProps
)(withRouter(AppLayout));
