import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loader from '../../../../components/Loader';
import VideoPlayer from '../videoplayer/VideoPlayer';
import StyledVideoPlayer from '../videoplayer/StyledVideoPlayer';
import {generateProxy, getProxy} from '../../actions';

class InteractiveViewTab extends Component {
  state = {
    displayError: false
  };

  clearTimer = () => {
    if (this.updateTimerId) {
      clearTimeout(this.updateTimerId);
      this.updateTimerId = undefined;
    }
  };

  setTimer = () => {
    const {dispatch} = this.props;
    this.clearTimer();
    this.updateTimerId = setTimeout(() => {
      dispatch(getProxy());
      this.clearTimer();
    }, 30 * 1000);
  };

  needToSetTimer = (hasRawProxy, proxyStatus) => !!hasRawProxy && ['pending', 'in progress'].includes(proxyStatus);

  componentDidMount() {
    const {reportdetails} = this.props;
    const hasRawProxy = reportdetails.details.has_raw_proxy;
    const proxyStatus = reportdetails.details.request_proxy_status;
    if (this.needToSetTimer(hasRawProxy, proxyStatus)) {
      this.setTimer();
    }
  }

  componentDidUpdate(prevProps) {
    const {requestId, reportdetails: {details, isProxySaving, isProxyLoading}} = this.props;
    const hasRawProxy = details.has_raw_proxy;
    const proxyStatus = details.request_proxy_status;
    const needsTimer = this.needToSetTimer(hasRawProxy, proxyStatus);
    const isChangedRequest = requestId !== prevProps.requestId || details.RequestID !== (prevProps.details || {}).RequestID;
    if (this.updateTimerId) {
      if (isChangedRequest || !needsTimer) {
        this.clearTimer();
        needsTimer && this.setTimer();
      }
    } else if ((isChangedRequest ||
                (!isProxySaving && prevProps.reportdetails.isProxySaving) ||
                (!isProxyLoading && prevProps.reportdetails.isProxyLoading)) &&
               needsTimer) {
      this.setTimer();
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  onErrorLoadVideo = (event) => {
    this.setState({displayError: true});
  };

  handleGenerateProxy = () => {
    const {dispatch, requestId} = this.props;
    dispatch(generateProxy(requestId));
  };

  buildErrorFragment = (message) => {
    const defaultErrorMsg = (<span>This video is not available<br/>Sorry about that.</span>);

    return (
      <Fragment>
        <StyledVideoPlayer className="video-container">
          <video className="video" ref="player">
            <source src="" type="video/mp4"/>
          </video>
          <div className="overlay">
            <div className="content">
              {message || defaultErrorMsg}
            </div>
          </div>
        </StyledVideoPlayer>
      </Fragment>
    );
  }

  render() {
    const {dispatch, reportdetails, loading, onScrollToIssueOnSidebar} = this.props;
    const {displayError} = this.state;

    const pendingMsg = (<span>Your watermarked proxy video is being prepared.<br/>Please check back in a few minutes</span>);

    const hasRawProxy = reportdetails.details.has_raw_proxy;
    const proxyStatus = reportdetails.details.request_proxy_status;
    const proxyPercentComplete = reportdetails.details.request_proxy_percent_complete;

    let message;
    if (proxyStatus === 'pending') {
      message = pendingMsg;
    } else if (proxyStatus === 'in progress') {
      message = !hasRawProxy ? pendingMsg : (
        <span>
          Please wait.  Your secure proxy is currently being created.
          {typeof proxyPercentComplete === 'number' ? <><br/>{`${proxyPercentComplete}% Complete`}</> : null}
        </span>
      );
    } else if (proxyStatus === 'error') {
      message = 'The secure proxy is currently unavailable.  Please contact qodsupport@eurofins-dms.com for assistance.';
    } else if (proxyStatus === 'none') {
      message = !hasRawProxy ? (
        <span>
          We're sorry, but you do not have access to this content.<br/>
          To request access, please contact qodsupport@eurofins-dms.com
        </span>
      ) : (
        <span>
          A secure proxy hasn't been created for your account.<br/>
          <GenerateProxyBtn
            onClick={loading ? undefined : this.handleGenerateProxy}
            className={loading ? 'disabled' : undefined}
          >
            Click here to generate your secure proxy
          </GenerateProxyBtn>
        </span>
      );
    }

    if (displayError || message) {
      return this.buildErrorFragment(message);
    }

    return (
      <Fragment>
        {(loading || reportdetails.isProxySaving) ? <Loader/> : null}
        {
          reportdetails.details.request_proxy ?
            <VideoPlayer
              dispatch={dispatch}
              savedState={reportdetails.savedState}
              issues={reportdetails.issues}
              url={reportdetails.details.request_proxy}
              captions={reportdetails.details.proxy_captions || []}
              isActiveTopTab={reportdetails.activeTopTab === 2}
              isPlaying={reportdetails.videoPlayer.isPlaying}
              isChangedSelecting={reportdetails.videoPlayer.isChangedSelecting}
              selectedIssueID={reportdetails.videoPlayer.selectedIssue.IssueID}
              selectedCueID={(reportdetails.videoPlayer.selectedCue || {}).CueID}
              timeCodeStart={
                reportdetails.videoPlayer.selectedCue ? reportdetails.videoPlayer.selectedCue.StartTimeInSeconds :
                  reportdetails.videoPlayer.selectedIssue.ProxyTimeCodeStart
              }
              timeCodeSettings={reportdetails.details.request_proxy_timecode_settings}
              onIssueClick={onScrollToIssueOnSidebar}
              onErrorLoadVideo={this.onErrorLoadVideo}
            /> : this.buildErrorFragment(message)
        }
      </Fragment>
    );
  }
}

const GenerateProxyBtn = styled.span`
  &:not(.disabled) {
    color: #679dd7;
    text-decoration: underline;
    cursor: pointer;
    :hover {
      color: #a8caef;
    }
  }
`;

InteractiveViewTab.propTypes = {
  loading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  reportdetails: PropTypes.object.isRequired,
  requestId: PropTypes.any.isRequired,
  onScrollToIssueOnSidebar: PropTypes.func.isRequired,
};

export default InteractiveViewTab;
