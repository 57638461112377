import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {
  formatDurationAsMinutes, showRuntimeTextlessField, isStudioPartnerUser, isManagerUser, getAsperaConnect
} from '../../../../../utils';
import {
  CONTENT_TYPES, QC_REQUEST_TYPES, SELECT_FILES_OPTIONS, VIDEO_FORMAT_FIELDS, getVideoFormatOptionName,
  QC_REQUEST_TYPE_NEW_QC_REQUEST, buildVideoFormatName, FINISHING_HOUSE__N_A, VIDEO_FILTER_NAME_VIDEO_CAPTIONS,
  QC_REQUEST_TYPE_REPLACEMENT
} from '../../../constants';
import StyledReviewAndConfirm from './StyledReviewAndConfirm';
import EstimatedCost from '../../forms/EstimatedCost';
import Loader from '../../../../../components/Loader';
import Switch from 'react-switch';
import {Row, Col, Checkbox} from 'react-bootstrap';
import checkImg from '../../../../../../assets/images/check.svg';
import nocheckImg from '../../../../../../assets/images/no-check.svg';
import {getCostDetails} from '../../../actions';
import replacementImg from '../../../../../../assets/images/replacement-review.svg';
import config from '../../../../../../config';
import {UserPermission} from '../../../../RequestDetails/components/forms/UserPermissions';
import _ from 'lodash';
import WorkOrderStepsTable from '../../table/WorkOrderStepsTable';
import {hasChangedWoSteps} from '../../../../RequestDetails/components/editWorkOrderStepsTable/utils';

class ReviewAndConfirm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {loading: false, costDetails: null, asperaWeb: getAsperaConnect(config.initAsperaParams)};
  }

  isUnavailableAspera = () => !this.state.asperaWeb;

  hidePricingFromPartners = () => {
    const {data, filters} = this.props;
    if (isStudioPartnerUser()) {
      const studioId = data.origin_request ? data.origin_request.StudioID : data.studio_id;
      const studio = (filters.studios || []).find(s => s.value === studioId) || {};
      if (data.origin_request && studioId === undefined) {
        studio.hide_pricing_from_partners = data.origin_request.hide_pricing_from_partners;
      }
      if (studio.hide_pricing_from_partners) {
        return true;
      }
    }
    return false;
  };

  componentDidMount() {
    const {data, filters, onChange} = this.props;
    const title = data.origin_request ? (data.origin_request.Title || data.origin_request.SeriesName) :
      ((filters.series.find(s => s.series_id === data.series_id) || {}).series_name || data.series_id);
    onChange('title', title);

    if (this.hidePricingFromPartners()) {
      this.setState({costDetails: {hidePricingFromPartners: true}});
    } else {
      this.setState({loading: true});
      getCostDetails(data).then(
          res => {
            this.setState({loading: false, costDetails: res});
          },
          error => {
            console.log(error);
            this.setState({loading: false});
          }
      );
    }
  }

  getOptionName(options, value) {
    return ((options || []).find(o => o.value === value) || {}).name;
  }

  handleLogoLoad = () => {
    this.refs.distributorLogo.className = this.refs.distributorLogo.className.replace('hidden', '').trim();
  };

  renderSwitch = (value, offColor, checkedLabel, uncheckedLabel) => {
    return (
      <div className="switch-field">
        <Switch
          disabled
          checked={value}
          onChange={() => {}}
          className={`react-switch${value ? ' checked' : ''}`}
          uncheckedIcon={false}
          checkedIcon={false}
          offColor={offColor}
          onColor="#FFFFFF"
          offHandleColor="#A9A9A9"
          onHandleColor="#7FCF18"
          activeBoxShadow="0px 0px 1px 1px transparent"
          handleDiameter={20}
          height={26}
          width={48}
          />
        <span>{value ? checkedLabel : uncheckedLabel || checkedLabel}</span>
      </div>
    );
  };

  renderAudioFormatField = (audioFormat, filters) => {
    return audioFormat.format_id ? (
        <div className="review-confirm-section-value">
          <Row>
            <Col md={2} xs={6}>
              <div className="review-confirm-label">Audio Format</div>
              <div className="review-confirm-value">
                {this.getOptionName(filters.audio_formats, audioFormat.format_id)}
              </div>
            </Col>
          </Row>
        </div>
    ) : null;
  };

  renderAudioConfigField = (audioFormat, filters) => {
    return audioFormat.audio_configuration ? (
        <div className="review-confirm-section-value">
          <Row>
            <Col md={12} xs={12}>
              <div className="review-confirm-label">Audio Configuration</div>
              <div className="review-confirm-value audio-configs">
                {this.renderSwitch(audioFormat.audio_configuration.not_sure, '#fff', 'I am not sure')}
                {audioFormat.audio_configuration.data &&
                  audioFormat.audio_configuration.data.map((item, i) => (
                    <div
                      className={`audio-config-item${audioFormat.audio_configuration.not_sure ? ' disabled' : ''}`}
                      key={`audio-config-item-${i}`}
                      >
                      <Row className="audio-config-item-row">
                        <Col md={1} xs={1} className="item-number">
                          {i + 1}
                        </Col>
                        <Col md={2} xs={2} className="item-name">
                          {this.getOptionName(filters.audio_types, item.type_id)}
                        </Col>
                        <Col md={3} xs={3} className="item-value">
                          {this.getOptionName(filters.audio_sounds, item.sound_id)}
                        </Col>
                        <Col md={3} xs={3} className="item-theatrical">
                          <img src={item.theatrical ? checkImg : nocheckImg} alt=""/><span>Theatrical</span>
                        </Col>
                      </Row>
                    </div>
                  ))
                }
              </div>
            </Col>
          </Row>
        </div>
    ) : null;
  };

  renderTagForSingleVersion = data => {
    return !!data.version_tag && (
      <div className="review-confirm-section-value version-tag">
        <Row>
          <Col md={12} xs={12}>
            <div className="review-confirm-label">Tag</div>
            <div className="review-confirm-value">{data.version_tag}</div>
          </Col>
        </Row>
      </div>
    );
  };

  renderAdditionalDescForSingleVersion = data => {
    return !!data.additional_description && (
      <div className="review-confirm-section-value version-tag">
        <Row>
          <Col md={12} xs={12}>
            <div className="review-confirm-label">Additional Description</div>
            <div className="review-confirm-value">{data.additional_description}</div>
          </Col>
        </Row>
      </div>
    );
  };

  renderMediaFormats = () => {
    const {data, filters} = this.props;
    const {video_format, audio_format, caption_id} = data;
    if (video_format) {
      return (
        <div className="video-formats">
          {video_format.map((videoFormatItem, itemIndex) => {
            const audioFormat = videoFormatItem.audio_format;
            return (
              <div
                className="video-format-item"
                key={`video-format-item-${itemIndex}`}
                >
                <div className="video-format">
                  <div className="item-number">
                    {itemIndex + 1}
                  </div>
                  {
                    VIDEO_FORMAT_FIELDS.map((field, i) => {
                      return videoFormatItem[field.name] !== undefined ?
                        <div
                          key={`video-format-${itemIndex}-field-${i}`}
                          className="video-format-field"
                          >
                          <div className="review-confirm-label">{field.label}</div>
                          <div className="review-confirm-value">
                            {getVideoFormatOptionName(filters, field.filterName, data.qc_profile_id, videoFormatItem[field.name])}
                          </div>
                        </div> : null
                    })
                  }
                </div>
                {
                  audioFormat ?
                    <div className="audio-format">
                      {this.renderAudioFormatField(audioFormat, filters)}
                      {this.renderAudioConfigField(audioFormat, filters)}
                    </div> : null
                }
                {
                  (videoFormatItem.version_tag || videoFormatItem.additional_description) ?
                    <div className="video-format--tag-and-adesc">
                      {this.renderTagForSingleVersion(videoFormatItem)}
                      {this.renderAdditionalDescForSingleVersion(videoFormatItem)}
                    </div> : null
                }
              </div>
            );
          })}
        </div>
      );
    } else if (audio_format) {
      return (
        <div className="review-confirm-section single-audio-format">
          <div className="review-confirm-section-name">Audio</div>
          {this.renderAudioFormatField(audio_format, filters)}
          {this.renderAudioConfigField(audio_format, filters)}
          {this.renderTagForSingleVersion(data)}
          {this.renderAdditionalDescForSingleVersion(data)}
        </div>
      );
    } else if (caption_id) {
      return (
        <div className="review-confirm-section single-audio-format">
          <div className="review-confirm-section-name">Captions</div>
          <div className="review-confirm-section-value">
            <Row>
              <Col md={2} xs={6}>
                <div className="review-confirm-value">
                  {this.getOptionName(filters[VIDEO_FILTER_NAME_VIDEO_CAPTIONS], caption_id)}
                </div>
              </Col>
            </Row>
          </div>
          {this.renderTagForSingleVersion(data)}
          {this.renderAdditionalDescForSingleVersion(data)}
        </div>
      );
    }
    return (
      <>
        <div className="no-media-details">No media details</div>
        {this.renderTagForSingleVersion(data)}
        {this.renderAdditionalDescForSingleVersion(data)}
      </>
    );
  };

  getProductionOfUser = () => {
    const {data, filters} = this.props;
    return filters.production_id || data.cs_production_id || null;
  };

  getFinishingHouseOfUser = () => {
    const {data, filters} = this.props;
    return filters.finishing_house_id || data.cs_finishing_house_id || null;
  };

  renderStudioPartners = (partnerIDs, options, partnerIdOfUser, label) => {
    if (options && Array.isArray(partnerIDs) && partnerIDs.length) {
      const values = partnerIDs;
      return !!values.length && (
        <div className="review-confirm-section studio-partner">
          <div className="review-confirm-section-name">{label}{values.length > 1 ? 's' : ''}</div>
          {values.map(fhId => (
            <div
              key={`${label}--${fhId}`}
              className="review-confirm-section-value"
              style={fhId === FINISHING_HOUSE__N_A ? {opacity: 0.65} : undefined}
            >
              {this.getOptionName(options, fhId)}
            </div>
          ))}
          <br/>
        </div>
      );
    }
    return null;
  };

  renderReviewFormForOrigin = () => {
    const {data, filters} = this.props;
    const distributor = filters.distributors.find(d => d.value === data.distributor_id);
    const {season} = data;
    let totalEpisodesRuntime, totalEpisodesRuntimeTextless;
    if (season && season.episodes) {
      totalEpisodesRuntime = season.episodes.reduce((a, c) => a + c.runtime_in_sec, 0);
      if (showRuntimeTextlessField(data.video_format)) {
        totalEpisodesRuntimeTextless = season.episodes.reduce((a, c) => a + c.runtime_in_sec__textless, 0);
      }
    }
    const finishingHouseOfUser = this.getFinishingHouseOfUser();
    return (
      <div>
        <div className="distributor-current">
          <Row>
            <Col md={4} xs={12}>
              {
                distributor.logo_url ?
                  <img
                    className="distributor-logo hidden"
                    ref="distributorLogo"
                    title="Logo"
                    src={distributor.logo_url}
                    alt=""
                    onLoad={this.handleLogoLoad}
                  /> : null
              }
              <div className="distributor-name">
                {distributor.name}
                <div className="distributor-website">{distributor.website}</div>
              </div>
            </Col>
            <Col md={8} xs={12}>
              <div className="qc-profile-name">
                {this.getOptionName(filters.qc_profiles, data.qc_profile_id)}
                <div>
                  By selecting the above QC profile, you've specified the desired level of QC and associated rates.
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {
          data.studio_id && filters.studios && filters.studios.length ? (
            <div className="review-confirm-section studio">
              <div className="review-confirm-section-name">Studio</div>
              <div className="review-confirm-section-value">
                {this.getOptionName(filters.studios, data.studio_id)}
              </div>
              <br/>
            </div>
          ) : null
        }
        {this.renderStudioPartners(data.production_id, filters.productions, this.getProductionOfUser(), 'Production')}
        {this.renderStudioPartners(data.finishing_house_id, filters.finishing_houses, finishingHouseOfUser, 'Post Partner')}
        <div className="review-confirm-section qc-type">
          <div className="review-confirm-section-name">QC Type</div>
          <div className="review-confirm-section-value">
            {this.getOptionName(filters.qc_types, data.qc_type_id)}
          </div>
          <div className="review-confirm-section-note">
            {/*Sed metus augue, rhoncus vitae accumsan vitae, facilisis sed velit. Vivamus posuere cursus arcu quis consectetur.*/}
          </div>
        </div>
        <div className="review-confirm-section content-type">
          <div className="review-confirm-section-name">Content Type</div>
          <div className="review-confirm-section-value">
            {this.getOptionName(filters.content_types, data.content_type_id)}
          </div>
        </div>
        <div className="review-confirm-section media-formats">
          <div className="review-confirm-section-name">Media Formats</div>
          <div className="review-confirm-section-value">
            {this.renderMediaFormats()}
          </div>
        </div>
        {
          season ? (
            <div className="review-confirm-section season">
              <div className="review-confirm-section-name">Season Details</div>
              <div className="review-confirm-section-value">
                <Row>
                  <Col md={3} xs={12}>
                    <div className="review-confirm-label">Season #</div>
                    <div className="review-confirm-value">{season.season_number}</div>
                  </Col>
                  <Col md={3} xs={12}>
                    <div className="review-confirm-label">Total Episodes</div>
                    <div className="review-confirm-value">{season.total_episodes}</div>
                  </Col>
                  <Col md={3} xs={12}>
                    <div className="review-confirm-label">Total Runtime</div>
                    <div className="review-confirm-value">
                      {(formatDurationAsMinutes(totalEpisodesRuntime) || '-')}
                    </div>
                  </Col>
                  {
                    typeof totalEpisodesRuntimeTextless === 'number' ?
                      <Col md={3} xs={12}>
                        <div className="review-confirm-label">Total Runtime Textless</div>
                        <div className="review-confirm-value">
                          {(formatDurationAsMinutes(totalEpisodesRuntimeTextless) || '-')}
                        </div>
                      </Col> : null
                  }
                </Row>
              </div>
            </div>
          ) : null
        }
      </div>
    );
  };

  renderReviewTurnaround = () => {
    const {data, filters} = this.props;
    const {general} = data;
    const isNewRequest = data.request_type_id === QC_REQUEST_TYPES.NewQcRequest.id;
    const isNewEpisode = data.request_type_id === QC_REQUEST_TYPES.Episode.id;
    const isFeature = data.content_type_id === CONTENT_TYPES.Feature;
    const isTrailer = data.content_type_id === CONTENT_TYPES.Trailer;
    return (
      <div className={`review-confirm-section turnaround${isNewRequest ? '' : ' non-new-rq'}`}>
        <div className="review-confirm-section-name">Turnaround & Other Info</div>
        <div className="review-confirm-section-value">
          <Row>
            <Col md={3} xs={12}>
              <div className="review-confirm-label">Turnaround Time</div>
              <div className="review-confirm-value">
                {this.getOptionName(filters.sla_list, general.sla_id)}
              </div>
            </Col>
            {
              (isFeature || isTrailer || isNewEpisode) ? (
                <Col md={3} xs={12}>
                  <div className="review-confirm-label">Runtime</div>
                  <div className="review-confirm-value">
                    {(formatDurationAsMinutes(general.runtime_in_sec) || '-')}
                  </div>
                </Col>
              ) : null
            }
            {
              ((isFeature || isTrailer) && showRuntimeTextlessField(data.video_format)) ? (
                <Col md={3} xs={12}>
                  <div className="review-confirm-label">Runtime Textless</div>
                  <div className="review-confirm-value">
                    {(formatDurationAsMinutes(general.runtime_in_sec__textless) || '-')}
                  </div>
                </Col>
              ) : null
            }
            {
              isTrailer ?
                <Col md={3} xs={12} title="Trailer episode name">
                  <div className="review-confirm-label">Episode Name</div>
                  <div className="review-confirm-value">
                    {data.trailer_episode_name}
                  </div>
                </Col> :
              isNewEpisode ? (
                <Col md={3} xs={12}>
                  <div className="review-confirm-label">Episode</div>
                  <div className="review-confirm-value">
                    {general.episode_number}. {general.episode_name}
                  </div>
                </Col>
              ) : null
            }
            <Col md={3} xs={12}>
              <div className="review-confirm-label">Interactive Reporting</div>
              <div className="review-confirm-value">
                {this.renderSwitch(general.allow_interactive_report, '#F6F6F6', 'Enabled', 'Disabled')}
              </div>
            </Col>
          </Row>
          <div className="special-instructions">
            <div className="review-confirm-label">Special Instructions</div>
            <div className="review-confirm-value">{general.note}</div>
            <div className="review-confirm-label">File Delivery Notes</div>
            <div className="review-confirm-value">{general.file_delivery_note}</div>
            <div className="review-confirm-label">File Creation Notes</div>
            <div className="review-confirm-value">{general.file_creation_note}</div>
          </div>
        </div>
      </div>
    );
  };

  renderReviewFilesForFormat = (requestTypeId, fileValues, format, key) => {
    const {select_files_type_id, selectedFiles, uploadedFiles} = fileValues;
    const isUploadNow = select_files_type_id === SELECT_FILES_OPTIONS.UploadNow;
    const files = [...(selectedFiles || []), ...(uploadedFiles && isUploadNow ? uploadedFiles : [])];
    return (
      <div className="review-confirm-section files" key={key}>
        <div className="review-confirm-section-name">Files{format ? `: ${format}` : ''}</div>
        <div className="review-confirm-section-value">
          {
            select_files_type_id === SELECT_FILES_OPTIONS.PhysicalMediaDelivery ? 'Physical Media Delivery' :
            select_files_type_id === SELECT_FILES_OPTIONS.UploadLater ? 'To be uploaded later' : (
              <>
                {isUploadNow && this.isUnavailableAspera() &&
                  <StyledAlert>
                    <span className="icon-info-circle"/>
                    <div className="alert--title">The Aspera Connect is currently unavailable.</div>
                    <div>
                      Please go back to the {requestTypeId === QC_REQUEST_TYPE_REPLACEMENT ? 'Summary' : 'Select Files'
                      } step and choose another option for uploading files or contact qodsupport@eurofins-dms.com for assistance.
                    </div>
                  </StyledAlert>
                }
                <table>
                  <tbody>
                    {files.map((file, i) => (
                      <tr className="asset-file" key={`asset-file-${i}`}>
                        <td>{file.asset_name}</td>
                        <td>IBM Aspera {file.isDirectory ? 'Folder' : 'File'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )
          }
        </div>
      </div>
    );
  };

  renderReviewFiles = () => {
    const {data, filters} = this.props;
    const {request_type_id, content_type_id, video_format} = data;
    if (content_type_id === CONTENT_TYPES.Season) {
      return null;
    }
    if (video_format) {
      return video_format.length > 1 ? (
        <Fragment>
          {video_format.map((item, index) => {
            const format = buildVideoFormatName(filters, data.qc_profile_id, item, true);
            return this.renderReviewFilesForFormat(request_type_id, item.files, format, `files-${index}`);
          })}
        </Fragment>
      ) : this.renderReviewFilesForFormat(request_type_id, video_format[0].files);
    }
    return this.renderReviewFilesForFormat(request_type_id, data);
  };

  renderShowCode = () => {
    const {data, filters} = this.props;
    const {show_code, request_type_id} = data;
    const seriesShowCode = (filters && filters.series && (filters.series.find(t => t.series_id === data.series_id) || {}).show_code) || show_code;
    if (seriesShowCode && request_type_id === QC_REQUEST_TYPE_NEW_QC_REQUEST) {
      return (
        <div className="review-confirm-section">
          <div className="review-confirm-section-name">Show Code</div>
          <div className="review-confirm-section-value">{seriesShowCode}</div>
        </div>
      );
    }
    return null;
  };

  renderDistributionList = () => {
    const {distribution_list, request_type_id} = this.props.data;
    if (distribution_list && request_type_id === QC_REQUEST_TYPE_NEW_QC_REQUEST) {
      return (
        <div className="review-confirm-section">
          <div className="review-confirm-section-name">Distribution List</div>
          <div className="review-confirm-section-value">{distribution_list}</div>
        </div>
      );
    }
    return null;
  };

  renderClientContact = () => {
    const {data, filters} = this.props;
    const {client_contact_id, request_type_id} = data;
    if (client_contact_id && request_type_id === QC_REQUEST_TYPE_NEW_QC_REQUEST) {
      const {memfis_contacts} = filters;
      return (
        <div className="review-confirm-section">
          <div className="review-confirm-section-name">Client Contact</div>
          <div className="review-confirm-section-value">{this.getOptionName(memfis_contacts, client_contact_id)}</div>
        </div>
      );
    }
    return null;
  };

  renderMemfisClient = () => {
    const {data: {memfis_client_id, request_type_id}, filters: {memfis_clients}} = this.props;
    if (memfis_client_id && request_type_id === QC_REQUEST_TYPE_NEW_QC_REQUEST) {
      return (
        <div className="review-confirm-section">
          <div className="review-confirm-section-name">MEMFIS Client</div>
          <div className="review-confirm-section-value">{this.getOptionName(memfis_clients, memfis_client_id)}</div>
        </div>
      );
    }
    return null;
  };

  renderPendingApprovalStudioPartnerTypes = () => {
    const {
      data: {request_type_id, pending_approval_studio_partner_type_ids},
      filters: {pending_approval_studio_partner_types}
    } = this.props;
    if (request_type_id === QC_REQUEST_TYPE_NEW_QC_REQUEST && pending_approval_studio_partner_types && pending_approval_studio_partner_type_ids) {
      return (
        <div className="review-confirm-section">
          <div className="review-confirm-section-name">
            Approval required for
          </div>
          <div className="review-confirm-section-value">
            {!pending_approval_studio_partner_type_ids.length ? 'neither' :
              pending_approval_studio_partner_type_ids.map(id => this.getOptionName(pending_approval_studio_partner_types, id)).join(' and ').toLowerCase()
            }
          </div>
        </div>
      );
    }
    return null;
  };

  renderCSUserId = () => {
    const {cs_user_id, request_type_id} = this.props.data;
    if (cs_user_id && request_type_id === QC_REQUEST_TYPE_NEW_QC_REQUEST) {
      return (
        <div className="review-confirm-section">
          <div className="review-confirm-section-name">Client Service User</div>
          <div className="review-confirm-section-value">{this.getOptionName(this.props.filters.cs_users, cs_user_id)}</div>
        </div>
      );
    }
    return null;
  };

  renderOfficeField = () => {
    const {office_flag, request_type_id} = this.props.data;
    if (request_type_id === QC_REQUEST_TYPE_NEW_QC_REQUEST &&
        [config.officeFlag.BURBANK, config.officeFlag.NEW_YORK].includes(office_flag)) {
      const officeName = office_flag === config.officeFlag.BURBANK ? 'Burbank' : 'New York';
      return (
        <div className="review-confirm-section">
          <div className="review-confirm-section-name">Office</div>
          <div className="review-confirm-section-value">{officeName}</div>
        </div>
      );
    }
    return null;
  };

  renderSendFromQOD = () => {
    const {send_from_qod, request_type_id} = this.props.data;
    if (send_from_qod && request_type_id === QC_REQUEST_TYPE_NEW_QC_REQUEST) {
      return (
        <div className="review-confirm-section send_from_qod">
          <Checkbox
            checked={send_from_qod}
            readOnly
          >
            Send from QOD
          </Checkbox>
        </div>
      );
    }
    return null;
  };

  renderSecurity = () => {
    const {data: {user_permissions}, filters} = this.props;
    const {company_for_show_managers} = filters;
    const isNoData = !Object.keys(user_permissions || {}).length;
    const isManager = isManagerUser();
    const buildTBody = (data, type) => !!(data || []).length && (
      <tbody key={`${type}-${data[0].CompanyName}`}>
        <tr>
          <td
            colSpan={2}
            className={isManager ? undefined : 'user-permissions-header'}
          >
            <strong>
              {!isManager ? 'User Permissions' :
                <>{type === 'Finishing House' ? 'Post Partner' : type}<span>{data[0].CompanyName}</span></>
              }
            </strong>
          </td>
        </tr>
        {(data.length === 1 && !data[0].UserID) ? <tr><td colSpan={2} className="cell-no-users">No Users</td></tr> :
          data.map(item =>
            <tr key={`rup_${item.UserID}`}>
              <td>
                {item.FullName}
              </td>
              <td>
                {this.getOptionName(filters.user_permissions, item.PermissionType)}
                {item.CompanyID === company_for_show_managers && item.PermissionType === UserPermission.CAN_MANAGE ? <i> (Show Manager)</i> : ''}
              </td>
            </tr>
          )
        }
      </tbody>
    );
    return (
      <div className="review-confirm-section security">
        <div className="review-confirm-section-name">Security</div>
        <div className="review-confirm-section-value" style={isNoData ? {opacity: 0.65} : undefined}>
          {isNoData ? 'No Users' :
            <table>
              {['Studio'].map(type => buildTBody(user_permissions[type], type))}
              {['Production', 'Finishing House'].map(type => (
                !!(user_permissions[type] || []).length &&
                  Object.values(_.groupBy(user_permissions[type], 'CompanyName')).map(i => buildTBody(i, type))
              ))}
            </table>
          }
        </div>
      </div>
    );
  };

  renderWorkOrder = () => {
    const {data: {replacement: {wo_steps}}} = this.props;
    const nonDeletedSteps = (wo_steps || []).filter(({isDeleted}) => !isDeleted);
    const isOverriddenSteps = nonDeletedSteps.length > 0 && hasChangedWoSteps(wo_steps);
    const isUsedStepsFromTemplate = !isOverriddenSteps && (wo_steps || []).length > 0;
    return (
      <div className="review-confirm-section wo-steps">
        <div className="review-confirm-section-name">Work Order</div>
        <div className="review-confirm-section-value">
          <StyledAlert type={isOverriddenSteps ? 'info' : isUsedStepsFromTemplate ? 'warning' : undefined}>
            <span className="icon-info-circle"/>
            <div className="alert--title">
              {
                isOverriddenSteps ? 'The template will not be used for this request when creating a work order' :
                  isUsedStepsFromTemplate ? 'The template will be used for this request when creating a work order' :
                    'We could not find the steps for this request.'
              }
            </div>
            {
              isOverriddenSteps &&
                <div>
                  The work order will be created with the following steps:
                </div>
            }
          </StyledAlert>
          {isOverriddenSteps &&
            <WorkOrderStepsTable
              rows={nonDeletedSteps}
            />
          }
        </div>
      </div>
    );
  };

  renderReviewForm = () => {
    const {data} = this.props;
    const isNewQcRequest = data.request_type_id === QC_REQUEST_TYPES.NewQcRequest.id;
    const isReplacementRequest = data.request_type_id === QC_REQUEST_TYPES.Replacement.id;
    const seasonName = (data.origin_request || {}).SeasonName;
    const {loading, costDetails} = this.state;
    return loading ? <Loader/> : (
      <Row>
        <Col className="review-confirm-left" md={4} xs={12}>
          <div className="review-confirm-title">{data.title}</div>
          {
            isReplacementRequest ? (
              <div className="replacement-number">
                <img src={replacementImg} alt=""/>
                Replacement {(data.origin_request.ReplacementCount || 0) + 1}
              </div>
            ) : data.request_type_id === QC_REQUEST_TYPES.Episode.id ? (
              <div className="replacement-number">
                {`${seasonName.match(/\bseason\b/i) ? '' : 'Season '}`}<span>{seasonName}</span>
              </div>
            ) : null
          }
          <EstimatedCost data={costDetails} isSeasonRequest={data.content_type_id === CONTENT_TYPES.Season}/>
        </Col>
        <Col className="review-confirm-right" md={8} xs={12}>
          {isNewQcRequest ? this.renderReviewFormForOrigin() : null}
          {isReplacementRequest ? null : this.renderReviewTurnaround()}
          {this.renderReviewFiles()}
          {this.renderShowCode()}
          {this.renderDistributionList()}
          {this.renderClientContact()}
          {this.renderCSUserId()}
          {this.renderOfficeField()}
          {this.renderMemfisClient()}
          {this.renderPendingApprovalStudioPartnerTypes()}
          {this.renderSendFromQOD()}
          {isNewQcRequest && this.renderSecurity()}
          {isReplacementRequest && this.renderWorkOrder()}
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <StyledReviewAndConfirm className="step-body-wrapper review-confirm">
        {this.renderReviewForm()}
      </StyledReviewAndConfirm>
    );
  }
}

const StyledAlert = styled.div`
  margin: 0 0 20px;
  padding: 24px 25px 24px 60px;
  background: #E75592;
  ${props => props.type === 'info' && css`
    background: #28c0f0;
  `}
  ${props => props.type === 'warning' && css`
    background: #fbd445;
  `}
  color: #FFFFFF;
  font-weight: 300;
  font-size: 11px;
  line-height: 12px;
  position: relative;
  border-radius: 5px;

  .icon-info-circle {
    font-weight: normal;
    font-size: 26px;
    color: #fff;
    position: absolute;
    top: 19px;
    left: 20px;
  }

  .alert--title {
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
    padding-bottom: 5px;
  }

  a[href^="mailto"] {
    :not(:hover) {
      color: #02599d;
    }
  }
`;

ReviewAndConfirm.propTypes = {
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ReviewAndConfirm;
