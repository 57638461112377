import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loader from '../../components/Loader';
import Filter from '../../components/filters/Filter';
import AddFilterBtn from '../../components/filters/AddFilterBtn';

class TableFilters extends React.PureComponent {
  state = {
    openedFilters: {}
  };

  getFullFilter(filter) {
    const {allowedFilters} = this.props;
    const {name, disabled} = filter;
    const allowedFilter = allowedFilters && (allowedFilters.find(item => item.name === name) || {});
    return allowedFilter && allowedFilter.name ? {
      name: allowedFilter.name,
      label: allowedFilter.title === 'Finishing House' ? 'Post Partner' : allowedFilter.title,
      values: allowedFilter.values,
      isOrderedValues: allowedFilter.isOrderedValues,
      type: allowedFilter.type,
      multi: allowedFilter.multi,
      disabled: disabled || false,
    } : null;
  }

  getReminedFilters() {
    const {allowedFilters, shownFilters} = this.props;
    const filters = shownFilters && Object.keys(shownFilters).map(key => shownFilters[key].name);
    return allowedFilters && Array.isArray(allowedFilters) && allowedFilters.filter(item => !filters.includes(item.name));
  }

  getFilterOptions(filter) {
    const remainedFilters = this.getReminedFilters();
    remainedFilters.push(filter);
    return remainedFilters && remainedFilters.map(item => {
      return {
        label: item.title === 'Finishing House' ? 'Post Partner' : item.title,
        value: item.name,
        isOrderedValues: item.isOrderedValues,
        type: item.type,
        multi: item.multi,
        disabled: item.name === filter.name
      };
    });
  }

  isShowAddFilterBtn() {
    const remainedFilters = this.getReminedFilters();
    return Array.isArray(remainedFilters) && remainedFilters.length > 0;
  }

  handleAddFilter = () => {
    const {onAddFilter} = this.props;
    const remainedFilters = this.getReminedFilters();

    onAddFilter({
      name: remainedFilters[0].name,
      label: remainedFilters[0].title === 'Finishing House' ? 'Post Partner' : remainedFilters[0].title,
      values: remainedFilters[0].values,
      isOrderedValues: remainedFilters[0].isOrderedValues,
      type: remainedFilters[0].type,
      multi: remainedFilters[0].multi
    });

    this.setState({
      openedFilters: {...this.state.openedFilters, [remainedFilters[0].name]: true}
    });
  };

  handleChangeFilterType = (filterName, newFilterName, newFilterLabel) => {
    const {onChangeFilterType, allowedFilters} = this.props;

    const newSelectedFilter = allowedFilters && (allowedFilters.find(item => item.name === newFilterName) || {});

    onChangeFilterType(filterName, newFilterName, newFilterLabel, newSelectedFilter);
  };

  isOpenedFilter = filterName => {
    const {openedFilters} = this.state;
    return openedFilters[filterName] !== false;
  };

  toggleFilterOpen = filterName => {
    const isOpen = this.isOpenedFilter(filterName);
    this.setState({
      openedFilters: {...this.state.openedFilters, [filterName]: !isOpen}
    });
  };

  render() {
    const {loading, coloredFilterName, shownFilters, onChangeFilters} = this.props;

    return (
      <StyledFilter>
        {loading ? <Loader/> : null}
        {shownFilters.length ? shownFilters.map((filter, i) => {
          const fullFilter = this.getFullFilter(filter);
          return fullFilter ? (
            <Filter
              key={i}
              coloredFilterName={coloredFilterName}
              filter={fullFilter}
              filtersOptions={this.getFilterOptions(filter)}
              value={filter.value}
              onChangeFilters={onChangeFilters}
              onChangeFilterType={this.handleChangeFilterType}
              isOpen={this.isOpenedFilter(filter.name)}
              onToggleFilterOpen={() => this.toggleFilterOpen(filter.name)}
              />
          ) : null;
        }) : null}
        {this.isShowAddFilterBtn() ? <AddFilterBtn onClick={this.handleAddFilter}/> : null}
      </StyledFilter>
    );
  }
}

const StyledFilter = styled.div`
  margin-top: 37px;

  .loader-container {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    z-index: 3;

    .react-spinner {
      top: 49%;
    }
  }

  @media (max-width: 991px) {
    .requests-content & {
      margin-top: 0;
    }
  }
`;

TableFilters.propTypes = {
  coloredFilterName: PropTypes.string,
  allowedFilters: PropTypes.array.isRequired,
  shownFilters: PropTypes.array.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  onChangeFilterType: PropTypes.func.isRequired,
  onAddFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default TableFilters;
