import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {getSecondsFromTimeCode} from '../../../../utils';

class Marker extends React.PureComponent {
  onIssueClick = () => {
    const {issue, onIssueClick} = this.props;
    onIssueClick(issue);
  };

  render() {
    const {issue, duration, frameRate} = this.props;
    const {ProxyTimeCodeStart, TimeCodeStart, IssueID, SeverityColor, Description, IssueType} = issue;
    const seconds = getSecondsFromTimeCode(ProxyTimeCodeStart, frameRate);

    return (
      <StyledMarker
        key={IssueID}
        title={`${TimeCodeStart} - ${IssueType}${Description ? `: ${Description}` : ''}`.toUpperCase()}
        style={{
          backgroundColor: `#${SeverityColor}`,
          left: duration ? `calc(${Math.min(seconds, duration) / duration * 100}% - 2px)` : '-9999px'
        }}
        onClick={this.onIssueClick}
      />
    );
  }
}

const StyledMarker = styled.i`
  background-color: #666666;
  cursor: pointer;
  display: block;
  height: 8px;
  width: 4px;
  position: absolute;
  top: 0;
  left: 50%;
`;

Marker.propTypes = {
  issue: PropTypes.object.isRequired,
  duration: PropTypes.number,
  frameRate: PropTypes.number,
  onIssueClick: PropTypes.func.isRequired
};

export default Marker;