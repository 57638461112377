import React, {useState} from 'react';
import styled from 'styled-components';
import InputNumber from '../../../../components/forms/InputNumber';

function InputNumberCell(props) {
  const {
    className, value, onChange, onFocus, onBlur, isCost, isPercent, minValue, maxValue, placeholder
  } = props;

  const [editMode, setEditMode] = useState(false);

  const noValue = () => {
    const cost = parseFloat(value, 10);
    const isCorrectNumber = typeof cost === 'number' && !isNaN(cost);
    return !isCorrectNumber;
  };

  const formatValue = () => {
    const cost = parseFloat(value, 10);
    const isCorrectNumber = typeof cost === 'number' && !isNaN(cost);
    const temp = isCorrectNumber ? String(Math.abs(cost).toFixed(2)).split('.') : null;
    if (temp) {
      if (isPercent) {
        return <>{cost}%</>;
      }
      return <span className={cost < 0 ? 'negative' : undefined}><strong>{isCost ? '$' : ''}{temp[0]}</strong>.{temp[1]}</span>;
    }
    return value;
  };

  return (
    <StyledCell className={className}>
      {
        editMode ?
          <InputNumber
            type="number"
            defaultValue={value || minValue || ''}
            min={minValue}
            max={maxValue}
            step={0.01}
            precision={2}
            allowEmpty
            onChange={e => {onChange(e.target.value);}}
            onFocus={onFocus}
            onBlur={(event) => {
              if (!event.relatedTarget || event.relatedTarget.parentElement !== event.target.parentElement) {
                setEditMode(false);
                onBlur();
              }
            }}
            autoFocus
            forceReset
          /> :
          <div
            className={`selected-option${noValue() ? ' empty' : ''}`}
            onClick={() => {setEditMode(true);onFocus();}}
          >
            {formatValue() || (placeholder === undefined ? 'Enter...' : placeholder)}
          </div>
      }
    </StyledCell>
  );
}

const StyledCell = styled.td`
  > .selected-option {
    padding-left: 2px;
    min-height: 30px;
    border: 0.5px solid transparent;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &.empty {
      color: #808080;
    }
  }
  &:not(.disabled) {
    > .selected-option {
      :hover {
        background-color: #f2f2f2;
        border-color: #b3b3b3;
      }
    }
  }

  input {
    border: 0.5px solid #DADADA;
    border-radius: 3px;
    outline: 0;
    :hover, :focus {
      border-color: #007eff;
    }
  }

  &.is-percent {
    > div:not(.selected-option) {
      :after {
        content: '%';
        font-weight: 500;
        line-height: 34px;
        position: absolute;
        right: -11px;
      }
    }
  }

  span {
    &.negative {
      position: relative;
      &:before {
        content: '-';
        position: absolute;
        left: -5px;
      }
    }
  }
`;

export default InputNumberCell;