import React from 'react';

export default function DropdownIndicatorDotsSvg(props) {
  return (
    <svg width="12" height="3" viewBox="0 0 12 3" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.2">
        <path d="M6.72912 1.72948C6.72912 2.70212 5.271 2.70212 5.271 1.72948C5.271 0.756839 6.72912 0.756839 6.72912 1.72948Z" fill="black" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.45812 1.72948C2.45812 2.70212 1 2.70212 1 1.72948C1 0.756839 2.45812 0.756839 2.45812 1.72948Z" fill="black" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.0001 1.72948C11.0001 2.70212 9.54199 2.70212 9.54199 1.72948C9.54199 0.756839 11.0001 0.756839 11.0001 1.72948Z" fill="black" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>
  );
}