import Cookies from 'js-cookie';
import $ from 'jquery';
import config from '../../../config';
import {getCurrentDomainObj, getErrorMessage, getErrorStatus, buildRedirectUrlToLoginPage, getCurrentPageToSave,
  getDefaultRedirectUrl
} from '../../utils';
import * as FullStory from '@fullstory/browser';
import smartlookClient from 'smartlook-client';
import qs from 'qs';

export const USER_SET_DATA = 'USER_SET_DATA';
export const USER_SET_WELCOME = 'USER_SET_WELCOME';
export const USER_SHOW_LOGIN_ERROR_MESSAGE = 'USER_SHOW_LOGIN_ERROR_MESSAGE';
export const USER_SHOW_LOGIN_MESSAGE = 'USER_SHOW_LOGIN_MESSAGE';
export const USER_HIDE_LOGIN_MESSAGE = 'USER_HIDE_LOGIN_MESSAGE';
export const USER_SAVE_PREVIOUS_PAGE = 'USER_SAVE_PREVIOUS_PAGE';
export const USER_SET_IS_LOGIN = 'USER_SET_IS_LOGIN';
export const USER_SET_IS_LOGOUT = 'USER_SET_IS_LOGOUT';
export const USER_SET_DATA_FIELD = 'USER_SET_DATA_FIELD';
export const USER_SET_2FA = 'USER_SET_2FA';
export const USER_RESET_COMPANIES_AND_2FA = 'USER_RESET_COMPANIES_AND_2FA';
export const USER_SET_COMPANIES = 'USER_SET_COMPANIES';
export const USER_SHOW_LOADING = 'USER_SHOW_LOADING';
export const USER_HIDE_LOADING = 'USER_HIDE_LOADING';

const removeLocalStorageItems = () => {
  [
    'requestsFilters',
    'artworkReportFilters',
    'artworkReportTopFilters',
    'artworkRqFilters_artwork_request_queue',
    'artworkRqFilters_artwork_my_requests',
  ].forEach(k => localStorage.removeItem(k));
};

const setUserData = (name, avatar, phoneNumber, studio, email, role) => ({
  type: USER_SET_DATA,
  name,
  avatar,
  phoneNumber,
  studio,
  email,
  role
});

export const setUserDataField = (name, value) => ({
  type: USER_SET_DATA_FIELD,
  name,
  value
});

export const setUserWelcomeMessage = showWelcomeMessage => ({
  type: USER_SET_WELCOME,
  showWelcomeMessage
});

const savePreviousPage = previousPage => ({
  type: USER_SAVE_PREVIOUS_PAGE,
  previousPage
});

export const showLoginMessage = message => ({
  type: USER_SHOW_LOGIN_MESSAGE, message
});

export const showLoginErrorMessage = (message, params) => ({
  type: USER_SHOW_LOGIN_ERROR_MESSAGE, message, ...params
});

export const hideLoginMessage = () => ({
  type: USER_HIDE_LOGIN_MESSAGE
});

export const savePreviousPageUrl = () => dispatch => {
  if (window.location.pathname.indexOf('login') === -1) {
    dispatch(savePreviousPage(getCurrentPageToSave()));
  }
};

const isValidUser = roles => {
  return Array.isArray(roles) && roles.includes(config.roles.QC_ON_DEMAND);
};

const applyUserInfo = ({username, avatar, phone_number, studio, roles}, email) => (dispatch, getState) => {
  dispatch(setUserData(username, avatar, phone_number, studio, email, roles));
  dispatch({type: USER_SET_IS_LOGIN});

  if (email) {
    Cookies.set('__email', email, getCurrentDomainObj());
  }

  Cookies.set('__role', roles, getCurrentDomainObj());
  Cookies.set('__username', username, getCurrentDomainObj());
  Cookies.set('__studio', studio, getCurrentDomainObj());
  if (avatar) {
    Cookies.set('__avatar', `${avatar}?rnd=${Math.random()}`, getCurrentDomainObj());
  } else {
    Cookies.remove('__avatar', getCurrentDomainObj());
  }
  if (phone_number) {
    Cookies.set('__phone_number', phone_number, getCurrentDomainObj());
  } else {
    Cookies.remove('__phone_number', getCurrentDomainObj());
  }

  try {
    if (window.Tawk_API && typeof window.Tawk_API.setAttributes === 'function') {
      window.Tawk_API.setAttributes({
        name: username,
        email: email || getState().user.email
      }, function(error){
        !!error && console.log('Tawk_API.setAttributes ERROR: ', error);
      });
    }
  } catch(e) {
    console.log(e);
  }
};

const getInfoAboutLoggedUser = () => dispatch => {
  return $.ajax({
    method: 'GET',
    headers: {
      Authorization: Cookies.get('__auth', getCurrentDomainObj())
    },
    url: `${config.apiUrl}/v1/qc_on_demand/account/me`
  });
};

const resetCompaniesAnd2FA = () => ({type: USER_RESET_COMPANIES_AND_2FA});

export const login = (history, email, password, codeTOTP, companyId) => (dispatch, getState) => {
  const state = getState();
  const {user} = state;
  const {previousPage} = user;
  const counterOfTriesToEnterCodeTOTP = user.counterOfTriesToEnterCodeTOTP || 0;

  dispatch({type: USER_SHOW_LOADING});
  dispatch(hideLoginMessage());
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/auth/login`,
    data: {
      email,
      password,
      user_company_id: companyId || undefined,
      code_totp: codeTOTP || undefined
    },
    refreshRetry: true,
    xhrFields: {
      withCredentials: true
    }
  })
  .done(res => {
    if (res.need_auth_code) {
      dispatch({type: USER_HIDE_LOADING});
      // Two-factor authentication
      dispatch({type: USER_SET_2FA, attempts: 3});
      return;
    }

    if (Array.isArray(res.select_company) && res.select_company.length > 1) {
      dispatch({type: USER_HIDE_LOADING});
      // Ask the user to select the company they want to log in with
      dispatch({type: USER_SET_COMPANIES, companies: res.select_company});
      return;
    }

    // Authorization
    const authorization = `Bearer${res.token}`;
    // Send token in headers for development
    $.ajaxSetup({
      headers: {
        Authorization: authorization
      }
    });
    // Send token in cookies for production
    Cookies.remove('__auth', getCurrentDomainObj());
    Cookies.set('__auth', authorization, getCurrentDomainObj());

    dispatch(getInfoAboutLoggedUser())
    .then(info => {
      dispatch({type: USER_HIDE_LOADING});

      dispatch(resetCompaniesAnd2FA());
      if (isValidUser(info.roles)) {
        dispatch(applyUserInfo(info, email));
        dispatch(setUserWelcomeMessage(true));
        if (config.fullStoryOrgId) {
          FullStory.identify(email, {
            displayName: info.username,
            email
          });
          FullStory.event('User logged in', {
            id: email,
            username: info.username,
            email
          });
        }

        if (config.smartlookId) {
          if (info.roles.includes(config.roles.CLIENT_SERVICE) || info.roles.includes(config.roles.OPERATOR) || info.roles.includes(config.roles.OPS_MANAGER)) {
            smartlookClient.consentAPI('Approved. Smartlook can record my IP and use the API to identify me to provide better support.');
            smartlookClient.consentIP('Approved. Smartlook can record my IP and use the API to identify me to provide better support.');
            smartlookClient.consentForms('Approved. Smartlook can record my IP and use the API to identify me to provide better support.');
          }

          smartlookClient.identify(email, {
            name: info.username,
            email
            // other custom properties
          });
          smartlookClient.track('User logged in', {
            id: email,
            username: info.username,
            email
          });
        }

        // Redirect
        let redirectUrl;
        const urlFilters = qs.parse(window.location.search.substr(1));
        if (urlFilters && urlFilters.r) {
          redirectUrl = decodeURIComponent(urlFilters.r);
        }
        if (!redirectUrl) {
          redirectUrl = previousPage;
        }
        if (!redirectUrl || redirectUrl.length === 1) {
          redirectUrl = getDefaultRedirectUrl();
        }
        history.push(redirectUrl);
      } else {
        Cookies.remove('__auth', getCurrentDomainObj());
        dispatch(showLoginErrorMessage('Invalid user'));
      }
    })
    .fail(error => {
      dispatch({type: USER_HIDE_LOADING});
      dispatch(resetCompaniesAnd2FA());
      dispatch(showLoginErrorMessage(`Could not get user info.  ${getErrorMessage(error)}`));
    });
  })
  .fail(error => {
    dispatch({type: USER_HIDE_LOADING});

    const errorStatus = getErrorStatus(error);
    if (errorStatus === 401) {
      dispatch(resetCompaniesAnd2FA());
      dispatch(showLoginErrorMessage('Incorrect email or password'));
    } else if (codeTOTP && errorStatus === 400 && counterOfTriesToEnterCodeTOTP > 1) {
      const attemptLeft = counterOfTriesToEnterCodeTOTP - 1;
      dispatch(showLoginErrorMessage(`Incorrect authentication code.`));
      dispatch({type: USER_SET_2FA, attempts: attemptLeft});
    } else {
      dispatch(resetCompaniesAnd2FA());
      const errorMsg = error && typeof error === 'object' && error.responseJSON ? error.responseJSON.message : null;
      dispatch(showLoginErrorMessage(errorMsg || getErrorMessage(error)));
    }
  });
};

export const recoverPassword = email => dispatch => {
  dispatch({type: USER_SHOW_LOADING});
  dispatch(hideLoginMessage());
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/auth/recover`,
    refreshRetry: true,
    data: {
      email,
      source: 'QOD'
    }
  })
  .done(() => {
    dispatch({type: USER_HIDE_LOADING});
    dispatch(showLoginMessage(`QcOnDemand sent a password reset email to ${email}`));
  })
  .fail(res => {
    dispatch({type: USER_HIDE_LOADING});
    dispatch(showLoginErrorMessage(res.responseJSON.message));
  });
};

export const resetPassword = (history, password, key, isCreatingPassword) => dispatch => {
  dispatch({type: USER_SHOW_LOADING});
  dispatch(hideLoginMessage());
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/auth/reset`,
    refreshRetry: true,
    data: {
      password,
      key
    }
  })
  .done(() => {
    dispatch({type: USER_HIDE_LOADING});

    history.push('/login');
    dispatch(showLoginMessage(`Password has been successfully ${isCreatingPassword ? 'created' : 'updated'}.`));
  })
  .fail(res => {
    dispatch({type: USER_HIDE_LOADING});

    let message = res.responseJSON.message, showResendBtnToResetPwd = false, showResendBtnToCreatePwd = false;
    if (message && message.includes('link has expired')) {
      if (isCreatingPassword) {
        message = 'Your password reset link has expired.  Please click here to resend it.';
        showResendBtnToCreatePwd = key;
      } else {
        message = 'The password reset link has expired.  Please click here to resend.';
        showResendBtnToResetPwd = true;
      }
    }
    dispatch(showLoginErrorMessage(message, {showResendBtnToResetPwd, showResendBtnToCreatePwd}));
  });
};

const initAjax = history => dispatch => {
  $.ajaxSetup({
    headers: {
      Authorization: Cookies.get('__auth', getCurrentDomainObj())
    },
    statusCode: {
      401: () => {
        dispatch(savePreviousPageUrl());
        history.push(buildRedirectUrlToLoginPage());
      }
    }
  });
};

export const initAuth = (history, isLogin) => dispatch => {
  if (isLogin) {
    dispatch(initAjax(history));
    return;
  }
  dispatch(getInfoAboutLoggedUser())
  .then(info => {
    if (isValidUser(info.roles)) {
      dispatch(applyUserInfo(info));
      dispatch(initAjax(history));
    } else {
      dispatch(savePreviousPageUrl());
      history.push(buildRedirectUrlToLoginPage());
    }
  })
  .fail(() => {
    dispatch(savePreviousPageUrl());
    history.push(buildRedirectUrlToLoginPage());
  });
};

export const logout = history => dispatch => {
  dispatch({type: USER_SHOW_LOADING});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/auth/logout`,
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    xhrFields: {
      withCredentials: true
    }
  })
  .done(res => {
    dispatch({type: USER_HIDE_LOADING});

    $.ajaxSetup({
      headers: {
        Authorization: ''
      }
    });
    Cookies.remove('__auth', getCurrentDomainObj());
    Cookies.remove('__email', getCurrentDomainObj());
    Cookies.remove('__role', getCurrentDomainObj());
    Cookies.remove('__username', getCurrentDomainObj());
    Cookies.remove('__avatar', getCurrentDomainObj());
    Cookies.remove('__phone_number', getCurrentDomainObj());
    Cookies.remove('__studio', getCurrentDomainObj());
    removeLocalStorageItems();

    !!config.fullStoryOrgId && FullStory.anonymize();
    !!config.smartlookId && smartlookClient.disable();

    history.push('/login');
    dispatch({type: USER_SET_IS_LOGOUT});
  })
  .fail(error => {
    dispatch({type: USER_HIDE_LOADING});
    console.log(getErrorMessage('Failed logout'));
  });
};

export const resendOnboardingEmail = () => dispatch => {
  const urlParams = qs.parse(window.location.search.substr(1)) || {};
  dispatch({type: USER_SHOW_LOADING});
  dispatch(hideLoginMessage());
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/auth/qc_on_demand/resend_onboarding_email`,
    refreshRetry: true,
    data: {
      key: urlParams.vk
    }
  })
  .done(email => {
    dispatch({type: USER_HIDE_LOADING});
    dispatch(showLoginMessage(`QcOnDemand resent the onboarding email to ${email}`));
  })
  .fail(error => {
    dispatch({type: USER_HIDE_LOADING});
    const errorMsg = error && typeof error === 'object' && error.responseJSON ? error.responseJSON.message : null;
    dispatch(showLoginErrorMessage(errorMsg || getErrorMessage(error)));
  });
};
