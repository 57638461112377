import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RequestUserPermissions from '../../../RequestDetails/components/forms/UserPermissions';
import Loader from '../../../../components/Loader';
import {getUserPermissions} from '../../actions';
import {isManagerUser} from '../../../../utils';

function Security(props) {
  const {data, filters, onChange} = props;
  const {user_permissions} = data;

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUserPermissions(data, filters).then(
      res => {
        setLoading(false);
        onChange('user_permissions', res.data, true);
      },
      error => {
        console.log(error);
        setLoading(false);
      }
    );
  }, [onChange, data, filters]);

  const buildCompanyPermissions = (userPermissionsArr) => {
    const userPermissionsTemp = {};

    if (!userPermissionsArr) {
      return userPermissionsTemp;
    }

    for (let i = 0; i < userPermissionsArr.length; i++) {
      const companyId = userPermissionsArr[i].CompanyID;
      let companyObj = userPermissionsTemp[companyId];
      if (!companyObj) {
        companyObj = {
          companyId: userPermissionsArr[i].CompanyID,
          companyName: userPermissionsArr[i].CompanyName,
          companyType: userPermissionsArr[i].ClientUserType,
          users: []
        }
        userPermissionsTemp[companyId] = companyObj;
      }

      const userObj = {
        userId: userPermissionsArr[i].UserID,
        userName: userPermissionsArr[i].FullName,
        needToConfirm: userPermissionsArr[i].NeedToConfirm,
        permissionType: userPermissionsArr[i].PermissionType
      }
      companyObj.users.push(userObj);
    }
    return userPermissionsTemp;
  }

  const userPermisionsByCompany = user_permissions && {
    ...buildCompanyPermissions(user_permissions['Studio']),
    ...buildCompanyPermissions(user_permissions['Production']),
    ...buildCompanyPermissions(user_permissions['Finishing House'])
  }

  const handleChange = (userType, userId, permissionType) => {
    if (permissionType) {
      const userPermissions = {...user_permissions};
      try {
        const item = userPermissions[userType].find(item => item.UserID === userId);
        if (item.PermissionType !== permissionType) {
          if (!item.defaultPermissionType) {
            item.defaultPermissionType = item.PermissionType;
          }
          item.PermissionType = permissionType;
        }
      } catch {}
      onChange('user_permissions', userPermissions);
    }
  };

  const handleChangeCompanyLevelPermissions = (userType, companyId, permissionType) => {
    if (permissionType) {
      const userPermissions = {...user_permissions};
      try {
        userPermissions[userType].forEach(item => {
          if (item.CompanyID === companyId) {
            if (!item.defaultPermissionType) {
              item.defaultPermissionType = item.PermissionType;
            }
            item.PermissionType = permissionType;
          }
        });
      } catch {}
      onChange('user_permissions', userPermissions);
    }
  };

  return (
    <div className="step-body-wrapper request-security">
      {isLoading && <Loader className="full-screen"/>}
      {
        !Object.keys(user_permissions || {}).length ? (isLoading ? null : <NoData/>) :
          Object.entries(userPermisionsByCompany).map(([key, value]) =>
            <RequestUserPermissions
              key={'company' + value.companyId}
              userType={value.companyType}
              companyID={value.companyId}
              companyName={value.companyName}
              userList={value.users || []}
              onChange={handleChange}
              dictionaries={filters}
              forRequestWizard
              forManagerUser={isManagerUser()}
              onChangeCompanyLevelPermissions={handleChangeCompanyLevelPermissions}
            />
          )
      }
    </div>
  );
};

const NoData = styled.div`
  padding: 15px;
  font-weight: 700;
  text-align: center;
  :after {
    content: 'No Users';
    opacity: 0.7;
  }
`;

Security.propTypes = {
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Security;
