/* eslint "jsx-a11y/anchor-is-valid":"off" */
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

class Breadcrumbs extends Component {
  state = {
    activeClosedCaptions: false
  };

  constructor(props) {
    super();

    this.throttleClickOnCaptions = _.throttle(this.handleOnCaptionsClick, 1000);
  }

  handleOnCaptionsClick = () => {
    console.log('cc: ' + this.state.activeClosedCaptions);
    this.setState({
      activeClosedCaptions: !this.state.activeClosedCaptions
    });

    if (this.props.captionsClick) {
      this.props.captionsClick();
    }
  };

  renderCaptionsButton = () => {
    const {activeClosedCaptions} = this.state;
    return (
      <button
        className={`closed-captions ${activeClosedCaptions ? 'active' : ''}`}
        onClick={this.throttleClickOnCaptions}
        title={activeClosedCaptions ? 'Hide Captions' : 'Show Captions'}
      >
        CC
      </button>
    );
  }

  render() {
    const {data, captionsClick} = this.props;
    return (
      <StyledBreadcrumbs>
        {data.map((link, index) => {
          return (
            <Fragment key={index}>
              {index > 0 ? <span className="separator">/</span> : null}
              <a
                className={link.active ? 'active' : null}
                onClick={link.handleClick}
                title={link.name}
                >
                {link.name}
              </a>
            </Fragment>
          );
        })}
        { captionsClick && this.renderCaptionsButton() }
      </StyledBreadcrumbs>
    );
  }
}

const StyledBreadcrumbs = styled.div`
  align-items: center;
  background-color: #424242;
  color: #898989;
  display: flex;
  flex-wrap: nowrap;
  font-size: 10px;
  height: 43px;
  line-height: 43px;
  padding:  0 23px;

  a {
    color: #898989;
    font-size: 10px;
    line-height: initial;
    max-width: 100%;
    min-width: 50px;
    overflow: hidden;
    padding: 4px 0;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }

    &.active {
      background-color: #000000;
      border-radius: 10px;
      color: #ffffff;
      display: inline-block;
      font-size: 12px;
      line-height: initial;
      padding: 4px 8px;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .separator {
    margin: 0 5px;
  }

  .closed-captions {
    background: none;
    border: none;
    margin-left: auto;
    font-size: 13px;
    color: #b4b4b4;
    box-shadow: none;
    outline: none !important;
    padding: 0;

    &.active {
      padding: 0 7px;
      background: #353535;
      color: #fff;
    }
  }
`;

Breadcrumbs.propTypes = {
  data: PropTypes.array.isRequired
};

export default Breadcrumbs;
