import React, {useState} from 'react';
import styled from 'styled-components';
import SelectControl from './SelectControl';

function SelectableCell(props) {
  const {
    className, value, options, onChange, onFocus, onBlur, placeholder, isProductCode, allProductCodes, minWidth,
    assigneeName, isAssigneeControl, isClearable, menuMaxHeight
  } = props;

  const [editMode, setEditMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  let selectedOption = options.find(o => o.value === value);
  let preparedOptions = (isProductCode && searchTerm) ?
    allProductCodes.filter(i => i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) : options;
  let onInputChange;
  if (isProductCode) {
    if (value && !selectedOption) {
      selectedOption = {value, name: value};
      if (!searchTerm) {
        preparedOptions = [selectedOption, ...preparedOptions];
      }
    }
    onInputChange = (text, action) => {
      if (action.action === 'input-change') {
        setSearchTerm(text || '');
      } else if (action.action === 'menu-close') {
        setSearchTerm('');
      }
    };
  } else if (assigneeName && !selectedOption) {
    selectedOption = {value, name: assigneeName};
    if (!searchTerm) {
      preparedOptions = [selectedOption, ...preparedOptions];
    }
  }

  if (isAssigneeControl) {
    onInputChange = (text, action) => {
      if (action.action === 'input-change') {
        setSearchTerm(text || '');
      } else if (action.action === 'menu-close') {
        setSearchTerm('');
      }
    };
  }

  return (
    <StyledCell className={className}>
      {
        editMode ?
          <SelectControl
            selectedOption={selectedOption}
            value={value}
            options={preparedOptions}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={() => {setEditMode(false);onBlur();}}
            placeholder={placeholder}
            menuIsOpen
            onInputChange={onInputChange}
            minWidth={minWidth}
            menuMaxHeight={menuMaxHeight}
            isClearable={isClearable}
            menuPortalTarget={props.menuPortalTarget}
            menuPlacement={props.menuPlacement}
          /> :
          <div
            className={`selected-option${selectedOption ? '' : ' empty'}`}
            onClick={() => {setEditMode(true);onFocus();}}
          >
            {selectedOption ? (selectedOption.shortName || selectedOption.name) : placeholder === undefined ? 'Select...' : placeholder}
          </div>
      }
    </StyledCell>
  );
}

const StyledCell = styled.td`
  > .selected-option {
    padding-left: 2px;
    min-height: 30px;
    border: 0.5px solid transparent;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &.empty {
      color: #808080;
    }
  }
  &:not(.disabled) {
    > .selected-option {
      :hover {
        background-color: #f2f2f2;
        border-color: #b3b3b3;
      }
    }
  }
`;

export default SelectableCell;