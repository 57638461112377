import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Table from '../../../../components/table/Table';
import CostCell from '../../../RequestDetails/components/tablecells/CostCell';
import {IsBillableCell} from '../../../RequestDetails/components/tablecells/IsBillableCell';

const productCodeCell = ({value, row, className}) => (
  <td className={className} title="Product Code">
    {row.hasOwnProperty('Cost') && row.Cost == null ? <span style={{fontWeight: "bold", color: "#E75592"}}>{value}</span> : value}
  </td>
);

function WorkOrderStepsTable(props) {
  const {rows, isLoading} = props;

  const headers = {
    StepOrder: {
      title: 'Step',
      className: 'step-order'
    },
    Label: {
      title: 'Label',
      className: 'step-label'
    },
    Details: {
      title: 'Description',
      className: 'step-description'
    },
    ProductCode: {
      title: 'Product Code',
      className: 'product-code',
      component: productCodeCell
    },
    Unit: {
      title: 'Unit',
      className: 'step-unit'
    },
    OverrideRate: {
      title: 'Override Rate',
      className: 'override-rate',
      component: CostCell,
      componentArgs: {
        onChange: () => {}
      }
    },
    Assignee: {
      title: 'Assignee',
      className: 'assignee'
    },
    IsBillable: {
      title: 'Is Billable',
      className: 'is-billable',
      component: IsBillableCell
    },
    Quantity: {
      title: 'Quantity',
      className: 'quantity',
      component: CostCell,
      componentArgs: {
        onChange: () => {},
        isDecimal: true
      }
    },
  };

  return (
    <StyledTableWrapper className="sub-steps">
      <div className="table-block">
        <Table
          headers={headers}
          rows={rows}
          stripped={false}
          getRowClassNames={row => 'disabled'}
          emptyText={isLoading ? 'Loading...' : 'No steps'}
        />
      </div>
    </StyledTableWrapper>
  );
}

const StyledTableWrapper = styled.div`
  &.sub-steps {
    background: #f9f9f9;
    margin-bottom: 15px;
    border-radius: 3px;
    padding: 10px 0 14px;

    .table-block {
      max-width: 100%;
      overflow-x: auto;
      padding-bottom: 10px;

      .table {
        border-collapse: separate;
        border-spacing: 0;
        margin: 0;

        > thead {
          > tr > th {
            vertical-align: bottom;
            border: none;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
            font-weight: bold;
            font-size: 12px;
            line-height: 13px;
            text-align: left;
            white-space: nowrap;
            color: rgba(0, 0, 0, 0.7);
            padding: 0 10px 5px 0;
            &:first-child {
              padding-left: 10px;
            }
            &:last-child {
              padding-right: 16px;
            }
          }
        }

        > tbody > tr {
          > td {
            vertical-align: middle;
            border: none;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
            font-weight: normal;
            font-size: 11px;
            line-height: 12px;
            text-align: left;
            color: #000000;
            padding: 12px 10px 12px 0;
            background: rgba(255, 255, 255, 0.5);
            &:first-child {
              padding-left: 10px;
            }
            &:last-child {
              padding-right: 16px;
            }
            &.data-table-empty {
              padding: 15px;
              text-align: center;
              color: rgba(0, 0, 0, 0.7);
            }
            span, div {
              pointer-events: none;
            }
          }

          &.disabled {
            pointer-events: none;
          }
        }
      }
    }
  }
`;

WorkOrderStepsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  headers: PropTypes.object,
  isLoading: PropTypes.bool
};

export default WorkOrderStepsTable;
