import React from 'react';
import SelectAdvanced from '../../../../components/forms/SelectAdvanced';
import RadioButtonsAdvanced from '../../../../components/forms/RadioButtonsAdvanced';

const SelectOrCheckbox = props => {
  if (props.options.length > 2) {
    return (
      <SelectAdvanced
        {...props}
        onSelect={props.onChange}
      />
    );
  }

  return (
    <RadioButtonsAdvanced
      {...props}
    />
  )
};

export default SelectOrCheckbox;
