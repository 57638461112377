import styled from 'styled-components';
import IssueTableStyled from './IssueTableStyled';

const StyledMobileTable = styled(IssueTableStyled)`
  @media (max-width: 768px) {
    .table {
      .mobile-modal-with-issue-table & {
        margin-bottom: 0;
      }
      .mobile-modal-with-issue-table.fix-list-modal & {
        margin-bottom: 100px;
      }

      > thead {
        display: none;
      }
      > tbody {
        > tr {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          &.tr-collapsed > td {
            position: relative;

            &:before {
              .mobile-modal-with-issue-table & {
                padding-bottom: 0;
              }
            }

            > div {
              border-radius: 3px;
              position: absolute;
              top: 7px;
              right: 30px;
              width: 130px;
            }
          }

          &.tr-separate {
            padding: 0 20px;

            .mobile-modal-with-issue-table & {
              padding: 6px 0 0 24px;
            }

            > th {
              border-top: 1px solid #ddd;
              margin-bottom: 15px;
              width: 100%;

              .mobile-modal-with-issue-table & {
                margin-bottom: 23px;
              }
            }
          }

          > td {
            border-bottom: none;
            border-left: ${props => props.issueBorderWidth || '4px'} solid transparent;
            margin-bottom: 15px;
            padding: 0 calc(20px - ${props => props.issueBorderWidth || '4px'});
            text-align: left !important;

            .mobile-modal-with-issue-table & {
              padding: 8px 19px 0;
            }

            &.data-table-empty {
              text-align: center !important;
              padding: 0 !important;
            }
            > span {
              float: left;
              overflow-wrap: break-word;
            }
            &:before {
              content: attr(title);
              color: #000000;
              float: right;
              font-weight: bold;
              text-transform: none;
              width: 100%;

              .mobile-modal-with-issue-table & {
                font-size: 15px;
                line-height: 16px;
                padding-bottom: 4px;
              }
            }
            // First line
            &:nth-child(1) {
              order: 1;
              width: 75%;

              .mobile-modal-with-issue-table & {
                font-size: 15px;
                line-height: 16px;
                color: rgba(0, 0, 0, 0.7);
                padding-top: 0;
                padding-right: 2px;
                width: 68%;
              }
            }
            &:nth-child(10) {
              order: 2;
              padding: 0;
              width: 15%;

              .mobile-modal-with-issue-table & {
                width: 20%;
                img {
                  width: 17px;
                }
                i.fa {
                  font-size: 18px;
                }
              }
            }
            &:nth-child(11) {
              order: 3;
              padding: 0;
              width: 10%;

              > div {
                border-radius: 3px;
                position: absolute;
                top: 7px;
                right: 30px;
                width: 130px;
              }

              .mobile-modal-with-issue-table & {
                span {
                  background-size: 13px;
                  width: 13px;
                }
              }
            }
            // Second line
            &:nth-child(2) {
              order: 4;
              width: 30%;

              .mobile-modal-with-issue-table & {
                font-size: 13px;
                line-height: 14px;
              }
            }
            &:nth-child(4) {
              order: 5;
              width: 30%;

              .mobile-modal-with-issue-table & {
                display: none;
              }
            }
            &:nth-child(5) {
              order: 6;
              width: 40%;

              .mobile-modal-with-issue-table & {
                font-size: 13px;
                line-height: 14px;
                width: 40%;
              }
            }
            // Third line
            &:nth-child(6) {
              order: 7;
              width: 100%;

              .mobile-modal-with-issue-table & {
                font-size: 13px;
                line-height: 17px;
                color: rgba(0, 0, 0, 0.7);
              }
            }
            // Fourth line
            &:nth-child(3) {
              order: 8;
              width: 30%;

              .mobile-modal-with-issue-table & {
                font-size: 13px;
                line-height: 14px;
                order: 5;
              }
            }
            &:nth-child(7) {
              order: 9;
              width: 30%;

              .mobile-modal-with-issue-table & {
                font-size: 13px;
                line-height: 14px;
              }
            }
            &:nth-child(8) {
              order: 10;
              width: 40%;

              .mobile-modal-with-issue-table & {
                font-size: 13px;
                line-height: 14px;
                width: 70%;
              }
            }
            // Last line
            &:nth-child(9) {
              order: 11;
              width: 100%;

              .mobile-modal-with-issue-table & {
                font-size: 13px;
                line-height: 14px;
                width: 70%;
              }
            }
          }
        }
      }
    }
  }
`;

export default StyledMobileTable;
