import $ from 'jquery';
import config from '../../../../../config';
import {getErrorMessage} from '../../../../utils';
import {showError, showSuccess} from '../../../../layouts/actions';

export const ARTWORK_ADMIN_PARAMS_IS_LOADING = 'ARTWORK_ADMIN_PARAMS_IS_LOADING';
export const ARTWORK_ADMIN_PARAMS_IS_SAVING = 'ARTWORK_ADMIN_PARAMS_IS_SAVING';
export const ARTWORK_ADMIN_PARAMS_SET_ACTIVE_TAB = 'ARTWORK_ADMIN_PARAMS_SET_ACTIVE_TAB';
export const ARTWORK_ADMIN_PARAMS_SET_PARAM_MODAL_DATA = 'ARTWORK_ADMIN_PARAMS_SET_PARAM_MODAL_DATA';
export const ARTWORK_ADMIN_PARAMS_RECEIVE = 'ARTWORK_ADMIN_PARAMS_RECEIVE';
export const ARTWORK_ADMIN_PARAMS_DELETE = 'ARTWORK_ADMIN_PARAMS_DELETE';

export const setActiveTab = activeTab => ({type: ARTWORK_ADMIN_PARAMS_SET_ACTIVE_TAB, activeTab});

const setParamModalData = data => ({type: ARTWORK_ADMIN_PARAMS_SET_PARAM_MODAL_DATA, data});

export const openParamModal = param => dispatch => {
  dispatch(setParamModalData(param));
};

export const closeParamModal = () => dispatch => {
  dispatch(setParamModalData(null));
};

export const getParameters = () => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_PARAMS_IS_LOADING,
    loading: true
  });
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/asset_parameters`
  })
  .done(res => {
    const {studios, parameters} = res;
    dispatch({
      type: ARTWORK_ADMIN_PARAMS_RECEIVE,
      studios,
      parameters
    });
  })
  .fail(error => {
    dispatch(showError(`Could not get asset parameters.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_PARAMS_IS_LOADING,
      loading: false
    });
  });
};

export const deleteParameter = param => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_PARAMS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/asset_parameters/${param.ParameterID}`
  })
  .done(() => {
    dispatch(showSuccess('Parameter has been successfully deleted'));
    dispatch({type: ARTWORK_ADMIN_PARAMS_DELETE, param});
  })
  .fail(error => {
    const message = getErrorMessage(error);
    if (message && message.toLowerCase().indexOf(' is used') !== -1) {
      dispatch(getParameters());
    }
    dispatch(showError(`Could not delete parameter.  ${message}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_PARAMS_IS_SAVING,
      saving: false
    });
  });
};

export const saveParameter = param => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_PARAMS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/asset_parameters`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(param)
  })
  .done(() => {
    dispatch(showSuccess(`Parameter has been successfully ${param.ParameterID ? 'updated' : 'created'}`));
    dispatch(getParameters());
  })
  .fail(error => {
    dispatch(showError(`Could not save parameter.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_PARAMS_IS_SAVING,
      saving: false
    });
  });
};
