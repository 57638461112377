import React from 'react';
import PropTypes from 'prop-types';
import {OverlayTrigger, Popover} from 'react-bootstrap';

export const InfoCell = ({row}) => (
  <td className="asset-info-cell">
    <OverlayTrigger
      trigger={['hover']}
      placement="left"
      overlay={<Popover id={`popover-${row.id}`} title="Info">...</Popover>}
      >
      <span className="overlay-info">
        <i className="icon-info-circle-white"/>
      </span>
    </OverlayTrigger>
  </td>
);
InfoCell.propTypes = {
  row: PropTypes.any
};
