import {USER_SET_IS_LOGOUT} from '../../pages/Login/actions';
import {
  SEARCH_CLEAR, SEARCH_CHANGE_INPUT, SEARCH_TERM_IS_LOADING, SEARCH_TERM_IS_DONE, SEARCH_APPLY_INPUT,
  SEARCH_IS_LOADING, SEARCH_IS_DONE, SEARCH_REQUESTS_IS_LOADING, SEARCH_REQUESTS_IS_DONE
} from './actions';

const initialState = {
  inputSearchString: '',
  appliedSearchString: '',
  matchingTerm: '',
  termLoading: false,
  loading: false,
  results: null,
  loadingRequestsByParam: null
};


export default function (state = initialState, action) {
  switch (action.type) {

    case USER_SET_IS_LOGOUT:
    case SEARCH_CLEAR: {
      return {...initialState};
    }

    case SEARCH_CHANGE_INPUT: {
      return {
        ...state,
        inputSearchString: action.value || initialState.inputSearchString,
        matchingTerm: initialState.matchingTerm
      };
    }

    case SEARCH_TERM_IS_LOADING: {
      return {
        ...state,
        termLoading: action.loading,
        matchingTerm: initialState.matchingTerm
      };
    }

    case SEARCH_TERM_IS_DONE: {
      return {
        ...state,
        termLoading: false,
        matchingTerm: !state.inputSearchString ? initialState.matchingTerm : action.matchingTerm || initialState.matchingTerm
      };
    }

    case SEARCH_IS_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case SEARCH_APPLY_INPUT: {
      const results = {...state.results};
      return {
        ...state,
        appliedSearchString: action.value,
        inputSearchString: action.value,
        results: action.value ? results : initialState.results,
        matchingTerm: initialState.matchingTerm
      };
    }

    case SEARCH_IS_DONE: {
      return {
        ...state,
        appliedSearchString: (state.appliedSearchString || '').trim(),
        inputSearchString: (state.inputSearchString || '').trim(),
        loading: false,
        results: action.results
      };
    }

    case SEARCH_REQUESTS_IS_LOADING: {
      return {
        ...state,
        loadingRequestsByParam: action.loadingByParam
      };
    }

    case SEARCH_REQUESTS_IS_DONE: {
      const params = {...state.loadingRequestsByParam};
      const results = {...state.results};
      const scopeData = results[params.scope].data;
      let item;
      if (params.name.endsWith('_id')) {
        item = scopeData.find(i => i.id === params.value);
      } else if (params.name === 'issue_description') {
        item = scopeData.find(i => i.id === params.issue_type_id && i.source && i.source.toLowerCase() === 'description');
      }
      if (item) {
        item.requests = action.requests;
      }
      return {
        ...state,
        loadingRequestsByParam: initialState.loadingRequestsByParam,
        results
      };
    }

    default: {
      return state;
    }
  }
}
