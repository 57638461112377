import styled from 'styled-components';

const StyledReviewAndConfirm = styled.div`
  .new-request .new-request-wrapper &, .submit-replacement-modal & {
    &.review-confirm {
      margin-top: 37px;
      margin-right: 54px;

      .switch-field .react-switch {
        opacity: 1 !important;
      }
  
      .review-confirm-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 19px;
      }
  
      .replacement-number {
        display: inline-block;
        border-radius: 3px;
        background: #ededed;
        font-weight: 300;
        font-size: 12px;
        line-height: 28px;
        padding: 0 12px 0 8px;
        margin-top: 8px;
  
        img {
          height: 15px;
          margin-right: 6px;
        }

        span {
          font-weight: 500;
        }
      }
  
      .distributor-current {
        background: #F9F9F9;
        border-radius: 3px;
        padding: 14px 29px;
        margin-bottom: 28px;
  
        img.distributor-logo {
          height: 32px;
          max-width: 99px;
        }
  
        .distributor-name {
          font-weight: 700;
          font-size: 15px;
          line-height: 16px;
          padding-top: 8px;
  
          .distributor-website {
            font-weight: 300;
            font-size: 12px;
            line-height: 13px;
            color: #878793;
            margin-top: 3px;
          }
        }
  
        .qc-profile-name {
          font-weight: 500;
          font-size: 13px;
          line-height: 14px;
          padding-top: 8px;
          div {
            font-weight: 300;
            font-size: 13px;
            line-height: 17px;
            padding: 7px 0 8px;
            opacity: 0.7;
          }
        }
      }
  
      .review-confirm-section {
        padding-bottom: 9px;
        margin-bottom: 24px;
  
        .review-confirm-section-name {
          font-weight: 700;
          font-size: 15px;
          line-height: 16px;
          padding-bottom: 5px;
        }
  
        .review-confirm-section-value {
          font-weight: 500;
          font-size: 13px;
          line-height: 14px;
          padding-bottom: 1px;
        }
  
        .review-confirm-section-note {
          font-weight: 300;
          font-size: 13px;
          line-height: 17px;
          opacity: 0.7;
        }
  
        .review-confirm-label {
          font-weight: 700;
          font-size: 13px;
          line-height: 14px;
          margin-top: 15px;
          margin-bottom: 10px;
        }
  
        .review-confirm-value {
          font-weight: 400;
          font-size: 13px;
          line-height: 14px;
        }
  
        &.qc-type {
          margin-bottom: 51px;
          border-bottom: 4px solid #f3f3f3;
  
          .review-confirm-section-name {
            padding-bottom: 20px;
          }
  
          .review-confirm-section-value {
            font-weight: 700;
          }
        }
  
        &.content-type {
          margin-bottom: 14px;
        }

        &.media-formats {
          margin-bottom: 0;
          > .review-confirm-section-name {
            padding-bottom: 14px;
          }
          .video-formats {
            .video-format-item {
              background: #F9F9F9;
              border-bottom-right-radius: 3px;
              border-bottom-left-radius: 3px;
              &:not(:first-of-type) {
                margin-top: 14px;
              }
              .video-format {
                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.1);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
                border-radius: 3px;
                padding: 4px 18px 25px;
                @media (min-width: 768px) {
                  display: flex;
                  align-items: flex-start;
                }
                > div {
                  @media (min-width: 768px) {
                    &.video-format-field + .video-format-field {
                      padding-left: 8%;
                    }
                  }
                  &.item-number {
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 19px;
                    opacity: 0.3;
                    margin-top: 11px;
                    margin-right: 28px;
                  }
                  .review-confirm-label {
                    margin-bottom: 6px;
                  }
                }
              }
              .audio-format {
                padding: 2px 33px 7px 58px;
                background: transparent;
                .audio-format-id {
                  margin-bottom: 20px;
                  @media (min-width: 768px) {
                    width: calc(25% - 11px);
                  }
                }
                + .video-format--tag-and-adesc {
                  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.05);
                }
              }
              .video-format--tag-and-adesc {
                padding: 2px 33px 20px 58px;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 3px;
              }
            }
          }
          .no-media-details {
            opacity: 0.8;
            + .version-tag {
              margin-top: 5px;
            }
          }
        }

        &.single-audio-format {
          background: #f9f9f9;
          padding: 26px 20px 18px;
        }

        &.single-audio-format, .audio-format {

          .switch-field {
            margin-bottom: 5px;
            .react-switch-bg {
              border: 1px solid #EAEAEA;
              box-shadow: 0px 0px 10px rgba(0,0,0,0.05);
            }
          }

          .audio-config-item {
            background: #FFFFFF;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            margin-bottom: 9px;
            margin-right: 5px;
            padding: 10px 13px 11px;

            &.disabled {
              opacity: 0.7;
            }
  
            .item-number {
              font-weight: 700;
              font-size: 24px;
              line-height: 25px;
              opacity: 0.1;
            }
            .item-name {
              font-weight: 700;
              font-size: 13px;
              line-height: 14px;
              padding-top: 6px;
            }
            .item-value {
              font-weight: normal;
              font-size: 13px;
              line-height: 14px;
              padding-top: 6px;
            }
            .item-theatrical {
              font-weight: normal;
              font-size: 13px;
              line-height: 14px;
              padding-top: 6px;
              position: relative;
              img {
                position: absolute;
              }
              span {
                padding-left: 22px;
              }
            }
          }
        }
  
        &.season {
          border-bottom: 4px solid #f3f3f3;
          padding-top: 26px;
          padding-bottom: 20px;
  
          .review-confirm-section-name {
            padding-bottom: 14px;
          }
          .review-confirm-label {
            margin-bottom: 4px;
          }
        }
  
        &.turnaround {
          padding-top: 37px;
  
          &.non-new-rq {
            padding-top: 0;
          }
  
          .review-confirm-section-name {
            padding-bottom: 14px;
          }
          .review-confirm-label {
            margin-bottom: 4px;
          }
  
          .special-instructions {
            background: #f9f9f9;
            border-radius: 4px;
            padding: 14px 21px 19px;
            margin-top: 20px;
  
            .review-confirm-label {
              margin-top: 0;
            }
  
            .review-confirm-value {
              font-weight: 300;
              font-size: 12px;
              line-height: 15px;
              margin-bottom: 15px;
              opacity: 0.7;
              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
        }
  
        &.files {
          padding-top: 14px;
          .review-confirm-section-value {
            background: #f9f9f9;
            border-radius: 5px;
            padding: 13px;
            margin-top: 15px;
            tr.asset-file {
              font-size: 13px;
              line-height: 14px;
              word-break: break-all;
              &>td:last-child {
                font-weight: 700;
                padding-left: 33px;
                min-width: 110px;
              }
            }
          }
        }
        &.security {
          padding-top: 14px;
          .review-confirm-section-value {
            background: #f9f9f9;
            border-radius: 5px;
            padding: 13px;
            margin-top: 15px;
            table {
              tbody {
                + tbody:before {
                  content: "";
                  display: block;
                  height: 1em;
                  width: 100%;
                  background: transparent;
                }
                tr {
                  td {
                    padding-right: 15px;
                    padding-bottom: 3px;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 14px;
                    vertical-align: top;
                    text-align: left;
                    :last-child {
                      padding-right: 0;
                    }
                    span {
                      padding-left: 10px;
                      opacity: 0.7;
                    }
                    &.cell-no-users {
                      font-size: 12px;
                      line-height: 13px;
                      opacity: 0.65;
                    }
                  }
                  :first-child {
                    td {
                      padding-bottom: 5px;
                      &.user-permissions-header {
                        padding-bottom: 7px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &.wo-steps {
          padding-top: 14px;
          .review-confirm-section-value {
            background: #f9f9f9;
            border-radius: 5px;
            padding: 0;
            margin-top: 15px;
          }
        }

        &.send_from_qod {
          background: #f9f9f9;
          border-radius: 5px;
          padding: 13px;
          .checkbox {
            pointer-events: none;
            label {
              font-weight: 700;
              line-height: 21px;
            }
          }
        }
      }
  
      .review-confirm-right>.review-confirm-section:last-child {
        margin-bottom: 0 !important;
      }
      .review-confirm-right {
        margin-bottom: 20px;
        .file-upload-status {
          border-radius: 5px;
          margin-top: 13px;
        }
      }
    }
    
    @media (min-width: 992px) {
      &.review-confirm {
        .review-confirm-right.col-md-8 {
          border-left: 1px solid #e5e5e5;
          padding-left: 56px;
          min-height: 355px;
        }
        .distributor-current {
          .col-md-4 {
            min-width: 130px;
            width: auto;
            padding-right: 54px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .submit-replacement-modal & {
      &.review-confirm {
        padding: 18px 0 0 23px;
        margin: 0;
        .row {
          margin: 0;
          .col-xs-12 {
            padding: 0;
          }
        }
      }
    }
  }
`;

export default StyledReviewAndConfirm;