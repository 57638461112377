import React from 'react';
import styled from 'styled-components';
import Table from '../../../../components/table/Table';
import {TitleCell} from '../table/cells/TitleCell';
import {CommentsShowMore} from './ShowMore';

export const CommentsSection = props => {
  const {data, total, onCloseSearch, showMoreComments, onToggleShowMore} = props;

  const headers = {
    fBegin: {},
    preparedComment: {
      title: 'Comment',
      className: 'comment-text'
    },
    CommentedBy: {
      title: 'Author',
      className: 'commented-by'
    },
    Title: {
      title: 'Request Title',
      className: 'request-title',
      component: TitleCell,
      componentArgs: {
        onCloseSearch
      }
    },
    fEnd: {}
  };

  return (
      <TableStyled className={`${total > 3 ? ' more-rows' : ''}`}>
        <div className="table-block">
          <Table
            headers={headers}
            rows={data.map((item, index) => ({...item, index}))}
            getRowClassNames={row => row.index >= 3 ? 'more-comments collapse' : undefined}
            emptyText="No Comments"
            stripped={false}
          />
        </div>
        <CommentsShowMore
          total={total}
          showMore={showMoreComments}
          onToggle={onToggleShowMore}
        />
      </TableStyled>
  );
};

const TableStyled = styled.div`
  @media (min-width: 768px) {
    margin-top: -4px;
  }

  & > .table-info, & > .loader-container + .table-info {
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    margin-top: 4px;
    color: #000000;
    &.no-total-data {
      color: transparent;
      a.view-all {
        display: none;
      }
    }
  }
  & > .table-info {
    margin-bottom: 16px;

    @media (min-width: 768px) {
      a.view-all {
        display: none;
      }
    }

    @media (max-width: 767px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      a.view-all {
        margin: 0 25px 0 0;
      }
    }
  }
  & > .table-block, & > .loader-container + .table-block,
  & > .table-info + .table-block, & > .loader-container + .table-info + .table-block {
    max-width: 100%;
    overflow-x: auto;
    > .table {
      border-collapse: separate;
      color: #000000;
      margin: 0;

      > thead {

        @media (max-width: 767px) {
          display: none;
        }

        > tr {
          > th {
            pointer-events: none;
            font-weight: bold;
            font-size: 12px;
            line-height: 13px;
            text-align: left;
            vertical-align: bottom;
            border: 0;
            border-bottom: 1px solid #e0e0e0;
            padding: 0 10px 15px 1px;
            background: #f9f9f9;
            &:first-child {
              padding-left: 0;
              padding-right: 0;
              width: 31px;
            }
            &:last-child {
              padding-left: 0;
              padding-right: 0;
              width: 37px;
            }
            @media (min-width: 768px) {
              &.request-title {
                width: 40%;
              }
              &.commented-by {
                width: 20%;
              }
            }
          }
        }
      }

      > tbody {
        > tr {
          cursor: default;
          > td {
            vertical-align: middle;
            padding: 14px 10px 14px 1px;
            background: #fff;
            font-weight: normal;
            font-size: 12px;
            line-height: 13px;
            text-align: left;
            border: 0;
            border-bottom: 0.5px solid #DADADA;
            &:first-child {
              padding-left: 0;
              padding-right: 0;
              width: 31px;
              border-bottom-color: #fff;

              @media (max-width: 767px) {
                width: 25px;
              }
            }
            &:last-child {
              padding-left: 0;
              padding-right: 0;
              width: 37px;
              border-bottom-color: #fff;

              @media (max-width: 767px) {
                width: 0;
              }
            }

            @media (max-width: 767px) {
              &.commented-by {
                display: none;
              }

              border-bottom-color: #efefef;
              padding-right: 25px;
              vertical-align: top;
            }

            &.data-table-empty {
              text-align: center;
              width: 100% !important;
            }
          }
        }
      }
    }
  }
  &:not(.more-rows) {
    .table > tbody > tr:last-child > td {
      border-bottom: 0;
    }
  }
`;
