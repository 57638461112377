import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import calendarImg from '../../../../assets/images/analystic/calendar.svg';

const DateField = ({id, placeholder, value, onChange, minValue, maxValue}) => {
  const prepareDate = (val) => {
    const date = new Date(val);
    const res = date.getTime() ? date : null;
    return res || null;
  };
  return (
    <DateFieldStyled>
      <DatePicker
        id={id}
        minDate={prepareDate(minValue)}
        maxDate={prepareDate(maxValue)}
        selected={prepareDate(value)}
        placeholderText={placeholder}
        onChange={date => onChange(id, date)}
      />
    </DateFieldStyled>
  )
};

DateField.propTypes = {
  id: PropTypes.any.isRequired,
  placeholder: PropTypes.any,
  minValue: PropTypes.any,
  maxValue: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

const DateFieldStyled = styled.div`
  input {
    background: url(${calendarImg}) no-repeat right 5px center;
    border: 1px solid #E9E9E9;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    border-radius: 3px;
    font-size: 11px;
    height: 27px;
    cursor: pointer;
    padding: 9px 20px 9px 9px;
    width: 100%;
  }
`;

export default DateField;
