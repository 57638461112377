import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loader from '../../../components/Loader';

class SearchInput extends Component {
  resetValuesOnBlur = false;
  timer = null;

  state = {
    value: this.props.value
  };

  componentDidUpdate(prevProps) {
    if ((JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value))) {
      this.setState({
        value: this.props.value
      });
    }
  }

  handleKeyDown = event => {
    const keyCode = event.keyCode;
    if (keyCode === 13) {
      this.clearTimeout();
      this.props.onEnter(this.state.value, () => {
        this.resetValuesOnBlur = false;
        this._input.blur();
      });
    } else if (keyCode === 27) {
      this._input.blur();
    } else if (keyCode === 9) {
      const {matchingTerm} = this.props;
      this._input.value = matchingTerm;
      event.preventDefault();
      this.triggerChange(matchingTerm);
      this.setState({value: matchingTerm});
    }
  };

  handleChange = event => {
    this.resetValuesOnBlur = true;
    const value = event.target.value;
    this.setState({value});
    this.clearTimeout();
    this.timer = setTimeout(this.handleChangeByTimeout, 500);
  };

  handleChangeByTimeout = () => {
    this.triggerChange(this.state.value);
  };

  handleBlur = () => {
    if (this.resetValuesOnBlur) {
      const {appliedValue} = this.props;
      this.triggerChange(appliedValue, true);
      this.setState({value: appliedValue});
      this.resetValuesOnBlur = false;
    } else {
      this.clearTimeout();
      this.forceUpdate();
    }
  };

  clearTimeout = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  triggerChange = (value, isBlur) => {
    this.clearTimeout();
    this.props.onChange(value, isBlur);
  };

  render() {
    const {loading, matchingTerm} = this.props;
    const {value} = this.state;
    return (
      <SearchInputStyled>
        {loading ? <Loader/> : null}
        <input
          id="search-input"
          type="text"
          placeholder="Enter search phrase"
          value={value}
          autoComplete="off"
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          ref={r => this._input = r}
          />
        <div className="matching-term">
          {matchingTerm && value && matchingTerm.startsWith(value) ? matchingTerm : null}
        </div>
      </SearchInputStyled>
    );
  }
}

const SearchInputStyled = styled.div`
  width: 100%;
  position: relative;
  margin-top: 13px;

  @media (max-width: 767px) {
    margin: 0;
  }

  input {
    z-index: 10061;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    height: 28px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 25px;
    color: #fff;
    &::-moz-placeholder {
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      font-size: 24px;
      line-height: 25px;
      color: #fff;
      opacity: 0.2;
    }
    &:-ms-input-placeholder {
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      font-size: 24px;
      line-height: 25px;
      color: #fff;
      opacity: 0.2;
    }
    &::-webkit-input-placeholder {
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      font-size: 24px;
      line-height: 25px;
      color: #fff;
      opacity: 0.2;
    }
    &:not(:focus) + .matching-term {
      color: transparent !important;
    }

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 19px;
      &::placeholder {
        font-size: 18px;
        line-height: 19px;
      }
    }
  }
  .matching-term {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 25px;
    color: #fff;
    opacity: 0.4;
    position: absolute;
    top: 2px;
    left: 0;
    height: 28px;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 19px;
      top: 5px;
    }
  }
  .loader-container {
    z-index: 10070 !important;
    background: none !important;
    position: absolute !important;
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    padding: 0 !important;
    .react-spinner {
      top: 0 !important;
      .react-spinner_bar {
        background-color: #fff !important;
      }
    }
  }
`;

SearchInput.propTypes = {
  loading: PropTypes.bool.isRequired,
  matchingTerm: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  appliedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired
};

export default SearchInput;
