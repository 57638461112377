import $ from 'jquery';
import config from '../../config';
import {getErrorMessage} from '../utils';

export const MAIN_ADD_ALERT = 'MAIN_ADD_ALERT';
export const MAIN_HIDE_ALERT = 'MAIN_HIDE_ALERT';
export const MAIN_HIDE_ALL_ALERTS = 'MAIN_HIDE_ALL_ALERTS';
export const MAIN_TOGGLE_SHOW_SIDEBAR = 'MAIN_TOGGLE_SHOW_SIDEBAR';
export const MAIN_NOTIFICATIONS_IS_LOADING = 'MAIN_NOTIFICATIONS_IS_LOADING';
export const MAIN_NOTIFICATIONS_UNSEEN_RECEIVE = 'MAIN_NOTIFICATIONS_UNSEEN_RECEIVE';
export const MAIN_NOTIFICATIONS_BY_FILTER_RECEIVE = 'MAIN_NOTIFICATIONS_BY_FILTER_RECEIVE';
export const MAIN_NOTIFICATIONS_SHOW_BAR = 'MAIN_NOTIFICATIONS_SHOW_BAR';
export const MAIN_NOTIFICATIONS_SHOW_MODAL = 'MAIN_NOTIFICATIONS_SHOW_MODAL';

export const MAIN_CS_STUDIOS_IS_LOADING = 'MAIN_CS_STUDIOS_IS_LOADING';
export const MAIN_CS_GET_STUDIOS = 'MAIN_CS_GET_STUDIOS';
export const MAIN_CS_SHOW_CREATE_REQUEST_MODAL = 'MAIN_CS_SHOW_CREATE_REQUEST_MODAL';
export const MAIN_CS_AUTO_CREATE_REPLACEMENT_WO = 'MAIN_CS_AUTO_CREATE_REPLACEMENT_WO';

export const MAIN__IS_LOADING = 'MAIN__IS_LOADING';

export const showSuccess = (message, headline) => dispatch => {
  dispatch({
    type: MAIN_ADD_ALERT,
    notificationType: 'success',
    headline: headline || 'Success',
    timeout: 1000,
    message
  });
};

export const showError = (message, headline) => dispatch => {
  dispatch({
    type: MAIN_ADD_ALERT,
    notificationType: 'danger',
    headline: headline || 'Error',
    timeout: 3000,
    message
  });
};

export const showInfo = (message, headline) => dispatch => {
  dispatch({
    type: MAIN_ADD_ALERT,
    notificationType: 'info',
    headline: headline || 'Info',
    timeout: 3000,
    message
  });
};

export const hideNotification = notification => dispatch => {
  dispatch({type: MAIN_HIDE_ALERT, notification});
};

export const hideAllNotifications = () => ({type: MAIN_HIDE_ALL_ALERTS});

export const toggleShowSidebar = isShow => dispatch => {
  dispatch({type: MAIN_TOGGLE_SHOW_SIDEBAR, isShow});
};

const prepareAllNotifications = data => {
  const archived = [], action_items = [], system_alerts = [], unanswered_comments = [];
  for (const item of data) {
    if (!item.is_new) {
      archived.push(item);
    } else if (item.category === 'action_items') {
      action_items.push(item);
    } else if (item.category === 'system_alerts') {
      system_alerts.push(item);
    }
    if (item.category === 'unanswered_comments' || item.category2 === 'unanswered_comments') {
      unanswered_comments.push(item);
    }
  }
  return {archived, action_items, system_alerts, unanswered_comments};
};

const getUnseenNotificationsInfo = () => dispatch => {
  dispatch({type: MAIN_NOTIFICATIONS_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/notifications/unseen`
  })
  .done(res => {
    dispatch({type: MAIN_NOTIFICATIONS_UNSEEN_RECEIVE, data: res});
  })
  .fail(() => {
    dispatch({type: MAIN_NOTIFICATIONS_IS_LOADING, loading: false});
  });
};

const archiveNotifications = callback => dispatch => {
  const maxNewSystemNtfId = Number(sessionStorage.getItem('max_new_system_ntf_id'));
  sessionStorage.removeItem('max_new_system_ntf_id');
  const maxNewActionNtfId = Number(sessionStorage.getItem('max_new_action_ntf_id'));
  sessionStorage.removeItem('max_new_action_ntf_id');
  const maxNewNtfId = Math.max(!isNaN(maxNewSystemNtfId) ? maxNewSystemNtfId : 0, !isNaN(maxNewActionNtfId) ? maxNewActionNtfId : 0);
  if (maxNewNtfId > 0) {
    $.ajax({
      method: 'POST',
      url: `${config.apiUrl}/v1/qc_on_demand/notifications/archive`,
      data: {
        max_id: maxNewNtfId
      }
    }).always(() => {callback && callback();});
  } else if (callback) {
    callback();
  }
};

export const getUnseenNotifications = () => dispatch => {
  dispatch(archiveNotifications(() => {
    dispatch(getUnseenNotificationsInfo());
  }));
};

export const getNotificationsByFilter = (filter, isLoadMore) => (dispatch, getState) => {
  if (!filter) {
    filter = 'all';
  }
  const isAll = filter === 'all';
  dispatch({type: MAIN_NOTIFICATIONS_IS_LOADING, loading: true, filter, isLoadMore});
  const state = getState();
  const start = isLoadMore ? state.main.notifications[filter].data.length : 0;
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/notifications/${filter}`,
    data: {
      start,
      limit: config.pagingSize
    }
  })
  .done(res => {
    let {total = 0, data = []} = res;
    let preparedData;
    if (isAll) {
      preparedData = prepareAllNotifications(data);
      if (isLoadMore) {
        preparedData = {
          archived: [...state.main.notifications[filter].preparedData.archived, ...preparedData.archived],
          action_items: [...state.main.notifications[filter].preparedData.action_items, ...preparedData.action_items],
          system_alerts: [...state.main.notifications[filter].preparedData.system_alerts, ...preparedData.system_alerts],
          unanswered_comments: [...state.main.notifications[filter].preparedData.unanswered_comments, ...preparedData.unanswered_comments],
        };
      }
    }
    if (isLoadMore) {
      data = [...state.main.notifications[filter].data, ...data];
    } else if (filter === 'system_alerts' || filter === 'unanswered_comments') {
      data.length > 0 && sessionStorage.setItem('max_new_system_ntf_id', data[0].id);
    } else if (filter === 'action_items') {
      data.length > 0 && sessionStorage.setItem('max_new_action_ntf_id', data[0].id);
    }
    if (isAll) {
      preparedData.system_alerts.length > 0 && sessionStorage.setItem('max_new_system_ntf_id', preparedData.system_alerts[0].id);
      preparedData.action_items.length > 0 && sessionStorage.setItem('max_new_action_ntf_id', preparedData.action_items[0].id);
    }
    dispatch({type: MAIN_NOTIFICATIONS_BY_FILTER_RECEIVE, start, filter, notifications: {total, data, preparedData}});
  })
  .fail(err => {
    if (isLoadMore) {
      dispatch({type: MAIN_NOTIFICATIONS_IS_LOADING, loading: false});
    } else {
      dispatch({type: MAIN_NOTIFICATIONS_BY_FILTER_RECEIVE, filter, notifications: {total: 0, data: []}});
    }
    dispatch(showError(`Could not ${isLoadMore ? 'load more' : 'get'} "${filter}" notifications.  ${getErrorMessage(err)}`));
  });
};

export const showNotificationBar = isShow => dispatch => {
  dispatch({type: MAIN_NOTIFICATIONS_SHOW_BAR, isShow});
};

export const showNotificationsModal = (isShow, ntfFilter) => dispatch => {
  const filter = isShow && ntfFilter ? ntfFilter : null;
  dispatch({type: MAIN_NOTIFICATIONS_SHOW_MODAL, isShow, filter});
  if (isShow) {
    dispatch(getNotificationsByFilter(filter));
  } else {
    dispatch(archiveNotifications());
  }
};

export const getClientServiceStudios = () => dispatch => {
  dispatch({type: MAIN_CS_STUDIOS_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/studios`
  })
  .done(res => {
    dispatch({type: MAIN_CS_GET_STUDIOS, data: res});
  })
  .fail(error => {
    dispatch({type: MAIN_CS_STUDIOS_IS_LOADING, loading: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const showClientServiceCreateRequestModal = show => dispatch => {
  dispatch({type: MAIN_CS_SHOW_CREATE_REQUEST_MODAL, show: show});
}

export const showConfirmAlertToAutoCreateReplacementWO = requestId => ({
  type: MAIN_CS_AUTO_CREATE_REPLACEMENT_WO,
  requestId
});

export const reloadCache = () => dispatch => {
  dispatch({type: MAIN__IS_LOADING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/reload-cache`
  })
  .done(() => {
    dispatch(showSuccess('Successfully reloaded cache.'));
  })
  .fail(error => {
    dispatch(showError(`Could not reload cache.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({type: MAIN__IS_LOADING, loading: false});
  });
};

export const reloadAppConfig = () => dispatch => {
  dispatch({type: MAIN__IS_LOADING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/reload-config/app`
  })
  .done(() => {
    dispatch(showSuccess('Successfully reloaded app config.'));
  })
  .fail(error => {
    dispatch(showError(`Could not reload app config.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({type: MAIN__IS_LOADING, loading: false});
  });
};
