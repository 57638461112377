import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';
import plusImg from '../../../assets/images/input-plus.svg';
import minusImg from '../../../assets/images/input-minus.svg';

function InputNumber(props) {
  const {name, text, min, max, precision, onChange, allowEmpty, forceReset, onFocus, onBlur} = props;
  const valueFromProps = props.value || props.defaultValue;
  const inputRef = useRef(null);

  useEffect(() => {
    if (forceReset && inputRef.current) {
      inputRef.current.value = valueFromProps;
    }
  }, [forceReset, valueFromProps]);

  const handleBtnClick = val => {
    let newValue = Number(inputRef.current.value) + val;
    if ((min === undefined || newValue >= min) && (max === undefined || newValue <= max)) {
      if (precision !== undefined) {
        newValue = Number(newValue.toFixed(precision));
      }
      inputRef.current.value = newValue;
      onChange({
        target: {name, value: newValue}
      });
    }
  };

  const handleChange = e => {
    let newValue = e.target.value;
    if (allowEmpty && newValue === '') {
      onChange({
        target: {name, value: null}
      });
    } else {
      const isDecimal = newValue.indexOf('.') >= 0 || newValue.indexOf(',') >= 0;
      const isDecimalHas0AfterPoint = isDecimal && Boolean((newValue || '').trim().match(/[.,][0]/));
      newValue = Number(newValue);
      if (precision !== undefined) {
        newValue = Number(newValue.toFixed(precision));
      }
      newValue = min !== undefined && newValue < min ? min : max !== undefined && newValue > max ? max : newValue;
      inputRef.current.value = `${newValue}${isDecimal && newValue.toString().indexOf('.') < 0 ? `.${isDecimalHas0AfterPoint ? '0' : ''}` : ''}`;
      onChange({
        target: {name, value: newValue}
      });
    }
  };

  const value = inputRef.current ? inputRef.current.value : valueFromProps;
  const width = 83 + String(value).length * 7;
  const inputProps = {...props, onChange: handleChange};
  delete inputProps.precision;
  delete inputProps.text;
  delete inputProps.allowEmpty;
  delete inputProps.forceReset;
  if (allowEmpty) {
    delete inputProps.min;
    delete inputProps.max;
  }
  return (
    <StyledInput>
      <input
        {...inputProps}
        ref={inputRef}
        style={{width: `${width}px`}}
      />
      {text ? <span className="text">{text}</span> : null }
      <button className="plus-btn"  onClick={() => {handleBtnClick(1)}} onFocus={onFocus} onBlur={onBlur}/>
      <button className="minus-btn" onClick={() => {handleBtnClick(-1)}} onFocus={onFocus} onBlur={onBlur}/>
    </StyledInput>
  );
}

const StyledInput = styled.div`
  position: relative;
  width: fit-content;
  input {
    background: #FFFFFF;
    border: 0.5px solid #D7D7D7;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.05);
    height: 34px;
    line-height: 34px;
    padding: 0 19px;
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield;
    &::-webkit-inner-spin-button, 
    &::-webkit-outer-spin-button { 
      -webkit-appearance: none;
    }
  }
  .text {
    position: absolute;
    right: 34px;
    line-height: 34px;
  }
  button {
    outline: 0 !important;
    box-shadow: none !important;
  }
  i, button {
    background-color: #fff;
    background-position: center center;
    background-repeat: no-repeat;
    border: 1px solid #cfcfcf !important;
    cursor: pointer;
    display: block;
    position: absolute;
    height: 14px;
    width: 18px;
    &.plus-btn {
      background-image: url(${plusImg});
      border-radius: 3px 3px 0 0;
      top: 4px;
      right: 10px;
    }
    &.minus-btn {
      background-image: url(${minusImg});
      border-radius: 0 0 3px 3px;
      top: 16px;
      right: 10px;
    }
    :hover {
      background-color: #eee;
    }
  }
`;

export default InputNumber;