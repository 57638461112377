import React, {useRef} from 'react';
import Select, {components} from 'react-select-v2';
import expandImg from '../../../../../../../assets/images/expand.svg';
import closeImg from '../../../../../../../assets/images/close.svg';

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <img src={expandImg} alt="" width="9"/>
  </components.DropdownIndicator>
);

const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <img src={closeImg} alt="" width="9" title="Clear"/>
  </components.ClearIndicator>
);

function SelectControl(props) {
  const {
    value, options, onChange, onFocus, onBlur, placeholder, menuIsOpen, minWidth, isClearable, isDisabled,
    multiSelectProps, menuMaxHeight
  } = props;

  const otherProps = {};
  if (props.menuPortalTarget) {
    otherProps.menuPortalTarget = document.body;
  }
  if (props.menuPlacement) {
    otherProps.menuPlacement = props.menuPlacement;
  }

  const handleInputChange = (text, action) => {
    i = 0; // reset index for correct work a filter option

    if (props.onInputChange) {
      props.onInputChange(text, action);
    }
  }

  const hasNewOption = options.some(o => o.isNew);
  const selectedOption = props.selectedOption !== undefined ? props.selectedOption : options.find(o => o.value === value);
  const selectRef = useRef(null);

  const resultLimit = 100;
  let i = 0;
  return (
    <Select
      ref={selectRef}
      {...otherProps}
      placeholder={placeholder}
      menuIsOpen={menuIsOpen}
      autoFocus={menuIsOpen}
      value={selectedOption || null}
      options={options}
      isClearable={isClearable}
      isDisabled={isDisabled}
      backspaceRemovesValue={false}
      onChange={o => {
        onChange(o, !!o && o.isNew && selectRef.current.getProp('inputValue'));
        //selectRef.current.blur();
      }}
      blurInputOnSelect
      onInputChange={handleInputChange}
      onFocus={onFocus}
      onBlur={onBlur}
      getOptionLabel={o => o.name}
      getOptionValue={o => o.value}
      filterOption={(option, rawInput) => {
        return (!rawInput || option.data.isNew ||
          option.data.name.toLowerCase().indexOf(rawInput.toLowerCase()) !== -1) && i++ < resultLimit;
      }}
      components={{DropdownIndicator, IndicatorSeparator: null, ClearIndicator}}
      styles={{
        control: (styles, {menuIsOpen}) => {
          const newStyles = {
            ...styles,
            marginLeft: 0,
            marginRight: 0,
            backgroundColor: '#fff',
            borderColor: '#DADADA',
            borderWidth: 0.5,
            borderRadius: menuIsOpen ? '3px 3px 0 0' : '3px',
            boxShadow: 'none',
            fontSize: '12px',
            minHeight: '30px',
          };
          return menuIsOpen ? newStyles : {
            ...newStyles,
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            '&:hover': {...styles['&:hover'], backgroundColor: '#F2F2F2'}
          };
        },
        clearIndicator: (styles, {selectProps}) => {
          const newStyles = {
            ...styles,
            padding: (selectProps.isClearable ? '8px 4px 8px 8px' : '8px'),
            opacity: '0.7',
            '&:hover': {...styles['&:hover'], opacity: '1', backgroundColor: '#eee', cursor: 'pointer'}
          };
          return selectProps.menuIsOpen ? newStyles : {...newStyles, display: 'none'};
        },
        dropdownIndicator: (styles, {selectProps}) => {
          const newStyles = {...styles, padding: (selectProps.isClearable ? '8px 8px 8px 4px' : '8px'), opacity: '0.6'};
          return selectProps.menuIsOpen ? newStyles : {...newStyles, display: 'none'};
        },
        menu: styles => ({
          ...styles,
          marginTop: 0,
          border: '0.5px solid #DADADA',
          borderRadius: '0 0 3px 3px',
          boxShadow: 'none',
          fontSize: '12px'
        }),
        menuList: styles => ({
          ...styles,
          ...(menuMaxHeight ? {maxHeight: menuMaxHeight} : {}),
          paddingBottom: hasNewOption ? 0 : styles.paddingBottom
        }),
        menuPortal: styles => ({
          ...styles,
          zIndex: '9999'
        }),
        singleValue: (styles, {selectProps}) => (selectProps.menuIsOpen ? styles : {
          ...styles,
          color: '#000',
          overflow: 'unset',
          whiteSpace: 'unset'
        }),
        multiValue: (styles, {data}) => {
          return data.isFixed ? {...styles, backgroundColor: 'gray'} : styles;
        },
        multiValueLabel: (styles, {data}) => (
          data.isFixed ? {
            ...styles,
            paddingRight: styles.paddingLeft,
            fontWeight: 500,
            color: 'white',
            whiteSpace: 'normal'
          } : {...styles, whiteSpace: 'normal'}
        ),
        multiValueRemove: (styles, {data}) => {
          return data.isFixed ? {...styles, display: 'none'} : styles;
        },
        valueContainer: (styles, {selectProps}) => {
          const newStyles = {...styles, paddingLeft: 6};
          return selectProps.menuIsOpen ? newStyles : {
            ...newStyles,
            overflow: 'unset',
            paddingLeft: 0,
            paddingRight: 0,
            '&:not(:hover)': {opacity: selectProps.value && selectProps.value.isAll ? 0.4 : 1}
          };
        },
        groupHeading: styles => ({...styles, paddingLeft: '10px'}),
        option: (styles, props) => {
          const {data} = props;
          const newStyles = {...styles, paddingLeft: 10};
          return data.hidden ? {display: 'none'} : !data.isNew ? newStyles : {
            ...newStyles,
            borderTop: '0.5px solid #DADADA',
            borderRadius: 0,
            fontWeight: 'bold',
            position: 'sticky',
            bottom: 0,
            '&:not(:hover)': {backgroundColor: '#fff'}
          };
        },
        container: (styles, {selectProps}) => ({
          ...styles,
          margin: 0,
          padding: 0,
          minWidth: selectProps.menuIsOpen ? (minWidth || 65) : undefined
        })
      }}
      {...multiSelectProps}
    />
  );
}

export default SelectControl;