import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {buildReportLink} from '../../../../utils';

export const ActionsCell = React.memo((props) => {
  const {
    IsQuibi
  } = props.row;

  const viewRequestLink = `/requests/details/${props.value}`;
  const viewReportLink = buildReportLink(props.row);
 
  return (
      <ActionsCellStyled className={props.className}>
        <div>
          <a href={viewRequestLink}>View Request</a>
          <a href={viewReportLink}>View Report</a>
          {IsQuibi ?
            <span
              onClick={() => props.openQuickUpdateDatePopup(props.row)}
            >
              Quick Update
            </span> : null
          }
        </div>
      </ActionsCellStyled>
  );
});

const ActionsCellStyled = styled.td`
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  div {
    display: inline-block;
    a, span {
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      display: -webkit-box;
      display: -moz-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding: 2px 2px;
      margin-bottom: 0;
      + a, + span {
        margin-top: 10px;
      }
    }
    span {
      color: #29A4CB;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        color: #228daf;
      }
    }
  }
`;

ActionsCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  className: PropTypes.string,
  openQuickUpdateDatePopup: PropTypes.func
};
