import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import isMobile from 'is-mobile';

const DateCell = props => {
  const cellRef = useRef(null);
  const {
    row, schedulingId, schedulingName, showScheduledDates, selectedCell, onClick, isCollapsed
  } = props;
  const cell = row.milestones[schedulingId] || {scheduled_date: '', actual_date: ''};
  const isActive = selectedCell.qcProjectId === row.ProjectID && selectedCell.schedulingId === schedulingId;

  const handleClick = event => {
    const rect = cellRef.current.getBoundingClientRect();
    onClick(rect, row.ProjectID, schedulingId, cell.actual_date);
  };

  const cellClassNames = classNames({
    active: isActive,
    'day-passed': !!row.lock_scheduling && cell.actual_date,
    'diff-plus': !!row.lock_scheduling && cell.diff_in_days > 0,
    'diff-minus': !!row.lock_scheduling && cell.diff_in_days <= 0,
    'in-progress': !!row.lock_scheduling && !cell.actual_date,
    'locked': !!row.lock_scheduling,
    'show-scheduled-date': showScheduledDates,
    collapsed: isCollapsed
  });

  return (
    <StyledCell
      ref={cellRef}
      title={isMobile() ? schedulingName : 'Click to Edit'}
      className={cellClassNames}
      onClick={handleClick}>
      <div className="cell">
        <span className="date" title={!row.lock_scheduling ? 'Scheduled Date' : 'Actual Date'}>
          {!row.lock_scheduling ? cell.scheduled_date : cell.actual_date || 'In Progress'}{' '}
          {!!row.lock_scheduling && cell.diff_in_days ?
            <i title="Diff">{cell.diff_in_days > 0 ? '+' : ''}{cell.diff_in_days}d</i> : null}
        </span>
        {showScheduledDates && !!row.lock_scheduling ?
          <span className="scheduled-date" title="Scheduled Date">{cell.scheduled_date}</span> : null}
        <div className="progress-line"/>
        <div className="day-passed-line"/>
        <div className="status-line"/>
      </div>
    </StyledCell>
  );
};

const StyledCell = styled.td`
  border-left: 1px solid #d2d2d2;
  cursor: pointer;
  width: 1%;
  min-width: 100px;

  .status-line, .day-passed-line {
    height: 2px;
    width: 101%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: none;
  }
  @media (min-width: 768px) {
    &:hover, &.active {
      background-color: #c4c4c4;
    }
  }
  &.day-passed {
    .day-passed-line {
      display: block;
      background-color:  #585858;
    }
    .date {
      font-weight: bold;
    }
  }
  &.in-progress .progress-line{
    background-color: #585858;
    display: block;
  }
  &.diff-plus {
    &.day-passed {
      .day-passed-line, .status-line {
        display: block;
        background-color: #ff6d03;
      }
    }
    .progress-line{
      background-color: #ff6d03;
    }
    i {
      color: #ff6d03;
    }
  }
  &.diff-minus {
    &.day-passed {
      .day-passed-line, .status-line {
        display: block;
        background-color: #aece84;
      }
    }
    .progress-line{
      background-color: #aece84;
    }
    i {
      color: #aece84;
    }
  }
  &.not-done {
    padding-top: 22px;
  }
  .cell {
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 20px 6px 5px;
    position: relative;
    height: 100%;
    width: 100%;
  }
  .date {
    color: #000000;
    display: block;
    font-size: 10px;
    white-space: nowrap;
    i {
      font-size: 8px;
      font-style: normal;
      position: absolute;
      top: 9px;
      right: 3px;
      vertical-align: text-bottom;
    }
  }
  .scheduled-date {
    color: #919191;
    display: block;
    font-size: 8px;
    white-space: nowrap;
  }
  .progress-line {
    display: none;
    height: 2px;
    width: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border: none;
    position: relative;

    &:before {
      clear: both;
      content: attr(title);
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      font-weight: bold;
      line-height: 14px;
      margin-bottom: 0;
      color: #000;
      width: calc(100% - 97px);
    }

    &.collapsed {
      display: none;
    }

    &.active {
      background-color: #c4c4c4;
      box-shadow: 10px 0 0 #c4c4c4, -10px 0 0 #c4c4c4;
      border-radius: 3px;
    }

    &.in-progress:not(.day-passed) .progress-line {
      box-shadow: 0 16px 0 #d2d2d2;
    }

    &.day-passed .day-passed-line {
      display: block;
    }

    &.not-done {
      padding-top: 14px;
    }

    .cell {
      flex-direction: row;
      justify-content: space-between;
      padding: 0;
      width: 97px;
      position: unset;

      span {
        font-size: 13px;
        line-height: 14px;
        width: 97px;
        text-align: right;

        &.date {
          font-weight: normal;
          position: relative;

          i {
            font-size: 11px;
            top: -10px;
            right: -18px;
          }
        }

        &.scheduled-date {
          color: #909090;
        }
      }
    }

    .status-line {
      display: none !important;
    }

    .progress-line {
      width: 5px;
      height: calc(50% - 3px);
      top: 3px;
      left: -25px;
      bottom: unset;
    }

    .day-passed-line {
      position: absolute;
      width: 5px;
      height: calc(100% - 6px);
      top: 3px;
      left: -25px;
    }

    &.show-scheduled-date {
      &:before {
        width: calc(100% - 194px);
      }
      .cell {
        width: 194px;
      }
    }
  }
`;

DateCell.propTypes = {
  row: PropTypes.object.isRequired,
  showScheduledDates: PropTypes.bool.isRequired,
  selectedCell: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default DateCell;