import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Clearfix} from 'react-bootstrap';
import classNames from 'classnames';
import {
  NEW_REQUEST_STEP_CHOICES, QC_REQUEST_TYPES
} from '../../NewRequest/constants';
import styled from 'styled-components';

class StepHeader extends Component {
  renderFirstTitle = () => {
    return (
      <div className="step-choices">
        <span className="icon-create-request"/>
        <span className="step-title">Create QC Request</span>
      </div>
    );
  };

  renderTitleWithSteps = () => {
    const {onBack, currentStep, data, steps, filters} = this.props;
    const requestTypeTitle = Object.values(QC_REQUEST_TYPES).find(i => i.id === data.request_type_id).title;
    const originRequest = data.origin_request || {};
    const seriesTitle = data.request_type_id === QC_REQUEST_TYPES.NewQcRequest.id ?
      ((filters.series.find(s => s.series_id === data.series_id) || {}).series_name || data.series_id) :
      (originRequest.Title || originRequest.SeriesName);
    return (
      <div>
        <Row>
          <Col md={12} xs={12} className="clearfix">
            <div className="step-titles">
              <BackArrow title="Back" className={`icon-back-arrow${!onBack ? ' disabled' : ''}`} onClick={onBack}/>
              <span className="step-title">Create <span>{requestTypeTitle}</span></span>
              <span className="step-series-title">{seriesTitle}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12} className="clearfix">
            {steps.map((s, i) => i === 0 ? null : (
              <div
                key={`header-step-${i}`}
                className={`header-step${s.id === currentStep ? ' header-current-step' : ''}`}
                >
                {typeof s.name === 'string' ? s.name : s.name[data.request_type_id]}
              </div>
            ))}
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const {currentStep, onClose} = this.props;
    const compClassNames = classNames({
      'step-content-container step-header': true,
      'step-header-choices': currentStep === NEW_REQUEST_STEP_CHOICES
    });
    return (
      <StyledStepHeader className={compClassNames}>
        <Row>
          <Col md={11} xs={11}>
            {currentStep === NEW_REQUEST_STEP_CHOICES ? this.renderFirstTitle() : this.renderTitleWithSteps()}
          </Col>
          <Col md={1} xs={1}>
            <div title="Close" className="icon-close-wizard close-wizard" onClick={onClose}/>
          </Col>
        </Row>
      </StyledStepHeader>
    );
  }
}

const StyledStepHeader = styled(Clearfix)`
  &.step-header {
    height: 107px;
    overflow: hidden;

    * {
      color: #fff;
      font-weight: normal;
      font-size: 24px;
      line-height: 25px;
    }

    [class^="icon-"], [class*=" icon-"] {
      font-size: 36px;
      line-height: 1;
    }

    .close-wizard.icon-close-wizard {
      float: right;
      margin: 29px 19px 0 0;
      cursor: pointer;

      &:hover {
        font-size: 37px;
      }
    }

    .header-step {
      display: inline-block;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      opacity: 0.6;
      vertical-align: top;
      margin: 0 0 0 28px;

      &:first-child {
        margin-left: 73px;
      }

      &:last-child {
        margin-right: 0;
      }

      &.header-current-step {
        font-weight: 500;
        font-size: 15px;
        opacity: 1;
      }
    }

    &.step-header-choices {
      height: 183px;

      .step-choices {
         margin: 74px 0 0 40px;

         * {
           display: inline-block;
         }

         .icon-create-request {
           font-size: 42px;
         }

         .step-title {
           vertical-align: top;
           padding: 7px 0 0 17px;
           font-weight: 500;
         }
      }
    }

    &:not(.step-header-choices) {
      .step-titles {
         margin: 35px 0 18px 59px;
         position: relative;
         display: flex;
         flex-direction: row;
         align-items: flex-end;
         justify-content: flex-start;

         .step-title {
           padding: 0 0 0 14px;

           span {
             font-weight: 500;
           }
         }

         .step-series-title {
           padding: 0 0 1px 25px;
           font-weight: 300;
           font-size: 14px;
           line-height: 15px;
           opacity: 0.5;
         }
      }
    }
  }
`;

const BackArrow = styled.div`
  margin-top: 3px;
  font-size: 24px !important;
  color: #fff !important;
  border: 2px solid transparent;
  border-radius: 50%;
  padding: 4px;
  position: absolute;
  top: -6px;
  left: -30px;
  will-change: border-color, background-color, color;
  transition: all 0.3s ease 0s;
  &:hover {
    cursor: pointer;
    border-color: #fff;
    background-color: #fff;
    color: #28c0f0 !important;
  }
  &.disabled {
    pointer-events: none;
  }
`;

StepHeader.propTypes = {
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired
};

StepHeader.defaultProps = {
  onClose: () => {},
  data: {}
};

export default StepHeader;
