import $ from 'jquery';
import config from '../../../config';
import {getErrorMessage, getErrorMessageByCode, getHiddenWizardFields, getTextlessOptionName} from '../../utils';
import {getDictionaries, prepareDictionaries} from '../NewRequest/actions';
import {showSuccess, showError, showInfo, showConfirmAlertToAutoCreateReplacementWO} from '../../layouts/actions';
import {VIDEO_FORMAT_FIELDS, VIDEO_FORMAT_FIELD_TEXTLESS} from '../NewRequest/constants';
import {UserPermission} from './components/forms/UserPermissions';

export const REQUEST_DETAILS_CLEAR = 'REQUEST_DETAILS_CLEAR';
export const REQUEST_DETAILS_SET_ACTIVE_TAB = 'REQUEST_DETAILS_SET_ACTIVE_TAB';
export const REQUEST_DETAILS_CHANGE = 'REQUEST_DETAILS_CHANGE';
export const REQUEST_DETAILS_UPDATE = 'REQUEST_DETAILS_UPDATE';
export const REQUEST_DETAILS_IS_LOADING = 'REQUEST_DETAILS_IS_LOADING';
export const REQUEST_DETAILS_GET_RECEIVE = 'REQUEST_DETAILS_GET_RECEIVE';
export const REQUEST_DETAILS_IS_SAVING = 'REQUEST_DETAILS_IS_SAVING';
export const REQUEST_DETAILS_CREATE_WO = 'REQUEST_DETAILS_CREATE_WO';
export const REQUEST_DETAILS_PRECUT_WO = 'REQUEST_DETAILS_PRECUT_WO';
export const REQUEST_DETAILS_CREATE_ASSET = 'REQUEST_DETAILS_CREATE_ASSET';
export const REQUEST_DETAILS_COST_IS_LOADING = 'REQUEST_DETAILS_COST_IS_LOADING';
export const REQUEST_DETAILS_COST_RECEIVE = 'REQUEST_DETAILS_COST_RECEIVE';
export const REQUEST_DETAILS_MARK_EPISODES_AS_SAVED = 'REQUEST_DETAILS_MARK_EPISODES_AS_SAVED';
export const REQUEST_DETAILS_MARK_FORMATS_AS_SAVED = 'REQUEST_DETAILS_MARK_FORMATS_AS_SAVED';
export const REQUEST_DETAILS_RESET_UNSAVED_VIEW = 'REQUEST_DETAILS_RESET_UNSAVED_VIEW';
export const REQUEST_DETAILS_CANCELLED = 'REQUEST_DETAILS_CANCELLED';
export const REQUEST_DETAILS_SUB_STEPS_IS_LOADING = 'REQUEST_DETAILS_SUB_STEPS_IS_LOADING';
export const REQUEST_DETAILS_SUB_STEPS_RECEIVE = 'REQUEST_DETAILS_SUB_STEPS_RECEIVE';
export const REQUEST_DETAILS_MEMFIS_FILE_LIST_IS_LOADING = 'REQUEST_DETAILS_MEMFIS_FILE_LIST_IS_LOADING';
export const REQUEST_DETAILS_MEMFIS_FILE_LIST_RECEIVE = 'REQUEST_DETAILS_MEMFIS_FILE_LIST_RECEIVE';
export const REQUEST_DETAILS_VERSIONS_IS_LOADING = 'REQUEST_DETAILS_VERSIONS_IS_LOADING';
export const REQUEST_DETAILS_ATTACHMENTS_IS_LOADING = 'REQUEST_DETAILS_ATTACHMENTS_IS_LOADING';
export const REQUEST_DETAILS_ATTACHMENTS_RECEIVE = 'REQUEST_DETAILS_ATTACHMENTS_RECEIVE';
export const REQUEST_DETAILS_ATTACHMENT_IS_UPLOADING = 'REQUEST_DETAILS_ATTACHMENT_IS_UPLOADING';
export const REQUEST_DETAILS_ATTACHMENT_ADD = 'REQUEST_DETAILS_ATTACHMENT_ADD';
export const REQUEST_DETAILS_ATTACHMENT_IS_DOWNLOADING = 'REQUEST_DETAILS_ATTACHMENT_IS_DOWNLOADING';
export const REQUEST_DETAILS_ATTACHMENT_IS_DELETING = 'REQUEST_DETAILS_ATTACHMENT_IS_DELETING';
export const REQUEST_DETAILS_ATTACHMENT_DELETE = 'REQUEST_DETAILS_ATTACHMENT_DELETE';

export const REQUEST_DETAILS_CAPTIONS_IS_LOADING = 'REQUEST_DETAILS_CAPTIONS_IS_LOADING';
export const REQUEST_DETAILS_CAPTIONS_RECEIVE = 'REQUEST_DETAILS_CAPTIONS_RECEIVE';
export const REQUEST_DETAILS_CAPTIONS_IS_UPLOADING = 'REQUEST_DETAILS_CAPTIONS_IS_UPLOADING';
export const REQUEST_DETAILS_CAPTIONS_ADD = 'REQUEST_DETAILS_CAPTIONS_ADD';
export const REQUEST_DETAILS_CAPTIONS_IS_DOWNLOADING = 'REQUEST_DETAILS_CAPTIONS_IS_DOWNLOADING';
export const REQUEST_DETAILS_CAPTIONS_IS_DELETING = 'REQUEST_DETAILS_CAPTIONS_IS_DELETING';
export const REQUEST_DETAILS_CAPTIONS_DELETE = 'REQUEST_DETAILS_CAPTIONS_DELETE';
export const REQUEST_DETAILS_CAPTIONS_FILTERS_IS_LOADING = 'REQUEST_DETAILS_CAPTIONS_FILTERS_IS_LOADING';
export const REQUEST_DETAILS_CAPTIONS_FILTERS = 'REQUEST_DETAILS_CAPTIONS_FILTERS';

export const REQUEST_DETAILS_GET_PROJECT_WORK_ORDER_LIST = 'REQUEST_DETAILS_GET_PROJECT_WORK_ORDER_LIST';

export const REQUEST_DETAILS_PROXIES_VERSIONS_IS_LOADING = 'REQUEST_DETAILS_PROXIES_VERSIONS_IS_LOADING';
export const REQUEST_DETAILS_PROXIES_IS_LOADING = 'REQUEST_DETAILS_PROXIES_IS_LOADING';
export const REQUEST_DETAILS_PROXIES_DELETE = 'REQUEST_DETAILS_PROXIES_DELETE';
export const REQUEST_DETAILS_PROXIES_RECEIVE = 'REQUEST_DETAILS_PROXIES_RECEIVE';
export const REQUEST_DETAILS_PROXIES_CREATE = 'REQUEST_DETAILS_PROXIES_CREATE';
export const REQUEST_DETAILS_PROXY_IS_LOADING = 'REQUEST_DETAILS_PROXY_IS_LOADING';

export const REQUEST_DETAILS_PERMISSIONS_IS_LOADING = 'REQUEST_DETAILS_PERMISSIONS_IS_LOADING';
export const REQUEST_DETAILS_PERMISSIONS_RECEIVE = 'REQUEST_DETAILS_PERMISSIONS_RECEIVE';

export const REQUEST_DETAILS_TOGGLE_SEASON_STATUS_POPUP = 'REQUEST_DETAILS_TOGGLE_SEASON_STATUS_POPUP';
export const REQUEST_DETAILS_TOGGLE_WO_STEPS_MODAL = 'REQUEST_DETAILS_TOGGLE_WO_STEPS_MODAL';
export const REQUEST_DETAILS_GET_DICTIONARIES_FOR_EDIT_STEPS = 'REQUEST_DETAILS_GET_DICTIONARIES_FOR_EDIT_STEPS';

export const REQUEST_DETAILS_EDIT_COMPANY_FIELD_NAME = 'REQUEST_DETAILS_EDIT_COMPANY_FIELD_NAME';

export const clearRequestDetails = () => dispatch => {
  dispatch({type: REQUEST_DETAILS_CLEAR});
};

export const setActiveTab = activeTab => dispatch => {
  dispatch({type: REQUEST_DETAILS_SET_ACTIVE_TAB, activeTab});
}

export const changeRequestDetails = (details, unsavedView) => dispatch => {
  dispatch({type: REQUEST_DETAILS_CHANGE, details, unsavedView});
};

const updateRequestDetails = data => ({type: REQUEST_DETAILS_UPDATE, data});

const getRequestSteps = () => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_SUB_STEPS_IS_LOADING, loading: true});
  const {details} = getState().requestdetails;
  const {RequestID} = details;
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/steps`
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_SUB_STEPS_RECEIVE, data: res});
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_SUB_STEPS_IS_LOADING, loading: false});
    console.log(getErrorMessage(error));
    dispatch(showError('Could not get request steps'));
  });
};

const getCostDetails = (requestId, noNeedToGetSteps) => dispatch => {
  dispatch({type: REQUEST_DETAILS_COST_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/cost_details`,
    data: {
      params: JSON.stringify({request_id: requestId})
    }
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_COST_RECEIVE, data: res});
  })
  .then(() => !noNeedToGetSteps && dispatch(getRequestSteps()))
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_COST_IS_LOADING, loading: false});
    console.log(getErrorMessage(error));
    dispatch(showError('Could not get cost details'));
  });
};

const getPermissions = () => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_PERMISSIONS_IS_LOADING, loading: true});
  const {details: {RequestID}} = getState().requestdetails;
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/permissions`
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_PERMISSIONS_RECEIVE, data: res});
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_PERMISSIONS_IS_LOADING, loading: false});
    console.log(getErrorMessage(error));
    dispatch(showError('Could not get request permissions'));
  });
};

const getMemfisFileList = () => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_MEMFIS_FILE_LIST_IS_LOADING, loading: true});
  const {details} = getState().requestdetails;
  const {RequestID} = details;
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/files`
  })
      .done(res => {
        dispatch({type: REQUEST_DETAILS_MEMFIS_FILE_LIST_RECEIVE, data: res});
      })
      .fail(error => {
        dispatch({type: REQUEST_DETAILS_MEMFIS_FILE_LIST_IS_LOADING, loading: false});
        console.log(getErrorMessage(error));
        dispatch(showError('Could not get request files'));
      });
};

const getAttachments = () => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_ATTACHMENTS_IS_LOADING, loading: true});
  const {details} = getState().requestdetails;
  const {RequestID, QcTypeID} = details;
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/attachments`,
    data: {
      qc_type_id: QcTypeID
    }
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_ATTACHMENTS_RECEIVE, data: res.attachments, filters: res.filters});
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_ATTACHMENTS_IS_LOADING, loading: false});
    console.log(getErrorMessage(error));
    dispatch(showError('Could not get request attachments'));
  });
};

const getCaptions = () => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_CAPTIONS_IS_LOADING, loading: true});
  const {details} = getState().requestdetails;
  const {RequestID} = details;
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/captions`
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_CAPTIONS_RECEIVE, data: res});
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_CAPTIONS_IS_LOADING, loading: false});
    console.log(getErrorMessage(error));
    dispatch(showError('Could not get request captions'));
  });
};

const getFullDictionaries = (request) => dispatch => {
  const dictParams = {for_request_details: true, qc_profile_id: request.QcProfileID};
  if (request.IsStudioRequest || !(request.IsProductionRequest || request.IsFinishingHouseRequest)) {
    dictParams.cs_studio_id = request.StudioID;
  } else {
    dictParams.partner_studio_id = request.StudioID;
    if (request.IsProductionRequest) {
      dictParams.cs_production_id = request.ProductionID[0];
    } else {
      dictParams.cs_finishing_house_id = request.FinishingHouseID[0];
    }
  }
  dictParams.company_ids = [
    request.StudioID,
    ...request.ProductionID || [],
    ...request.FinishingHouseID || []
  ].filter(i => +i > 0).join(',');
  dispatch(getDictionaries(dictParams));
};

export const getRequestDetails = (history, requestId, isSelectedVersion) => (dispatch, getState) => {
  const {details} = getState().requestdetails;
  const needToLoadDictionaries = !details || !!isSelectedVersion;
  dispatch({type: REQUEST_DETAILS_IS_LOADING, loading: true, loadingSelectedVersion: isSelectedVersion});

  if (history && requestId && 'string' === typeof requestId && requestId.startsWith('WO')) {
    return $.ajax({
      method: 'GET',
      url: `${config.apiUrl}/v1/qc_on_demand/requests/wo/${requestId}`
    })
    .done(res => {
      const {requestID: qodRequestId} = res;
      history.push(`/requests/details/${qodRequestId}`);
      window.location.reload();
    })
    .fail(error => {
      dispatch({type: REQUEST_DETAILS_IS_LOADING, loading: false});
      console.log(getErrorMessage(error));
      dispatch(showError('Could not get request details'));
      history.push('/requests');
    });
  }


  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${requestId}`
  })
  .done(res => {
    const {request, filters} = res;
    const {Episodes} = request;
    if (Array.isArray(Episodes)) {
      Episodes.forEach(item => {
        if (!item.episode_request_id) {
          item.episode_request_id = -1;
        }
      });
    }
    const details = {
      ...request,
      Episodes
    };
    dispatch({type: REQUEST_DETAILS_GET_RECEIVE, filters, details, needToResetState: isSelectedVersion});
    if (needToLoadDictionaries) {
      dispatch(getFullDictionaries(request));
    }
    dispatch(getCostDetails(requestId, true));
  })
  .then(() => {
    dispatch(getRequestSteps());
    dispatch(getMemfisFileList(requestId));
    dispatch(getAttachments());
    dispatch(getCaptions());
    dispatch(getPermissions());
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_LOADING, loading: false});
    console.log(getErrorMessage(error));
    dispatch(showError('Could not get request details'));
    if (history) {
      history.push('/requests');
    } else {
      window.location.href = '/requests';
    }
  });
};

export const getCompanyRelatedDictionaries = (params) => dispatch => {
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/filters_related_company`,
    data: params
  })
  .then(res => {
    const dictionaries = prepareDictionaries(res);
    delete dictionaries.finishing_houses;
    delete dictionaries.series;
    return dictionaries;
  });
};

const ErrorCodes = {
  CANNOT_CREATE_WO_BY_BAD_TEMPLATE: 78,
  OFFICE_FLAG_MISSING: 81
};

export const createWorkOrder = (requestId, skipUpdatingOfState) => dispatch => {
  if (!skipUpdatingOfState) {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true});
  }
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${requestId}/ready_for_qc`
  })
  .done(res => {
    const {id, request_title, wo_description, MemfisCompanyID} = res;
    if (!skipUpdatingOfState) {
      dispatch({type: REQUEST_DETAILS_CREATE_WO, id, wo_description, MemfisCompanyID});
      dispatch(getCostDetails(requestId));
    }
    const message = `Creating work order for request "${request_title}".  It should appear in Memfis shortly.`;
    dispatch(showSuccess(message));
    dispatch(showConfirmAlertToAutoCreateReplacementWO());
  })
  .fail(error => {
    if (!skipUpdatingOfState) {
      dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    }
    let errorMessage = getErrorMessageByCode(error, ErrorCodes.CANNOT_CREATE_WO_BY_BAD_TEMPLATE);
    if (errorMessage) {
      dispatch(showError('Cannot create work order.  The work order template is missing or incomplete.'));
    } else {
      errorMessage = getErrorMessageByCode(error, ErrorCodes.OFFICE_FLAG_MISSING)
      if (errorMessage) {
        dispatch(showError('Cannot create work order.  Please select an office.'));
      } else {
        dispatch(showError(`Could not create work order.  ${getErrorMessage(error)}`));
      }
      console.log(getErrorMessage(error));
    }
    dispatch(showConfirmAlertToAutoCreateReplacementWO());
  });
};

export const preCutWorkOrders = (requestId, history) => dispatch => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${requestId}/pre_cut`
  })
  .done(res => {
    const {episodes, new_episode_wo_count, cannot_create_wo_by_bad_template_count, cannot_create_wo_without_office_flag_count} = res;
    dispatch({type: REQUEST_DETAILS_PRECUT_WO, episodes});
    if (new_episode_wo_count > 0) {
      dispatch(showSuccess(`${new_episode_wo_count} episode work order(s) created`));
      dispatch(getRequestDetails(history, requestId));
    }
    if (cannot_create_wo_by_bad_template_count > 0) {
      dispatch(showError(`Cannot create ${new_episode_wo_count > 0 ? `${cannot_create_wo_by_bad_template_count} ` : ''}work order(s).  The work order template is missing or incomplete.`));
    }
    if (cannot_create_wo_without_office_flag_count > 0) {
      dispatch(showError(`Cannot create ${new_episode_wo_count > 0 ? `${cannot_create_wo_without_office_flag_count} ` : ''}work order(s).  Please select an office.`));
    }
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const preCancelRequest = requestId => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true});
  const {details: {WOID}} = getState().requestdetails;
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${requestId}/cancel`
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_CANCELLED, statusId: config.requestStatuses.CANCELLED});
    dispatch(showSuccess(`${!WOID ? 'Request' : 'Work Order'} was canceled`));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const preCancelAllWorkOrdersForEntireSeason = () => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true});
  const {details: {RequestID}} = getState().requestdetails;
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/cancel_all`
  })
  .always(() => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
  })
  .done(res => {
    if (typeof res === 'string') {
      dispatch(showInfo(res));
    } else {
      dispatch(showSuccess('All canceled successfully!'));
    }
    dispatch({type: REQUEST_DETAILS_CANCELLED, HasEpisodesToCancel: false});
  })
  .fail(error => {
    dispatch(showError(getErrorMessage(error)));
  });
};

export const updateRequestFormats = (mediaData, allowUpdateAdditionalDesc) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true});
  const {dictionaries} = getState().newrequest;
  const {details} = getState().requestdetails;
  const {RequestID, QcProfileID, QcTypeID, RequestFormat} = details;
  const {AudioFormatID, AudioConfiguration, NeedCheckAudio, CaptionID} = mediaData;
  const hiddenFields = getHiddenWizardFields(dictionaries.qc_profiles, QcProfileID);
  const videoFields = hiddenFields ?
    VIDEO_FORMAT_FIELDS.filter(item => !hiddenFields.includes(item.filterName)) : VIDEO_FORMAT_FIELDS;
  const qcType = dictionaries.qc_types.find(t => t.value === QcTypeID) || {};
  const values = {};
  if (qcType.is_video) {
    values.video_format = {};
    videoFields.forEach(field => {
      values.video_format[field.name] = mediaData[field.altName];
    });
  }
  if (qcType.is_audio) {
    values.audio_format = {};
    if (!hiddenFields.includes('audio_format')) {
      values.audio_format.format_id = AudioFormatID;
    }
    if (!hiddenFields.includes('audio_configuration')) {
      values.audio_format.audio_configuration = {
        not_sure: NeedCheckAudio,
        data: AudioConfiguration || []
      };
    }
  }
  if (qcType.is_captions) {
    values.caption_id = CaptionID;
  }
  values.allow_update_additional_desc = allowUpdateAdditionalDesc;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/formats`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(values)
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch({type: REQUEST_DETAILS_MARK_FORMATS_AS_SAVED});
    dispatch(showSuccess('The media details has been updated.'));
    dispatch(getCostDetails(RequestID));
    let newReqFormat = RequestFormat;
    if (qcType.is_video) {
      newReqFormat = '';
      videoFields.filter(field => !!field.useToBuildRequestFormat).forEach(field => {
        const valId = values.video_format[field.name];
        const valName = typeof valId !== 'number' ? null :
          field.name === VIDEO_FORMAT_FIELD_TEXTLESS ? getTextlessOptionName(valId) :
            (dictionaries[field.filterName].find(t => t.value === valId) || {}).name;
        if (valName) {
          newReqFormat += (newReqFormat ? '/' : '') + valName;
        }
      });
    }
    dispatch(changeRequestDetails({...details, ...mediaData, RequestFormat: newReqFormat, ...res}));
    if (qcType.is_video || qcType.is_audio) {
      dispatch(getRequestVersions(RequestID));
    }
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const resetUnsavedView = (unsavedView) => ({type: REQUEST_DETAILS_RESET_UNSAVED_VIEW, unsavedView});

export const updateSeasonEpisodes = (episodesData) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true, savingParam: 'Episodes'});
  const {details} = getState().requestdetails;
  const {RequestID, SeasonRequestID} = details;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${SeasonRequestID || RequestID}/episodes`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(episodesData)
  })
  .done(res => {
    episodesData.forEach(item => {
      if (!item.episode_request_id) {
        item.episode_request_id = -1;
      }
    });
    const updatedDetails = {...details, Episodes: episodesData, TotalEpisodes: episodesData.length};
    if (SeasonRequestID) {
      const episode = episodesData.find(item => item.episode_request_id === RequestID);
      if (episode) {
        updatedDetails.EpisodeName = episode.episode_name;
        updatedDetails.Runtime = episode.runtime_in_sec;
      }
    }
    dispatch(changeRequestDetails(updatedDetails));
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch({type: REQUEST_DETAILS_MARK_EPISODES_AS_SAVED});
    dispatch(showSuccess('The season details has been updated.'));
    dispatch(getCostDetails(RequestID));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(`Could not update season details.  ${getErrorMessage(error)}`));
  });
};

const getRequestVersions = requestId => dispatch => {
  dispatch({type: REQUEST_DETAILS_VERSIONS_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${requestId}/versions`
  })
  .done(res => {
    dispatch(updateRequestDetails(res));
    dispatch({type: REQUEST_DETAILS_VERSIONS_IS_LOADING, loading: false});
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_VERSIONS_IS_LOADING, loading: false});
    dispatch(showError(`Could not get request versions.  ${getErrorMessage(error)}`));
  });
};

export const isRequestHasNoOwnRawProxy = ({ownRawProxyS3FileName}) => {
  return typeof ownRawProxyS3FileName !== 'string';
};

export const getAvailableRawProxies = requestId => (dispatch, getState) => {
  const {details} = getState().requestdetails;
  if (!requestId && !isRequestHasNoOwnRawProxy(details)) {
    return;
  }

  if (!requestId) {
    const {RequestID} = details;
    requestId = RequestID;
  }

  dispatch({type: REQUEST_DETAILS_PROXIES_VERSIONS_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${requestId}/available_proxies`
  })
  .done(res => {
    dispatch(updateRequestDetails({VersionsForProxies: res}));
    dispatch({type: REQUEST_DETAILS_PROXIES_VERSIONS_IS_LOADING, loading: false});
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_PROXIES_VERSIONS_IS_LOADING, loading: false});
    dispatch(showError(`Could not get available proxies.  ${getErrorMessage(error)}`));
  });
};

export const updateRequestField = (label, name, value, callback, needToUpdateWOs, updateType) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true, savingParam: name});
  const {details} = getState().requestdetails;
  const {RequestID, SeasonRequestID, Episodes} = details;
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/field`,
    data: {
      update_type: updateType,
      update_existing_work_orders: needToUpdateWOs,
      [name]: value
    }
  })
  .done(res => {
    let episodes;
    if (SeasonRequestID && Episodes) {
      episodes = [...Episodes];
      if (['Runtime', 'EpisodeName'].includes(name)) {
        const episode = episodes.find(item => item.episode_request_id === RequestID);
        if (episode) {
          episode[name === 'Runtime' ? 'runtime_in_sec' : 'episode_name'] = value;
        }
      }
    } else {
      episodes = Episodes;
    }
    dispatch(changeRequestDetails({...details, [name]: value, Episodes: episodes, ...res}));
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess(`The ${label} has been updated.`));
    callback && callback();
    if (['Runtime', 'DeliveredAsPatches'].includes(name)) {
      dispatch(getCostDetails(RequestID));
    } else if (name === 'Tag') {
      dispatch(getRequestVersions(RequestID));
    }
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(`Could not update ${label}.  ${getErrorMessage(error)}`));
  });
};

export const updateRequestStatus = ({RequestStatusID, RequestStatus}, callback) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true, savingParam: 'RequestStatusID'});
  const {details} = getState().requestdetails;
  const {RequestID} = details;
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/field`,
    data: {
      RequestStatusID
    }
  })
  .done(() => {
    dispatch(changeRequestDetails({...details, RequestStatusID, RequestStatus, is_allow_change_request_status: false}));
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess('The Request Status has been changed.'));
    callback && callback();
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(`Could not update the Request Status.  ${getErrorMessage(error)}`));
  });
};

export const updateMemfisClient = ({MemfisCompanyID, MemfisCompanyName}, updateType, callback) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true, savingParam: 'MemfisCompanyID'});
  const {details} = getState().requestdetails;
  const {RequestID} = details;
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/field`,
    data: {
      MemfisCompanyID,
      update_type: updateType
    }
  })
  .done(() => {
    dispatch(changeRequestDetails({...details, MemfisCompanyID, MemfisCompanyName}));
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess('The MEMFIS Client has been changed.'));
    callback && callback();
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(`Could not update the MEMFIS Client.  ${getErrorMessage(error)}`));
  });
};

export const updateSeriesShowCode = (showCode, needToUpdateExistingWOs) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true});
  const {details} = getState().requestdetails;
  const {SeriesID} = details;
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/series/${SeriesID}/show_code`,
    data: {
      update_existing_work_orders: needToUpdateExistingWOs,
      show_code: showCode
    }
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess('The show code has been updated.'));
    dispatch(changeRequestDetails({...details, ShowCode: showCode}));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const updateQcProjectDistributionList = (distributionList, updateTypeValue) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true});
  const {details} = getState().requestdetails;
  const {RequestID} = details;
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/distribution_list`,
    data: {
      update_type: updateTypeValue,
      distribution_list: distributionList
    }
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess('The distribution list has been updated.'));
    dispatch(changeRequestDetails({...details, DistributionList: distributionList}));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(`Could not update distribution list.  ${!updateTypeValue ? 'Please select an update option in popup.  ' : getErrorMessage(error)}`));
  });
};

export const updateQcProjectClientContactID = (clientContactID, needToUpdateExistingWOs) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true});
  const {details} = getState().requestdetails;
  const {RequestID} = details;
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/client_contact`,
    data: {
      update_existing_work_orders: needToUpdateExistingWOs,
      client_contact_id: clientContactID
    }
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess('The client contact has been updated.'));
    dispatch(changeRequestDetails({...details, ClientContactID: clientContactID}));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const updateCSUser = csUserId => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true});
  const {details} = getState().requestdetails;
  const {RequestID} = details;
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/cs_user`,
    data: {
      cs_user_id: csUserId
    }
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess('The client service user has been updated.'));
    dispatch(changeRequestDetails({...details, CSUserID: csUserId}));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const updateOffice = officeFlag => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true});
  const {details} = getState().requestdetails;
  const {RequestID} = details;
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/office`,
    data: {
      office_flag: officeFlag
    }
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess('The office value has been updated.'));
    dispatch(changeRequestDetails({...details, OfficeFlag: officeFlag}));
    dispatch(getCostDetails(RequestID));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const duplicateRequest = values => dispatch => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true});
  const {RequestID, series_id} = values;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/duplicate`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      ...values,
      series_id: (typeof series_id === 'number' ? series_id : null),
      series_name: (typeof series_id === 'string' ? series_id : null),
      show_code: (typeof series_id === 'string' ? values.show_code : null)
    })
  })
  .done(() => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess('Request has been successfully duplicated'));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(`Could not duplicate request.  ${getErrorMessage(error)}`));
  });
};

export const getSeasonLevelRequest = () => (dispatch, getState) => {
  const {details} = getState().requestdetails;
  const {SeasonRequestID} = details;
  dispatch({type: REQUEST_DETAILS_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/season/${SeasonRequestID}`
  })
  .done(() => {
    dispatch({type: REQUEST_DETAILS_IS_LOADING, loading: false});
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_LOADING, loading: false});
    dispatch(showError(`Could not get season-level request details.  ${getErrorMessage(error)}`));
  });
};

export const uploadAttachment = (file, label, category, pse_test, callback) => (dispatch, getState) => {
  const {details} = getState().requestdetails;
  const {RequestID} = details;
  dispatch({type: REQUEST_DETAILS_ATTACHMENT_IS_UPLOADING, loading: true});
  const data = new FormData();
  data.append('file', file);
  !!label && data.append('label', label);
  data.append('category_id', category.value);
  !!pse_test && data.append('pse_test_status', pse_test);
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/attachment`,
    data,
    cache: false,
    processData: false,
    contentType: false
  })
  .done(res => {
    const {attachment_id, request_statuses, Attachments, Message} = res;
    callback && callback();
    if (Message && Message.indexOf('already attached') !== -1) {
      dispatch({type: REQUEST_DETAILS_ATTACHMENT_IS_UPLOADING, loading: false});
      dispatch(changeRequestDetails({...details, Attachments}));
      dispatch(showError(Message));
    } else {
      const newAttachment = {
        id: attachment_id,
        filename: file.name,
        label,
        category_id: category.value,
        category: category.name,
        pse_test
      };
      dispatch({type: REQUEST_DETAILS_ATTACHMENT_ADD, data: newAttachment, request_statuses});
      dispatch(showSuccess('Attachment has been successfully uploaded.'));
    }
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_ATTACHMENT_IS_UPLOADING, loading: false});
    dispatch(showError(`Could not upload attachment.  ${getErrorMessage(error)}`));
  });
};

export const downloadAttachment = attachmentId => dispatch => {
  dispatch({type: REQUEST_DETAILS_ATTACHMENT_IS_DOWNLOADING, loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/attachment/${attachmentId}`
  })
  .done(url => {
    dispatch({type: REQUEST_DETAILS_ATTACHMENT_IS_DOWNLOADING, loading: false});
    if (url) {
      window.open(url);
    } else {
      dispatch(showError(`Could not get the URL to download attachment.`));
    }
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_ATTACHMENT_IS_DOWNLOADING, loading: false});
    dispatch(showError(`Could not download attachment.  ${getErrorMessage(error)}`));
  });
};

export const deleteAttachment = attachmentId => dispatch => {
  dispatch({type: REQUEST_DETAILS_ATTACHMENT_IS_DELETING, loading: true});
  $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/attachment/${attachmentId}`
  })
  .done(res => {
    const {request_statuses} = res;
    dispatch({type: REQUEST_DETAILS_ATTACHMENT_DELETE, attachmentId, request_statuses});
    dispatch(showSuccess('Attachment has been successfully deleted.'));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_ATTACHMENT_IS_DELETING, loading: false});
    dispatch(showError(`Could not delete attachment.  ${getErrorMessage(error)}`));
  });
};

export const updateRequestDwOperator = (dwOperator) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true});
  const {details} = getState().requestdetails;
  const {RequestID} = details;
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/dw_operator`,
    data: {
      dw_operator: dwOperator
    }
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess('The DW Operator has been updated.'));
    dispatch(changeRequestDetails({...details, DwOperator: dwOperator}));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const uploadCaptions = (file, params, callback) => (dispatch, getState) => {
  const {details} = getState().requestdetails;
  const {RequestID} = details;
  const {fileExt, label, language, originalFormat, convertToVtt} = params;
  dispatch({type: REQUEST_DETAILS_CAPTIONS_IS_UPLOADING, loading: true});
  const data = new FormData();
  data.append('file', file);
  data.append('label', label);
  data.append('language_id', language.LanguageID);
  !!originalFormat && data.append('original_format_id', originalFormat.FormatID);
  data.append('convert_to_vtt', convertToVtt);
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/captions`,
    data,
    cache: false,
    processData: false,
    contentType: false
  })
  .done(({captions_id, original_captions_id}) => {
    callback && callback();
    dispatch({type: REQUEST_DETAILS_CAPTIONS_ADD, data: {
      captions_id,
      original_captions_id,
      file_name: file.name,
      label,
      language_key: language.LanguageKey,
      language_code: language.LanguageCode,
      language_name: language.LanguageName,
      format: originalFormat ? originalFormat.FormatName : fileExt,
      file_ext_original: (original_captions_id ? fileExt : undefined),
      file_ext_main: (original_captions_id ? 'vtt' : fileExt),
    }});
    dispatch(showSuccess('Captions has been successfully uploaded.'));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_CAPTIONS_IS_UPLOADING, loading: false});
    dispatch(showError(`Could not upload captions.  ${getErrorMessage(error)}`));
  });
};

export const sendSupportEmailForCaptions = params => (dispatch, getState) => {
  const {details: {RequestID}} = getState().requestdetails;
  const {fileExt, language, originalFormat} = params;
  dispatch({type: REQUEST_DETAILS_CAPTIONS_FILTERS_IS_LOADING, loading: true});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/captions/support_email`,
    data: {
      file_ext: fileExt,
      format_name: originalFormat ? originalFormat.FormatName : undefined,
      language_code: language.LanguageCode,
    }
  })
  .done(() => {
    dispatch({type: REQUEST_DETAILS_CAPTIONS_FILTERS_IS_LOADING, loading: false});
    dispatch(showSuccess('Email has been successfully sent to support team!'));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_CAPTIONS_FILTERS_IS_LOADING, loading: false});
    dispatch(showError(`Could not send a support ticket.  ${getErrorMessage(error)}`));
  });
};

export const downloadCaptions = (captionsId, isOriginal) => dispatch => {
  dispatch({type: REQUEST_DETAILS_CAPTIONS_IS_DOWNLOADING, loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${isOriginal ? 'original_' : ''}captions/${captionsId}`
  })
  .done(url => {
    dispatch({type: REQUEST_DETAILS_CAPTIONS_IS_DOWNLOADING, loading: false});
    if (url) {
      window.open(url);
    } else {
      dispatch(showError(`Could not get the URL to download captions.`));
    }
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_CAPTIONS_IS_DOWNLOADING, loading: false});
    dispatch(showError(`Could not download captions.  ${getErrorMessage(error)}`));
  });
};

export const deleteCaptions = captionsId => dispatch => {
  dispatch({type: REQUEST_DETAILS_CAPTIONS_IS_DELETING, loading: true});
  $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/captions/${Math.abs(captionsId)}`
  })
  .done(() => {
    dispatch({type: REQUEST_DETAILS_CAPTIONS_DELETE, captionsId});
    dispatch(showSuccess('Captions has been successfully deleted.'));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_CAPTIONS_IS_DELETING, loading: false});
    dispatch(showError(`Could not delete captions.  ${getErrorMessage(error)}`));
  });
};

export const getFiltersForCaptions = () => dispatch => {
  dispatch({type: REQUEST_DETAILS_CAPTIONS_FILTERS_IS_LOADING, loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/captions/filters`
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_CAPTIONS_FILTERS, data: res});
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_CAPTIONS_FILTERS_IS_LOADING, loading: false});
    dispatch(showError(`Could not get dictionaries for captions.  ${getErrorMessage(error)}`));
  });
};

export const getProjectWorkOrderList = () => (dispatch, getState) => {
  const {details} = getState().requestdetails;
  const {QcProjectID} = details;
  dispatch({type: REQUEST_DETAILS_GET_PROJECT_WORK_ORDER_LIST, loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/project/${QcProjectID}/work_orders`
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_GET_PROJECT_WORK_ORDER_LIST, loading: false, work_orders: res.workOrderList, delivery_categories: res.deliveryCategoryList});
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_GET_PROJECT_WORK_ORDER_LIST, loading: false});
    dispatch(showError(`Could not get a list of work orders.  ${getErrorMessage(error)}`));
  });
};

export const createDeliveryWorkOrder = deliveryRequestData => (dispatch, getState) => {
  const {details} = getState().requestdetails;
  const {QcProjectID} = details;
  dispatch({type: REQUEST_DETAILS_GET_PROJECT_WORK_ORDER_LIST, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/delivery_wo`,
    data: JSON.stringify({...deliveryRequestData, projectID: QcProjectID})
  })
  .done(() => {
    dispatch({type: REQUEST_DETAILS_GET_PROJECT_WORK_ORDER_LIST, loading: false});
    dispatch(showSuccess('Delivery work order has been successfully created!'));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_GET_PROJECT_WORK_ORDER_LIST, loading: false});
    let errorMessage = getErrorMessageByCode(error, ErrorCodes.CANNOT_CREATE_WO_BY_BAD_TEMPLATE);
    if (errorMessage) {
      dispatch(showError('Cannot create delivery work order.  The work order template is missing or incomplete.'));
    } else {
      errorMessage = getErrorMessageByCode(error, ErrorCodes.OFFICE_FLAG_MISSING)
      if (errorMessage) {
        dispatch(showError('Cannot create delivery work order.  Please select an office.'));
      } else {
        dispatch(showError(`Could not create delivery work order.  ${getErrorMessage(error)}`));
      }
    }
  });
};

export const getWatermarkedProxies = withUsers => (dispatch, getState) => {
  const {details: {RequestID}, detailsFilters} = getState().requestdetails;
  dispatch({type: REQUEST_DETAILS_PROXIES_IS_LOADING, loading: true});
  if (!withUsers) {
    withUsers = detailsFilters.users_for_proxies === undefined;
  }
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/watermarked-proxies`,
    data: {request_id: RequestID, with_users: withUsers}
  })
  .done(res => {
    dispatch({
      type: REQUEST_DETAILS_PROXIES_RECEIVE,
      ...res,
      withUsers
    });
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_PROXIES_IS_LOADING, loading: false});
    console.log(`Could not get watermarked proxies.  ${getErrorMessage(error)}`);
  });
};

export const createWatermarkedProxy = (userId, isSeasonRequest, selectedEpReqIDs) => (dispatch, getState) => {
  const {details: {RequestID}} = getState().requestdetails;
  dispatch({type: REQUEST_DETAILS_PROXIES_IS_LOADING, loading: true});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/watermarked-proxies`,
    data: {
      request_id: RequestID,
      user_id: userId,
      episode_request_ids: isSeasonRequest ? selectedEpReqIDs : undefined,
    }
  })
  .done(() => {
    dispatch({type: REQUEST_DETAILS_PROXIES_CREATE, userId});
    dispatch(getWatermarkedProxies());
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_PROXIES_IS_LOADING, loading: false});
    dispatch(showError(`Could not create proxy.  ${getErrorMessage(error)}`));
    !!isSeasonRequest && dispatch(getWatermarkedProxies());
  });
};

export const deleteWatermarkedProxy = id => dispatch => {
  dispatch({type: REQUEST_DETAILS_PROXIES_IS_LOADING, loading: true});
  $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/watermarked-proxies/${id}`
  })
  .done(() => {
    dispatch({type: REQUEST_DETAILS_PROXIES_DELETE, id});
    dispatch(showSuccess('Proxy has been successfully deleted.'));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_PROXIES_IS_LOADING, loading: false});
    dispatch(showError(`Could not delete proxy.  ${getErrorMessage(error)}`));
  });
};

export const loadProxiesFromAnotherRequests = openReusing => (dispatch, getState) => {
  const {details: {RequestID}} = getState().requestdetails;
  return dispatch(getAvailableRawProxies(RequestID))
  .then(() => getState().requestdetails.details)
  .then(details => {
    const proxy = (details.VersionsForProxies || []).find(v => v.RequestID === RequestID && !!v.InputFileID);
    if (proxy) {
      dispatch(changeRequestDetails({...details, ownRawProxyS3FileName: proxy.ObjectKey, has_raw_proxy: true}));
      dispatch(getWatermarkedProxies(true));
      dispatch(showInfo('The request has its own proxy'));
    } else if (!(details.VersionsForProxies || []).find(v => v.RequestID !== RequestID && !!v.InputFileID)) {
      dispatch(showInfo('No other request in this QC project has a proxy'));
    } else {
      openReusing();
    }
  });
};

export const setProxyFromAnotherRequest = anotherRequest => (dispatch, getState) => {
  const {details} = getState().requestdetails;
  const {RequestID, VersionsForProxies} = details;
  dispatch({type: REQUEST_DETAILS_PROXY_IS_LOADING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/proxy_from/${anotherRequest ? anotherRequest.RequestID : 'reset'}`
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_PROXY_IS_LOADING, loading: false});
    dispatch(showSuccess(`Proxy has been successfully ${anotherRequest ? '' : 'un'}joined!`));
    ((VersionsForProxies || []).find(v => v.RequestID === RequestID) || {}).InputFileID = (anotherRequest ? anotherRequest.InputFileID : null);
    dispatch(changeRequestDetails({...details, VersionsForProxies}));
    dispatch({
      type: REQUEST_DETAILS_PROXIES_RECEIVE,
      ...res,
      withUsers: true
    });
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_PROXY_IS_LOADING, loading: false});
    dispatch(showError(`Could not ${anotherRequest ? '' : 're'}set proxy${anotherRequest ? ' from another request' : ''}.  ${getErrorMessage(error)}`));
  });
};

export const searchArtworkProxies = woMemfis => dispatch => {
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/artwork_proxies`,
    data: {
      wo_memfis: woMemfis
    }
  })
  .fail(error => {
    dispatch(showError(`Could not found proxies.  ${getErrorMessage(error)}`));
  });
};

export const updateSingleClientUserPermission = (userType, userId, companyId, permissionType, onlyThisProject) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true, savingParam: 'UserPermissions'});
  const {details} = getState().requestdetails;
  const {QcProjectID, UserPermissions} = details;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/project/${QcProjectID}/user_permissions`,
    data: {
      only_this_project: onlyThisProject,
      user_id: userId,
      company_id: companyId,
      permission_type: permissionType
    }
  })
  .done(() => {
    try {
      const permission = UserPermissions[userType].find(item => item.UserID === userId && item.CompanyID === companyId);
      permission.PermissionType = permissionType;
      permission.NeedToConfirm = 0;
    } catch {}
    dispatch(changeRequestDetails({...details, UserPermissions}));
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess('The user permission has been updated.'));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(`Could not update user permission.  ${getErrorMessage(error)}`));
  });
};

export const updateAllCompanyUsersPermission = (userType, companyId, permissionType, onlyThisProject) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true, savingParam: 'UserPermissions'});
  const {details} = getState().requestdetails;
  const {QcProjectID, UserPermissions} = details;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/project/${QcProjectID}/user_permissions`,
    data: {
      only_this_project: onlyThisProject,
      company_id: companyId,
      permission_type: permissionType
    }
  })
  .done(() => {
    try {
      UserPermissions[userType].forEach(item => {
        if (item.CompanyID === companyId) {
          item.PermissionType = permissionType;
          item.NeedToConfirm = 0;
        }
      });
    } catch {}
    dispatch(changeRequestDetails({...details, UserPermissions}));
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess('The user permission has been updated.'));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(`Could not update user permission.  ${getErrorMessage(error)}`));
  });
};

export const updateShowManagers = (userType, companyId, userIDs) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true, savingParam: 'UserPermissions'});
  const {details} = getState().requestdetails;
  const {QcProjectID, UserPermissions} = details;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/project/${QcProjectID}/show_managers`,
    data: {
      company_id: companyId,
      user_id: userIDs
    }
  })
  .done(() => {
    try {
      UserPermissions[userType].forEach(item => {
        if (item.CompanyID === companyId) {
          if (userIDs.includes(item.UserID)) {
            item.PermissionType = UserPermission.CAN_MANAGE;
            item.NeedToConfirm = 0;
          } else if (item.PermissionType === UserPermission.CAN_MANAGE) {
            item.PermissionType = UserPermission.CAN_VIEW;
          }
        }
      });
    } catch {}
    dispatch(changeRequestDetails({...details, UserPermissions}));
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess('The show managers has been updated.'));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(`Could not update show managers.  ${getErrorMessage(error)}`));
  });
};

// update finishing houses
export const updateRequestStudioPartners = (label, name, value, updateType) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true});
  const {details} = getState().requestdetails;
  const {RequestID} = details;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/studio_partners/${name.replace('ID', '').toLowerCase()}`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({partners: value, update_type: updateType})
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess(`The "${label}" has been updated.`));
    dispatch(changeRequestDetails({...details, [name]: value, ...res}));
    dispatch(getWatermarkedProxies(true));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(`Could not update ${label}.  ${getErrorMessage(error)}`));
  });
};

export const showEditCompanyModal = (fieldName) => ({type: REQUEST_DETAILS_EDIT_COMPANY_FIELD_NAME, fieldName});
export const closeEditCompanyModal = () => ({type: REQUEST_DETAILS_EDIT_COMPANY_FIELD_NAME, fieldName: null});

// update productions
export const updateRequestProductions = (label, name, value, params) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true});
  const {details} = getState().requestdetails;
  const {RequestID} = details;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/${name.replace('ID', '').toLowerCase()}`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      partners: value,
      ...params
    })
  })
  .done(res => {
    dispatch(showSuccess(`The "${label}" has been updated.`));
    if (params.distributor_id || params.qc_profile_id) {
      dispatch(getRequestDetails(null, RequestID, true));
    } else {
      dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
      const newDetails = {...details, ...res};
      dispatch(changeRequestDetails(newDetails));
      dispatch(getFullDictionaries(newDetails));
      dispatch(getWatermarkedProxies(true));
      dispatch(closeEditCompanyModal());
    }
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(`Could not update ${label}.  ${getErrorMessage(error)}`));
  });
};

// update studio
export const updateRequestStudio = (label, name, value, params) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true});
  const {details} = getState().requestdetails;
  const {RequestID} = details;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/${name.replace('ID', '').toLowerCase()}`,
    data: {
      [name]: value || 0,
      ...params
    }
  })
  .done(res => {
    dispatch(showSuccess(`The "${label}" has been updated.`));
    if (params.distributor_id || params.qc_profile_id) {
      dispatch(getRequestDetails(null, RequestID, true));
    } else {
      dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
      const newDetails = {...details, ...res};
      dispatch(changeRequestDetails(newDetails));
      dispatch(getFullDictionaries(newDetails));
      if ((res || {})[name] === value) {
        dispatch(getWatermarkedProxies(true));
      }
      dispatch(closeEditCompanyModal());
    }
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(`Could not update ${label}.  ${getErrorMessage(error)}`));
  });
};

export const showSeasonStatusPopup = () => ({type: REQUEST_DETAILS_TOGGLE_SEASON_STATUS_POPUP, isShow: true});
export const closeSeasonStatusPopup = () => ({type: REQUEST_DETAILS_TOGGLE_SEASON_STATUS_POPUP, isShow: false});

export const getSeasonStatus = (seasonRequestId) => dispatch => {
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${seasonRequestId}/season_status`
  });
};

export const updatePostExecutiveUser = postExecutiveUserId => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true});
  const {details} = getState().requestdetails;
  const {RequestID} = details;
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/post_executive_user`,
    data: {
      post_executive_user_id: postExecutiveUserId
    }
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess('The post executive user has been updated.'));
    dispatch(changeRequestDetails({...details, PostExecutiveUserID: postExecutiveUserId}));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const updateProjectGroupPendingApprovalMinSeverity = (label, name, value) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true, savingParam: 'PendingApprovalMinSeverity'});
  const {details} = getState().requestdetails;
  const {ProjectGroupId, StudioID} = details;
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/project_group/${ProjectGroupId}/pending_approval/min_severity`,
    data: {
      request_studio_id: StudioID,
      min_severity_type_id: value
    }
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess(`The ${label} has been updated.`));
    dispatch(changeRequestDetails({...details, [name]: value, ...res}));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const updateProjectGroupPendingApprovalStudioPartnerTypes = (label, name, value) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true, savingParam: 'PendingApprovalStudioPartnerTypes'});
  const {details} = getState().requestdetails;
  const {ProjectGroupId, StudioID} = details;
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/project_group/${ProjectGroupId}/pending_approval/studio_partner_type`,
    data: {
      request_studio_id: StudioID,
      company_type_id: value
    }
  })
  .done(res => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess(`The ${label} has been updated.`));
    dispatch(changeRequestDetails({...details, [name]: value, ...res}));
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const showEditWoStepsModal = () => ({type: REQUEST_DETAILS_TOGGLE_WO_STEPS_MODAL, mode: 'editing'});

export const closeWoStepsModal = (needToReloadCost) => (dispatch, getState) => {
  const {details: {RequestID}} = getState().requestdetails;
  if (needToReloadCost) {
    dispatch(getRequestSteps());
    dispatch(getCostDetails(RequestID, true));
  }
  dispatch({type: REQUEST_DETAILS_TOGGLE_WO_STEPS_MODAL});
};

export const updateRequestSteps = (steps, callback) => (dispatch, getState) => {
  dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: true});
  const {details: {RequestID}} = getState().requestdetails;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${RequestID}/wo_steps`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(steps)
  })
  .done(() => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showSuccess('Steps have been successfully changed'));
    callback && callback();
    dispatch(getRequestSteps());
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_IS_SAVING, saving: false});
    dispatch(showError(`Could not update steps.  ${getErrorMessage(error)}`));
  });
};

export const getDictionariesForEditSteps = () => (dispatch, getState) => {
  const {details} = getState().requestdetails;
  const {QcProfileID} = details;
  dispatch({type: REQUEST_DETAILS_GET_DICTIONARIES_FOR_EDIT_STEPS, loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/edit_steps_data`,
    data: {qc_profile_id: QcProfileID}
  })
  .done(res => {
    dispatch({
      type: REQUEST_DETAILS_GET_DICTIONARIES_FOR_EDIT_STEPS,
      loading: false,
      filters: res
    });
  })
  .fail(error => {
    dispatch({type: REQUEST_DETAILS_GET_DICTIONARIES_FOR_EDIT_STEPS, loading: false});
    dispatch(showError(`Could not get dictionaries for edit the steps.  ${getErrorMessage(error)}`));
  });
};

export const getCommonSteps = (commonStepType, setLoadingCommonSteps) => dispatch => {
  setLoadingCommonSteps(true);
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/common_steps`,
    type: 'GET',
    data: {
      type: commonStepType
    }
  })
  .always(() => {
    setLoadingCommonSteps(false);
  })
  .fail(error => {
    dispatch(showError(getErrorMessage(error) || 'Could not get common steps.'));
  });
};
