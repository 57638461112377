import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SelectAdvanced from '../../../../components/forms/SelectAdvanced';
import {Row, Col, Button} from 'react-bootstrap';
import {
  QC_REQUEST_TYPES, CONTENT_TYPES, QC_REQUEST_TYPE_NEW_QC_REQUEST, QC_REQUEST_TYPE_REPLACEMENT, QC_REQUEST_TYPE_EPISODE
} from '../../../NewRequest/constants';
import classNames from 'classnames';
import _ from 'lodash';
import newRequestImg from '../../../../../assets/images/new-request.svg';
import replacementRequestImg from '../../../../../assets/images/replacement-request.svg';
import newEpisodeImg from '../../../../../assets/images/new-episode.svg';
import {isStudioPartnerOrManagerAsSuchUser, getOptionName, isStudioUser, isStudioPartnerUser} from '../../../../utils';

class Choices extends Component {
  convertToNumber(value) {
    return isNaN(Number(value)) ? value : Number(value);
  }

  handleSelect = (name, value) => {
    let needToRerender = false;
    const {onSelect, data, filters} = this.props;
    onSelect(name, value);
    if (name === 'request_type_id' && value > QC_REQUEST_TYPES.NewQcRequest.id) {
      if (data.request_type_id) {
        setTimeout(this.handleContinue, 10);
      } else {
        this.handleContinue();
      }
    } else if (name === 'content_type_id') {
      onSelect('studio_id', null);
      onSelect('series_id', null);
      onSelect('season_name', null);
      onSelect('trailer_episode_name', null);
      needToRerender = true;
    } else if (name === 'series_id') {
      let studioId;
      if (value) {
        studioId = (filters.series.find(s => s.series_id === value) || {}).studio_id;
      }
      onSelect('studio_id', studioId || null);
      if (data.content_type_id === CONTENT_TYPES.Season) {
        onSelect('season_name', null);
        needToRerender = true;
      } else if (data.content_type_id === CONTENT_TYPES.Trailer) {
        onSelect('trailer_episode_name', null);
        needToRerender = true;
      }
    }
    if (needToRerender) {
      this.forceUpdate();
    }
  };

  handleContinue = () => {
    const {onSelect, onContinue, data} = this.props;
    if (data.content_type_id === CONTENT_TYPES.Season) {
      const seasonName = String(data.season_name).trim();
      const seasonNum = seasonName.match(/^\d+$/) ? this.convertToNumber(seasonName) : seasonName;
      let {season} = data;
      if (!season || season.season_number !== seasonNum) {
        season = {
          season_number: seasonNum,
          total_episodes: 1,
          episodes: []
        };
      }
      onSelect('season', season);
      onSelect('general', {allow_interactive_report: true});
      onSelect('season_name', seasonName);
    } else {
      onSelect('season', null);
      if (data.content_type_id === CONTENT_TYPES.Feature || data.content_type_id === CONTENT_TYPES.Trailer) {
        onSelect('general', {allow_interactive_report: true, runtime_in_sec: null, runtime_in_sec__textless: null});
      } else if (data.request_type_id === QC_REQUEST_TYPES.Episode.id) {
        onSelect('general', {allow_interactive_report: true, runtime_in_sec: null, episode_number: null, episode_name: null});
      } else {
        onSelect('general', null);
      }
    }
    typeof data.series_id === 'string' && onSelect('series_id', data.series_id.trim());
    onContinue();
  };

  getRequestTypeClassNames(typeId, selectedTypeId) {
    return classNames({
      'request-type-item': true,
      'request-type-new-qc-request': typeId === QC_REQUEST_TYPE_NEW_QC_REQUEST,
      'request-type-replacement': typeId === QC_REQUEST_TYPE_REPLACEMENT,
      'request-type-new-episode': typeId === QC_REQUEST_TYPE_EPISODE,
      selected: typeId === selectedTypeId,
      'not-selected': selectedTypeId && typeId !== selectedTypeId
    });
  }

  getRequestTypeImgClassNames = typeId => {
    return classNames({
      'request-type-img': true,
      'img-new-qc-request': typeId === QC_REQUEST_TYPE_NEW_QC_REQUEST,
      'img-replacement': typeId === QC_REQUEST_TYPE_REPLACEMENT,
      'img-new-episode': typeId === QC_REQUEST_TYPE_EPISODE
    });
  };

  prepareSeriesOptions = series => {
    const {filters, data} = this.props;
    if (isStudioPartnerOrManagerAsSuchUser(data)) {
      return series.map(item => ({...item, studio_name: getOptionName(filters.studios, item.studio_id)}));
    }
    return series;
  };

  render() {
    const {data, filters} = this.props;
    const seasons = _.compact(((filters.series || []).find(i => i.series_id === data.series_id) || {}).seasons || []);

    const requestTypesImages = {
      1: newRequestImg,
      2: replacementRequestImg,
      3: newEpisodeImg
    };

    const seriesOptions = this.prepareSeriesOptions(filters.series || []);

    const requestTypesForWizard = {...QC_REQUEST_TYPES};
    if (isStudioUser() || isStudioPartnerUser()) {
      delete requestTypesForWizard.Replacement
    }

    return (
      <div className="create-request-as">
        {
          Object.values(requestTypesForWizard).map((type, i) => (
            <div
              key={`req-type-${i}`}
              className={this.getRequestTypeClassNames(type.id, data.request_type_id)}
              onClick={() => data.request_type_id === type.id ? {} : this.handleSelect('request_type_id', type.id)}
              >
              {data.request_type_id === type.id ? <span className="icon-circle-check"/> : null}
              <img
                className={this.getRequestTypeImgClassNames(type.id)}
                src={requestTypesImages[type.id]}
                alt=""
                />
              <div className="request-type-name">{type.title}</div>
              {
                (data.request_type_id !== type.id || data.request_type_id !== QC_REQUEST_TYPES.NewQcRequest.id) ? (
                  <div className="request-type-desc">
                    {type.description}
                  </div>
                ) : (
                  <div className="request-type-selects">
                    <Row>
                      <Col md={6}>
                        <SelectAdvanced
                          label="Content Type"
                          labelSeparator=""
                          name="content_type_id"
                          options={filters.content_types.filter(ct => ct.value !== CONTENT_TYPES.Episode)}
                          value={data.content_type_id}
                          placeholder=""
                          onSelect={this.handleSelect}
                          />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <SelectAdvanced
                          label={`${data.content_type_id === CONTENT_TYPES.Season ? 'Series ' : ''}Title`}
                          labelSeparator=""
                          name="series_id"
                          options={seriesOptions}
                          optionRenderer={option => {
                            const {series_name, studio_name} = option;
                            if (studio_name) {
                              return <>{series_name}<i className="in-wizard">Studio: {studio_name}</i></>
                            }
                            return series_name;
                          }}
                          value={data.series_id}
                          placeholder="Select or type to create new"
                          onSelect={this.handleSelect}
                          valueKey="series_id"
                          labelKey="series_name"
                          creatable
                          />
                      </Col>
                    </Row>
                    {
                      data.content_type_id === CONTENT_TYPES.Season ? (
                        <Row>
                          <Col md={6}>
                            <SelectAdvanced
                              label="Select Season"
                              labelSeparator=""
                              name="season_name"
                              options={seasons}
                              value={data.season_name}
                              placeholder="Select or type to create new"
                              onSelect={this.handleSelect}
                              creatable
                              />
                          </Col>
                        </Row>
                      ) : null
                    }
                    <Row>
                      <Col md={12}>
                        <Button
                          bsStyle="primary"
                          disabled={!data.content_type_id || !data.series_id ||
                            (data.content_type_id === CONTENT_TYPES.Season && (!data.season_name || !String(data.season_name).trim()))}
                          onClick={this.handleContinue}
                          >
                          Continue
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )
              }
            </div>
          ))
        }
      </div>
    );
  }
}

Choices.propTypes = {
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired
};

Choices.defaultProps = {
  data: {},
  onSelect: () => {},
  onContinue: () => {}
};

export default Choices;
