import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../components/Loader';
import Table from '../../../components/table/CollapsedTable';
import {TimeCodeCell} from './table/TimeCodeCell';
import {DurationCell} from './table/DurationCell';
import {ActionsCell} from './table/ActionsCell';
import {getSortedRowsBy} from '../../../utils';
import StyledMobileTable from './IssueTableStyledMobile';
import CollapsedRow from './table/CollapsedRow';
import ArrowCell from './table/ArrowCell';
import {SeverityCell} from './table/SeverityCell';
import {IssueStatusesOptions} from '../utils';

const ClientStatusCell = React.memo(({value, row, className}) => {
  const clientStatus = (IssueStatusesOptions.find(i => i.value === row.ClientStatusID) || {name: ''}).name;
  return (<td className={`${className || ''}${clientStatus ? '' : ' empty--cell'}`} title="Client Status">
    {clientStatus || '-'}
  </td>)
})

class IssueTable extends Component {
  handleClickIssueImage = issue => {
    this.props.onClickImage(issue);
  };

  handleClickDiscussIssue = issue => {
    this.props.onClickDiscuss(issue);
  };

  handleClickFixNotesIssue = issue => {
    this.props.onClickFixNotes(issue);
  };

  handleClickViewIssue = issue => {
    this.props.onClickView(issue);
  };

  handleClickEditIssue = issue => {
    this.props.onClickEdit(issue);
  }

  handleClickDeleteIssue = issue => {
    this.props.onClickDelete(issue);
  }

  handleClickUndeleteIssue = issue => {
    this.props.onClickUndelete(issue);
  }

  /*
  The mobile version for this table shows the title from the style,
  check them for custom td, if you change something in the table
  */
  headers = {
    Timecode: {
      title: 'Timeсode',
      component: TimeCodeCell
    },
    Duration: {
      title: 'Duration',
      component: DurationCell
    },
    LocationAndChannel: {
      title: 'Location',
      className: 'location'
    },
    Category: {
      title: 'Category',
      className: 'capitalized-all'
    },
    IssueType: {
      title: 'Type',
      className: 'capitalized-all'
    },
    Description: {
      title: 'Description',
      className: 'description capitalized-all'
    },
    Severity: {
      title: 'Severity',
      component: SeverityCell
    },
    Status: {
      title: 'Status'
    },
    ClientStatus: {
      title: 'Client Status',
      className: 'client_status',
      component: ClientStatusCell
    },
    actions: {
      title: '',
      className: 'actions',
      component: ActionsCell,
      componentArgs: {
        canComment: this.props.canComment,
        onClickDiscuss: this.handleClickDiscussIssue,
        onClickImage: this.handleClickIssueImage
      }
    },
    rowArrow: {
      title: '',
      className: 'arrow-cell',
      component: ArrowCell,
      componentArgs: {
        collapsedEl: this.getRowCollapsed()
      }
    }
  };

  sortAltFields = {
    Timecode: 'TimeCodeStart',
    Severity: 'SeverityPriority'
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      tableSort: props.sort || {},
      showCollapsedRowId: null
    };
  }

  componentDidUpdate(prevProps) {
    if ((JSON.stringify(this.props) !== JSON.stringify(prevProps))) {
      this.setState({
        showCollapsedRowId: null
      });
    }
  }

  handleShowCollapsedRow = id => {
    this.setState({
      showCollapsedRowId: id === this.state.showCollapsedRowId ? null : id
    });
  };

  handleHeaderCellClick = (field, direction) => {
    if (field !== 'actions' && field !== 'rowArrow') {
      this.setState({
        showCollapsedRowId: null,
        tableSort: {field, direction}
      });
    }
  };

  getStatus(row) {
    return {
      1: 'type-low',
      2: 'type-low',
      3: 'type-middle',
      4: 'type-middle',
      5: 'type-hight'
    }[row.SeverityTypeID];
  }

  getRowClassNames = row => {
    return [this.getStatus(row), row.IsDeleted ? 'issue-deleted' : ''].filter(Boolean).join(' ') || undefined;
  };

  getRowCollapsed(isHiddenXS) {
    const {isEnabledInteractiveView, onClickEdit, onClickDelete, canManage, canComment, onClickUndelete, allowEditOnRequest
    } = this.props;
    return {
      component: CollapsedRow,
      isHiddenXS,
      componentArgs: {
        allowEditOnRequest,
        canComment,
        canManage,
        onClickDiscuss: this.handleClickDiscussIssue,
        onClickFixNotes: this.handleClickFixNotesIssue,
        onClickView: this.handleClickViewIssue,
        onClickEdit: onClickEdit ? this.handleClickEditIssue : undefined,
        onClickDelete: onClickDelete ? this.handleClickDeleteIssue : undefined,
        onClickUndelete: onClickUndelete ? this.handleClickUndeleteIssue : undefined,
        isEnabledInteractiveView
      }
    };
  }

  render() {
    const {loading, data, issueBorderWidth, emptyText} = this.props;
    const {tableSort, showCollapsedRowId} = this.state;

    return (
      <div className="table-contaner midl_in">
        {loading ? <Loader/> : null}
        <StyledMobileTable issueBorderWidth={issueBorderWidth}>
          <Table
            isSeparatedRows
            headers={this.headers}
            rows={getSortedRowsBy(data, tableSort.field, tableSort.direction, this.sortAltFields)}
            sort={tableSort}
            onHeaderCellClick={this.handleHeaderCellClick}
            getRowClassNames={this.getRowClassNames}
            rowCollapsedEl={this.getRowCollapsed(true)}
            onRowClick={this.handleShowCollapsedRow}
            showCollapsedRowId={showCollapsedRowId}
            emptyText={emptyText}
            />
        </StyledMobileTable>
      </div>
    );
  }
}

IssueTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  issueBorderWidth: PropTypes.string,
  allowEditOnRequest: PropTypes.bool,
  canManage: PropTypes.bool.isRequired,
  canComment: PropTypes.bool.isRequired,
  onClickFixNotes: PropTypes.func.isRequired,
  onClickDiscuss: PropTypes.func.isRequired,
  onClickView: PropTypes.func.isRequired,
  onClickImage: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickUndelete: PropTypes.func,
  isEnabledInteractiveView: PropTypes.bool.isRequired,
  sort: PropTypes.object,
  emptyText: PropTypes.string
};

export default IssueTable;
