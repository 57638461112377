import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

class ViewAllLink extends Component {
  render() {
    const {total, to, onClick} = this.props;
    return total > 3 ?
      <StyledLink to={to} className="view-all" onClick={() => onClick(to)}>View All</StyledLink> : null;
  }
}

const StyledLink = styled(Link)`
  margin: 23px 0;
  width: 84px;
  display: block;
  text-align: center;
  padding: 6px 0;
  background: #F2F2F2;
  border-radius: 100px;
  font-size: 10px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.7);
  &:hover, &:active {
    color: #000;
    background: #dcdcdc;
  }
  .sub-table & {
    width: 78px;
    background: #4E4E4E;
    color: #fff;
    font-weight: bold;
    line-height: 13px;
    text-transform: uppercase;
    &:hover {
      background: #353435;
    }
  }
  .scope-section-body.requests & {
    margin-left: 31px;
  }

  @media (min-width: 768px) {
    .scope-section-header & {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .scope-section-body .table-block + & {
      display: none;
    }
  }
`;

ViewAllLink.propTypes = {
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired
};

export default ViewAllLink;
