import {USER_SET_IS_LOGOUT} from '../../pages/Login/actions';
import {
  SEASON_DETAILS_CLEAR, SEASON_DETAILS_IS_LOADING, SEASON_DETAILS_GET_LIST, SEASON_DETAILS_SELECT_SEASON,
  SEASON_DETAILS_UPDATE_EDIT_MODAL, SEASON_DETAILS_UPDATE_EPISODE_MILESTONES, SEASON_DETAILS_LOCK_EPISODE,
  SEASON_DETAILS_SET_ACTIVE_TAB,
  SEASON_DETAILS_CS_CREATE_REQ_MODAL_SHOW, SEASON_DETAILS_CS_CREATE_REQ_MODAL_HIDE
} from './actions';

const initialState = {
  loading: false,
  activeTab: 1,
  details: {
    scheduling_headers: [],
    episodes: []
  },
  selectedSeasonId: null,
  editDateModal: {
    show: false,
    qcProjectId: null,
    schedulingId: null,
    position: {},
    selectedDate: null,
    minDate: null,
    maxDate: null,
    updatePrevDates: false,
    updateNextDates: false
  },
  csCreateRequestModalData: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEASON_DETAILS_SET_ACTIVE_TAB: {
      return {...state, activeTab: action.activeTab}
    }

    case USER_SET_IS_LOGOUT:
    case SEASON_DETAILS_CLEAR: {
      return {...initialState};
    }

    case SEASON_DETAILS_IS_LOADING: {
      return {
        ...state,
        loading: action.loading
      }
    }

    case SEASON_DETAILS_GET_LIST: {
      return {
        ...state,
        details: action.details
      }
    }

    case SEASON_DETAILS_SELECT_SEASON: {
      return {
        ...state,
        activeTab: 1,
        selectedSeasonId: +action.selectedSeasonId
      }
    }

    case SEASON_DETAILS_UPDATE_EDIT_MODAL: {
      return {
        ...state,
        editDateModal: {
          ...state.editDateModal,
          ...action.values
        }
      }
    }

    case SEASON_DETAILS_UPDATE_EPISODE_MILESTONES: {
      return {
        ...state,
        details: {
          ...state.details,
          episode_project_list: state.details.episode_project_list.map(episode => episode.ProjectID === action.qcProjectId ?
            {
              ...episode,
              milestones: action.milestones
            } : episode
          )
        }
      }
    }

    case SEASON_DETAILS_LOCK_EPISODE: {
      return {
        ...state,
        details: {
          ...state.details,
          episode_project_list: state.details.episode_project_list.map(episode => episode.ProjectID === action.qcProjectId ?
            {
              ...episode,
              lock_scheduling: 1
            } : episode
          )
        }
      }
    }

    case SEASON_DETAILS_CS_CREATE_REQ_MODAL_SHOW: {
      return {
        ...state,
        csCreateRequestModalData: action.data || initialState.csCreateRequestModalData
      }
    }

    case SEASON_DETAILS_CS_CREATE_REQ_MODAL_HIDE: {
      return {
        ...state,
        csCreateRequestModalData: initialState.csCreateRequestModalData
      }
    }

    default:
      return state;
  }
}
