import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Switch from 'react-switch';

class SwitchAdvanced extends Component {
  render() {
    const {value, name, label, className, onChange, boxShadow, activeBoxShadow, offColor, onColor, offHandleColor,
      onHandleColor, handleDiameter, height, width, title
    } = this.props;
    return (
      <StyledSwitch className={`switch-adv ${className}`}>
        <div className="switch-field" title={title}>
          <Switch
            onChange={v => onChange(name, v)}
            checked={value}
            className={`react-switch${value ? ' checked' : ''}`}
            uncheckedIcon={false}
            checkedIcon={false}
            offColor={offColor || '#F6F6F6'}
            onColor={onColor || '#FFFFFF'}
            offHandleColor={offHandleColor || '#A9A9A9'}
            onHandleColor={onHandleColor || '#7FCF18'}
            boxShadow={boxShadow}
            activeBoxShadow={activeBoxShadow || '0px 0px 1px 1px transparent'}
            handleDiameter={handleDiameter || 20}
            height={height || 24}
            width={width || 37}
            />
          {label ? <span>{label}</span> : null}
        </div>
      </StyledSwitch>
    );
  }
}

const StyledSwitch = styled.div`
  &.allow-uploads-via-qc-portal {
    background-color: #f9f9f9;
    padding-top: 14px;
    padding-bottom: 14px;
  }

  &.upload-channels {
    border-top: 0.5px solid #C1C1C1;
    padding-top: 13px;
    padding-bottom: 13px;

    &.signiant-media-shuttle-app {
      border-bottom: 0.5px solid #C1C1C1;
    }
  }

  .switch-field {
    .react-switch + span {
      font-weight: normal;
      line-height: 24px;
      padding-left: 8px;
      opacity: 1;
    }
  }
`;

SwitchAdvanced.propTypes = {
  value: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  boxShadow: PropTypes.string,
  activeBoxShadow: PropTypes.string,
  offColor: PropTypes.string,
  onColor: PropTypes.string,
  offHandleColor: PropTypes.string,
  onHandleColor: PropTypes.string,
  handleDiameter: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  title: PropTypes.string,
};

SwitchAdvanced.defaultProps = {
  value: false,
  name: '',
  className: '',
  onChange: () => {}
};

export default SwitchAdvanced;
