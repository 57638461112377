/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {getCostDetails, getDictionariesForWoEditSteps} from '../../actions';
import Loader from '../../../../components/Loader';
import EditWorkOrderStepsTable from '../../../RequestDetails/components/editWorkOrderStepsTable/EditWorkOrderStepsTable';

function CsWorkOrderSteps(props) {
  const {data, onChange} = props;
  const {origin_request_id, replacement, qc_profile_id} = data;

  const [isLoading, setLoading] = useState(false);
  const [woSteps, setWoSteps] = useState(replacement.wo_steps || []);
  const [isLoadingDictionaries, setLoadingDictionaries] = useState(false);
  const [dictionaries, setDictionaries] = useState({});

  const hasStepsForRequest = woSteps.some(s => s.origin_request_id === origin_request_id);

  const handleChangeSteps = (newSteps) => {
    onChange('replacement', {...replacement, wo_steps: newSteps.map(s => ({...s, origin_request_id}))});
  };

  useEffect(() => {
    if (!hasStepsForRequest) {
      setLoading(true);
      setWoSteps([]);
      handleChangeSteps([]);
      getCostDetails({origin_request_id}, true).then(
        res => {
          const {success, cost_details} = res || {};
          setLoading(false);
          const costData = success && cost_details && cost_details.length ? cost_details[0] : null;
          const stepsFromCostDetails = ((!costData || !costData.success) ? [] : (costData.step_subtypes || [])).map((s, i) => ({...s, origin_request_id, StepOrder: i + 1}));
          setWoSteps(stepsFromCostDetails);
          handleChangeSteps(stepsFromCostDetails);
        },
        error => {
          console.log(error);
          setLoading(false);
        }
      );
    }
  }, [origin_request_id, hasStepsForRequest]);

  useEffect(() => {
    setLoadingDictionaries(true);
    getDictionariesForWoEditSteps(data).then(
      res => {
        setLoadingDictionaries(false);
        setDictionaries(res || {});
      },
      error => {
        console.log(error);
        setLoadingDictionaries(false);
        setDictionaries({});
      }
    );
  }, [qc_profile_id]);

  return (
    <StyledStepBodyWrap className="step-body-wrapper cs-wo-steps">
      {(isLoading || isLoadingDictionaries) && <Loader className="full-screen"/>}
      <EditWorkOrderStepsTable
        loading={isLoading || isLoadingDictionaries}
        filters={dictionaries}
        storedSteps={[]}
        steps={replacement.wo_steps || []}
        onChangeSteps={handleChangeSteps}
        isCreatedWO={false}
      />
    </StyledStepBodyWrap>
  );
}
const StyledStepBodyWrap = styled.div`
  .table {
    tr {
      th, td {
        &.status, &.time-spent {
          display: none;
        }
      }
    }
  }
`;

CsWorkOrderSteps.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CsWorkOrderSteps;
