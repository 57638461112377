/* eslint "jsx-a11y/anchor-is-valid":"off" */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classNames from 'classnames';
import styled from 'styled-components';
import {logout} from '../../pages/Login/actions';
import {toggleShowSidebar, showNotificationsModal, getClientServiceStudios, showClientServiceCreateRequestModal,
  reloadAppConfig,
  reloadCache,
} from '../actions';
import {isManagerUser, getUserRoles, allowViewUserAdminPage, isInternalUser, getDefaultRedirectUrl,
  isArtworkManagerUser, isArtworkOperatorUser
} from '../../utils';
import {NavLink, withRouter} from 'react-router-dom';
import {Navbar} from 'react-bootstrap';
import StyledHeader from './StyledHeader';
import hamburgerRightImg from '../../../assets/images/hamburger-right.svg';
import HeaderSearchIcon from '../../components/icons/HeaderSearchIcon';
import alertImg from '../../../assets/images/notification/alert.svg';
import alertNewImg from '../../../assets/images/notification/alert-new.svg';
import actionItemsImg from '../../../assets/images/notification/action-items.svg';
import maleIcon from '../../../assets/images/header/male.svg';
import hamburgerLeftImg from '../../../assets/images/header/mobile/hamburger.svg';
import Can from '../../components/Can';
import {showCreateNewUserPopup} from '../../pages/Admin/actions';

class Header extends React.PureComponent {

  constructor(props) {
    super(props);

    this.userRoles = getUserRoles();
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      const {dispatch, match} = this.props;
      if (match.path !== '/requests/:requestId') {
        dispatch(toggleShowSidebar(false));
      }
    });
  }

  UNSAFE_componentWillReceiveProps = props => {
    const {sidebarIsOpen} = props;
    if (sidebarIsOpen) {
      this.openSidebarIfClose();
    } else {
      this.closeSidebarIfOpen();
    }
  };

  componentWillUnmount() {
    this.unlisten();
  }

  handleLogout = () => {
    const {dispatch, history} = this.props;
    dispatch(logout(history));
  };

  toggleShowSidebar = isShow => {
    const {dispatch} = this.props;
    dispatch(toggleShowSidebar(isShow));
  };

  handleHamburgerClick = () => {
    const {sidebarIsOpen} = this.props;
    this.toggleShowSidebar(!sidebarIsOpen);
  };

  openSidebarIfClose = () => {
    document.body.classList.remove('sidebar-collapsed');
  };

  closeSidebarIfOpen = () => {
    document.body.classList.add('sidebar-collapsed');
  };

  handleSearchClick = () => {
    const {body} = document;
    body.className += ' search-open';
    document.getElementById('search-input').focus();
  };

  handleNotificationClick = hasUnansweredComments => {
    const {dispatch} = this.props;
    dispatch(showNotificationsModal(true, hasUnansweredComments ? 'unanswered_comments' : null));
  };

  handleClickActionItems = () => {
    const {dispatch} = this.props;
    dispatch(showNotificationsModal(true, 'action_items'));
  };

  openMobileHeaderMenu = () => {
    const {body} = document;
    if (body.className.indexOf('mobile-header-menu-open') === -1) {
      body.className += ' mobile-header-menu-open';
    }
  };

  handleNewRequestClick = event => {
    if (isManagerUser()) {
      // need to ask the manager about the owner of the request, studio or studio partner
      event.preventDefault();
      const {dispatch} = this.props;
      dispatch(getClientServiceStudios())
        .then(() => {
          dispatch(showClientServiceCreateRequestModal(true));
        });
    }
  };

  handleReloadCache = () => {
    const {dispatch} = this.props;
    dispatch(reloadCache());
  };

  handleReloadAppConfig = () => {
    const {dispatch} = this.props;
    dispatch(reloadAppConfig());
  };

  handleClickAdminUser = () => {
    const {dispatch, match} = this.props;
    !allowViewUserAdminPage() && match.path === '/admin/user' && dispatch(showCreateNewUserPopup());
  };

  renderAvatar() {
    const {avatar} = this.props;
    return avatar ? <HeaderAvatar avatar={avatar}><span/></HeaderAvatar> : <HeaderAvatarPlaceholder/>;
  }

  renderArtworkNav() {
    const userRoles = this.userRoles;
    const isCompactMenu = isArtworkManagerUser() || isArtworkOperatorUser();
    const reportNav = (
      <Can
        roles={userRoles}
        perform="header:show-artwork-assets"
        yes={() => (
          <li>
            <NavLink
              title="Assets"
              to="/report"
              activeClassName={isCompactMenu ? '' : 'active'}
            >
              Assets
            </NavLink>
          </li>
        )}
      />
    );
    if (isCompactMenu) {
      return (
        <li className="admin-dropdown dropdown">
          <a
            href="#"
            className="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            >
            Artwork
            <span className="caret"/>
          </a>
          <AdminMenu className="dropdown-menu">
            <Can
              roles={userRoles}
              perform="header:show-artwork-request-queue"
              yes={() => (
                <li>
                  <NavLink
                    title="Artwork Request Queue"
                    to="/artwork/request-queue"
                    activeClassName=""
                  >
                    Request Queue
                  </NavLink>
                </li>
              )}
            />
            <Can
              roles={userRoles}
              perform="header:show-artwork-my-requests"
              yes={() => (
                <li>
                  <NavLink
                    title="My Artwork Requests"
                    to="/artwork/my-requests"
                    activeClassName=""
                  >
                    My Requests
                  </NavLink>
                </li>
              )}
            />
            {reportNav}
          </AdminMenu>
        </li>
      );
    }
    return reportNav;
  }

  renderAdminNav() {
    return (
      <Can
        roles={this.userRoles}
        perform="header:show-admin"
        yes={() => (
          <li className="admin-dropdown dropdown">
            <a
              href="#"
              className="dropdown-toggle"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              >
              Admin
              <span className="caret"/>
            </a>
            <AdminMenu className="dropdown-menu">
              <Can
                roles={this.userRoles}
                perform="admin:show-user-admin"
                yes={() => (
                  <li>
                    <NavLink
                      title={allowViewUserAdminPage() ? 'User Admin' : 'Create Client'}
                      to="/admin/user"
                      activeClassName=""
                      onClick={this.handleClickAdminUser}
                    >
                      {allowViewUserAdminPage() ? 'User Admin' : 'Create Client'}
                    </NavLink>
                  </li>
                )}
              />
              <li>
                <NavLink title="Company Admin" to="/admin/company" activeClassName="">
                  Company Admin
                </NavLink>
              </li>
              <li>
                <NavLink title="Distributor Admin" to="/admin/distributor" activeClassName="">
                  Distributor Admin
                </NavLink>
              </li>
              <li>
                <NavLink title="Series Admin" to="/admin/series" activeClassName="">
                  Series Admin
                </NavLink>
              </li>
              <Can
                roles={this.userRoles}
                perform="admin:show-analytics"
                yes={() => (
                  <li>
                    <NavLink
                      title="Analytics Reports Security"
                      to="/admin/analytics"
                      activeClassName=""
                    >
                      Analytics Reports
                    </NavLink>
                  </li>
                )}
              />
              <Can
                roles={this.userRoles}
                perform="admin:show-artwork-admin"
                yes={() => (
                  <li className="dropdown-submenu">
                    <a
                      href="#"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      >
                      Artwork
                    </a>
                    <AdminMenu className="dropdown-menu">
                      <li>
                        <NavLink
                          to="/admin/artwork/parameters"
                          activeClassName=""
                        >
                          Asset Parameters
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/admin/artwork/specs"
                          activeClassName=""
                        >
                          Specs
                        </NavLink>
                      </li>
                    </AdminMenu>
                  </li>
                )}
              />
              <Can
                roles={this.userRoles}
                perform="admin:reload-cache-and-config"
                yes={() => (
                  <li className="dropdown-submenu">
                    <a
                      href="#"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      >
                      Configuration
                    </a>
                    <AdminMenu className="dropdown-menu">
                      <li>
                        <NavLink
                          title="Reload Cache"
                          to="#"
                          activeClassName=""
                          onClick={this.handleReloadCache}
                        >
                          Reload Cache
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          title="Reload App Config"
                          to="#"
                          activeClassName=""
                          onClick={this.handleReloadAppConfig}
                        >
                          Reload App Config
                        </NavLink>
                      </li>
                    </AdminMenu>
                  </li>
                )}
              />
              <li>
                <NavLink title="Convert Manual WO" to="/admin/convert-manual-wo" activeClassName="">
                  Convert Manual WO to QOD
                </NavLink>
              </li>
            </AdminMenu>
          </li>
        )}
      />
    );
  }

  render() {
    const {
      hideLinks, email, username, studioName, sidebarIsOpen, notifications, mobileTitle, showNotificationsLoader
    } = this.props;
    const navbar = classNames({
      'page-scroll': true,
      'brand-center': hideLinks
    });
    const ntfIcon = classNames({
      'header-img': !hideLinks,
      'new': !showNotificationsLoader && notifications && (notifications.system_alerts_count || notifications.action_items_count)
    });
    const hasUnansweredComments = isInternalUser() && !showNotificationsLoader && !!(notifications || {}).unanswered_comments_count;

    return (
      <StyledHeader>
        <Navbar id="mainNav" className="navbar navbar-default navbar-inverse navbar-custom main-havbar">
          <Navbar.Header className={navbar}>
            <Navbar.Brand>
              <NavLink className="navbar-brand" to={getDefaultRedirectUrl()}>
                <span className="studio-name">{studioName || 'Eurofins DMS'}</span>
              </NavLink>
            </Navbar.Brand>
          </Navbar.Header>

          {
            !hideLinks ?
                <MobileHamburger>
                  <div onClick={this.openMobileHeaderMenu}/>
                  <span title={mobileTitle}/>
                </MobileHamburger> : null
          }

          {
            !hideLinks ?
              <div className="navbars-wrap">
                <ul className="nav navbar-nav navbar-nav-left">
                  <Can
                    roles={this.userRoles}
                    perform="header:show-dashboard"
                    yes={() => (
                      <li>
                        <NavLink title="Dashboard" to="/" activeClassName={window.location.pathname.length > 1 ? '' : 'active'}>
                          Dashboard
                        </NavLink>
                      </li>
                    )}
                  />
                  <Can
                    roles={this.userRoles}
                    perform="header:show-artwork-dashboard"
                    yes={() => (
                      <li>
                        <NavLink title="Dashboard" to="/dashboard" activeClassName="active">
                          Dashboard
                        </NavLink>
                      </li>
                    )}
                  />
                  <Can
                    roles={this.userRoles}
                    perform="header:show-requests"
                    yes={() => (
                      <li><NavLink title="Requests" to="/requests" activeClassName="active">Requests</NavLink></li>
                    )}
                  />
                  {this.renderArtworkNav()}
                  <Can
                    roles={this.userRoles}
                    perform="header:show-analytics"
                    yes={() => (
                      <li><NavLink title="Analytics" to="/analytics" activeClassName="active">Analytics</NavLink></li>
                    )}
                  />
                  <Can
                    roles={this.userRoles}
                    perform="header:show-settings"
                    yes={() => (
                      <li><NavLink title="Settings" to="/settings" activeClassName="active">Settings</NavLink></li>
                    )}
                  />
                  {this.renderAdminNav()}
                  <Can
                    roles={this.userRoles}
                    perform="header:show-create-qc-request-btn"
                    yes={() => (
                      <li>
                        <NavLink
                          title="Create QC Request"
                          to="/requests/new"
                          activeClassName="active"
                          className="create-qc-request"
                          onClick={this.handleNewRequestClick}
                          >
                          Create QC Request
                        </NavLink>
                      </li>
                    )}
                  />
                </ul>
                <ul className="nav navbar-nav navbar-nav-right">
                  <Can
                    roles={this.userRoles}
                    perform="header:show-search-btn"
                    yes={() => (
                      <li title="Search" className="search-header" onClick={this.handleSearchClick}>
                        <HeaderSearchIcon className="header-img search-header" />
                      </li>
                    )}
                  />
                  <li className="user-dropdown dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      >
                      {this.renderAvatar()}
                      <span className="header-username">{username}</span>
                      <span className="caret"/>
                    </a>
                    <UserMenu className="dropdown-menu">
                      <li><a href="/settings/general/profile">Manage Profile</a></li>
                      <Can
                        roles={this.userRoles}
                        perform="header:user:show-settings-and-preferences"
                        yes={() => <li><a href="/settings/qc-profiles">Settings & Preferences</a></li>}
                      />
                      <li><a href="#" onClick={this.handleLogout} title={email} className="logout">Log out</a></li>
                      <li className="username-placeholder-hidden"><a href="#">{username}</a></li>
                    </UserMenu>
                    <div className="back-btn"/>
                    <div className="overlay"/>
                  </li>
                  <li
                    className="notifications-btn"
                    title="Notifications"
                    onClick={() => this.handleNotificationClick(hasUnansweredComments)}
                  >
                    <HeaderNotification className={ntfIcon}>
                      {showNotificationsLoader ? <i className="fa fa-spinner fa-spin"/> :
                        hasUnansweredComments ? <><i className="fas fa-exclamation-triangle"/><i className="fas fa-exclamation"/></> : null
                      }
                    </HeaderNotification>
                  </li>
                  <li className="notifications-btn" title="Action Items" onClick={this.handleClickActionItems}>
                    <HeaderNtfActionItems className={ntfIcon}>
                      {showNotificationsLoader ? <i className="fa fa-spinner fa-spin"/> :
                        (notifications || {}).action_items_count ? <i className="fas fa-circle"/> : null
                      }
                    </HeaderNtfActionItems>
                  </li>
                  <li
                    title="Sidebar"
                    className={`hamburger-right ${sidebarIsOpen ? 'active' : ''}`}
                    onClick={this.handleHamburgerClick}>
                    <div className="header-img hamburger-right">
                      <img src={hamburgerRightImg} alt=""/>
                    </div>
                  </li>
                </ul>
              </div> : null
          }
        </Navbar>
      </StyledHeader>
    );
  }
}

const MobileHamburger = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    div {
      background-color: transparent;
      background-repeat: no-repeat;
      background-image: url(${hamburgerLeftImg});
      background-position: 0 1px;
      min-width: 21px;
      height: 19px;
      margin-left: 8px;
      margin-right: 20px;
      opacity: 1 !important;
    }
    span {
      &:after {
        content: attr(title);
        font-family: 'Roboto',sans-serif;
        font-weight: bold;
        font-size: 18px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #fff;
      }
    }
  }
`;

const HeaderNotification = styled.div`
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  background-repeat: no-repeat;
  background-image: url(${alertImg});
  background-position: 19px 21px;
  width: 57px;
  height: 59px;
  &.new {
    background-image: url(${alertNewImg});
  }
  &:hover {
    border-right-color: rgba(255, 255, 255, 0.4);
    background-color: #4a5165;
    cursor: pointer;
  }

  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;

  i {
    margin: 18px 0 0 11px;
    color: #fff;
    font-size: 14px;
    &.fa-exclamation-triangle {
      color: red;
    }
    &.fa-exclamation {
      position: absolute;
      top: 4px;
      left: 21px;
      font-size: 8px;
      @media (max-width: 767px) {
        left: 12px;
      }
    }
  }
`;

const HeaderNtfActionItems = styled.div`
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  background-repeat: no-repeat;
  background-image: url(${actionItemsImg});
  background-size: auto 20px;
  background-position: 19px 21px;
  width: 57px;
  height: 59px;
  &:hover {
    border-right-color: rgba(255, 255, 255, 0.4);
    background-color: #4a5165;
    cursor: pointer;
  }

  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;

  i {
    margin: 18px 0 0 11px;
    color: #fff;
    font-size: 14px;
    &.fa-circle {
      margin-top: 21px;
      color: red;
      font-size: 9px;
    }
  }
`;

const HeaderAvatar = styled.div`
  width: 59px;
  height: 59px;
  span {
    background-color: transparent;
    background-image: url(${props => (props.avatar)});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex: 1 1 100%;
    height: 100%;
    width: 100%;
    a:hover & {
      opacity: 0.8;
    }
  }
`;

const HeaderAvatarPlaceholder = styled(HeaderAvatar)`
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${maleIcon});
`;

const UserMenu = styled.ul`
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  padding: 0;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  a {
    letter-spacing: 0 !important;
    font-weight: normal !important;
    font-size: 11px !important;
    line-height: 12px !important;
    color: #000 !important;
    padding: 15px 13px 14px 20px !important;
    text-transform: none !important;
    display: block;
    clear: both;
    white-space: nowrap;
    &.logout {
      font-weight: bold !important;
    }
    &:hover {
      color: #fff !important;
    }
  }
  li {
    border-top: 0.5px solid rgba(0, 0, 0, 0.2);
    &:first-child {
      border-top: none;
      a {
        padding-top: 24px !important;
      }
    }
    &.username-placeholder-hidden {
      visibility: hidden;
      height: 0;
      padding-right: 86px;
      padding-left: 75px;
      border-top: none;
      a {
        font-size: 13px !important;
        font-weight: normal !important;
        padding: 0 !important;
      }
    }
  }
`;

const AdminMenu = styled.ul`
  left: 11px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  padding: 0;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  a {
    letter-spacing: 0 !important;
    font-weight: normal !important;
    font-size: 11px !important;
    line-height: 12px !important;
    color: #000 !important;
    padding: 15px 13px 14px 20px !important;
    text-transform: none !important;
    display: block;
    clear: both;
    white-space: nowrap;
    &:hover {
      color: #fff !important;
    }
  }
  li {
    border-top: 0.5px solid rgba(0, 0, 0, 0.2);
    &:first-child {
      border-top: none;
      a {
        padding-top: 24px !important;
      }
    }
  }


  .dropdown-submenu {
    >.dropdown-menu {
      border-radius: 0;
    }
    >a {
      &:after {
        border-width: 4px 0 4px 4px;
        margin-top: 1px;
        margin-right: -8px;
      }
    }
  }

`;

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  email: PropTypes.string,
  username: PropTypes.string,
  avatar: PropTypes.string,
  studioName: PropTypes.string,
  hideLinks: PropTypes.bool,
  sidebarIsOpen: PropTypes.bool,
  notifications: PropTypes.object,
  showNotificationsLoader: PropTypes.bool,
  location: PropTypes.object.isRequired
};

export default connect()(withRouter(Header));
