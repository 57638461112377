/* eslint "complexity":"off" */
import {USER_SET_IS_LOGOUT} from '../../../../pages/Login/actions';
import {
  ARTWORK_ADMIN_PARAMS_IS_LOADING,
  ARTWORK_ADMIN_PARAMS_IS_SAVING,
  ARTWORK_ADMIN_PARAMS_SET_ACTIVE_TAB,
  ARTWORK_ADMIN_PARAMS_SET_PARAM_MODAL_DATA,
  ARTWORK_ADMIN_PARAMS_RECEIVE,
  ARTWORK_ADMIN_PARAMS_DELETE,
} from './actions';

const initialState = {
  loading: false,
  saving: false,
  studios: [],
  parameters: {},
  activeTab: 0,
  paramModalData: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT: {
      return initialState;
    }

    case ARTWORK_ADMIN_PARAMS_IS_LOADING: {
      return {
        ...state,
        loading: action.loading || initialState.loading
      };
    }

    case ARTWORK_ADMIN_PARAMS_IS_SAVING: {
      return {
        ...state,
        saving: action.saving || initialState.saving
      };
    }

    case ARTWORK_ADMIN_PARAMS_SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.activeTab
      };
    }

    case ARTWORK_ADMIN_PARAMS_SET_PARAM_MODAL_DATA: {
      return {
        ...state,
        paramModalData: action.data || initialState.paramModalData
      };
    }

    case ARTWORK_ADMIN_PARAMS_RECEIVE: {
      return {
        ...state,
        studios: action.studios || state.studios,
        parameters: action.parameters || state.parameters,
      };
    }

    case ARTWORK_ADMIN_PARAMS_DELETE: {
      const {param} = action;
      const parameters = {...state.parameters};
      const idx = parameters[param.StudioID].findIndex(p => p.ParameterID === param.ParameterID);
      if (idx !== -1) {
        parameters[param.StudioID].splice(idx, 1);
      }
      return {
        ...state,
        parameters
      };
    }

    default:
      return state;
  }
}
