import React, {Fragment, PureComponent} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {changeVideoParams} from '../../actions';
import {getSecondsFromTimeCode, getTimeCodeForVideoPlayer} from '../../../../utils';
import StyledVideoPlayer from './StyledVideoPlayer';
import Marker from './Marker';
import Issue from './Issue';
import $ from 'jquery';
import _ from 'lodash';
import TimecodeModal from '../modals/TimecodeModal';

const DEFAULT_VOLUME = 70;

const TIME_OPTION = {
  TIME_ELAPSED: 1,
  TIME_REMAINING: 2,
  TIME_ELAPSED__TOTAL_TIME: 3
};

class VideoPlayer extends PureComponent {
  constructor(props) {
    super();

    this.curIssue = null;
    this.curCue = null;
    this.isShowedDefaultCurPosition = true;

    this.throttleScrollIssues = _.throttle(this.scrollIssues, 1000);
    this.throttleScrollCues = _.throttle(this.scrollCues, 1000);
  }

  state = {
    timeOption: TIME_OPTION.TIME_ELAPSED,
    currentTime: 0,
    duration: null,
    muted: false,
    isFullScreen: false,
    showCaptionsMenu: false,
    selectedCaptions: 'Off',
    isShowTimecodeModal: false,
  };

  componentDidMount() {
    this.addEventsListener();
  }

  componentWillUnmount() {
    this.removeEventsListener();
  }

  componentDidUpdate(prevProps) {
    const {isFullScreen} = this.state;
    if (isFullScreen) {
      const {player} = this.refs;
      this.scrollFullScreenIssues(player.currentTime);
    }

    const {timeCodeStart, url, savedState, isPlaying} = this.props;
    if (prevProps.timeCodeStart !== timeCodeStart) {
      this.seekToPlayer();
    }

    const isTabChanged = savedState.viewTab !== prevProps.savedState.viewTab
      || savedState.tab !== prevProps.savedState.tab;
    if (prevProps.url !== url) {
      this.restartPlayer();
    } else if (isTabChanged && isPlaying) {
      this.onPauseClick();
    }
    return true;
  }

  addEventsListener() {
    const {player} = this.refs;
    player.addEventListener('timeupdate', this.onProgress);
    player.addEventListener('durationchange', this.onDurationLoaded);
    document.addEventListener('keydown', this.onKeyPress);
  }

  removeEventsListener() {
    const {player} = this.refs;
    player.removeEventListener('timeupdate', this.onProgress);
    player.removeEventListener('timeupdate', this.onDurationLoaded);
    document.removeEventListener('keydown', this.onKeyPress);
  }

  restartPlayer() {
    const {progress, player} = this.refs;

    this.onPauseClick();
    player.currentTime = 0;
    progress.value = 0;

    $('div .caption-cue .currentPosition').css('display', 'none');
    $('div .issue .currentPosition').css('display', 'none');
    $('.sidebar-comments-list .default-current-position').css('display', 'block');
    this.isShowedDefaultCurPosition = true;
    /*dispatch(changeVideoParams({
      currentIssueID: null
    }));*/

    this.removeEventsListener();
    this.addEventsListener();

    this.setState({currentTime: 0});
  }

  seekToPlayer = () => {
    const {player} = this.refs;
    const {timeCodeStart, timeCodeSettings: {frameRate}} = this.props;
    const isNumberTimeCodeStart = typeof timeCodeStart === 'number';
    if ((isNumberTimeCodeStart || timeCodeStart) && player) {
      player.currentTime = isNumberTimeCodeStart ? timeCodeStart : getSecondsFromTimeCode(timeCodeStart, frameRate);
    }
  };

  getTimeCode = (secs, startFileOffsetSeconds) => {
    const {timeCodeSettings: {frameRate}} = this.props;
    return getTimeCodeForVideoPlayer(secs, frameRate, startFileOffsetSeconds);
  };

  onPlayerClick = () => {
    const {isPlaying} = this.props;
    if (isPlaying) {
      this.onPauseClick()
    } else {
      this.onPlayClick()
    }
  };

  setCurrentTime = currentTime => {
    const {player, progress} = this.refs;
    const {isPlaying} = this.props;
    if (typeof player.duration === 'number' && currentTime > player.duration) {
      currentTime = player.duration - 0.01;
    }
    if (currentTime < 0) {
      currentTime = 0;
    }
    player.currentTime = currentTime;
    this.setState({currentTime});
    if (player.duration) {
      const percentage = (100 / player.duration) * currentTime;
      progress.value = percentage;
      progress.innerHTML = percentage + '% played';
      const prevIssue = this.scrollIssues(currentTime);
      if (prevIssue && isPlaying) {
        this.scrollFullScreenIssues(currentTime, prevIssue);
      }
    }
  };

  onKeyPress = event => {
    const SPACE = 32;
    const ARROW_LEFT = 37;
    const ARROW_RIGHT = 39;
    const {player, defaultFocusBtn} = this.refs;
    const {isActiveTopTab} = this.props;
    if (isActiveTopTab && !['INPUT', 'TEXTAREA'].includes(event.target.nodeName) &&
        [SPACE, ARROW_LEFT, ARROW_RIGHT].includes(event.keyCode) && !event.altKey && !event.ctrlKey) {
      defaultFocusBtn.focus();
      if (event.keyCode === SPACE) {
        this.onPlayerClick();
      } else {
        let currentTime = player.currentTime + (event.keyCode === ARROW_RIGHT ? (1/24) : -(1/24));
        this.setCurrentTime(currentTime);
      }
    }
  };

  onDurationLoaded = e => {
    const {duration} = e.currentTarget;
    this.setState({duration});
  };

  onError = e => {
    const {onErrorLoadVideo} = this.props;
    onErrorLoadVideo(e);
  };

  onProgress = e => {
    const {dispatch, timeCodeStart, isChangedSelecting, selectedIssueID, isPlaying, selectedCueID,
      timeCodeSettings: {frameRate}
    } = this.props;
    const {currentTime, duration} = e.currentTarget;
    if (duration && (isPlaying || isChangedSelecting)) {
      const scrollSelectedCueID = isChangedSelecting && selectedCueID && selectedCueID > 0 ? selectedCueID : null;
      const scrollSelectedIssueID = isChangedSelecting && selectedIssueID > 0 ? selectedIssueID : null;
      let startTime;
      if (isChangedSelecting) {
        if (typeof timeCodeStart !== 'undefined') {
          const isNumberTimeCodeStart = typeof timeCodeStart === 'number';
          startTime = isNumberTimeCodeStart ? timeCodeStart : getSecondsFromTimeCode(timeCodeStart, frameRate);
          // if (startTime >= 1) {
          //   startTime-=0.05;
          // }
          const {player} = this.refs;
          player.currentTime = startTime;
        }
        if (selectedIssueID === undefined && !selectedCueID) {
          // click on Add Issue
          this.onPauseClick();
        }
        dispatch(changeVideoParams({isChangedSelecting: false, selectedIssue: {}}));
      }
      this.setState({currentTime: startTime || currentTime});
      const {progress, player} = this.refs;
      const currTime = startTime || (player.currentTime ? currentTime : 0);
      const percentage = (100 / duration) * currTime;
      progress.value = percentage;
      progress.innerHTML = percentage + '% played';

      if (scrollSelectedCueID) {
        this.throttleScrollCues(currTime, scrollSelectedCueID, isPlaying);
      } else {
        this.throttleScrollIssues(currTime, scrollSelectedIssueID, isPlaying);
      }

      /*if (prevIssue && isPlaying) {
        this.scrollFullScreenIssues(currTime, prevIssue);
      }*/
      if (currentTime === duration) {
        this.onPauseClick();
      }
    }
  };

  getPrevIssueMark = currentTime => {
    const {issues, timeCodeSettings: {frameRate}} = this.props;
    const issuesWithDiff = issues.filter(issue => {
      if (typeof issue.ProxyTimeCodeStart === 'undefined' || issue.ProxyTimeCodeStart === null) {
        return false;
      }
      issue.seconds = getSecondsFromTimeCode(issue.ProxyTimeCodeStart, frameRate);
      issue.diff = currentTime - issue.seconds;
      return issue.seconds <= currentTime;
    });
    const minDiff = Math.min(...issuesWithDiff.map(item => item.diff));
    const issuesWithMinimalDiff = issuesWithDiff.filter(item => item.diff === minDiff);
    return issuesWithMinimalDiff[issuesWithMinimalDiff.length - 1];
  };

  scrollFullScreenIssues = (currentTime, prevIssue) => {
    if (!prevIssue) {
      prevIssue = this.getPrevIssueMark(currentTime);
    }
    if (prevIssue) {
      const issuesContainerEl = this.refs.issuesContainer;
      const issueEl = ReactDOM.findDOMNode(this.refs[`issue-${prevIssue.IssueID}`]);
      if (issuesContainerEl && issueEl) {
        const wasExpanded = this.expandIssuesForCaptionCueIfNeeded(prevIssue, issueEl);
        const scrollTop = () => issuesContainerEl.scrollTop = issueEl.offsetTop - 20;
        if (wasExpanded) {
          setTimeout(scrollTop, 360);
        } else {
          scrollTop();
        }
      }
    }
  };

  scrollIssues = (currentTime, selectedIssueID, isPlaying) => {
    const prevIssue = selectedIssueID ? {IssueID: selectedIssueID} : this.getPrevIssueMark(currentTime);

    if (prevIssue) {

      if (this.isShowedDefaultCurPosition) {
        $('.sidebar-comments-list .default-current-position').css('display', 'none');
        this.isShowedDefaultCurPosition = false;
      }

      if (this.curIssue && this.curIssue === prevIssue.IssueID) {
        return prevIssue;
      }

      //dispatch(changeVideoParams({currentIssueID: prevIssue.IssueID}));
      const issuesContainerEl = document.getElementsByClassName('sidebar-comments-list')[0];
      const issueEl = document.getElementsByClassName(`issue-${prevIssue.IssueID}`)[0];

      const wasExpanded = this.expandIssuesForCaptionCueIfNeeded(prevIssue, issueEl);

      this.curCue = null;
      this.curIssue = prevIssue.IssueID;
      $('div .caption-cue .currentPosition').css('display', 'none');
      $('div .issue .currentPosition').css('display', 'none');
      const showCurPos = ()  => $('div .issue-' + prevIssue.IssueID + ' .currentPosition').css('display', 'inline-block');
      if (wasExpanded) {
        setTimeout(showCurPos, 360);
      } else {
        showCurPos();
      }

      const headerHeight = document.getElementsByClassName('nav navbar-nav')[0].clientHeight;
      const offsetTop = issueEl ? issueEl.offsetTop - headerHeight + 5 : 0;
      if (issueEl && issueEl.offsetHeight > 150) {
        issuesContainerEl.scrollTop = offsetTop + issueEl.offsetHeight - 150;
      } else {
        issuesContainerEl.scrollTop = offsetTop;
      }
    } else {
      $('.sidebar-comments-list .default-current-position').css('display', 'block');
      this.isShowedDefaultCurPosition = true;
      //dispatch(changeVideoParams({currentIssueID: null}));
    }

    if (prevIssue && isPlaying) {
      this.scrollFullScreenIssues(currentTime, prevIssue);
    }
    return prevIssue;
  };

  scrollCues = (currentTime, selectedCueID, isPlaying) => {
    if (this.isShowedDefaultCurPosition) {
      $('.sidebar-comments-list .default-current-position').css('display', 'none');
      this.isShowedDefaultCurPosition = false;
    }

    if (this.curCue && this.curCue === selectedCueID) {
      return;
    }

    const issuesContainerEl = document.getElementsByClassName('sidebar-comments-list')[0];
    const cueEl = document.getElementsByClassName(`caption-cue-${selectedCueID}`)[0];

    //this.curIssue = null;
    this.curCue = selectedCueID;
    $('div .issue .currentPosition').css('display', 'none');
    $('div .caption-cue .currentPosition').css('display', 'none');
    //$('div .caption-cue-' + selectedCueID + ' > .currentPosition').css('display', 'inline-block');

    const headerHeight = document.getElementsByClassName('nav navbar-nav')[0].clientHeight;
    const offsetTop = cueEl ? cueEl.offsetTop - headerHeight + 5 : 0;
    if (cueEl && cueEl.offsetHeight > 120/*150*/) {
      issuesContainerEl.scrollTop = offsetTop + cueEl.offsetHeight - 120/*150*/;
    } else {
      issuesContainerEl.scrollTop = offsetTop;
    }
  };

  onPlayClick = event => {
    if (typeof event === 'object') {
      try {
        event.target.blur();
      } catch(ignored) {/**/}
    }
    const {dispatch} = this.props;
    dispatch(changeVideoParams({isPlaying: true}));
    this.refs.player.play();
  };

  onPauseClick = event => {
    if (typeof event === 'object') {
      try {
        event.target.blur();
      } catch(ignored) {/**/}
    }
    const {dispatch} = this.props;
    dispatch(changeVideoParams({isPlaying: false}));
    this.refs.player.pause();
  };

  onProgressClick = e => {
    const {player, progress} = this.refs;
    const x = e.clientX - progress.getBoundingClientRect().left + document.body.scrollLeft;
    const percentage = x * progress.max / progress.offsetWidth;
    player.currentTime = percentage / 100 * player.duration;
  };

  onVolumeClick = e => {
    const {volume, player} = this.refs;
    const y = volume.offsetWidth - (e.clientY - volume.getBoundingClientRect().top + document.body.scrollTop);
    const percentage = y * volume.max / volume.offsetWidth;
    this.setVolume(percentage);
    player.muted = false;
  };

  setVolume = percentage => {
    const {player, volume} = this.refs;
    player.volume = percentage / 100;
    volume.value = percentage;
    volume.innerHTML = percentage + '% volume';
    this.setState({
      muted: !percentage
    });
  };

  onMuteClick = () => {
    const {muted} = this.state;
    const {player} = this.refs;
    if (muted) {
      player.muted = false;
      this.setVolume(DEFAULT_VOLUME);
      this.setState({muted: false});
    } else {
      player.muted = true;
      this.setVolume(0);
      this.setState({muted: true});
    }
  };

  onFullScreenClick = () => {
    const {isFullScreen} = this.state;
    if (isFullScreen) {
      document.body.classList.remove('full-screen');
    } else {
      document.body.classList.add('full-screen');
    }
    this.setState({isFullScreen: !isFullScreen});
  };

  handleJumpToTimecode = timecode => {
    if (timecode) {
      const {timeCodeSettings: {frameRate, startFileOffsetSeconds}} = this.props;
      try {
        let currentTime = getSecondsFromTimeCode(timecode, frameRate);
        if (typeof currentTime === 'number') {
          currentTime -= (startFileOffsetSeconds || 0);
          this.setCurrentTime(currentTime);
        }
      } catch {}
    }
    this.setState({isShowTimecodeModal: false});
  };

  handleClickJumpToTimecode = () => {
    this.setState({isShowTimecodeModal: true});
  };

  onCaptionsBtnClick = () => {
    const {showCaptionsMenu} = this.state;
    this.setState({showCaptionsMenu: !showCaptionsMenu});
  };

  onChangeCaptions = (e) => {
    const {player} = this.refs;
    const {showCaptionsMenu} = this.state;

    // Find the language to activate
    var lang = e.target.value;
    this.setState({selectedCaptions: lang});

    for (var i = 0; i < player.textTracks.length; i++) {
      if (player.textTracks[i].label === lang) {
        player.textTracks[i].mode = 'showing';
      }
      else {
        player.textTracks[i].mode = 'hidden';
      }
    }

    this.setState({showCaptionsMenu: !showCaptionsMenu});
  };

  expandIssuesForCaptionCueIfNeeded = (issue, issueEl) => {
    try {
      const issueParentEl = (issueEl || document.getElementsByClassName(`issue-${issue.IssueID}`)[0]).parentElement;
      if (issueParentEl.classList.contains('issues-for-caption-cue') &&
          issueParentEl.classList.contains('collapse') && !issueParentEl.classList.contains('in')) {
        issueParentEl.parentElement.getElementsByClassName('toggle-issues-for-caption-cue')[0].click();
        return true;
      }
    } catch {}
    return false;
  };

  handleIssueClick = issue => {
    const {onIssueClick, timeCodeSettings: {frameRate}} = this.props;
    const {player} = this.refs;
    player.currentTime = getSecondsFromTimeCode(issue.ProxyTimeCodeStart, frameRate);
    if (this.expandIssuesForCaptionCueIfNeeded(issue)) {
      setTimeout(() => onIssueClick(issue), 360);
    } else {
      onIssueClick(issue);
    }
  };

  render() {
    const {url, isPlaying, issues, captions, timeCodeSettings} = this.props;
    const {frameRate, startFileOffsetSeconds} = timeCodeSettings;
    const {currentTime, duration, muted, isFullScreen, showCaptionsMenu, selectedCaptions, timeOption,
      isShowTimecodeModal
    } = this.state;
    const durationTimeCodeProxy = this.getTimeCode(duration);
    const currentTimeCodeProxy = currentTime !== duration ? this.getTimeCode(currentTime) : durationTimeCodeProxy;
    const durationTimeCode = this.getTimeCode(duration, startFileOffsetSeconds);
    const currentTimeCode = currentTime < duration ? this.getTimeCode(currentTime, startFileOffsetSeconds) : durationTimeCode;
    const currentTimeCodeToDisplay =
      timeOption === TIME_OPTION.TIME_ELAPSED__TOTAL_TIME ? `${currentTimeCode} / ${durationTimeCode}` :
        timeOption === TIME_OPTION.TIME_REMAINING ? this.getTimeCode(Math.max(duration - currentTime, 0)) :
          currentTimeCode;
    return (
      <StyledVideoPlayer className="video-container">
        <video key={url} className="video" ref="player" crossOrigin="anonymous" onError={this.onError} onProgress={this.onProgress} onClick={this.onPlayerClick} onDoubleClick={this.onFullScreenClick}>
          <source src={url} type="video/mp4"/>
          {captions && captions.map(c => {
            return (
              <track
                key={`track--captions--${c.captions_id}`}
                label={c.label}
                kind="subtitles"
                src={c.file_url}
              />
            );
          })}
        </video>
        {isFullScreen ?
          <Fragment>
            <button
              className="close"
              title="Exit Fullscreen"
              onClick={this.onFullScreenClick}>
              Close video
            </button>
          </Fragment>: null
        }

        <div className="issues" ref="issuesContainer">
          {issues && issues.map(issue => {
            const {IssueID} = issue;
            return (
              <Issue
                key={IssueID}
                ref={`issue-${IssueID}`}
                issue={issue}
              />
            );
          })}
        </div>
        <div className="controls">
          <button
            className={isPlaying ? 'pause' : 'play'}
            onClick={isPlaying ? this.onPauseClick : this.onPlayClick}
            title={`${isPlaying ? 'Pause' : 'Play'} (Spacebar)`}
          >
            {isPlaying ? 'Pause' : 'Play'}
          </button>
          <button
            className="time"
            title="Back .05s (&larr;)  |  Forward .05s (&rarr;)"
            ref="defaultFocusBtn"
            onClick={() => {
              const newTimeOption = timeOption === TIME_OPTION.TIME_ELAPSED__TOTAL_TIME ? TIME_OPTION.TIME_ELAPSED : (timeOption + 1);
              this.setState({timeOption: newTimeOption});
            }}
          >
            {currentTimeCodeToDisplay}
          </button>
          <button
            className="time timecode-item hidden"
          >
            {currentTimeCode}
          </button>
          <button
            className="time timecode-proxy hidden"
          >
            {currentTimeCodeProxy}
          </button>
          <div className="progress-wrap" ref="progressWrap">
            <progress ref="progress" min="0" max="100" onClick={this.onProgressClick}>
              0% played
            </progress>
            {issues && issues.map(issue => {
              const {IssueID} = issue;
              return (
                <Marker
                  key={IssueID}
                  issue={issue}
                  duration={duration}
                  frameRate={frameRate}
                  onIssueClick={this.handleIssueClick}
                />
              )
            })}
          </div>
          <div className="volume-wrap">
            <progress ref="volume" min="0" max="100" value={DEFAULT_VOLUME} onClick={this.onVolumeClick}>
              {DEFAULT_VOLUME}% volume
            </progress>
            <button
              className={muted ? 'no-volume' : 'volume'}
              onClick={this.onMuteClick}>
              Volume
            </button>
          </div>
          {captions ?
            <div>
              {showCaptionsMenu ? <div className="subtitles-menu">
                  <div>
                    {selectedCaptions === 'Off' ?
                      <span>
                        <svg width="12" height="10" viewBox="0 0 12 10" fill="none">
                            <path d="M10.1968 0.612305L9.49059 1.3248C7.57497 3.24434 6.11239 4.8474 4.33439 6.6624L2.44063 5.0624L1.67813 4.41864L0.390625 5.94364L1.15313 6.59364L3.75313 8.79364L4.45313 9.3874L5.10313 8.7374C7.25713 6.5788 8.79073 4.8546 10.9031 2.7374L11.6094 2.0249L10.1968 0.612305Z" fill="#7FCF18"/>
                        </svg>
                      </span> : null
                    }
                    <button id="subtitles-off" className="subtitles-button" value="Off" data-state="inactive" onClick={this.onChangeCaptions}>Off</button>
                  </div>
                  {captions.map(c => {
                    return (
                      <div key={`captions--${c.captions_id}`}>
                        {selectedCaptions === c.label ?
                          <span>
                            <svg width="12" height="10" viewBox="0 0 12 10" fill="none">
                                <path d="M10.1968 0.612305L9.49059 1.3248C7.57497 3.24434 6.11239 4.8474 4.33439 6.6624L2.44063 5.0624L1.67813 4.41864L0.390625 5.94364L1.15313 6.59364L3.75313 8.79364L4.45313 9.3874L5.10313 8.7374C7.25713 6.5788 8.79073 4.8546 10.9031 2.7374L11.6094 2.0249L10.1968 0.612305Z" fill="#7FCF18"/>
                            </svg>
                          </span> : null
                        }
                        <button id="subtitles-en" className="subtitles-button" lang="en" value={c.label} data-state="inactive" onClick={this.onChangeCaptions}>{c.label}</button>
                      </div>
                    );
                  })}
                </div> : null
              }
              <button
                className="captions"
                title="Subtitles/Closed captions"
                onClick={this.onCaptionsBtnClick}>
                CC
              </button>
            </div> : null
          }
          <button
            className="jump-to-timecode"
            title="Jump to timecode"
            onClick={this.handleClickJumpToTimecode}
            disabled={isShowTimecodeModal}
          >
            <i
              className="fa-regular fa-clock-rotate-left"
            />
          </button>
          <button
            className="full-screen"
            title={isFullScreen ? "Exit Fullscreen" : "Fullscreen"}
            onClick={this.onFullScreenClick}>
            FullScreen
          </button>
        </div>
        {isShowTimecodeModal &&
          <TimecodeModal
            onClose={() => this.setState({isShowTimecodeModal: false})}
            onConfirm={this.handleJumpToTimecode}
            timeCodeSettings={timeCodeSettings}
          />
        }
      </StyledVideoPlayer>
    );
  }
}

VideoPlayer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  captions: PropTypes.array,
  isActiveTopTab: PropTypes.bool,
  isPlaying: PropTypes.bool,
  isChangedSelecting: PropTypes.bool,
  selectedIssueID: PropTypes.any,
  selectedCueID: PropTypes.any,
  timeCodeStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  timeCodeSettings: PropTypes.object.isRequired,
  issues: PropTypes.array,
  savedState: PropTypes.object,
  onErrorLoadVideo: PropTypes.func,
  onIssueClick: PropTypes.func
};

export default VideoPlayer;
