import styled from 'styled-components';
import playBlackImg from '../../../../../assets/images/video/play-black.svg';
import pauseBlackImg from '../../../../../assets/images/video/pause-black.svg';
import volumeBlackImg from '../../../../../assets/images/video/volume-black.svg';
import noVolumeBlackImg from '../../../../../assets/images/video/no-volume-black.svg';
import fullscreenBlackImg from '../../../../../assets/images/video/full-screen-black.svg';
import playWhiteImg from '../../../../../assets/images/video/play-white.svg';
import pauseWhiteImg from '../../../../../assets/images/video/pause-white.svg';
import volumeWhiteImg from '../../../../../assets/images/video/volume-white.svg';
import noVolumeWhiteImg from '../../../../../assets/images/video/no-volume-white.svg';
import fullscreenWhiteImg from '../../../../../assets/images/video/full-screen-white.svg';
import closeImg from '../../../../../assets/images/video/close-video.svg';

const StyledVideoPlayer = styled.div`
  .issues {
    display: none;
  }
  .video {
    background-color: #000000;
    display: block;
    height: calc(100vh - 425px);
    width: 100%;
  }
  .controls {
    background-color: #e7e7e7;
    display: flex;
    height: 36px;

    button {
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center center;
      border: 0;
      margin: 10px 14px;
      padding: 0;
      outline: none;
      height: 16px;
      width: 16px;
      text-indent: -9999px;
      &.play {
        background-image: url(${playBlackImg});
      }
      &.pause {
        background-image: url(${pauseBlackImg});
        background-size: 15px;
      }
      &.volume {
        background-image: url(${volumeBlackImg});
        background-position-x: 0;
        margin-right: 7px;
        width: 17px;
      }
      &.no-volume {
        background-image: url(${noVolumeBlackImg});
        background-position-x: 0;
        margin-right: 7px;
        width: 17px;
      }
      &.full-screen {
        background-image: url(${fullscreenBlackImg});
        margin-left: 7px;
        margin-right: 10px;
        width: 17px;
      }
      &.jump-to-timecode,
      &.captions {
        text-indent: 0;
        font-size: 13px;
        color: #555;
      }
    }
    
    .time {
      color: #969696;
      font-size: 10px;
      line-height: 36px;
      padding-right: 15px;
      margin: 0;
      height: unset;
      width: unset;
      text-indent: unset;
      cursor: default;
    }

    progress {
      appearance: none;
      -webkit-appearance: none;
      cursor: pointer;
    }

    .progress-wrap {
      margin-top: 14px;
      position: relative;
      width: auto;
      flex-grow: 1;
      overflow: hidden;
      progress {
        vertical-align: top;
        height: 8px;
        width: 100%;
        &::-webkit-progress-value {
          background: #28C0F0;
        }
        &::-webkit-progress-bar {
          background: #ffffff;
        }
      }
    }

    .volume-wrap {
      position: relative;
      &:hover progress {
        display: block;
      }
      progress {
        border-radius: 2px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        display: none;
        height: 10px;
        overflow: hidden;
        position: absolute;
        top: -32px;
        left: -15px;
        transform: rotate(-90deg);
        width: 68px;
        
        &::-webkit-progress-value {
          background: #ffffff;
        }
        &::-webkit-progress-bar {
          background: #9D9D9D;
        }
      }
    }

    .subtitles-menu {
        position: absolute;
        bottom: 8%;
        right: 24px;
        background-color: hsla(0, 0%, 7%, .85);
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 130px;
        padding: 10px;
        border-radius: 2px;
    }

    .subtitles-menu div {
        padding: 0;
        text-align: center;
        display: flex;

        span {
          margin: 10px 0;
          position: absolute;
        }
    }

    .subtitles-menu div button {
        border: none;
        color: #fff;
        cursor: pointer;
        width: 90%;
        min-height: 16px;
        height: auto;
        padding: 2px 5px;
        text-indent: 0;
    }
  }

  .overlay {
    position:absolute;
    top:0;
    left:0;
    z-index:1;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #eee;
    font-size: 20px;

    .content {
      margin-top: 150px;
    }
  }

  body.full-screen & {
    background-color: #000000;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    .video {
      height: calc(100vh - 36px);
      max-width: 100%;
    }
    .close {
      background-image: url(${closeImg});
      border: 0;
      opacity: 1;
      outline: none;
      text-indent: -9999px;
      height: 23px;
      width: 23px;
      position: absolute;
      top: 36px;
      right: 77px;
    }
    .issues {
      display: block;
      height: 158px;
      overflow: auto;
      padding-top: 20px;
      scroll-behavior: smooth;
      position: absolute;
      top: calc(100vh - 194px);
      right: 30px;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .controls {
      background-color: #595959;

      .subtitles-menu {
        bottom: 40px;
      }

      button {
        &.play {
          background-image: url(${playWhiteImg});
        }
        &.pause {
          background-image: url(${pauseWhiteImg});
        }
        &.volume {
          background-image: url(${volumeWhiteImg});
        }
        &.no-volume {
          background-image: url(${noVolumeWhiteImg});
        }
        &.full-screen {
          background-image: url(${fullscreenWhiteImg});
        }
        &.jump-to-timecode,
        &.captions {
          color: #aaa;
        }
      }
    }
  }

  @media (max-width: 767px) and (orientation: landscape) {
    .controls .subtitles-menu {
      bottom: 10% !important;
    }
  }

  @media (max-width: 767px) {
    .video {
      height: auto;
      width: 100%;
    }
    .controls .subtitles-menu {
      bottom: 16%;
    }
    body.full-screen & {
      .video {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 11;
        height: calc(100% - 36px);
        width: 100%;
      }
      .close {
        top: 20px;
        right: 20px;
        position: fixed;
        z-index: 13;
      }
      .issues {
        right: 3px;
        max-width: calc(100vw - 6px);
        position: fixed;
        z-index: 12;
      }
      .controls {
        background: #595959 !important;
        opacity: 1 !important;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 13;

        .subtitles-menu {
          bottom: 40px;
        }
      }
    }
  }
`;

export default StyledVideoPlayer;