import {USER_SET_IS_LOGOUT} from '../../../pages/Login/actions';
import {
  ARTWORK_REQ_QUEUE_CLEAR,
  ARTWORK_REQ_QUEUE_SET_LOADING,
  ARTWORK_REQ_QUEUE_DICTIONARIES,
  ARTWORK_REQ_QUEUE_RECEIVE,
  ARTWORK_REQ_QUEUE_ASSIGN_REQUEST,
  ARTWORK_REQ_QUEUE_PICK_REQUEST,
  ARTWORK_REQ_QUEUE_CHANGE_FILTER_TYPE,
  ARTWORK_REQ_QUEUE_CHANGE_FILTER,
  ARTWORK_REQ_QUEUE_SAVED_FILTER,
  ARTWORK_REQ_QUEUE_SET_FILTERS,
  ARTWORK_REQ_QUEUE_ADD_FILTERS,
  ARTWORK_REQ_QUEUE_REMOVE_FILTERS,
} from './actions';
import {NonNumericFilters} from '../Report/utils';

const initialState = {
  dictionaries: {
    operators: [],
  },
  requestsType: '',
  data: [],
  total: 0,
  loaders: {
    dictionaries: false,
    requests: true,
    more: false,
    assign: false,
    filters: false,
  },
  allowedFilters: [],
  selectedFilters: [{
    name: 'search',
    value: null
  }, {
    name: 'sort',
    value: {}
  }],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT:
    case ARTWORK_REQ_QUEUE_CLEAR: {
      return {...initialState};
    }

    case ARTWORK_REQ_QUEUE_SET_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.name]: action.loading
        }
      };
    }

    case ARTWORK_REQ_QUEUE_DICTIONARIES: {
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          operators: action.operators || state.dictionaries.operators,
        }
      };
    }

    case ARTWORK_REQ_QUEUE_RECEIVE: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          requests: false,
          more: false
        },
        requestsType: action.requestsType,
        data: [...action.data],
        total: action.total
      };
    }

    case ARTWORK_REQ_QUEUE_ASSIGN_REQUEST: {
      const {requestId, values} = action;
      const total = state.total - 1;
      const data = [...state.data];
      const idx = data.findIndex(({RequestID}) => RequestID === requestId);
      if (idx !== -1) {
        data[idx] = {...data[idx], ...values};
      }
      return {
        ...state,
        loaders: {
          ...state.loaders,
          assign: false
        },
        data,
        total
      };
    }

    case ARTWORK_REQ_QUEUE_PICK_REQUEST: {
      const {requestId} = action;
      const total = state.total - 1;
      const data = [...state.data];
      const idx = data.findIndex(({RequestID}) => RequestID === requestId);
      if (idx !== -1) {
        data.splice(idx, 1);
      }
      return {
        ...state,
        loaders: {
          ...state.loaders,
          assign: false
        },
        data,
        total
      };
    }

    case ARTWORK_REQ_QUEUE_SAVED_FILTER: {
      const urlFilters = {...action.urlFilters};
      if (!urlFilters.search) {
        urlFilters.search = null;
      }
      if (!urlFilters.sort) {
        urlFilters.sort = {};
      }

      const selectedFilters = [];
      Object.keys(urlFilters).forEach(key => {
        const value = Array.isArray(urlFilters[key]) && !NonNumericFilters.includes(key) ?
          urlFilters[key].map(item => !isNaN(+item) ? +item : item) :
          urlFilters[key];

        const res = {name: key, value};
        if (key === 'Last Updated') {
          res.disabled = true;
          selectedFilters.unshift(res);
        } else {
          selectedFilters.push(res);
        }
      });

      return {
        ...state,
        selectedFilters
      }
    }

    case ARTWORK_REQ_QUEUE_CHANGE_FILTER: {
      const {selectedFilters} = state;
      const isFilterExist = !!selectedFilters.find(filter => filter.name === action.name);
      if (isFilterExist) {
        return {
          ...state,
          selectedFilters: selectedFilters.map(item => {
            return item.name === action.name ? {
              ...item,
              value: action.value || null
            } : item;
          })
        };
      }
      return {
        ...state,
        selectedFilters: [
          ...selectedFilters,
          {
            name: action.name,
            value: action.value
          }
        ]
      }
    }

    case ARTWORK_REQ_QUEUE_CHANGE_FILTER_TYPE: {
      return {
        ...state,
        selectedFilters: state.selectedFilters.map(item => {
          return item.name === action.filter.name ? {
            ...action.newSelectedFilter,
            title: action.newSelectedFilter.label || action.newSelectedFilter.title,
            value: action.newSelectedFilter.defaultValue ?
              (Array.isArray(action.newSelectedFilter.defaultValue) ? action.newSelectedFilter.defaultValue : [action.newSelectedFilter.defaultValue]) : null
          } : item;
        })
      };
    }

    case ARTWORK_REQ_QUEUE_ADD_FILTERS: {
      const selectedFilters = [...state.selectedFilters];

      const filterIndex = selectedFilters.findIndex(f => f.name === action.filter.name);
      if (filterIndex !== -1) {
        selectedFilters[filterIndex] = {
          ...selectedFilters[filterIndex],
          title: action.filter.label || action.filter.title,
          type: action.filter.type,
          multi: action.filter.multi,
          forFilterName: action.filter.forFilterName,
          hasAdditionalFilters: action.filter.hasAdditionalFilters,
          isStudioParameter: action.filter.isStudioParameter,
          studioID: action.filter.studioID,
          isOrderedValues: action.filter.isOrderedValues,
          orderNum: action.filter.orderNum,
          disabled: action.filter.disabled
        };
      } else {
        const res = {
          ...action.filter,
          name: action.filter.name,
          title: action.filter.label || action.filter.title,
          value: action.filter.defaultValue ?
           (Array.isArray(action.filter.defaultValue) ? action.filter.defaultValue : [action.filter.defaultValue]) : null,
          orderNum: action.filter.orderNum,
          disabled: action.filter.disabled
        };

        if (action.filter.required) {
          selectedFilters.unshift(res);
        } else {
          selectedFilters.push(res);
        }
      }

      return {
        ...state,
        selectedFilters: selectedFilters.sort((a, b) => (a.orderNum || state.allowedFilters.length) - (b.orderNum || state.allowedFilters.length))
      };
    }

    case ARTWORK_REQ_QUEUE_REMOVE_FILTERS: {
      const selectedFilters = [...state.selectedFilters];
      return {
        ...state,
        selectedFilters: selectedFilters.filter(f => f.name !== action.filter.name)
      };
    }

    case ARTWORK_REQ_QUEUE_SET_FILTERS: {
      return {
        ...state,
        allowedFilters: action.allowedFilters
      }
    }

    default: {
      return state;
    }
  }
}
