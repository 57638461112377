import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {login, showLoginErrorMessage, hideLoginMessage, recoverPassword, resetPassword} from './actions';
import LoginLayout from '../../layouts/LoginLayout';
import LoginForm from './components/LoginForm';
import ResetForm from './components/ResetForm';
import RecoverForm from './components/RecoverForm';
import qs from 'qs';
import {isInvalidPasswordWithMessage} from '../../utils';

class Login extends Component {
  state = {
    companyId: null,
    codeTOTP: '',
    email: '',
    password: '',
    forgotPassword: '',
    forgotConfirm: ''
  };

  UNSAFE_componentWillMount() {
    const {dispatch} = this.props;
    dispatch(hideLoginMessage());
  }

  componentDidUpdate(prevProps) {
    const {user} = this.props;
    const isUpdatedCompanies = JSON.stringify(user.companies || null) !== JSON.stringify(prevProps.user.companies || null);
    if (!user.companies) {
      const attemptsToEnterCodeTOTP = user.counterOfTriesToEnterCodeTOTP || 0;
      const isReset2FA = attemptsToEnterCodeTOTP !== (prevProps.user.counterOfTriesToEnterCodeTOTP || 0) && attemptsToEnterCodeTOTP <= 0;
      if (isReset2FA/* || isUpdatedCompanies*/) {
        this.setState({codeTOTP: ''/*, companyId: null*/});
      }
    } else if (isUpdatedCompanies) {
      this.setState({companyId: null});
    }
  }

  formRef = form => {
    this._form = form;
  };

  handleHideMessage = () => {
    const {dispatch} = this.props;
    dispatch(hideLoginMessage());
  };

  handleChange = event => {
    const {name, value} = event.target;
    this.setState({
      [name]: value
    });
    if (name === 'companyId' && value) {
      const {dispatch, history} = this.props;
      dispatch(login(history, this.state.email, this.state.password, this.state.codeTOTP, value));
    }
  };

  handleLogin = event => {
    event.preventDefault();
    const {dispatch, history, user} = this.props;
    const counterOfTriesToEnterCodeTOTP = user.counterOfTriesToEnterCodeTOTP || 0;
    const {codeTOTP, companyId} = this.state;
    if (counterOfTriesToEnterCodeTOTP > 0 && !codeTOTP) {
      dispatch(showLoginErrorMessage('Incorrect authentication code'));
    } else if (user.companies && !companyId) {
      dispatch(showLoginErrorMessage('Incorrect company'));
    } else if (this._form.checkValidity()) {
      dispatch(login(history, this.state.email, this.state.password, codeTOTP, companyId));
    } else {
      dispatch(showLoginErrorMessage('Incorrect email'));
    }
  };

  handleRecover = event => {
    event.preventDefault();
    const {dispatch} = this.props;
    if (this._form.checkValidity()) {
      dispatch(recoverPassword(this.state.email));
      this.setState({email: ''});
    } else {
      dispatch(showLoginErrorMessage('Incorrect email'));
    }
  };

  isCreatingPassword = () => {
    const {location} = this.props;
    return location.pathname.indexOf('create-password') !== -1;
  };

  handleReset = event => {
    event.preventDefault();
    const {dispatch, history, urlParams} = this.props;
    const {forgotPassword, forgotConfirm} = this.state;
    if (forgotPassword !== forgotConfirm) {
      dispatch(showLoginErrorMessage('Password does not match the confirm password'));
    } else {
      const errorMsg = isInvalidPasswordWithMessage(forgotPassword);
      if (errorMsg) {
        dispatch(showLoginErrorMessage(errorMsg));
      } else {
        const [password, key] = [forgotPassword, urlParams.vk];
        dispatch(resetPassword(history, password, key, this.isCreatingPassword()));
      }
    }
  };

  render() {
    const {location, urlParams, user} = this.props;
    const counterOfTriesToEnterCodeTOTP = user.counterOfTriesToEnterCodeTOTP || 0;
    let formContent = null;
    if (location.pathname.indexOf('login') !== -1) {
      formContent = (
        <LoginForm
          email={this.state.email}
          codeTOTP={this.state.codeTOTP}
          onChange={this.handleChange}
          onLogin={this.handleLogin}
          onHideMessage={this.handleHideMessage}
          isNeedCodeTOTP={counterOfTriesToEnterCodeTOTP > 0}
          companyId={this.state.companyId}
          companies={user.companies}
          />
      );
    } else if (!urlParams.vk) {
      formContent = (
        <RecoverForm
          email={this.state.email}
          onChange={this.handleChange}
          onRecover={this.handleRecover}
          onHideMessage={this.handleHideMessage}
          />
      );
    } else {
      formContent = (
        <ResetForm
          isCreatingPassword={this.isCreatingPassword()}
          onChange={this.handleChange}
          onReset={this.handleReset}
          onHideMessage={this.handleHideMessage}
          />
      );
    }

    return (
      <LoginLayout title="Login">
        <form action="#" ref={this.formRef}>
          {formContent}
        </form>
      </LoginLayout>
    );
  }
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    urlParams: qs.parse(ownProps.location.search.substr(1))
  };
}

export default connect(
  mapStateToProps
)(withRouter(Login));
