import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import Calendar from './Calendar';
import ScheduleRow from './ScheduleRow';

const Schedule = props => {
  const {milestones} = props;

  const calendarData = useMemo(() => {
    const result = {};
    let colorIndex = 1;
    milestones.forEach(milestone => {
      if (milestone.StatusUpdateDate) {
        const date = moment(milestone.StatusUpdateDate);
        const month = date.month() + 1;
        const year = date.year();
        if (!result[year]) {
          result[year] = {};
        }
        if (!result[year][month]) {
          result[year][month] = [];
        }
        result[year][month].push({
          date,
          colorIndex
        });
        colorIndex += 1;
      }
    });
    return result;
  }, [milestones]);

  return (
    <StyledSchedule>
      <div className="calendars">
       <div>
        {Object.keys(calendarData).map(year => {
          const yearObj = calendarData[year];
          return Object.keys(yearObj).map(month => {
            return (
              <Calendar
                key={`${month}-${year}`}
                year={year}
                month={month}
                data={yearObj[month]}
              />
            );
          });
        })}
       </div>
      </div>
      <div className="milestones">
        <div className="header">
          <div className="date">Date</div>
          <div className="name">Milestone</div>
        </div>
        <div className="body">
          {milestones.map((milestone, index) => (
            <ScheduleRow
              key={index}
              milestone={milestone}
            />
          ))}
        </div>
      </div>
    </StyledSchedule>
  );
};

const StyledSchedule = styled.div`
  display: flex;
  padding: 15px 30px 0;
  @media (max-width: 767px) {
    padding: 0;
    flex-direction: column;
    background-color: #f9f9f9;
  }
  .calendars {
    background: #ffffff;
    margin-right: 25px;
    margin-bottom: 21px;
    padding: 15px 38px 80px;
    width: 421px;
    @media (max-width: 767px) {
      margin: 0;
      padding: 16px;
      width: 100%;
      > div {
        display: flex;
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        @media (pointer:coarse) {
          overflow-x: scroll;
        }
        /*-webkit-overflow-scrolling: touch;*/
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: none;
          background-color: transparent;
        }
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          -webkit-box-shadow: none;
          background-color: #DFDFDF;
        }
      }
    }
  }
  .milestones {
    border-left: 1px solid #C7C7C7;
    color: #000000;
    font-size: 11px;
    flex-grow: 1;
    @media (max-width: 767px) {
      margin-left: 26px;
      background-color: transparent;
    }
    .header {
      display: flex;
      font-weight: bold;
      padding-left: 17px;
      .date {
        font-weight: bold;
        padding-top: 10px;
        padding-left: 7px;
        width: 55px;
      }
      .name {
        flex-grow: 1;
        padding-top: 10px;
      }
      @media (max-width: 767px) {
        font-size: 15px;
        line-height: 16px;
        .date, .name {
          padding-top: 30px;
        }
      }
    }
  }
`

Schedule.propTypes = {
  milestones: PropTypes.array.isRequired
};

export default Schedule;