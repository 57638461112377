import React, {Component} from 'react';
import PropTypes from 'prop-types';
import 'bootstrap';
import SelectAdvanced from '../../../../components/forms/SelectAdvanced';
import Loader from '../../../../components/Loader';
import {Row, Col, Button} from 'react-bootstrap';
import styled from 'styled-components';
import {
  StyledModalBackdrop, StyledModalDialog, StyledModal, StyledIssueModalHeader, StyledCloseModal, StyledIssueModalBody,
  StyledModalSubmitFooter, StyledMobileModal
} from '../../../../styled/ModalStyled';
import {IssueSubHeader} from './IssueSubHeader';
import {IssueStatusesOptions, isApprovedIssueStatus, isPendingApprovalIssueStatus} from '../../utils';

export const prepareStatusOptions = (issue, selectedStatusId) => {
  const {IsIssueForPendingApproval} = issue;
  const isSelectedPendingApproval = isPendingApprovalIssueStatus(selectedStatusId);
  const isStoredPendingApproval = isPendingApprovalIssueStatus(issue.ClientStatusID, issue.ClientStatus);
  return IssueStatusesOptions.filter(o => {
    if (isPendingApprovalIssueStatus(o.value)) {
      return (isSelectedPendingApproval && !IsIssueForPendingApproval) || (isStoredPendingApproval && !!IsIssueForPendingApproval);
    }
    if (isApprovedIssueStatus(o.value)) {
      return !IsIssueForPendingApproval || !(isSelectedPendingApproval || isStoredPendingApproval);
    }
    return true;
  });
};

class IssueFixNotesModal extends Component {
  state = {
    status_id: this.props.currentIssue.ClientStatusID,
    reason_id: null,
    status_note: null
  };

  componentDidMount() {
    document.body.classList.add('modal-open');
  }

  componentWillUnmount() {
    document.body.classList.remove('modal-open');
  }

  handleChangeStatus = (name, value) => {
    if (value !== this.state.status_id) {
      this.setState({reason_id: null, status_note: null, status_id: value});
      if (this._textarea) {
        this._textarea.value = '';
      }
    }
  };

  handleFieldValueChanged = (name, value) => {
    if (value === '') {
      value = null;
    }
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  handleTextareaBlur = event => {
    this.handleFieldValueChanged(event.target.name, event.target.value);
  };

  handleSend = () => {
    this.props.onSend(this.state);
  };

  render() {
    const {currentIssue, reasons, onClose, saving} = this.props;
    const {reason_id, status_note, status_id} = this.state;
    const isShowReasonField = isApprovedIssueStatus(status_id);
    const disabledSend = !status_id || status_id === currentIssue.ClientStatusID || (isShowReasonField && !reason_id);
    return (
      <StyledMobileModal>
        <StyledModalBackdrop className="issue-modal-backdrop" onClick={onClose}/>
        <StyledModalDialog className="mobile-modal-dialog-custom">
          {saving ? <Loader/> : null}
          <StyledModal className="modal-styled-custom">
            <StyledIssueModalHeader severityColor={currentIssue.SeverityColor} className="modal-header-custom">
              <div className="back-btn" onClick={onClose}/>
              <h4>Issue - Fix Notes</h4>
              <StyledCloseModal className="icon-close-modal" onClick={onClose}/>
            </StyledIssueModalHeader>
            <StyledIssueModalBody>
              <IssueSubHeader issue={currentIssue}/>
              <StyledModalBodyContent>
                <Row>
                  <Col md={12} xs={12} className="col--select">
                    <SelectAdvanced
                      label="Client Status"
                      labelSeparator=""
                      name="status_id"
                      placeholder=""
                      options={prepareStatusOptions(currentIssue, status_id)}
                      value={status_id}
                      onSelect={this.handleChangeStatus}
                      />
                  </Col>
                </Row>
                {isShowReasonField && status_id !== currentIssue.ClientStatusID &&
                  <Row>
                    <Col md={12} xs={12} className="col--select">
                      <SelectAdvanced
                        label="Reason"
                        labelSeparator=""
                        name="reason_id"
                        placeholder=""
                        options={reasons}
                        value={reason_id}
                        onSelect={this.handleFieldValueChanged}
                        />
                    </Col>
                  </Row>
                }
                <Row>
                  <Col md={7} xs={12} className="approve-comment">
                    <div className="form-group">
                      <div className="field">
                        <i className="icon-comment"/>
                        <textarea
                          className="form-control"
                          name="status_note"
                          rows="3"
                          maxLength="125"
                          placeholder="Comment..."
                          defaultValue={status_note || ''}
                          onBlur={this.handleTextareaBlur}
                          onChange={this.handleTextareaBlur}
                          ref={r => {this._textarea = r;}}
                          />
                      </div>
                    </div>
                  </Col>
                </Row>
              </StyledModalBodyContent>
            </StyledIssueModalBody>
            <StyledModalSubmitFooter disabled={disabledSend}>
              <div>
                <Button bsStyle="default" onClick={onClose}>Cancel</Button>
                <Button
                  bsStyle="primary"
                  onClick={this.handleSend}
                  disabled={disabledSend}
                  >
                  Submit
                </Button>
              </div>
            </StyledModalSubmitFooter>
          </StyledModal>
        </StyledModalDialog>
      </StyledMobileModal>
    );
  }
}

const StyledModalBodyContent = styled.div`
  padding: 42px 45px 0 50px;

  @media (max-width: 767px) {
    padding: 22px 25px 0 23px;
  }

  .col--select {
    @media (min-width: 992px) {
      .field {
        max-width: 274px;
      }
    }

    @media (max-width: 767px) {
      .form-group {
        margin-bottom: 25px;
      }
    }

    label {
      font-weight: bold;
      font-size: 13px;
      line-height: 14px;
      color: #000;
      margin-bottom: 10px;

      @media (max-width: 767px) {
        font-size: 15px;
        line-height: 16px;
        margin-bottom: 9px;
      }
    }
    .Select.is-searchable.Select--single {
      .Select-control {
        .Select-arrow-zone img {
          opacity: 0.4;
        }
        .Select-multi-value-wrapper .Select-value {

          @media (max-width: 767px) {
            padding-top: 10px;
          }

          .Select-value-label {
            font-weight: normal;
          }
        }
      }
      .Select-menu-outer {
        .Select-option {
          &.is-disabled {
            color: #757575 !important;
            opacity: 0.75;
          }
        }
      }
    }
  }
  .approve-comment {
    .field {
      position: relative;
      i {
        position: absolute;
        top: 16px;
        left: 20px;
        font-size: 19px;
        color: #adadad;
      }
      textarea {
        background: #F9F9F9;
        border: 1px solid #EAEAEA;
        box-sizing: border-box;
        border-radius: 3px;
        padding: 16px 20px 16px 50px;
        height: 146px;
        width: 100%;
        overflow: auto;
        resize: none;
        box-shadow: none !important;
        outline: none !important;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #000;

        @media (max-width: 767px) {
          font-size: 13px;
          line-height: 15px;
        }

        &:focus {
          background: #fff;
          border-color: #b3b3b3 #ccc #d9d9d9;
        }

        &::placeholder {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: #000;
          opacity: 0.7;

          @media (max-width: 767px) {
            font-size: 13px;
            line-height: 15px;
          }
        }
      }
    }
  }
`;

IssueFixNotesModal.propTypes = {
  saving: PropTypes.bool.isRequired,
  currentIssue: PropTypes.object.isRequired,
  reasons: PropTypes.array.isRequired,
  onSend: PropTypes.func.isRequired,
  onClose: PropTypes.func
};

IssueFixNotesModal.defaultProps = {
  onClose: () => {}
};

export default IssueFixNotesModal;
