import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PageComponent from '../../components/PageComponent';
import AppLayout from '../../layouts/AppLayout';
import {getRequestByWOMemfis} from './actions';
import Loader from '../../components/Loader';

class RequestByWOMemfis extends PageComponent {
  componentDidMount() {
    const {dispatch, history, match} = this.props;
    dispatch(getRequestByWOMemfis(history, match.params.woID));
  }

  render() {
    const {requestwomemfis} = this.props;
    const {loading} = requestwomemfis;

    return (
      <AppLayout title="Request by WOID" withMainHeader={false}>
        {loading ? <Loader className="full-screen"/> : null}
      </AppLayout>
    );
  }
}

RequestByWOMemfis.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  requestwomemfis: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    requestwomemfis: state.requestwomemfis,
    user: state.user,
    main: state.main
  };
}

export default connect(
  mapStateToProps
)(withRouter(RequestByWOMemfis));
