import styled from 'styled-components';
import AppLayout from '../../layouts/AppLayout';

const StyledNewRequest = styled(AppLayout)`
  .std-requests, .original-report, .distribution-step, .cs-tags {
    .table-info {
      margin: 11px 0;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 16px;
      color: #000;
    }
  
    .table-buttons-container {
      width: 275px;
      max-width: 100%;
      position: relative;
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      display: -webkit-box;
      display: -moz-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
  
      .form-group, .icon-filter, .img-filter {
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
      }
  
      .icon-filter {
        font-size: 16px;
        opacity: 0.5;
        padding: 0 16px 0 21px;
      }
  
      .img-filter {
        width: 16px;
        max-height: 31px;
        margin: 0 16px 0 21px;
      }
  
      .form-group {
        width: 100%;
        margin-bottom: 20px;
  
        .form-control {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 29px;
          color: #000;
          background-color: #fff;
          border: 1px solid #eee;
          border-radius: 5px;
          box-sizing: border-box;
          box-shadow: none;
          padding: 0 10px 0 29px;
          height: auto;
        }
      }
    }
  
    .std-requests-table, .reports-table, .distributors-table {
  
      table.table {
  
        th, td {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 14px;
          text-align: left;
        }
  
        th {
          background: #5c5c5c;
          color: #fff;
          font-weight: 500;
          text-transform: unset;
          padding: 17px 16px 17px 0;
  
          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            padding-left: 26px;
            width: 90px;
          }
  
          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
  
        td {
          color: #000;
          border-top: 0.5px solid #DADADA;
          padding-left: 0;
          padding-right: 16px;
  
          &:nth-child(2) {
            font-weight: 500;
          }
  
          &:first-child {
            padding: 19px 0 19px 26px;
          }
        }
  
        tr.selected-row {
          background-color: #F9F9F9;
  
          &:not(:last-child) {
            box-shadow: 0 20px 25px rgba(0, 0, 0, 0.05);
          }
  
          td {
            &:first-child {
              padding-top: 19px;
            }
          }
        }
  
        tbody tr:not(.selected-row) {
          cursor: pointer;
  
          .icon-circle-check {
            color: #F3F3F3;
          }
  
          &:hover {
            .icon-circle-check {
              color: #dadada;
            }
          }
        }
      }
  
      .icon-circle-check {
        font-size: 21.6px;
        color: #E75593;
      }

      &.cs-tags-table {
        table.table {
          tr {
            cursor: default !important;
          }
          th, td {
            &.req-version-name {
              width: 25%;
            }
            &.req-title {
              width: 35%;
            }
            &.req-tag {
              padding-left: 16px !important;
              width: 15%;
            }
            &.req-additional-desc {
              width: 25%;
            }
          }
        }
      }
    }
  
    .std-requests-table, .reports-table {
      .table > tbody > tr:first-child > td {
        border-top: 0;
        &.data-table-empty {
          text-align: center;
          width: 100% !important;
          padding-right: 26px;
        }
      }
    }
  }
  
  .new-request {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100vh;
    background: #FFFFFF;
    font-family: 'Roboto', sans-serif;
  
    .new-request-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 100vh;
      background: linear-gradient(122.1deg, #0DB4D9 0%, rgba(2, 80, 173, 0.25) 99.75%), #004A8D;
  
      &>.loader-container {
        position: absolute;
        top: calc(50% - 16px);
        left: calc(50% - 16px);
        padding: 0;
        z-index: 1;
      }
  
      .loader-container+.step-content-container,
      .loader-container+.step-content-container+.step-content-container,
      .loader-container+.step-content-container+.step-content-container+.step-content-container,
      .loader-container+div{
        pointer-events: none;
      }
  
      .btn {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 38px;
        max-height: 38px;
        text-align: center;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 100px;
  
        &.btn-default {
          padding: 0;
          background: transparent;
          color: #000;
  
          &.btn-back {
            opacity: 0.4;
          }
  
          &:not([disabled]):hover {
            opacity: 1;
            background: transparent;
          }
        }
  
        &.btn-primary {
          padding: 0 36px;
          background: #33333F;
          opacity: 0.8;
          
          &:disabled, &.disabled {
            background: #e0e0e1;
            color: #c8c8c8;
            cursor: default;
          }
  
          &:not([disabled]):hover {
            opacity: 1;
          }
        }
      }
  
      .step-content-container {
        margin: 0;
        padding: 0 15px;
      }
  
      .step-body, .step-footer {
        &.step-choices {
          margin: 0 37px 0 99px;
        }
      }
  
      .step-body-wrapper {
        margin: 32px 20px 10px 73px;
      }
  
      .select-files {
        margin-top: 39px;
      }

      .Select--single {
        .Select-control {
          .Select-value .Select-value-label i.in-wizard {
            font-weight: normal !important;
            padding-left: 10px;
          }
        }
        .Select-menu-outer {
          .Select-option i.in-wizard {
            font-weight: normal;
            padding-left: 10px;
            opacity: 0.8;
          }
        }
      }
    }
  }
  
  .new-request-step-wrapper {
    position: relative;
  
    .loader-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 3;
  
      .react-spinner {
        top: 49%;
      }
    }
  }
`;

export default StyledNewRequest;