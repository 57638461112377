import React from 'react';
import PropTypes from 'prop-types';
import {formatDateTz} from '../../../../utils';
import styled from 'styled-components';

export const SubmittedOnCell = React.memo(({value, className, title}) => {
  const date = formatDateTz(value, '', 'll HH:mm A z');
  return date ? (
    <StyledCell className={className} title={title}>
      <span>Submitted on </span><strong>{date}</strong>
    </StyledCell>
  ) : <StyledCell className={className}/>;
});

const StyledCell = styled.td`
  @media (min-width: 768px) {
    span {
      display: none;
    }
    strong {
      font-weight: normal;
    }
  }
  @media (max-width: 767px) {
    .requests-content & {
      font-size: 11px !important;
      line-height: 12px !important;
      color: #000 !important;
      padding-bottom: 14px !important;
    }
  }
`;

SubmittedOnCell.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string,
};
