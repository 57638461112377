import $ from 'jquery';
import config from '../../../config';
import {getErrorMessage, getErrorMessageByCode, getCurrentDomainObj, allowViewUserAdminPage} from '../../utils';
import {showSuccess, showError, hideAllNotifications, showInfo} from '../../layouts/actions';
import Cookies from 'js-cookie';
import {setUserDataField} from '../Login/actions';

export const ADMIN_USER_ALL = 'ADMIN_USER_ALL';
export const ADMIN_USER_CREATE_NEW = 'ADMIN_USER_CREATE_NEW';
export const ADMIN_USER_EDIT = 'ADMIN_USER_EDIT';
export const ADMIN_USER_COMPANY_LIST_EDIT = 'ADMIN_USER_COMPANY_LIST_EDIT';
export const ADMIN_USER_IS_SAVING = 'ADMIN_USER_IS_SAVING';
export const ADMIN_COMPANY_IS_SAVING = 'ADMIN_COMPANY_IS_SAVING';
export const ADMIN_COMPANY_IS_LOADING = 'ADMIN_COMPANY_IS_LOADING';
export const ADMIN_COMPANY_ALL = 'ADMIN_COMPANY_ALL';
export const ADMIN_COMPANY_UPDATE = 'ADMIN_COMPANY_UPDATE';
export const ADMIN_COMPANY_TO_EDIT = 'ADMIN_COMPANY_TO_EDIT';
export const ADMIN_COMPANY_DELETE = 'ADMIN_COMPANY_DELETE';
export const ADMIN_COMPANY_CREATE_IS_LOADING = 'ADMIN_COMPANY_CREATE_IS_LOADING';
export const ADMIN_COMPANY_INFO_TO_CREATE = 'ADMIN_COMPANY_INFO_TO_CREATE';
export const ADMIN_COMPANY_FILTERS_IS_LOADING = 'ADMIN_COMPANY_FILTERS_IS_LOADING';
export const ADMIN_COMPANY_FILTERS_LOAD = 'ADMIN_COMPANY_FILTERS_LOAD';
export const ADMIN_USER_MFA_MODAL_SHOW = 'ADMIN_USER_MFA_MODAL_SHOW';
export const ADMIN_USER_MFA_MODAL_CLOSE = 'ADMIN_USER_MFA_MODAL_CLOSE';
export const ADMIN_USER_IS_LOADING = 'ADMIN_USER_IS_LOADING';
export const ADMIN_DISTRIBUTOR_IS_LOADING = 'ADMIN_DISTRIBUTOR_IS_LOADING';
export const ADMIN_DISTRIBUTOR_IS_SAVING = 'ADMIN_DISTRIBUTOR_IS_SAVING';
export const ADMIN_DISTRIBUTOR_ALL = 'ADMIN_DISTRIBUTOR_ALL';
export const ADMIN_DISTRIBUTOR_MODAL_DATA = 'ADMIN_DISTRIBUTOR_MODAL_DATA';
export const ADMIN_MERGE_INTO_SYSTEM_DISTR_MODAL_DATA = 'ADMIN_MERGE_INTO_SYSTEM_DISTR_MODAL_DATA';
export const ADMIN_DISTRIBUTOR_DELETE = 'ADMIN_DISTRIBUTOR_DELETE';
export const ADMIN_DISTRIBUTOR_UPDATE = 'ADMIN_DISTRIBUTOR_UPDATE';
export const ADMIN_USER_MANAGE_PERMISSIONS_MODAL_SHOW = 'ADMIN_USER_MANAGE_PERMISSIONS_MODAL_SHOW';
export const ADMIN_USER_MANAGE_PERMISSIONS_MODAL_CLOSE = 'ADMIN_USER_MANAGE_PERMISSIONS_MODAL_CLOSE';
export const ADMIN_USER_MANAGE_PERMISSIONS_CHANGE = 'ADMIN_USER_MANAGE_PERMISSIONS_CHANGE';
export const ADMIN_SERIES_IS_LOADING = 'ADMIN_SERIES_IS_LOADING';
export const ADMIN_SERIES_ALL = 'ADMIN_SERIES_ALL';

export const closeCreateNewUserPopup = () => ({
  type: ADMIN_USER_CREATE_NEW
});

export const showCreateNewUserPopup = fromUser => dispatch => {
  dispatch(hideAllNotifications());
  dispatch({type: ADMIN_USER_CREATE_NEW, data: {isShow: true, fromUser}});
};

const ErrorCodes = {
  QOD_USER_ALREADY_EXIST: 44,
  COULD_NOT_SEND_EMAIL: 45,
  EMAIL_ALREADY_USED: 46
};

const prepareValues = values => {
  const result = {...values};
  Object.keys(result).forEach(k => {
    if (result[k] === null || result[k] === '' || (k !== 'netflixUserId' && result[k] <= 0) || (k === 'netflixUserId' && result[k] === 0)) {
      delete result[k];
    }
  });
  return result;
};

export const createNewUser = values => (dispatch, getState) => {
  dispatch(hideAllNotifications());
  dispatch({type: ADMIN_USER_CREATE_NEW, data: {isSaving: true}});
  const {users} = getState().admin.allUsers;
  const {email} = values;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/user/new`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(prepareValues({...values, userRole: undefined}))
  })
  .done(({user, isSentWelcomeEmail}) => {
    dispatch(closeCreateNewUserPopup());
    const aboutEmail = typeof isSentWelcomeEmail !== 'boolean' ? '' : `.  ${isSentWelcomeEmail ? `Email sent to ${email}.` : 'Could not send welcome email.'}`;
    dispatch(showSuccess(`User has been successfully created${aboutEmail}`));
    if (user) {
      dispatch({type: ADMIN_USER_ALL, data: {users: [...users, user]}});
    }
  })
  .fail(error => {
    dispatch({type: ADMIN_USER_CREATE_NEW, data: {isSaving: false}});
    let errorMessage = getErrorMessageByCode(error, ErrorCodes.COULD_NOT_SEND_EMAIL);
    if (errorMessage) {
      dispatch(showError(`User has been successfully created. But ${errorMessage.toLowerCase()}`));
    } else {
      errorMessage =
        getErrorMessageByCode(error, ErrorCodes.QOD_USER_ALREADY_EXIST) ||
        getErrorMessageByCode(error, ErrorCodes.EMAIL_ALREADY_USED) || getErrorMessage(error);
      dispatch(showError(`Could not create new user${errorMessage ? `. ${errorMessage}` : ''}`));
    }
  });
};

export const getUsersAndFilters = () => dispatch => {
  dispatch({type: ADMIN_USER_ALL, data: {isLoading: true}});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/user`
  })
  .done(res => {
    const {users, filters} = res;
    dispatch({
      type: ADMIN_USER_ALL,
      data: {
        isLoading: false,
        users: users || [],
        filters
      }
    });
  })
  .fail(error => {
    dispatch({type: ADMIN_USER_ALL, data: {isLoading: false}});
    dispatch(showError(`Could not get ${allowViewUserAdminPage() ? 'users' : 'data'}.  ${getErrorMessage(error)}`));
  });
};

export const toggleEnableUser = (userID, active) => (dispatch, getState) => {
  dispatch({type: ADMIN_USER_IS_SAVING, saving: true});
  const {users} = getState().admin.allUsers;
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/user/${userID}/active`,
    data: {
      active
    }
  })
  .done(() => {
    dispatch(showSuccess(`User has been successfully ${active ? 'enabled' : 'disabled'}`));
    users.find(i => i.userID === userID).active = active;
    dispatch({type: ADMIN_USER_ALL, data: {users}});
    dispatch({type: ADMIN_USER_IS_SAVING, saving: false});
  })
  .fail(error => {
    dispatch({type: ADMIN_USER_IS_SAVING, saving: false});
    dispatch(showError(`Could not ${active ? 'enable' : 'disable'} user.  ${getErrorMessage(error)}`));
  });
};

export const updateUserEmployee = (userID, employeeAccountNo) => (dispatch, getState) => {
  dispatch({type: ADMIN_USER_IS_SAVING, saving: true});
  const {users} = getState().admin.allUsers;
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/user/${userID}/employee`,
    data: {
      employee: employeeAccountNo
    }
  })
  .done(() => {
    dispatch(showSuccess('User data has been successfully updated'));
    users.find(i => i.userID === userID).employeeAccountNo = employeeAccountNo;
    dispatch({type: ADMIN_USER_ALL, data: {users}});
    dispatch({type: ADMIN_USER_IS_SAVING, saving: false});
  })
  .fail(error => {
    dispatch({type: ADMIN_USER_IS_SAVING, saving: false});
    dispatch(showError(`Could not update employee for user.  ${getErrorMessage(error)}`));
  });
};

export const updateUserFlags = (userID, flags) => (dispatch, getState) => {
  dispatch({type: ADMIN_USER_IS_SAVING, saving: true});
  const {users} = getState().admin.allUsers;
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/user/${userID}/flags`,
    data: {
      flags
    }
  })
  .done(res => {
    dispatch(showSuccess('User settings has been successfully updated'));
    const user = users.find(i => i.userID === userID);
    user.flags = flags;
    dispatch({type: ADMIN_USER_ALL, data: {users}});
    dispatch({type: ADMIN_USER_IS_SAVING, saving: false});
    if (res && res.roles && getState().user.email === user.email) {
      Cookies.set('__role', res.roles, getCurrentDomainObj());
      dispatch(setUserDataField('role', res.roles));
      window.location.reload();
    }
  })
  .fail(error => {
    dispatch({type: ADMIN_USER_IS_SAVING, saving: false});
    dispatch(showError(`Could not update settings for user.  ${getErrorMessage(error)}`));
  });
};

export const uploadUserAvatar = (userID, file) => (dispatch, getState) => {
  dispatch({type: ADMIN_USER_IS_SAVING, saving: true});
  const {users} = getState().admin.allUsers;
  const user = users.find(i => i.userID === userID);
  const prevImageUrl = user.imageUrl;
  if (file.preview) {
    user.imageUrl = file.preview;
    dispatch({type: ADMIN_USER_ALL, data: {users}});
  }
  const data = new FormData();
  data.append('file', file);
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/user/${userID}/image`,
    data,
    cache: false,
    processData: false,
    contentType: false
  })
  .done(res => {
    if (getState().user.email === user.email) {
      const imageUrl = `${res}?rnd=${Math.random()}`;
      Cookies.set('__avatar', imageUrl, getCurrentDomainObj());
      dispatch(setUserDataField('avatar', imageUrl));
    }
    user.imageUrl = res;
    dispatch({type: ADMIN_USER_ALL, data: {users}});
    dispatch({type: ADMIN_USER_IS_SAVING, saving: false});
    dispatch(showSuccess('Image has been successfully uploaded'));
  })
  .fail(error => {
    user.imageUrl = prevImageUrl;
    dispatch({type: ADMIN_USER_ALL, data: {users}});
    dispatch({type: ADMIN_USER_IS_SAVING, saving: false});
    dispatch(showError(`Could not upload user image.  ${getErrorMessage(error)}`));
  });
};

export const openUserEditPopup = user => ({
  type: ADMIN_USER_EDIT, data: {isShow: true, user}
});

export const closeUserEditPopup = () => ({
  type: ADMIN_USER_EDIT
});

export const editExistingUser = values => (dispatch, getState) => {
  dispatch(hideAllNotifications());
  dispatch({type: ADMIN_USER_EDIT, data: {isSaving: true}});
  const {users} = getState().admin.allUsers;
  const {userID} = values;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/user/edit`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(prepareValues(values))
  })
  .done(user => {
    dispatch(closeUserEditPopup());
    dispatch(showSuccess('User data has been successfully updated'));
    if (user) {
      const index = users.findIndex(i => i.userID === userID)
      users[index] = user;
      dispatch({type: ADMIN_USER_ALL, data: {users}});
    }
  })
  .fail(error => {
    dispatch({type: ADMIN_USER_EDIT, data: {isSaving: false}});
    dispatch(showError(`Could not update user data.  ${getErrorMessage(error)}`));
  });
};

const getFiltersForCompanyAjax = (companyTypeId, companyId) => {
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/company/type/${companyTypeId}/filters`,
    data: {company_id: companyId}
  });
};

const getFiltersForCompany = (companyTypeId, companyId) => dispatch => {
  dispatch({type: ADMIN_COMPANY_FILTERS_IS_LOADING, loading: true});
  return getFiltersForCompanyAjax(companyTypeId, companyId)
  .done(res => {
    dispatch({type: ADMIN_COMPANY_FILTERS_LOAD, data: res});
  })
  .fail(error => {
    dispatch({type: ADMIN_COMPANY_FILTERS_IS_LOADING, loading: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const openCompanyEditPopup = company => dispatch => {
  dispatch(getFiltersForCompany(company.CompanyTypeID, company.CompanyID));
  dispatch({type: ADMIN_COMPANY_TO_EDIT, company});
};

export const closeCompanyEditPopup = () => ({
  type: ADMIN_COMPANY_TO_EDIT
});

export const getCompanies = () => dispatch => {
  dispatch({type: ADMIN_COMPANY_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/company`
  })
  .done(res => {
    dispatch({type: ADMIN_COMPANY_ALL, data: res || []});
  })
  .fail(error => {
    dispatch({type: ADMIN_COMPANY_IS_LOADING, loading: false});
    dispatch(showError(`Could not get companies.  ${getErrorMessage(error)}`));
  });
};

export const getFiltersToCreateCompany = companyType => dispatch => {
  dispatch({type: ADMIN_COMPANY_CREATE_IS_LOADING, loading: true});
  return getFiltersForCompanyAjax(companyType.value)
  .done(res => {
    dispatch({type: ADMIN_COMPANY_INFO_TO_CREATE, data: {...res, companyType}});
  })
  .fail(error => {
    dispatch({type: ADMIN_COMPANY_CREATE_IS_LOADING, loading: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const showCreateCompanyModal = () => ({type: ADMIN_COMPANY_INFO_TO_CREATE, data: {}});

export const closeCreateCompanyModal = () => ({type: ADMIN_COMPANY_INFO_TO_CREATE, data: null});

export const createCompany = (companyType, values, file) => (dispatch, getState) => {
  dispatch({type: ADMIN_COMPANY_CREATE_IS_LOADING, loading: true});
  const data = new FormData();
  data.append('type_id', companyType.value);
  !!file && data.append('file', file);
  Object.keys(values).forEach(k => {
    !!values[k] && data.append(k, values[k]);
  });
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/company/new`,
    data,
    cache: false,
    processData: false,
    contentType: false
  })
  .done(() => {
    dispatch(showSuccess(`The ${companyType.label} has been successfully created`));
    dispatch(closeCreateCompanyModal());
    dispatch(getCompanies());
  })
  .fail(error => {
    dispatch({type: ADMIN_COMPANY_CREATE_IS_LOADING, loading: false});
    const message = (getErrorMessage(error) || '').replace('Finishing House', 'Post Partner');
    dispatch(showError(`Could not create ${companyType.label}.  ${message}`));
    if (message && message.indexOf('already exist') !== -1) {
      dispatch(getCompanies());
    }
  });
};

export const uploadCompanyLogo = (companyID, file) => dispatch => {
  dispatch({type: ADMIN_COMPANY_IS_SAVING, saving: true});
  const data = new FormData();
  data.append('file', file);
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/company/${companyID}/logo`,
    data,
    cache: false,
    processData: false,
    contentType: false
  })
  .done(LogoUrl => {
    dispatch({type: ADMIN_COMPANY_UPDATE, companyID, values: {LogoUrl}});
    dispatch(showSuccess('Company logo has been successfully uploaded'));
  })
  .fail(error => {
    dispatch({type: ADMIN_COMPANY_IS_SAVING, saving: false});
    dispatch(showError(`Could not upload company logo.  ${getErrorMessage(error)}`));
  });
};

export const updateCompany = (companyID, values, needToReloadCompanies) => dispatch => {
  dispatch(hideAllNotifications());
  dispatch({type: ADMIN_COMPANY_IS_SAVING, saving: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/company/${companyID}`,
    data: {...values}
  })
  .done(() => {
    dispatch(showSuccess('Company data has been successfully updated'));
    dispatch({type: ADMIN_COMPANY_UPDATE, companyID, values});
    if (needToReloadCompanies) {
      dispatch(getCompanies());
    }
  })
  .fail(error => {
    dispatch({type: ADMIN_COMPANY_IS_SAVING, saving: false});
    dispatch(showError(`Could not update company data.  ${getErrorMessage(error)}`));
  });
};

export const deleteCompany = companyID => dispatch => {
  dispatch({type: ADMIN_COMPANY_IS_SAVING, saving: true});
  return $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/company/${companyID}`
  })
  .done(() => {
    dispatch(showSuccess('Company data has been successfully deleted'));
    dispatch({type: ADMIN_COMPANY_DELETE, companyID});
  })
  .fail(error => {
    dispatch({type: ADMIN_COMPANY_IS_SAVING, saving: false});
    const message = getErrorMessage(error);
    if (message && message.toLowerCase().indexOf('company is used') !== -1) {
      dispatch({type: ADMIN_COMPANY_UPDATE, companyID, values: {IsUsed: 1}});
    }
    dispatch(showError(`Could not delete company.  ${message}`));
  });
};

export const syncImages = (category) => (dispatch, getState) => {
  const type = `ADMIN_${category.toUpperCase()}_IS_SAVING`;
  dispatch({type, saving: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/${category}/sync_images`,
  })
  .done(res => {
    dispatch({type, saving: false});
    if (typeof res === 'string') {
      dispatch(showSuccess(res, 'Info'));
    } else {
      const {images, error} = res;
      if (!images) {
        dispatch(showError(error || 'Could not sync images.'));
      } else {
        dispatch(showSuccess(!error ? 'Images has been successfully synchronized' :
          `${Object.keys(images).length} image(s) has been successfully synchronized.  ${error}`));
        if (category === 'user') {
          const {users} = getState().admin.allUsers;
          Object.keys(images).forEach(userID => {
            const user = users.find(i => i.userID === Number(userID));
            if (user) {
              user.imageUrl = images[userID];
            }
          });
          dispatch({type: ADMIN_USER_ALL, data: {users}});
        } else if (category === 'company') {
          const {companies} = getState().admin;
          Object.keys(images).forEach(companyID => {
            const company = companies.find(i => i.CompanyID === Number(companyID));
            if (company) {
              company.LogoUrl = images[companyID];
            }
          });
          dispatch({type: ADMIN_COMPANY_ALL, data: companies});
        } else {
          const {distributors} = getState().admin;
          Object.keys(images).forEach(distributorID => {
            const distributor = distributors.find(i => i.DistributorID === Number(distributorID));
            if (distributor) {
              distributor.LogoUrl = images[distributorID];
            }
          });
          dispatch({type: ADMIN_DISTRIBUTOR_ALL, data: distributors});
        }
      }
    }
  })
  .fail(error => {
    dispatch({type, saving: false});
    dispatch(showError(`Could not sync images.  ${getErrorMessage(error)}`));
  });
};

const getDataForUserMFA = (user, isCreate) => dispatch => {
  dispatch({type: ADMIN_USER_IS_LOADING, loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/user/${user.userID}/mfa`,
    data: {is_create: isCreate}
  })
  .done(res => {
    dispatch({type: ADMIN_USER_MFA_MODAL_SHOW, data: {mfa: {...res, needToSave: isCreate}, user}});
  })
  .fail(error => {
    dispatch({type: ADMIN_USER_IS_LOADING, loading: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const showModalUserManageMFA = user => dispatch => {
  dispatch(getDataForUserMFA(user));
};

export const closeModalUserManageMFA = () => ({type: ADMIN_USER_MFA_MODAL_CLOSE});

export const createUserMFA = user => dispatch => {
  dispatch(getDataForUserMFA(user, true));
};

export const resetUserMFA = user => dispatch => {
  dispatch({type: ADMIN_USER_IS_LOADING, loading: true});
  return $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/user/${user.userID}/mfa`
  })
  .done(() => {
    dispatch(showSuccess('The secret key has been successfully reset'));
    dispatch({type: ADMIN_USER_MFA_MODAL_SHOW, data: {mfa: {}, user}});
  })
  .fail(error => {
    dispatch({type: ADMIN_USER_IS_LOADING, loading: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const saveUserMFA = (user, {code, key}) => dispatch => {
  dispatch({type: ADMIN_USER_IS_LOADING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/user/${user.userID}/mfa`,
    data: {key}
  })
  .done(() => {
    dispatch(showSuccess('The secret key has been successfully saved'));
    dispatch({type: ADMIN_USER_MFA_MODAL_SHOW, data: {mfa: {code, key}, user}});
  })
  .fail(error => {
    dispatch({type: ADMIN_USER_IS_LOADING, loading: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const sendIntroEmailForUser = user => dispatch => {
  dispatch({type: ADMIN_USER_IS_LOADING, loading: true});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/user/${user.userID}/intro_email`
  })
  .done(() => {
    dispatch(showSuccess('Intro email has been successfully sent!'));
    dispatch({type: ADMIN_USER_IS_LOADING, loading: false});
  })
  .fail(error => {
    dispatch({type: ADMIN_USER_IS_LOADING, loading: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const getDistributors = () => dispatch => {
  dispatch({type: ADMIN_DISTRIBUTOR_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/distributor`
  })
  .done(res => {
    const {distributors, filters} = res;
    dispatch({type: ADMIN_DISTRIBUTOR_ALL, data: distributors, filters});
  })
  .fail(error => {
    dispatch({type: ADMIN_DISTRIBUTOR_IS_LOADING, loading: false});
    dispatch(showError(`Could not get distributors.  ${getErrorMessage(error)}`));
  });
};

export const getInfoToTurnIntoSystemDistr = (data) => dispatch => {
  dispatch({type: ADMIN_DISTRIBUTOR_IS_SAVING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/distributor/${data.DistributorID}/turn_into_system/pre`,
  })
  .always(() => dispatch({type: ADMIN_DISTRIBUTOR_IS_SAVING, loading: false}))
  .done(res => {
    if (typeof res.reload === 'string') {
      dispatch(showInfo(res.reload));
      dispatch(getDistributors());
    }
  })
  .fail(error => {
    dispatch(showError(`Could not get info to turn into a system distributor.  ${getErrorMessage(error)}`));
  });
};

export const turnIntoSystemDistr = (data) => dispatch => {
  dispatch({type: ADMIN_DISTRIBUTOR_IS_SAVING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/distributor/${data.DistributorID}/turn_into_system/submit`,
  })
  .always(() => dispatch({type: ADMIN_DISTRIBUTOR_IS_SAVING, loading: false}))
  .done(res => {
    if (typeof res.reload === 'string') {
      dispatch(showInfo(res.reload));
    } else {
      dispatch(showSuccess('Turned into a System distributor successfully!'));
    }
    dispatch(getDistributors());
  })
  .fail(error => {
    dispatch(showError(`Could not turn into a system distributor.  ${getErrorMessage(error)}`));
  });
};

export const showMergeIntoSystemDistributorModal = (data) => ({type: ADMIN_MERGE_INTO_SYSTEM_DISTR_MODAL_DATA, data});

export const closeMergeIntoSystemDistributorModal = () => ({type: ADMIN_MERGE_INTO_SYSTEM_DISTR_MODAL_DATA, data: null});

export const getInfoToMergeIntoSystemDistributor = (values) => dispatch => {
  dispatch({type: ADMIN_DISTRIBUTOR_IS_SAVING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/distributor/merge_into_system/pre`,
    data: values
  })
  .always(() => dispatch({type: ADMIN_DISTRIBUTOR_IS_SAVING, loading: false}))
  .done(res => {
    if (typeof res.reload === 'string') {
      dispatch(closeMergeIntoSystemDistributorModal());
      dispatch(showInfo(res.reload));
      dispatch(getDistributors());
    }
  })
  .fail(error => {
    dispatch(showError(`Could not get info to merge into a single system distributor.  ${getErrorMessage(error)}`));
  });
};

export const mergeIntoSystemDistributor = (values) => dispatch => {
  dispatch({type: ADMIN_DISTRIBUTOR_IS_SAVING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/distributor/merge_into_system/submit`,
    data: values
  })
  .always(() => dispatch({type: ADMIN_DISTRIBUTOR_IS_SAVING, loading: false}))
  .done(res => {
    dispatch(closeMergeIntoSystemDistributorModal());
    if (typeof res.reload === 'string') {
      dispatch(showInfo(res.reload));
    } else {
      dispatch(showSuccess('Merged into a System distributor successfully!'));
    }
    dispatch(getDistributors());
  })
  .fail(error => {
    dispatch(showError(`Could not merge into a single system distributor.  ${getErrorMessage(error)}`));
  });
};

export const showCreateDistributorModal = () => ({type: ADMIN_DISTRIBUTOR_MODAL_DATA, data: {}});

export const showEditDistributorModal = (data) => ({type: ADMIN_DISTRIBUTOR_MODAL_DATA, data});

export const closeCreateOrEditDistributorModal = () => ({type: ADMIN_DISTRIBUTOR_MODAL_DATA, data: null});

export const saveDistributor = (values, file) => dispatch => {
  dispatch({type: ADMIN_DISTRIBUTOR_IS_SAVING, loading: true});
  const data = new FormData();
  !!file && data.append('file', file);
  Object.keys(values).forEach(k => {
    !!values[k] && data.append(k, values[k]);
  });
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/distributor/s/${values.DistributorID || 'new'}`,
    data,
    cache: false,
    processData: false,
    contentType: false
  })
  .done(() => {
    dispatch(closeCreateOrEditDistributorModal());
    dispatch({type: ADMIN_DISTRIBUTOR_IS_SAVING, loading: false});
    dispatch(showSuccess(`Distributor has been successfully ${values.DistributorID ? 'updated' : 'created'}`));
    dispatch(getDistributors());
  })
  .fail(error => {
    dispatch({type: ADMIN_DISTRIBUTOR_IS_SAVING, loading: false});
    dispatch(showError(`Could not ${values.DistributorID ? 'update' : 'create'} distributor.  ${getErrorMessage(error)}`));
  });
};

export const uploadDistributorLogo = (distributorID, file) => dispatch => {
  dispatch({type: ADMIN_DISTRIBUTOR_IS_SAVING, loading: true});
  const data = new FormData();
  data.append('file', file);
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/distributor/${distributorID}/logo`,
    data,
    cache: false,
    processData: false,
    contentType: false
  })
  .done(LogoUrl => {
    dispatch({type: ADMIN_DISTRIBUTOR_UPDATE, distributorID, values: {LogoUrl}});
    dispatch(showSuccess('Distributor logo has been successfully uploaded'));
  })
  .fail(error => {
    dispatch({type: ADMIN_DISTRIBUTOR_IS_SAVING, loading: false});
    dispatch(showError(`Could not upload distributor logo.  ${getErrorMessage(error)}`));
  });
};

export const deleteDistributor = distributorID => dispatch => {
  dispatch({type: ADMIN_DISTRIBUTOR_IS_SAVING, loading: true});
  return $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/distributor/${distributorID}`
  })
  .done(() => {
    dispatch(showSuccess('Distributor data has been successfully deleted'));
    dispatch({type: ADMIN_DISTRIBUTOR_DELETE, distributorID});
  })
  .fail(error => {
    dispatch({type: ADMIN_DISTRIBUTOR_IS_SAVING, loading: false});
    const message = getErrorMessage(error);
    if (message && message.toLowerCase().indexOf('Distributor is used') !== -1) {
      dispatch({type: ADMIN_DISTRIBUTOR_UPDATE, distributorID, values: {IsUsed: 1}});
    }
    dispatch(showError(`Could not delete distributor.  ${message}`));
  });
};

export const getUserPermissions = (user, filters, isLoadMore) => (dispatch, getState) => {
  const state = getState();
  const start = isLoadMore ? state.admin.dataToManageUserPermissions.projects.data.length : 0;
  dispatch({type: ADMIN_USER_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/user/${user.userID}/permissions`,
    data: {
      start,
      limit: config.pagingSize,
      filters: JSON.stringify(filters)
    }
  })
  .done(res => {
    const {projects, isUseShowManagersForUserCompany} = res;
    let {data, total} = projects;
    if (isLoadMore) {
      data = [...state.admin.dataToManageUserPermissions.projects.data, ...data];
    }
    dispatch({type: ADMIN_USER_MANAGE_PERMISSIONS_MODAL_SHOW, data: {projects: {data, total}, user, isUseShowManagersForUserCompany}});
  })
  .fail(error => {
    dispatch({type: ADMIN_USER_IS_LOADING, loading: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const showManageUserPermissionsModal = user => dispatch => {
  dispatch(getUserPermissions(user));
};

export const closeManageUserPermissionsModal = () => ({type: ADMIN_USER_MANAGE_PERMISSIONS_MODAL_CLOSE});

export const changeUserPermission = (user, qcProjectId, companyId, permissionTypeId, filters) => (dispatch, getState) => {
  const {isUseShowManagersForUserCompany} = getState().admin.dataToManageUserPermissions;
  dispatch({type: ADMIN_USER_IS_LOADING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/user/${user.userID}/permissions`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      permission: {qcProjectID: qcProjectId, companyID: companyId, permissionTypeID: permissionTypeId},
      filters
    })
  })
  .done(() => {
    dispatch({type: ADMIN_USER_IS_LOADING, loading: false});
    dispatch(showSuccess('The permission has been successfully updated'));
    dispatch(({type: ADMIN_USER_MANAGE_PERMISSIONS_CHANGE, qcProjectId, permissionTypeId, companyId}));
    if (qcProjectId && isUseShowManagersForUserCompany && Object.values(isUseShowManagersForUserCompany).some(Boolean)) {
      dispatch(getUserPermissions(user, filters));
    }
  })
  .fail(error => {
    dispatch({type: ADMIN_USER_IS_LOADING, loading: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const getSeries = () => dispatch => {
  dispatch({type: ADMIN_SERIES_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/series`
  })
  .done(res => {
    const {series} = res;
    dispatch({type: ADMIN_SERIES_ALL, data: series});
  })
  .fail(error => {
    dispatch({type: ADMIN_SERIES_IS_LOADING, loading: false});
    dispatch(showError(`Could not get series.  ${getErrorMessage(error)}`));
  });
};

export const changeShowCode = (seriesId, value) => (dispatch, getState) => {
  dispatch({type: ADMIN_SERIES_IS_LOADING, loading: true});
  const series = [...getState().admin.series];
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/series/${seriesId}/show_code`,
    data: {
      value
    }
  })
  .done(() => {
    dispatch(showSuccess('Show code has been successfully updated'));
    series.find(i => i.SeriesID === seriesId).ShowCode = value;
    dispatch({type: ADMIN_SERIES_ALL, data: series});
  })
  .fail(error => {
    dispatch({type: ADMIN_SERIES_IS_LOADING, loading: false});
    dispatch(showError(`Could not update show code.  ${getErrorMessage(error)}`));
  });
};

export const showModalEditUserCompanyList = user => ({
  type: ADMIN_USER_EDIT, data: {isShowEditCompanyModal: true, user}
});

export const closeEditUserCompanyListModal = () => ({
  type: ADMIN_USER_EDIT, data: {isShowEditCompanyModal: false}
});

export const updateUserCompany = (userID, newUserCompanyList) => (dispatch) => {
  dispatch({type: ADMIN_USER_IS_SAVING, saving: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/user/${userID}/company`,
    data: {
      company_id: newUserCompanyList
    }
  })
  .done(() => {
    dispatch({type: ADMIN_USER_IS_SAVING, saving: false});
    dispatch(showSuccess('Company list has been successfully updated'));
    dispatch({type: ADMIN_USER_EDIT, data: {isShowEditCompanyModal: false}});
    dispatch(getUsersAndFilters());
  })
  .fail(error => {
    dispatch(showError(`Could not update company list for this user.  ${getErrorMessage(error)}`));
  });
};