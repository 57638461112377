import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import SelectAdvanced from '../../../components/forms/SelectAdvanced';

const ForgotPasswordButton = React.memo(({onClick}) => (
  <div className="forgot">
    <Link to="forgot-password" onClick={onClick}>forgot password?</Link>
  </div>
))

class LoginForm extends Component {
  render() {
    const {isNeedCodeTOTP, codeTOTP, onHideMessage, onChange, companyId, companies} = this.props;

    let twoFAInstructions = null;
    if (isNeedCodeTOTP) {
      twoFAInstructions = (
        <div className="totp-instructions">
          You will need to set up two-factor authentication (2FA) in order to receive an Authentication Code and access your account.
          <ul>
            <li>Install an authenticator app to your computer or phone, such as: Authy, Google Authenticator, or Microsoft Authenticator. We recommend installing the Authy app on your mobile device.</li>
            <li>In the app of your choice, click or tap on "Add account" and take a photo of the QR code that can be found in the QOD onboarding email. This code is unique to your account.</li>
            <li>The app will generate a 6-digit code you will need to enter each time you log into QC on Demand.</li>
          </ul>
        </div>
      )
    } else if (companies) {
      twoFAInstructions = (
        <div className="totp-instructions">
          You will need to select the company you want to log in with
        </div>
      )
    }
    return (
      <div>
        {isNeedCodeTOTP ?
          <p>
            <label>Authentication Code</label>
            <span className="text-icon">
              <i className="fas fa-user-secret"/>
            </span>
            <input
              type="text"
              name="codeTOTP"
              placeholder=""
              autoComplete="off"
              autoFocus={true}
              value={codeTOTP}
              onChange={event => {
                onHideMessage();
                onChange(event);
              }}
            />
          </p> : companies ?
          <div className="row-company">
            <label>Company</label>
            {
              companies.length <= 4 ?
                <div className="select-company-cont">
                  {companies.map(c => (
                    <div
                      key={c.value}
                      className={`select-company-item${c.value === companyId ? ' is-selected' : ''}`}
                      title={c.value === companyId ? undefined : 'Click to select a company'}
                      onClick={() => {
                        onHideMessage();
                        onChange({target: {name: 'companyId', value: c.value}});
                      }}
                    >
                      {c.img_url ? <div className="company-logo"><img src={c.img_url} alt=""/></div> : null}
                      {c.name}
                    </div>
                  ))}
                </div> : null
            }
            <span className="text-icon icon-company">
              <i className="fas fa-house-user"/>
            </span>
            <SelectAdvanced
              onlySelectControl
              selectClassName="select-in-cell"
              name="companyId"
              placeholder=""
              options={companies}
              value={companyId}
              onSelect={(name, value) => {
                onHideMessage();
                onChange({target: {name, value}});
              }}
            />
          </div> :
            <>
              <p>
                <label>Email</label>
                <span className="text-icon">
                  <i className="fa fa-user"/>
                </span>
                <input
                  type="email"
                  pattern="[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,8}$"
                  name="email"
                  placeholder=""
                  autoComplete="off"
                  autoFocus={true}
                  value={this.props.email}
                  onChange={onChange}
                  />
              </p>
              <p>
                <label>Password</label>
                <span className="text-icon">
                  <i className="fa fa-lock"/>
                </span>
                <input
                  type="password"
                  name="password"
                  placeholder=""
                  autoComplete="off"
                  onChange={onChange}
                  />
              </p>
            </>
        }
        <p className="row-submit"><input type="submit" name="" value="login" onClick={this.props.onLogin}/></p>
        {!isNeedCodeTOTP && !companies &&
          <ForgotPasswordButton
            onClick={onHideMessage}
          />
        }
        { twoFAInstructions }
      </div>
    );
  }
}

LoginForm.propTypes = {
  email: PropTypes.string,
  codeTOTP: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onHideMessage: PropTypes.func.isRequired,
  isNeedCodeTOTP: PropTypes.bool.isRequired,
  companyId: PropTypes.number,
  companies: PropTypes.array,
};

export default LoginForm;
