import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RequestsTable from './RequestsTable';

const CollapsedRow = ({row, isLoading, onCloseSearch, getViewAllLinkTo}) => {
  const {requests} = row;
  return (
    <CollapsedRowStyled>
      <RequestsTable
        loading={isLoading()}
        subTable
        data={(requests || {}).data || []}
        total={(requests || {}).total || 0}
        viewAllLinkTo={getViewAllLinkTo(row)}
        onCloseSearch={onCloseSearch}
        />
    </CollapsedRowStyled>
  );
};

const CollapsedRowStyled = styled.div`
  background: #f9f9f9;
  padding: 16px 0 0 24px;;
`;

CollapsedRow.propTypes = {
  row: PropTypes.object,
  isLoading: PropTypes.func,
  onCloseSearch: PropTypes.func,
  getViewAllLinkTo: PropTypes.func
};

export default CollapsedRow;
