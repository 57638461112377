import $ from 'jquery';
import config from '../../../config';
import {getErrorMessage} from '../../utils';
import {showSuccess, showError} from '../../layouts/actions';

export const MANUAL_WO_DATA_IS_LOADING = 'MANUAL_WO_DATA_IS_LOADING';
export const SET_MANUAL_WO_DATA = 'SET_MANUAL_WO_DATA';

const toggleLoading = loading => ({type: MANUAL_WO_DATA_IS_LOADING, loading});

export const getManualWorkOrderData = (memfisCompanyID, manualTitleFilter, nextManualWoid) => dispatch => {
  dispatch(toggleLoading(true));
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/manual_wo_data`,
    data: {
      memfis_company: memfisCompanyID,
      manual_title_filter: manualTitleFilter,
      next_manual_woid: nextManualWoid
    }
  })
  .done(res => {
    dispatch({type: SET_MANUAL_WO_DATA, data: res, loadMore: nextManualWoid});
  })
  .fail(error => {
    dispatch(toggleLoading(false));
    dispatch(showError(`Could not get manual work order data.  ${getErrorMessage(error)}`));
  });
};

export const createReplacementFromManualWorkOrder = (manualWOID, requestID, replacementNumber) => dispatch => {
  dispatch(toggleLoading(true));
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/replacement_from_manual`,
    data: {
      origin_request_id: requestID,
      manual_woid: manualWOID,
      replacemet_number: replacementNumber
    }
  })
  .done(res => {
    dispatch(toggleLoading(false));
    dispatch(showSuccess(`Replacement successfully created`));
  })
  .fail(error => {
    dispatch(toggleLoading(false));
    dispatch(showError(`Could not get manual work order data.  ${getErrorMessage(error)}`));
  });
}