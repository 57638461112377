import React from 'react';
import PropTypes from 'prop-types';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import styled from 'styled-components';
import config from '../../../../../config';

export const RequestStatusCell = React.memo(({value, row, className, title}) => {
  return (
    <StyledCell
      className={className}
      title={title}
      statusColor={row.status_color}
      >
      {
        row.RequestStatusID === config.requestStatuses.WAITING_FOR_ASSETS ? (
          <OverlayTrigger
            placement="bottom"
            overlay={<Popover id={`popover-${row.RequestID}-status`}>...</Popover>}
            >
            <span className="waiting-for-assets">{value}</span>
          </OverlayTrigger>
        ) : <span>{value}</span>
      }
    </StyledCell>
  );
});

const StyledCell = styled.td`
  color: ${props => props.statusColor ? `#${props.statusColor}` : '#000'} !important;

  span.waiting-for-assets {
    cursor: pointer;
    text-decoration: underline;
    color: ${props => props.statusColor ? `#${props.statusColor}` : '#28C0F0'};

    &:hover {
      color: #1a99c1;
    }
  }

  @media (max-width: 767px) {
    .requests-content .std-requests-table:not(.client-service) & {
      padding-bottom: 15px !important;
      display: flex !important;
      * {
        pointer-events: none;
      }
      span {
        background-color: ${props => props.statusColor ? `#${props.statusColor}` : 'unset'};
        color: #fff;
        border-radius: 3px;
        text-decoration: none;
        font-size: 10px;
        line-height: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 74px;
        height: 18px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
`;

RequestStatusCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
};
