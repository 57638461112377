import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {formatDate} from '../../../utils';
import arrowRightImg from '../../../../assets/images/notification/arrow-right.svg';
import {isArtworkClientUser} from '../../../utils';

const NTF_TYPE_NEW_ISSUE_COMMENT = 'New Comment';
const NTF_TYPE_NEW_GENERAL_COMMENT = 'New General Comment';
const NTF_TYPE_ISSUE_PENDING_APPROVAL = 'Issue Pending Approval';

class NotificationsList extends Component {
  isArtworkAsset = ({is_artwork_content}) => {
    return !!is_artwork_content || isArtworkClientUser();
  };

  buildNotificationLink = ({type, issue_id, comment_id, request_id, is_artwork_content}) => {
    const urlParams = type === NTF_TYPE_NEW_ISSUE_COMMENT ? `?issue_id=${issue_id}&comment_id=${comment_id}` :
      type === NTF_TYPE_ISSUE_PENDING_APPROVAL ? `?issue_id=${issue_id}&pending_approval=1` :
      type === NTF_TYPE_NEW_GENERAL_COMMENT ? `?general_comment_id=${comment_id}` : '';
    if (this.isArtworkAsset({is_artwork_content})) {
      return `/report?asset_id=${request_id}${urlParams.replace('?', '&')}`;
    }
    return `/requests/${request_id}${urlParams}`;
  };

  clickOnComment = (item) => {
    const commentLink = this.buildNotificationLink(item);
    window.location = commentLink;
  };

  render() {
    const {title, list} = this.props;

    if (!list.length) {
      return null;
    }

    return (
      <StyledNotificationsList>
        <div className="list-title">{title}</div>
        {
          list.map((item, i) => (
            <div className="ntf-item" key={`n-${i}`}>
            <div className={`list-item ${item.category === 'action_items' ? ' action-item' : ''}`}>
              <div className="item-title">
                {item.text}
              </div>
              <div className="item-date">
                {formatDate(item.timestamp, '', 'MMMM D, YYYY HH:mm A')}
              </div>
              <div className="item-link">
                <a href={this.buildNotificationLink(item)}>
                  {item.type === NTF_TYPE_ISSUE_PENDING_APPROVAL ? 'Fix Notes' :
                    [NTF_TYPE_NEW_ISSUE_COMMENT, NTF_TYPE_NEW_GENERAL_COMMENT].includes(item.type) ? 'View Comment' : 'View Report'
                  }
                </a>
              </div>
            </div>
            {item.comment_text &&
              (<div className="item-comment-wrapper">
                <div className="comment-text" title="Comment text, click to discuss" onClick={() => {this.clickOnComment(item);}}>
                  {item.comment_text}
                </div>
              </div>)
            }
            </div>
          ))
        }
      </StyledNotificationsList>
    );
  }
}

const StyledNotificationsList = styled.div`
  color: #000;
  font-weight: normal;
  margin-bottom: 34px;
  &:last-of-type {
    margin-bottom: 0;
  }
  .list-title {
    font-size: 13px;
    line-height: 19px;
    padding: 0 0 15px;
  }
  .list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2px;
    padding: 0 19px 0 0;
    .item-title {
      width: 50%;
      font-size: 14px;
      border-left: 3px solid transparent;
      padding: 12px 8px 15px 19px;
      color: rgb(29, 28, 29);
    }
    .item-date {
      font-size: 11px;
      flex-grow: 1;
      min-width: 100px;
    }
    .item-link {
      text-align: right;
      width: 145px;
      a {
        background: url(${arrowRightImg}) no-repeat right center;
        float: right;
        display: none;
        outline: none;
        font-size: 11px;
        text-align: center;
        text-decoration: none;
        color: #585858;
        padding-right: 34px;
      }
    }
    &.action-item {
      .item-title {
        border-left-color: #FF6D03;
      }
      .item-date {
        color: #4d4d4d;
      }
      .item-link a {
        display: block;
      }
    }
  }

  .ntf-item {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);

    &:hover {
      background: #f9f9f9;
      a {
        display: block;
      }
    }
  }

  .item-comment-wrapper {
    width: 50%;
    margin-bottom: 10px;
    padding: 0 19px 0 32px;

    .comment-text {
      font-size: 13px;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 5px;
      cursor: pointer;
      color: rgb(51, 51, 51);
      word-break: break-word;
      line-height: 1.5em;
    }
  }

  @media (max-width: 991px) {
    margin-bottom: 0;
    .list-title {
      display: none;
    }
    .list-item {
      flex-direction: column;
      align-items: flex-start;
      border: none;
      padding-top: 16px;

      .item-title {
        width: 100%;
        font-size: 15px;
        line-height: 19px;
        padding-left: 20px;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        min-height: 45px;
        align-items: center;
         display: flex;
      }
      .item-date {
        color: #000;
        opacity: 0.7;
        font-size: 13px;
        line-height: 14px;
        min-width: 100%;
        padding-left: 23px;
        padding-top: 7px;
        padding-bottom: 16px;
      }
      .item-link {
        text-align: left;
        width: 100%;
        margin-left: 23px;
        padding-bottom: 1px;
        a {
          color: #000;
          opacity: 0.7;
          float: left;
          padding-right: 24px;
          margin-bottom: 15px;
          margin-top: 2px;
        }
      }

      &:not(.action-item) {
        padding-top: 12px;
      }
    }

    .item-comment-wrapper {
      width: 100%;
    }
  }
`;

NotificationsList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array
};

export default NotificationsList;
