import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {HeaderRow} from '../../components/table/HeaderRow';
import {Row} from '../../components/table/CollapsedRow';

class Table extends Component {
  render() {
    const {headers, rows, sort, onHeaderCellClick, getRowClassNames, keyName, stripped,
      emptyText, rowCollapsedEl, isSeparatedRows, onRowClick, showCollapsedRowId
    } = this.props;

    return (
      <table className={`table${stripped ? ' table-striped' : ''}`}>
        <HeaderRow headers={headers} sort={sort} onHeaderCellClick={onHeaderCellClick}/>
        <tbody>
          {rows.map((row, i) =>
            <Row
              headers={headers}
              row={row}
              key={keyName ? `${row[keyName]}_${i}` : i}
              rowKey={keyName}
              onRowClick={() => onRowClick(i)}
              getRowClassNames={getRowClassNames}
              rowCollapsedEl={rowCollapsedEl}
              isSeparatedRows={isSeparatedRows}
              isShowCollapsedRow={showCollapsedRowId === i}
              />
          )}
          {
            !rows.length && emptyText ? (
              <tr><td className="data-table-empty" colSpan={Object.keys(headers).length}>{emptyText}</td></tr>
            ) : null
          }
        </tbody>
      </table>
    );
  }
}

Table.propTypes = {
  headers: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  sort: PropTypes.object,
  onHeaderCellClick: PropTypes.func,
  getRowClassNames: PropTypes.func,
  keyName: PropTypes.string,
  stripped: PropTypes.bool,
  emptyText: PropTypes.string,
  rowCollapsedEl: PropTypes.object,
  isSeparatedRows: PropTypes.bool,
  showCollapsedRowId: PropTypes.number,
  onRowClick: PropTypes.func
};

Table.defaultProps = {
  stripped: true
};

export default Table;

