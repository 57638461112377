import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../../components/Loader';
import ReplacementForm from '../../../../ReportDetails/components/ReplacementForm';
import styled from 'styled-components';

class SubmitReplacement extends Component {
  UNSAFE_componentWillMount() {
    const {onGetReplacement, startedRequestId} = this.props;
    onGetReplacement(startedRequestId);
  }

  render() {
    const {reportdetails, replacementCount, onChange, files, startedRequestId, allowFileUpload
    } = this.props;
    const {loading, saving, fixlistIssues} = reportdetails;
    return loading ? <Loader/> : (
      <StyledForm>
        <h4>Submit Replacement<span>Replacement {replacementCount + 1}</span></h4>
        <ReplacementForm
          loading={false}
          saving={saving}
          onChange={onChange}
          issues={fixlistIssues}
          files={files}
          requestId={startedRequestId}
          allowFileUpload={allowFileUpload}
          />
      </StyledForm>
    );
  }
}

const StyledForm = styled.div`
  padding: 0;
  h4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    color: #000;
    margin-top: 0;
    margin-bottom: 21px;
    span {
      font-size: 15px;
      line-height: 16px;
      opacity: 0.4;
      padding-left: 18px
    }
  }
  .step-body-wrapper.select-files {
    margin: 0 !important;
  }
`;

SubmitReplacement.propTypes = {
  allowFileUpload: PropTypes.bool.isRequired,
  files: PropTypes.object,
  reportdetails: PropTypes.object.isRequired,
  startedRequestId: PropTypes.number.isRequired,
  replacementCount: PropTypes.number.isRequired,
  onGetReplacement: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SubmitReplacement;
