import React from 'react';
import PropTypes from 'prop-types';
import {WizardCheckbox} from './CheckboxCell';

export const CheckboxHeaderCell = ({isChecked, onChange}) => {
  const checked = isChecked();
  return(
    <th className="checkbox-cell">
      <WizardCheckbox
        checked={checked}
        onChange={event => onChange(event.target.checked)}
        withLabel
        title={`${checked ? 'Des' : 'S'}elect All`}
      />
    </th>
  );
};

CheckboxHeaderCell.propTypes = {
  isChecked: PropTypes.any,
  onChange: PropTypes.func
};
