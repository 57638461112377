import $ from 'jquery';
import qs from 'qs';
import config from '../../../config';
import {isOperatorUser, isDwManagerUser} from '../../utils';
import {CONTENT_TYPES} from '../NewRequest/constants';

export const DASHBOARD_GET_DATA = 'DASHBOARD_GET_DATA';
export const DASHBOARD_GET_REQUEST_QUEUE_DATA = 'DASHBOARD_GET_REQUEST_QUEUE_DATA';
export const DASHBOARD_GET_REQUESTS_BY_STATUS_DATA = 'DASHBOARD_GET_REQUESTS_BY_STATUS_DATA';
export const DASHBOARD_GET_ISSUES_BY_TYPE_DATA = 'DASHBOARD_GET_ISSUES_BY_TYPE_DATA';
export const DASHBOARD_GET_LARGEST_NUMBER_DATA = 'DASHBOARD_GET_LARGEST_NUMBER_DATA';
export const DASHBOARD_GET_PASS_PERCENTAGE_DATA = 'DASHBOARD_GET_PASS_PERCENTAGE_DATA';
export const DASHBOARD_GET_REPLACEMENT_CONST_DATA = 'DASHBOARD_GET_REPLACEMENT_CONST_DATA';
export const DASHBOARD_SET_LOADING = 'DASHBOARD_SET_LOADING';
export const DASHBOARD_CHANGE_CHART_FILTERS = 'DASHBOARD_CHANGE_CHART_FILTERS';
export const DASHBOARD_CHANGE_TOP_FILTERS = 'DASHBOARD_CHANGE_TOP_FILTERS';
export const DASHBOARD_GET_FILTERS_FOR_CONTENT = 'DASHBOARD_GET_FILTERS_FOR_CONTENT';
export const DASHBOARD_CLEAR = 'DASHBOARD_CLEAR';

export const clearDashboard = () => ({type: DASHBOARD_CLEAR});

export const changeChartFilters = (name, value) => ({type: DASHBOARD_CHANGE_CHART_FILTERS, name, value});

export const getDashboardData = () => dispatch => {
  dispatch({type: DASHBOARD_SET_LOADING, name: 'dashboard', value: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/dashboard`
  })
    .done(res => {
      dispatch({type: DASHBOARD_GET_DATA, data: res});
      dispatch({type: DASHBOARD_SET_LOADING, name: 'dashboard', value: false});
    })
    .fail(() => {
      dispatch({type: DASHBOARD_SET_LOADING, name: 'dashboard', value: false});
    })
};

const prepareTopFilters = (values, state) => {
  if (!values) {
    values = {...state.dashboard.selectedTopFilters};
  }
  const {content, season, episode, version, revision} = values;
  const preparedValues = {};
  if (content && content.SeriesID) {
    preparedValues.series_id = content.SeriesID;
    preparedValues.content_type_id = content.ContentTypeID;
    if (season) {
      preparedValues.season_num = season.value;
      if (episode) {
        preparedValues.episode_num = episode.value;
      }
    }
    if (version) {
      preparedValues.req_version = version.value;
    }
    if (revision) {
      preparedValues.req_revision = revision.value;
    }
  }
  return preparedValues;
};

export const prepareTopFiltersForUrl = (values) => {
  const {content, season, episode, version, revision} = values;
  const preparedValues = {};
  if (content && content.SeriesID) {
    preparedValues.series = [content.SeriesID];
    preparedValues.content_type = [content.ContentTypeID];
    if (season) {
      preparedValues.season_num = [season.value];
      if (episode) {
        preparedValues.episode_num = [episode.value];
      }
    }
    if (version) {
      preparedValues.req_version = [version.value];
    }
    if (revision) {
      preparedValues.req_revision = [revision.value];
    }
  }
  return qs.stringify(preparedValues);
};

export const getRequestQueueData = ({day, month, year, type}, topFilters) => (dispatch, getState) => {
  const data = {
    ...prepareTopFilters(topFilters, getState()),
    ...day && {day},
    ...month && {month},
    ...year && {year},
    ...type && {type}
  };

  dispatch({type: DASHBOARD_SET_LOADING, name: 'requestQueue', value: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/dashboard/request_queue`,
    data
  })
  .done(res => {
    dispatch({type: DASHBOARD_GET_REQUEST_QUEUE_DATA, data: res});
    dispatch({type: DASHBOARD_SET_LOADING, name: 'requestQueue', value: false});
  })
  .fail(() => {
    dispatch({type: DASHBOARD_SET_LOADING, name: 'requestQueue', value: false});
  })
};

export const getRequestByStatusData = (period, topFilters) => (dispatch, getState) => {
  dispatch({type: DASHBOARD_SET_LOADING, name: 'requestsByStatus', value: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/dashboard/requests_by_status`,
    data: {
      ...prepareTopFilters(topFilters, getState()),
      period
    }
  })
    .done(res => {
      dispatch({type: DASHBOARD_GET_REQUESTS_BY_STATUS_DATA, data: res['requests_by_status']});
      dispatch({type: DASHBOARD_SET_LOADING, name: 'requestsByStatus', value: false});
    })
    .fail(() => {
      dispatch({type: DASHBOARD_SET_LOADING, name: 'requestsByStatus', value: false});
    })
};

export const getIssueByTypeData = (period, topFilters) => (dispatch, getState) => {
  dispatch({type: DASHBOARD_SET_LOADING, name: 'issueByType', value: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/dashboard/issues_by_type`,
    data: {
      ...prepareTopFilters(topFilters, getState()),
      period
    }
  })
  .done(res => {
    dispatch({type: DASHBOARD_GET_ISSUES_BY_TYPE_DATA, data: res['issues_by_type']});
    dispatch({type: DASHBOARD_SET_LOADING, name: 'issueByType', value: false});
  })
  .fail(() => {
    dispatch({type: DASHBOARD_SET_LOADING, name: 'issueByType', value: false});
  })
};

export const getLargestNumberData = ({period, group}, topFilters) => (dispatch, getState) => {
  dispatch({type: DASHBOARD_SET_LOADING, name: 'numberOfReplacements', value: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/dashboard/replacement/largest_number`,
    data: {
      ...prepareTopFilters(topFilters, getState()),
      period,
      group
    }
  })
  .done(res => {
    dispatch({type: DASHBOARD_GET_LARGEST_NUMBER_DATA, data: res['replacement_largest_number']});
    dispatch({type: DASHBOARD_SET_LOADING, name: 'numberOfReplacements', value: false});
  })
  .fail(() => {
    dispatch({type: DASHBOARD_SET_LOADING, name: 'numberOfReplacements', value: false});
  })
};

export const getReplacementConstData = ({period, group}, topFilters) => (dispatch, getState) => {
  dispatch({type: DASHBOARD_SET_LOADING, name: 'replacementCost', value: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/dashboard/replacement/cost`,
    data: {
      ...prepareTopFilters(topFilters, getState()),
      period,
      group
    }
  })
  .done(res => {
    dispatch({type: DASHBOARD_GET_REPLACEMENT_CONST_DATA, data: res['replacement_cost']});
    dispatch({type: DASHBOARD_SET_LOADING, name: 'replacementCost', value: false});
  })
  .fail(() => {
    dispatch({type: DASHBOARD_SET_LOADING, name: 'replacementCost', value: false});
  })
};

export const getPassPercentageData = ({period, group}, topFilters) => (dispatch, getState) => {
  dispatch({type: DASHBOARD_SET_LOADING, name: 'passPercentage', value: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/dashboard/pass`,
    data: {
      ...prepareTopFilters(topFilters, getState()),
      period,
      group
    }
  })
    .done(res => {
      dispatch({type: DASHBOARD_GET_PASS_PERCENTAGE_DATA, data: res['pass_percentage']});
      dispatch({type: DASHBOARD_SET_LOADING, name: 'passPercentage', value: false});
    })
    .fail(() => {
      dispatch({type: DASHBOARD_SET_LOADING, name: 'passPercentage', value: false});
    })
};

export const changeTopFilters = (filterName, filtersValues) => (dispatch, getState) => {
  const {dictionaries, selectedChartFilters} = getState().dashboard;
  dispatch({type: DASHBOARD_CHANGE_TOP_FILTERS, values: filtersValues});
  const {content, season} = filtersValues;
  if (filterName === 'season' && season) {
    const contentKey = `${content.SeriesID}--s-${season.value}`;
    if (!dictionaries.contentMap[contentKey]) {
      const loadingName = 'filtersForContent';
      dispatch({type: DASHBOARD_SET_LOADING, name: loadingName, value: true});
      $.ajax({
        method: 'GET',
        url: `${config.apiUrl}/v1/qc_on_demand/dashboard/filters/season`,
        data: {
          series_id: content.SeriesID,
          season_num: season.value
        }
      })
      .done(res => {
        dispatch({type: DASHBOARD_SET_LOADING, name: loadingName, value: false});
        dispatch({type: DASHBOARD_GET_FILTERS_FOR_CONTENT, data: res.data, contentKey});
      })
      .fail(() => {
        dispatch({type: DASHBOARD_SET_LOADING, name: loadingName, value: false});
      });
    }
  } else if (filterName === 'content' && content && content.SeriesID &&
             [CONTENT_TYPES.Feature, CONTENT_TYPES.Trailer].includes(content.ContentTypeID)) {
    const contentKey = `${content.SeriesID}--${content.ContentTypeID}`;
    if (!dictionaries.contentMap[contentKey]) {
      const loadingName = 'filtersForContent';
      dispatch({type: DASHBOARD_SET_LOADING, name: loadingName, value: true});
      $.ajax({
        method: 'GET',
        url: `${config.apiUrl}/v1/qc_on_demand/dashboard/filters/content`,
        data: {
          series_id: content.SeriesID,
          content_type_id: content.ContentTypeID
        }
      })
      .done(res => {
        dispatch({type: DASHBOARD_SET_LOADING, name: loadingName, value: false});
        dispatch({type: DASHBOARD_GET_FILTERS_FOR_CONTENT, data: res.data, contentKey});
      })
      .fail(() => {
        dispatch({type: DASHBOARD_SET_LOADING, name: loadingName, value: false});
      });
    }
  }
  dispatch(getRequestQueueData(selectedChartFilters.requestQueue, filtersValues));
  dispatch(getRequestByStatusData(selectedChartFilters.requestsByStatus.period, filtersValues));
  dispatch(getIssueByTypeData(selectedChartFilters.issuesByType.period, filtersValues));
  dispatch(getLargestNumberData(selectedChartFilters.replacementLargestNumber, filtersValues));
  if (!(isOperatorUser() || isDwManagerUser())) {
    dispatch(getReplacementConstData(selectedChartFilters.replacementCost, filtersValues));
  }
  dispatch(getPassPercentageData(selectedChartFilters.passPercentage, filtersValues));
};
