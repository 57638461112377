import $ from 'jquery';
import _ from 'lodash';
import config from '../../../config';
import {getErrorMessage, isManagerUser, isOperatorUser, isUserCanComment, getDefaultRedirectUrl} from '../../utils';
import {toggleShowSidebar} from '../../layouts/actions';
import {asperaStartTransfer} from '../NewRequest/actions';
import qs from 'qs';
import {showSuccess, showError, showConfirmAlertToAutoCreateReplacementWO} from '../../layouts/actions';

export const REPORT_DETAILS_CLEAR = 'REPORT_DETAILS_CLEAR';
export const REPORT_DETAILS_IS_LOADING = 'REPORT_DETAILS_IS_LOADING';
export const REPORT_DETAILS_FILTERS_IS_LOADING = 'REPORT_DETAILS_FILTERS_IS_LOADING';
export const REPORT_DETAILS_ISSUES_IS_LOADING = 'REPORT_DETAILS_ISSUES_IS_LOADING';
export const REPORT_DETAILS_SET_TOP_ACTIVE_TAB = 'REPORT_DETAILS_SET_TOP_ACTIVE_TAB';
export const REPORT_DETAILS_TOGGLE_SCHEDULE = 'REPORT_DETAILS_TOGGLE_SCHEDULE';
export const REPORT_DETAILS_SHOW_EDIT_SCHEDULE_MODAL = 'REPORT_DETAILS_SHOW_EDIT_SCHEDULE_MODAL';
export const REPORT_DETAILS_UPDATE_EDIT_MODAL = 'REPORT_DETAILS_UPDATE_EDIT_MODAL';
export const REPORT_DETAILS_UPDATE_MILESTONE = 'REPORT_DETAILS_UPDATE_MILESTONE';
export const REPORT_DETAILS_LOCK_FEATURE = 'REPORT_DETAILS_LOCK_FEATURE';
export const REPORT_DETAILS_SET_LIST_VIEW_TAB = 'REPORT_DETAILS_SET_LIST_VIEW_TAB';
export const REPORT_DETAILS_GET_REQUEST = 'REPORT_DETAILS_GET_REQUEST';
export const REPORT_DETAILS_GET_ISSUES = 'REPORT_DETAILS_GET_ISSUES';
export const REPORT_DETAILS_IS_COMPLETING = 'REPORT_DETAILS_IS_COMPLETING';
export const REPORT_DETAILS_MARK_COMPLETED = 'REPORT_DETAILS_MARK_COMPLETED';
export const REPORT_DETAILS_DISCUSS_ISSUE = 'REPORT_DETAILS_DISCUSS_ISSUE';
export const REPORT_DETAILS_IS_LOADING_ISSUE_COMMENTS = 'REPORT_DETAILS_IS_LOADING_ISSUE_COMMENTS';
export const REPORT_DETAILS_IS_LOADING_ISSUE_COMMENTS_SIDEBAR = 'REPORT_DETAILS_IS_LOADING_ISSUE_COMMENTS_SIDEBAR';
export const REPORT_DETAILS_GET_ISSUE_COMMENTS = 'REPORT_DETAILS_GET_ISSUE_COMMENTS';
export const REPORT_DETAILS_IS_ISSUE_SAVING = 'REPORT_DETAILS_IS_ISSUE_SAVING';
export const REPORT_DETAILS_SHOW_APPROVE_MODAL = 'REPORT_DETAILS_SHOW_APPROVE_MODAL';
export const REPORT_DETAILS_SHOW_DISCUSS_MODAL = 'REPORT_DETAILS_SHOW_DISCUSS_MODAL';
export const REPORT_DETAILS_SHOW_FIXLIST_MODAL = 'REPORT_DETAILS_SHOW_FIXLIST_MODAL';
export const REPORT_DETAILS_SHOW_SUBMIT_MODAL = 'REPORT_DETAILS_SHOW_SUBMIT_MODAL';
export const REPORT_DETAILS_GET_FIXLIST = 'REPORT_DETAILS_GET_FIXLIST';
export const REPORT_DETAILS_CLEAR_FIXLIST = 'REPORT_DETAILS_CLEAR_FIXLIST';
export const REPORT_DETAILS_GET_FILTERS = 'REPORT_DETAILS_GET_FILTERS';
export const REPORT_DETAILS_ADD_FILTERS = 'REPORT_DETAILS_ADD_FILTERS';
export const REPORT_DETAILS_CHANGE_FILTERS = 'REPORT_DETAILS_CHANGE_FILTERS';
export const REPORT_DETAILS_SAVED_FILTERS = 'REPORT_DETAILS_SAVED_FILTERS';
export const REPORT_DETAILS_RESET_FILTERS = 'REPORT_DETAILS_RESET_FILTERS';
export const REPORT_DETAILS_CHANGE_VIDEO_PARAMS = 'REPORT_DETAILS_CHANGE_VIDEO_PARAMS';
export const REPORT_DETAILS_CHANGE_FILTERS_TYPE = 'REPORT_DETAILS_CHANGE_FILTERS_TYPE';
export const REPORT_DETAILS_SET_SAVED_STATE = 'REPORT_DETAILS_SET_SAVED_STATE';
export const REPORT_DETAILS_SCROLL_TO_ISSUE_COMMENT = 'REPORT_DETAILS_SCROLL_TO_ISSUE_COMMENT';
export const REPORT_DETAILS_SHOW_EMAIL_REPORT_MODAL = 'REPORT_DETAILS_SHOW_EMAIL_REPORT_MODAL';
export const REPORT_DETAILS_SHOW_WARNING_IN_EMAIL_REPORT_MODAL = 'REPORT_DETAILS_SHOW_WARNING_IN_EMAIL_REPORT_MODAL';
export const REPORT_DETAILS_SHOW_ADD_ISSUE_MODAL = 'REPORT_DETAILS_SHOW_ADD_ISSUE_MODAL';
export const REPORT_DETAILS_SET_LOADING = 'REPORT_DETAILS_SET_LOADING';
export const REPORT_DETAILS_SHOW_ISSUE_IMAGE_MODAL = 'REPORT_DETAILS_SHOW_ISSUE_IMAGE_MODAL';
export const REPORT_DETAILS_GET_GENERAL_COMMENTS = 'REPORT_DETAILS_GET_GENERAL_COMMENTS';
export const REPORT_DETAILS_SHOW_GENERAL_COMMENTS = 'REPORT_DETAILS_SHOW_GENERAL_COMMENTS';
export const REPORT_DETAILS_CLOSE_GENERAL_COMMENTS = 'REPORT_DETAILS_CLOSE_GENERAL_COMMENTS';
export const REPORT_DETAILS_SCROLL_TO_GENERAL_COMMENT = 'REPORT_DETAILS_SCROLL_TO_GENERAL_COMMENT';
export const REPORT_DETAILS_SHOW_IMPORT_CSV_ISSUES_MODAL = 'REPORT_DETAILS_SHOW_IMPRORT_CSV_ISSUES_MODAL';
export const REPORT_DETAILS_IS_PROXY_SAVING = 'REPORT_DETAILS_IS_PROXY_SAVING';
export const REPORT_DETAILS_IS_PROXY_LOADING = 'REPORT_DETAILS_IS_PROXY_LOADING';
export const REPORT_DETAILS_UPDATE_DETAILS = 'REPORT_DETAILS_UPDATE_DETAILS';
export const REPORT_DETAILS_SHOW_IMPORT_ISSUES_MODAL = 'REPORT_DETAILS_SHOW_IMPORT_ISSUES_MODAL';

export const REPORT_DETAILS_ATTACHMENT_IS_DOWNLOADING = 'REPORT_DETAILS_ATTACHMENT_IS_DOWNLOADING';

export const REPLACEMENT_IS_SAVING = 'REPLACEMENT_IS_SAVING';

export const COLORED_FILTER_ID = 'SeverityIDs';

export const getIssueComments = issue => dispatch => {
  const [requestId, issueId] = [issue.RequestID, issue.IssueID];
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/issues/${issueId}/comments`
  })
  .done(res => {
    const {comments, client_statuses_history, images} = res;
    const preparedComments = [...comments || [], ...client_statuses_history || [], ...images || []]
      .sort((a, b) => (a.UnixTimestamp - b.UnixTimestamp) || ((a.CommentID || 0) - (b.CommentID || 0)));
    dispatch({type: REPORT_DETAILS_GET_ISSUE_COMMENTS, issueId, comments: preparedComments});
    issue.comments = preparedComments;
    if (images && images.length) {
      issue.ImagesCount = images.length;
      issue.ImageExists = 1;
      issue.FirstImageID = images[0].IssueImageID;
      issue.FirstImageUrl = images[0].IssueImageUrl;
      issue.FirstImageUser = images[0].UserName;
      issue.FirstImageUserLogo = images[0].UserImageUrl;
    } else {
      issue.ImagesCount = 0;
      issue.ImageExists = 0;
    }
  });
};

const getFilters = requestId => (dispatch, getState) => {
  const state = getState();
  const {details} = state.reportdetails;
  const filters = {};

  if (parseInt(requestId)) {
    filters.RequestID = requestId;
  } else {
    filters.ParentRequestID = details.ParentID || details.RequestID;
  }
  return filters;
};

export const updateReportIssuesFilters = (requestId, withLoader = true) => dispatch => {
  dispatch({type: REPORT_DETAILS_FILTERS_IS_LOADING, loading: withLoader});
  const filters = dispatch(getFilters(requestId));

  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/issues/filters`,
    data: {
      filters: JSON.stringify(filters)
    }
  })
  .done(res => {
    dispatch({type: REPORT_DETAILS_GET_FILTERS, allowedFilters: res.filters});
    dispatch({type: REPORT_DETAILS_FILTERS_IS_LOADING, loading: false});
  })
  .fail(() => {
  });
};

const getValidFilter = (allFilters, filter, value) => {
  const allowedFilters = allFilters.filters.map(item => item.name) || [];
  const isExist = allowedFilters.includes(filter);
  if (isExist) {
    const existFilter = allFilters.filters.find(item => item.name === filter);
    const existFilterValues = existFilter ? existFilter.values.map(item => item.value) : [];
    return Array.isArray(value) ?
      value.filter(item => existFilterValues.includes(+item)) :
      null;
  }
  return null;
};

const getValidFilters = (allFilters, urlFilters) => {
  const res = {};
  const filters = {...urlFilters};
  delete filters.tab;
  delete filters.viewTab;

  filters && Object.keys(filters).forEach(key => {
    const validFilter = getValidFilter(allFilters, key, filters[key]);
    if (validFilter) {
      res[key] = validFilter;
    }
  });
  return res;
};

export const savedAllFilters = urlFilters => dispatch => {
  const filters = {...urlFilters};
  dispatch({type: REPORT_DETAILS_SAVED_FILTERS, filters});
};

export const getReportIssuesFilters = (requestId, urlFilters, withLoader = true) => (dispatch, getState) => {
  return dispatch(updateReportIssuesFilters(requestId, withLoader))
  .then(allowedFilters => {
    const validUrlFilters = getValidFilters(allowedFilters, urlFilters);
    dispatch(savedAllFilters(validUrlFilters));
    return allowedFilters;
  })
  .then(res => {
    const filters = getState().reportdetails.shownFilters;

    // new array for save the order because we using reverse
    // reverse we use for save the order required filters
    const allFilters = [...res.filters];
    allFilters.reverse().filter(filter => filter.required).forEach(filter => {
      const isIncludes = filters && filters.map(item => item.name).includes(filter.name);
      if (!isIncludes) {
        const requiredFilter = {
          name: filter.name,
          label: filter.title,
          values: filter.values,
          required: true,
          disabled: true
        };
        dispatch(addFilter(requiredFilter));
      }
    });

    dispatch({type: REPORT_DETAILS_FILTERS_IS_LOADING, loading: false});
  });
};

export const getValidTab = (urlFilters, details) => {
  const isUrlTabExist = urlFilters || (urlFilters && +urlFilters.tab === 0);
  const replacement = details.replacement ? details.replacement.map(item => item.RequestID) : [];
  replacement.push(0);
  const isValidUrlTab = replacement && isUrlTabExist &&
    replacement.includes(+urlFilters.tab);
  return isValidUrlTab && urlFilters ? urlFilters.tab : details.RequestID;
};

const setSavedState = (history, requestId, query) => (dispatch, getState) => {
  dispatch({type: REPORT_DETAILS_SET_SAVED_STATE, query});
  const resQuery = {...getState().reportdetails.savedState};
  const shownFilters = [...getState().reportdetails.shownFilters];

  const filters = {};
  shownFilters.forEach(filter => {
    if ((Array.isArray(filter.value) && filter.value.length) || filter.value) {
      filters[filter.name] = filter.value;
    }
  });

  Object.keys(resQuery).forEach(key => {
    const isEmpty = !resQuery[key] && +resQuery[key] !== 0;
    const isFixedFalse = key === 'fixed' && (!resQuery[key] || resQuery[key] === 'false');
    if (isEmpty || isFixedFalse || key === 'tab') {
      delete resQuery[key];
    }
  });

  history.replace({
    pathname: `/requests/${requestId}`,
    search: `${qs.stringify({...resQuery, ...filters})}`
  });
};

export const setTopActiveTab = (history, requestId, key, showSchedule) => dispatch => {
  dispatch(setSavedState(history, requestId, {viewTab: key, showSchedule}));
  dispatch({type: REPORT_DETAILS_SET_TOP_ACTIVE_TAB, key});
};

export const toggleSchedule = value => dispatch => {
  dispatch({type: REPORT_DETAILS_TOGGLE_SCHEDULE, value});
};

export const toggleEditScheduleModal = showModal => dispatch => {
  dispatch({type: REPORT_DETAILS_SHOW_EDIT_SCHEDULE_MODAL, showModal});
};

export const setListViewActiveTab = (history, requestId, key) => dispatch => {
  dispatch(setSavedState(history, requestId, {tab: key}));
  dispatch({type: REPORT_DETAILS_SET_LIST_VIEW_TAB, key});
};

const isNeededShowGeneralCommentFromUrl = urlFilters => !!urlFilters && !!urlFilters.general_comment_id && !isNaN(parseInt(urlFilters.general_comment_id, 10));

export const getReportDetails = (history, requestId, urlFilters) => dispatch => {
  dispatch({type: REPORT_DETAILS_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}`
  })
  .then(res => {
    const details = res.request;
    if (res.request.replacement && res.request.replacement.length > 1) {
      const replacement = _.orderBy(res.request.replacement, ['RequestID'], ['asc']).slice();
      for (let i = 0; i < replacement.length; i++) {
        replacement[i].version_name = i > 0 ? `Replacement ${i}` : 'NewQcRequest';
      }
      details.replacement = replacement;
    }
    const {issue_categories_and_types = [], issue_severities = [], issue_locations = [], issue_statuses = [],
    } = res;
    details.versions = _.sortBy(res.versions.map(v => ({...v, VersionName: (v.VersionName || `Version#${v.VersionID}`)})), ['QcProfileID', 'VersionID', 'RequestID']);
    details.versions_for_import = res.versions_for_import;
    details.prev_request_list = res.prev_request_list;
    dispatch({
      type: REPORT_DETAILS_GET_REQUEST,
      filters: {
        approve_reason: res.approve_reason, issue_categories_and_types, issue_severities, issue_locations,
        issue_statuses,
      },
      details,
      timeline: res.timeline || [],
      schedulingHeaders: res.scheduling_headers || [],
      milestones: res.milestones || [],
      lockScheduling: res.lock_scheduling || 0,
      issues: res.issues || []
    });
    return details;
  })
  .then(details => {
    if (urlFilters) {
      if (urlFilters.viewTab) {
        const validTab = +urlFilters.viewTab === 2 ? 2 : 1;
        dispatch(setTopActiveTab(history, requestId, validTab, urlFilters.showSchedule));
        if (validTab === 2) {
          dispatch(toggleShowSidebar(true));
        }
      }
      if (urlFilters.showSchedule === 'true') {
        dispatch(toggleSchedule(true));
      }
      /*const tab = getValidTab(urlFilters, details);
      dispatch(setListViewActiveTab(history, requestId, tab));*/
    }
    return details;
  })
  .then(details => {
    const isEnabledInteractiveView = Boolean(details.interactive_report && details.request_proxy);
    if (!isEnabledInteractiveView) {
      dispatch(setTopActiveTab(history, requestId, 1, (urlFilters || {}).showSchedule));
    }
    if (isUserCanComment(details.UserPermissionType) && !isNeededShowGeneralCommentFromUrl(urlFilters)) {
      dispatch(getGeneralComments(requestId));
    }
  })
  .fail(error => {
    dispatch({type: REPORT_DETAILS_IS_LOADING, loading: false});
    dispatch(showError(getErrorMessage(error), 'Oops, something went wrong'));
    history.push(getDefaultRedirectUrl());
  });
};

export const getFiltersForTable = () => (dispatch, getState) => {
  const state = getState();
  const {shownFilters} = state.reportdetails;
  const filters = {};
  shownFilters.forEach(item => {
    filters[item.name] = item.value || []
  });
  return filters;
};

export const getReportIssues = (history, tabRequestId, requestId, withLoader = true, urlFilters, isFirstLoad) => (dispatch, getState) => {
  const filtersForTable = urlFilters || dispatch(getFiltersForTable());
  const filters = dispatch(getFilters(/*tabRequestId*/requestId));
  !isFirstLoad && dispatch(setSavedState(history, requestId, {}));

  dispatch({type: REPORT_DETAILS_ISSUES_IS_LOADING, loading: withLoader});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/issues`,
    data: {
      filters: JSON.stringify({...filters, ...filtersForTable})
    }
  })
  .done(res => {
    const {request_proxy_timecode_settings, has_comments} = res;
    dispatch({type: REPORT_DETAILS_UPDATE_DETAILS, data: {request_proxy_timecode_settings}});
    const issues = res.issues || [];
    dispatch({type: REPORT_DETAILS_GET_ISSUES, issues, hasIssueComments: has_comments});
    dispatch({type: REPORT_DETAILS_ISSUES_IS_LOADING, loading: false});
    if (urlFilters && urlFilters.issue_id) {
      const {details: {UserPermissionType}} = getState().reportdetails;
      if (isUserCanComment(UserPermissionType)) {
        const issue = issues.find(i => i.IssueID === Number(urlFilters.issue_id));
        if (issue) {
          if (urlFilters.comment_id) {
            dispatch(getIssueCommentsModal(issue, Number(urlFilters.comment_id)));
          } else if (urlFilters.status_id) {
            dispatch(getIssueCommentsModal(issue, null, Number(urlFilters.status_id)));
          } else if (+urlFilters.pending_approval === 1) {
            dispatch(showFixNotesModal(true, issue));
          }
        }
      }
    }
  })
  .fail(() => {
    dispatch({type: REPORT_DETAILS_ISSUES_IS_LOADING, loading: false});
  });
};

export const showDiscussModal = (showModal, issue, commentId, clientStatusId) => dispatch => {
  dispatch({type: REPORT_DETAILS_SHOW_DISCUSS_MODAL, showModal, issue, commentId, clientStatusId});
};

export const getIssueCommentsModal = (issue, commentId, clientStatusId) => dispatch => {
  dispatch({type: REPORT_DETAILS_IS_LOADING_ISSUE_COMMENTS, loading: true});
  dispatch(getIssueComments(issue))
  .done(() => {
    dispatch(showDiscussModal(true, issue, commentId, clientStatusId));
  })
  .fail(() => {
    dispatch({type: REPORT_DETAILS_IS_LOADING_ISSUE_COMMENTS, loading: false});
  });
};

export const scrollToGeneralComment = () => ({type: REPORT_DETAILS_SCROLL_TO_GENERAL_COMMENT});

export const closeGeneralComments = () => ({type: REPORT_DETAILS_CLOSE_GENERAL_COMMENTS});

const getGeneralComments = (requestId, commentId = null) => dispatch => {
  dispatch({type: REPORT_DETAILS_SET_LOADING, name: 'generalComments', value: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/comments`
  })
  .done(res => {
    const {comments} = res;
    dispatch({type: REPORT_DETAILS_GET_GENERAL_COMMENTS, comments, commentId});
  })
  .fail(error => {
    dispatch({type: REPORT_DETAILS_SET_LOADING, name: 'generalComments', value: false});
    dispatch(showError(`Could not get general comments.  ${getErrorMessage(error)}`));
  });
};

export const getAndShowGeneralComments = (requestId, commentId = null) => dispatch => {
  return dispatch(getGeneralComments(requestId, commentId))
    .then(() => dispatch({type: REPORT_DETAILS_SHOW_GENERAL_COMMENTS}));
};

export const showGeneralComments = (requestId, callback) => dispatch => {
  if (!callback) {
    dispatch(getAndShowGeneralComments(requestId));
  } else {
    callback();
    dispatch(getGeneralComments(requestId)).then(callback);
  }
};

export const addGeneralComment = (requestId, comment) => dispatch => {
  dispatch({type: REPORT_DETAILS_SET_LOADING, name: 'generalComments', value: true});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/comments`,
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(comment)
  })
  .done(res => {
    dispatch(getGeneralComments(requestId/*, res.comment_id*/));
  })
  .fail(error => {
    dispatch({type: REPORT_DETAILS_SET_LOADING, name: 'generalComments', value: false});
    dispatch(showError(`Could not add general comment.  ${getErrorMessage(error)}`));
  });
};

export const getReport = (history, requestId, urlFilters) => (dispatch, getState) => {
  delete urlFilters.tab;

  let filtersToGetIssues = {...urlFilters};
  delete filtersToGetIssues.tab;
  delete filtersToGetIssues.viewTab;
  delete filtersToGetIssues.showSchedule;
  if (!Object.keys(filtersToGetIssues).length) {
    filtersToGetIssues = null;
  }

  dispatch(getReportIssuesFilters(/*savedState.tab*/requestId, urlFilters));

  return dispatch(getReportDetails(history, requestId, urlFilters))
  .then(() => {
    dispatch(getReportIssues(history, /*savedState.tab*/requestId, requestId, /*savedState.fixed*/true, filtersToGetIssues, true));
    dispatch(setSavedState(history, requestId, {}));
    const {details: {UserPermissionType}} = getState().reportdetails;
    if (isUserCanComment(UserPermissionType) && isNeededShowGeneralCommentFromUrl(urlFilters)) {
      dispatch(getAndShowGeneralComments(requestId, +urlFilters.general_comment_id));
    } else if (Number(urlFilters.fixlist) === 1 && !isOperatorUser()) {
      dispatch(showFixListModal(true));
    } else if (Number(urlFilters.confirm_auto_create_repl_wo) === 1 && isManagerUser()) {
      dispatch(showConfirmAlertToAutoCreateReplacementWO(Number(requestId)));
    }
  });
};

export const clearReportDetails = () => dispatch => {
  dispatch({type: REPORT_DETAILS_CLEAR});
};

export const addFilter = filter => dispatch => {
  dispatch({type: REPORT_DETAILS_ADD_FILTERS, filter});
};

export const clearFilters = () => dispatch => {
  dispatch({type: REPORT_DETAILS_RESET_FILTERS});
};

export const showFixNotesModal = (showModal, issue) => dispatch => {
  dispatch({type: REPORT_DETAILS_SHOW_APPROVE_MODAL, showModal, issue});
};

export const discussIssue = (issue, comment) => dispatch => {
  dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: true});
  const [requestId, issueId] = [issue.RequestID, issue.IssueID];
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/issues/${issueId}/comments`,
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(comment)
  })
  .done(res => {
    dispatch({type: REPORT_DETAILS_DISCUSS_ISSUE, issueId});
    dispatch(getIssueCommentsModal(issue, res.comment_id));
  })
  .fail(error => {
    dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const fixNotesOfIssue = (issue, values, history) => dispatch => {
  dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: true});
  const [requestId, issueId] = [issue.RequestID, issue.IssueID];
  $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/issues/${issueId}/fix_notes`,
    method: 'POST',
    data: {
      status_id: values.status_id,
      approve_type_id: values.reason_id,
      note: values.status_note
    }
  })
  .done(() => {
    dispatch(showFixNotesModal(false));
    dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: false});

    const urlFilters = qs.parse(window.location.search.substr(1));
    delete urlFilters.tab;

    dispatch(getReportIssuesFilters(requestId, urlFilters))
      .then(dispatch(getReportIssues(history, null, requestId)));
  })
  .fail(error => {
    dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const markCompleted = requestId => dispatch => {
  dispatch({type: REPORT_DETAILS_IS_COMPLETING, completing: true});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/complete`
  })
  .done(res => {
    dispatch({type: REPORT_DETAILS_MARK_COMPLETED, res});
  })
  .fail(() => {
    dispatch({type: REPORT_DETAILS_IS_COMPLETING, completing: false});
  });
};

export const completeDwQc = requestId => dispatch => {
  dispatch({type: REPORT_DETAILS_IS_COMPLETING, completing: true});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/dw_qc_complete`
  })
  .done(res => {
    dispatch({type: REPORT_DETAILS_MARK_COMPLETED, res});
  })
  .fail(() => {
    dispatch({type: REPORT_DETAILS_IS_COMPLETING, completing: false});
  });
};

export const showEmailReportModal = showModal => dispatch => {
  dispatch({type: REPORT_DETAILS_SHOW_EMAIL_REPORT_MODAL, showModal});
};

const showWarningInEmailReportModal = warning => ({type: REPORT_DETAILS_SHOW_WARNING_IN_EMAIL_REPORT_MODAL, warning});

export const hideWarningInEmailReportModal = () => ({type: REPORT_DETAILS_SHOW_WARNING_IN_EMAIL_REPORT_MODAL});

export const sendEmailReport = (requestId, params) => dispatch => {
  dispatch({type: REPORT_DETAILS_IS_COMPLETING, completing: true});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/email_report`,
    data: params
  })
  .done(() => {
    dispatch({type: REPORT_DETAILS_IS_COMPLETING, completing: false});
    dispatch(showEmailReportModal(false));
    dispatch(showSuccess('Email Report was sent successfully.'));
  })
  .fail(error => {
    dispatch({type: REPORT_DETAILS_IS_COMPLETING, completing: false});
    dispatch(hideWarningInEmailReportModal());
    const ERROR_CODE__INVALID_QOD_USERS_IN_DISTRIBUTION_LIST = 102;
    let errorMessage;
    if (error && typeof error === 'object' && error.responseJSON) {
      const {message, errors} = error.responseJSON;
      if (Array.isArray(errors) && errors.find(i => i.code === ERROR_CODE__INVALID_QOD_USERS_IN_DISTRIBUTION_LIST)) {
        errorMessage = message;
      }
    }
    if (errorMessage) {
      dispatch(showWarningInEmailReportModal(errorMessage));
    } else {
      dispatch(showError(getErrorMessage(error)));
    }
  });
};

export const sendFixListEmail = (requestId, minSeverity, params) => dispatch => {
  dispatch({type: REPORT_DETAILS_IS_COMPLETING, completing: true});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/fixlist`,
    data: {
      ...params,
      min_severity: minSeverity
    }
  })
  .done(() => {
    dispatch({type: REPORT_DETAILS_IS_COMPLETING, completing: false});
    dispatch(showEmailReportModal(false));
    dispatch(showSuccess('Fix List was sent successfully.'));
  })
  .fail(error => {
    dispatch({type: REPORT_DETAILS_IS_COMPLETING, completing: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const getIssueCommentsSidebar = issue => dispatch => {
  dispatch({type: REPORT_DETAILS_IS_LOADING_ISSUE_COMMENTS_SIDEBAR, loading: true});
  dispatch(getIssueComments(issue))
  .done(() => {
    dispatch({type: REPORT_DETAILS_IS_LOADING_ISSUE_COMMENTS_SIDEBAR, loading: false});
  })
  .fail(() => {
    dispatch({type: REPORT_DETAILS_IS_LOADING_ISSUE_COMMENTS_SIDEBAR, loading: false});
  });
};

export const showFixListModal = showModal => dispatch => {
  dispatch({type: REPORT_DETAILS_SHOW_FIXLIST_MODAL, showModal});
};

export const clearFixList = () => dispatch => {
  dispatch({type: REPORT_DETAILS_CLEAR_FIXLIST});
};

export const getFixListIssues = (history, requestId, minSeverity) => dispatch => {
  dispatch({type: REPORT_DETAILS_GET_FIXLIST, loading: true, minSeverity});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/issues`,
    data: {
      filters: JSON.stringify({RequestID: requestId, FromFixlist: true, MinSeverity: minSeverity})
    }
  })
  .done(res => {
    dispatch({type: REPORT_DETAILS_GET_FIXLIST, loading: false, ...res});
  })
  .fail(() => {
    dispatch({type: REPORT_DETAILS_GET_FIXLIST, loading: false});
  });
};

export const showSubmitReplacementModal = (showModal, csParam) => dispatch => {
  dispatch({type: REPORT_DETAILS_SHOW_SUBMIT_MODAL, showModal, csParam});
};

export const changeIssueTableFilters = (filterName, value) => dispatch => {
  dispatch({type: REPORT_DETAILS_CHANGE_FILTERS, filterName, value});
};

export const changeFilterType = (filterName, newFilterName, newFilterLabel) => dispatch => {
  dispatch({type: REPORT_DETAILS_CHANGE_FILTERS_TYPE, filterName, newFilterName, newFilterLabel});
};

export const changeVideoParams = data => dispatch => {
  dispatch({type: REPORT_DETAILS_CHANGE_VIDEO_PARAMS, data});
};

export const submitReplacement = (history, values) => (dispatch, getState) => {
  const {reportdetails} = getState();
  const {replacementCSParam} = reportdetails;
  dispatch({type: REPLACEMENT_IS_SAVING, saving: true});
  const {manual_uploads} = values;
  const hasManualUploads = Boolean(manual_uploads && manual_uploads.length);
  const preparedValues = {
    ...values,
    ...replacementCSParam,
    manual_uploads: hasManualUploads ? manual_uploads.map(item => (item.asset_name)) : null
  };
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/replacement/new`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(preparedValues)
  })
  .done(res => {
    const {request_id} = res;
    const actionsAfterSaving = () => {
      history.push(`/requests/${request_id}${isManagerUser() ? `?confirm_auto_create_repl_wo=1` : ''}`);
      history.go();
    };
    if (hasManualUploads) {
      dispatch(asperaStartTransfer(res, actionsAfterSaving));
    } else {
      actionsAfterSaving();
    }
  })
  .fail(error => {
    dispatch({type: REPLACEMENT_IS_SAVING, saving: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const scrollToIssueComment = () => dispatch => {
  dispatch({type: REPORT_DETAILS_SCROLL_TO_ISSUE_COMMENT});
};

const getSignedTokenForDownloadFiles = (path, query) => {
  query = query || '';
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/util/download-file`,
    data: {
      path: `/services${path}`,
      query
    }
  })
  .done(res => {
    const token = encodeURIComponent(res.token);
    window.open(`${config.apiUrl}${path}?${query}&t=${token}`);
  })
  .fail(() => {});
};

export const exportReport = (requestId, includeFixedIssues, includeIssuesComments) => () => {
  const path = `/v1/qc_on_demand/requests/${requestId}/export`;
  getSignedTokenForDownloadFiles(path, `include_fixed_issues=${includeFixedIssues}&include_comments=${includeIssuesComments}`);
};

export const uploadFixlist = (requestId, minSeverity, exportType) => dispatch => {
  const path = `/v1/qc_on_demand/requests/${requestId}/fixlist/export`;
  getSignedTokenForDownloadFiles(path, `min_severity=${minSeverity}&export_type=${exportType}`);
};

export const exportRequestIssues = (requestId, exportType = 'csv') => dispatch => {
  const filtersForTable = dispatch(getFiltersForTable());
  const filters = dispatch(getFilters(requestId));

  const filtersParam = encodeURIComponent(JSON.stringify({...filters, ...filtersForTable}));

  const query = `filters=${filtersParam}&export=${exportType}`;

  const path = `/v1/qc_on_demand/requests/issues`;
  getSignedTokenForDownloadFiles(path, query);
};

export const updateEditDateModal = values => dispatch => {
  dispatch({type: REPORT_DETAILS_UPDATE_EDIT_MODAL, values});
};
  
export const updateMilestoneDate = ({
    rowId,
    schedulingId,
    serverDate,
    updatePrevDates,
    updateNextDates
  }) => dispatch => {
    dispatch({type: REPORT_DETAILS_SET_LOADING, name: 'editScheduleModal', value: true});
    return $.ajax({
      method: 'POST',
      url: `${config.apiUrl}/v1/qc_on_demand/requests/${rowId}/schedule`,
      contentType: 'application/json;charset=utf-8',
      data: JSON.stringify({
        profile_scheduling_id: schedulingId,
        date: serverDate,
        left: updatePrevDates,
        right: updateNextDates
      })
    })
      .then(data => {
        const {milestones} = data;
        dispatch(updateEditDateModal({show: false, rowId: null, schedulingId: null, position: {}}));
        dispatch({type: REPORT_DETAILS_UPDATE_MILESTONE, milestones});
        dispatch({type: REPORT_DETAILS_SET_LOADING, name: 'editScheduleModal', value: false});
      })
      .fail(() => {
        dispatch(updateEditDateModal({show: false, rowId: null, schedulingId: null, position: {}}));
        dispatch({type: REPORT_DETAILS_SET_LOADING, name: 'editScheduleModal', value: false});
      });
};
  
export const lockRequest = rowId => dispatch => {
  dispatch({type: REPORT_DETAILS_SET_LOADING, name: 'editScheduleModal', value: true});
    return $.ajax({
      method: 'POST',
      url: `${config.apiUrl}/v1/qc_on_demand/requests/${rowId}/lock`
    })
      .then(data => {
        dispatch({type: REPORT_DETAILS_LOCK_FEATURE, rowId});
        dispatch({type: REPORT_DETAILS_SET_LOADING, name: 'editScheduleModal', value: false});
      })
      .fail(() => {
        dispatch({type: REPORT_DETAILS_SET_LOADING, name: 'editScheduleModal', value: false});
      });
};

export const showAddIssueModal = (showModal, issue) => dispatch => {
  dispatch({type: REPORT_DETAILS_SHOW_ADD_ISSUE_MODAL, showModal, issue});
};

export const addIssue = (history, requestId, issue) => (dispatch, getState) => {
  const {issue_id} = issue;
  const isEditing = !!issue_id && issue_id > 0;
  dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: true});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/issue`,
    contentType: 'application/json;charset=utf-8',
    data: JSON.stringify(issue)
  })
  .done(() => {
    dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: false});
    dispatch(showAddIssueModal(false));
    dispatch(showSuccess(`Issue has been successfully ${isEditing ? 'updated' : 'created'}.`));

    const urlFilters = qs.parse(window.location.search.substr(1));
    delete urlFilters.tab;
    dispatch(getReportIssuesFilters(requestId, urlFilters, false))
      .then(() => getState().reportdetails.savedState)
      .then(savedState => dispatch(getReportIssues(history, savedState.tab, requestId, false)));
  })
  .fail(error => {
    dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: false});
    const errorMessage = getErrorMessage(error);
    if (typeof errorMessage === 'string' && errorMessage.toLowerCase().indexOf('dw step') && errorMessage.toLowerCase().indexOf('signed off')) {
      dispatch({type: REPORT_DETAILS_UPDATE_DETAILS, data: {allow_edit_issues: false}});
      dispatch(showAddIssueModal(false));
    }
    dispatch(showError(`Could not ${isEditing ? 'update' : 'create'} issue.  ${errorMessage}`));
  });
};

export const deleteIssue = (history, issue) => (dispatch, getState) => {
  const [requestId, issueId] = [issue.RequestID, issue.IssueID];
  dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: true});
  $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/issues/${issueId}`
  })
  .done(() => {
    dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: false});
    dispatch(showSuccess('Issue has been successfully deleted.'));

    const urlFilters = qs.parse(window.location.search.substr(1));
    delete urlFilters.tab;
    dispatch(getReportIssuesFilters(requestId, urlFilters, false))
      .then(() => getState().reportdetails.savedState)
      .then(savedState => dispatch(getReportIssues(history, savedState.tab, requestId, false)));
  })
  .fail(error => {
    dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: false});
    dispatch(showError(`Could not delete issue.  ${getErrorMessage(error)}`));
  });
};

export const undeleteIssue = (history, issue) => (dispatch, getState) => {
  const [requestId, issueId] = [issue.RequestID, issue.IssueID];
  dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: true});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/issues/${issueId}/undelete`
  })
  .done(() => {
    dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: false});
    dispatch(showSuccess('Issue has been successfully undeleted.'));

    const urlFilters = qs.parse(window.location.search.substr(1));
    delete urlFilters.tab;
    dispatch(getReportIssuesFilters(requestId, urlFilters, false))
      .then(() => getState().reportdetails.savedState)
      .then(savedState => dispatch(getReportIssues(history, savedState.tab, requestId, false)));
  })
  .fail(error => {
    dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: false});
    dispatch(showError(`Could not undelete issue.  ${getErrorMessage(error)}`));
  });
};

export const showIssueImageModal = issue => (dispatch, getState) => {
  if (issue) {
    const showModal = images => {
      dispatch({type: REPORT_DETAILS_SET_LOADING, name: 'issueImageModal', value: false});
      if ((images && images.length) || isManagerUser()) {
        dispatch({type: REPORT_DETAILS_SHOW_ISSUE_IMAGE_MODAL, imageModalIssue: {issue, images}});
      }
    };
    if (issue.ImageExists) {
      const [requestId, issueId] = [issue.RequestID, issue.IssueID];
      dispatch({type: REPORT_DETAILS_SET_LOADING, name: 'issueImageModal', value: true});
      $.ajax({
        method: 'GET',
        url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/issues/${issueId}/images`
      })
      .done(res => {
        showModal(res.images);
      })
      .fail(err => {
        dispatch(showError(getErrorMessage(err), 'Get issue image URL'));
        showModal();
      });
    } else {
      showModal();
    }
  } else {
    const {imageModalIssue} = getState().reportdetails;
    if (imageModalIssue.issue) {
      //dispatch(getIssueCommentsSidebar(imageModalIssue.issue));
      dispatch({type: REPORT_DETAILS_IS_LOADING_ISSUE_COMMENTS, loading: true});
      dispatch(getIssueComments(imageModalIssue.issue))
      .always(() => {
        dispatch({type: REPORT_DETAILS_IS_LOADING_ISSUE_COMMENTS, loading: false});
      });
    }
    dispatch({type: REPORT_DETAILS_SHOW_ISSUE_IMAGE_MODAL});
  }
};

export const uploadIssueImage = file => (dispatch, getState) => {
  dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: true});
  const {imageModalIssue} = getState().reportdetails;
  const {issue} = imageModalIssue;
  const [requestId, issueId] = [issue.RequestID, issue.IssueID];
  const data = new FormData();
  data.append('file', file);
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/issues/${issueId}/image`,
    data,
    cache: false,
    processData: false,
    contentType: false
  })
  .done(res => {
    dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: false});
    dispatch(showSuccess('Image has been successfully uploaded.'));
    const images = [...imageModalIssue.images || [], {...res, IssueImageUrl: res.IssueImageUrl || file.preview}];
    issue.ImagesCount = images.length;
    issue.ImageExists = 1;
    if (issue.ImagesCount === 1) {
      issue.FirstImageID = images[0].IssueImageID;
      issue.FirstImageUrl = images[0].IssueImageUrl;
      issue.FirstImageUser = images[0].UserName;
      issue.FirstImageUserLogo = images[0].UserImageUrl;
    }
    dispatch({type: REPORT_DETAILS_SHOW_ISSUE_IMAGE_MODAL, imageModalIssue: {issue, images}});
  })
  .fail(error => {
    dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const deleteIssueImage = image => (dispatch, getState) => {
  dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: true});
  const {imageModalIssue} = getState().reportdetails;
  const {issue} = imageModalIssue;
  return $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/issue_image/${image.IssueImageID}`
  })
  .done(res => {
    dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: false});
    dispatch(showSuccess('Image has been successfully deleted.'));
    const images = imageModalIssue.images.filter(({IssueImageID}) => IssueImageID !== image.IssueImageID);
    issue.ImagesCount = images.length;
    if (!issue.ImagesCount) {
      issue.ImageExists = 0;
    }
    if (issue.FirstImageID === image.IssueImageID) {
      if (!issue.ImageExists) {
        issue.FirstImageID = null;
        issue.FirstImageUrl = null;
        issue.FirstImageUser = null;
        issue.FirstImageUserLogo = null;
      } else {
        issue.FirstImageID = images[0].IssueImageID;
        issue.FirstImageUrl = images[0].IssueImageUrl;
        issue.FirstImageUser = images[0].UserName;
        issue.FirstImageUserLogo = images[0].UserImageUrl;
      }
    }
    dispatch({type: REPORT_DETAILS_SHOW_ISSUE_IMAGE_MODAL, imageModalIssue: {issue, images}});
  })
  .fail(error => {
    dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

export const showImportCsvIssuesModal = (showModal) => dispatch => {
  dispatch({type: REPORT_DETAILS_SHOW_IMPORT_CSV_ISSUES_MODAL, showModal});
};

export const importCsvIssues = (requestId, csvFile, history) => (dispatch, getState) => {
  dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: true});
  const data = new FormData();
  data.append('file', csvFile);
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/issues/statuses`,
    data,
    cache: false,
    processData: false,
    contentType: false
  })
  .done(() => {
    dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: false});
    dispatch(showImportCsvIssuesModal(false));
    dispatch(showSuccess('Successfully imported issue statuses.'));

    const urlFilters = qs.parse(window.location.search.substr(1));
    delete urlFilters.tab;
    dispatch(getReportIssuesFilters(requestId, urlFilters, false))
      .then(() => getState().reportdetails.savedState)
      .then(savedState => dispatch(getReportIssues(history, savedState.tab, requestId, false)));
  })
  .fail(error => {
    dispatch({type: REPORT_DETAILS_IS_ISSUE_SAVING, saving: false});
    dispatch(showError(`Could not import file. ${getErrorMessage(error)}`));
  });
};

export const getMentions = (requestId, commentType) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/mention`,
      type: 'GET',
      data: {comment_type: commentType}
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
};

export const getProxy = () => (dispatch, getState) => {
  const requestId = (getState().reportdetails.details || {}).RequestID;
  if (!requestId) {
    return;
  }
  dispatch({type: REPORT_DETAILS_IS_PROXY_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/secure_proxy`
  })
  .done(res => {
    if (+requestId === (getState().reportdetails.details || {}).RequestID) {
      const data = {...res};
      const {isPendingRequestProxy} = getState().reportdetails.details;
      if (isPendingRequestProxy) {
        if (data.request_proxy_status === 'none') {
          data.request_proxy_status = 'pending';
        } else {
          data.isPendingRequestProxy = undefined;
        }
      }
      dispatch({type: REPORT_DETAILS_UPDATE_DETAILS, data});
    }
    dispatch({type: REPORT_DETAILS_IS_PROXY_LOADING, loading: false});
  })
  .fail(error => {
    dispatch({type: REPORT_DETAILS_IS_PROXY_LOADING, loading: false});
    dispatch(showError(`Could not get a secure proxy info.`));
  });
};

export const generateProxy = requestId => dispatch => {
  dispatch({type: REPORT_DETAILS_IS_PROXY_SAVING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/secure_proxy`
  })
  .done(() => {
    dispatch({type: REPORT_DETAILS_UPDATE_DETAILS, data: {request_proxy_status: 'pending', isPendingRequestProxy: true}});
    dispatch({type: REPORT_DETAILS_IS_PROXY_SAVING, loading: false});
    dispatch(showSuccess('Your watermarked proxy video is being prepared.'));
  })
  .fail(error => {
    dispatch({type: REPORT_DETAILS_IS_PROXY_SAVING, loading: false});
    dispatch(showError(`Could not generate a secure proxy. Please contact qodsupport@eurofins-dms.com for assistance`));
  });
};

export const toggleImportIssuesModal = showModal => dispatch => {
  dispatch({type: REPORT_DETAILS_SHOW_IMPORT_ISSUES_MODAL, showModal});
};

export const getIssuesToImportFromAnotherReport = (requestId, woNum, versionRequestId, prevRevisionRequestId) => dispatch => {
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/issues/import`,
    data: {
      wo_num: woNum,
      version_request_id: versionRequestId,
      prev_revision_request_id: prevRevisionRequestId
    }
  })
  .done(res => {
    return res;
  })
  .fail(error => {
    dispatch(showError(`Could not get issues. ${getErrorMessage(error)}`));
  });
};

export const importIssuesFromAnotherReport = (history, requestId, importRequestId, issueIDs) => (dispatch, getState) => {
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${requestId}/issues/import`,
    data: {
      import_request_id: importRequestId,
      issue_ids: issueIDs
    }
  })
  .done(() => {
    dispatch(toggleImportIssuesModal(false));
    dispatch(showSuccess(`Issues has been successfully imported`));

    const urlFilters = qs.parse(window.location.search.substr(1));
    delete urlFilters.tab;
    dispatch(getReportIssuesFilters(requestId, urlFilters, false))
      .then(() => getState().reportdetails.savedState)
      .then(savedState => dispatch(getReportIssues(history, savedState.tab, requestId, false)));
  })
  .fail(error => {
    dispatch(showError(`Could not import issues. ${getErrorMessage(error)}`));
  });
};

export const downloadAttachment = (attachmentId, requestId) => dispatch => {
  dispatch({type: REPORT_DETAILS_ATTACHMENT_IS_DOWNLOADING, loading: true});
  const apiUrl = isManagerUser() ? `css/requests/attachment/${attachmentId}` : `requests/${requestId}/attachment/${attachmentId}`;
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/${apiUrl}`
  })
  .done(url => {
    dispatch({type: REPORT_DETAILS_ATTACHMENT_IS_DOWNLOADING, loading: false});
    if (url) {
      window.open(url);
    } else {
      dispatch(showError(`Could not get the URL to download attachment.`));
    }
  })
  .fail(error => {
    dispatch({type: REPORT_DETAILS_ATTACHMENT_IS_DOWNLOADING, loading: false});
    dispatch(showError(`Could not download attachment.  ${getErrorMessage(error)}`));
  });
};
