import $ from 'jquery';
import config from '../../../config';
import {showError, showSuccess} from "../../layouts/actions";
import {getErrorMessage} from "../../utils";

export const SETTINGS_GET_QC_PROFILE = 'SETTINGS_GET_QC_PROFILE';
export const SETTINGS_UPDATE_QC_PROFILE_SETTINGS = 'SETTINGS_UPDATE_QC_PROFILE_SETTINGS';
export const SETTINGS_SET_SCHEDULING_DATA = 'SETTINGS_SET_SCHEDULING_DATA';
export const SETTINGS_SET_IS_OPEN_TEMPLATE_CONFIGURE = 'SETTINGS_SET_IS_OPEN_TEMPLATE_CONFIGURE';
export const SETTINGS_SET_IS_OPEN_NEW_TYPE_MODAL = 'SETTINGS_SET_IS_OPEN_NEW_TYPE_MODAL';
export const SETTINGS_SET_OPTIONS_TEMPLATE_CONFIGURE = 'SETTINGS_SET_OPTIONS_TEMPLATE_CONFIGURE';
export const SETTINGS_SAVE_SELECTED_OPTIONS = 'SETTINGS_SAVE_SELECTED_OPTIONS';
export const SETTINGS_SET_OPTIONS_COLUMNS = 'SETTINGS_SET_OPTIONS_COLUMNS';
export const SETTINGS_ADD_NEW_STEP = 'SETTINGS_ADD_NEW_STEP';
export const SETTINGS_INSERT_STEP = 'SETTINGS_INSERT_STEP';
export const SETTINGS_SET_STEPS = 'SETTINGS_SET_STEPS';
export const SETTINGS_SET_STEP_TYPES = 'SETTINGS_SET_STEP_TYPES';
export const SETTINGS_SET_IS_OPEN_NEW_DESCRIPTION_MODAL = 'SETTINGS_SET_IS_OPEN_NEW_DESCRIPTION_MODAL';
export const SETTINGS_SET_COMBINATIONS = 'SETTINGS_SET_COMBINATIONS';
export const SETTINGS_TB_DELETE_NEW_STEP = 'SETTINGS_TB_DELETE_NEW_STEP';
export const SETTINGS_TB_CLEAR_FOR_QC_PROFILE = 'SETTINGS_TB_CLEAR_FOR_QC_PROFILE';
export const SETTINGS_TB_SET_FILTERS = 'SETTINGS_TB_SET_FILTERS';
export const SETTINGS_SET_TEMPLATE_CONFIGURE_COMBINATIONS = 'SETTINGS_SET_TEMPLATE_CONFIGURE_COMBINATIONS';
export const SETTINGS_TOGGLE_HIDE_VIDEO_CAPTIONS = 'SETTINGS_TOGGLE_HIDE_VIDEO_CAPTIONS';
export const SETTINGS_SCHEDULES_SET_LIST = 'SETTINGS_SCHEDULES_SET_LIST';
export const SETTINGS_SCHEDULES_SET_PROFILE = 'SETTINGS_SCHEDULES_SET_PROFILE';

export const setQcProfile = ({
  qcProfileSettings, severityTypeList, severityConstituteList, scheduleProfileList,
  templateBuilderSettings
}) => dispatch => {
  dispatch({
    type: SETTINGS_GET_QC_PROFILE,
    qcProfileSettings,
    severityTypeList,
    severityConstituteList,
    scheduleProfileList,
    templateBuilderSettings
  });
};

export const updateQCProfileSettings = value => dispatch => {
  dispatch({type: SETTINGS_UPDATE_QC_PROFILE_SETTINGS, value});
};

export const setSchedulingData = data => dispatch => {
  dispatch({type: SETTINGS_SET_SCHEDULING_DATA, data});
};

export const setIsOpenTemplateConfigure  = isOpenTemplateConfigure => dispatch => {
  dispatch({type: SETTINGS_SET_IS_OPEN_TEMPLATE_CONFIGURE, isOpenTemplateConfigure})
};

export const setIsOpenNewTypeModal = (isOpenNewTypeModal, newTypeData) => dispatch => {
  dispatch({type: SETTINGS_SET_IS_OPEN_NEW_TYPE_MODAL, isOpenNewTypeModal, newTypeData})
};

export const setIsOpenNewDescriptionModal  = (isOpenNewDescriptionModal, editDescData) => dispatch => {
  dispatch({type: SETTINGS_SET_IS_OPEN_NEW_DESCRIPTION_MODAL, isOpenNewDescriptionModal, editDescData})
};

export const saveSelectedOptions  = (optionsSetName, option) => dispatch => {
  dispatch({type: SETTINGS_SAVE_SELECTED_OPTIONS, optionsSetName, option})
};

export const setOptionsColumn  = (optionsColumns) => dispatch => {
  dispatch({type: SETTINGS_SET_OPTIONS_COLUMNS, optionsColumns})
};

export const setTemplateConfigureCombinations  = (templateConfigureCombinations) => dispatch => {
  dispatch({type: SETTINGS_SET_TEMPLATE_CONFIGURE_COMBINATIONS, templateConfigureCombinations})
};

export const addNewStep = () => ({type: SETTINGS_ADD_NEW_STEP});

export const insertStep = (stage, index) => ({type: SETTINGS_INSERT_STEP, stage, index});

export const sendOptionsID  = (optionsID, qcProfileID) => dispatch => {
  $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/${qcProfileID}/task_options`,
    type: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(optionsID)
  })
      .done(combinations => {
        dispatch({type: SETTINGS_SET_COMBINATIONS, combinations});
      })
      .fail(error => {
        dispatch(showError(getErrorMessage(error), 'Save task options'));
      });
};

const getData = cmd => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.apiUrl}/v1/qc_on_demand/account/${cmd}`,
      type: 'GET'
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
};

const postData = (cmd, values) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.apiUrl}/v1/qc_on_demand/account/${cmd}`,
      type: 'POST',
      contentType: 'application/json;charset=utf-8',
      dataType: 'json',
      data: typeof values === 'string' ? values : JSON.stringify(values)
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
};

const deleteData = cmd => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.apiUrl}/v1/qc_on_demand/account/${cmd}`,
      type: 'DELETE'
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
}

const postImageFile = (cmd, file) => {
  const data = new FormData();
  data.append('file', file);
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.apiUrl}/v1/qc_on_demand/account/${cmd}`,
      type: 'POST',
      data,
      cache: false,
      processData: false,
      contentType: false
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
};

export const getFileTransfers = () => {
  return getData('file_transfers');
};

export const saveFileTransfers = values => {
  return postData('file_transfers', values);
};

export const getNotifications = () => {
  return getData('notification_preferences');
};

export const saveNotifications = values => {
  return postData('notification_preferences', values);
};

export const getAccountDetails = () => {
  return getData('details');
};

export const saveAccountDetails = values => {
  return postData('details', values);
};

export const saveAccountAvatar = file => {
  return postImageFile('avatar', file);
};

export const getDesignReport = () => {
  return getData('report_preferences');
};

export const saveDesignReport = values => {
  return postData('report_preferences', values);
};

export const saveReportLogo = file => {
  return postImageFile('studio_logo', file);
};

export const getQcProfileList = () => {
  return getData('qc_profile');
};

export const getQcProfile = profileID => {
  return getData(`qc_profile/${profileID}`);
};

export const createQcProfile = values => {
  return postData('qc_profile/', values);
};

export const saveQcProfile = (profileID, values) => {
  return postData(`qc_profile/${profileID}`, values);
};

export const duplicateQcProfile = (profileID, values) => {
  return postData(`qc_profile/${profileID}/duplicate`, values);
};

export const editQcProfile = (profileID, values) => {
  return postData(`qc_profile/${profileID}/edit`, values);
};

export const sendFeedback = values => {
  return postData('feedback/', values);
};

export const getSeverityConfiguration = () => {
  return getData('severity_configuration');
};

export const saveSeverityConfiguration = values => {
  return postData('severity_configuration', values);
};

export const saveNewPassword = values => {
  return postData('change_password', values);
};

export const getScheduleProfileList = () => {
  return getData('schedule_profile');
};

export const getScheduleProfile = profileID => {
  return getData(`schedule_profile/${profileID}`);
};

export const createScheduleProfile = values => {
  return postData('schedule_profile', values);
};

export const addScheduleProfileConfig = (profileId, values) => {
  return postData(`schedule_profile/${profileId}/config`, values)
};

export const updateScheduleProfileConfig = (profileId, configId, values) => {
  return postData(`schedule_profile/${profileId}/config/${configId}`, values)
};

export const deleteScheduleProfileConfig = (profileId, configId) => {
  return deleteData(`schedule_profile/${profileId}/config/${configId}`)
};

export const toggleAccountFlag = (flag) => dispatch => {
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/account/flag`,
    method: 'PUT',
    data: {flag}
  });
};

export const setSteps = steps => ({type: SETTINGS_SET_STEPS, steps});

export const sortSteps = (qcProfileID, allSteps, stage, stepSubtypeId, orderedStepsInStage) => dispatch => {
  return postData(`qc_profile/${qcProfileID}/steps/sort/${stage}/${stepSubtypeId}`, orderedStepsInStage)
    .then(res => {
      dispatch(showSuccess('The sorting has been successfully changed'));
      dispatch(setSteps(allSteps));
    })
    .catch(error => {
      console.log(getErrorMessage(error));
      dispatch(showError('Could not save sorting'));
    });
};

export const deleteStep = (qcProfileID, allSteps, stepSubtypeID) => dispatch => {
  return deleteData(`qc_profile/${qcProfileID}/steps/${stepSubtypeID}`)
    .then(res => {
      dispatch(showSuccess('The step has been successfully deleted'));
      dispatch(setSteps(allSteps));
    })
    .catch(error => {
      console.log(getErrorMessage(error));
      dispatch(showError('Could not delete step'));
    });
};

const addNewStepType = (stage, type) => (dispatch, getState) => {
  const stepTypes = [...getState().settings.templateBuilderSettings.stepTypes];
  if (!stepTypes.find(t => t.stage === stage && t.type.toLowerCase() === type.toLowerCase())) {
    stepTypes.push({stage, type});
    stepTypes.sort((a, b) => `${a.type}${a.stage}`.localeCompare(`${b.type}${b.stage}`));
    dispatch({type: SETTINGS_SET_STEP_TYPES, data: stepTypes});
  }
};

const showErrorOnSavingStep = (steps, stage, error, defaultMsg) => dispatch => {
  console.log(getErrorMessage(error));
  const ERROR_CODE_STEP_ALREADY_EXIST = 79;
  const errorStepExists = getErrorMessage(error, ERROR_CODE_STEP_ALREADY_EXIST);
  let errorMsg;
  if (Array.isArray(errorStepExists)) {
    const stepSubtypeID = Number((/StepSubtypeID[^0-9]+([0-9]+)/.exec(errorStepExists[1]) || ['', 0])[1]);
    let stepIndex = (stepSubtypeID > 0 ? steps[stage].findIndex(s => s.step_subtype_id === stepSubtypeID) : -1) + 1;
    if (stepIndex > 0) {
      for (let i = stage - 1; i > 0; i--) {
        stepIndex += (steps[i] || []).length;
      }
    }
    errorMsg = `This step is identical to ${stepIndex ? '' : 'other '}step ${stepIndex || ''}, all steps are required to be unique.  Please edit the description to be more specific.`;
  } else {
    errorMsg = defaultMsg;
  }
  dispatch(showError(errorMsg));
};

export const saveNewStep = (qcProfileID, stage, index, stepData) => (dispatch, getState) => {
  const steps = {...getState().settings.templateBuilderSettings.steps};
  const {type_label, description, product_code, category_flags, version, office, patches, time_estimate} = stepData;
  let prevStep = null;
  if (index > 0) {
    for (let i = index - 1; i >=0; i--) {
      if (steps[stage][i].step_type_id <= 0) {
        prevStep = steps[stage][i];
        break;
      }
    }
  }
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/account/qc_profile/${qcProfileID}/steps/new`,
    method: 'POST',
    data: {
      prev_step_subtype_id: prevStep ? prevStep.step_subtype_id : null,
      stage,
      type_label,
      description,
      product_code,
      category_flags,
      version,
      office,
      patches,
      time_estimate,
    }
  })
  .done(stepSubtypeID => {
    const step = steps[stage].find(s => !s.step_subtype_id);
    if (step) {
      step.step_subtype_id = stepSubtypeID;
      step.step_type_id = -qcProfileID;
    }
    dispatch(setSteps(steps));
    dispatch(showSuccess('The new step has been successfully created'));
  })
  .fail(error => {
    dispatch(showErrorOnSavingStep(steps, stage, error, 'Could not save new step'));
  });
};

export const updateStep = (qcProfileID, stage, stepSubtypeID, {label, name}, params) => (dispatch, getState) => {
  const steps = {...getState().settings.templateBuilderSettings.steps};
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/account/qc_profile/${qcProfileID}/steps/${stepSubtypeID}`,
    method: 'POST',
    data: {
      ...params,
      field_name: name
    }
  })
  .done(newStepSubtypeID => {
    if (stepSubtypeID !== newStepSubtypeID) {
      const step = steps[stage].find(s => s.step_subtype_id === stepSubtypeID);
      if (step) {
        step.step_subtype_id = newStepSubtypeID;
      }
      dispatch(setSteps(steps));
    }
    dispatch(showSuccess(`The ${label} of step has been successfully updated`));
  })
  .fail(error => {
    dispatch(showErrorOnSavingStep(steps, stage, error, `Could not update step ${label}`));
  });
};

export const updateStepAssignee = (qcProfileID, stage, index, newValue, office, onSetLoading) => (dispatch, getState) => {
  const steps = {...getState().settings.templateBuilderSettings.steps};
  const {step_subtype_id} = steps[stage][index];
  const assigneeFieldPrefix = `${office.toLowerCase()}_assignee`;
  const prevValue = steps[stage][index][`${assigneeFieldPrefix}_id`];
  const prevName = steps[stage][index][`${assigneeFieldPrefix}_name`];

  if (newValue === prevValue) {
    return;
  }

  steps[stage][index][`${assigneeFieldPrefix}_id`] = newValue;
  if (prevName) {
    delete steps[stage][index][`${assigneeFieldPrefix}_name`];
  }
  dispatch(setSteps(steps));
  onSetLoading(true);
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/account/qc_profile/${qcProfileID}/steps/${step_subtype_id}/${assigneeFieldPrefix}`,
    method: 'POST',
    data: {
      assignee_id: newValue
    }
  })
  .done(() => {
    dispatch(showSuccess(`${office} Assignee successfully updated`));
    onSetLoading(false);
  })
  .fail(error => {
    dispatch(showError(`Could not update ${office} assignee.  ${getErrorMessage(error)}`));
    steps[stage][index][`${assigneeFieldPrefix}_id`] = prevValue;
    if (prevName) {
      steps[stage][index][`${assigneeFieldPrefix}_name`] = prevName;
    }
    dispatch(setSteps(steps));
    onSetLoading(false);
  });
};

export const updateStepSubtypeTask = (qcProfileID, stepSubtypeID, taskID, isMapped) => dispatch => {
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/account/qc_profile/${qcProfileID}/steps/${stepSubtypeID}/task/${taskID}`,
    method: 'POST',
    data: {
      is_mapped: isMapped
    }
  })
  .done(() => {
    //dispatch(showSuccess('The step has been successfully updated'));
  })
  .fail(error => {
    dispatch(showError(`Could not update step.  ${getErrorMessage(error)}`));
  });
};

export const getTemplateBuilderFilters = () => {
  return getData('tb/filters');
};

export const setTemplateBuilderFilters = filters => ({
  type: SETTINGS_TB_SET_FILTERS,
  filters
});

export const getStepDescriptions = () => dispatch => {
  return getData('tb/descriptions')
    .then(res => res)
    .catch(error => {
      console.log(getErrorMessage(error));
      dispatch(showError('Could not get step descriptions'));
      return error;
    });
};

export const updateStepTypeAndStage = (stage, index, typeName, typeStage) => (dispatch, getState) => {
  const {steps} = getState().settings.templateBuilderSettings;
  steps[stage][index].stage = typeStage;
  steps[stage][index].type_label = typeName;
  let resultIndex = index;
  if (stage !== typeStage) {
    steps[typeStage] = [...steps[typeStage] || [], {...steps[stage][index]}];
    steps[stage] = steps[stage].filter((_, i) => i !== index);
    if (!steps[stage].length) {
      delete steps[stage];
    }
    resultIndex = steps[typeStage].length - 1;
  }
  dispatch(setSteps(steps));
  return resultIndex;
};

export const openEditDescModal = (qcProfileID, stage, index) => (dispatch, getState) => {
  const step = getState().settings.templateBuilderSettings.steps[stage][index];
  dispatch(setIsOpenNewDescriptionModal(true, {qcProfileID, stage, index, currentStep: {...step}}));
};

export const closeEditDescModal = () => dispatch => {
  dispatch(setIsOpenNewDescriptionModal(false));
};

export const saveNewStepTypeInModal = (stepType, typeStage) => (dispatch, getState) => {
  const {qcProfileID, stage, index} = getState().settings.templateBuilderSettings.newTypeData;
  dispatch(setIsOpenNewTypeModal(false));
  dispatch(addNewStepType(typeStage, stepType));
  const resultIndex = dispatch(updateStepTypeAndStage(stage, index, stepType, typeStage));
  dispatch(openEditDescModal(qcProfileID, typeStage, resultIndex));
};

export const saveStepDescAndVersionAndOfficeInModal = (newDescription, version, office) => (dispatch, getState) => {
  const {steps, editDescData} = getState().settings.templateBuilderSettings;
  const {qcProfileID, stage, index, currentStep} = editDescData;
  const {step_subtype_id, product_code, description} = currentStep;
  if (description === newDescription) {
    dispatch(closeEditDescModal());
    return;
  }

  const params = {...currentStep, description: newDescription, version, office};

  const afterSave = () => {
    steps[stage][index] = {...steps[stage][index], description: newDescription, version, office};
    dispatch(setSteps(steps));
    dispatch(closeEditDescModal());
  };

  if (step_subtype_id) {
    return dispatch(updateStep(qcProfileID, stage, step_subtype_id, {label: 'description', name: 'description'}, params))
      .then(() => afterSave());
  } else if (product_code) {
    return dispatch(saveNewStep(qcProfileID, stage, index, params)).then(() => afterSave());
  } else {
    return afterSave();
  }
};

export const deleteNewStep = () => ({type: SETTINGS_TB_DELETE_NEW_STEP});

export const clearTemplateBuilderForQcProfile = () => ({type: SETTINGS_TB_CLEAR_FOR_QC_PROFILE});

export const updateHideVideoCaptions = value => dispatch => {
  dispatch({type: SETTINGS_TOGGLE_HIDE_VIDEO_CAPTIONS, value});
};

export const setScheduleProfileList = data => ({type: SETTINGS_SCHEDULES_SET_LIST, data});

export const setScheduleProfile = data => ({type: SETTINGS_SCHEDULES_SET_PROFILE, data});
