import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {buildVideoFormatName} from '../../constants';
import SelectFiles from './SelectFiles';
import styled from 'styled-components';

class SelectFilesForMultiFormats extends Component {

  autoSelectUploadOptionForAllVersions = (optionOfFirstVersion, isInit) => {
    const {data, onChange} = this.props;
    const {video_format} = data;
    const videoFormats = [...video_format];
    if (videoFormats.length > 1 && optionOfFirstVersion) {
      for (let i = 1; i < videoFormats.length; i++) {
        const noOption = !videoFormats[i].files.select_files_type_id;
        if (noOption || (!isInit && !videoFormats[i].files.isSetDefaultUploadOption)) {
          const versionRef = `selectFilesForFormat${i}`;
          if (this.refs[versionRef]) {
            this.refs[versionRef].handleChangeType(optionOfFirstVersion);
            videoFormats[i].files.isSetDefaultUploadOption = !noOption;
            onChange('video_format', videoFormats);
          }
        }
      }
    }
  };

  componentDidMount() {
    const {data} = this.props;
    const {video_format} = data;
    if (video_format.length > 1) {
      this.autoSelectUploadOptionForAllVersions(video_format[0].files.select_files_type_id, true);
    }
  }

  handleChange = (index, name, value) => {
    const {data, onChange} = this.props;
    const {video_format} = data;
    const videoFormats = [...video_format];
    videoFormats[index].files[name] = value;
    onChange('video_format', videoFormats);
    if (name === 'select_files_type_id') {
      if (index === 0) {
        this.autoSelectUploadOptionForAllVersions(value);
      } else if (!videoFormats[index].files.isSetDefaultUploadOption) {
        videoFormats[index].files.isSetDefaultUploadOption = true;
        onChange('video_format', videoFormats);
      }
    }
  };

  render() {
    const {data, filters} = this.props;
    const {video_format, studio_id, cs_studio_id, cs_production_id, cs_finishing_house_id} = data;
    const isMultiVersion = video_format.length > 1;
    return (
      <SelectFilesContainer className="step-body-wrapper select-files-for-multi-formats">
        {video_format.map((vf, index) => {
          const formatHeader = `Select File(s)${isMultiVersion ? `: ${buildVideoFormatName(filters, data.qc_profile_id, vf, true)}` : ''}`;
          const usedPackages = video_format.map((oVf, oIndex) => oIndex === index ? null : oVf.files.selectedFiles)
            .reduce((a, c) => !c ? a : a.concat(c), []);
          return (
            <div className="video-format-files" key={`video-format-files-${index}`}>
              <div
                data-toggle={isMultiVersion ? 'collapse' : undefined}
                data-target={isMultiVersion ? `#select-files-${index}` : undefined}
                aria-expanded={!isMultiVersion ? undefined : index > 0 ? "false" : "true"}
                className={`video-format-files-header${index > 0 ? ' collapsed' : ''}${isMultiVersion ? ' multi' : ''}`}
                >
                {formatHeader}{isMultiVersion ? <i className="fa fa-angle-down"/> : null}
              </div>
              <div
                id={`select-files-${index}`}
                className={!isMultiVersion ? undefined : `collapse${index > 0 ? '' : ' in'}`}
                >
                <SelectFiles
                  allowFileUpload={filters.allow_file_upload}
                  data={{...vf.files, studio_id, cs_studio_id, cs_production_id, cs_finishing_house_id}}
                  onChange={(name, value) => this.handleChange(index, name, value)}
                  usedPackages={usedPackages}
                  notSetDefaultUploadOption={index > 0}
                  ref={`selectFilesForFormat${index}`}
                />
              </div>
            </div>
          );
        })}
      </SelectFilesContainer>
    );
  }
}

const SelectFilesContainer = styled.div`
  position: relative;
  margin-top: 39px;

  .loader-container {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    .react-spinner {
      top: 49% !important;
    }
  }

  .video-format-files {
    margin-bottom: 30px;
    &:last-of-type {
      margin-bottom: 20px;
    }
    .video-format-files-header {
      font-weight: 700;
      font-size: 13px;
      line-height: 14px;
      &.multi {
        i {
          font-weight: 700;
          font-size: 13px;
          line-height: 14px;
          margin-left: 13px;
          transition: transform 0.2s ease 0s;
          will-change: transform;
        }
        &[aria-expanded="false"] i {
          transform: rotate(-90deg);
        }
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    .step-body-wrapper.select-files {
      margin: 0;
      .select-files-header {
        display: none;
      }
    }
  }
`;

SelectFilesForMultiFormats.propTypes = {
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SelectFilesForMultiFormats;
