import React from 'react';

export default class NavigationHamburgerIcon extends React.PureComponent<React.HTMLAttributes<HTMLSpanElement>> {
  render() {
    return (
      <span {...this.props}>
        <svg
          width="9"
          height="6"
          viewBox="0 0 9 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7.00634 0H0.151163C0.0675729 0 0 0.0707133 0 0.157274C0 0.244243 0.0675749 0.314548 0.151163 0.314548H7.00674C7.09033 0.314548 7.15791 0.244241 7.15791 0.157274C7.15752 0.0707112 7.08994 0 7.00635 0H7.00634Z" fill="black"/>
          <path d="M7.00634 2.84277H0.151163C0.0675729 2.84277 0 2.91308 0 3.00005C0 3.08701 0.0675749 3.15732 0.151163 3.15732H7.00674C7.09033 3.15732 7.15791 3.08701 7.15791 3.00005C7.15752 2.91308 7.08994 2.84277 7.00635 2.84277H7.00634Z" fill="black"/>
          <path d="M7.00634 5.68542H0.151163C0.0675729 5.68542 0 5.75573 0 5.8427C0 5.92967 0.0675749 5.99997 0.151163 5.99997H7.00674C7.09033 5.99997 7.15791 5.92967 7.15791 5.8427C7.15752 5.75573 7.08994 5.68542 7.00635 5.68542H7.00634Z" fill="black"/>
          <path d="M8.84881 2.84277H8.45194C8.36835 2.84277 8.30078 2.91308 8.30078 3.00005C8.30078 3.08701 8.36836 3.15732 8.45194 3.15732H8.84881C8.9324 3.15732 8.99997 3.08701 8.99997 3.00005C8.99997 2.91308 8.93239 2.84277 8.84881 2.84277Z" fill="black"/>
          <path d="M8.45194 0.314547H8.84881C8.9324 0.314547 8.99997 0.244241 8.99997 0.157274C8.99997 0.0703045 8.93239 0 8.84881 0H8.45194C8.36835 0 8.30078 0.0703065 8.30078 0.157274C8.30078 0.244243 8.36836 0.314547 8.45194 0.314547Z" fill="black"/>
          <path d="M8.84881 5.68542H8.45194C8.36835 5.68542 8.30078 5.75573 8.30078 5.8427C8.30078 5.92967 8.36836 5.99997 8.45194 5.99997H8.84881C8.9324 5.99997 8.99997 5.92967 8.99997 5.8427C8.99997 5.75573 8.93239 5.68542 8.84881 5.68542Z" fill="black"/>
        </svg>
      </span>
    );
  }
}