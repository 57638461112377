import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import backImg from '../../../assets/images/back.svg';
import sidebarMenuImg from '../../../assets/images/sidebar-menu-btn.svg';

const MobileSidebarHeader = ({closeSidebar}) => {
  return (
    <StyledMobileSidebarHeader className="visible-xs">
      <span className="back-btn pull-left" onClick={closeSidebar}/>
      <span className="menu-btn pull-right" onClick={closeSidebar}/>
    </StyledMobileSidebarHeader>
  );
};

const StyledMobileSidebarHeader = styled.div`
  padding: 15px 20px;
  height: 50px;
  .back-btn {
    background: url(${backImg}) no-repeat center center;
    display: block;
    height: 20px;
    width: 20px;
  }
  .menu-btn {
    background: url(${sidebarMenuImg}) no-repeat center center;
    display: block;
    height: 20px;
    width: 20px;
  }
`;

MobileSidebarHeader.propTypes = {
  closeSidebar: PropTypes.func.isRequired
};

export default MobileSidebarHeader;
