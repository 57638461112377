import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {PieChart, Pie, Cell, Sector, ResponsiveContainer, Legend} from 'recharts';
import NoData from '../NoData';
import isMobile from 'is-mobile';

const COLORS = [
  '#FFE400',
  '#8AF1DB',
  '#FB9199',
  '#DB91FB',
  '#742A70'
];

class Chart extends Component {
  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getValues() {
    const {data} = this.props;
    const res = data ? data.map((item, index) => {
      return {
        name: item.Type,
        value: item.IssuePercent,
        color: COLORS[index] || this.getRandomColor(),
        count: item.IssueCount
      }
    }) : [];

    const values = res.filter(o => o.value !== 0);
    return this.sortValues(values);
  }

  sortValues(arr) {
    const sortValues = _.orderBy(arr, ['value'], ['desc']);
    sortValues.forEach((item, index) => {
      item.index = index;
    });
    return sortValues;
  }

  renderActiveShape = props => {
    const {cx, cy, outerRadius, startAngle, endAngle, fill, index} = props;
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          outerRadius={outerRadius - index * 5 }
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  renderCustomizedLabel = props => {
    const {cx, cy, midAngle, outerRadius, value, name, count, index} = props;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    let outerRadiusDiff = 10;
    let oneLineLabel = false;
    if (value < 10 && index > 0) {
      outerRadiusDiff = [10, 20, 25, 40, 50][index];
      const values = this.getValues();
      if ((values[index - 1].value - value) < 3) {
        oneLineLabel = true;
      }
    }

    const sx = cx + (outerRadius + outerRadiusDiff) * cos;
    const sy = cy + (outerRadius + outerRadiusDiff) * sin;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    const preparedValue = `(${value.toFixed(2)}%)`;
    return (
      <>
        <path d={`M${cx},${cy}L${sx},${sy}L${sx + (cos >= 0 ? 1 : -1) * 8},${sy}`} stroke={props.color} fill="none"/>
        <circle cx={sx + (cos >= 0 ? 1 : -1) * 8} cy={sy} r={2} fill={props.color} stroke="none"/>
        <text
          x={sx + (cos >= 0 ? 1 : -1) * 12}
          y={sy}
          textAnchor={textAnchor}
          fill="#333"
          style={{fontWeight: 'bold', fontSize: '11px'}}
        >
          {name}, {count} {
            oneLineLabel ? preparedValue :
              <tspan x={sx + (cos >= 0 ? 1 : -1) * 12} y={sy} dy={12} textAnchor={textAnchor}>
                {preparedValue}
              </tspan>
          }
          <title>{count} issues by {name} type</title>
        </text>
      </>
    );
  };

  renderLegend = props => {
    const {payload} = props;
    return (
      <ul style={{padding: 0, margin: 0, textAlign: 'left'}}>
        {
          payload.map((entry, index) => (
            <li
              key={`item-${index}`}
              style={{
                padding: 0,
                margin: `${index > 0 ? 20 : 0}px 5px 0 0`,
                display: 'block',
                color: '#31394D',
                fontWeight: 'bold',
                fontSize: '11px'
              }}
              >
              <i
                style={{
                  backgroundColor: entry.color,
                  height: '4px',
                  width: '19px',
                  borderRadius: '3px',
                  display: 'inline-block',
                  margin: '0px 3px 2px 0'
                }}
                /><br/>
              {entry.value} {entry.payload.count}<br/><span>({entry.payload.value.toFixed(2)}%)</span>
            </li>
          ))
        }
      </ul>
    );
  };

  render() {
    const {data} = this.props;
    const values = this.getValues();

    if ([undefined, null].includes(data) || !data.length) {
      return <NoData/>;
    }

    const height = isMobile() && data.length > 4 ? 355 : 300;

    return (
      <ChartStyled height={height}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              activeIndex={data.map((item, index) => index)}
              activeShape={this.renderActiveShape}
              data={values}
              dataKey="value"
              outerRadius={isMobile() ? 60 : 70}
              fill="#8884d8"
              startAngle={-270}
              labelLine={false}
              label={isMobile() ? undefined : this.renderCustomizedLabel}
              isAnimationActive={false}
            >
              {
                values.map((entry, index) => <Cell key={index} fill={entry.color}/>)
              }
            </Pie>
            {
              isMobile() ? (
                <Legend
                  content={this.renderLegend}
                  height={height}
                  layout="vertical"
                  verticalAlign="middle"
                  align="right"
                  wrapperStyle={{display: 'flex', alignItems: 'center'}}
                  />
              ) : null
            }
          </PieChart>
        </ResponsiveContainer>
      </ChartStyled>
    );
  }
}

const ChartStyled = styled.div`
  height: 300px;
  height: ${props => `${props.height}px`};
  width: 100%;
`;

Chart.propTypes = {
  data: PropTypes.array
};

export default Chart;
