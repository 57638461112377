import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import styled from 'styled-components';
import DateCell from './DateCell';
import {Link} from 'react-router-dom';
import calendarImg from '../../../assets/images/season/calendar.svg';
import calendarActiveImg from '../../../assets/images/season/calendar-active.svg';
import lockImg from '../../../assets/images/season/lock.svg';
import lockActiveImg from '../../../assets/images/season/lock-active.svg';
import arrowUpImg from '../../../assets/images/request/arrow-up.svg';

export const TABLE_GRAY_BG = '#f0f0f0';

const Row = props => {
  const {
    headers, row, rowTitleId, showScheduledDates, showCalendarLink, selectedCell, onDateClick, onLockRequest
  } = props;

  const [expandedMilestones, setExpandedMilestones] = useState(false);

  const isRowNotFilledIn = headers.some(header => !row.milestones[header.scheduling_id] || !row.milestones[header.scheduling_id].scheduled_date);

  const handleLockClick = () => {
    if (!!row.lock_scheduling) {
      alert('You can\'t unlock scheduling');
      return;
    }
    if (isRowNotFilledIn) {
      alert('A row can’t be locked until all dates are filled in');
      return;
    }
    onLockRequest(row.ProjectID);
  }

  const lockClassNames = ClassNames({
    active: !!row.lock_scheduling,
    disabled: isRowNotFilledIn
  });

  const maxStartDisplayedMilestones = 4;
  const showMoreMilestones = headers.length > maxStartDisplayedMilestones;

  return (
    <StyledRow className={`${showMoreMilestones ? '' : 'no-show-more'}${showCalendarLink ? '' : ' no-show-calendar'}`}>
      <td className="title">{row[rowTitleId]}</td>
      {headers.map((header, index) => {
        return (
          <DateCell
            key={header.scheduling_id}
            row={row}
            isCollapsed={index >= maxStartDisplayedMilestones && !expandedMilestones}
            schedulingId={header.scheduling_id}
            schedulingName={header.scheduling_name}
            showScheduledDates={showScheduledDates}
            selectedCell={selectedCell}
            onClick={onDateClick}
          />
        )
      })}
      {showCalendarLink ?
        <td className="calendar">
          <Link to={`/requests/${row.RequestID}?showSchedule=true`} disabled={!row.RequestID}>Calendar</Link>
        </td> : null}
      <td className="lock">
        <i
          className={lockClassNames}
          title={isRowNotFilledIn ? 'A row can’t be locked until all dates are filled in' : null}
          onClick={handleLockClick}
        />
      </td>
      <td className="show-more">
        {
          showMoreMilestones ?
            <i
              className={expandedMilestones ? 'expanded' : ''}
              onClick={() => setExpandedMilestones(!expandedMilestones)}
            /> : null
        }
      </td>
    </StyledRow>
  );
};

const StyledRow = styled.tr`
  @media (min-width: 768px) {
   &:first-of-type td {
     border-top: 1px solid #d2d2d2;
   }
   td {
    background-color: ${TABLE_GRAY_BG};
    border-bottom: 1px solid #d2d2d2;
    height: 40px;
    &.title {
      font-size: 11px;
      padding-right: 21px;
      text-align: right;
    }
    &.calendar {
      border-left: 1px solid #d2d2d2;
    }
    &.lock {
      border-left: 1px solid #d2d2d2;
      border-right: 1px solid #d2d2d2;
      i {
        &.active {
          cursor: pointer;
        }
      }
    }
    &.show-more * {
      display: none;
    }
   }
  }

  td {
    &.calendar {
      width: 39px;
      a {
        background: url(${calendarImg}) no-repeat center center;
        color: transparent;
        display: block;
        font-size: 0;
        height: 100%;
        width: 100%;
        line-height: 0;
        &:hover {
          background-color: #444444;
          background-image: url(${calendarActiveImg});
        }
        &[disabled] {
          pointer-events: none;
          opacity: 0.8;
        }
      }
    }
    &.lock {
      width: 39px;
      i {
        background: url(${lockImg}) center center no-repeat;
        display: block;
        height: 100%;
        width: 100%;
        &.active {
          background-image: url(${lockActiveImg});
        }
        &.disabled {
          opacity: 0.8;
        }
        &:hover {
          cursor: pointer;
          background-image: url(${lockActiveImg});
        }
      }
    }
  }

  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 25px 18px 0;
    border: none;
    border-bottom: 0.5px solid rgba(0,0,0,0.3);
    td {
      background-color: #fff;
      border: none;
      width: 100%;
      order: 5;
      font-size: 13px;
      line-height: 14px;
      &.title {
        order: 1;
        width: calc(100% - 120px);
        font-size: 15px;
        font-weight: bold;
        line-height: 16px;
        padding-bottom: 17px;
        padding-top: 25px;
      }
      &.calendar {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        order: 2;
        width: 40px;
        a {
          width: 18px;
          height: 19px;
          background-size: 18px;
        }
      }
      &.lock {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        order: 3;
        width: 40px;
        i {
          width: 14px;
          height: 19px;
          margin-top: 1px;
          background-size: 14px;
        }
      }
      &.show-more {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        order: 4;
        width: 40px;
        i {
          background: url(${arrowUpImg}) center center no-repeat;
          display: block;
          height: 16px;
          width: 12px;
          opacity: 0.4;
          transform: rotate(180deg);
          transition: none;
          transition-property: transform;
          transition-duration: 0.25s;
          transition-timing-function: ease;
          &.expanded {
            transform: initial;
          }
        }
      }
    }
    &.no-show-more td {
      &.title {
        width: calc(100% - 80px);
      }
      &.show-more {
        width: 0;
      }
    }
    &.no-show-calendar td {
      &.title {
        width: calc(100% - 80px);
      }
      &.calendar {
        width: 0;
      }
    }
    &.no-show-more.no-show-calendar td {
      &.title {
        width: calc(100% - 40px);
      }
      &.show-more, &.calendar {
        width: 0;
      }
    }
  }
`;

Row.propTypes = {
  headers: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
  rowTitleId: PropTypes.string.isRequired,
  showScheduledDates: PropTypes.bool.isRequired,
  showCalendarLink: PropTypes.bool,
  selectedCell: PropTypes.object.isRequired,
  onDateClick: PropTypes.func.isRequired,
  onLockRequest: PropTypes.func.isRequired
};

export default Row;