import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {Button} from 'react-bootstrap';
import {ContentStyled, UserNameShortStyled, UserLogoStyled} from '../modals/IssueDiscussModal';
import CommentContent from './CommentContent';

class Comment extends Component {
  getNameFirstLetters(name) {
    return name && name.split(' ').map(s => s[0]).slice(0, 2).join('');
  }

  render() {
    const {comment, onClickMore, length, isFirstComment} = this.props;

    return (
      <CommentStyled>
        {
          comment.UserImageUrl ? (
            <div className="user-column-logo">
              <UserLogoStyled logoUrl={comment.UserImageUrl}></UserLogoStyled>
            </div>
          ) : (
            <UserNameShortStyled className="username-short content-column" bgColor="#999">
              {this.getNameFirstLetters(comment.UserName || 'No Name')}
            </UserNameShortStyled>
          )
        }
        <div className="text-block content-column">
          <div className="username">{comment.UserName || <>&nbsp;</>}</div>
          <CommentDescStyled overflow={isFirstComment ? 1 : 0}>
            <CommentContent comment={comment} prefixKey="sci"/>
          </CommentDescStyled>
          {isFirstComment && length > 1 ? (
            <Button
              onClick={onClickMore}
            >+{length - 1} other comments</Button>
          ) : null}
        </div>
      </CommentStyled>
    );
  }
}

const CommentStyled = styled(ContentStyled)`
  background: #FFF7D9;
  padding: 10px 15px;
  .username-short {
    min-width: 33px;
  }
  .text-block {
    overflow: hidden;
    .username {
      font-weight: bold;
    }
  }
  .btn {
    margin-right: auto;
  }

  .user-column-logo {
    flex-grow: 1;
    min-width: 33px;
    max-width: 33px;
    height: 33px;
    margin-right: 11px;
  }
`;

const CommentDescStyled = styled.div`
  ${props => props.overflow && css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;

Comment.propTypes = {
  length: PropTypes.number,
  comment: PropTypes.object.isRequired,
  onClickMore: PropTypes.func,
  isFirstComment: PropTypes.bool
};

export default Comment;
