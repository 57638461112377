import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import TextEditableField from './TextEditableField';
import SelectAdvanced from '../../../../components/forms/SelectAdvanced';
import MultiSelect from './MultiSelect';
import MultiSelectEditable from './MultiSelectEditable';

export const UserPermission = {
  NO_ACCESS: 1,
  CAN_VIEW: 2,
  CAN_COMMENT: 3,
  CAN_MANAGE: 4,
};

const isShowManager = ({permissionType}) => permissionType === UserPermission.CAN_MANAGE;

function RequestUserPermissions(props) {
  const {
    userType, companyID, companyName, userList, onChange, dictionaries, forRequestWizard = false, forManagerUser = true,
    onChangeCompanyLevelPermissions, onChangeMultiShowManagers
  } = props;
  const options = dictionaries.user_permissions || [];
  const withShowManagers = companyID === dictionaries.company_for_show_managers && !!userList.length;
  const showManagers = withShowManagers ? userList.filter(isShowManager) : null;
  const isNoUsers = !userList.length || (userList.length === 1 && !userList[0].userId);

  return (
    <StyledUserPermissions forRequestWizard={forRequestWizard} forManagerUser={forManagerUser}>
      <div className="rup-header">
        <div className="rup-header-company">
          <div className="company-name">{companyName}</div>
          <div className="company-type">{userType === 'Finishing House' ? 'Post Partner' : userType}</div>
        </div>
        {!isNoUsers && !withShowManagers &&
        <SelectAdvanced
          options={options}
          onSelect={(_, newValue) => onChangeCompanyLevelPermissions(userType, companyID, newValue)}
          placeholder="Click to change permissions for all users"
        />
        }
      </div>
      <div className="rup-data">
        {isNoUsers ? <div className="rup-data-empty">No Users</div> :
          <>
            {withShowManagers &&
              <div className="rup-data-item show-managers">
                {forRequestWizard ?
                  <MultiSelect
                    label="Show Managers"
                    options={userList.map(({userId, userName, needToConfirm}) => ({value: userId, name: userName, isDisabled: !needToConfirm}))}
                    value={showManagers.map(({userId, userName}) => ({value: userId, name: userName}))}
                    onChange={selected => {
                      const prevSelected = showManagers.map(({userId, userName}) => ({value: userId, name: userName}));
                      prevSelected.filter(i => !selected.find(j => j.value === i.value)).forEach(i => onChange(userType, i.value, UserPermission.CAN_VIEW));
                      selected.filter(i => !prevSelected.find(j => j.value === i.value)).forEach(i => onChange(userType, i.value, UserPermission.CAN_MANAGE));
                    }}
                    requiredValue={showManagers.filter(({needToConfirm}) => !needToConfirm).map(({userId}) => userId)}
                    isDisabled={userList.every(({needToConfirm}) => !needToConfirm)}
                  /> :
                  <MultiSelectEditable
                    label="Show Managers"
                    options={userList.map(({userId, userName}) => ({value: userId, name: userName}))}
                    value={showManagers.map(({userId}) => (userId))}
                    onConfirm={(l, n, selected) => onChangeMultiShowManagers(userType, companyID, selected)}
                  />
                }
              </div>
            }
            {(!withShowManagers || !userList.every(isShowManager)) && <>
            <div className="rup-data-item rup-data-header">
              <div className="user-full-name">
                User
              </div>
              <div className="permission-type">
                Permission
                <span>Click to change</span>
              </div>
            </div>
            {userList.sort((a, b) => (a.userName || '').localeCompare(b.userName || '')).map(item => (!withShowManagers || !isShowManager(item)) &&
              <div key={`rup-data-item-${item.userID || item.userId}`} className="rup-data-item">
                <div className="user-full-name">
                  {item.userName}
                </div>
                <StyledEditableField forRequestWizard={forRequestWizard}>
                  {forRequestWizard ?
                    <SelectAdvanced
                      value={item.permissionType}
                      options={options}
                      onSelect={(_, newValue) => onChange(userType, item.userId, newValue)}
                      disabled={!item.needToConfirm}
                    /> :
                      <TextEditableField
                        label=""
                        value={item.permissionType}
                        options={options}
                        onConfirm={(label, name, newValue, callback) => !!newValue && onChange(userType, companyID, item.userId, newValue, callback, item.userName, options.find(o => o.value === newValue))}
                        isSelectControl
                        hiddenHint
                        selectProps={{selectClassName: 'menu_zIndex100'}}
                      />
                  }
                </StyledEditableField>
              </div>
            )}
            </>}
          </>
        }
      </div>
    </StyledUserPermissions>
  );
}

const StyledUserPermissions = styled.div`
  padding-bottom: 30px;
  ${props => props.forRequestWizard && css`
    padding-top: 0;
  `}

  .rup-header {
    ${props => props.forRequestWizard && !props.forManagerUser && css`
      display: none;
    `}

    padding: 10px 14px 10px;;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.07);
    color: #000;
    border-radius: 5px 5px 0 0;
    font-weight: bold;
    flex: auto;
    display: flex;
    align-items: end;

    .company-name {
      padding-bottom: 2px;
      font-size: 14px;
    }
    .company-type {
      font-size: 12px;
      color: #757575;
    }

    .form-group {
      width: 300px;
      margin-left: 15px;
    }

    @media (min-width: 768px) {
      width: 718px;
      max-width: 100%;
    }
  }

  .rup-data {
    margin-top: -10px;
    ${props => props.forRequestWizard && !props.forManagerUser && css`
      margin-top: 0;
    `}
    padding: 15px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
    color: #000;
    border-radius: 5px;

    @media (min-width: 768px) {
      width: 718px;
      max-width: 100%;
    }

    > div {
      background-color: #f9f9f9;
    }

    .rup-data-empty {
      padding: 18px 8px;
      opacity: 0.7;
      font-size: 11px;
      line-height: 12px;
      ${props => props.forRequestWizard && css`
        font-size: 12px;
        line-height: 13px;
      `}
    }

    .rup-data-item {
      padding: 0;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 11px;
      line-height: 12px;

      &.show-managers {
        padding: 13px 18px 10px;
        border-bottom: 0;
        .form-group {
          margin: 0;
          width: 100%;
          font-size: 13px;
          line-height: 14px;
          .custom-multi-select--select--is-disabled {
            .custom-multi-select--select__indicator {
              opacity: 0.35;
            }
          }
        }
      }

      &.rup-data-header {
        padding-top: 18px;
        padding-bottom: 5px;
        font-weight: bold;
        font-size: 12px;
        line-height: 13px;
        color: rgba(0, 0, 0, 0.7);
        ${props => props.forRequestWizard && css`
          color: rgba(0, 0, 0, 0.8);
          * {
            font-size: 13px !important;
            line-height: 14px !important;
          }
        `}
      }
      &:last-of-type {
        box-shadow: 0 8px 0 #f9f9f9;
      }
      .user-full-name {
        padding: 4px 9px 4px 18px;
        flex: 0 0 50%;
        ${props => props.forRequestWizard && css`
          font-size: 12px;
          line-height: 13px;
        `}
      }
      .user-full-name + div {
        padding: 4px 18px 4px 9px;
        flex: 0 0 50%;
        position: relative;
        i {
          position: absolute;
          top: 8px;
          left: -3px;
          padding: 0 4px;
        }
        span {
          font-weight: normal;
          font-size: 10px;
          margin-left: 10px;
        }
      }
      @media (max-width: 767px) {
        .user-full-name {
          padding: 4px 4px 4px 10px;
          flex: 0 0 40%;
        }
        .user-full-name + div {
          padding: 4px 10px 4px 4px;
          flex: 0 0 60%;
          i {
            left: -5px;
          }
        }
      }
    }
  }

  .Select-placeholder, .Select-menu-outer {
    font-weight: initial;
  }
`;

const StyledEditableField = styled.div`;
  > .form-group {
    @media (min-width: 768px) {
      min-width: 200px;
    }

    .editable-control {
      @media (min-width: 992px) {
        width: 100%;
      }
    }

    .form-control {
      &.on-edit-mode {
        min-width: 30px;
        font-size: 11px;
        line-height: 19px;
        &:not(:hover) {
          background-color: transparent;
          border-color: transparent;
        }
      }
    }

    .Select,
    .Select.has-value.Select--single,
    .Select.Select--single,
    .Select--multi,
    .Select.is-searchable.Select--single {
      ${props => props.forRequestWizard && css`
        &.is-disabled {
          opacity: 0.75;
          > .Select-control {
            background-color: transparent !important;
          }
        }
      `}
      > .Select-control {

        .Select-multi-value-wrapper {

          .Select-placeholder,
          .Select-value {
            padding-left: 6px !important;
            padding-right: 14px !important;
            font-size: 11px !important;
            color: #aaa !important;

            .Select-value-label {
              font-size: 11px !important;
            }
          }

          .Select-placeholder {
            color: #aaa !important;
          }

          .Select-input {
            padding-left: 6px !important;
            padding-right: 1px !important;
            input {
              font-size: 11px !important;
            }
          }
        }

        .Select-arrow-zone {
          width: 17px !important;
          img {
            margin: 0 !important;
            width: 7px !important;
          }
        }
      }
      > .Select-menu-outer {
        max-width: 100% !important;
        * {
          font-size: 11px !important;
        }
        .Select-option {
          padding-left: 6px !important;
          padding-right: 6px !important;
        }
      }
    }
  }
  .form-group {
    margin-bottom: 0;
  }
`;

RequestUserPermissions.propTypes = {
  forRequestWizard: PropTypes.bool,
  forManagerUser: PropTypes.bool,
  userType: PropTypes.string.isRequired,
  companyID: PropTypes.number,
  companyName: PropTypes.string,
  userList: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  dictionaries: PropTypes.object.isRequired
};

export default RequestUserPermissions;
