import styled, {css} from 'styled-components';
import {Button} from 'react-bootstrap';

export const StyledTableWrap = styled.div`
  --qod-wo-step-deleted-color: #ff8100;
  --qod-wo-step-new-color: #0287e5;

  max-width: 100%;
  ${props => !props.withSuggestions && css`
    overflow-x: auto;
  `}

  .fa-exchange-alt {
    transform: rotate(90deg);
  }

  .fa-bars {
    &:hover {
      background-color: #ccc;
      border-radius: 2px;
      box-shadow: 3px -2px 0 #ddd, -3px 2px 0 #ddd, -3px -2px 0 #ddd, 3px 2px 0 #ddd;
    }
  }

  &:not(.edit-mode) {
    overflow-x: auto;
    overflow-y: hidden;
    .table {
      tr {
        th, td {
          &.without--cost {
            > .selected-option {
              font-weight: bold;
              color: #E75592;
            }
          }
        }
      }
    }
  }

  .table {
    margin: 0;
    border-collapse: separate;
    border-spacing: 0;

    tr {
      th, td {
        &:first-child {
          padding-left: 10px;
        }
        &.step-unit {
          min-width: 90px;
        }
        ${props => !props.isCreatedWO && css`
          &.status, &.time-spent {
            display: none;
          }
        `}
      }
    }

    & > thead {
      & > tr {
        & > th {
          padding: 40px 20px 14px 0;
          border: 0;
          border-bottom: 0.5px solid #DADADA;
          background: #fff;
          font-weight: 700;
          font-size: 12px;
          line-height: 13px;
          text-align: left;
          vertical-align: middle;
          white-space: nowrap;
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }

    tbody {
      & + tbody {
        border-top: 0;
      }

      tr {

        td {
          padding: 7px 10px 7px 0;
          border-top: 0;
          border-bottom: 0.5px solid #DADADA;
          background: #fff;
          font-weight: 400;
          font-size: 12px;
          line-height: 12px;
          text-align: left;
          vertical-align: middle;
          color: #000000;

          &.disabled {
            pointer-events: none;
          }

          &.data-table-empty {
            padding: 15px;
            text-align: center;
            color: rgba(0, 0, 0, 0.7);
          }

          /*& > input {
            width: 100%;
            padding: 5px;
            height: 30px;
            line-height: 23px;
            border: 1px solid rgba(51, 51, 51, .16);
            border-radius: 2px;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          }
          & > input:focus {
            outline: 0;
            box-shadow: none;
            border-color: rgba(51, 51, 51, .5);
          }*/

          &.step-label {
            > div {
              width: 170px;
              word-break: break-word;
            }
          }

          &.step-description {
            > div {
              width: 200px;
              word-break: break-word;
            }
          }

          &.product-code {
            > div {
              width: 150px;
              word-break: break-word;
            }
          }

          &.assignee {
            > div {
              width: 135px;
            }
          }

          &.category-flags {
            > div {
              width: 160px;
            }
          }

          &.sort {
            cursor: move;
          }

          &.actions {
            padding-right: 2px;

            > div {
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }

            button {
              padding: 0;
              width: 26px;
              height: 26px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              border: none !important;
              box-shadow: none !important;
              outline: none !important;
            }
          }

          &.step-order {
            white-space: nowrap;
            sup {
              left: 0.5em;
              text-transform: uppercase;
            }
          }
        }

        &.canceled {
          text-decoration: line-through;
        }

        &.canceled, &.disabled {
          pointer-events: none;
        }

        &.disabled {
          opacity: 0.75;
        }

        &:last-child td {
          /*border-bottom: none;*/
        }

        &:hover {
          background-color: #f9f9f9;
          box-shadow: 0 20px 25px rgba(0, 0, 0, 0.05);
        }

        &.is-new-step-row {
          td {
            &.sort,
            &.step-order {
              color: var(--qod-wo-step-new-color);
            }
          }
        }

        &.deleted {
          td {
            &.sort,
            &.step-order {
              color: var(--qod-wo-step-deleted-color);
            }
            &:not(.actions, .step-order) {
              text-decoration: line-through;
              text-decoration-color: var(--qod-wo-step-deleted-color);
              text-decoration-style: dotted;
              opacity: 0.75;
            }
            &:not(.actions) {
              pointer-events: none;
            }
          }
        }
      }
    }
  }
`;

export const StyledButtonToolbar = styled.div`
  margin: 17px 0 20px !important;
  display: flex;
  flex-direction: row;
  column-gap: 7px;
  row-gap: 7px;
  @media (max-width: 767px) {
    margin-left: 7px !important;
    margin-right: 7px !important;
    flex-direction: column;
  }
`;

export const StyledButton = styled(Button)`
  padding: 9px 11px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  background-color: #33333f !important;
  opacity: 0.8;
  border-radius: 100px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  font-size: 13px;
  line-height: 15px;
  &:not([disabled]):hover {
    opacity: 1;
  }
  .new-request-wrapper & {
    margin-bottom: 50px !important;
  }
`;
