import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loader from '../../../../components/Loader';
import GeneralComment from './GeneralComment';
import CommentForm, {HeightOfCommentToolbar} from '../../../../components/comments/CommentForm';
import _ from 'lodash';
import isMobile from 'is-mobile';
import {getMentions} from '../../actions';

function GeneralComments(props) {
  const {isLoading, data: {comments/*, currentCommentId*/}, onSave, requestId} = props;

  const commentsByUsers = _.groupBy(comments, 'UserName');

  const formHeight = (isMobile() ? 81 : 65) + HeightOfCommentToolbar;

  return (
    <StyledContainer>
      <StyledComments className="sidebar-general-comments-list-cont" formHeight={formHeight}>
        {isLoading && <Loader/>}
        {!comments.length ? <NoComments>There are no comments yet</NoComments> :
          <StyledList>
            {Object.keys(commentsByUsers).map((userName, i) => (
              <GeneralComment
                key={userName || 'No Name'}
                comments={commentsByUsers[userName]}
                even={((i + 1) % 2) === 0}
              />
            ))}
          </StyledList>
        }
      </StyledComments>
      <CommentForm
        isLoading={isLoading}
        onGetMentions={getMentions}
        onSave={onSave}
        requestId={requestId}
        height={formHeight}
        requestImmediateResponseForMentionType="EDMS"
      />
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  height: calc(100vh - 103px);

  .sidebar-fixed & {
    height: calc(100vh - 43px);
  }

  @media (max-width: 767px) {
    height: calc(100vh - 93px) !important;
  }
`;

const StyledComments = styled.div`
  position: relative;
  padding: 15px;
  height: calc(100% - ${props => props.formHeight + 'px'});
  max-height: calc(100% - ${props => props.formHeight + 'px'});
  overflow: auto;
  scroll-behavior: smooth;

  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 3;

    .react-spinner {
      top: 40%;
    }
  }
`;

const StyledList = styled.div`
  padding: 0;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-size: 12px;
`;

const NoComments = styled.div`
  padding: 20px 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #909090;
  font-size: 15px;
  line-height: 21px;
`;

GeneralComments.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  requestId: PropTypes.number.isRequired
};

export default GeneralComments;
