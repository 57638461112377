import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import main from './layouts/reducer';
import user from './pages/Login/reducer';
import dashboard from './pages/Dashboard/reducer';
import requests from './pages/Requests/reducer';
import newrequest from './pages/NewRequest/reducer';
import seasondetails from './pages/SeasonDetails/reducer';
import reportdetails from './pages/ReportDetails/reducer';
import requestdetails from './pages/RequestDetails/reducer';
import analytics from './pages/Analytics/reducer';
import search from './pages/Search/reducer';
import settings from './pages/Settings/reducer';
import requestuuid from './pages/RequestByUUID/reducer';
import requestwomemfis from './pages/RequestByWOMemfis/reducer';
import admin from './pages/Admin/reducer';
import adminAnalytics from './pages/Admin/Analytics/reducer';
import woConflicts from './pages/WOConflicts/reducer';
import manualWoConvert from './pages/ConvertManualWO/reducer';
import artworkReport from './pages/Artwork/Report/reducer';
import artworkDashboard from './pages/Artwork/Dashboard/reducer';
import artworkTopFilters from './pages/Artwork/components/TopFilters/reducer';
import artworkAdminParams from './pages/Artwork/Admin/Parameters/reducer';
import artworkAdminSpecs from './pages/Artwork/Admin/Specs/reducer';
import artworkRequestQueue from './pages/Artwork/RequestQueue/reducer';

const rootReducer = combineReducers({
  routing: routerReducer,
  main,
  user,
  dashboard,
  requests,
  newrequest,
  seasondetails,
  reportdetails,
  requestdetails,
  analytics,
  search,
  settings,
  requestuuid,
  requestwomemfis,
  admin,
  adminAnalytics,
  woConflicts,
  manualWoConvert,
  artworkReport,
  artworkDashboard,
  artworkTopFilters,
  artworkAdminParams,
  artworkAdminSpecs,
  artworkRequestQueue,
});

export default rootReducer;
