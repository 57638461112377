/* eslint "complexity":"off" */
import {USER_SET_IS_LOGOUT} from '../pages/Login/actions';
import {
  MAIN_ADD_ALERT,
  MAIN_HIDE_ALERT,
  MAIN_HIDE_ALL_ALERTS,
  MAIN_TOGGLE_SHOW_SIDEBAR,
  MAIN_NOTIFICATIONS_IS_LOADING,
  MAIN_NOTIFICATIONS_UNSEEN_RECEIVE,
  MAIN_NOTIFICATIONS_BY_FILTER_RECEIVE,
  MAIN_NOTIFICATIONS_SHOW_BAR,
  MAIN_NOTIFICATIONS_SHOW_MODAL,
  MAIN_CS_STUDIOS_IS_LOADING,
  MAIN_CS_GET_STUDIOS,
  MAIN_CS_AUTO_CREATE_REPLACEMENT_WO,
  MAIN_CS_SHOW_CREATE_REQUEST_MODAL,
  MAIN__IS_LOADING,
} from './actions';

const initialState = {
  alerts: [],
  sidebarIsOpen: false,

  unseenNotifications: {
    action_items_count: 0,
    system_alerts_count: 0,
    last_system_alert_text: null,
    unanswered_comments_count: 0,
  },
  notifications: {
    all: {
      total: 0,
      data: [],
      preparedData: {
        archived: [],
        action_items: [],
        system_alerts: [],
        unanswered_comments: [],
      }
    },
    archived: {total: 0, data: []},
    action_items: {total: 0, data: []},
    system_alerts: {total: 0, data: []},
    unanswered_comments: {total: 0, data: []},
  },
  notificationsIsMoreLoading: false,
  notificationsIsLoading: false,
  notificationBarIsOpen: false,
  notificationsModalIsOpen: false,
  notificationsModalFilter: 'all',

  csStudiosIsLoading: false,
  csCreateRequestModalData: null,
  requestIdToAutoCreateReplacementWO: null,

  showCsCreateRequestModal: false,

  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MAIN_ADD_ALERT: {
      // show max 3 alerts on screen at the same time
      if (state.alerts.length > 2) {
        state.alerts.shift();
      }

      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: (new Date()).getTime(),
            type: action.notificationType,
            headline: action.headline,
            timeout: action.timeout || null,
            message: action.message
          }
        ]
      };
    }

    case MAIN_HIDE_ALERT: {
      return {
        ...state,
        alerts: state.alerts.filter((notification, i) => notification !== action.notification)
      };
    }

    case MAIN_HIDE_ALL_ALERTS: {
      return {
        ...state,
        alerts: initialState.alerts
      };
    }

    case MAIN_TOGGLE_SHOW_SIDEBAR: {
      return {
        ...state,
        sidebarIsOpen: action.isShow
      };
    }

    case USER_SET_IS_LOGOUT: {
      return initialState;
    }

    case MAIN_NOTIFICATIONS_IS_LOADING: {
      const filter = action.loading ? (action.filter || initialState.notificationsModalFilter) : state.notificationsModalFilter;
      return {
        ...state,
        notificationsIsLoading: action.loading,
        notificationsIsMoreLoading: action.isLoadMore || initialState.notificationsIsMoreLoading,
        notificationsModalFilter: filter,
        notificationBarIsOpen: false
      };
    }

    case MAIN_NOTIFICATIONS_UNSEEN_RECEIVE: {
      const notificationBarIsOpen = !state.notificationsModalIsOpen && action.data.system_alerts_count > 0;
      return {
        ...state,
        notificationsIsLoading: false,
        unseenNotifications: {...initialState.unseenNotifications, ...action.data},
        notificationBarIsOpen
      };
    }

    case MAIN_NOTIFICATIONS_BY_FILTER_RECEIVE: {
      return {
        ...state,
        notificationsIsLoading: false,
        notificationsIsMoreLoading: false,
        notificationsModalFilter: action.filter,
        notifications: {...state.notifications, [action.filter]: action.notifications},
        notificationBarIsOpen: false
      };
    }

    case MAIN_NOTIFICATIONS_SHOW_BAR: {
      return {
        ...state,
        notificationBarIsOpen: action.isShow
      };
    }

    case MAIN_NOTIFICATIONS_SHOW_MODAL: {
      const {notifications} = state;
      const unseenNotifications = {...state.unseenNotifications};
      const notificationsModalFilter = action.filter || initialState.notificationsModalFilter;
      if (action.isShow) {
        if (['all', 'action_items'].includes(notificationsModalFilter)) {
          unseenNotifications.action_items_count = 0;
        }
        if (['all', 'system_alerts'].includes(notificationsModalFilter)) {
          unseenNotifications.system_alerts_count = 0;
          unseenNotifications.last_system_alert_text = null;
        }
      }
      return {
        ...state,
        unseenNotifications,
        notifications: action.isShow ? {...notifications} : {
          ...notifications,
          system_alerts: {total: 0, data: []},
          action_items: {total: 0, data: []},
          archived: {
            total: notifications.archived.total + notifications.system_alerts.total + notifications.action_items.total,
            data: [...notifications.archived.data, ...notifications.system_alerts.data, ...notifications.action_items.data]
          }
        },
        notificationsModalFilter,
        notificationBarIsOpen: false,
        notificationsModalIsOpen: action.isShow
      };
    }

    case MAIN_CS_STUDIOS_IS_LOADING: {
      return {
        ...state,
        csStudiosIsLoading: action.loading
      };
    }

    case MAIN_CS_GET_STUDIOS: {
      return {
        ...state,
        csStudiosIsLoading: false,
        showCsCreateRequestModal: false,
        csCreateRequestModalData: action.data || initialState.csCreateRequestModalData
      };
    }

    case MAIN_CS_SHOW_CREATE_REQUEST_MODAL: {
      return {
        ...state,
        showCsCreateRequestModal: action.show
      };
    }

    case MAIN_CS_AUTO_CREATE_REPLACEMENT_WO: {
      return {
        ...state,
        requestIdToAutoCreateReplacementWO: action.requestId || initialState.requestIdToAutoCreateReplacementWO
      };
    }

    case MAIN__IS_LOADING: {
      return {
        ...state,
        isLoading: action.loading || initialState.isLoading
      };
    }

    default:
      return state;
  }
}
