import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import 'bootstrap';
import Loader from '../../../../components/Loader';
import {
  StyledModalBackdrop, StyledModalDialog, StyledModal, StyledIssueModalHeader, StyledCloseModal, StyledIssueModalBody,
  StyledMobileModal
} from '../../../../styled/ModalStyled';
import {scrollToGeneralComment, closeGeneralComments, addGeneralComment, getMentions} from '../../actions';
import GeneralComment from '../sidebar/GeneralComment';
import CommentForm, {HeightOfCommentToolbar} from '../../../../components/comments/CommentForm';
import _ from 'lodash';
import isMobile from 'is-mobile';

class GeneralCommentsModal extends Component {
  state = {
    comment: null
  };

  timerId = null;

  clearTimeout = () => {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  };

  scrollToComment = () => {
    const {reportdetails: {generalComments: {currentCommentId}}, dispatch} = this.props;
    if (currentCommentId) {
      try {
        const comment = document.getElementById(`g-comment-${currentCommentId}`);
        if (comment) {
          comment.scrollIntoView();
          this.timerId = setTimeout(() => {
            dispatch(scrollToGeneralComment());
            this.timerId = null;
          }, 500);
        }
      } catch {}
    }
  };

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(closeGeneralComments());
  };

  componentDidMount() {
    document.body.classList.add('modal-open');
    this.scrollToComment();
  }

  componentWillUnmount() {
    document.body.classList.remove('modal-open');
    this.clearTimeout();
    this.handleClose();
  }

  calcFooterHeight = formHeight => {
    return isMobile() ? formHeight : formHeight + 25;
  };

  render() {
    const {reportdetails: {generalComments: {currentCommentId, comments}, loaders}, match, dispatch} = this.props;
    const commentsByUsers = _.groupBy(comments, 'UserName');
    const formHeight = (isMobile() ? 81 : 65) + HeightOfCommentToolbar;
    const footerHeight = this.calcFooterHeight(formHeight);
    return (
      <StyledMobileModal>
        <StyledModalBackdrop className="issue-modal-backdrop" onClick={this.handleClose}/>
        <StyledModalDialog className="mobile-modal-dialog-custom">
          {loaders.generalComments && <Loader/>}
          <StyledModal className="modal-styled-custom">
            <StyledIssueModalHeader className="modal-header-custom">
              <div className="back-btn" onClick={this.handleClose}/>
              <h4>Comments</h4>
              <StyledCloseModal className="icon-close-modal" onClick={this.handleClose}/>
            </StyledIssueModalHeader>
            <StyledIssueModalBody bottom={`${footerHeight}px`} top="85px">
              <StyledModalBodyContent>
                {
                  comments.length ? Object.keys(commentsByUsers).map((userName, i) => (
                    <GeneralComment
                      key={userName || 'No Name'}
                      comments={commentsByUsers[userName]}
                      even={((i + 1) % 2) === 0}
                      isModal
                      currentCommentId={currentCommentId && commentsByUsers[userName].find(c => c.CommentID === currentCommentId) ? currentCommentId : null}
                    />
                  )) : <div className="no-comments">There are no comments yet</div>
                }
              </StyledModalBodyContent>
            </StyledIssueModalBody>
            <StyledModalFooter className="comments-modal-footer">
              <CommentForm
                isLoading={loaders.generalComments}
                requestId={+match.params.requestId}
                onGetMentions={getMentions}
                onSave={newComment => {
                  return dispatch(addGeneralComment(match.params.requestId, newComment));
                }}
                height={formHeight}
                calcModalFooterHeight={this.calcFooterHeight}
                requestImmediateResponseForMentionType="EDMS"
              />
            </StyledModalFooter>
          </StyledModal>
        </StyledModalDialog>
      </StyledMobileModal>
    );
  }
}

const StyledModalBodyContent = styled.div`
  padding: 31px 45px 0 50px;

  @media (max-width: 767px) {
    padding: 22px 0 0 23px;
  }
`;

const StyledModalFooter = styled.div`
  background-color: #F8F8F8;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
  height: ${90 + HeightOfCommentToolbar}px;
  padding: 12px 14px 12px 13px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 10%;
  z-index: 1060;

  @media (max-width: 991px) {
    left: 2.66%;
  }

  @media (max-width: 767px) {
    padding: 0;
    height: ${81 + HeightOfCommentToolbar}px;
    background-color: #fff;
    border: none;
  }
`;

GeneralCommentsModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  reportdetails: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    reportdetails: state.reportdetails
  };
}

export default connect(
  mapStateToProps
)(withRouter(GeneralCommentsModal));
