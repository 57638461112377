import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SelectAdvanced from '../../../../components/forms/SelectAdvanced';
import InputAdvanced from '../../../../components/forms/InputAdvanced';
import {Row, Col, FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {
  CONTENT_TYPES, QC_REQUEST_TYPE_EPISODE
} from '../../../NewRequest/constants';
import Switch from 'react-switch';
import RuntimeInput from '../forms/RuntimeInput';
import {getSeasonEpisodes} from '../../actions';
import Loader from '../../../../components/Loader';
import {showRuntimeTextlessField} from '../../../../utils';

class Turnaround extends Component {
  state = {
    loading: false,
    episodes: null,
  };

  componentDidMount() {
    const {data} = this.props;
    if (this.isEpisodeContentType()) {
      this.setState({loading: true});
      getSeasonEpisodes(data.origin_request_id).then(
        res => {
          const episodes = [...res];
          episodes.forEach(item => {
            item.disabled = Boolean(item.EpisodeRequestID);
          });
          this.setState({loading: false, episodes});
        },
        error => {
          console.log(error);
          this.setState({loading: false});
        }
      );
    }
  }

  isFeatureContentType() {
    return this.props.data.content_type_id === CONTENT_TYPES.Feature;
  }

  isEpisodeContentType() {
    const {data} = this.props;
    return data.content_type_id === CONTENT_TYPES.Episode || data.request_type_id === QC_REQUEST_TYPE_EPISODE;
  }

  isTrailerContentType() {
    return this.props.data.content_type_id === CONTENT_TYPES.Trailer;
  }

  handleChangeTrailerEpisodeName = (name, value) => {
    const {onChange} = this.props;
    onChange('trailer_episode_name', value);
  };

  handleBlurTrailerEpisodeName = (name, value) => {
    const {onChange} = this.props;
    value = (value || '').trim() || null;
    onChange('trailer_episode_name', value);
  };

  handleFieldValueChanged = (name, value) => {
    const {data, onChange} = this.props;
    const {general} = data;
    if (value === '' && name === 'episode_name') {
      value = null;
    }
    general[name] = value;
    onChange('general', general);
  };

  handleTextareaBlur = event => {
    this.handleFieldValueChanged(event.target.name, event.target.value);
  };

  handleChangedRuntime = (name, value) => {
    if (this.isEpisodeContentType()) {
      const {general} = this.props.data;
      if (general.episode_number) {
        const {episodes} = this.state;
        const episode = episodes.find(item => item.EpisodeNum === general.episode_number);
        if (episode && episode.Runtime && typeof value !== 'number') {
          value = episode.Runtime;
        }
      }
    }
    this.handleFieldValueChanged(name, value);
  };

  handleSelectEpisode = (name, value) => {
    const {episodes} = this.state;
    const episode = episodes.find(item => item.EpisodeNum === value);
    this.handleFieldValueChanged(name, value);
    if (episode) {
      this.handleFieldValueChanged('runtime_in_sec', episode.Runtime);
      this.handleFieldValueChanged('episode_name', episode.EpisodeName);
    }
  };

  render() {
    const {data, filters} = this.props;
    const {general} = data;
    const {loading, episodes} = this.state;
    const isFeature = this.isFeatureContentType();
    const isTrailer = this.isTrailerContentType();
    const isEpisode = this.isEpisodeContentType();
    return (
      <StyledTurnaround className="step-body-wrapper turnaround-and-other-info">
        {loading ? <Loader/> : null}
        <Row>
          <Col md={12} xs={12}>
            <Row>
              <Col md={3} xs={6} className="select-control turnaround-time">
                <SelectAdvanced
                  label="Turnaround Time"
                  labelSeparator=""
                  name="sla_id"
                  value={general.sla_id}
                  options={filters.sla_list}
                  onSelect={this.handleFieldValueChanged}
                  placeholder=""
                  />
              </Col>
              {isEpisode ? (
                <Fragment>
                  <Col md={3} xs={6} className="select-control episode-number">
                    <SelectAdvanced
                      label="Episode #"
                      labelSeparator=""
                      name="episode_number"
                      options={episodes || []}
                      value={general.episode_number}
                      placeholder="Select or type"
                      onSelect={this.handleSelectEpisode}
                      creatable
                      isLoading={loading}
                      valueKey="EpisodeNum"
                      labelKey="EpisodeNum"
                      promptTextCreator={label => `Create New "${label}"`}
                      />
                  </Col>
                  <Col md={3} xs={6} className="input-control episode-name">
                    <InputAdvanced
                      label="Episode Name"
                      labelSeparator=""
                      name="episode_name"
                      value={general.episode_name}
                      maxLength={255}
                      onChange={this.handleFieldValueChanged}
                      onBlur={this.handleFieldValueChanged}
                      />
                  </Col>
                </Fragment>
              ) : isTrailer ?
                  <Col md={3} xs={6} className="input-control episode-name" title="Trailer episode name">
                    <InputAdvanced
                      label="Episode Name"
                      labelSeparator=""
                      name="trailer_episode_name"
                      value={data.trailer_episode_name}
                      maxLength={200}
                      onChange={this.handleChangeTrailerEpisodeName}
                      onBlur={this.handleBlurTrailerEpisodeName}
                      forceReset
                    />
                  </Col> : null
              }
              {
                (isFeature || isTrailer || isEpisode) ? (
                  <Col md={3} xs={6} className="input-control runtime-in-sec">
                    <RuntimeInput
                      name="runtime_in_sec"
                      value={general.runtime_in_sec}
                      onChange={this.handleChangedRuntime}
                      forceReset={isEpisode}
                      />
                  </Col>
                ) : null
              }
              {
                (isFeature || isTrailer) && showRuntimeTextlessField(data.video_format) ? (
                  <Col md={3} xs={6} className="input-control runtime-in-sec">
                    <RuntimeInput
                      label="Runtime Textless"
                      name="runtime_in_sec__textless"
                      value={general.runtime_in_sec__textless}
                      onChange={this.handleChangedRuntime}
                      />
                  </Col>
                ) : null
              }
            </Row>
            <Row>
              <Col md={10} xs={12}>
                <div className="form-group interactive-reporting">
                  <ControlLabel>Interactive Reporting</ControlLabel>
                  <div className="field">
                    <div className="switch-field switch-interactive-reporting">
                      <Switch
                        onChange={value => this.handleFieldValueChanged('allow_interactive_report', value)}
                        checked={general.allow_interactive_report}
                        className={`react-switch${general.allow_interactive_report ? ' checked' : ''}`}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        offColor="#F6F6F6"
                        onColor="#FFFFFF"
                        offHandleColor="#A9A9A9"
                        onHandleColor="#7FCF18"
                        activeBoxShadow="0px 0px 1px 1px transparent"
                        handleDiameter={20}
                        height={26}
                        width={48}
                        />
                      <span>Enable</span>
                    </div>
                    <div>
                      NOTE: Allowing Interactive Reporting will result in a watermarked proxy of your content being uploaded to AWS.
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="notes-textareas">
              <Col md={10} xs={12}>
                <FormGroup>
                  <ControlLabel>Special Instructions</ControlLabel>
                  <FormControl
                    name="note"
                    type="text"
                    componentClass="textarea"
                    placeholder=""
                    defaultValue={general.note}
                    onChange={this.handleTextareaBlur}
                    onBlur={this.handleTextareaBlur}
                    />
                </FormGroup>
              </Col>
              <Col md={10} xs={12}>
                <FormGroup>
                  <ControlLabel>File Delivery Notes</ControlLabel>
                  <FormControl
                    name="file_delivery_note"
                    type="text"
                    componentClass="textarea"
                    placeholder=""
                    defaultValue={general.file_delivery_note}
                    onChange={this.handleTextareaBlur}
                    onBlur={this.handleTextareaBlur}
                  />
                </FormGroup>
              </Col>
              <Col md={10} xs={12}>
                <FormGroup>
                  <ControlLabel>File Creation Notes</ControlLabel>
                  <FormControl
                    name="file_creation_note"
                    type="text"
                    componentClass="textarea"
                    placeholder=""
                    defaultValue={general.file_creation_note}
                    onChange={this.handleTextareaBlur}
                    onBlur={this.handleTextareaBlur}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledTurnaround>
    );
  }
}

const StyledTurnaround = styled.div`
  .new-request .new-request-wrapper & {
    &.turnaround-and-other-info {
      margin-top: 39px;
    
      * {
        color: #000000;
      }
    
      .form-group {
        margin-bottom: 0;
    
        label {
          font-weight: 700;
          font-size: 13px;
          line-height: 14px;
          margin-bottom: 12px;
        }
    
        .form-control {
          height: 39px;
          font-size: 13px;
          line-height: 14px;
        }
    
        textarea.form-control {
          min-height: 80px;
          max-width: 100%;
          min-width: 100%;
        }
    
        input.form-control {
          padding-top: 2px;
          font-weight: 500;
        }
    
        &.interactive-reporting {
          background-color: #f9f9f9;
          border-radius: 4px;
          padding: 22px 26px 15px;
          margin: 24px 0 0;
    
          label {
            font-size: 15px;
            line-height: 16px;
            margin-bottom: 20px;
          }
    
          .switch-field {
    
            .react-switch + span {
              line-height: 26px;
            }
    
            & + div {
              padding-top: 6px;
              padding-left: 57px;
              font-weight: 500;
              font-size: 12px;
              line-height: 17px;
              opacity: 0.5;
            }
          }
        }
      }

      .notes-textareas .form-group {
        margin-top: 20px;
      }

      .loader-container {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        z-index: 1;
        .react-spinner {
          top: 49% !important;
        }
      }

      .Select-menu-outer .Select-option {
        &.is-disabled {
          cursor: not-allowed;
          opacity: 0.4;
        }
      }

    }

    &.turnaround-and-other-info {
      @media (min-width: 992px) {
        .select-control.col-md-3 {
          &:not(.turnaround-time) {
            width: auto;
          }
          .form-group {
            width: 153px;
          }
        }
        .input-control.col-md-3 {
          width: auto;
          &.episode-name {
            .form-group {
              width: 250px;
            }
          }
          .form-group {
            width: 107px;
          }
        }
      }
    }
  }
`;

Turnaround.propTypes = {
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Turnaround;
