/* eslint "jsx-a11y/anchor-is-valid":"off" */
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled, {createGlobalStyle} from 'styled-components';
import {withRouter} from 'react-router-dom';
import 'bootstrap';
import Loader from '../../../../components/Loader';
import IssueTable from '../IssueTable';
import {StyledModalBackdrop, StyledModalDialog, StyledModal, StyledBaseCloseModal, StyledMobileModal
} from '../../../../styled/ModalStyled';
import {
  showEmailReportModal, showFixListModal, sendEmailReport, sendFixListEmail, hideWarningInEmailReportModal
} from '../../actions';
import {Row, Col, Button, Checkbox, FormGroup, ControlLabel} from 'react-bootstrap';
import SelectAdvanced from '../../../../components/forms/SelectAdvanced';
import InputAdvanced from '../../../../components/forms/InputAdvanced';
import Switch from 'react-switch';
import {isManagerUser, isUserCanManage, isUserCanComment, isClientServiceUser} from '../../../../utils';
import Select, {components} from 'react-select-v2';
import expandImg from '../../../../../assets/images/expand.svg';
import {confirmAlert} from 'react-confirm-alert';

class EmailReportModal extends Component {
  state = {
    email_recipients: this.props.reportdetails.details.DistributionList,
    sender_note: null,
    include_fixlist: false,
    min_severity: 2,
    include_fixed_issues: false,
    include_comments: false,
    include_excel_fixlist: false,
    include_excel_pdf_fixlist: false,
    do_not_include_link: false,
    enable_override_from: false,
    override_from_email: null,
    override_from_name: null,
    email_bcc_address: null,
    additionalAttachments: null
  };

  UNSAFE_componentWillMount() {
    const {body} = document;
    body.className += ' modal-open';
  }

  componentDidUpdate(prevProps) {
    const elements = document.getElementsByClassName('issue-modal-backdrop');
    if (elements && elements.length) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.opacity = '0';
      }
    }
    const {dispatch, reportdetails: {warnInEmailReportModal}} = this.props;
    if (warnInEmailReportModal && !prevProps.reportdetails.warnInEmailReportModal) {
      confirmAlert({
        childrenElement: () =>
          <div className="custom-msg">
            {warnInEmailReportModal.split(';').map((warn, i) =>
              <div key={`warn-${i}`} className="custom-msg-item">{warn}</div>
            )}
          </div>,
        buttons: [{
          label: 'Cancel',
          onClick: () => dispatch(hideWarningInEmailReportModal())
        }, {
          label: 'Send anyway',
          onClick: this.handleEmailReport
        }]
      });
    }
  }

  componentWillUnmount() {
    const {body} = document;
    body.className = body.className.replace('modal-open', '').trim();
  }

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showEmailReportModal(false));
  };

  handleEmailReport = () => {
    const {dispatch, match, reportdetails} = this.props;
    if (reportdetails.showFixListModal) {
      dispatch(sendFixListEmail(match.params.requestId, reportdetails.fixlistMinSeverity, this.state));
    } else {
      const params = {...this.state};
      if (params.additionalAttachments && params.additionalAttachments.length) {
        params.additional_attachments = params.additionalAttachments.map(a => a.id);
      }
      delete params.additionalAttachments;
      if (reportdetails.warnInEmailReportModal) {
        params.ignore_qod_users_validation = true;
      }
      dispatch(sendEmailReport(match.params.requestId, params));
    }
  };

  handleClickView = issue => {
    const {dispatch, onClickViewIssue} = this.props;
    dispatch(showEmailReportModal(false));
    dispatch(showFixListModal(false));
    onClickViewIssue(issue);
  };

  handleFieldValueChanged = (name, value) => {
    if (value === '') {
      value = null;
    }
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  handleTextareaBlur = event => {
    this.handleFieldValueChanged(event.target.name, event.target.value);
  };

  handleIncludeFixlist = (value) => {
    this.setState({
      ...this.state,
      include_fixlist: value
    });
  };

  handleIncludeFixedIssues = include_fixed_issues => this.setState({include_fixed_issues});

  handleIncludeIssuesComments = include_comments => this.setState({include_comments});

  handleSelectMinSeverity = (_, value) => {
    this.setState({
      ...this.state,
      min_severity: value
    });
  };

  handleIncludeExcelFixlist = (value) => {
    const {include_excel_pdf_fixlist} = this.state;
    this.setState({
      ...this.state,
      include_excel_fixlist: value,
      include_excel_pdf_fixlist: include_excel_pdf_fixlist ? false : null
    });
  };

  handleIncludeExcelAndPdfFixlist = (value) => {
    const {include_excel_fixlist} = this.state;
    this.setState({
      ...this.state,
      include_excel_pdf_fixlist: value,
      include_excel_fixlist: include_excel_fixlist ? false : null
    });
  };

  handleEnableOverrideFrom = (value) => {
    const {override_from_email, override_from_name} = this.state;
    this.setState({
      ...this.state,
      enable_override_from: value,
      override_from_email: value ? override_from_email : null,
      override_from_name: value ? override_from_name : null
    });
  };

  handleChangeOverrideFromText = (name, value) => {
    this.handleFieldValueChanged(name, value);
  };

  handleBlurOverrideFromText = (name, value) => {
    this.handleFieldValueChanged(name, value);
  };

  renderSwitchField = (label, checked, onChange) => {
    return (
      <div className="form-group">
        <div className="field">
          <div className="switch-field switch-not-sure">
            <Switch
              onChange={onChange}
              checked={checked}
              className={`react-switch${checked ? ' checked' : ''}`}
              uncheckedIcon={false}
              checkedIcon={false}
              offColor="#F6F6F6"
              onColor="#FFFFFF"
              offHandleColor="#A9A9A9"
              onHandleColor="#7FCF18"
              activeBoxShadow="0px 0px 1px 1px transparent"
              handleDiameter={22}
              height={28}
              width={48}
            />
            <span>{label}</span>
          </div>
        </div>
      </div>
    );
  };

  renderAdditionalFieldsForClientService = () => {
    const {reportdetails} = this.props;
    const {filters, details: {attachments, IsHardingTestRequest}} = reportdetails;
    const {
      include_fixlist, min_severity, include_excel_fixlist, include_excel_pdf_fixlist, enable_override_from,
      override_from_email, override_from_name, email_bcc_address, include_fixed_issues, additionalAttachments,
      do_not_include_link
    } = this.state;

    if (!isManagerUser() || reportdetails.showFixListModal) {
      return null;
    }

    return (
      <>
        {!IsHardingTestRequest &&
          <>
            <Row>
              <Col md={3} xs={12}>
                {this.renderSwitchField('Include Fixed Issues', include_fixed_issues, this.handleIncludeFixedIssues)}
              </Col>
            </Row>
            <Row>
              <Col md={3} xs={12}>
                {this.renderSwitchField('Include Fixlist', include_fixlist, this.handleIncludeFixlist)}
              </Col>
            </Row>
          </>
        }
      {
        include_fixlist ? (
          <>
          <Row>
            <Col md={3} xs={6} className="min-severity-field">
              <SelectAdvanced
                label="Min Severity"
                labelSeparator=""
                options={filters.issue_severities || []}
                value={min_severity}
                onSelect={this.handleSelectMinSeverity}
                placeholder=""
                />
            </Col>
          </Row>
          <Row>
            <Col md={7} xs={12}>
              {this.renderSwitchField('Include Fixlist as Excel instead PDF', include_excel_fixlist, this.handleIncludeExcelFixlist)}
            </Col>
          </Row>
          <Row>
            <Col md={7} xs={12}>
              {this.renderSwitchField('Include Fixlist as Excel & PDF', include_excel_pdf_fixlist, this.handleIncludeExcelAndPdfFixlist)}
            </Col>
          </Row>
          </>
        ) : null
      }
      {isClientServiceUser() &&
        <Row>
          <Col md={2} xs={12}>
            {this.renderSwitchField('Do not include link', do_not_include_link, (v) => this.setState({do_not_include_link: v}))}
          </Col>
        </Row>
      }
      <Row>
        <Col md={2} xs={12}>
          {this.renderSwitchField('Override From: ', enable_override_from, this.handleEnableOverrideFrom)}
        </Col>
      </Row>
      {
        enable_override_from ? (
          <Row>
            <Col md={3} xs={6} className="min-severity-field">
              <InputAdvanced
                labelSeparator=""
                label="From Email: "
                name="override_from_email"
                value={override_from_email}
                type="text"
                maxLength={30}
                onChange={this.handleChangeOverrideFromText}
                onBlur={this.handleBlurOverrideFromText}
              />
            </Col>
            <Col md={3} xs={6} className="min-severity-field">
              <InputAdvanced
                labelSeparator=""
                label="From Name: "
                name="override_from_name"
                value={override_from_name}
                type="text"
                maxLength={30}
                onChange={this.handleChangeOverrideFromText}
                onBlur={this.handleBlurOverrideFromText}
              />
            </Col>
          </Row>
        ) : null
      }
      <Row>
        <Col md={7} xs={12} className="min-severity-field">
          <InputAdvanced
            labelSeparator=""
            label="BCC: "
            name="email_bcc_address"
            value={email_bcc_address}
            type="text"
            onChange={this.handleChangeOverrideFromText}
            onBlur={this.handleBlurOverrideFromText}
            placeholder='comma separated list of addresses'
          />
        </Col>
      </Row>
      {!IsHardingTestRequest && (attachments || []).length > 0 &&
        <>
          <Row>
            <Col md={12} xs={12}>
              <Checkbox
                className={`additional-attachments-checkbox${!!additionalAttachments ? '' : ' margin-bottom-20'}`}
                checked={!!additionalAttachments}
                onChange={({target: {checked}}) => this.setState({additionalAttachments: checked ? [] : null})}
              >
                Additional Attachments
              </Checkbox>
            </Col>
          </Row>
          {!!additionalAttachments &&
            <Row>
              <Col md={7} xs={12}>
                <FormGroup className="additional-attachments-form-group">
                  <ControlLabel>
                    Attachments
                  </ControlLabel>
                  <div className="field">
                    <Select
                      className="select-in-cell additional-attachments-list"
                      classNamePrefix="additional-attachments-select"
                      isMulti
                      getOptionValue={o => o.id}
                      getOptionLabel={o => <>{o.filename}{attachments.length === 1 ? '' : <small>({o.label ? `${o.label} / ` : ''}{o.category}{o.pse_test ? ` / ${o.pse_test}` : ''})</small>}</>}
                      options={attachments}
                      value={additionalAttachments}
                      onChange={selected => this.setState({additionalAttachments: selected})}
                      isSearchable={false}
                      closeMenuOnSelect={false}
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: diProps => (
                          <components.DropdownIndicator {...diProps}>
                            <img src={expandImg} alt=""/>
                          </components.DropdownIndicator>
                        )
                      }}
                      maxMenuHeight={200}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          }
        </>
      }
      </>
    )
  };

  render() {
    const {reportdetails, onClickFixNotes, onClickDiscuss, onClickIssueImage, isEnabledInteractiveView} = this.props;
    const {completing, loadingFixlistIssues, loadingIssueComments, fixlistIssues, loaders, details: {UserPermissionType}
    } = reportdetails;
    const {email_recipients, sender_note, include_comments} = this.state;
    return (
      <StyledMobileModal className="mobile-modal-with-issue-table">
        <StyledModalBackdrop
          opacity={reportdetails.showFixListModal ? 0 : undefined}
          onClick={() => this.handleClose()}
          />
        <StyledModalDialog className="mobile-modal-dialog-custom">
          {completing ? <Loader/> : null}
          <StyledModal>
            <StyledModalHeader className="modal-header-custom">
              <div className="back-btn" onClick={this.handleClose}/>
              <div className="modal-header-content">
                <h4>Email Report</h4>
                <div>
                  <StyledBaseCloseModal className="icon-close-modal" onClick={() => this.handleClose()}/>
                </div>
              </div>
              <div className="modal-fields">
                <Row>
                  <Col md={7} xs={12}>
                    <div className="form-group">
                      <textarea
                          className="form-control"
                          name="email_recipients"
                          rows="3"
                          placeholder="Email Recipients"
                          value={email_recipients || ''}
                          onBlur={this.handleTextareaBlur}
                          onChange={this.handleTextareaBlur}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={7} xs={12}>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        name="sender_note"
                        rows="3"
                        placeholder="Message Note"
                        defaultValue={sender_note || ''}
                        onBlur={this.handleTextareaBlur}
                        onChange={this.handleTextareaBlur}
                        />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={3} xs={12}>
                    {this.renderSwitchField('Include Comments for Issues', include_comments, this.handleIncludeIssuesComments)}
                  </Col>
                </Row>
                {this.renderAdditionalFieldsForClientService()}
                <Row>
                  <Col md={12} xs={12} className="modal-btn-toolbar">
                    <Button
                      bsStyle="primary"
                      onClick={this.handleEmailReport}
                      disabled={!email_recipients}
                      title={(!email_recipients) ? 'Fill fields above' : undefined}
                      >
                      Send
                    </Button>
                    <Button
                      bsStyle="default"
                      onClick={this.handleClose}
                      >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </div>
            </StyledModalHeader>
            {
              reportdetails.showFixListModal ? (
                <Fragment>
                  <StyledModalBody className="modal-body-custom">
                    <IssueTable
                      loading={loadingFixlistIssues || loadingIssueComments || loaders.issueImageModal}
                      data={fixlistIssues}
                      issueBorderWidth="6px"
                      canManage={isUserCanManage(UserPermissionType)}
                      canComment={isUserCanComment(UserPermissionType)}
                      onClickFixNotes={onClickFixNotes}
                      onClickDiscuss={onClickDiscuss}
                      onClickView={this.handleClickView}
                      onClickImage={onClickIssueImage}
                      isEnabledInteractiveView={isEnabledInteractiveView}
                      sort={{field: 'Severity', direction: 'desc'}}
                      emptyText={loadingFixlistIssues ? null : 'No Issues'}
                    />
                  </StyledModalBody>
                  <StyledModalFooter/>
                </Fragment>
              ) : null
            }
          </StyledModal>
        </StyledModalDialog>
        <GlobalStyled/>
      </StyledMobileModal>
    );
  }
}

const GlobalStyled = createGlobalStyle`
  .react-confirm-alert-body {
    @media (min-width: 768px) {
      padding-right: 34px !important;
      .react-confirm-alert-button-group {
        margin-right: 0 !important;
      }
    }
    .custom-msg-item {
      font-size: 13px;
      line-height: 16px;
      + .custom-msg-item {
        margin-top: 8px;
      }
    }
  }
`;

const StyledModalHeader = styled.div`
  padding: 21px 25px 0 30px;
  position: fixed;
  top: 0;
  right: 0;
  left: 10%;
  z-index: 1060;
  .modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  h4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #000;
    margin: 10px 0 0;
  }
  h4 + div {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8.5px 0 8.5px 23px;

    @media (max-width: 767px) {
      display: none;
    }
  }
  .modal-fields {
    padding: 19px 0;
    max-height: calc(100vh - 21px - 40px);
    overflow-y: auto;
    overflow-x: hidden;
    @media (min-width: 992px) {
      .col-md-7 {
        width: 61.69354839%;
      }
    }

    @media (max-width: 767px) {
      padding: 3px 25px 33px 23px;
      max-height: calc(100vh - 19px - 49px);
    }

    .row {
      &:first-child .form-group {
        margin-bottom: 12px;

        @media (max-width: 767px) {
          margin-bottom: 16px;
        }
      }
      &:nth-child(2) .form-group {
        margin-bottom: 16px;

        @media (max-width: 767px) {
          margin-bottom: 28px;
        }
      }
    }

    .form-control {
      background: #F8F8F8;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      color: #000;
      padding: 16px 11px;

      &::placeholder {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 13px;
        color: #000;
        opacity: 0.3;
      }

      &:focus {
        border-color: #ccc;
      }
   }
    textarea.form-control {
      height: 93px;
      width: 100%;
      overflow: auto;
      resize: none;
    }
    input.form-control {
      height: 47px;
      padding-top: 18px;
      padding-bottom: 14px;
    }
    .checkbox.additional-attachments-checkbox {
      &.margin-bottom-20 {
        margin-bottom: 20px;
      }
      label {
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
      }
      input[type="checkbox"] {
        margin-top: 1px;
      }
    }
    .form-group.additional-attachments-form-group {
      margin-bottom: 25px;
      padding-left: 2px;
      .additional-attachments-list {
        font-size: 12px;
        line-height: 13px;
        color: #000;
        .additional-attachments-select__control {
          background-color: #F8F8F8;
          border-color: #EBEBEB;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
          border-radius: 3px;
          .additional-attachments-select__placeholder {
            font-size: 12px;
            line-height: 13px;
            color: #000;
            opacity: 0.3;
          }
          .additional-attachments-select__indicator {
            opacity: 0.2;
            &.additional-attachments-select__clear-indicator {
              :hover {
                opacity: 0.4;
              }
            }
          }
          &.additional-attachments-select__control--is-focused {
            border-color: #ccc;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
            .additional-attachments-select__placeholder {
              opacity: 0.5;
            }
            .additional-attachments-select__indicator {
              opacity: 0.4;
            }
          }
          &.additional-attachments-select__control--menu-is-open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .additional-attachments-select__menu {
          margin-top: 0;
          border-radius: 3px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
        .additional-attachments-select__multi-value__label {
          white-space: normal;
        }
        small {
          margin-left: 5px;
          opacity: 0.75;
        }
      }
    }
  }
  button:not(.close) {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #fff;
    background: #414141 !important;
    opacity: 0.8;
    border-radius: 100px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 11px 40px 12px;
    &.btn-primary {
      @media (min-width: 992px) {
        min-width: 128px;
      }
      &:disabled, &.disabled {
        background: #e0e0e1 !important;
        color: #c8c8c8;
        cursor: default;
      }
      &:not([disabled]):hover {
        opacity: 1;
      }
    }
    &.btn-default {
      background-color: transparent !important;
      color: #000;
      padding-left: 0;
      padding-right: 0;
      margin-left: 17px;
    }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }

  .modal-btn-toolbar {
    @media (max-width: 767px) {
      display: flex;
      justify-content: flex-start;
      button:first-child {
        order: 2;
      }
      button:last-child {
        order: 1;
        margin: 0 34px 0 0;
      }
    }
  }
`;

const StyledModalBody = styled.div`
  background: rgba(196, 196, 196, 0.2);
  padding: 0 36px 0 24px;
  position: fixed;
  top: 406px;
  bottom: 0;
  right: 0;
  left: 10%;
  z-index: 1060;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 768px) {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      -webkit-box-shadow: none;
      background-color: #c4c4c4;
    }
  }

  .table {
    > thead {
      > tr {
        > th {
          padding-top: 15px;
          padding-bottom: 4px;

          &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(7) {
            min-width: 108px;
          }
          &:nth-child(8) {
            min-width: 77px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }

  @media (max-width: 767px) {
    top: 422px !important;
    padding: 24px 0 0;
  }
`;

const StyledModalFooter = styled.div`
  @media (max-width: 767px) {
    background: linear-gradient(0deg, #FFFFFF 22.44%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.95;
    height: 57px;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 2.66%;
    z-index: 1060;
  }
`;

EmailReportModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  reportdetails: PropTypes.object.isRequired,
  isEnabledInteractiveView: PropTypes.bool.isRequired,
  onClickFixNotes: PropTypes.func.isRequired,
  onClickDiscuss: PropTypes.func.isRequired,
  onClickViewIssue: PropTypes.func.isRequired,
  onClickIssueImage: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    reportdetails: state.reportdetails
  };
}

export default connect(
  mapStateToProps
)(withRouter(EmailReportModal));
