import $ from 'jquery';
import config from '../../../config';
import {getErrorMessage} from '../../utils';
import {showSuccess, showError} from '../../layouts/actions';

export const WO_CONFLICTS_CLEAR = 'WO_CONFLICTS_CLEAR';
export const WO_CONFLICTS_IS_LOADING = 'WO_CONFLICTS_IS_LOADING';
export const WO_CONFLICTS_RECEIVE = 'WO_CONFLICTS_RECEIVE';
export const WO_CONFLICTS_ACCEPT_VERSION = 'WO_CONFLICTS_ACCEPT_VERSION';

export const clearWOConflicts = () => ({type: WO_CONFLICTS_CLEAR});

const toggleLoading = loading => ({type: WO_CONFLICTS_IS_LOADING, loading});

export const getConflictsData = (conflictArea, id) => dispatch => {
  dispatch(toggleLoading(true));
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/conflicts/${conflictArea}/${id}`
  })
  .done(res => {
    dispatch({type: WO_CONFLICTS_RECEIVE, data: res});
  })
  .fail(error => {
    dispatch(toggleLoading(false));
    dispatch(showError(`Could not get data.  ${getErrorMessage(error)}`));
  });
};

export const acceptVersion = (history, conflictArea, id, version) => dispatch => {
  dispatch(toggleLoading(true));
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/conflicts/resolve/${conflictArea}/${id}`,
    data: {
      accepted_version: version
    }
  })
  .done(() => {
    dispatch({type: WO_CONFLICTS_ACCEPT_VERSION, version});
    dispatch(showSuccess('Saved successfully!'));
//    history.push(getDefaultRedirectUrl());
  })
  .fail(error => {
    dispatch(toggleLoading(false));
    dispatch(showError(`Could not save.  ${getErrorMessage(error)}`));
  });
};

export const updateMemfisData = (history, conflictArea, id) => dispatch => {
  dispatch(toggleLoading(true));
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/conflicts/latest/${conflictArea}/${id}/pull`
  })
  .done(() => {
    //dispatch(toggleLoading(false));
    dispatch(showSuccess('Updated successfully!'));
    window.location.reload();
  })
  .fail(error => {
    dispatch(toggleLoading(false));
    dispatch(showError(`Could not update MEMFIS Data.  ${getErrorMessage(error)}`));
  });
};
