import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Cell} from '../../components/table/cells/Cell';

export const Row = ({
  headers, row, rowKey, onRowClick, getRowClassNames, rowCollapsedEl, isSeparatedRows, isShowCollapsedRow
}) => {
  const cells = Object.keys(headers)
  .map(k => {
    const CustomComponent = headers[k].component;
    const customArgs = headers[k].componentArgs || {};
    const className = headers[k].className || '';
    return CustomComponent ? <CustomComponent {...customArgs} value={row[k]} row={row} key={k} rowKey={rowKey} className={className}/> :
    <Cell value={row[k]} key={k} className={className} title={headers[k].title}/>;
  });

  const trClassName = isShowCollapsedRow ? `open ${getRowClassNames(row) || ''}` : getRowClassNames(row);
  const CollapsedRow = (rowCollapsedEl || {}).component;
  const collapsedRowArgs = (rowCollapsedEl || {}).componentArgs || {};
  const isComponentHiddenXS = (rowCollapsedEl || {}).isHiddenXS;

  return (
    <Fragment>
      <tr className={trClassName} onClick={onRowClick ? row => onRowClick(row) : undefined}>{cells}</tr>
      {isShowCollapsedRow && CollapsedRow ? (
        <tr className={`tr-collapsed ${isComponentHiddenXS ? 'hidden-xs' : ''}`}>
          <td colSpan={cells.length}><CollapsedRow {...collapsedRowArgs} row={row}/></td>
        </tr>
      ) : null}
      {isSeparatedRows ? <tr className="tr-separate"><th colSpan={cells.length}/></tr> : null}
    </Fragment>
  );
};

Row.propTypes = {
  headers: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  rowKey: PropTypes.any,
  onRowClick: PropTypes.func,
  getRowClassNames: PropTypes.func,
  rowCollapsedEl: PropTypes.object,
  isSeparatedRows: PropTypes.bool,
  isShowCollapsedRow: PropTypes.bool
};

Row.defaultProps = {
  getRowClassNames: () => {},
  rowCollapsedEl: {},
  isSeparatedRows: false
};

export default Row;
