import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FormGroup, FormControl, ControlLabel, OverlayTrigger, Tooltip} from 'react-bootstrap';
import confirmImg from '../../../../../assets/images/settings/confirm.svg';
import cancelImg from '../../../../../assets/images/settings/cancel.svg';
import deleteImg from '../../../../../assets/images/settings/delete.svg';
import isMobile from 'is-mobile';
import SelectAdvanced from '../../../../components/forms/SelectAdvanced';
import RuntimeInput from '../../../NewRequest/components/forms/RuntimeInput';
import {getOptionName, formatDurationAsMinutes, formatDateTz} from '../../../../utils';
import InputNumber from '../../../../components/forms/InputNumber';
import DatePicker from 'react-datepicker';

const DateField = props => {
  const {
    value,
    isClearable,
    onChange,
    dateProps
  } = props;
  const CustomInput = React.forwardRef((inputProps, inputRef) => (
    <input
      {...inputProps}
      readOnly
      ref={inputRef}
    />
  ));
  return (
    <DatePicker
      className={`form-control${isClearable ? ' is-clearable' : ''}`}
      showTimeInput
      timeFormat="HH:mm"
      dateFormat="MMM d, yyyy HH:mm aa z"
      placeholderText=""
      selected={value ? new Date(value) : null}
      shouldCloseOnSelect={false}
      customInput={<CustomInput/>}
      {...dateProps}
      onChange={date => {onChange(date ? date.getTime() : 0);}}
    />
  );
};

export const TextField = ({label, value, hiddenHint, hiddenNone, linkProps, tooltip}) => (
  <StyledField>
    {hiddenHint ? null :
      <ControlLabel>
        {label}
        {tooltip ?
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip
                id="text-field-tooltip"
              >
                {tooltip}
              </Tooltip>
            }
          >
            <i className="fas fa-info-circle" style={{marginLeft: '7px'}}/>
          </OverlayTrigger> : null
        }
      </ControlLabel>
    }
    <div
      title={tooltip}
    >
      <div className={`form-control on-edit-mode${!value ? ' no-value' : ''}${linkProps ? ' with-link' : ''} non-editable`}>
        {linkProps && linkProps.href ?
          <a {...linkProps} onClick={event => {event.stopPropagation();}}>
            {value}
          </a> : (value || (hiddenNone ? '' : 'None'))
        }
      </div>
    </div>
  </StyledField>
);

function TextEditableField(props) {
  const {
    label, name, value, onConfirm, isTextArea, isRuntime, isSelectControl, options, maxLength, placeholder, disabled,
    valueSuffix, defaultValue, isNumber, min, max, step, precision, allowEmpty, isCost, isDecimal, title, hiddenHint,
    allowReset, resetValue, selectProps, valueForNonEditModeInSelect, onOpenEditMode, onCloseEditMode, isStartEdit,
    confirmBtnTitle, hiddenNone, linkProps, isDate, dateProps
  } = props;

  const [editMode, setEditMode] = useState(isStartEdit || false);
  const [currentValue, setCurrentValue] = useState(value || defaultValue || '');

  const openEditMode = () => {
    !!onOpenEditMode && onOpenEditMode();
    setEditMode(true);
  };

  const closeEditMode = () => {
    !!onCloseEditMode && onCloseEditMode();
    setEditMode(false);
  };

  const handleConfirm = () => {
    if (onConfirm && currentValue !== (value || '')) {
      onConfirm(label, name, currentValue, closeEditMode);
    } else {
      closeEditMode();
    }
  };

  const handleReset = () => {
    if (onConfirm) {
      const newValue = resetValue !== undefined ? resetValue : '';
      setCurrentValue(newValue);
      onConfirm(label, name, newValue, closeEditMode);
    } else {
      closeEditMode();
    }
  };

  const handleCancel = () => {
    closeEditMode();
    setCurrentValue(value || defaultValue || '');
  };

  const noValue = () => {
    if (isCost || isDecimal) {
      const cost = parseFloat(value, 10);
      const isCorrectNumber = typeof cost === 'number' && !isNaN(cost);
      return !isCorrectNumber;
    }
    return !value;
  };

  const formatCostValue = () => {
    const cost = parseFloat(value, 10);
    const isCorrectNumber = typeof cost === 'number' && !isNaN(cost);
    const temp = isCorrectNumber ? String(cost.toFixed(2)).split('.') : null;
    if (temp) {
      return <span><strong>{isDecimal ? '' : '$'}{temp[0]}</strong>.{temp[1]}</span>;
    }
    return value;
  };

  const hint = `${isMobile() ? 'Tap' : 'Click'} to ${isSelectControl ? (value ? 'Change' : 'Select') : value ? 'Edit' : 'Enter'}`;

  const buildValue = () => {
    if (linkProps && linkProps.href) {
      return <a {...linkProps} onClick={event => {event.stopPropagation();}}>{value}</a>;
    }
    return (
        isDate && (value || valueForNonEditModeInSelect) ? (formatDateTz(value, 'x', 'll HH:mm A z') || valueForNonEditModeInSelect) :
        isSelectControl && (valueForNonEditModeInSelect || value) ? (valueForNonEditModeInSelect || getOptionName(options, value)) :
          isRuntime && value ? formatDurationAsMinutes(value) :
            value && valueSuffix ? `${value}${valueSuffix}` : (isCost || isDecimal) ? formatCostValue() : value
      ) || (hiddenNone ? '' : 'None');
  };

  return (
    <StyledField title={title} className={editMode ? 'text-editable-field--in-edit-mode' : undefined}>
      {hiddenHint ? null : <ControlLabel title={disabled ? undefined : hint}>{label}</ControlLabel>}
      {
        editMode ?
          <div className={`editable-control${isTextArea ? ' editable-control-textarea' : ''}`}>
            {
              isDate ?
                <DateField
                  {...dateProps}
                  value={currentValue}
                  onChange={setCurrentValue}
                /> :
              isNumber ?
                <InputNumber
                  type="number"
                  defaultValue={currentValue || min || (allowEmpty ? '' : 0)}
                  min={min}
                  max={max}
                  step={step}
                  precision={precision}
                  allowEmpty={allowEmpty}
                  onChange={e => {setCurrentValue(e.target.value)}}
                /> :
              isSelectControl ?
                <SelectAdvanced
                  value={currentValue}
                  options={options || []}
                  onSelect={(_, v) => setCurrentValue(v || '')}
                  placeholder={placeholder || ''}
                  {...selectProps}
                /> :
                  isRuntime ?
                    <RuntimeInput
                      withoutLabel
                      name="runtime_in_sec"
                      value={currentValue}
                      onChange={(_, v) => setCurrentValue(v || 0)}
                    /> :
                      <FormControl
                        type="text"
                        componentClass={isTextArea ? 'textarea' : 'input'}
                        defaultValue={currentValue}
                        onChange={e => {setCurrentValue(e.target.value)}}
                        placeholder={placeholder !== undefined ?  placeholder : `Enter ${label || ''}`}
                        maxLength={maxLength || (isTextArea ? undefined : 50)}
                      />
            }
            <img src={confirmImg} title={confirmBtnTitle || 'Ok'} alt="" onClick={handleConfirm} />
            {!!value && allowReset && <img src={deleteImg} title="Delete this value" alt="" onClick={handleReset} />}
            <img src={cancelImg} title="Cancel" alt="" onClick={handleCancel} />
          </div> :
            <div>
              <div
                className={`form-control on-edit-mode${noValue() ? ' no-value' : ''}${disabled ? ' disabled' : ''}${linkProps ? ' with-link' : ''}`}
                title={hint}
                onClick={() => !editMode && openEditMode()}
              >
                {buildValue()}
              </div>
            </div>
      }
    </StyledField>
  );
}

const StyledField = styled(FormGroup)`
  .editable-control {
    display: flex;

    @media (min-width: 992px) {
      width: 25%;
      .form-group {
        width: 100%
      } 
    }

    &.editable-control-textarea {
      @media (min-width: 992px) {
        width: 55%;
        min-height: 100px;
      }
    }

    .form-group {
      margin-bottom: 0;
      flex-grow: 1;
    }

    input[type="number"] {
      width: 90px !important;
      border-radius: 4px;
      &:invalid {
        border-color: #ff4040;
        outline-color: #ff4040;
      }
    }
  }

  img {
    cursor: pointer;
    margin-left: 15px;
    flex: 0 0 25px;
  }

  .control-label {
    :after {
      content: attr(title);
      font-weight: normal;
      font-size: 10px;
      margin-left: 10px;
    }
  }

  .form-control {
    padding-left: 5px;
    padding-right: 2px;
    font-size: 13px;

    &:not(.non-editable).disabled {
      pointer-events: none;
    }

    &.on-edit-mode {
      margin: 0;
      padding-left: 0;
      padding-right: 0;
      height: auto;
      min-height: 34px;
      background-color: #fff;
      border-color: #fff;
      box-shadow: none;
      word-break: break-all;
      transition: none;

      &.no-value {
        font-weight: normal;
        opacity: 0.75;
      }

      @media (min-width: 992px) {
        display: inline-block;
        width: auto;
        min-width: 70px;
      }

      &:not(.non-editable):hover {
        background-color: #eee;
        border-color: #eee;
        cursor: pointer;
      }

      &.with-link {
        &:not(.non-editable) {
          @media (min-width: 992px) {
            min-width: 90%;
          }
          :hover {
            box-shadow: -3px 0 0 #eee, 3px 0 0 #eee;
          }
          a {
            :hover {
              text-decoration: underline;
            }
          }
        }
        &.non-editable {
          a {
            border-radius: 3px;
            &:hover {
              background-color: #f5f5f5;
              box-shadow: -5px -3px 0 #f5f5f5, 5px -3px 0 0 #f5f5f5, -5px 3px 0 #f5f5f5, 5px 3px 0 0 #f5f5f5;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .Select,
  .Select.has-value.Select--single,
  .Select.Select--single,
  .Select--multi,
  .Select.is-searchable.Select--single {
    >
      .Select-control {
        background: #fff !important;
        border: 1px solid #E9E9E9 !important;
        box-shadow: none !important;
        cursor: pointer;
        height: 32px !important;

        .Select-multi-value-wrapper {
          height: 32px !important;

          .Select-placeholder,
          .Select-value {
            line-height: 14px !important;
            font-size: 13px !important;
            padding: 9px !important;

            .Select-value-label {
              font-size: 13px !important;
              font-weight: normal !important;
            }
          }

          .Select-input {
            height: 32px !important;
            padding-left: 9px !important;
            input {
              font-size: 13px !important;
              height: 32px !important;
              padding: 0 !important;
            }
          }
        }

        .Select-clear-zone {
          display: none !important;
        }
        .Select-arrow-zone img {
          width: 11.5px !important;
          opacity: 0.3 !important;
        }
      }

      .Select-option {
        &.is-disabled {
          opacity: 0.65;
        }
      }
  }
  .Select .Select-menu {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background: rgba(0, 0, 0, .1);
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgba(0, 0, 0, .2);
      -webkit-box-shadow: none;
    }
  }
  .menu_position_top .Select-menu-outer{
    top: auto;
    bottom: 100%;
  }
  .menu_zIndex100 .Select-menu-outer{
    z-index: 100;
  }

  .react-datepicker-wrapper {
    display: block;
    .react-datepicker__input-container {
      display: block;
      .form-control {
        background-color: #fff;
        &.is-clearable {
          padding-right: 34px;
        }
      }
      .react-datepicker__close-icon {
      }
    }
  }
  .react-datepicker-popper {
    .react-datepicker,
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      font-family: 'Roboto',sans-serif;
      font-size: 11px;
    }
    .react-datepicker__input-time-container {
      /*margin-left: 0;*/
      > .react-datepicker-time__caption {
        /*margin-left: 10px;*/
      }
      > .react-datepicker-time__input-container {
        > .react-datepicker-time__input {
          /*margin-left: 0;*/
          input[type="time"] {
            border-radius: 3px;
            &::-webkit-inner-spin-button, &::-webkit-outer-spin-button, &::-webkit-clear-button {
              display: none;
            }
          }
        }
      }
    }
  }
`;

TextEditableField.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  onConfirm: PropTypes.func
};

export default TextEditableField;
