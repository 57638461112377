import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

export const StudioPartnerInfoCell = ({row}) => {
  return (
    <td>
      <Row>
        <Col md={12} xs={12}>
          {row.website || ' '}
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12}>
          {row.description || ' '}
        </Col>
      </Row>
    </td>
  );
};

StudioPartnerInfoCell.propTypes = {
  row: PropTypes.object
};
