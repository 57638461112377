import React from 'react';
import PropTypes from 'prop-types';

const CostCell = ({value, className}) => {
  const cost = +value;
  const isCorrectNumber = typeof cost === 'number' && !isNaN(cost);
  return (
    <td className={className}>{isCorrectNumber ? `$${cost.toFixed(2)}` : value}</td>
  );
};

CostCell.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string
};

export default CostCell;
