import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Table from '../../../../components/table/Table';
import {getOptionName, getTextlessOptionName, formatSeasonNameForTitle} from '../../../../utils';
import {getVideoFormatOptionName, buildVideoFormatName} from '../../constants';
import TextEditableField from '../../../RequestDetails/components/forms/TextEditableField';
import {confirmAlert} from 'react-confirm-alert';
import {getExistingTagsForNewRequest} from '../../actions';
import Loader from '../../../../components/Loader';

/*const QC_TYPE_AUDIO_VIDEO = 1;
const QC_TYPE_AUDIO = 2;
const QC_TYPE_VIDEO = 3;*/
const QC_TYPE_S_AND_P = 4;
const QC_TYPE_CAPTION_QC = 5;
const QC_TYPE_HARDING_TEST = 15;

function CsTags(props) {
  const {data, filters, onChange} = props;
  const {qc_profile_id, qc_type_id, series_id, trailer_episode_name} = data;
  const seasonNum = (data.season || {}).season_number;
  const reqTitle = (data.title || ((filters.series.find(s => s.series_id === series_id) || {}).series_name || series_id)) +
    (trailer_episode_name ? ` / ${trailer_episode_name}` : seasonNum ? formatSeasonNameForTitle(seasonNum) : '');

  const [isLoading, setLoading] = useState(false);
  const [existingTags, setExistingTags] = useState([]);

  useEffect(() => {
    setLoading(true);
    getExistingTagsForNewRequest(data).then(
      res => {
        setLoading(false);
        setExistingTags(res.tags);
      },
      error => {
        console.log(error);
        setLoading(false);
      }
    );
  }, [data]);

  useEffect(() => {
    if (data.video_format) {
      const videoFormats = [...data.video_format];
      videoFormats.forEach((vf, index) => {
        if (!vf.additional_description && vf.additional_description !== '') {
          const textlessName = getTextlessOptionName(vf.textless);
          const vfFields = [
            getVideoFormatOptionName(filters, 'video_resolutions', qc_profile_id, vf.resolution_id),
            getVideoFormatOptionName(filters, 'video_color_spaces', qc_profile_id, vf.color_space_id),
            textlessName === 'Textless' ? textlessName : '',
            getVideoFormatOptionName(filters, 'video_formats', qc_profile_id, vf.format_id)
          ].filter(Boolean);
          vf.additional_description = ((vf.version_tag ? `${vf.version_tag} - ` : '') + vfFields.join(' ') + ' QC').trim();
        }
      });
      onChange('video_format', videoFormats);
    } else if (!data.additional_description && data.additional_description !== '') {
      let desc;
      if (qc_type_id === QC_TYPE_S_AND_P) {
        desc = 'S&P QC';
      } else if (qc_type_id === QC_TYPE_CAPTION_QC) {
        desc = 'Closed Caption QC';
      } else if (qc_type_id === QC_TYPE_HARDING_TEST) {
        desc = 'Harding Test QC';
      } else if (data.audio_format) {
        const sounds = ((data.audio_format.audio_configuration || {}).data || [])
          .map(ac => getOptionName(filters.audio_sounds, ac.sound_id)).sort((a, b) => b.localeCompare(a));
        desc = ((data.version_tag ? `${data.version_tag} - ` : '') + sounds.join('/') + ' QC').trim();
      }
      onChange('additional_description', desc);
    }
  }, [onChange, filters, qc_profile_id, qc_type_id, data]);

  const versions = data.video_format ? data.video_format.map((vf, index) => ({
    index,
    versionName: buildVideoFormatName(filters, qc_profile_id, vf),
    reqTitle: reqTitle + (vf.version_tag ? ` - ${vf.version_tag}` : ''),
    reqTag: vf.version_tag,
    additionalDesc: vf.additional_description
  })) : [{
    versionName: qc_type_id === QC_TYPE_CAPTION_QC ? 'CC' : '',
    reqTitle: reqTitle + (data.version_tag ? ` - ${data.version_tag}` : ''),
    reqTag: data.version_tag,
    additionalDesc: data.additional_description
  }];

  const isExistsTag = (tag, versionName) => {
    const prepareTag = t => (t || '').trim().toLowerCase();
    tag = prepareTag(tag);
    return versions.filter(v => prepareTag(v.reqTag) === tag && (v.versionName || '') === (versionName || '')).length > 1 ||
           existingTags.some(t => prepareTag(t.Tag) === tag && (t.Version || '') === (versionName || ''));
  };

  const confirmAlertUpdateAddDesc = (index, oldTag, newTag) => {
    confirmAlert({
      childrenElement: () =>
        <h5 className="custom-msg">
          Do you want to update the additional description?
        </h5>,
      buttons: [{
        label: 'No'
      }, {
        label: 'Yes',
        onClick: () => {
          if (qc_type_id === QC_TYPE_CAPTION_QC) {
            const newDesc = 'Closed Caption QC' + (newTag ? ' - ' + newTag : '');
            onChange('additional_description', newDesc);
            return;
          }

          const curDesc = (data.video_format ? data.video_format[index] : data).additional_description;
          const newDesc = (!oldTag || !curDesc) ? (curDesc ? `${newTag} - ${curDesc}` : newTag) :
            curDesc.replace(new RegExp(`^${oldTag} [-] `), newTag ? `${newTag} - ` : '').trim();
          if (data.video_format) {
            data.video_format[index].additional_description = newDesc;
            onChange('video_format', data.video_format);
          } else {
            onChange('additional_description', newDesc);
          }
        }
      }]
    });
  };

  const cell = ({value, row, className, name}) => {
    const isTagCell = name === 'version_tag';
    return (
      <Cell className={className}>
        <TextEditableField
          value={value}
          onConfirm={(l, n, newValue, callback) => {
            newValue = (newValue || '').trim();
            if (value === newValue) {
              callback();
              return;
            }
            if (data.video_format) {
              data.video_format[row.index][name] = newValue;
              onChange('video_format', data.video_format);
            } else {
              onChange(name, newValue);
            }
            callback();
            if (isTagCell && (data.video_format || data.audio_format)) {
              confirmAlertUpdateAddDesc(row.index, value, newValue);
            }

            if (isTagCell && data.qc_type_id === QC_TYPE_CAPTION_QC) {
              confirmAlertUpdateAddDesc(row.index, value, newValue);
            }
          }}
          hiddenHint
        />
        {isTagCell && isExistsTag(value, row.versionName) &&
          <i className="fas fa-exclamation-triangle" title={`duplicate ${value ? '' : 'empty '}tag`}/>
        }
      </Cell>
    );
  };

  const headers = {
    reqTitle: {
      title: 'Title',
      className: 'req-title'
    },
    versionName: {
      title: 'Version',
      className: 'req-version-name'
    },
    reqTag: {
      title: 'Tag',
      className: 'req-tag',
      component: cell,
      componentArgs: {
        name: 'version_tag'
      }
    },
    additionalDesc: {
      title: 'Additional Description',
      className: 'req-additional-desc',
      component: cell,
      componentArgs: {
        name: 'additional_description'
      }
    },
  };

  return (
    <div className="step-body-wrapper cs-tags">
      {isLoading && <Loader className="full-screen"/>}
      <div className="table-contaner uploads-table reports-table cs-tags-table midl_in">
        <div>You can edit tag and additional description values for new request</div>
        <div className="table-block">
          <Table
            headers={headers}
            rows={versions}
            stripped={false}
          />
        </div>
      </div>
    </div>
  );
};

const Cell = styled.td`
  position: relative;

  i {
    position: absolute;
    left: 0;
    top: 18px;
    font-size: 12px;
    color: #ffbc00;
  }

  .text-editable-field--in-edit-mode + i {
    opacity: 0.3;
  }

  > .form-group {
    margin: 0;

    @media (min-width: 768px) {
      min-width: 170px;
    }

    > div {
      display: flex;
    }

    .editable-control {
      @media (min-width: 992px) {
        width: 100%;
      }
    }
  }
`;

CsTags.propTypes = {
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CsTags;
