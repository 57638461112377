import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Button} from 'react-bootstrap';

class ManualUploads extends Component {
  render() {
    const {data, onRemove, onRemoveAll} = this.props;
    return (
      <StyledTable>
        <tbody>
        {
          data.map((item, i) => (
            <tr key={`manual-upload-${i}`}>
              <td>
                <i className={`fas fa-${item.isDirectory ? 'folder-open' : 'file'}`}/>
                {item.asset_name.split('\\').pop().split('/').pop()}
              </td>
              <td>
                {item.asset_name}
              </td>
              <td>
                <span className="icon-trash" onClick={() => onRemove(i)}/>
              </td>
            </tr>
          ))
        }
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="3">
              <Button bsStyle="primary" onClick={onRemoveAll}>
                Remove All
              </Button>
            </td>
          </tr>
        </tfoot>
      </StyledTable>
    );
  }
}

const StyledTable = styled.table`
  border-collapse: separate;
  max-width: 100%;
  margin: 10px 0 17px 33px;
  .icon-trash {
    margin-right: 5px;
    color: #000;
    opacity: 0.8;
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
  tbody tr {
    &:hover {
      background-color: #f1f1f1;
    }
    td {
      padding-top: 3px;
      word-break: break-all;
      &:not(:last-child) {
        padding-right: 20px;
      }
      i {
        padding-right: 0.5rem;
        padding-left: 5px;
      }
    }
  }
  tfoot tr td {
    text-align: right;
    .btn {
      margin: 7px 0 0 !important;
      padding: 6px 12px !important;
      font-size: 14px !important;
      line-height: 15px !important;
    }
  }
`;

ManualUploads.propTypes = {
  data: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRemoveAll: PropTypes.func.isRequired
};

export default ManualUploads;
