import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Table from '../../../../components/table/Table';
import {SelectedCell} from './cells/SelectedCell';
import {DistributorInfoCell} from './cells/DistributorInfoCell';
import {ImageCell} from './cells/ImageCell';
import {Row, Col, Button, Checkbox} from 'react-bootstrap';
import InputAdvanced from '../../../../components/forms/InputAdvanced';
import searchImg from '../../../../../assets/images/search.svg';
import filterImg from '../../../../../assets/images/filter.svg';
import expandImg from '../../../../../assets/images/expand.svg';
import {StyledSearchImg} from './styledStudiosTable'
import {isClientServiceUser} from '../../../../utils'

class DistributorsTable extends Component {
  state = {
    creating: false,
    filtered: null,
    searchTitle: null,
    values: null
  };

  constructor(props, context) {
    super(props, context);

    this.headers = {
      selected: {
        component: SelectedCell
      },
      name: {},
      common: {
        component: DistributorInfoCell,
        componentArgs: {
          getSpecQcProfile: this.getSpecQcProfile
        }
      },
      logo_url: {
        component: ImageCell
      }
    };
  }

  isSelected = row => {
    return row.value === this.props.selected;
  };

  getSpecQcProfile = row => {
    return row.qc_profile_id ? this.props.qcProfiles.find(p => p.value === row.qc_profile_id) : null;
  };

  handleSearch = (_, value) => {
    let filtered;
    if (!value) {
      filtered = null;
    } else {
      filtered = this.props.data.filter(r => r.name.match(new RegExp(`.*${value}.*`, 'i')));
    }
    this.setState({searchTitle: value, filtered});
  };

  handleResetSearch = () => {
    this.handleSearch('', null);
  };

  handleFieldValueChanged = (name, value) => {
    const values = {...this.state.values};
    values[name] = value || null;
    this.setState({values});
  };

  handleClickCreateNew = () => {
    this.setState({creating: true});
  };

  handleCancelNew = () => {
    this.setState({creating: false, values: null});
  };

  handleSaveNew = () => {
    const {values, searchTitle} = this.state;
    this.props.onSave(values, () => {
      this.handleCancelNew();
      !!searchTitle && this.handleSearch('', searchTitle);
    });
  };

  renderCreateDistributor = () => {
    const values = this.state.values || {};
    return (
      <div className="create-new-distributor">
        <Row>
          <Col md={12} xs={12}>
            <div className="distributor-select-text">Create New Distributor</div>
          </Col>
        </Row>
        {isClientServiceUser() &&
          <Row>
            <Col md={8} xs={12}>
              <StyledCheckbox
                checked={!!values.is_system_distributor}
                onChange={({target: {checked}}) => this.handleFieldValueChanged('is_system_distributor', checked)}
              >
                This distributor should be available to all companies (not only to the current studio)
              </StyledCheckbox>
            </Col>
          </Row>
        }
        <Row className="distributor-new-fields">
          <Col md={2} xs={12}>
            <InputAdvanced
              name="distributor_name"
              value={values.distributor_name}
              placeholder="Distributor Name"
              onChange={this.handleFieldValueChanged}
              onBlur={this.handleFieldValueChanged}
              />
          </Col>
          <Col md={2} xs={12}>
            <InputAdvanced
              name="website"
              value={values.website}
              placeholder="Website"
              onChange={this.handleFieldValueChanged}
              onBlur={this.handleFieldValueChanged}
              optional
              />
          </Col>
          <Col md={4} xs={12}>
            <InputAdvanced
              name="brief_note"
              value={values.brief_note}
              placeholder="Brief Note"
              onChange={this.handleFieldValueChanged}
              onBlur={this.handleFieldValueChanged}
              optional
              />
          </Col>
          <Col md={3} xs={12}>
            <div className="table-buttons-container input-group">
              <Button
                bsStyle="default"
                onClick={this.handleCancelNew}
                >
                Cancel
              </Button>
              <Button
                bsStyle="primary"
                onClick={this.handleSaveNew}
                disabled={!values.distributor_name}
                >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const {data, onSelect} = this.props;
    const {creating, searchTitle} = this.state;
    return (
      <div className="table-contaner uploads-table distributors-table">
        <Row>
          <Col md={4} xs={12}>
            <div className="table-info">
              <div className="distributor-select-text">Select Distributor</div>
              <div>Click to select your media distributor service</div>
            </div>
          </Col>
          <Col md={8} xs={12}>
            <div className="table-buttons-container input-group">
              <StyledSearchImg className="img-search" src={searchImg} alt=""/>
              <InputAdvanced
                inputClassName={`search-title-input${searchTitle ? '' : ' empty'}`}
                value={searchTitle}
                placeholder="Search by title"
                onChange={this.handleSearch}
                onBlur={this.handleSearch}
                onClear={this.handleResetSearch}
                forceReset
                />
              {searchTitle ? <img className="img-filter" src={filterImg} alt=""/> : null}
              <Button
                className="btn-create-new-distributor"
                bsStyle="primary"
                onClick={this.handleClickCreateNew}
                disabled={creating}
                >
                Create New{creating ? <img src={expandImg} alt=""/> : null}
              </Button>
            </div>
          </Col>
        </Row>
        {creating ? this.renderCreateDistributor() : null}
        <div className="table-block">
          <Table
            headers={this.headers}
            rows={this.state.filtered || data}
            onRowClick={row => onSelect(row.value)}
            getRowClassNames={row => this.isSelected(row) ? 'selected-row' : null}
            stripped={false}
            />
        </div>
      </div>
    );
  }
}

const StyledCheckbox = styled(Checkbox)`
  input[type="checkbox"] {
    margin-top: 1px;
  }
  label {
    font-size: 12px;
  }
`;

DistributorsTable.propTypes = {
  data: PropTypes.array.isRequired,
  qcProfiles: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.number
};

export default DistributorsTable;
