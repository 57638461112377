import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {HelpBlock} from 'react-bootstrap';
import SelectAdvanced from '../../../../components/forms/SelectAdvanced';
import {IssueStatusesOptions, isPendingApprovalIssueStatus} from '../../utils';
import {prepareStatusOptions} from '../modals/IssueFixNotesModal';

export const StatusCell = ({row, onSelect, title}) => {
  const clientStatusId = (IssueStatusesOptions.find(i => i.value === row.ClientStatusID) || {}).value;
  const isPendingApproval = isPendingApprovalIssueStatus(clientStatusId) && !row.IsIssueForPendingApproval;
  return (
    <StyledCell
      title={title || 'Status'}
    >
      <SelectAdvanced
        placeholder=""
        options={prepareStatusOptions(row, clientStatusId)}
        value={clientStatusId}
        onSelect={(_, id) => onSelect(row, IssueStatusesOptions.find(i => i.value === id))}
        searchable={false}
      />
      {isPendingApproval &&
        <HelpBlock
          style={{color: '#8a6d3b'}}
        >
          <i
            className="fas fa-exclamation-triangle"
            style={{marginRight: '3px', fontSize: '12px'}}
          />
          An issue is waiting for approval
        </HelpBlock>
      }
    </StyledCell>
  );
};

const StyledCell = styled.td`
  .form-group {
    max-width: 124px;
    margin: 0;
    @media (max-width: 767px) {
      max-width: 100%;
    }
    .Select-control {
      height: 30px !important;
      background: #FFFFFF !important;
      border: 0.5px solid #D1D1D1 !important;
      border-radius: 3px !important;
      .Select-placeholder, .Select-value {
        line-height: 16px !important;
        padding: 7px 11px 7px 8px !important;
        .Select-value-label {
          font-weight: normal !important;
        }
      }
      .Select-input {
        height: 30px !important;
        padding-left: 8px !important;
        input {
          padding-top: 7px !important;
          padding-bottom: 7px !important;
          line-height: 16px !important;
        }
      }
      .Select-arrow-zone {
        width: 31px !important;
        padding-right: 0 !important;
        img {
          opacity: 1 !important;
          width: 7.58px !important;
        }
      }
    }
    .Select-menu-outer {
      width: 187px !important;
      max-width: calc(100vw / 2) !important;
      background: #FFFFFF !important;
      border: 0.5px solid #E0E0E0 !important;
      box-sizing: border-box !important;
      box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.13) !important;
      border-radius: 0px 3px 3px 3px !important;
      margin-top: -4px;

      .Select-option {
        margin-left: 18px !important;
        padding-left: 0 !important;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        &:not(:last-of-type) {
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.2) !important;
        }
        &.is-selected, &.is-focused {
          box-shadow: none !important;
          background-color: #fff !important;
        }
        &.is-disabled {
          opacity: 0.75;
        }
      }
    }
  }
`;

StatusCell.propTypes = {
  row: PropTypes.object,
  onSelect: PropTypes.func,
};
