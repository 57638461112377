/* eslint "complexity":"off" */
import {USER_SET_IS_LOGOUT} from '../../pages/Login/actions';
import {
  REPORT_DETAILS_ADD_FILTERS, REPORT_DETAILS_CHANGE_FILTERS, REPORT_DETAILS_CHANGE_VIDEO_PARAMS, REPORT_DETAILS_CLEAR,
  REPORT_DETAILS_CLEAR_FIXLIST, REPORT_DETAILS_GET_FILTERS, REPORT_DETAILS_GET_FIXLIST,
  REPORT_DETAILS_GET_ISSUE_COMMENTS, REPORT_DETAILS_GET_ISSUES, REPORT_DETAILS_GET_REQUEST,
  REPORT_DETAILS_IS_COMPLETING, REPORT_DETAILS_IS_ISSUE_SAVING, REPORT_DETAILS_IS_LOADING, REPORT_DETAILS_DISCUSS_ISSUE,
  REPORT_DETAILS_IS_LOADING_ISSUE_COMMENTS, REPORT_DETAILS_MARK_COMPLETED,
  REPORT_DETAILS_RESET_FILTERS, REPORT_DETAILS_SET_TOP_ACTIVE_TAB, REPORT_DETAILS_SHOW_DISCUSS_MODAL,
  REPORT_DETAILS_SHOW_FIXLIST_MODAL, REPORT_DETAILS_SHOW_APPROVE_MODAL, REPORT_DETAILS_SHOW_SUBMIT_MODAL,
  REPLACEMENT_IS_SAVING, REPORT_DETAILS_ISSUES_IS_LOADING, REPORT_DETAILS_SHOW_WARNING_IN_EMAIL_REPORT_MODAL,
  REPORT_DETAILS_CHANGE_FILTERS_TYPE, REPORT_DETAILS_FILTERS_IS_LOADING, REPORT_DETAILS_SET_LIST_VIEW_TAB,
  REPORT_DETAILS_IS_LOADING_ISSUE_COMMENTS_SIDEBAR, REPORT_DETAILS_SET_SAVED_STATE, REPORT_DETAILS_SAVED_FILTERS,
  REPORT_DETAILS_SCROLL_TO_ISSUE_COMMENT, REPORT_DETAILS_SHOW_EMAIL_REPORT_MODAL, REPORT_DETAILS_TOGGLE_SCHEDULE,
  REPORT_DETAILS_SHOW_EDIT_SCHEDULE_MODAL, REPORT_DETAILS_UPDATE_EDIT_MODAL, REPORT_DETAILS_UPDATE_MILESTONE,
  REPORT_DETAILS_LOCK_FEATURE, REPORT_DETAILS_SET_LOADING, REPORT_DETAILS_SHOW_ADD_ISSUE_MODAL,
  REPORT_DETAILS_SHOW_ISSUE_IMAGE_MODAL,
  REPORT_DETAILS_GET_GENERAL_COMMENTS, REPORT_DETAILS_SHOW_GENERAL_COMMENTS, REPORT_DETAILS_CLOSE_GENERAL_COMMENTS,
  REPORT_DETAILS_SCROLL_TO_GENERAL_COMMENT,
  REPORT_DETAILS_SHOW_IMPORT_CSV_ISSUES_MODAL,
  REPORT_DETAILS_IS_PROXY_SAVING, REPORT_DETAILS_IS_PROXY_LOADING, REPORT_DETAILS_UPDATE_DETAILS,
  REPORT_DETAILS_SHOW_IMPORT_ISSUES_MODAL,
  REPORT_DETAILS_ATTACHMENT_IS_DOWNLOADING,
} from './actions';

const initialState = {
  loading: false,
  issueFiltersLoading: false,
  issuesLoading: false,
  completing: false,
  loadingIssueComments: false,
  loadingIssueCommentsSidebar: false,
  activeTopTab: 1,
  showSchedule: false,
  showEditScheduleModal: false,
  editDateModal: {
    show: false,
    episodeId: null,
    schedulingId: null,
    position: {},
    selectedDate: null,
    minDate: null,
    maxDate: null,
    updatePrevDates: false,
    updateNextDates: false
  },
  activeListViewTab: 0,
  filters: {},
  details: {},
  timeline: [],
  schedulingHeaders: [],
  milestones: [],
  issues: [],
  hasIssueComments: false,

  videoPlayer: {
    selectedCue: null,
    selectedIssue: {},
    isChangedSelecting: false,
    isPlaying: false,
    currentIssueID: null
  },

  saving: false,
  showFixNotesModal: false,
  showDiscussModal: false,
  modalIssue: {},
  modalIssueCommentId: null,
  modalIssueClientStatusId: null,

  showFixListModal: false,
  loadingFixlistIssues: false,
  fixlistIssues: [],
  fixlistSeverityTypes: null,
  fixlistMinSeverity: null,
  showSubmitReplacementModal: false,
  replacementCSParam: null,
  showEmailReportModal: false,
  warnInEmailReportModal: null,

  allowedFilters: [],
  shownFilters: [],

  savedState: {},

  loaders: {
    editScheduleModal: false,
    issueImageModal: false,
    generalComments: false,
  },

  showImportCsvIssuesModal: false,
  showAddIssueModal: false,

  imageModalIssue: {
    issue: null,
    images: null
  },

  generalComments: {show: false, comments: [], currentCommentId: null},

  isProxySaving: false,
  isProxyLoading: false,

  showImportIssuesModal: false,

  isAttachmentDownloading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {

    case USER_SET_IS_LOGOUT:
    case REPORT_DETAILS_CLEAR: {
      return {...initialState};
    }

    case REPORT_DETAILS_SET_SAVED_STATE: {
      return {
        ...state,
        savedState: {
          ...state.savedState,
          ...action.query
        }
      }
    }

    case REPORT_DETAILS_IS_LOADING: {
      return {...state, loading: action.loading};
    }

    case REPORT_DETAILS_FILTERS_IS_LOADING: {
      return {...state, issueFiltersLoading: action.loading}
    }

    case REPORT_DETAILS_ISSUES_IS_LOADING: {
      return {...state, issuesLoading: action.loading}
    }

    case REPORT_DETAILS_SET_TOP_ACTIVE_TAB: {
      return {...state, activeTopTab: +action.key};
    }

    case REPORT_DETAILS_TOGGLE_SCHEDULE: {
      return {...state, showSchedule: action.value}
    }

    case REPORT_DETAILS_SHOW_EDIT_SCHEDULE_MODAL: {
      return {...state, showEditScheduleModal: action.showModal}
    }

    case REPORT_DETAILS_UPDATE_EDIT_MODAL: {
      return {
        ...state,
        editDateModal: {
          ...state.editDateModal,
          ...action.values
        }
      }
    }

    case REPORT_DETAILS_UPDATE_MILESTONE: {
      return {
        ...state,
        milestones: action.milestones
      }
    }

    case REPORT_DETAILS_LOCK_FEATURE: {
      return {
        ...state,
        details: {
          ...state.details,
          lock_scheduling: 1
        }
      }
    }

    case REPORT_DETAILS_SET_LIST_VIEW_TAB: {
      return {...state, activeListViewTab: +action.key};
    }

    case REPORT_DETAILS_GET_REQUEST: {
      return {
        ...state,
        loading: false,
        details: action.details,
        timeline: action.timeline,
        schedulingHeaders: action.schedulingHeaders,
        milestones: action.milestones,
        filters: action.filters
      };
    }

    case REPORT_DETAILS_GET_FILTERS: {
      return {...state, allowedFilters: action.allowedFilters};
    }

    case REPORT_DETAILS_GET_ISSUES: {
      return {...state, issues: action.issues, hasIssueComments: action.hasIssueComments};
    }

    case REPORT_DETAILS_IS_COMPLETING: {
      return {...state, completing: action.completing};
    }

    case REPORT_DETAILS_MARK_COMPLETED: {
      return {
        ...state,
        completing: false,
        details: {...state.details, ...action.res},
      };
    }

    case REPORT_DETAILS_IS_LOADING_ISSUE_COMMENTS: {
      return {...state, loadingIssueComments: action.loading};
    }

    case REPORT_DETAILS_IS_LOADING_ISSUE_COMMENTS_SIDEBAR: {
      return {...state, loadingIssueCommentsSidebar: action.loading};
    }

    case REPORT_DETAILS_GET_ISSUE_COMMENTS: {
      const issues = state.issues.map(issue => issue.IssueID === action.issueId
        ? { ...issue, comments: action.comments }
        : issue
      );

      const fixlistIssues = state.fixlistIssues.map(issue => issue.IssueID === action.issueId
        ? { ...issue, comments: action.comments }
        : issue
      );

      return {
        ...state,
        loadingIssueComments: false,
        issues,
        fixlistIssues
      };
    }

    case REPORT_DETAILS_DISCUSS_ISSUE: {
      const issues = state.issues.map(issue => issue.IssueID === action.issueId
        ? { ...issue, Comments: issue.Comments + 1 }
        : issue
      );

      const fixlistIssues = state.fixlistIssues.map(issue => issue.IssueID === action.issueId
        ? { ...issue, Comments: issue.Comments + 1 }
        : issue
      );

      return {
        ...state,
        saving: false,
        issues,
        hasIssueComments: true,
        fixlistIssues
      };
    }

    case REPORT_DETAILS_IS_ISSUE_SAVING: {
      return {
        ...state,
        saving: action.saving
      };
    }

    case REPORT_DETAILS_SHOW_DISCUSS_MODAL: {
      return {
        ...state,
        showDiscussModal: action.showModal,
        modalIssue: action.issue || {},
        modalIssueCommentId: action.commentId || null,
        modalIssueClientStatusId: action.clientStatusId || null,
      };
    }

    case REPORT_DETAILS_SHOW_APPROVE_MODAL: {
      return {
        ...state,
        showFixNotesModal: action.showModal,
        modalIssue: action.issue || {}
      };
    }

    case REPLACEMENT_IS_SAVING: {
      return {
        ...state,
        saving: action.saving
      };
    }

    case REPORT_DETAILS_SHOW_FIXLIST_MODAL: {
      return {
        ...state,
        showFixListModal: action.showModal
      };
    }

    case REPORT_DETAILS_GET_FIXLIST: {
      return {
        ...state,
        loadingFixlistIssues: action.loading,
        fixlistIssues: action.issues || [],
        fixlistSeverityTypes: action.severityTypeList || state.fixlistSeverityTypes,
        fixlistMinSeverity: action.minSeverity || state.fixlistMinSeverity
      };
    }

    case REPORT_DETAILS_CLEAR_FIXLIST: {
      return {
        ...state,
        loadingFixlistIssues: false,
        fixlistIssues: [],
        fixlistSeverityTypes: initialState.fixlistSeverityTypes,
        fixlistMinSeverity: initialState.fixlistMinSeverity
      };
    }

    case REPORT_DETAILS_SHOW_SUBMIT_MODAL: {
      return {
        ...state,
        showSubmitReplacementModal: action.showModal,
        replacementCSParam: action.csParam || initialState.replacementCSParam
      };
    }

    case REPORT_DETAILS_CHANGE_VIDEO_PARAMS: {
      const {selectedCue} = (action.data || {});
      return {
        ...state,
        videoPlayer: {
          ...state.videoPlayer,
          ...(selectedCue
            ? {selectedIssue: initialState.videoPlayer.selectedIssue, currentIssueID: initialState.videoPlayer.currentIssueID}
            : {selectedCue: initialState.videoPlayer.selectedCue}
          ),
          ...action.data
        }
      };
    }

    case REPORT_DETAILS_ADD_FILTERS: {
      const shownFilters = [...state.shownFilters];
      const res = {
        name: action.filter.name,
        title: action.filter.label,
        value: action.filter.defaultValue ? [action.filter.defaultValue] : null,
        required: action.filter.required,
        disabled: action.filter.disabled
      };

      if (action.filter.required) {
        shownFilters.unshift(res);
      } else {
        shownFilters.push(res);
      }

      return {
        ...state,
        shownFilters
      };
    }

    case REPORT_DETAILS_SAVED_FILTERS: {
      const shownRequiredFilters = [];
      const shownOtherFilters = [];
      Object.keys(action.filters).forEach(key => {
        const value = Array.isArray(action.filters[key]) ?
          action.filters[key].map(item => +item) :
          action.filters[key];
        const existFilter = state.allowedFilters.find(item => item.name === key);

        const res = {name: key, value, title: existFilter.title};
        if (existFilter.required) {
          res.disabled = true;
          res.required = true;
          shownRequiredFilters.push(res);
        } else {
          shownOtherFilters.push(res);
        }
      });

      return {
        ...state,
        shownFilters: shownRequiredFilters.concat(shownOtherFilters)
      }
    }

    case REPORT_DETAILS_CHANGE_FILTERS: {
      return {
        ...state,
        shownFilters: state.shownFilters.map(item => {
          return item.name === action.filterName ? {
            ...item,
            value: action.value || null
          } : item;
        })
      };
    }

    case REPORT_DETAILS_CHANGE_FILTERS_TYPE: {
      return {
        ...state,
        shownFilters: state.shownFilters.map(item => {
          if (item.name === action.filterName) {
            return {
              name: action.newFilterName,
              title: action.newFilterLabel,
              value: null
            }
          }
          return item;
        })
      };
    }

    case REPORT_DETAILS_RESET_FILTERS: {
      return {
        ...state,
        allowedFilters: [],
        shownFilters: []
      };
    }

    case REPORT_DETAILS_SCROLL_TO_ISSUE_COMMENT: {
      return {
        ...state,
        modalIssueCommentId: null,
        modalIssueClientStatusId: null,
      };
    }

    case REPORT_DETAILS_SHOW_EMAIL_REPORT_MODAL: {
      return {
        ...state,
        showEmailReportModal: action.showModal,
        warnInEmailReportModal: initialState.warnInEmailReportModal
      };
    }

    case REPORT_DETAILS_SHOW_WARNING_IN_EMAIL_REPORT_MODAL: {
      return {
        ...state,
        warnInEmailReportModal: action.warning || initialState.warnInEmailReportModal
      };
    }

    case REPORT_DETAILS_SET_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.name]: action.value
        }
      }
    }

    case REPORT_DETAILS_SHOW_ADD_ISSUE_MODAL: {
      return {
        ...state,
        showAddIssueModal: action.showModal,
        modalIssue: action.issue || {}
      }
    }

    case REPORT_DETAILS_SHOW_ISSUE_IMAGE_MODAL: {
      return {...state, imageModalIssue: action.imageModalIssue || initialState.imageModalIssue}
    }

    case REPORT_DETAILS_GET_GENERAL_COMMENTS: {
      const {comments, commentId} = action;
      return {
        ...state,
        loaders: {
          ...state.loaders,
          generalComments: false,
        },
        generalComments: {...state.generalComments, comments: comments || [], currentCommentId: commentId || null}
      }
    }

    case REPORT_DETAILS_SHOW_GENERAL_COMMENTS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          generalComments: false,
        },
        generalComments: {...state.generalComments, show: true}
      }
    }

    case REPORT_DETAILS_CLOSE_GENERAL_COMMENTS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          generalComments: false,
        },
        generalComments: {...state.generalComments, show: false, currentCommentId: null}
      }
    }

    case REPORT_DETAILS_SCROLL_TO_GENERAL_COMMENT: {
      return {
        ...state,
        generalComments: {...state.generalComments, currentCommentId: null}
      };
    }

    case REPORT_DETAILS_SHOW_IMPORT_CSV_ISSUES_MODAL: {
      return {
        ...state,
        showImportCsvIssuesModal: action.showModal
      };
    }

    case REPORT_DETAILS_IS_PROXY_SAVING: {
      return {
        ...state,
        isProxySaving: action.loading
      };
    }

    case REPORT_DETAILS_IS_PROXY_LOADING: {
      return {
        ...state,
        isProxyLoading: action.loading
      };
    }

    case REPORT_DETAILS_UPDATE_DETAILS: {
      return {
        ...state,
        completing: false,
        details: {...state.details, ...action.data},
      };
    }

    case REPORT_DETAILS_SHOW_IMPORT_ISSUES_MODAL: {
      return {...state, showImportIssuesModal: action.showModal}
    }

    case REPORT_DETAILS_ATTACHMENT_IS_DOWNLOADING: {
      return {
        ...state,
        isAttachmentDownloading: action.loading
      };
    }

    default:
      return state;
  }
}
