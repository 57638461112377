import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Row, Col} from 'react-bootstrap';
import SwitchAdvanced from '../../../../components/forms/SwitchAdvanced';

class ConfigModal extends Component {
  columns = [
    {id: 'not_started', text: 'Not Started'},
    {id: 'in_progress', text: 'In Progress'},
    {id: 'completed', text: 'Completed'}
  ];

  otherOptions = [
    {id: 'at_risk', text: 'At Risk'},
    {id: 'overdue', text: 'Missed Deadlines'}
  ];

  render() {
    const {show, columns, otherOptions, onColumnChange, onOtherOptionChange} = this.props;
    return (
      <StyledModal className={!show ? 'hidden' : null}>
        <Row>
          <Col xs={5} className="left">
            <h6>Select Columns to Show</h6>
            {this.columns.map(column => {
              return (
                <div className="switch-wrap" key={column.id}>
                  <SwitchAdvanced
                    name={column.id}
                    value={columns[column.id]}
                    onChange={onColumnChange}
                    handleDiameter={16.5}
                    height={18}
                    width={27.75}
                    offHandleColor={'#A4A4A4'}
                    offColor={'#FFFFFF'}
                    label={column.text}
                  />
                </div>
              )
            })}
          </Col>
          <Col xs={7} className="right">
            <h6>Other Options</h6>
            <span>View on Chart</span>
            {this.otherOptions.map(option => {
              return (
                <div className="switch-wrap" key={option.id}>
                  <SwitchAdvanced
                    name={option.id}
                    value={otherOptions[option.id]}
                    onChange={onOtherOptionChange}
                    handleDiameter={16.5}
                    height={18}
                    width={27.75}
                    offHandleColor={'#A4A4A4'}
                    offColor={'#EAEAEA'}
                    onColor={'#EAEAEA'}
                    label={option.text}
                  />
                </div>
              );
            })}
          </Col>
        </Row>
      </StyledModal>
    );
  }
}

const StyledModal = styled.div`
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  height: 210px;
  width: 436px;
  position: absolute;
  top: 50px;
  right: 30px;
  z-index: 1;
  
  .row {
    height: 100%;
    .left {
      background-color: #f7f7f7;
      height: 100%;
      padding: 21px 25px;
    }
    .right {
      padding: 21px 25px;
    }
  }
  
  h6 {
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 10px;
    line-height: 11px;
  }
  
  span {
    color: rgb(0, 0, 0, 0.5); 
    display: block;
    font-size: 10px;
    font-style: italic;
    line-height: 18px;
    margin-bottom: 14px;
  }
  
  .switch-wrap {
    .switch-adv {
      margin-right: 7px;
      margin-bottom: 14px;
      .switch-field {
        display: flex;
        align-items: center;
        .react-switch {
          .react-switch-bg {
            border: none;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
          }
          & + span {
            vertical-align: unset;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 11px;
            color: #000000;
            opacity: 0.5;
            margin: 0;
          }
          &.checked + span {
            opacity: 1;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    max-width: calc(100% - 25px);
    right: 15px;
  }
`;

ConfigModal.propTypes = {
  show: PropTypes.bool,
  columns: PropTypes.object.isRequired,
  otherOptions: PropTypes.object.isRequired
};

export default ConfigModal;