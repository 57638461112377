import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BlockHeader from '../BlockHeader';
import Loader from '../../../../components/Loader';
import Chart from './Chart';

class QcIssuesByType extends React.PureComponent {
  state = {
    period: 3
  };

  dropdownOptions = [
    {label: "This Year", value: 3},
    {label: "Past 60 days", value: 2},
    {label: "Past 30 days", value: 1}
  ];

  componentDidMount() {
    const {filters} = this.props;
    this.setState({
      period: filters.period
    });
  }

  onSelectChange = value => {
    const period = value.value;
    this.setState({period});
    this.props.getData(period);
  };

  render() {
    const {data, loading} = this.props;
    return (
      <StyledQcIssues className="box">
        {loading ? <Loader/> : null}
        <BlockHeader
          title="QC Issues by Type"
          dropDown = {{
            value: this.state.period,
            options: this.dropdownOptions,
            onChange: this.onSelectChange
          }}
        />
        <Chart data={data}/>
      </StyledQcIssues>
    );
  }
}

const StyledQcIssues = styled.div`
  @media (min-width: 992px) {
    .title {
      width: 72px;
    }
  }
`;

QcIssuesByType.propTypes = {
  filters: PropTypes.object,
  loading: PropTypes.bool,
  data: PropTypes.array,
  getData: PropTypes.func.isRequired
};

export default QcIssuesByType;
