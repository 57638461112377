import $ from 'jquery';
import config from '../../../../config';
import {getErrorMessage} from '../../../utils';
import {showError, showSuccess, showInfo} from '../../../layouts/actions';
import {changeTopFilters} from '../components/TopFilters/actions';
import {
  NonNumericFilters,
  QC_RESULT__PASS
} from './utils';

export const ARTWORK_REPORT_CLEAR = 'ARTWORK_REPORT_CLEAR';
export const ARTWORK_REPORT_TOGGLE_OPERATOR_MODE = 'ARTWORK_REPORT_TOGGLE_OPERATOR_MODE';
export const ARTWORK_REPORT_TOGGLE_FULL_SCREEN_MODE = 'ARTWORK_REPORT_TOGGLE_FULL_SCREEN_MODE';
export const ARTWORK_REPORT_TOGGLE_DARK_MODE = 'ARTWORK_REPORT_TOGGLE_DARK_MODE';
export const ARTWORK_REPORT_SET_PROJECTS_VIEW = 'ARTWORK_REPORT_SET_PROJECTS_VIEW';
export const ARTWORK_REPORT_SAVED_FILTER = 'ARTWORK_REPORT_SAVED_FILTER';
export const ARTWORK_REPORT_CHANGE_FILTER = 'ARTWORK_REPORT_CHANGE_FILTER';
export const ARTWORK_REPORT_CHANGE_FILTER_TYPE = 'ARTWORK_REPORT_CHANGE_FILTER_TYPE';
export const ARTWORK_REPORT_ADD_FILTERS = 'ARTWORK_REPORT_ADD_FILTERS';
export const ARTWORK_REPORT_REMOVE_FILTERS = 'ARTWORK_REPORT_REMOVE_FILTERS';
export const ARTWORK_REPORT_SET_LOADING = 'ARTWORK_REPORT_SET_LOADING';
export const ARTWORK_REPORT_SET_FILTERS = 'ARTWORK_REPORT_SET_FILTERS';
export const ARTWORK_REPORT_SET_PROJECTS = 'ARTWORK_REPORT_SET_PROJECTS';
export const ARTWORK_REPORT_UPDATE_PROJECT_ASSETS = 'ARTWORK_REPORT_UPDATE_PROJECT_ASSETS';
export const ARTWORK_REPORT_SET_DETAIL = 'ARTWORK_REPORT_SET_DETAIL';
export const ARTWORK_REPORT_UPDATE_DETAIL = 'ARTWORK_REPORT_UPDATE_DETAIL';
export const ARTWORK_REPORT_UPDATE_DETAIL_ISSUE = 'ARTWORK_REPORT_UPDATE_DETAIL_ISSUE';
export const ARTWORK_REPORT_ADD_DETAIL_ISSUE = 'ARTWORK_REPORT_ADD_DETAIL_ISSUE';
export const ARTWORK_REPORT_DELETE_DETAIL_ISSUE = 'ARTWORK_REPORT_DELETE_DETAIL_ISSUE';
export const ARTWORK_REPORT_TOGGLE_UPLOAD_NEW_VERSION_MODAL = 'ARTWORK_REPORT_TOGGLE_UPLOAD_NEW_VERSION_MODAL';
export const ARTWORK_REPORT_TOGGLE_ASSET_METADATA_MODAL = 'ARTWORK_REPORT_TOGGLE_ASSET_METADATA_MODAL';
export const ARTWORK_REPORT_TOGGLE_QC_INSTRUCTIONS_MODAL = 'ARTWORK_REPORT_TOGGLE_QC_INSTRUCTIONS_MODAL';
export const ARTWORK_REPORT_CHANGE_VIDEO_PARAMS = 'ARTWORK_REPORT_CHANGE_VIDEO_PARAMS';
export const ARTWORK_REPORT_TOGGLE_COMMENT_TO_VIEW = 'ARTWORK_REPORT_TOGGLE_COMMENT_TO_VIEW';

export const clearAll = () => ({type: ARTWORK_REPORT_CLEAR});

export const toggleOperatorMode = (modeIsOn) => ({type: ARTWORK_REPORT_TOGGLE_OPERATOR_MODE, modeIsOn});

export const toggleFullScreenMode = (modeIsOn) => ({type: ARTWORK_REPORT_TOGGLE_FULL_SCREEN_MODE, modeIsOn});

export const toggleDarkMode = (modeIsOn) => ({type: ARTWORK_REPORT_TOGGLE_DARK_MODE, modeIsOn});

export const setProjectsView = (view) => ({type: ARTWORK_REPORT_SET_PROJECTS_VIEW, view});

export const toggleCommentToView = (commentId) => ({type: ARTWORK_REPORT_TOGGLE_COMMENT_TO_VIEW, commentId});

export const getReportDetail = (report, onlyThumbnail = false) => dispatch => {
  const {asset_id} = report;
  const loadingName = 'getReportDetail';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${asset_id}`,
    data: {
      only_thumbnail: onlyThumbnail
    }
  })
  .done(res => {
    const {data, filters} = res;
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_REPORT_SET_DETAIL, data, filters});
    if (data && data.is_assigned && !data.is_viewed) {
      dispatch(markAssetAsViewed(asset_id));
      if (data.qc_instructions) {
        dispatch(showQcInstructions(data));
      }
    }
  })
  .fail(error => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    if (Object.keys(report).length > 1) {
      dispatch({type: ARTWORK_REPORT_SET_DETAIL, data: {...report}});
    }
    dispatch(showError(`Could not get report detail #${asset_id} data.  ${getErrorMessage(error)}`));
  });
};

export const resetReportDetail = () => ({type: ARTWORK_REPORT_SET_DETAIL, data: null});

export const getReportComments = (assetId) => dispatch => {
  const loadingName = 'getReportComments';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/comments`,
    data: {
      mark_as_read: true
    }
  })
  .done(res => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_REPORT_UPDATE_DETAIL, assetId, data: {has_new_comment: false, comments: res}});
  })
  .fail(error => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not get report comments.  ${getErrorMessage(error)}`));
  });
};

const loadAssetMetadata = asset => dispatch => {
  const {asset_id: assetId} = asset;
  const loadingName = 'loadingAssetMetadata';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/metadata`
  })
  .done(res => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_REPORT_UPDATE_DETAIL, assetId, data: {metadata: res}});
  })
  .fail(error => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not get asset metadata.  ${getErrorMessage(error)}`));
  });
};

export const showAssetMetadata = asset => dispatch => {
  const showPopup = asset => ({type: ARTWORK_REPORT_TOGGLE_ASSET_METADATA_MODAL, isShow: true, asset});
  const hasMetadata = metadata => metadata && Object.keys(metadata).length;
  if (hasMetadata(asset.metadata)) {
    dispatch(showPopup(asset));
  } else {
    dispatch(loadAssetMetadata(asset))
      .then(
        metadata => {
          if (hasMetadata(metadata)) {
            dispatch(showPopup({...asset, metadata}));
          } else {
            dispatch(showInfo('Metadata not found'));
          }
        }
      );
  }
};

export const hideAssetMetadata = () => ({type: ARTWORK_REPORT_TOGGLE_ASSET_METADATA_MODAL, isShow: false});

export const showQcInstructions = asset => ({type: ARTWORK_REPORT_TOGGLE_QC_INSTRUCTIONS_MODAL, isShow: true, asset});

export const hideQcInstructions = () => ({type: ARTWORK_REPORT_TOGGLE_QC_INSTRUCTIONS_MODAL, isShow: false});

const markAssetAsViewed = assetId => dispatch => {
  const loadingName = 'MarkAssetAsViewed';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/viewed`
  })
  .done(() => {
    dispatch({type: ARTWORK_REPORT_UPDATE_DETAIL, assetId, data: {is_viewed: true}});
  })
  .always(() => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
  });
};

export const toggleIsOverrideIssue = (assetId, issue) => dispatch => {
  const loadingName = 'toggleIsOverrideIssue';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  const isOverride = !issue.is_override;
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/issues/${issue.issue_id}/override`,
    data: {
      is_override: isOverride
    }
  })
  .done(res => {
    const {qc_status_id} = res;
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_REPORT_UPDATE_DETAIL_ISSUE, assetId, issue: {...issue, is_override: isOverride}});
    dispatch({type: ARTWORK_REPORT_UPDATE_DETAIL, assetId, data: {qc_status_id}});
  })
  .fail(error => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not override issue.  ${getErrorMessage(error)}`));
  });
};

export const addCommentToIssue = (assetId, issue, comment, parentCommentId) => dispatch => {
  const loadingName = 'addCommentToIssue';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/issues/${issue.issue_id}/comment`,
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({comment, parent_comment_id: parentCommentId || undefined})
  })
  .done(() => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_REPORT_UPDATE_DETAIL_ISSUE, assetId, issue: {...issue, comment_text: comment.map(({text}) => text).join('')}});
  })
  .fail(error => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not add a comment.  ${getErrorMessage(error)}`));
  });
};

export const deleteIssueComment = (assetId, issue) => dispatch => {
  const loadingName = 'DeleteIssueComment';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/issues/${issue.issue_id}/comment`,
    method: 'DELETE'
  })
  .done(() => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_REPORT_UPDATE_DETAIL_ISSUE, assetId, issue: {...issue, comment_text: null}});
  })
  .fail(error => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not delete a comment.  ${getErrorMessage(error)}`));
  });
};

export const addCommentToAsset = (assetId, comment, parentCommentId) => dispatch => {
  const loadingName = 'addCommentToAsset';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/comment`,
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({comment, parent_comment_id: parentCommentId || undefined})
  })
  .done(() => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(getReportComments(assetId));
  })
  .fail(error => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not add comment.  ${getErrorMessage(error)}`));
  });
};

export const getMentions = (assetId, commentType) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/mention`,
      data: {comment_type: commentType}
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
};

export const addCustomIssue = (assetId, qcResultId, parameterLabel, callback) => dispatch => {
  const loadingName = 'addCustomIssue';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  const issue = {
    param_label: parameterLabel,
    param_value: 'Custom',
    is_custom_issue: true,
    qc_result_id: qcResultId,
    is_override: qcResultId === QC_RESULT__PASS ? null : false,
  };
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/issue`,
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(issue)
  })
  .done(res => {
    callback && callback(res.issue_id);
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_REPORT_ADD_DETAIL_ISSUE, assetId, issue: {...issue, ...res}});
  })
  .fail(error => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not add custom parameter.  ${getErrorMessage(error)}`));
  });
};

export const deleteCustomIssue = (assetId, issueId, callback) => dispatch => {
  const loadingName = 'deleteCustomIssue';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/issues/${issueId}`,
    method: 'DELETE'
  })
  .done(() => {
    callback && callback();
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_REPORT_DELETE_DETAIL_ISSUE, assetId, issueId});
  })
  .fail(error => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not delete custom parameter.  ${getErrorMessage(error)}`));
  });
};

export const updateCustomIssue = (assetId, issue) => dispatch => {
  const loadingName = 'updateCustomIssue';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/issues/${issue.issue_id}`,
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(issue)
  })
  .done(() => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_REPORT_UPDATE_DETAIL_ISSUE, assetId, issue});
  })
  .fail(error => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not update custom parameter.  ${getErrorMessage(error)}`));
  });
};

export const updateIssueTimecode = (assetId, issue, timecode) => dispatch => {
  const loadingName = 'updateIssueTimecode';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/issues/${issue.issue_id}/timecode`,
    method: 'POST',
    data: timecode
  })
  .done(res => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_REPORT_UPDATE_DETAIL_ISSUE, assetId, issue: {...issue, ...res}});
  })
  .fail(error => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not update time codes.  ${getErrorMessage(error)}`));
  });
};

const getValidFilter = (allFilters, filter, value) => {
  const allowedFilters = allFilters ? Object.keys(allFilters) : [];
  const isExist = allowedFilters.includes(filter);

  if (isExist) {
    const isNonNumericFilter = NonNumericFilters.includes(filter);

    const existFilter = allFilters[filter] || {};

    const existFilterValues = existFilter ? existFilter.map(f => f.value) : [];
    return Array.isArray(value) ?
      value.filter(item => existFilterValues.includes(!isNonNumericFilter && !isNaN(+item) ? +item : item)).map(item => (!isNonNumericFilter && !isNaN(+item) ? +item : item)) :
      null;
  }

  return null;
};

export const getValidFilters = (allFilters, urlFilters) => {
  const res = {};

  const filters = {...urlFilters};
  delete filters.tab;
  delete filters.viewTab;

  filters && Object.keys(filters).forEach(key => {

    if (key === 'sort') {
      res[key] = {
        field: filters[key],
        direction: filters.sortDirect === 'desc' ? 'desc' : 'asc'
      }
    } else if (key !== 'search') {
      let validFilter = getValidFilter(allFilters, key, filters[key]);
      if (!validFilter) {
        const othFilters = {};
        allFilters.filters.forEach(item => {
          othFilters[item.name] = item.values;
        });
        validFilter = getValidFilter(othFilters, key, filters[key]);
      }
      if (validFilter) {
        res[key] = validFilter;
      }
    } else {
      res[key] = filters[key];
    }
  });

  return res;
};

export const getValidFiltersForInitSearch = (urlFilters) => {
  const res = {};

  const filters = {...urlFilters};
  delete filters.tab;
  delete filters.viewTab;

  filters && Object.keys(filters).forEach(key => {

    if (key === 'sort') {
      res[key] = {
        field: filters[key],
        direction: (filters.sortDirect || filters.sortdirect) === 'desc' ? 'desc' : 'asc'
      }
    } else if (!['sortDirect', 'sortdirect'].includes(key)) {
      res[key] = filters[key];
    }
  });

  return res;
};

const DASHBOARD_FILTERS_ARR = [
  'from', 'to', 'state', 'period', 'date_type',
];

export const prepareSingleFiltersParams = filters => {
  const preparedFilters = {...filters};
  DASHBOARD_FILTERS_ARR.forEach(key => {
    if (Array.isArray(preparedFilters[key])) {
      preparedFilters[key] = preparedFilters[key].length > 0 ? preparedFilters[key][0] : null;
    }
  });
  return preparedFilters;
};

export const savedAllLeftFilters = urlFilters => ({type: ARTWORK_REPORT_SAVED_FILTER, urlFilters});

export const savedState = (history, selectedFilters, localStorageItemName) => {
  const filters = {};
  selectedFilters.forEach(filter => {
    if ((Array.isArray(filter.value) && filter.value.length) || filter.value) {
      filters[filter.name] = filter.value;
      if (filter.isStudioParameter) {
        filters.__parameter_names = [...filters.__parameter_names || [], filter.name];
      }
    }
    if (filter.name === 'sort') {
      filters[filter.name] = filter.value.field;
      filters.sortDirect = filter.value.direction;
    }
  });

  [...DASHBOARD_FILTERS_ARR/*, 'qc_status'*/].forEach(key => {
    delete filters[key];
  });
  if (window.location.search.includes('qc_status')) {
    delete filters.qc_status;
  }
  localStorage.setItem(localStorageItemName || 'artworkReportFilters', JSON.stringify(filters));
};

export const prepareFiltersParams = (filters, studioParameterNames) => {
  if (!filters) {
    return {};
  }

  const filtersObj = {};
  filters.forEach(filter => {
    const isStudioParameter = Boolean(filter.isStudioParameter || (Array.isArray(studioParameterNames) && studioParameterNames.includes(filter.name)));
    const name = isStudioParameter ? filter.name : filter.name.replace(/ /g, '_').toLowerCase();
    //filtersObj[name] = filter.value;
    if (isStudioParameter) {
      if (filter.value && filter.value.length) {
        filtersObj.parameters = {...filtersObj.parameters, [name]: filter.value};
      }
    } else {
      filtersObj[name] = filter.value;
    }
  });

  delete filtersObj.__parameter_names;

  if (filtersObj.sort) {
    const {field, direction} = filtersObj.sort;
    filtersObj.field = field ? field.toLowerCase() : null;
    filtersObj.direction = direction;
    delete filtersObj.sort;
  }

  return prepareSingleFiltersParams(filtersObj);
};

export const getProjects = (history, topFilters, startedFilters, parameterNames) => (dispatch, getState) => {
  const state = getState();

  !startedFilters && savedState(history, state.artworkReport.selectedFilters);

  if (!topFilters) {
    topFilters = state.artworkTopFilters.values;
  }
  localStorage.setItem('artworkReportTopFilters', JSON.stringify(topFilters));
  !!startedFilters && dispatch(changeTopFilters(topFilters));

  const filtersObj = {
    ...prepareFiltersParams(state.artworkReport.selectedFilters, parameterNames),
    title_id: topFilters.titleId,
    project_id: topFilters.projectId,
    amazon_series_id: topFilters.amazonSeriesId,
    amazon_concept_name: topFilters.amazonConceptName,
    amazon_asset_type: topFilters.amazonAssetType,
  };

  const loadingName = 'projects';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/projects`,
    data: {
      filters: JSON.stringify(filtersObj)
    }
  })
  .done(res => {
    let {data, total} = res.projects;
    dispatch({type: ARTWORK_REPORT_SET_PROJECTS, data, total});
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(changeTopFilters(topFilters));
  })
  .fail(() => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
  });
};

export const getProjectAssets = (project, isLoadMore, searchString) => (dispatch, getState) => {
  const state = getState();
  const filtersObj = {
    ...prepareFiltersParams(state.artworkReport.selectedFilters),
    title_id: state.artworkReport.titleId,
    project_id: state.artworkReport.projectId,
  };
  const start = isLoadMore ? project.assets.data.length : 0;
  if (isLoadMore) {
    searchString = project.assets.search;
  }
  if (searchString && (!filtersObj.search || !filtersObj.search.toLowerCase().includes(searchString.toLowerCase()))) {
    filtersObj.search = (filtersObj.search ? filtersObj.search + ' ' : '') + searchString;
  }

  const loadingName = 'getProjectAssets';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/projects/${project.project_id}/assets`,
    data: {
      start,
      limit: config.pagingSize,
      filters: JSON.stringify(filtersObj)
    }
  })
  .done(res => {
    let {data, total} = res.assets;
    if (isLoadMore) {
      data = [...project.assets.data, ...data];
      total = total || project.assets.total;
    }
    dispatch({type: ARTWORK_REPORT_UPDATE_PROJECT_ASSETS, project, assets: {data, total, search: searchString}});
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
  })
  .fail(() => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
  });
};

export const changeFilter = (history, name, value) => dispatch => {
  dispatch({type: ARTWORK_REPORT_CHANGE_FILTER, name, value});
  return dispatch(getProjects(history));
};

export const changeFilterType = (history, filter, newSelectedFilter) => dispatch => {
  dispatch({type: ARTWORK_REPORT_CHANGE_FILTER_TYPE, filter, newSelectedFilter});
  return dispatch(getProjects(history));
};

export const addFilter = filter => ({type: ARTWORK_REPORT_ADD_FILTERS, filter});

export const removeFilter = filter => ({type: ARTWORK_REPORT_REMOVE_FILTERS, filter});

export const getFilters = (history, storedReportFilters, srcFilters) => (dispatch, getState) => {
  const loadingName = 'filters';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/filters`,
    data: {
      filters: JSON.stringify(prepareSingleFiltersParams(storedReportFilters))
    }
  })
    .then(res => {
      const validUrlFilters = getValidFilters(res, srcFilters);
      const urlParams = {};
      const someFilters = {};
      Object.keys(validUrlFilters).forEach(filter => {
        if (['search', 'sort'].includes(filter)) {
          urlParams[filter] = validUrlFilters[filter];
        } else {
          someFilters[filter] = validUrlFilters[filter];
        }
      });
      dispatch(savedAllLeftFilters(urlParams));
      return {allowedFilters: res.filters, someFilters};
    })
    .then(({allowedFilters, someFilters}) => {
      dispatch({type: ARTWORK_REPORT_SET_FILTERS, allowedFilters});

      const someFiltersKeys = Object.keys(someFilters).sort((a, b) => {
        if (DASHBOARD_FILTERS_ARR.includes(a)) {
          if (a === 'to' && b === 'from') {
            return 1;
          }
          return -1;
        }
        if (DASHBOARD_FILTERS_ARR.includes(b)) {
          return 1;
        }
        return a.localeCompare(b);
      });
      someFiltersKeys.forEach(filterName => {
        const filter = allowedFilters.find(item => item.name === filterName);
        if (filter) {
          const initFilterValue = someFilters[filterName];
          if (filter.defaultValue && initFilterValue && initFilterValue.length === 1 && initFilterValue[0] === filter.defaultValue) {
            return;
          }
          dispatch(addFilter({
            name: filter.name,
            label: filter.title,
            type: filter.type,
            multi: filter.multi,
            forFilterName: filter.forFilterName,
            hasAdditionalFilters: filter.hasAdditionalFilters,
            isStudioParameter: filter.isStudioParameter,
            studioID: filter.studioID,
            isOrderedValues: filter.isOrderedValues,
            orderNum: filter.orderNum,
            defaultValue: someFilters[filterName],
            disabled: true
          }));
        }
      });

      const requiredFilters = allowedFilters.filter(filter => filter.required);
      requiredFilters.forEach(filter => {
        if (true || !Object.keys(someFilters).includes(filter.name)) {
          dispatch(addFilter({
            name: filter.name,
            label: filter.title,
            type: filter.type,
            multi: filter.multi,
            forFilterName: filter.forFilterName,
            hasAdditionalFilters: filter.hasAdditionalFilters,
            isStudioParameter: filter.isStudioParameter,
            studioID: filter.studioID,
            isOrderedValues: filter.isOrderedValues,
            orderNum: filter.orderNum,
            defaultValue: filter.defaultValue,
            disabled: true,
            required: true
          }));
        }
      });

      dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    })
    .then(() => ({selectedFilters: getState().artworkReport.selectedFilters, allowedFilters: getState().artworkReport.allowedFilters}))
    .then(({selectedFilters, allowedFilters}) => {
      const additionalFilters = allowedFilters.filter(filter => filter.forFilterName);
      additionalFilters.forEach(filter => {
        const selMainFilter = selectedFilters.find(f => f.name === filter.forFilterName);
        if (!selMainFilter) {
          dispatch(removeFilter(filter));
        } else if (selMainFilter.value && (Array.isArray(selMainFilter.value) ? selMainFilter.value.length && selMainFilter.value[0] : selMainFilter.value)) {
          dispatch(addFilter({...filter, disabled: true}));
        }
      });
    })
    .then(() => getState().artworkReport.selectedFilters)
    .then(selectedFilters => savedState(history, selectedFilters))
    .catch(() => {
      dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    });
};

export const changeAssetQcStatus = (assetId, qcStatusId) => dispatch => {
  const loadingName = 'changeAssetQcStatus';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/qc_status`,
    method: 'PUT',
    data: {
      qc_status_id: qcStatusId
    }
  })
  .done(res => {
    const {qc_status_id} = res;
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_REPORT_UPDATE_DETAIL, assetId, data: {qc_status_id}});
  })
  .fail(error => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not update status.  ${getErrorMessage(error)}`));
  });
};

export const showUploadNewVersionModal = () => ({type: ARTWORK_REPORT_TOGGLE_UPLOAD_NEW_VERSION_MODAL, isShow: true});
export const closeUploadNewVersionModal = () => ({type: ARTWORK_REPORT_TOGGLE_UPLOAD_NEW_VERSION_MODAL, isShow: false});

export const uploadNewVersion = (history, assetId, file, deliverableFormat, isCreateWorkOrder) => dispatch => {
  const loadingName = 'uploadNewVersion';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  const data = new FormData();
  if (file) {
    data.append('file', file);
  }
  if (deliverableFormat) {
    data.append('deliverable_format', deliverableFormat);
  }
  data.append('is_create_work_order', isCreateWorkOrder);
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/new_version`,
    method: 'POST',
    data,
    cache: false,
    processData: false,
    contentType: false
  })
  .done(replacementAssetId => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(getReportDetail({asset_id: replacementAssetId}, true));
    dispatch(getProjects(history));
  })
  .fail(error => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not upload a new version.  ${getErrorMessage(error)}`));
  });
};

export const changeVideoParams = data => dispatch => {
  dispatch({type: ARTWORK_REPORT_CHANGE_VIDEO_PARAMS, data});
};

export const getProxy = () => (dispatch, getState) => {
  const assetId = (getState().artworkReport.reportDetail || {}).asset_id;
  if (!assetId) {
    return;
  }
  const loadingName = 'getProxy';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${assetId}/secure_proxy`
  })
  .done(res => {
    const {reportDetail} = getState().artworkReport;
    if (+assetId === (reportDetail || {}).asset_id) {
      const data = {...res};
      const {isPendingRequestProxy} = reportDetail;
      if (isPendingRequestProxy) {
        if (data.request_proxy_status === 'none') {
          data.request_proxy_status = 'pending';
        } else {
          data.isPendingRequestProxy = undefined;
        }
      }
      dispatch({type: ARTWORK_REPORT_SET_DETAIL, data: {...reportDetail, ...data, asset_file_url: data.request_proxy}});
    }
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
  })
  .fail(error => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not get a secure proxy info.`));
  });
};

export const generateProxy = assetId => (dispatch, getState) => {
  const loadingName = 'generateProxy';
  dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${assetId}/secure_proxy`
  })
  .done(() => {
    const {reportDetail} = getState().artworkReport;
    if (+assetId === (reportDetail || {}).asset_id) {
      dispatch({type: ARTWORK_REPORT_SET_DETAIL, data: {...reportDetail, request_proxy_status: 'pending', isPendingRequestProxy: true}});
    }
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(showSuccess('Your watermarked proxy video is being prepared.'));
  })
  .fail(error => {
    dispatch({type: ARTWORK_REPORT_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not generate a secure proxy. Please contact qodsupport@eurofins-dms.com for assistance`));
  });
};
