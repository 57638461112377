import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NoData from '../NoData';
import {PieChart, Pie, Cell, Sector} from 'recharts';
import arrowImg from '../../../../../assets/images/dashboard/pie-chart-arrow.svg';

class PieChartComponent extends Component {
  renderActiveShape = props => {
    const {cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, index} = props;
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={index === 0 ? innerRadius : innerRadius + 8}
          outerRadius={index === 0 ? outerRadius : outerRadius - 8}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  render() {
    const {percentage} = this.props;

    if ([undefined, null].includes(percentage)) {
      return <NoData/>;
    }

    const values = [
      {index: 0, color: '#2DBF74', value: percentage},
      {index: 1, color: '#DADADA', value: 100 - percentage}
    ];

    return (
      <StyledPieChart
        width={206}
        height={204}
      >
        <Pie
          activeIndex={[0, 1]}
          activeShape={this.renderActiveShape}
          data={values}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={55}
          outerRadius={90}
          startAngle={-253}
        >
          {
            values.map((entry, index) =>
              <Cell
                key={index}
                fill={entry.color}
              />
            )
          }
        </Pie>
        <image x="95" y="83" href={arrowImg} />
        <text className="percentage" x="102" y="122">
          {percentage}%
        </text>
      </StyledPieChart>
    );
  }
}

PieChartComponent.propTypes = {
  percentage: PropTypes.number
};

const StyledPieChart = styled(PieChart)`
  margin: 18px auto 0;
  
  .percentage {
    font-size: 24px;
    text-anchor: middle;
  }
`;

export default PieChartComponent;
