import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import config from '../../../../config';

class ResetForm extends Component {
  render() {
    const {isCreatingPassword} = this.props;
    return (
      <div>
        <p>
          <label>Password</label>
          <span className="text-icon">
            <i className="fa fa-lock"/>
          </span>
          <input
            type="password"
            name="forgotPassword"
            placeholder=""
            defaultValue=""
            autoComplete="off"
            onChange={this.props.onChange}
            />
        </p>
        <p>
          <label>Confirm Password</label>
          <span className="text-icon">
            <i className="fa fa-lock"/>
          </span>
          <input
            type="password"
            name="forgotConfirm"
            placeholder=""
            autoComplete="off"
            onChange={this.props.onChange}
            />
        </p>
        <p>
          <input
            type="submit"
            name=""
            value={`${isCreatingPassword ? 'Create' : 'Update'} password`}
            onClick={this.props.onReset}
          />
        </p>
        <div className="forgot">
          <Link to="/login" onClick={this.props.onHideMessage}>sign in</Link>
        </div>
        <br/>
        <p className="pwd-instructions">
            Your password must include 3 of the following 4: lowercase, uppercase, numerals, and special characters <br/>and must be at least {config.passwordMinLength} characters long.
        </p>
      </div>
    );
  }
}

ResetForm.propTypes = {
  isCreatingPassword: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onHideMessage: PropTypes.func.isRequired
};

export default ResetForm;
