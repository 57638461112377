import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputAdvanced from '../../../../components/forms/InputAdvanced';
import {Button, ButtonToolbar, Row, Col, ControlLabel, FormGroup} from 'react-bootstrap';
import RuntimeInput from '../forms/RuntimeInput';
import {showRuntimeTextlessField} from '../../../../utils';

class MediaDetails extends Component {
  forceResetValues = false;
  resetValues = false;
  resetRuntimeValues = false;

  componentDidMount() {
    const {season} = this.props.data;
    if (season.episodes && season.episodes.length !== season.total_episodes) {
      this.changeRuntimesNumber();
    }
  }

  changeRuntimesNumber() {
    const {data, onChange} = this.props;
    const {season} = data;
    while (season.total_episodes < season.episodes.length) {
      season.episodes.pop();
    }
    while (season.total_episodes > season.episodes.length) {
      this.addNewEpisodeRuntime(season.episodes);
    }
    onChange('season', season);
  }

  addNewEpisodeRuntime(episodes) {
    let prevEpNum;
    if (episodes.length > 0) {
      prevEpNum = episodes[episodes.length - 1].episode_number;
      if (typeof prevEpNum === 'string' && prevEpNum.match(/^\d+$/)) {
        prevEpNum = Number(prevEpNum);
      }
      if (typeof prevEpNum !== 'number') {
        prevEpNum = null;
      }
    } else {
      prevEpNum = null;
    }
    if (prevEpNum === null) {
      prevEpNum = 0;
    }
    const epNum = this.buildEpisodeNumber({episode_number: `${prevEpNum + 1}`});
    const runtime = episodes.length ? episodes[episodes.length - 1].runtime_in_sec : 0;
    let runtimeTextless;
    if (showRuntimeTextlessField(this.props.data.video_format)) {
      runtimeTextless = episodes.length ? episodes[episodes.length - 1].runtime_in_sec__textless : 0;
    }
    episodes.push({
      episode_number: epNum,
      episode_name: null,
      runtime_in_sec: runtime,
      runtime_in_sec__textless: runtimeTextless,
      created: Date.now()
    });
  }

  prepareSeasonNum = () => {
    const {season_number} = this.props.data.season;
    if (typeof season_number === 'string' && season_number.match(/^\d+$/)) {
      return Number(season_number);
    }
    return season_number;
  };

  buildEpisodeNumber({episode_number}) {
    const seasonNum = this.prepareSeasonNum();
    let epNum = episode_number;
    if (epNum && epNum.match(/^\d+$/) && typeof seasonNum === 'number' && Number(epNum) < (seasonNum * 100)) {
      epNum = `${seasonNum}${Number(epNum) < 10 ? '0' : ''}${epNum}`;
    }
    return epNum;
  }

  handleFieldValueChanged = (name, value) => {
    const {data, onChange} = this.props;
    const {season} = data;
    const seasonNum = this.prepareSeasonNum();
    if (value === '') {
      value = null;
    }

    if (typeof value === 'string' && value.match(/^\d+$/)) {
      value = Number(value);
    }

    this.resetValues = false;

    if (name === 'total_episodes' && (value === null || value < 1)) {
      value = season.total_episodes;
      this.resetValues = true;
    } else if (name === 'season_number' && value === null) {
      value = seasonNum;
      this.resetValues = true;
    } else {
      season[name] = value;
      onChange('season', season);

      if (name === 'total_episodes' && value !== season.episodes.length) {
        this.changeRuntimesNumber();
        this.resetValues = true;
      } else if (name === 'season_number') {
        onChange('season_name', String(data.season_name).replace(String(seasonNum), String(value)));
        if (typeof value === 'number' && typeof seasonNum === 'number') {
          const regExp = new RegExp(`^${seasonNum}`);
          season.episodes.forEach(episode => {
            episode.episode_number = episode.episode_number.replace(regExp, String(value));
          });
          onChange('season', season);
          this.resetValues = true;
        } else if (typeof value === 'number') {
          const regExp = new RegExp(`^${value}[0-9]{2}$`);
          if (!season.episodes.every(e => String(e.episode_number).match(regExp))) {
            season.episodes.forEach(episode => {
              episode.episode_number = this.buildEpisodeNumber(episode);
            });
            onChange('season', season);
            this.resetValues = true;
          }
        } else if (typeof seasonNum === 'number') {
          const regExp = new RegExp(`^${seasonNum}`);
          season.episodes.forEach(episode => {
            episode.episode_number = episode.episode_number.replace(regExp, '');
            if (episode.episode_number.match(/^\d+$/)) {
              episode.episode_number = String(Number(episode.episode_number));
            }
          });
          onChange('season', season);
          this.resetValues = true;
        }
      }
    }

    if (this.resetValues) {
      this.forceUpdate();
    }
  };

  handleRuntimeChanged = (name, value, isBlur = false) => {
    const {data, onChange} = this.props;
    const {season} = data;
    if (value === '') {
      value = null;
    }
    this.resetRuntimeValues = false;
    this.resetValues = value === null && !name.startsWith('episode_name-') && !name.startsWith('runtime_in_sec');
    this.forceResetValues = this.resetValues && isBlur;
    if (this.resetValues) {
      this.forceUpdate();
    } else {
      const runtimeItem = /(episode_number|episode_name|runtime_in_sec|runtime_in_sec__textless)-(\d+)/.exec(name);
      const propField = runtimeItem[1];
      const propIndex = Number(runtimeItem[2]);
      const isChangedValue = season.episodes[propIndex][propField] !== value;
      season.episodes[propIndex][propField] = value;
      if (['runtime_in_sec', 'runtime_in_sec__textless'].includes(propField) && isChangedValue) {
        delete season.episodes[propIndex].created;
        for (let i = propIndex + 1; i < season.episodes.length; i++) {
          if (season.episodes[i].created) {
            season.episodes[i][propField] = value;
            this.resetRuntimeValues = true;
          }
        }
      }
      onChange('season', season);
      if (this.resetRuntimeValues) {
        this.forceUpdate();
      }
    }
  };

  handleRuntimesNumberChanged = (index, added) => {
    const {data, onChange} = this.props;
    const {season} = data;
    if (added) {
      this.addNewEpisodeRuntime(season.episodes);
    } else {
      season.episodes.splice(index, 1);
    }
    season.total_episodes = season.episodes.length;
    onChange('season', season);
    this.resetValues = true;
    this.forceUpdate();
  };

  renderRuntimes = () => {
    const {data, isSavingDetails, disabled, seasonStatus} = this.props;
    const isDisabled = disabled === true;
    const {season} = data;
    return (
      <Row className="episode-runtime-row">
        <Col md={12} xs={12}>
          <div className="episode-runtimes">
            {
              season.episodes && season.episodes.map((runtime, i) => (
                <Row className={`episode-runtime-item i-${i}`} key={`episode-runtime-item-${i}`}>
                  <Col md={3} xs={12} className="runtimes-label">
                    <ControlLabel className="runtimes">Runtimes</ControlLabel>
                  </Col>
                  <Col md={9} xs={12} className="episode-info">
                    <div>
                      <div className="episode-number">
                        <InputAdvanced
                          label="Episode #"
                          labelSeparator=""
                          name={`episode_number-${i}`}
                          reset={this.resetValues}
                          forceReset={this.forceResetValues || !!seasonStatus}
                          value={runtime.episode_number}
                          onBlur={(name, value) => this.handleRuntimeChanged(name, value, true)}
                          onChange={this.handleRuntimeChanged}
                          disabled={runtime.episode_request_id > 0 || isDisabled}
                          title={runtime.episode_request_id > 0 ? 'This episode has a linked request' : undefined}
                          />
                      </div>
                      <div className="episode-name">
                        {isSavingDetails ?
                          <FormGroup>
                            <ControlLabel>Episode Name</ControlLabel>
                            <input
                              className="form-control"
                              name={`episode_name-${i}`}
                              defaultValue={runtime.episode_name || ''}
                              disabled={isDisabled}
                            />
                          </FormGroup> :
                        <InputAdvanced
                          label="Episode Name"
                          labelSeparator=""
                          name={`episode_name-${i}`}
                          reset={this.resetValues}
                          forceReset={this.forceResetValues || !!seasonStatus}
                          value={runtime.episode_name}
                          maxLength={255}
                          onBlur={(name, value) => this.handleRuntimeChanged(name, value, true)}
                          onChange={this.handleRuntimeChanged}
                          disabled={isDisabled}
                          />
                        }
                      </div>
                      <div className="episode-runtime">
                        <RuntimeInput
                          name={`runtime_in_sec-${i}`}
                          value={runtime.runtime_in_sec}
                          reset={this.resetRuntimeValues}
                          onChange={this.handleRuntimeChanged}
                          disabled={isDisabled}
                          />
                      </div>
                      {
                        showRuntimeTextlessField(data.video_format) ?
                          <div className="episode-runtime">
                            <RuntimeInput
                              label="Runtime Textless"
                              name={`runtime_in_sec__textless-${i}`}
                              value={runtime.runtime_in_sec__textless}
                              reset={this.resetRuntimeValues}
                              onChange={this.handleRuntimeChanged}
                              disabled={isDisabled}
                              />
                          </div> : null
                      }
                      <div>
                        <ButtonToolbar
                          className={runtime.episode_request_id > 0 ? `view-episode-request${runtime.episode_wo_id ? ' e-with-wo' : ''}` : undefined}
                        >
                          {
                            runtime.episode_request_id > 0 ?
                              <div title="This episode has a linked request">
                                <a href={`/requests/details/${runtime.episode_request_id}`}>View Episode Request</a>
                                {runtime.episode_wo_id &&
                                  <div title="There is a work order for this episode">
                                    {`Work Order ID: ${runtime.episode_wo_id}`}
                                  </div>
                                }
                              </div> :
                              <Button
                                bsStyle="default"
                                className={runtime.episode_request_id < 0 ? 'hidden-delete-btn' : undefined}
                                title={runtime.episode_request_id < 0 ? 'We don\'t delete records from DB' : 'Remove Episode'}
                                onClick={() => this.handleRuntimesNumberChanged(i)}
                                disabled={(season.episodes.length === 1 && !runtime.episode_request_id) || runtime.episode_request_id < 0 || isDisabled}
                              >
                                <span className="icon-trash"/>
                              </Button>
                          }
                        </ButtonToolbar>
                      </div>
                    </div>
                    <hr/>
                  </Col>
                </Row>
              ))
            }
          </div>
        </Col>
      </Row>
    );
  };

  render() {
    const {data, filters, seasonRequestID, disabled, seasonStatus} = this.props;
    const isDisabled = disabled === true;
    const isExistsSeason =
      ((filters.series.find(s => s.series_id === data.series_id) || {}).seasons || []).includes(data.season_name);
    let episodeCount = 1;
    if (data.season.episodes) {
      episodeCount = data.season.episodes.filter(i => Boolean(i.episode_request_id)).length;
    }

    return (
      <StyledMediaDetails className="step-body-wrapper media-details">
        <Row>
          <Col md={12} xs={12}>
            <Row>
              <Col md={6} xs={6} className="season-number" title="Click to edit">
                {
                  seasonRequestID ?
                    <FormGroup>
                      <ControlLabel>Season #</ControlLabel>
                      <a
                        className="form-control"
                        href={`/requests/details/${seasonRequestID}`}
                        title="View Season Request"
                      >
                        {data.season.season_number}
                      </a>
                    </FormGroup> :
                      <InputAdvanced
                        label="Season #"
                        labelSeparator=""
                        name="season_number"
                        forceReset={this.resetValues}
                        value={data.season.season_number}
                        onBlur={this.handleFieldValueChanged}
                        disabled={isExistsSeason || isDisabled}
                      />
                }
              </Col>
              <Col md={6} xs={6} className="total-episodes">
                <InputAdvanced
                  label="Total Episodes"
                  labelSeparator=""
                  name="total_episodes"
                  forceReset={this.resetValues || !!seasonStatus}
                  value={data.season.total_episodes}
                  onBlur={this.handleFieldValueChanged}
                  onChange={this.handleFieldValueChanged}
                  type="number"
                  min={episodeCount}
                  disabled={isDisabled}
                  />
              </Col>
            </Row>
            {seasonStatus}
            {this.renderRuntimes()}
          </Col>
        </Row>
      </StyledMediaDetails>
    );
  }
}

const StyledMediaDetails = styled.div`
  .new-request .new-request-wrapper &, .season-details-view & {
    &.media-details {
      margin-top: 37px;

      * {
        font-size: 13px;
        line-height: 14px;
        color: #000000;
      }

      .form-group {
        margin-bottom: 0;

        label {
          font-weight: 700;
        }

        .form-control {
          width: 107px;
          height: 39px;
        }
      }

      label.control-label.runtimes {
        font-size: 13px;
        line-height: 14px;
        color: #000000;
        font-weight: 700;
        vertical-align: top;
      }

      .episode-number, .episode-name, .episode-runtime {

        label {
          font-weight: 400;
        }
      }

      .episode-runtime-item:not(.i-0) label.control-label {
        display: none;
      }

      .season-number {

        .form-control {
          font-weight: 700;
          font-size: 17px;
          line-height: 18px;
        }
      }

      .btn-toolbar {
        float: right;
        margin-top: 0;

        .icon-trash {
          font-size: 22px;
          line-height: 38px;
          color: #000;
          opacity: 0.8;

          &:hover {
            opacity: 1;
          }
        }

        &.view-episode-request {
          margin-top: 10px;
          &.e-with-wo {
            margin-top: 5px;
          }
          a {
            font-weight: bold;
            color: #29A4CB;
            & + div {
              margin-top: 5px;
              font-size: 11px;
              cursor: default;
            }
          }
        }

        .hidden-delete-btn {
          visibility: hidden;
        }
      }

      .episode-runtime-item.i-0 .btn-toolbar {
        margin-top: 20px;
        &.view-episode-request {
          margin-top: 30px;
          &.e-with-wo {
            margin-top: 25px;
          }
        }
      }

      .episode-runtimes {
        background-color: #f9f9f9;
        padding: 22px 41px 0 20px;
        border-radius: 5px;

        hr {
          border-top: 1px solid #e5e5e5;
          margin: 6px 0;
        }

        .episode-runtime-item:last-child hr {
          border-color: transparent;
        }

        .episode-info {
          > div {
            display: flex;
            align-items: flex-start;
          }
        }
      }

      .episode-runtime-row {
        margin-top: 39px;
      }
    }
    
    @media (min-width: 992px) {
      &.media-details {
        .season-number.col-md-6 {
          width: 200px;
        }
        .runtimes-label.col-md-3 {
          width: 180px;
        }
        .episode-info.col-md-9 {
          width: calc(100% - 180px);
        }
        .form-group {
          margin-right: 18px;
          .form-control {
            width: 107px;
          }
        }
        .episode-name .form-group .form-control {
          width: 250px;
        }
        .btn-toolbar {
          margin-right: 35px;
          margin-left: 75px;
          &.view-episode-request {
            margin-left: 25px;
          }
        }
        .episode-runtime-row {
          .col-md-12 {
            width: auto;
          }
        }
      }
    }
  }
`;

MediaDetails.propTypes = {
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default MediaDetails;
