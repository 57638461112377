import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../components/Loader';
import Table from '../../../components/table/CollapsedTable';
import {TimeCodeCell} from './table/TimeCodeCell';
import {DurationCell} from './table/DurationCell';
import {SeverityCell} from './table/SeverityCell';
import {StatusCell} from './table/StatusCell';
import {getSortedRowsBy} from '../../../utils';
import StyledMobileTable from './IssueTableStyledMobile';
import styled from 'styled-components';
import CollapsedRow from './table/CollapsedRow';
import ArrowCell from './table/ArrowCell';
import {ActionsCell} from './table/ActionsCell';

class ReplacementIssueTable extends Component {
  handleSelectStatus = (issue, status) => {
    const {onSelectStatus} = this.props;
    onSelectStatus(issue.IssueID, status);
  };

  handleClickIssueImage = issue => {
    const {onClickImage} = this.props;
    onClickImage && onClickImage(issue);
  };

  handleClickDiscussIssue = issue => {
    const {onClickDiscuss} = this.props;
    onClickDiscuss && onClickDiscuss(issue);
  };

  handleClickFixNotesIssue = issue => {
    const {onClickFixNotes} = this.props;
    onClickFixNotes && onClickFixNotes(issue);
  };

  handleClickViewIssue = issue => {
    const {onClickView} = this.props;
    onClickView && onClickView(issue);
  };

  state = {
    showCollapsedRowId: null,
    tableSort: {}
  };

  sortAltFields = {
    Timecode: 'TimeCodeStart',
    Severity: 'SeverityPriority'
  };

  headers = {
    Timecode: {
      title: 'Timeсode',
      component: TimeCodeCell
    },
    Duration: {
      title: 'Duration',
      component: DurationCell
    },
    LocationAndChannel: {
      title: 'Location',
      className: 'location'
    },
    Category: {
      title: 'Category',
      className: 'category capitalized-all'
    },
    IssueType: {
      title: 'Type',
      className: 'type capitalized-all'
    },
    Description: {
      title: 'Description',
      className: 'description capitalized-all'
    },
    Severity: {
      title: 'Severity',
      className: 'severity',
      component: SeverityCell
    },
    Status: {
      title: 'Select Reaction',
      className: 'status',
      component: StatusCell,
      componentArgs: {
        onSelect: this.handleSelectStatus,
      }
    },
    actions: {
      title: '',
      className: 'actions',
      component: ActionsCell,
      componentArgs: {
        onClickDiscuss: this.handleClickDiscussIssue,
        onClickImage: this.handleClickIssueImage
      }
    },
    rowArrow: {
      title: '',
      className: 'arrow-cell',
      component: ArrowCell,
      componentArgs: {
        collapsedEl: this.getRowCollapsed()
      }
    }
  };

  componentDidUpdate(prevProps) {
    if ((JSON.stringify(this.props) !== JSON.stringify(prevProps))) {
      this.setState({
        showCollapsedRowId: null
      });
    }
  }

  handleShowCollapsedRow = id => {
    this.setState({
      showCollapsedRowId: id === this.state.showCollapsedRowId ? null : id
    });
  };

  handleHeaderCellClick = (field, direction) => {
    this.setState({
      showCollapsedRowId: null,
      tableSort: {field, direction}
    });
  };

  getRowCollapsed() {
    const {isEnabledInteractiveView} = this.props;
    return {
      component: CollapsedRow,
      componentArgs: {
        onClickDiscuss: this.handleClickDiscussIssue,
        onClickFixNotes: this.handleClickFixNotesIssue,
        onClickView: this.handleClickViewIssue,
        isEnabledInteractiveView
      }
    };
  }

  render() {
    const {loading, saving, data} = this.props;
    const {tableSort, showCollapsedRowId} = this.state;

    return (
      <div className="table-container">
        {(loading || saving) ? <Loader/> : null}
        <TableStyled issueBorderWidth="4.87px">
          <Table
            isSeparatedRows
            headers={this.headers}
            rows={getSortedRowsBy(data, tableSort.field, tableSort.direction, this.sortAltFields)}
            sort={tableSort}
            onHeaderCellClick={this.handleHeaderCellClick}
            onRowClick={this.handleShowCollapsedRow}
            showCollapsedRowId={showCollapsedRowId}
            emptyText={loading ? null : 'No Issues'}
            />
        </TableStyled>
      </div>
    );
  }
}

const TableStyled = styled(StyledMobileTable)`
  .table {

    > thead > tr > th {
      padding-bottom: 4px;
      &.location {
        text-align: left;
        min-width: 78px;
      }
      &.type {
        min-width: 63px;
      }
      &.severity {
        min-width: 77px;
      }
      &.status {
        width: 350px;
      }
      &.category, &.actions, &.arrow {
        display: none;
      }
    }

    > tbody {
      > tr {
        cursor: default;
        > td {
          vertical-align: middle;
          padding-top: 8px;
          padding-bottom: 10px;

          &:nth-child(4), &:nth-child(9), &:nth-child(10) {
            display: none;
          }

          @media (max-width: 767px) {
            .mobile-modal-with-issue-table & {
              &:nth-child(9), &:nth-child(10) {
                display: table-cell;
              }
            }
          }
        }
      }
    }
  }
`;

ReplacementIssueTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  onSelectStatus: PropTypes.func.isRequired,
  onClickFixNotes: PropTypes.func,
  onClickDiscuss: PropTypes.func,
  onClickView: PropTypes.func,
  onClickImage: PropTypes.func,
  isEnabledInteractiveView: PropTypes.bool,
};

export default ReplacementIssueTable;
