/* eslint "jsx-a11y/anchor-is-valid":"off" */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import 'bootstrap';
import Loader from '../../../../components/Loader';
import IssueTable from '../IssueTable';
import {StyledModalBackdrop, StyledModalDialog, StyledModal, StyledBaseCloseModal, StyledMobileModal
} from '../../../../styled/ModalStyled';
import {showFixListModal, clearFixList, getFixListIssues, uploadFixlist} from '../../actions.js';
import uploadImg from '../../../../../assets/images/upload.svg';
import emailImg from '../../../../../assets/images/email2.svg';
import printImg from '../../../../../assets/images/print.svg';
import Can from '../../../../components/Can';
import SelectAdvanced from '../../../../components/forms/SelectAdvanced';
import {getUserRoles, isUserCanManage, isUserCanComment} from '../../../../utils';

class FixListModal extends Component {
  UNSAFE_componentWillMount() {
    const {body} = document;
    body.className += ' modal-open';
    const {dispatch, history, match} = this.props;
    dispatch(getFixListIssues(history, match.params.requestId));
  }

  componentDidUpdate() {
    const elements = document.getElementsByClassName('issue-modal-backdrop');
    if (elements && elements.length) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.opacity = '0';
      }
    }
  }

  componentWillUnmount() {
    const {dispatch} = this.props;
    dispatch(clearFixList());

    const {body} = document;
    body.className = body.className.replace('modal-open', '').trim();
  }

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showFixListModal(false));
  };

  handleClickView = issue => {
    const {dispatch, onClickViewIssue} = this.props;
    dispatch(showFixListModal(false));
    onClickViewIssue(issue);
  };

  handlePrint = exportType => {
    const {dispatch, match, reportdetails} = this.props;
    dispatch(uploadFixlist(match.params.requestId, reportdetails.fixlistMinSeverity, exportType));
  };

  handleSelectMinSeverity = (_, value) => {
    const {dispatch, history, match} = this.props;
    dispatch(getFixListIssues(history, match.params.requestId, value));
  };

  render() {
    const {
      match, reportdetails, onClickFixNotes, onClickDiscuss, onSubmit, isEnabledInteractiveView, onEmailReport,
      onClickIssueImage
    } = this.props;
    const requestId = Number(match.params.requestId);
    const {loaders, timeline} = reportdetails;
    const {QcStatusID, RequestStatusID, UserPermissionType} = reportdetails.details;
    return (
      <StyledMobileModal className="fix-list-modal mobile-modal-with-issue-table">
        <StyledModalBackdrop onClick={() => this.handleClose()}/>
        <StyledModalDialog className="mobile-modal-dialog-custom">
          {(reportdetails.saving || reportdetails.completing) ? <Loader/> : null}
          <StyledModal>
            <StyledMinSeveritySelect
              options={reportdetails.fixlistSeverityTypes || []}
              value={reportdetails.fixlistMinSeverity}
              onSelect={this.handleSelectMinSeverity}
              label="Min Severity"
              labelSeparator=""
              placeholder=""
              searchable={false}
            />
            <StyledModalHeader className="modal-header-custom">
              <div className="back-btn" onClick={this.handleClose}/>
              <div className="modal-header-content">
                <h4>Fix List</h4>
                <ul className="menu">
                  <Can
                    roles={getUserRoles()}
                    perform="report-details:show-submit-replacement-btn"
                    data={{QcStatusID, RequestStatusID, UserPermissionType, requestId, timeline}}
                    yes={() => (
                      <li className="margin-left-xs-0" title="Submit">
                        <a className="submit-item" onClick={onSubmit}>
                          <i className="upload-icon icon"/><span>Submit Fixes</span>
                        </a>
                      </li>
                    )}
                  />
                  <li className="separator"/>
                  <li className="email-summary">
                    <a onClick={onEmailReport}><i className="email-icon icon"/><span>Email</span></a>
                  </li>
                  <li>
                    <a onClick={() => this.handlePrint('pdf')}>
                      <i className="print-icon icon print-pdf"/><span>Print As PDF</span>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => this.handlePrint('excel')}>
                      <i className="print-icon icon print-excel"/><span>Print As Excel</span>
                    </a>
                  </li>
                  <li className="separator close-separator"/>
                  <li title="Close" className="close-modal">
                    <StyledBaseCloseModal className="icon-close-modal" onClick={() => this.handleClose()}/>
                  </li>
                </ul>
              </div>
            </StyledModalHeader>
            <StyledModalBody className="modal-body-custom">
              <IssueTable
                loading={reportdetails.loadingFixlistIssues || reportdetails.loadingIssueComments || loaders.issueImageModal}
                data={reportdetails.fixlistIssues}
                issueBorderWidth="6px"
                canManage={isUserCanManage(UserPermissionType)}
                canComment={isUserCanComment(UserPermissionType)}
                onClickFixNotes={onClickFixNotes}
                onClickDiscuss={onClickDiscuss}
                onClickView={this.handleClickView}
                onClickImage={onClickIssueImage}
                isEnabledInteractiveView={isEnabledInteractiveView}
                sort={{field: 'Severity', direction: 'desc'}}
                emptyText={reportdetails.loadingFixlistIssues ? null : 'No Issues'}
                />
            </StyledModalBody>
            <StyledModalFooter/>
          </StyledModal>
        </StyledModalDialog>
      </StyledMobileModal>
    );
  }
}

const StyledMinSeveritySelect = styled(SelectAdvanced)`
  margin: 0;
  z-index: 1061;
  position: absolute;
  top: 30px;
  left: 196px;

  @media (max-width: 767px) {
    top: 0;
    left: 120px;
  }

  .control-label {
    font-size: 10px !important;
    color: #000 !important;
    margin: 0;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .Select {
    * {
      font-size: 12px;
      box-shadow: none;
      outline: none;
    }
    .Select-control {
      border-radius: 3px;
      cursor: pointer;
      height: 24px;
      min-width: 50px;
      .Select-multi-value-wrapper {
        .Select-placeholder, .Select-value {
          line-height: 12px;
          padding: 7px 7px 4px 8px;
        }
        .Select-value .Select-value-label {
          font-weight: normal;
        }
        .Select-input {
          display: none !important;
        }
      }
      .Select-arrow-zone {
        width: 21px;
        img {
          opacity: 0.6;
          width: 10px;
        }
      }
    }
    &:not(.is-open), &:not(.is-focused) {
      .Select-control {
        background-color: #F2F2F2;
        border: 0.5px solid #DADADA;
      }
    }
    .Select-menu-outer {
      background-color: #F5F5F5;
      .Select-option {
        background-color: #F5F5F5;
        &.is-selected, &.is-focused {
          background-color: #fff;
          font-weight: bold;
        }
      }
    }
  }
`;

const StyledModalHeader = styled.div`
  padding: 33px 44px 0 24px;
  position: fixed;
  top: 0;
  right: 0;
  left: 10%;
  z-index: 1060;
  .modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  h4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    color: #000;
    margin-top: 0;
    margin-bottom: 15px;

    @media (min-width: 768px) {
      &:after {
        content: '- Min Severity';
        padding-left: 4px;
        font-weight: normal;
        font-size: 18px;
        line-height: 19px;
        color: #000;
      }
    }
  }
  .menu {
    padding: 0;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 767px) {
      margin: 0 8px 0 0;
      height: 20px;
      overflow-x: auto;
      overflow-y: hidden;
      max-width: calc(100% - 200px);
      justify-content: unset;
    }

    li {
      list-style: none;
      margin: 0 11px;
      &.separator {
        border-left: 1px solid #ebebeb;
        margin: 0 10px -14px;

        @media (max-width: 767px) {
          margin: -19px 4px 0 -1px;
          &.close-separator {
            display: none;
          }
        }
      }
      &.margin-left-xs-0 {
        @media (max-width: 767px) {
          margin-left: 0;
          span {
            display: none;
          }
        }
      }
      &:not(.separator) {
        padding-top: 3px;

        @media (max-width: 767px) {
          padding-top: 0;
        }
      }
      &.email-summary {
        padding-top: 1px;
        span {
          padding-top: 2px;
        }
      }
      &.close-modal {
        padding-top: 2px;
        margin-right: 0;
        @media (max-width: 767px) {
          display: none;
        }
      }

      @media (min-width: 768px) {
        &:hover span {
          max-width: 1000px;
          transition: all 1.5s ease-in-out;
        }
      }
    }
    a {
      color: #000000;
      display: flex;
      font-size: 10px;
      line-height: 23px;
      white-space: nowrap;
      &[disabled] {
        pointer-events: none;
        opacity: 0.65;
      }
      span {
        max-width: 0;
        overflow: hidden;
        transition: all 0.1s ease-in-out;
      }
    }
    .icon {
      background-repeat: no-repeat;
      display: block;
      margin: 0 3px;
      height: 21px;
      width: 21px;
    }
    .upload-icon {
      background-image: url(${uploadImg});
      &+span {
        margin: 0 5px 0 4px;
      }
    }
    .email-icon {
      background-image: url(${emailImg});

      @media (max-width: 767px) {
        background-position: 0;
      }
    }
    .print-icon {
      background-image: url(${printImg});
      position: relative;
      &.print-pdf:after {
        content: 'PDF';
        position: absolute;
        top: 13px;
        left: 2px;
        font-style: normal;
      }
      &.print-excel:after {
        content: 'Excel';
        position: absolute;
        top: 13px;
        left: -1px;
        font-style: normal;
      }
    }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }
`;

const StyledModalBody = styled.div`
  padding: 2px 36px 0 24px;
  position: fixed;
  top: 73px;
  bottom: 0;
  right: 0;
  left: 10%;
  z-index: 1060;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (pointer:coarse) {
    overflow-y: scroll;
  }

  @media (min-width: 768px) {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      -webkit-box-shadow: none;
      background-color: #c4c4c4;
    }
  }

  .loader-container {
    z-index: 1070;
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }

  @media (max-width: 767px) {
    padding: 0;
  }
`;

const StyledModalFooter = styled.div`
  @media (max-width: 767px) {
    background: linear-gradient(0deg, #FFFFFF 22.44%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.95;
    height: 127px;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 2.66%;
    z-index: 1060;
  }
`;

FixListModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  reportdetails: PropTypes.object.isRequired,
  isEnabledInteractiveView: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onEmailReport: PropTypes.func.isRequired,
  onClickFixNotes: PropTypes.func.isRequired,
  onClickDiscuss: PropTypes.func.isRequired,
  onClickViewIssue: PropTypes.func.isRequired,
  onClickIssueImage: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    reportdetails: state.reportdetails
  };
}

export default connect(
  mapStateToProps
)(withRouter(FixListModal));
