import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import 'bootstrap';
import {
  StyledModalBackdrop, StyledModalDialog, StyledModal, StyledIssueModalHeader, StyledCloseModal, StyledIssueModalBody,
  StyledModalSubmitFooter, StyledMobileModal
} from '../../../../styled/ModalStyled';
import {IssueSubHeader} from './IssueSubHeader';
import {showIssueImageModal, uploadIssueImage, deleteIssueImage} from '../../actions.js';
import Can from '../../../../components/Can';
import Loader from '../../../../components/Loader';
import {isManagerUser, getUserRoles, formatDate} from '../../../../utils';
import {Button} from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import Lightbox from 'react-image-lightbox';
import {Carousel} from 'react-responsive-carousel';
import _ from 'lodash';
import {ImageIconStyled} from '../sidebar/Issue';
import {confirmAlert} from 'react-confirm-alert';

class IssueImageModal extends Component {
  state = {
    failedIndexes: [],
    fullscreenIndex: -1,
    selectedIndex: (this.props.reportdetails.imageModalIssue.images || []).length - 1,
  };

  updateModalContentHeight = () => {
    try {
      const subHeaderHeight = document.getElementsByClassName('issue-subheader-modal')[0].clientHeight;
      document.getElementsByClassName('issue-image-modal-body-content')[0].style.height = `calc(100% - ${subHeaderHeight}px)`;
    } catch(ignored) {/**/}
  };

  componentDidMount() {
    const {body} = document;
    body.className += ' modal-open';
    this.updateModalContentHeight();
  }

  componentDidUpdate(prevProps) {
    this.updateModalContentHeight();
    const {reportdetails: {imageModalIssue: {images}}} = this.props;
    if (images && images.length && images.length !== (prevProps.reportdetails.imageModalIssue.images || []).length) {
      this.setState({selectedIndex: images.length - 1});
      if (!images.length) {
        this.imageGalleryRef = null;
      }
    }
  }

  componentWillUnmount() {
    const {body} = document;
    body.className = body.className.replace('modal-open', '').trim();
  }

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showIssueImageModal());
  };

  handleSelectImage = files => {
    const {dispatch} = this.props;
    dispatch(uploadIssueImage(files[0]));
  };

  handleImgError = (index) => {
    const {failedIndexes} = this.state;
    if (!failedIndexes.includes(index)) {
      this.setState({failedIndexes: [...failedIndexes, index]});
    }
  };

  prepareDate = image => {
    let date = image.UploadedOn;
    let fromFormat = '';
    if (!date && image.UnixTimestamp) {
      date = image.UnixTimestamp * 1000;
      fromFormat = 'x';
    }
    return formatDate(date, fromFormat, 'MMMM D, YYYY HH:mm A');
  };

  handleDeleteImage = (selectedIndex) => {
    const {dispatch} = this.props;
    /*const {selectedIndex} = this.state;*/
    const failedIndexes = [...this.state.failedIndexes];
    const removedIndexes = _.remove(failedIndexes, n => n >= selectedIndex);
    removedIndexes.forEach(n => {
      if (n > selectedIndex) {
        failedIndexes.push(n - 1);
      }
    });
    const {reportdetails: {imageModalIssue: {images}}} = this.props;
    const image = images[selectedIndex];
    confirmAlert({
      title: 'Are You Sure?',
      message: 'Are you sure you want to delete this image?',
      buttons: [{
        label: 'No'
      }, {
        label: 'Yes',
        onClick: () => {
          dispatch(deleteIssueImage(image))
            .then(() => {
              this.setState({failedIndexes});
            });
        }
      }]
    });
  };

  handleClickImage = (index) => {
    this.setState({fullscreenIndex: index});
  };

  handleCloseLightbox = () => {
    const {fullscreenIndex} = this.state;
    this.setState({fullscreenIndex: -1, selectedIndex: fullscreenIndex});
    if (this.imageGalleryRef && this.imageGalleryRef.moveTo) {
      this.imageGalleryRef.moveTo(fullscreenIndex);
    }
  };

  renderLightbox() {
    const {reportdetails: {imageModalIssue: {images}}} = this.props;
    const {fullscreenIndex} = this.state;
    if (fullscreenIndex >= 0) {
      const currentImage = images[fullscreenIndex];
      const lightboxProps = {
        onCloseRequest: this.handleCloseLightbox,
        animationDuration: 300,
        mainSrc: currentImage.IssueImageUrl,
        ...(images.length > 1 ? {
          nextSrc: images[(fullscreenIndex + 1) % images.length].IssueImageUrl,
          prevSrc: images[(fullscreenIndex + images.length - 1) % images.length].IssueImageUrl,
          onMovePrevRequest: () => {
            this.setState({
              fullscreenIndex: (fullscreenIndex + images.length - 1) % images.length
            });
          },
          onMoveNextRequest: () => {
            this.setState({
              fullscreenIndex: (fullscreenIndex + 1) % images.length
            });
          },
        } : {}),
        imageTitle: (
          <span
            style={{fontSize: '12px'}}
          >
            Uploaded {currentImage.UserName ? <>by <strong>{currentImage.UserName}</strong>, </> : 'on:'} {this.prepareDate(currentImage)}
          </span>
        ),
      };
      return (
        <Lightbox
          {...lightboxProps}
        />
      );
    }
    return null;
  }

  renderImages() {
    const {reportdetails: {imageModalIssue: {images, issue}}} = this.props;
    const {selectedIndex, failedIndexes} = this.state;
    if (!images || !images.length) {
      return <span>[No Image]</span>;
    }
    return (
      <StyledGallery>
        <Carousel
          ref={r => {
            if (r) {
              this.imageGalleryRef = r;
            }
          }}
          autoPlay={false}
          showArrows
          showIndicators={false}
          showStatus={false}
          dynamicHeight={false}
          infiniteLoop
          selectedItem={Math.min((selectedIndex + 1) || images.length, images.length) - 1}
          onClickItem={(index) => this.handleClickImage(index)}
          onChange={(index) => this.setState({selectedIndex: index})}
          renderThumbs={(items) => items.map((item) => (item))}
          thumbWidth={100}
        >
          {images.map((image, index) => {
            const deleteBtn = (
              <Can
                roles={getUserRoles()}
                perform="report-details:modal-issue-image:show-footer"
                data={issue.IsDeleted}
                yes={() => (
                  <ImageIconStyled
                    className="fas fa-trash"
                    title="Delete Image"
                    onClick={event => {
                      event.stopPropagation();
                      this.handleDeleteImage(index);
                    }}
                  />
                )}
              />
            );
            return (
              <div
                key={image.IssueImageID}
                className="issue-image-container"
              >
                <img
                  src={image.IssueImageUrl}
                  alt=""
                  onError={() => this.handleImgError(index)}
                />
                {failedIndexes.includes(index) && <span className="msg-failed-img">[Image did not load]</span>}
                <p className="legend">
                  <span>
                    {image.UserName ? <>{image.UserName}<br/></> : ''}
                    {this.prepareDate(image)}
                  </span>
                  {deleteBtn}
                </p>
              </div>
            );
          })}
        </Carousel>
        {this.renderLightbox()}
      </StyledGallery>
    );
  }

  render() {
    const {reportdetails} = this.props;
    const {saving, showFixListModal, showEmailReportModal, showSubmitReplacementModal, imageModalIssue} = reportdetails;
    const {issue} = imageModalIssue;
    return (
      <StyledMobileModal>
        <StyledModalBackdrop
          opacity={(showFixListModal || showEmailReportModal || showSubmitReplacementModal) ? 0 : undefined}
          onClick={() => this.handleClose()}
        />
        <StyledModalDialog className="mobile-modal-dialog-custom">
          {saving ? <Loader/> : null}
          <StyledModal className="modal-styled-custom">
            <StyledIssueModalHeader severityColor={issue.SeverityColor} className="modal-header-custom">
              <div className="back-btn" onClick={this.handleClose}/>
              <h4>Issue Images</h4>
              <StyledCloseModal className="icon-close-modal" onClick={this.handleClose}/>
            </StyledIssueModalHeader>
            <StyledIssueModalBody bottom={isManagerUser() ? undefined : '0'}>
              <IssueSubHeader issue={issue}/>
              <StyledModalBodyContent className="issue-image-modal-body-content">
                {this.renderImages()}
              </StyledModalBodyContent>
            </StyledIssueModalBody>
            <Can
              roles={getUserRoles()}
              perform="report-details:modal-issue-image:show-footer"
              data={issue.IsDeleted}
              yes={() => (
                <StyledModalSubmitFooter>
                  <Dropzone onDrop={this.handleSelectImage} className="dropzone" accept="image/*" multiple={false}>
                    <Button
                      bsStyle="primary"
                      >
                      Upload Image
                    </Button>
                  </Dropzone>
                </StyledModalSubmitFooter>
              )}
            />
          </StyledModal>
        </StyledModalDialog>
      </StyledMobileModal>
    );
  }
}

const StyledModalBodyContent = styled.div`
  padding: 31px 45px 30px 50px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (min-width: 768px) {
    height: 100% !important;
  }

  @media (max-width: 767px) {
    padding: 22px 25px 20px 23px;
  }

  img.single-image {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    cursor: pointer;
  }
`;

const StyledGallery = styled.div`
  max-width: 100%;

  .thumb {
    .legend {
      margin: 0 0 0 3px;
      padding: 5%;
      font-size: 0.75em;
      line-height: 1.1em;
      white-space: normal;
      display: grid;
      grid-template-columns: auto min-content;
      i.fa-trash {
        margin: 0 0 0 5px;
        opacity: 0.75;
        :hover {
          opacity: 1;
        }
      }
    }
    img {
      max-width: 100%;
      width: auto;
    }
    .msg-failed-img {
      display: none;
    }
  }

  .slide {
    .legend {
      i.fa-trash {
        display: none;
      }
    }
  }

  @media (min-width: 768px) {
    & {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .carousel-root {
      display: flex;
      flex-direction: row;
      column-gap: 15px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      > .carousel {
        &:not(.carousel-slider) {
          order: 1;
          flex: 0 0 195px;
          height: 100%;
          overflow: hidden;
          > .thumbs-wrapper {
            display: flex;
            margin: 0;
            width: 100%;
            height: 100%;
            max-height: 100%;
            overflow-y: hidden;
            overflow-x: hidden;
            > .thumbs {
              margin: 0;
              padding: 0;
              flex: 0 0 100%;
              width: 100% !important;
              height: 100%;
              max-height: 100%;
              overflow-y: auto;
              overflow-x: hidden;
              display: flex;
              flex-direction: column;
              transform: none !important;
              > .thumb {
                margin: 0;
                width: 100% !important;
                overflow: unset;
                & + .thumb {
                  margin-top: 6px;
                }
                .legend {
                  margin: 3px 0 0;
                }
              }
              &::-webkit-scrollbar-track {
                -webkit-box-shadow: none;
                background-color: transparent;
              }
              &::-webkit-scrollbar {
                width: 0;
                background-color: transparent;
              }
              &::-webkit-scrollbar-thumb {
                -webkit-box-shadow: none;
                background-color: transparent;
              }
            }
            button {
              display: none;
            }
          }
        }
        &.carousel-slider {
          order: 2;
          flex-grow: 1;
          height: 100%;
          overflow: hidden;
          .slider-wrapper {
            height: 100%;
            .slider {
              height: 100%;
              max-height: 100%;
              .slide {
                height: 100%;
                max-height: 100%;
                .issue-image-container {
                  height: 100%;
                  cursor: pointer;
                }
                img {
                  min-width: unset;
                  max-width: 100%;
                  max-height: 100%;
                  width: auto;
                  height: auto;
                  object-fit: contain;
                  cursor: pointer;
                }
                .legend {
                  margin: 0;
                  left: 40px;
                  bottom: 10px;
                  width: fit-content;
                }
              }
            }
          }
          &:hover .control-arrow {
            background: rgba(0,0,0,0.2);
          }
        }
      }
    }
  }
`;


IssueImageModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  reportdetails: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    reportdetails: state.reportdetails
  };
}

export default connect(
  mapStateToProps
)(withRouter(IssueImageModal));
