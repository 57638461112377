import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const QcRequestsCell = ({value}) => {
  return (
    <StyledCell>
      {value} QC Requests
    </StyledCell>
  );
};

const StyledCell = styled.td`
  font-weight: bold;
  font-size: 9px;
  color: #000000;
  text-transform: uppercase;
  text-align: right;
  padding-right: 15px !important;
  border-left: 0.5px solid rgba(0, 0, 0, 0.2) !important;
  width: 110px;
`;

QcRequestsCell.propTypes = {
  value: PropTypes.any
};
