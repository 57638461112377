import React from 'react';
import classNames from 'classnames';
import {Button} from 'react-bootstrap';
import InputTextCell from './InputTextCell';
import InputNumberCell from './InputNumberCell';
import SelectableCell from '../../../Settings/components/qcProfiles/templateBuilder/table/SelectableCell';
import MultiSelectableCell from '../../../Settings/components/qcProfiles/templateBuilder/table/MultiSelectableCell';
import {prepareHoursSpentOptions, prepareHoursSpentValue} from '../tablecells/HoursSpentCell'
import {IS_BILLABLE_OPTIONS, IS_BILLABLE_VALUE_YES, getIsBillableOptionByValue} from '../tablecells/IsBillableCell'
import {SortableHandle} from 'react-sortable-hoc';
import {checkDiscountPercentStep} from './utils';

const DragHandle = SortableHandle(() => <i className="fa fa-bars" title="Move to change order"/>);

function Row(props) {
  const {
    value,
    onDelete,
    onUndelete,
    onInsert,
    onFocus, onBlur,
    onInput, onSelect,
    productCodes, allProductCodes, categories, assignees, statuses,
    labels, descriptions,
    isCreatedWO,
  } = props;

  // fixme - remove deprecated and unused code
  const isCanceled = false; // value.StepStatusID === 0 && !(value.changedFields || []).includes('StepStatusID');
  const isNew = !!value.isNew;
  const isDeleted = !!value.isDeleted;
  const isDisabledStatusAndTimeSpent = !isCreatedWO || isNew;
  // deprecated
  const isDisabledProductCodeAndCategory = false; // !isNew;
  const isEmptyPlaceholder = isCanceled || isDeleted;
  const isDiscountPercentStep = checkDiscountPercentStep(value, isCreatedWO);

  const handleSelectBlur = () => {
    const modalBody = document.querySelector('.modal-body-custom');
    if (modalBody) {
      modalBody.classList.remove('no-scroll');
    }

    if (props.onBlur) {
        props.onBlur();
    }
  };

  const handleSelectFocus = () => {
    const modalBody = document.querySelector('.modal-body-custom');
    if (modalBody) {
      modalBody.classList.add('no-scroll');
    }

    if (props.onFocus) {
      props.onFocus();
    }
  };

  return (
    <tr
      className={classNames({
        canceled: isCanceled,
        deleted: isDeleted,
        disabled: false,
        'is-new-step-row': isNew,
      })}
    >
      <td className="sort">
        <DragHandle/>
      </td>
      <td className="step-order">
        {value.StepOrder}{(isNew || isDeleted) ? <sup>{isDeleted ? 'deleted' : 'new'}</sup> : ''}
      </td>
      <InputTextCell
        className="step-label"
        placeholder="Enter Label..."
        value={value.Label}
        onChange={t => onInput('Label', t)}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={30}
        minWidth={170}
        suggestions={labels}
      />
      <InputTextCell
        className="step-description"
        placeholder="Enter Description..."
        value={value.Details}
        onChange={t => onInput('Details', t)}
        onFocus={onFocus}
        onBlur={onBlur}
        /*type="textarea"*/
        maxLength={150}
        minWidth={200}
        suggestions={descriptions}
      />
      <SelectableCell
        className={classNames({
          'product-code': true,
          disabled: isDisabledProductCodeAndCategory,
          'without--cost': value.hasOwnProperty('Cost') && value.Cost == null
        })}
        placeholder={(isEmptyPlaceholder || isDisabledProductCodeAndCategory) ? '' : undefined}
        value={value.ProductCode}
        options={productCodes}
        onChange={o => onSelect('ProductCode', o)}
        onFocus={ handleSelectFocus }
        onBlur={ handleSelectBlur }
        isProductCode
        allProductCodes={allProductCodes}
        menuMaxHeight={150}
        minWidth={225}
        menuPortalTarget={true}
        menuPlacement="auto"
      />
      <td className="step-unit">
        {value.Unit}
      </td>
      <InputNumberCell
        className={classNames({
          'override-rate': true,
          'is-percent': isDiscountPercentStep
        })}
        placeholder={(isEmptyPlaceholder) ? '' : isDiscountPercentStep ? 'Enter %' : undefined}
        value={value.OverrideRate}
        onChange={v => onInput('OverrideRate', v)}
        onFocus={onFocus}
        onBlur={onBlur}
        minValue={!isCreatedWO ? 0 : undefined}
        maxValue={isDiscountPercentStep? 100 : undefined}
        isCost={!isDiscountPercentStep}
        isPercent={isDiscountPercentStep}
      />
      <SelectableCell
        className={classNames({
          'assignee': true,
        })}
        placeholder={(isEmptyPlaceholder) ? '' : undefined}
        assigneeName={value.Assignee}
        value={value.AssigneeId || null}
        options={assignees}
        onChange={o => onSelect('AssigneeId', o)}
        onFocus={ handleSelectFocus }
        onBlur={ handleSelectBlur }
        menuMaxHeight={150}
        minWidth={170}
        isClearable
        isAssigneeControl
        menuPortalTarget={true}
        menuPlacement="auto"
      />
      {categories.length > 0 ?
        <MultiSelectableCell
          className={classNames({
            'category-flags': true,
            disabled: isDisabledProductCodeAndCategory,
          })}
          disabled={isEmptyPlaceholder || isDisabledProductCodeAndCategory}
          placeholder={(isEmptyPlaceholder || isDisabledProductCodeAndCategory) ? '' : undefined}
          value={value.StepFlags}
          options={categories}
          onChange={v => {
            const allCategoriesFlags = categories.map(o => o.value).reduce((a, b) => a | b, 0);
            const newStepFlags = ((value.StepFlags || 0) & ~allCategoriesFlags) | v;
            onInput('StepFlags', newStepFlags);
          }}
          onFocus={ handleSelectFocus }
          onBlur={ handleSelectBlur }
          menuMaxHeight={150}
          //minWidth={180}
          menuPlacement="auto"
          menuPortalTarget={true}
        /> : <td className="category-flags">
          <span>Select...</span>
        </td>
      }
      <SelectableCell
        className={classNames({
          'status': true,
          disabled: isDisabledStatusAndTimeSpent,
        })}
        placeholder={(isEmptyPlaceholder || isDisabledStatusAndTimeSpent) ? '' : undefined}
        value={value.StepStatusID}
        options={statuses}
        onChange={o => onSelect('StepStatusID', o)}
        onFocus={ handleSelectFocus }
        onBlur={ handleSelectBlur }
        menuMaxHeight={150}
        minWidth={120}
        menuPortalTarget={true}
        menuPlacement="auto"
      />
      <SelectableCell
        className={classNames({
          'time-spent': true,
          disabled: isDisabledStatusAndTimeSpent,
        })}
        placeholder={(isEmptyPlaceholder || isDisabledStatusAndTimeSpent) ? '' : undefined}
        value={prepareHoursSpentValue(value.HoursSpent)}
        options={prepareHoursSpentOptions(value.HoursSpent)}
        onChange={o => onSelect('HoursSpent', o)}
        onFocus={ handleSelectFocus }
        onBlur={ handleSelectBlur }
        menuMaxHeight={150}
        minWidth={120}
        menuPortalTarget={true}
        menuPlacement="auto"
      />
      <SelectableCell
        className="is-billable"
        placeholder={(isEmptyPlaceholder) ? '' : undefined}
        value={getIsBillableOptionByValue(value.IsBillable).value}
        options={IS_BILLABLE_OPTIONS}
        onChange={o => !!o && onInput('IsBillable', o.value === IS_BILLABLE_VALUE_YES)}
        onFocus={ handleSelectFocus }
        onBlur={ handleSelectBlur }
        menuMaxHeight={150}
        /*minWidth={100}*/
        menuPortalTarget={true}
      />
      <InputNumberCell
        className="quantity"
        placeholder={(isEmptyPlaceholder) ? '' : undefined}
        value={value.Quantity}
        onChange={v => onInput('Quantity', v)}
        onFocus={onFocus}
        onBlur={onBlur}
        minValue={0}
      />
      <SelectableCell
        className="estimated-hours"
        placeholder={(isEmptyPlaceholder) ? '' : undefined}
        value={prepareHoursSpentValue(value.EstimatedHours)}
        options={prepareHoursSpentOptions(value.EstimatedHours, 24, true)}
        onChange={o => onSelect('EstimatedHours', o)}
        onFocus={ handleSelectFocus }
        onBlur={ handleSelectBlur }
        menuMaxHeight={150}
        minWidth={120}
        menuPortalTarget={true}
        menuPlacement="auto"
      />
      <td className="actions">
        <div>
          {isDeleted ?
            <Button
              className="btn-in-modal"
              title="Click to undelete step"
              onClick={onUndelete}
            >
              <i className="fas fa-trash-restore"/>
            </Button> : (isNew || !isCreatedWO) &&
            <Button
              className="btn-in-modal"
              title="Delete"
              onClick={onDelete}
            >
              <i className="fas fa-trash"/>
            </Button>
          }
          <Button
            className="btn-in-modal"
            title="Insert"
            onClick={onInsert}
          >
            <i className="fas fa-plus-circle"/>
          </Button>
        </div>
      </td>
    </tr>
  );
}

export default Row;