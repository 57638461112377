import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Button, Collapse} from 'react-bootstrap';
import Select from 'react-select';
import ColoredFilter from './ColoredFilter';
import SelectStyled from './SelectStyled';
import StyledBtn from './FilterBtnStyled';

const ALL_OPTION_VALUE = -100;
const VISIBLE_OPTIONS_LIMIT = 10;

class Filter extends React.PureComponent {
  state = {
    hideMoreThan10Options: true
  };

  handleClick = (filterName, filterValue, newValue) => {
    const {onChangeFilters, filter} = this.props;
    const value = newValue === null ? null :
      (filterValue && !filterValue.includes(ALL_OPTION_VALUE) && newValue !== ALL_OPTION_VALUE) ?
        (filter.multi === false ? (filterValue.includes(newValue) ? filterValue : [newValue]) :
          filterValue.includes(newValue) ?
            filterValue.filter(item => item !== newValue) :
              [...filterValue, newValue]
        ) :
          [newValue];
    onChangeFilters(filterName, value && value.length ? value : null);
  };

  handleSelect = event => {
    const {onChangeFilterType, filter} = this.props;
    onChangeFilterType(filter.name, event.value, event.label);
  };

  isSelected = item => {
    const {value} = this.props;
    return (value && value.includes(item.value));
  };

  getClassName(item) {
    const {value} = this.props;
    return ((item.name === 'All' && value === null) || this.isSelected(item)) ? 'selected' : '';
  }

  isVisible = item => {
    return item.name === 'All'|| item.value < 0 || this.isSelected(item);
  };

  renderFilter() {
    const {filter, value} = this.props;
    if (!filter || !filter.values || !filter.values.length) {
      return null;
    }

    const values = [...filter.values];
    const visibleCount = values.reduce((a, c) => a + (this.isVisible(c) ? 1 : 0), 0);
    const showMoreOrLessBtn = values.length > VISIBLE_OPTIONS_LIMIT && visibleCount <= VISIBLE_OPTIONS_LIMIT;
    const {hideMoreThan10Options} = this.state;
    const showSelectedItemsOnTop = hideMoreThan10Options && showMoreOrLessBtn && values.length > VISIBLE_OPTIONS_LIMIT;
    !filter.isOrderedValues && values.sort((a, b) => {
      if (a.name === 'All') {
          return -1;
      }
      if (b.name === 'All') {
        return 1;
      }
      if (a.value < 0) {
        return b.name === 'All' ? 1 : -1;
      }
      if (b.value < 0) {
        return a.name === 'All' ? -1 : 1;
      }
      if (showSelectedItemsOnTop && this.isSelected(a) && !this.isSelected(b)) {
        return -1;
      }
      if (showSelectedItemsOnTop && this.isSelected(b) && !this.isSelected(a)) {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });
    return (
      <FilterStyled id={`filter-${filter.name}`}>
      {
        values.map((item, i) => (hideMoreThan10Options && i >= VISIBLE_OPTIONS_LIMIT && !this.isVisible(item)) ? null :
          <div key={i}>
            <Button
              onClick={() => this.handleClick(filter.name, value, item.value)}
              className={this.getClassName(item)}
              title={`${item.name}${item.total >= 0 ? ` :: ${item.total}` : ''}`}
              >{item.name} <span className="total">
                {item.total}
              </span>
            </Button>
          </div>
        )
      }
      {
        showMoreOrLessBtn ?
          <StyledBtn
            className="show-more-options"
            onClick={() => this.setState({hideMoreThan10Options: !hideMoreThan10Options})}
          >
            {hideMoreThan10Options ? 'Show more options' : 'Show less'}
          </StyledBtn> : null
      }
      </FilterStyled>
    );
  }

  render() {
    const {coloredFilterName, filter, filtersOptions, value, isOpen, onToggleFilterOpen} = this.props;
    const open = typeof isOpen !== 'boolean' || isOpen;

    return (
      <StyledWrapFilter className={filter.type ? `type-${filter.type}` : undefined}>
        <SelectStyled>
          <Select
            value={filter.name}
            options={filtersOptions}
            onChange={this.handleSelect}
            disabled={filter.disabled || filtersOptions.length <= 1}
            />
          <div
            className="filter-collapse-arrow"
            onClick={onToggleFilterOpen}
            aria-controls={`filter-${filter.name}`}
            aria-expanded={open}
            />
        </SelectStyled>
        {
          filter.name === coloredFilterName ? (
            <ColoredFilter
              filter={filter}
              value={value}
              onClick={this.handleClick}
              />
          ) : (
            <Collapse in={open}>
              {this.renderFilter()}
            </Collapse>
          )
        }
      </StyledWrapFilter>
    );
  }
}

const StyledWrapFilter = styled.div`
  @media (max-width: 991px) {
    .requests-content & {
      margin-bottom: 15px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &.type-info {
    pointer-events: none;
    opacity: 0.8;
    .Select-arrow-zone {
      visibility: hidden;
    }
  }
`;

const FilterStyled = styled.div`
  border-bottom: 1px solid #ddd;
  color: #000000;
  margin: 5px 0 12px;
  padding: 7px;

  .btn {
    border: none;
    background-color: transparent;
    box-shadow: none;
    font-size: 10px;
    margin-bottom: 1px;
    outline: none;
    opacity: 0.7;
    padding: 7px;
    &.selected {
      background-color: #585858;
      color: #fff;
      opacity: 1;
    }

    span {
      margin-left: 10px;
    }
  }

  @media (max-width: 991px) {
    .requests-content & {
      margin: 0;
      border-bottom-color: #e0e0e0;
      background-color: #F8F8F8;
      border-top: 0.5px solid rgba(0,0,0,0.2);
    }
  }

  @media (min-width: 992px) {

  .btn {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
    .requests-content & {
      display: block !important;
      height: auto !important;
    }
  }
`;

Filter.propTypes = {
  isOpen: PropTypes.bool,
  onToggleFilterOpen: PropTypes.func,
  coloredFilterName: PropTypes.string,
  filter: PropTypes.object.isRequired,
  filtersOptions: PropTypes.array.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  onChangeFilterType: PropTypes.func.isRequired,
  value: PropTypes.any
};

export default Filter;

