// type stages from subtype_category.Priority
export const TYPE_STAGE_PRE_QC = 1;
export const TYPE_STAGE_QC = 2;
export const TYPE_STAGE_POST_QC = 3;

export const TYPE_STAGES = {
  [TYPE_STAGE_PRE_QC]: 'Pre QC',
  [TYPE_STAGE_QC]: 'QC',
  [TYPE_STAGE_POST_QC]: 'Post QC'
};

export const TYPE_STAGE_FOR_NEW_STEP = 0;

export const VERSION_ALL = 'ALL';
export const VERSION_VERSION_1 = 'V1';
export const VERSION_REPLACEMENT = 'R';


export const OFFICE_ALL = 'ALL';
export const OFFICE_BURBANK = 'B';
export const OFFICE_NEW_YORK = 'NY';

export const PATCHES_ANY = 'ANY';
export const PATCHES_YES = 'YES';
export const PATCHES_NO = 'NO';


export const TIME_ESTIMATE_TYPE__RUNTIME = 'RT';
export const TIME_ESTIMATE_TYPE__FLAT = 'F';

export const TIME_ESTIMATE_TYPE_OPTIONS = [{
  value: TIME_ESTIMATE_TYPE__RUNTIME,
  shortName: TIME_ESTIMATE_TYPE__RUNTIME,
  name: 'Run Time'
}, {
  value: TIME_ESTIMATE_TYPE__FLAT,
  shortName: TIME_ESTIMATE_TYPE__FLAT,
  name: 'Flat'
}];

const TIME_ESTIMATE_VALUE_OPTIONS__RUNTIME = (new Array((5.75-1)/.25+1)).fill(.75).reduce((a,item,idx) => {const value=.25*(idx+1)+item;a.push({value,name:value+'x'});return a;},[]);
const TIME_ESTIMATE_VALUE_OPTIONS__FLAT = (new Array((10-.25)/.25+1)).fill(0).reduce((a,item,idx) => {const value=.25*(idx+1)+item;a.push({value,name:String(value).replace('0.', '.')});return a;},[]);

export const getTimeEstimateValueOptions = type => {
  return type === TIME_ESTIMATE_TYPE__RUNTIME ? TIME_ESTIMATE_VALUE_OPTIONS__RUNTIME : TIME_ESTIMATE_VALUE_OPTIONS__FLAT;
};
