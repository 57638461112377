import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {Button} from 'react-bootstrap';
import {formatDate} from '../../../../utils';
import CommentContent from './CommentContent';

function GeneralComment(props) {
  const {comments, even, isModal, currentCommentId} = props;

  const [showAllComments, setShowAllComments] = useState(comments.length === 1 || !!currentCommentId);

  const getNameFirstLetters = (name) => {
    return name && name.split(' ').map(s => s[0]).slice(0, 2).join('');
  };

  return comments.slice(0, showAllComments ? comments.length : 1).map(comment => (
    <Comment
      key={`g-comment-${comment.CommentID}`}
      id={isModal ? `g-comment-${comment.CommentID}` : undefined}
      className={`${even ? 'even' : ''} ${comment.CommentID === currentCommentId ? 'highlight' : ''}`}
    >
      {
        comment.UserImageUrl ? (
          <div className="user-column-logo">
            <UserLogoStyled logoUrl={comment.UserImageUrl}></UserLogoStyled>
          </div>
        ) : (
          <ShortUserName className={even ? 'even' : undefined}>
            <div>{getNameFirstLetters(comment.UserName || 'No Name')}</div>
          </ShortUserName>
        )
      }
      <Content maxWidth={isModal ? '71%' : undefined}>
        <Header>
          <strong>{comment.UserName || 'No Name'}</strong>
          <div title={formatDate(comment.CommentedOn, '', 'MMMM D, YYYY HH:mm A')}>
            {formatDate(comment.CommentedOn, '', 'MMM D, YYYY')}
          </div>
        </Header>
        <CommentDesc overflow={!showAllComments ? 1 : 0}>
          <CommentContent comment={comment} prefixKey="gci"/>
        </CommentDesc>
        {!showAllComments && comments.length > 1 && (
          <Button
            onClick={() => setShowAllComments(true)}
            title="Click to view"
          >
            +{comments.length - 1} other comments
          </Button>
          )
        }
      </Content>
    </Comment>
  ));
}

const Comment = styled.div`
  margin: 0;
  padding: 15px 15px 15px 19px;
  display: flex;
  flex-wrap: nowrap;

  &.even {
    background-color: #f8f8f8;
  }

  :first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  :last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &.highlight {
    border-top-left-radius: 14px;
    background-color: #e5e5e5;
  }

  .user-column-logo {
    min-width: 33px;
    width: 33px;
    height: 33px;
    margin-right: 10px;
  }
`;

export const UserLogoStyled = styled.span`
  background-color: transparent;
  background-image: url(${props => props.logoUrl});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1 1 100%;
  height: 100%;
  width: 100%;
  border-radius: 100px;
`;

const ShortUserName = styled.div`
  flex: 0 0 40px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #a6a6a6;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    font-size: 13px;
    line-height: 15px;
    color: #fff;
  }

  &.even {
    > div {
      background-color: #575757;
    }
  }
`;

const CommentDesc = styled.div`
  word-break: break-word;
  white-space: pre-wrap;
  ${props => props.overflow && css`
    word-break: unset;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;

const Header = styled.div`
  display: flex;
  flex-wrap: nowrap;
  > div {
    margin-left: 7px;
    white-space: nowrap;
    opacity: 0.6;
  }
`;

const Content = styled.div`
  overflow: hidden;

  @media (min-width: 992px) {
    ${props => props.maxWidth && css`
      max-width: ${props.maxWidth};
    `}
  }

  .btn {
    margin-right: auto;
    padding: 0;
    border: none !important;
    background: none !important;
    font-family: 'Roboto',sans-serif;
    font-size: 12px;
    font-weight: bold;
    outline: none !important;
    box-shadow: none !important;

    :hover {
      opacity: 0.8;
    }
  }
`;

GeneralComment.propTypes = {
  comments: PropTypes.array.isRequired,
  even: PropTypes.bool.isRequired,
  isModal: PropTypes.bool,
  currentCommentId: PropTypes.any
};

export default GeneralComment;
