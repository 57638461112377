import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';
import styled from 'styled-components';
import Switch from "react-switch";
import {isManagerUser} from '../../../../utils';

class PrintReportModal extends Component {
    state = {
        include_fixed_issues: false,
        include_issues_comments: false
    };

    handleSubmit = () => {
        const {onConfirm} = this.props;
        const {include_fixed_issues, include_issues_comments} = this.state;
        onConfirm(include_fixed_issues, include_issues_comments);
    };

    handleChangeIncludeFixedIssues = (value) => {
        this.setState({include_fixed_issues: value});
    };

    handleChangeIncludeIssuesComments = (value) => {
        this.setState({include_issues_comments: value});
    };

    render() {
        const {onClose} = this.props;
        const {include_fixed_issues, include_issues_comments} = this.state;
        return (
            <ModalStyled
                className="cs-create-request-modal"
                show={true}
                onHide={onClose}
                enforceFocus={false}
            >
                <Modal.Header>
                    <Modal.Title>Print Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <span>Select additional options for report:</span>
                        { isManagerUser() ?
                            <div className="switch-field switch-not-sure">
                                <Switch
                                    onChange={this.handleChangeIncludeFixedIssues}
                                    checked={include_fixed_issues}
                                    className={`react-switch${include_fixed_issues ? ' checked' : ''}`}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    offColor="#F6F6F6"
                                    onColor="#FFFFFF"
                                    offHandleColor="#A9A9A9"
                                    onHandleColor="#7FCF18"
                                    activeBoxShadow="0px 0px 1px 1px transparent"
                                    handleDiameter={22}
                                    height={28}
                                    width={48}
                                />
                                <span>Include fixed issues</span>
                            </div> : null
                        }
                        <div className="switch-field switch-not-sure">
                            <Switch
                                onChange={this.handleChangeIncludeIssuesComments}
                                checked={include_issues_comments}
                                className={`react-switch${include_issues_comments ? ' checked' : ''}`}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                offColor="#F6F6F6"
                                onColor="#FFFFFF"
                                offHandleColor="#A9A9A9"
                                onHandleColor="#7FCF18"
                                activeBoxShadow="0px 0px 1px 1px transparent"
                                handleDiameter={22}
                                height={28}
                                width={48}
                            />
                            <span>Include comments for issues</span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonStyled
                        bsStyle="default"
                        onClick={onClose}
                    >
                        Cancel
                    </ButtonStyled>
                    <ButtonStyled
                        bsStyle="primary"
                        onClick={this.handleSubmit}
                    >
                        Print Report
                    </ButtonStyled>
                </Modal.Footer>
            </ModalStyled>
        );
    }
}

const ModalStyled = styled(Modal)`
  .modal-header {
    border-bottom: none;
    h4 {
      padding: 5px 15px 0;
    }
  }
  .modal-body {
    padding: 0 15px;
  }
  .modal-footer {
    border-top: none;
  }
  .switch-field {
    margin-top: 10px;
  }
`;

const ButtonStyled = styled(Button)`
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 100px;
  font-weight: bold;

  & + & {
    margin-left: 20px !important;
  }

  &:not(:disabled):hover {
    background-color: #f1f1f1 !important;
  }

  &.btn-primary {
    color: #5d3996;

    &:disabled {
      opacity: 0.65;
      cursor: default;
    }

    &:not(:disabled):hover {
      color: #401782;
    }
  }

  &.btn-default {
    color: #bbb;

    &:hover {
      color: #969696;
    }
  }
`;

PrintReportModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default PrintReportModal;